import Colors from './Colors';

export default {
    cursorDefault: {
        cursor: "default",
    },
    cursorPointer: {
        cursor: "pointer",
    },
    cursorAuto: {
        cursor: "auto",
    },
}
