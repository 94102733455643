import React from 'react';

import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Div from '../components/Div';

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSelectOption(event) {
        const pr = this.props;

        const value = event.target.value;
        let selectedOption = null;

        if(value === undefined) {
            return;
        }

        for(let i = 0; i < pr.options.length; ++i) {
            if(pr.options[i].value === value) {
                selectedOption = pr.options[i];
            }
        }

        pr.onSelectOption(selectedOption);
    }

    getDropdownColors() {
        switch(this.props.color) {
            case "purple":
                return {
                    backgroundColor: Colors.purpleDarkBackground,
                    color: "white",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: Colors.purpleLight,
                };
            case "indigo":
                return {
                    backgroundColor: Colors.indigo,
                    color: "white",
                    borderWidth: 0,
                    borderStyle: "solid",
                    borderColor: "white",
                };
            default:
                null;
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const errorStyle = pr.isError ? sty.errorStyle : null;

        const selectStyle = pr.selectStyle ? pr.selectStyle : null;
        let dropdownColors = this.getDropdownColors();

        return (
            <Div className="dropdownElement" style={{...sty.dropdownElement, ...pr.style}}>
                {pr.title ? <Div style={sty.titleDropdown}>{pr.title}</Div> : null}
                <select
                    style={{...sty.dropdown, ...dropdownColors, ...selectStyle, ...errorStyle, ...StylesPlatform.cursorPointer}}
                    onChange={this.onSelectOption.bind(this)}
                >
                    {pr.options.map((option, optionIndex) => {
                        let selected = false;

                        if(Utils.get(pr, "selected.value", undefined) == option.value) { // NOTE
                            selected = true;
                        }

                        return (
                            <option
                                key={`${optionIndex}-${option.value}`}
                                value={option.value}
                                disabled={option.value == undefined}
                                selected={selected}
                            >
                                {option.text}
                            </option>
                        )
                    })}
                </select>
            </Div>
        );
    }

    styles = {
        dropdownElement: {
            color: "white",
        },
        titleDropdown: {

        },
        dropdown: {
            borderRadius: 100,
            padding: 15,
            minWidth: 200,
            fontFamily: "Nyata",

            // NOTE: down carat dropdown styles
            appearance: "none",
            backgroundImage: "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 1rem center",
            backgroundSize: "1.3em",

        },
        errorStyle: {
            borderColor: Colors.redError,
        },
    };
}
