import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Button from "../../elements/Button";
import Div from "../../elements/Div";
import Colors from "../../modules/Colors";
import "./cropper.css"

const ImageCropper = ({
    onCropImage,
    inputImg,
    imgName,
    closeModal,
    ratio,
    aspectRatio,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
            onCropImage(
                new File([croppedImage], imgName, {
                    type: "image/png",
                    lastModified: new Date().getTime(),
                })
            );
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels]);

    return (
        /* need to have a parent with `position: relative`
        to prevent cropper taking up whole page */
        <Div className="cropper imageCropperComponent" style={{width: "100%"}}>
            <Cropper
                minZoom={0.4}
                image={inputImg}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio ? aspectRatio : 1}
                restrictPosition={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                    containerStyle: {
                        // reactEasyCrop_Container
                        maxWidth: 650,
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: 500,
                        position: "relative",
                    },
                }}
            />
            <Div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20}} className="">
                <Button
                    onClick={closeModal}
                    color="white"
                    size="small"
                >
                    Cancel
                </Button>
                <input
                    type="range"
                    value={zoom}
                    max={3.2}
                    min={ratio ? 0.4 : 0.6}
                    step={0.1}
                    onChange={e => setZoom(e.target.value)}
                    style={{accentColor: Colors.magenta, marginLeft: 15, marginRight: 15}}
                />
                <Button
                    onClick={() => {
                        showCroppedImage();
                        closeModal();
                    }}
                    color="pinkGradient"
                    size="small"
                >
                    Upload
                </Button>
            </Div>
        </Div>
    );
};

export default ImageCropper;
