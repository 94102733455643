import Styles from '../modules/Styles';
import StylesMedia from '../modules/StylesMedia';
import Span from '../elements/Span';

export default {
    desktopWidth: 1900,
    laptopWidth: 1200,
    ipadSideWidth: 992,
    iphoneLandscapeWidthLarge: 812,
    ipadWidth: 768,
    // iphoneWidth: 576,
    iphoneWidth: 568,
    iphoneLandscapeWidthSmall: 568,
    iphoneSmallWidth: 320,

    getWidthSize(widthType) {
        switch(widthType) {
            case "dw":
                return this.desktopWidth;
            case "xl":
                return this.laptopWidth;
            case "lg":
                return this.ipadSideWidth;
            // case "lsl":
            //     return iphoneLandscapeWidthLarge;
            case "md":
                return this.ipadWidth;
            case "sm":
                return this.iphoneWidth;
            // case "lss":
            //     return this.iphoneLandscapeWidth;
            case "xs":
                return this.iphoneSmallWidth;
        }
    },

    // If width is 1600 or minWidth == 'md'
    getWidthType(width, minWidth) {
        // if(width < 576 || (minWidth && minWidth == 'xs')) {
        //     return "xs";
        // } else if (width < 768 || (minWidth && minWidth == 'sm')) {
        //     return "sm";
        // } else if (width < 992 || (minWidth && minWidth == 'md') ) {
        //     return "md";
        // } else if (width < 1200 || (minWidth && minWidth == 'lg') ) {
        //     return "lg";
        // } else {
        //     return "xl";
        // }

        if(width >= this.laptopWidth || (minWidth && minWidth == 'xl')) {
            return "xl";
        } else if (width >= this.ipadSideWidth || (minWidth && minWidth == 'lg') ) { // ipad side
            return "lg";
        } else if (width >= this.ipadWidth || (minWidth && minWidth == 'md') ) { // ipad
            return "md";
        } else if (width >= this.iphoneWidth || (minWidth && minWidth == 'sm')) { // iphone
            return "sm";
        } else { // iphone
            return "xs";
        }
    },

    getMediaStyle(styleName, defaultStyle, styles, widthType, alwaysMobile) {
        if(alwaysMobile) {
            widthType = "md";
        }
        let styleNameType = styleName + "_" + widthType; // "headline_xl"

        if(defaultStyle == undefined) {
            defaultStyle = null;
        }

        if(styles && styleNameType in styles) { // if "headline" override "headline_xl" exists, use that
            return {...defaultStyle, ...styles[styleNameType]};
        } else if(defaultStyle) {
            return defaultStyle;
        } else if(styleName in Styles) { // otherwise, use "headline"
            return Styles[styleName];
        } else if(styleName in StylesMedia) {
            return {...defaultStyle, ...StylesMedia[styleNameType]};
        } else {
            return null;
        }
    },
    getMStyle(styles, styleName, width, targetWidthType, defaultStyle) {
        let styleNameType = styleName + "_" + this.getWidthType(width, targetWidthType); // "headline_xl"

        if(defaultStyle == undefined) {
            if(styles && styleName in styles) {
                defaultStyle = styles[styleName];
            } else {
                defaultStyle = {};
            }
        }

        if(styles && styleNameType in styles) { // if "headline" override "headline_xl" exists, use that
            return {...defaultStyle, ...styles[styleNameType]};
        } else if(styles && styleName in styles) {
            return styles[styleName];
        } else if(defaultStyle) {
            return defaultStyle;
        } else if(styleName in Styles) { // otherwise, use "headline"
            return Styles[styleName];
        } else if(styleName in StylesMedia) {
            return {...defaultStyle, ...StylesMedia[styleNameType]};
        } else {
            return null;
        }
    },
    getMStyle_OLD(styles, styleName, width, targetWidthType, defaultStyle) {
        // let widthType = width;
        let currentWidthType = this.getWidthType(width);
        // if(!targetWidthType) {
        //     if(typeof width == "number") {
        //         currentWidthType = this.getWidthType(width);
        //     }
        // }
        console.log("Current width type", currentWidthType);
        if(defaultStyle == undefined) {
            if(styles && styleName in styles) {
                defaultStyle = styles[styleName];
            } else {
                defaultStyle = {};
            }
        }

        console.log("defaultStyle", defaultStyle);
        let styleNameType = styleName + "_" + currentWidthType; // "headline_xl"
        const widths = ['xs', 'sm', 'md', 'lg', 'xl'];
        const thisOrSmaller = widths.slice(0, widths.indexOf(targetWidthType ? targetWidthType : currentWidthType)+1);
        console.log("thisOrSmaller", thisOrSmaller);
        for(var idx in thisOrSmaller) {
            const styleNameType = styleName + "_" + thisOrSmaller[idx]; // "headline_xl"
            console.log("Checking style", styleNameType, idx);
            if(styles && styleNameType in styles) {
                console.log("Found!", styleNameType);
                return {...defaultStyle, ...styles[styleNameType]};
                break;
            }
        }

        if(styles && styleNameType in styles) { // if "headline" override "headline_xl" exists, use that
            return {...defaultStyle, ...styles[styleNameType]};
        } else if(styles && styleName in styles) {
            return styles[styleName];
        } else if(defaultStyle) {
            return defaultStyle;
        } else if(styleName in Styles) { // otherwise, use "headline"
            return Styles[styleName];
        } else if(styleName in StylesMedia) {
            return {...defaultStyle, ...StylesMedia[styleNameType]};
        } else {
            return null;
        }
    },
    colorBoolean(val, valStyle) {
        valStyle = valStyle != undefined ? valStyle : "yesno";
        let trueVal = "True";
        let falseVal = "False";
        if(valStyle == 'yesno') {
            trueVal = "Yes";
            falseVal = "No";
        }
        if(val) {
            return <Span style={{color:"green"}}>{trueVal}</Span>;
        } else {
            return <Span style={{color:"gray"}}>{falseVal}</Span>;
        }
    },
    reactStyleToCSS(reactStyleObject) {
      const toKebabCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

      const cssString = Object.entries(reactStyleObject)
        .map(([key, value]) => `${toKebabCase(key)}: ${value};`)
        .join(' ');

      return cssString;
    },


}
