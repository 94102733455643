import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';

import Div from './Div';
import CustomerProfile from './CustomerProfile';
import Chatbox from './Chatbox';
import ModalNFT from './ModalNFT';
import Scroller from './Scroller';
import ProfileMenu from './ProfileMenu';
import Profile from './Profile';
import AfterpartyRoomBadges from './AfterpartyRoomBadges';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';
import followUser from '../assets/icons/follow-user.svg';

import AgoraVideoCall from "./AgoraVideoCall";
const AGORA_APP_ID = 'b0dc8c2769cd4f8389486454d1dfb958';

export default class AfterpartyRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            wallet: null,
            mode: Utils.getRequest('host') == 1 ? 'host' : 'guest',
            hostPicture: true,
            guestsOnline: 0,
            sessionDuration: 0,
            onlineUsers: [],
            chatboxContent: "messages", //"messages", // "participants",
            showSionModal: false,
            currentSoin: {
                name: "",
                description: "",
                image_url: "",
                created_at: "",
            },
            currentSoinIndex: -1,

            videoIcons: {
                video: {
                    isShown: true,
                    isOn: false,
                },
                audio: {
                    isShown: true,
                    isOn: false,
                },
                tv: {
                    isShown: false,
                    isOn: false,
                },
                desktop: {
                    isShown: true,
                    isOn: true,
                },
                exit: {
                    isShown: true,
                },
                eventEnd: {
                    isShown: true , // NOTE not being used
                },
            },

            agoraActive: false, //Utils.getRequest('agora') == 1 ? true : true,
            agoraKey: 0,
            testMode: Utils.getRequest('test') == 1 ? true : false,
            showHelpQuestions: true,
            muteChecked: {},
            showProfileModal: false,

            mostRecentMessage: {
                body: "",
                created_at: "",
            },
            posting: false,
            post: "",
            expandMostRecentMessageInput: false,
            showBitCloutBuyModel: false,

            subscribeSuccess: false,
            muteVideo: {}, // NOTE user id is the key
            muteAudio: {}, // TODO when a VIP is added, automatically add them to this map
        };

        this.emojis = ["🎉", "😍", "❤️", "🔥", "👏", "😂"];

        //window.startConference = this.startConference;
        window.addVideoStream = this.addVideoStream;
        window.removeVideoStream = this.removeVideoStream;
        this.hostRef = React.createRef();
        const urlPath = Utils.get(document, "location.pathname", "");
        let channel = "mychannel";
        const parts =  urlPath.split("-");
          console.log("LISTING", parts);
          if(parts.length >= 2) {
              const id = parts[parts.length-1];
              channel = id;
          }

        //ll._("Joining channel: "+channel, "green")
        this.videoProfile = "480p_4";
        this.channel = channel;
        this.transcode = "interop";
        this.attendeeMode = "video";
        this.baseMode = "avc";
        this.appId = AGORA_APP_ID;
        if(window.location.host.indexOf('localhost') == -1) {
            if(window.location.protocol.indexOf('https') == -1) {
                if(Utils.getRequest('test') != 1) {
                    alert("Video chat won't work with an HTTP -- must be HTTPs");
                }
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;

        this.setState({
            isHost: Utils.get(newProps, "roomItem.insiders.isHost"),
            isCohost: Utils.get(newProps, "roomItem.insiders.isCohost"),
            isMod: Utils.get(newProps, "roomItem.insiders.isMod"),
        });
    }

    // Add video streams to the container.
    addVideoStream(elementId){
        // Creates a new div for every stream
        let streamDiv = document.createElement("div");
        // Assigns the elementId to the div.
        streamDiv.id = elementId;
        // Takes care of the lateral inversion
        streamDiv.style.transform = "rotateY(180deg)";
        // Adds the div to the container.
        remoteContainer.appendChild(streamDiv);
    }

    // Remove the video stream from the container.
    removeVideoStream(elementId) {
        let remoteDiv = document.getElementById(elementId);
        if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
    }

    updateStats() {
         window.client.getSessionStats((o) => {
            this.setState({guestsOnline: o["UserCount"], sessionDuration: o["Duration"]});
        })
    }
    openProfileModal() {
        this.setState({showProfileModal: true});
    }

    closeProfileModal() {
        this.setState({showProfileModal: false});
    }

    handleStartAgora() {
        const roomId = Utils.get(this.props.roomItem, "id");
        const eventId = Utils.get(this.props.roomItem, "event.id");
        const data = {
            event_type: 1, // Join
            room_id: roomId,
            event_id: eventId,
        }
        Api.postCustomerEvent(data, (o) => { console.log("Joined"); });
        this.setState({agoraActive: true, agoraKey: this.state.agoraKey+1});
    }

    handleChangeAgoraClient(newClient, clientId) {
        console.log("New client", newClient);
        this.agoraClient = newClient;
        this.setState({clientId:clientId});
    }
    handleChangeAgoraStream(newStream) {
        console.log("New stream", newStream);
        this.agoraStream = newStream;
        let tempState = {}; //streamActive:attendeeMode};
        let videoIcons = this.state.videoIcons;
        videoIcons.video.isOn = !newStream['video'].muted;
        videoIcons.audio.isOn = !newStream['audio'].muted;
        tempState['videoIcons'] = videoIcons;
        console.log("tempState", tempState, this.state.streamActive);
        this.setState(tempState);
    }
    handleChangeAgoraEvent(eventType, eventInfo) {
        ll._("AGORA EVENT", "yellow", eventType, eventInfo);
    }

    handleCloseHelpQuestions() {
        this.setState({showHelpQuestions: false});
    }

    handleVideoClick(e) {
        let videoOn = this.state.videoIcons.video.isOn;
        if(this.agoraStream && this.agoraStream['video']) {
            if(!this.agoraStream['video'].muted) {
                this.agoraStream['video'].setMuted(true);
                videoOn = false;
            } else {
                this.agoraStream['video'].setMuted(false);
                videoOn = true;
            }
        } else {
            ll._("There is no Agora video stream", "red");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.video.isOn = videoOn;

        this.setState(videoIcons);
    }

    handleAudioClick(e) {
        let microphoneOn = this.state.videoIcons.audio.isOn;
        if(this.agoraStream && this.agoraStream['audio']) {
            if(!this.agoraStream['audio'].muted) {
                this.agoraStream['audio'].setMuted(true);
                microphoneOn = false;
            } else {
                this.agoraStream['audio'].setMuted(false);
                microphoneOn = true;
            }
        } else {
            ll._("There is no Agora audio stream", "red");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.audio.isOn = microphoneOn;

        this.setState(videoIcons);
    }
    handleShareScreenClick(e) {
        let shareScreen = this.state.videoIcons.tv.isOn;
        if(this.agoraStream) {
            if(shareScreen) {
                //this.agoraStream.disableAudio();
                shareScreen = false;
            } else {
                //this.agoraStream.enableAudio();
                shareScreen = true;
            }
        } else {
            console.log("There is no Agora stream >>>");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.tv.isOn = shareScreen;

        this.setState(videoIcons);
    }

    handleExitClick(e) {
        if (e.currentTarget.classList.contains("disabled")) {
          return;
        }
        try {
            if(this.agoraClient && this.agoraStream['video']) {
                ll._("Unpublishing video stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['video']);
            }
            if(this.agoraClient && this.agoraStream['audio']) {
                ll._("Unpublishing audio stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['audio']);
            }
            if(this.agoraClient) {
                window.agoraClient = agoraClient;
                this.agoraClient.leave();
            }
            // TODO: Enable when screen sharing is activated
            /*
            if (this.state.stateSharing) {
                this.shareClient && this.shareClient.unpublish(this.shareStream);
                this.shareStream && this.shareStream.close();
            }
            */
            if(this.agoraClient) {
                this.agoraClient.leave(
                    () => { alert("You have left the room."); },
                    () => { alert("Leave failed."); }
                );
            };
        } finally {
          this.setState({ readyState: false });
          this.client = null;
          this.localStream = null;
          // redirect to index
          window.location.reload();
        }
    }

    handleChatSignal(signal) {
        const pr = this.props;

        if(signal["cmd"] == "mute") {
            console.log("MUTE SIGNAL", signal);
            const itemSel = "#ag-item-" + signal['customer_id'];
            var ele = document.getElementById(itemSel);
            if(ele) {
                //console.log("FOUND", ele.muted);
                if(!ele.muted) {
                    window.clickStream(ele, true);
                }
            }

        } else if(signal["cmd"] == "unmute") {
            console.log("UNMUTE SIGNAL", signal);
            const itemSel = "#ag-item-" + signal['customer_id'];
            var ele = document.getElementById(itemSel);
            if(ele) {
                //console.log("FOUND", ele.muted);
                if(ele.muted) {
                    window.clickStream(ele, false);
                }
            }
        }

        if(signal["cmd"] == "badgenotify") {
            const signalId = signal['id'];
            const key = "s-" + signalId;

            if(!Utils.getCookie(key)) {
                Api.postAnalytics({signal_id: signalId, type: "display_notify"});

                let listing = null;
                let index = -1;

                for(let i = 0; i < pr.roomItem.listings.length; ++i) {
                    if(pr.roomItem.listings[i].id === signal.extra_id) {
                        listing = pr.roomItem.listings[i];
                        index = i;
                        break;
                    }
                }

                pr.handleShowRoomNotification(listing, signal);
                Utils.setCookie(key, 1);
            }
        }
    }

    showNotification() {
        console.log("WE GOT IT!!!!!!!!! >>>");
    }

    selectTab(chatboxContent) {
        this.setState({chatboxContent: chatboxContent})
    }

    closeSoinModal() {
        this.setState({showSionModal: false});
    }

    eventPageBack() {
        const pr = this.props;
        const event = pr.roomItem.event;

        let path = `event/${event.name}-${event.crc}`;
        pr.history.push(`/${path}`);
        pr.change('event', event);
        // pr.change(path);
    }

    backToEvents() {
        this.props.history.push("/home");
    }

    renderHeroEvent() {
        const sty = this.styles;
        const pr = this.props;

        return (
            <Div style={sty.eventBanner}>
                <Div style={{display:"flex"}}>
                    <Div style={{flex:1}}>
                        <Image style={{height:360}} src={pr.roomItem["image_url"]} />
                    </Div>
                    <Div style={{flex:1}}>
                        <Image src="/webapp/static/media/drops_transparent.22c9bc05.gif" style={{width: 250}} />
                        <Div style={Styles.headline}>The Weeknd</Div>
                        <Div style={Styles.subhead}>Blinding Lights</Div>
                        <Div style={Styles.subhead}>Launch party -- be a part of history</Div>
                    </Div>
                </Div>
                <Div style={sty.eventBannerInfobox}>
                    <Div style={{fontSize:24,fontWeight:"bold"}}>1000</Div>
                    <Div style={{fontSize:18,}}>seats</Div>
                    <Div style={{fontSize:64,fontWeight:"bold",lineHeight: "59px"}}>17</Div>
                    <Div style={{fontSize:12,}}>June</Div>
                </Div>
            </Div>
        );
    }

  renderHostTile(type, imageUrl, title, size) {
      const sty = this.styles;
      const st = this.state;

      let tileStyle = {width:640,borderRadius:8,overflow:"hidden"};
      let nameBoxStyle = this.styles.nameBox;
      if(size) {
          tileStyle['width'] = size;
          tileStyle['height'] = size;
          if(size <= 200) {
              nameBoxStyle = sty.nameBoxSmall;
          }

      } else if(type=="sub") {
          tileStyle['width'] = 200;
          tileStyle['height'] = 200;
          nameBoxStyle = sty.nameBoxSmall;
      }

      return true || type=="host" ?
              <Div key={`host-tile-${title}`} id="remote-container" ref={this.hostRef} style={sty.remoteContainer}>
                {st.hostPicture ?
                    <Div style={{width: "100%"}}>
                        {false ?
                            <Image style={tileStyle} src={imageUrl} />
                            :
                            this.renderVideobox()
                        }
                    </Div>
                    :
                    null
                }
            </Div>
            :
            <Div style={{position:"relative", margin: 10}}>
                <Image style={tileStyle} src={imageUrl} />
                <Div className="nameBox" style={nameBoxStyle}>
                    {title}
                </Div>
            </Div>
        ;
    }

    renderCustomer(guest) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const isMicrophone = guest["speak"];

        return (
            <CustomerProfile
                key={`ap_guest_${guest['id']}`}
                name={guest.full_name}
                username={guest.username}
                // image_url={guest.image_url}
                image_url={guest.photo}
                style={{width: "50%"}}
                microphoneIcon={isMicrophone}
                questionIcon
                size={56}
                width={pr.screen.width}
            />
        )
    }

    renderCustomers() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const host = pr.roomItem["host"];
        const cohosts = pr.roomItem["cohosts"];
        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventRowDateCol = StyleUtils.getMediaStyle('eventRowDateCol', sty.eventRowDateCol, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    Host
                </Div>
                {this.renderCustomer(host)}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    Co-Hosts ({cohosts.length})
                </Div>
                {cohosts.map((customerVip) => {
                    return this.renderCustomer(customerVip)
                })}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomer(customerVip)
                })}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomer(customer)
                })}
            </Div>
        )
    }

    chatAction(action, customerId) {
      console.log("Check", action, customerId);
      const roomCrc = Utils.getUrlCrc();
      let modalData = {
          action: action, // NOTE either: "kick", "block", "mute"
          roomCrc: roomCrc,
          customerId: customerId,
      };
      if(action == "mutev") {
          let mutes = this.state.muteVideo;
          mutes[customerId] = mutes[customerId] ? false : true;
          this.setState({muteVideo: mutes});
      }
      if(action == "mute") {
          let mutes = this.state.muteAudio;
          mutes[customerId] = mutes[customerId] ? false : true;
          this.setState({muteAudio: mutes});
          if(!mutes[customerId]) {
              ll._("Unmute", "green");
              modalData['action'] = "unmute";
          }
      }

      if(true) {
          Api.putRoomChatSignal(roomCrc, modalData, (response) => {
              console.log("response", response);
          });
      }
    }

    openSoinModal(listing, i) {
        console.log("openSoinModal", listing, i);
        this.setState({
            showSionModal: true,
            currentSoin: listing,
            currentSoinIndex: i,
        });
    }

    goToSignIn() {
        this.props.history.push('/login');
    }

    renderManageGuests(guests) {
        return guests.map( (guest) => {
            return (
                <Div key={`ap_guest_${guest['id']}`} style={{position:"relative", display:"flex"}}>
                    <Div style={{margin:8,borderRadius:8,overflow:"hidden",backgroundImage: 'url('+guest["photo"]+')',backgroundSize: "cover",height: 48, width:48,color: "#f5f5f5"}}>
                    </Div>
                    <Div style={{flex: 3,fontSize:10,textAlign:"center",marginTop:20,color:"black"}}>
                        {guest["username"]}
                    </Div>
                </Div>
            );
        });
    }

    renderStats() {
        return (
            <Div>
                <Div>Guests: {this.props.roomItem && this.props.roomItem["guests"] ? this.props.roomItem["guests"].length : 0}</Div>
                <Div>Guests online: {this.state.guestsOnline}</Div>
            </Div>
        );
    }

    handleOnlineUsersChange(onlineUsers) {
        const customerDict = Utils.arrayToObject(this.props.roomItem["customers"], "id");
        let ou = [];
        for(var idx in onlineUsers) {
            //console.log("rec", onlineUsers, idx, idx in customerDict);
            if(idx in customerDict) {
                ou.push(customerDict[idx]);
            }
        }
        this.setState({onlineUsers: ou});
    }

    renderHostControls() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const listings = pr.roomItem['listings'] ? pr.roomItem['listings'] : [];

        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
          <Div className="hostControls" style={null}>
              {this.renderHostTile("host", pr.roomItem["image_url"], pr.roomItem['insiders']['host']["username"], 200) }
              { this.state.agoraActive ?
                  <Div className="videoIconsContainer" style={sty.videoIconsContainer}>
                      {st.videoIcons.video.isShown ?
                          <Icon
                              style={sty.videoIconsIcon}
                              size={16}
                              icon={st.videoIcons.video.isOn ? "video" : "video-slash"}
                              onClick={this.handleVideoClick.bind(this)}
                          />
                          :
                          null
                      }

                      {st.videoIcons.audio.isShown ?
                          <Icon
                              style={sty.videoIconsIcon}
                              size={16}
                              icon={st.videoIcons.audio.isOn ? "microphone" : "microphone-slash"}
                              onClick={this.handleAudioClick.bind(this)}
                          />
                          :
                          null
                      }

                      {st.videoIcons.tv.isShown ?
                          <Icon
                              style={sty.videoIconsIcon}
                              size={16}
                              title="Share Screen"
                              icon={st.videoIcons.tv.isOn ? "tv" : "times"}
                              onClick={this.handleShareScreenClick.bind(this)}
                          />
                          :
                          null
                      }

                      {st.videoIcons.desktop.isShown && false ?
                          <Icon
                              style={sty.videoIconsIcon}
                              size={16}
                              title="Switch Display"
                              icon={st.videoIcons.desktop.isOn ? "desktop" : "power-off"}
                          />
                          :
                          null
                      }

                      {st.videoIcons.exit.isShown ?
                          <Icon
                              style={sty.videoIconsIcon}
                              size={16}
                              title="Leave"
                              icon="sign-out"
                              onClick={this.handleExitClick.bind(this)}
                          />
                          :
                          null
                      }

                      {pr.roomItem.insiders.host.id === pr.user.id ?
                          <Div style={sty.videoEndEventButton}>
                              <Icon title="End Event" icon="times" size={16} />
                          </Div>
                          :
                          null
                      }
                  </Div>
                  :
                  null
              }
              {false ?
                  <Div>
                      <Div style={Styles.subhead}>Moderators</Div>
                      <Div style={{display:"flex"}}>
                        {pr.roomItem['insiders']['moderators'].map( (mod) => {
                            return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
                        })}
                      </Div>
                  </Div>
                  :
                  null
              }
              <Div style={{...afterpartyRoomInfoContainer, ...{marginTop: 30}}}>
                  <Div
                    style={{...Styles.subhead, ...{marginBottom: 20}}}
                >
                    Admission Nfts
                </Div>
                  <Div style={sty.admissionSoins}>
                      <Scroller
                        items={this.renderSoinsInScroller()}
                        numSlots={6}
                        centerSlidePercentage={100}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        autoPlay={false}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        whiteArrows={true}
                        minHeight={120}
                        marginLeft={40}
                        scrollerStyle={{display: "flex", flexDirection: "row", width: "100%"}}
                    />
                  </Div>
              </Div>

              { false ?
                  <Div style={afterpartyRoomInfoContainer}>
                      <Div style={Styles.subhead}>Stats</Div>
                      {this.renderStats()}
                  </Div>
                  :
                  null
              }
          </Div>
      )
  }

    renderSoinsInScroller() {
      const listings = this.props.roomItem['listings'] ? this.props.roomItem['listings'] : [];
      return (
          listings.map((listing, i) => {
              return (
                  <Div
                      key={`${i}-${Math.random()}`}
                      style={{
                          clipPath:"url(#bloated-circle-125)",
                          width: 125,
                          height: 125,
                          marginRight: 20,
                          flexShrink: 0,
                          backgroundImage: `url(${listing['image_url']})`,
                          cursor: "pointer",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                      }}
                      onClick={this.openSoinModal.bind(this, listing, i)}
                  >
                  </Div>
              )
          })
      )
  }

    renderChatboxTabs(showHelpTab) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const tabStyle = StyleUtils.getMediaStyle('tabStyle', sty.tabStyle, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="tabsContainer" style={sty.tabsContainer}>
                <Div
                    style={st.chatboxContent == "messages" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "messages")}
                >
                    Messages
                </Div>
                <Div
                    style={st.chatboxContent == "participants" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "participants")}
                >
                    Participants
                </Div>
                {showHelpTab ?
                    <Div
                        style={st.chatboxContent == "help" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                        onClick={this.selectTab.bind(this, "help")}
                    >
                        Help
                    </Div>
                    :
                    null
                }
            </Div>
       );
  }

    renderCustomerInHostMode(guest, vip) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const isMicrophone = guest["speak"];

        const hostButtonStyles = {
            ...Styles.btn,
            ...Styles.btnTiny
        };

        const guestId = guest['id'];

        return (
            <Div style={{display: "flex", width: "100%", alignItems: "center"}}>
                <CustomerProfile
                    key={`ap_guest_${guestId}`}
                    name={guest.full_name}
                    username={guest.username}
                    image_url={guest.photo}
                    style={{marginRight: "auto"}}
                    microphoneIcon={isMicrophone}
                    size={56}
                    width={pr.screen.width}
                    questionIcon
                />
                { vip ?
                    <Div style={Styles.videoIconsContainer} title="Reload">
                        <Icon
                            style={Styles.videoIconsIcon}
                            size={16}
                            icon="recycle"
                            onClick={this.chatAction.bind(this, "reload", guestId)}
                        />

                    </Div>
                    :
                    null
                }
                { vip ?
                    <Div style={Styles.videoIconsContainer}>
                        <Icon
                            style={Styles.videoIconsIcon}
                            size={16}
                            icon={!st.muteVideo[guestId] ? "video" : "video-slash"}
                            onClick={this.chatAction.bind(this, "mutev", guestId)}
                        />

                    </Div>
                    :
                    null
                }
                { vip ?
                    <Div style={Styles.videoIconsContainer}>
                        <Icon
                            style={Styles.videoIconsIcon}
                            size={16}
                            icon={!st.muteAudio[guestId] ? "microphone" : "microphone-slash"}
                            onClick={this.chatAction.bind(this, "mute", guestId)}
                        />

                    </Div>
                    :
                    null
                }
                { false ?
                    <Button
                        style={{marginRight: 0}}
                        onClick={this.chatAction.bind(this, "block", guestId)}
                        color="black"
                        size="small"
                    >
                        Block
                    </Button>
                    :
                    null
                }
            </Div>
        )
    }

    renderCustomersInHostMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const host = pr.roomItem.insiders["host"];
        const cohosts = pr.roomItem.insiders["cohosts"];
        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));

        // NOTE: USING THIS ONE
        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                {st.isMod ?
                    <Button
                        style={{marginTop: 20}}
                        onClick={this.chatAction.bind(this, "reload")}
                    >
                        Reload All
                    </Button>
                    :
                    null
                }
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    Host
                </Div>
                {/* {this.renderCustomer(host)} */}
                {this.renderCustomerInHostMode(host, false)}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    Co-Hosts ({cohosts.length})
                </Div>
                {cohosts.map((cohost) => {
                    // return this.renderCustomer(cohost)
                    return this.renderCustomerInHostMode(cohost, false)
                })}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomerInHostMode(customerVip, true)
                })}
                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomerInHostMode(customer, false)
                })}
            </Div>
        )
    }

  renderVideobox() {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      let attendeeMode = Utils.get(pr.roomItem, "insiders.isInsider") ? "video" : "audience";
      if(st.mode == 'host') {
          attendeeMode = 'video';
      }
      //attendeeMode = 'video';
      const host = Utils.get(pr.roomItem, "insiders.host", []);
      const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);
      const customers = Utils.get(pr.roomItem, "customers", []);
      const customersVip = Utils.get(pr.roomItem, "customers_vip", []);
      const vipDict = Utils.arrayToObject(customersVip, 'id');
      if(pr.user.id in vipDict) {
          attendeeMode = 'video';
      }
      let users = Utils.arrayToObject([host], 'id');
      Utils.arrayToObject(cohosts, 'id', users);
      Utils.arrayToObject(customers, 'id', users);
      Utils.arrayToObject(customersVip, 'id', users);
      //ll._("renderVideobox agora with users (host/customer/vip): ", "blue", users);
      return (
          <Div style={sty.videoContainer} className="AgoraCall">
              <AgoraVideoCall
                key={`agora-${st.agoraKey}`}
                screen={pr.screen}
                videoProfile={this.videoProfile}
                channel={this.channel}
                transcode={this.transcode}
                attendeeMode={attendeeMode}
                baseMode={this.baseMode}
                appId={this.appId}
                uid={this.uid}
                displayMode={'afterparty'}
                customerId={Utils.get(this.props.user, 'id')}
                changeLocalStream={this.handleChangeAgoraStream.bind(this)}
                changeClient={this.handleChangeAgoraClient.bind(this)}
                users={users}
                host={host}
                cohosts={cohosts}
                customersVip={customersVip}
                agoraActive={st.agoraActive}
                agoraEvents={this.handleChangeAgoraEvent.bind(this)}
              />
          </Div>
      )
     }

    renderCustomersInChatboxHostMode() {
        const pr = this.props;
        const sty = this.styles;

        // {pr.roomItem['insiders']['cohosts'].map((host) => {
        //     return this.renderHostTile("sub", host["photo"], host["username"], 100);
        // })}
        // {pr.roomItem['insiders']['mods'].map((mod) => {
        //     return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
        // })}

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem.customers || pr.roomItem.customers_vip ?
                    this.renderCustomersInHostMode()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    handleSubscribeClick(customer) {
        const st = this.state;
        const pr = this.props;

        const isCurrentlyFollowing = pr.user.following[customer.id];

        const data = {
            from_customer_id: pr.user.id,
            to_customer_id: customer.id,
            follow: !isCurrentlyFollowing ? true : false,
        };

        Api.postFollow(data, (response) => {
            if(response.errors.length) {
                console.log("ERROR", response.data.errors);
                return;
            }

            let user = JSON.parse(JSON.stringify(pr.user));
            user.following = response.data;

            pr.setUser(user);
        });
    }

    renderBackToEventAndProfile() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const buyListing = this.getBuyListing();

        const searchAndProfileContainer = StyleUtils.getMediaStyle('searchAndProfileContainer', sty.searchAndProfileContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        return (
            <Div className="searchAndProfileContainer" style={searchAndProfileContainer}>
                <Div className="eventPageBack" style={sty.eventPageBack} onClick={this.eventPageBack.bind(this)}>
                    <Div style={sty.eventIconCircle}>
                        <Icon icon="chevron-left" size={16} style={sty.chevronIcon} />
                    </Div>

                     {pr.screen.width > StyleUtils.iphoneSmallWidth ?
                         <Div style={{marginTop: 2, marginRight:20}}>Event Page</Div>
                         :
                         null
                     }
                </Div>
                <AfterpartyRoomBadges
                    style={{marginLeft: "auto"}}
                    showRoomNotification={pr.showRoomNotification}
                    badgeNotifications={pr.badgeNotifications}
                    currentRoomSignal={pr.currentRoomSignal}
                    // handleBuyClick={pr.handleBuyClick.bind(this)}
                    handleClickRoomNotification={pr.handleClickRoomNotification.bind(this)}
                    handleShowFollowModal={pr.handleShowFollowModal.bind(this)}
                    handleCloseRoomNotification={pr.handleCloseRoomNotification.bind(this)}
                    roomItem={pr.roomItem}
                    user={pr.user}
                />
                 {/* {buyListing ?
                     <Div style={{flex: 1}}>
                        {buyListing['bitclout_current_price'] && buyListing['bitclout_current_price'].length > 1 ?
                             <Button
                                 className="pulse2"
                                 onClick={this.handleBuyClick.bind(this)}
                                 color="white"
                                 size="small"
                                 noBorder
                             >
                                 {buyListing['bitclout_username']} - {buyListing['bitclout_current_price']} coin price BUY
                             </Button>
                             :
                             <Button
                                 className="pulse2"
                                 onClick={this.handleBuyClick.bind(this)}
                                 color="white"
                                 size="small"
                                 noBorder
                             >
                                 Buy {buyListing['bitclout_username']} coin
                             </Button>
                        }
                     </Div>
                     :
                     null
                 } */}

                 {/* {pr.screen.width > StyleUtils.iphoneSmallWidth ?
                     <Div style={{flex: 1}}>
                         {st.subscribeSuccess ?
                             <Div
                                 style={{marginLeft: "auto", width: "fit-content", paddingTop: 5,  paddingRight: 20, paddingBottom: 5, paddingLeft: 20, fontWeight: "bold"}}
                             >
                                 Subscribed to {Utils.get(pr, "roomItem.artist.title")}
                             </Div>
                             :
                             <Button
                                 style={{marginLeft: "auto", width: "fit-content", paddingLeft: 20, paddingRight: 20}}
                                 className="pulse2"
                                 onClick={this.handleSubscribeClick.bind(this)}
                                 color="purpleLight1"
                                 size="small"
                             >
                                 Subscribe to {Utils.get(pr, "roomItem.artist.title")}
                             </Button>

                         }
                     </Div>
                     :
                     null
                 } */}
            </Div>
        )
    }

    renderChatboxContent() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const helpHtml = Utils.get(pr.roomItem, "help.body", "");

        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const blogBody = StyleUtils.getMediaStyle('blogBody', sty.blogBody, sty, StyleUtils.getWidthType(pr.screen.width));

        switch(st.chatboxContent) {
            case "messages":
                return (
                    <Chatbox
                        screen={pr.screen}
                        roomId={pr.roomItem["id"]}
                        roomChats={pr.roomItem["roomChats"]}
                        customers={pr.roomItem["customers"]}
                        badgeTypes={pr.roomItem["badgeTypes"]}
                        handleOnlineUsersChange={this.handleOnlineUsersChange.bind(this)}
                        scrollContainerStyle={scrollContainerStyle}
                        handleSignal={this.handleChatSignal.bind(this)}
                        customerId={Utils.get(this.props.user, 'id')}
                        mostRecentMessage={this.handleMostRecentMessage.bind(this)}
                        width={pr.screen.width}
                    />
                )
                break;
            case "participants":
                return this.renderCustomersInChatboxHostMode()
                break;
            case "help":
                return (
                    <Div
                        style={blogBody}
                        dangerouslySetInnerHTML={{ __html: helpHtml }}
                    />
                )
                break;
        }
    }

    renderHostMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const hostModeContainer = StyleUtils.getMediaStyle('hostModeContainer', sty.hostModeContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxContainer = StyleUtils.getMediaStyle('chatboxContainer', sty.chatboxContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const videoNftContainer = StyleUtils.getMediaStyle('videoNftContainer', sty.videoNftContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxHeader = StyleUtils.getMediaStyle('chatboxHeader', sty.chatboxHeader, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="hostModeContainer" style={hostModeContainer}>
                <Div className="videoNftContainer" style={videoNftContainer}>
                    {this.renderBackToEventAndProfile()}
                    {this.renderTestInfo()}
                    {this.renderJoinMeeting()}
                    {this.renderMostRecentMessage()}

                    {this.renderHostControls()}
                </Div>

                <Div className="chatboxContainer" style={chatboxContainer}>
                    <Div className="chatboxHeader" style={chatboxHeader}>
                        <Div style={{fontSize: 20}}>
                            {this.renderRoomTitles()}
                        </Div>
                        {this.renderChatboxTabs(true)}
                    </Div>
                    {this.renderChatboxContent()}
                </Div>
            </Div>
        );
    }

    renderCustomersInChatboxGuestMode() {
        const pr = this.props;
        const sty = this.styles;

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem["customers"] || pr.roomItem["customers_vip"] ?
                    this.renderCustomers()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    renderRoomTitles() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const start = moment('8:57:00','HH:mm:ss');
        const hoursPassed = moment().diff(start, 'hours');
        const minutesPassed = moment().diff(start, 'minutes') % 60;
        const secondsPassed = moment().diff(start, 'seconds') % 60;
        const artistTitle = Utils.get(pr, "roomItem.artist.title");
        const username = Utils.get(pr, "user.username");

        const roomTitleContainer = StyleUtils.getMediaStyle('roomTitleContainer', sty.roomTitleContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventTitle = StyleUtils.getMediaStyle('eventTitle', sty.eventTitle, sty, StyleUtils.getWidthType(pr.screen.width));
        const artistTitleStyle = StyleUtils.getMediaStyle('artistTitle', sty.artistTitle, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="roomTitleContainer" style={roomTitleContainer}>
                <Div style={{width: "100%"}}>
                    <Div style={eventTitle}>
                        {pr.roomItem.event.title}
                    </Div>
                    <Div style={artistTitleStyle}>
                        {artistTitle}
                    </Div>
                </Div>
                {/* <span style={sty.eventTimeDuration}>
                    The Event has been happening for: <span style={{fontWeight: "bold"}}>{hoursPassed}h {minutesPassed}m {secondsPassed}s</span>
                </span> */}
            </Div>
        )
    }
    renderTestInfo() {
        if(!this.state.testMode) {
            return null;
        }
        let attendeeMode = Utils.get(this.props.roomItem, "insiders.isInsider") ? "Insider" : "Guest";

        return (
            <Div style={{marginTop:8,display:"flex", backgroundColor: Colors.purpleDark, padding: 10, borderRadius: 8, marginBottom:8}}>
                <Div style={{flex:1, display:"flex",}}>
                    Agora Active: {this.state.agoraActive ? <Div style={{marginLeft: 8,color:"green"}}>Yes</Div> : <Div style={{marginLeft: 8,color:"red"}}>No</Div> }
                </Div>
                <Div style={{flex:1, display:"flex",}}>Customer ID: {Utils.get(this.props.user, 'username')} / {Utils.get(this.props.user, 'id')}</Div>
                <Div style={{flex:1}}>Agora Client ID: {this.state.clientId ? this.state.clientId : "Null"}</Div>
                <Div style={{flex:1}}>LocalStream: {this.state.streamActive ? this.state.streamActive : "Null"}</Div>
                <Div style={{flex:1}}>Mode: {attendeeMode}</Div>
                <Div style={{flex:1}}>Width: {StyleUtils.getWidthType(this.state.width)}</Div>
            </Div>
        );
    }
    renderJoinMeeting() {
        const joinMessage = Utils.get(this.props.roomItem, "event.join_message");
        return !this.state.agoraActive ?
            <Div>
                { joinMessage && joinMessage.length > 1 ?
                    <Div style={{fontSize:18, textAlign:"center", marginBottom:18}}>
                        {joinMessage}
                    </Div>
                    :
                    null
                }
                <Button
                    className="pulse"
                    size="small"
                    style={{marginBottom: 30, marginLeft: "auto", marginRight: "auto", maxWidth: 300, width: "100%"}}
                    onClick={this.handleStartAgora.bind(this)}>
                    Join Afterparty Event
                </Button>
            </Div>
            :
            null;
    }

    handleMostRecentMessage(message) {
        this.setState({mostRecentMessage: message});
    }

    changePost(e) {
        this.setState({post: e.target.value});
    }

    keyDownPost(e) {
        if(e.key === 'Enter') {
            this.handleChatPost();
            return;
        }
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }
        let data = {
            body: st.post,
            room_id: pr.roomId,
        };
        this.setState({posting: true});

        Api.postRoomChat(data, (o) => {
             if(o && o.data) {
                 this.setState({messages: o.data, post: "", lastPost: st.post, posting: false}, () => {
                     const objDiv = document.getElementById('scrollBottom');
                     objDiv.scrollTop = objDiv.scrollHeight;
                 });
             }
        });
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }

        let data = {
            body: st.post,
            room_id: pr.roomItem["id"],
        };
        this.setState({posting: true});

        Api.postRoomChat(data, (o) => {
             if(o && o.data) {
                 this.setState({mostRecentMessage: o.data[o.data.length - 1], post: "", lastPost: st.post, posting: false});
             }
        });
    }

    renderUsername(message, customerDict) {
        let username = message['customer_id'];
        if(message['username']) {
            username = message['username'];
        } else if(message['customer_id'] in customerDict) {
            username = customerDict[message['customer_id']]['username'];
        }
        return username;
    }

    renderAvatar(message, customerDict) {
        const sty = this.styles;

        let avatarStyle = null;
        if(message['customer_id'] in customerDict && customerDict[message['customer_id']]['photo']) {
            avatarStyle = {...sty.customerIcon, ...{backgroundImage: `url(${customerDict[message['customer_id']]['photo']})`},};
        } else {
            const avatarColor = Colors.colorIndex[message['customer_id'] % Colors.colorIndex.length];
            //console.log("COLORS", Colors.colorIndex, avatarColor, message['customer_id']);
            avatarStyle = {...sty.customerIcon, ...{backgroundColor: avatarColor},};
        }
        return <Div style={avatarStyle}></Div>
    }

    renderBadges(message, customerDict) {
        const pr = this.props;
        const badgeTypes = pr.roomItem["badgeTypes"];

        let badges = null;

        if(message['customer_id'] in customerDict && badgeTypes && customerDict[message['customer_id']]['badges'].length > 0 ) {
            const badgeList = customerDict[message['customer_id']]['badges'];
            let activeBadges = [];
            for(var idx in badgeList) {
                if(badgeList[idx] in badgeTypes) {
                    activeBadges.push(<img key={`${message.id}-${Math.random()}`} style={{width:24,height:24,padding:2}} src={badgeTypes[badgeList[idx]]['image_url']} />)
                }
            }
            if(activeBadges.length > 0) {
                badges = activeBadges;
            }
        }
        return badges;
    }

    handleToggleMostRecent() {
        this.setState({expandMostRecentMessageInput: !this.state.expandMostRecentMessageInput});
    }

    addEmojiToChat(emoji) {
        this.setState({post: this.state.post += emoji});
    }

    renderMostRecentMessage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customerDict = Utils.arrayToObject(pr.roomItem["customers"], "id");

        // const roundedBorders = !st.expandMostRecentMessageInput ? {borderBottomLeftRadius: 22, borderBottomRightRadius: 22, overflow: "hidden"} : null;
        const roundedBorders = {borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: "hidden"};

        const inputContainer = StyleUtils.getMediaStyle('inputContainer', sty.inputContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const messageContainer = StyleUtils.getMediaStyle('messageContainer', sty.messageContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const messageStyle = StyleUtils.getMediaStyle('message', sty.message, sty, StyleUtils.getWidthType(pr.screen.width));
        const emojiRow = StyleUtils.getMediaStyle('emojiRow', sty.emojiRow, sty, StyleUtils.getWidthType(pr.screen.width));

        if(pr.screen.width > 992) {
            return null;
        }

        const messageTime = Utils.getTimeFromDate(st.mostRecentMessage['created_at']);

        return (
            <Div style={{...{marginTop: -10, marginBottom: 15, borderTopLeftRadius: 8, borderTopRightRadius: 8}, ...roundedBorders}}>
                <Div style={{display: "flex"}}>
                    <Div className="messageContainer" key={`msg-${st.mostRecentMessage['id']}`} style={messageContainer}>
                        <Div style={sty.customerInfoContainer}>
                            {this.renderBadges(st.mostRecentMessage, customerDict)}
                            {this.renderAvatar(st.mostRecentMessage, customerDict)}
                            <Div style={sty.customerName}>
                                {this.renderUsername(st.mostRecentMessage, customerDict)}
                            </Div>
                            <Div style={sty.messageDate}>
                                {messageTime}
                            </Div>
                        </Div>
                        <Div className="message" style={messageStyle}>
                            {st.mostRecentMessage['body']}
                        </Div>
                    </Div>
                    {st.expandMostRecentMessageInput ?
                        <Icon
                            icon="minus"
                            size={24}
                            onClick={this.handleToggleMostRecent.bind(this)}
                            style={{...sty.plusMinusButton, ...StylesPlatform.cursorPointer}}
                        />
                        :
                        <Icon
                            icon="plus"
                            size={24}
                            onClick={this.handleToggleMostRecent.bind(this)}
                            style={{...sty.plusMinusButton, ...StylesPlatform.cursorPointer}}
                        />
                    }
                </Div>
                {st.expandMostRecentMessageInput ?
                    <Div className="inputContainer" style={inputContainer}>
                        <Div className="emojiRow" style={emojiRow}>
                            {this.emojis.map((emoji, emojiIndex) => {
                                return (
                                    <Div
                                        key={`emoji-${emoji}`}
                                        className={`emoji-${emoji}`}
                                        style={{...sty.emoji, ...StylesPlatform.cursorPointer}}
                                        onClick={this.addEmojiToChat.bind(this, emoji)}
                                    >
                                        {emoji}
                                    </Div>
                                )
                            })}
                        </Div>
                        <Div className="inputAndSendIconContainer" style={sty.inputAndSendIconContainer}>
                            <Input
                                value={st.post}
                                onChange={this.changePost.bind(this)}
                                onKeyDown={this.keyDownPost.bind(this)}
                                style={{marginBottom: 0, marginRight: 10, padding: "7px 20px 6px", borderRadius: 8}}
                                placeholder="Type a message"
                            />
                            <Icon
                                icon="paper-plane"
                                size={24}
                                onClick={this.handleChatPost.bind(this)}
                                style={{color: "black"}}
                            />
                        </Div>
                    </Div>
                    :
                    null
                }
            </Div>
        )
    }

    renderGuestMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomItem) {
            return null;
        }

        let artistName = Utils.get(pr.roomItem, 'artist.title')
        const guestCount = pr.roomItem && pr.roomItem["guests"] ? pr.roomItem["guests"].length : 0;
        let hosts = Utils.get(pr.roomItem, 'insiders.mods');
        hosts = hosts ? hosts : [];

        const listings = this.props.roomItem['listings'] ? this.props.roomItem['listings'] : [];

        const guestModeContainer = StyleUtils.getMediaStyle('guestModeContainer', sty.guestModeContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxContainer = StyleUtils.getMediaStyle('chatboxContainer', sty.chatboxContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const videoNftContainer = StyleUtils.getMediaStyle('videoNftContainer', sty.videoNftContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxHeader = StyleUtils.getMediaStyle('chatboxHeader', sty.chatboxHeader, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="guestModeContainer" style={guestModeContainer}>
                <Div className="videoNftContainer" style={videoNftContainer}>
                    <Div style={{maxWidth: 1600, width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        {this.renderBackToEventAndProfile()}
                        {this.renderTestInfo()}

                        {this.renderJoinMeeting()}

                        {this.renderMostRecentMessage()}
                        <Div>
                            {this.renderHostTile("host", pr.roomItem["image_url"], artistName)}
                        </Div>
                        <Div style={{display: "flex"}}>
                            {hosts.map((host) => {
                                return this.renderHostTile("sub", host["photo"], host["username"], 100);
                            })}
                        </Div>

                        <Div style={{...afterpartyRoomInfoContainer, ...{marginTop: 30}}}>
                            <Div
                                onClick={this.handleStartAgora.bind(this)}
                                style={{...Styles.subhead, ...{marginBottom: 20}}}
                            >
                                Admission Nfts
                            </Div>
                            <Div style={sty.admissionSoins}>
                              {listings.map((listing, i) => {
                                  return (
                                      <Div
                                          key={`listing-${listing['id']}`}
                                          style={{
                                              clipPath:"url(#bloated-circle-125)",
                                              width: 125,
                                              height: 125,
                                              marginRight: 20,
                                              flexShrink: 0,
                                              backgroundImage: `url(${listing['image_url']})`,
                                              cursor: "pointer",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              backgroundRepeat: "no-repeat",
                                          }}
                                          onClick={this.openSoinModal.bind(this, listing, i)}
                                      >
                                      </Div>
                                  )
                              })}
                            </Div>
                        </Div>
                    </Div>
                </Div>

                <Div className="chatboxContainer" style={chatboxContainer}>
                    <Div className="chatboxHeader" style={chatboxHeader}>
                        <Div style={{fontSize: 20}}>
                            {this.renderRoomTitles()}
                        </Div>
                        {this.renderChatboxTabs(false)}
                    </Div>
                    {st.chatboxContent == "messages" ?
                        <Chatbox
                            screen={pr.screen}
                            roomId={pr.roomItem["id"]}
                            roomChats={pr.roomItem["roomChats"]}
                            customers={pr.roomItem["customers"]}
                            badgeTypes={pr.roomItem["badgeTypes"]}
                            handleOnlineUsersChange={this.handleOnlineUsersChange.bind(this)}
                            scrollContainerStyle={scrollContainerStyle}
                            handleSignal={this.handleChatSignal.bind(this)}
                            customerId={Utils.get(pr.user, 'id')}
                            mostRecentMessage={this.handleMostRecentMessage.bind(this)}
                            width={pr.screen.width}
                        />
                        :
                        this.renderCustomersInChatboxGuestMode()
                    }
                </Div>
            </Div>
        );
    }

    renderRoomNotFound() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomLoaded) {
            return null;
        }

        return (
            <Div style={sty.roomNotFound}>
                {Utils.get(pr.user, "email", false) ?
                    <>
                        <Div style={{textAlign: "center"}}>Room Not Found</Div>
                        <Button style={{marginTop: 10}} onClick={this.backToEvents.bind(this)}>
                            Back To Events
                        </Button>
                    </>
                    :
                    <>
                        <Div style={{textAlign: "center"}}>Room Not Accessible</Div>
                        <Button style={{marginTop: 10}} onClick={this.goToSignIn.bind(this)}>
                            Please Sign In
                        </Button>
                    </>
                }
            </Div>
        )
    }

    renderModes(isInsider, mode) {
        if(isInsider || mode == 'host') {
            return this.renderHostMode()
        } else {
            return this.renderGuestMode()
        }
    }

    getBuyListing() {
        let buyListing = false;
        const listings = Utils.get(this.props.roomItem, 'listings', []);
        for(var idx in listings) {
            const listing = listings[idx];
            if(Utils.get(listing, "bitclout_username") && listing['id'] != 75) {
                buyListing = listing;
                break;
            }
        }
        return buyListing;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");
        let buyListing = this.getBuyListing();

        const host = Utils.get(pr.roomItem, "insiders.host", {username: ""});
        const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);

        return (
            <Div className="afterpartyRoomComponent" style={sty.afterpartyRoomComponent}>
                <BloatedCircleDefs
                    scales={[50, 100, 125, 200]}
                />
                <DebugBox
                    show={this.state}
                    />

                {pr.roomItem && !pr.roomNotFound ?
                    this.renderModes(isInsider, st.mode)
                    :
                    this.renderRoomNotFound()
                }

                {st.showSionModal ?
                    <ModalNFT
                        screen={pr.screen}
                        nft={st.currentSoin}
                        onRequestClose={this.closeSoinModal.bind(this)}
                    />
                    :
                    null
                }

                {st.showProfileModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeProfileModal.bind(this)}
                        color="purple"
                        wide
                    >
                        <Profile
                            screen={screen}
                            style={{padding: "20px 0 0"}}
                            hidePageBack
                            user={pr.user}
                            history={pr.history}
                            userLoaded={pr.userLoaded}
                        />
                    </Modal>
                    :
                    null
                }
                {pr.showBitCloutBuyModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={pr.handleBuyModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <iframe style={sty.buyIframe} src={`https://bitclout.com/u/${Utils.get(buyListing, "bitclout_username","afterparty")}/buy`} />
                        </Div>
                    </Modal>
                    :
                    null
                }

                {pr.showFollowModal ?
                    <Modal
                        screen={pr.screen}
                        title={<><Image src={followUser} style={{width: 20, marginBottom: 6}} /> &nbsp;Following</>}
                        onRequestClose={pr.handleFollowModalClose.bind(this)}
                        color="purple"
                        style={{maxWidth: 500}}
                    >
                        <Div style={sty.followSubtitles}>Host</Div>
                        <Div className="followRow" style={sty.followRow}>
                            <Div
                                className="customerPhoto"
                                style={{...sty.followImage, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                            ></Div>
                            {host.username}
                            <Button
                                style={{marginLeft: "auto"}}
                                color={pr.user.following[host.id] ? "magenta" : "white"}
                                size="small"
                                onClick={this.handleSubscribeClick.bind(this, host)}
                            >
                                {pr.user.following[host.id] ? "Following" : "Follow"}
                            </Button>
                        </Div>

                        {cohosts.length > 0 ?
                            <Div style={sty.followSubtitles}>Co-Hosts</Div>
                            :
                            null
                        }
                        {cohosts.map((cohost, cohostIndex) => {
                            const isFollowing = pr.user.following[cohost.id];

                            return (
                                <Div key={`cohost-${cohostIndex}`} className="followRow" style={sty.followRow}>
                                    <Div
                                        className="customerPhoto"
                                        style={{...sty.followImage, ...{backgroundImage: `url('${cohost.photo ? cohost.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                    ></Div>
                                    {cohost.username}
                                    <Button
                                        style={{marginLeft: "auto"}}
                                        color={isFollowing ? "magenta" : "white"}
                                        size="small"
                                        onClick={this.handleSubscribeClick.bind(this, cohost)}
                                        noBorder
                                    >
                                        {isFollowing ? "Following" : "Follow"}
                                    </Button>
                                </Div>
                            )
                        })}
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }


    styles = {
        afterpartyRoomComponent: {
            minHeight: "100vh",
            backgroundColor: Colors.eggplant,
            color: "white",
        },
        videoIconsContainer: {
            display: "flex",
            paddingTop: 20,
            justifyContent: "center",
            alignItems: "center",
        },
        videoIconsIcon: {
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
            margin: "0 10px",
            cursor: "pointer",
        },
        remoteContainer: {
            position: "relative",
            margin: 0,
            borderRadius: 10,
            overflow: "hidden",
            justifyContent: "center",
        },
        app: {
            backgroundColor: "#000",
            textAlign: "left",
            fontFamily: "'Montserrat', sans-serif",
        },
        leftCol: {
            minHeight: 850,
            maxWidth: 220,
            backgroundColor:"white",
            paddingLeft: 0,
            paddingRight: 0,
        },
        leftColBody: {
            paddingTop: 20,
            justifyContent: "center",
            display: "flex",
            flexDirection:"col",
        },
        rightCol: {
            paddingLeft:0,
            background:"#FBF8F4",
            minHeight:800,
            color:"black",
            padding: 20,
            width: "100%",
        },
        eventBanner: {
            position: "relative",
            width: "100%",
        },
        eventBannerInfobox: {
            color: "white",
            position: "absolute",
            bottom: 55,
            left: 47,
            textAlign: "center",
        },
        nameBox: {
            position:"absolute",
            bottom: 50,
            left: 0,
            width: 200,
            height: 26,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        nameBoxSmall: {
            position:"absolute",
            bottom: 20,
            left: 0,
            width: 100,
            height: 23,
            fontSize: 10,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        videoContainer: {
            // minWidth: 565,
            //minHeight: 400,
            // background: "rgba(255,255,255,0.1)",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 36,
        },
        hostModeContainer: {
            display: "flex",
            flexWrap: "wrap",
            padding: "20px 40px",
            // maxWidth: 1600,
            marginLeft: "auto",
            marginRight: "auto",
        },
        hostModeContainer_lg: {
            padding: "20px 20px",
        },
        hostModeContainer_md: {
            padding: "20px 20px",
            flexDirection: "column",
        },
        hostModeContainer_xs: {
            padding: "5px 10px",
        },
        videoNftContainer: {
            display: "flex",
            flexDirection: "column",
            width: "calc(100vw - 590px)",
        },
        videoNftContainer_lg: {
            width: "calc(100vw - 560px)",
        },
        videoNftContainer_md: {
            width: "100%",
        },
        videoNftContainer_sm: {
            width: "100%",
        },
        videoNftContainer_xs: {
            width: "100%",
        },
        roomTitleContainer: {
            display: "flex",
            padding: "10px 0 0 0",
        },
        roomTitleContainer_xs: {
            padding: "0",
        },
        eventTitle: {
            fontWeight: "bold",
            fontSize: 18,
        },
        eventTitle_xs: {
            fontSize: 12,
        },
        artistTitle: {
            fontSize: 40,
            fontWeight: "bold",
            marginBottom: 15,
        },
        artistTitle_xs: {
            fontSize: 26,
        },
        eventTimeDuration: {
            padding: "8px 14px",
            borderRadius: 6,
            backgroundColor: Colors.purpleBackground,
            fontSize: 12,
        },
        guestModeContainer: {
            display: "flex",
            flexWrap: "wrap",
            padding: "20px 40px",
            // maxWidth: 1600,
            marginLeft: "auto",
            marginRight: "auto",
        },
        guestModeContainer_lg: {
            padding: "20px 20px",
        },
        guestModeContainer_md: {
            padding: "20px 20px",
            flexDirection: "column",
        },
        guestModeContainer_sm: {
            padding: "10px 12px",
        },
        guestModeContainer_xs: {
            padding: "10px 12px",
        },

        nftSoinContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
        },

        searchAndProfileContainer: {
            display: "flex",
            padding: "10px 0 0",
            alignItems: "center",
            marginBottom: 25,
            flexWrap: "none",
        },
        searchAndProfileContainer_md: {
            padding: "3px 0 0",
        },
        searchAndProfileContainer_xs: {
            flexWrap: "wrap",
            padding: "10px 0 0",
        },

        searchContainerStyle: {
            maxWidth: 250,
        },
        eventPageBack: {
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            cursor: "pointer",
            whiteSpace: "nowrap",
        },
        eventIconCircle: {
            borderRadius: "100%",
            height: 27,
            width: 27,
            backgroundColor: Colors.purpleLightBackground,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
        },
        chevronIcon: {
            fontSize: 18,
            marginLeft: -2,
        },
        profile: {
            width: 50,
            height: 50,
            backgroundColor: Colors.purpleLightBackground,
            borderRadius: 15,
            marginLeft: "auto",
        },

        customersContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
            flexDirection: "column",
        },

        hostsAndCohosts: {
            display: "flex",
            flexWrap: "wrap",
        },

        afterpartyRoomInfoContainer: {
            backgroundColor: Colors.purpleBackground,
            padding: 30,
            borderRadius: 24,
            marginBottom: 30,
         },
         afterpartyRoomInfoContainer_md: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_sm: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_xs: {
             padding: 20,
         },

         admissionSoins: {
            display: "flex",
            //overflowX: "scroll",
            overflow: "hidden",
         },

        chatboxContainer: {
            // flex: 4,
            backgroundColor: "white",
            borderRadius: 22,
            color: "black",
            //height: 804,
            marginLeft: 20,
            maxWidth: 500,
            width: "100%",
            // height: 866,
            height: "100vh",
            position: "fixed",
            right: 20,
            height: "calc(100vh - 37px)",
            zIndex: 1000
        },
        chatboxContainer_md: {
            marginLeft: 0,
            marginBottom: 5,
            maxWidth: "100%",
            height: "auto",
            position: "unset",
        },
        chatboxContainer_sm: {
            position: "unset",
        },
        chatboxContainer_xs: {
            position: "unset",
        },
        scrollContainerStyle_lg: {
            padding: "15px 15px 0 15px",
        },
        chatboxHeader: {
            padding: "20px 20px 15px 20px",
            borderBottom: `1px solid ${Colors.purpleLight}`,
        },
        chatboxHeader_xs: {
            padding: "20px 15px 0 15px",
        },
        tabsContainer: {
            display: "flex",
            width: "100%",
            // backgroundColor: Colors.purpleLight,
            backgroundColor: Colors.purpleLightest,
            borderRadius: 14,
            fontSize: "bold",
            color: Colors.purple,
            marginBottom: 15,
        },
        tabSelected: {
            backgroundColor: Colors.purpleLighter,
            borderRadius: 14,
        },
        tabStyle: {
            cursor: "pointer",
            padding: 15,
            width: "50%",
            textAlign: "center",
        },
        tabStyle_xs: {
            padding: 10,
        },

        innerParticipantsContainer: {
            display: "flex",
            flexWrap: "wrap",
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 254px)",
            padding: "0 24px 24px 24px",
        },
        innerParticipantsContainer_xs: {
            flexDirection: "column",
            flexWrap: "nowrap",
            height: 452,
            padding: "0 15px 15px 15px",
        },

        roomNotFound: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 48,
            color: "white",
            backgroundColor: Colors.purple,
            width: "100%",
            height: "100vh",
        },
        blogBody: {
            overflow: "scroll",
            height: "calc(100vh - 254px)",
            paddingTop: 20,
            paddingRight: 20,
        },
        blogBody_xs: {
            height: 452,
        },

        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 10,
        },
        messageContainer: {
            padding: 10,
            backgroundColor: "white",
            color: "black",
            wordWrap: "break-word",
            width: "100%",
        },
        messageContainer_xs: {
        },
        customerInfoContainer: {
            display: "flex",
            alignItems: "center",
        },
        customerIcon: {
            overflow: "hidden",
            backgroundSize: "cover",
            width: 18,
            height: 18,
            backgroundColor: Colors.purpleLight,
            marginRight: 10,
            borderRadius: 4,
        },
        customerName: {
            fontWeight: "bold",
            color: "#6B63BB",
            marginRight: 5,
            fontSize: 16,
        },
        messageDate: {
            color: "gray",
            fontSize: 10,
            marginTop: 1,
        },
        message: {
            fontSize: 14,
            color: "#555",
            marginLeft: 5,
        },
        message_xs: {
            fontSize: 12,
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 15,
        },
        plusMinusButton: {
            color: "black",
            padding: 10,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "15px 20px 20px",
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
        },
        inputContainer_xs: {
            padding: "8px 15px 15px",
        },
        inputAndSendIconContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
        },
        emojiRow: {
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            fontSize: 24,
            padding: "0 10px 6px",
        },
        emoji: {
            padding: "0 8px"
        },
        buyIframeContainer: {
            width:"100%",
            height: 500,
            overflowX: "hidden",
            overflowY: "scroll",
        },
        buyIframe: {
            width:"100%",
            height:600,
            borderRadius:32,
        },
        followSubtitles: {
            marginRight: "auto",
            marginBottom: 10,
            fontSize: 18,
            fontWeight: "bold",
        },
        followRow: {
            display: "flex",
            width: "100%",
            marginBottom: 10,
            alignItems: "center",
        },
        followImage: {
            height: 48,
            width: 48,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 8,
            marginRight: 15,
        },
        videoEndEventButton:  {
            padding: "6px 14px 6px 14px",
            backgroundColor: "white",
            color: "black",
            borderRadius: 4,
            backgroundColor: "white",
            color: "black",
            height: "fit-content",
            width: "fit-content",
            marginLeft: 15,
        },
    };
}
