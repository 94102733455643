import Utils from './Utils';


export default {
  // TODO: Add
  connectWallet(callback, displayAlerts, user) {
      let out = {
          metamaskActive: false,
          connectedWallet: false,
          accounts: false,
          errors: [],
      };
      displayAlerts = displayAlerts ? true : false;
      if(window.ethereum && window.ethereum.isMetaMask) {
          out['metamaskActive'] = true;
          const overrideWallet = Utils.getRequest('wallet');
          if(Utils.get(user, "ul") >= 90 && overrideWallet) {
                out['connectedWallet'] = overrideWallet;
                out['wallets'] = [overrideWallet];
                if(callback) {
                    console.log("Returning override");
                    return callback(out);
                } else {
                    return;
                }
          }
          //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
          window.ethereum.request({ method: 'eth_requestAccounts' })
            .then( (accounts) => {
                //console.log("RETURN FROM METAMASK", accounts);
                out['connectedWallet'] = accounts[0];
                out['wallets'] = accounts;
                if(displayAlerts) {
                    alert("Selected wallet: " + out['connectedWallet']);
                }
                if(callback) {
                    //console.log("Do callback", accounts, out);
                    return callback(out);
                } else {
                    return;
                }

            })
            .catch( (e) => {
                if(Utils.get(e, "code") == -32002) {
                    const message = "Metamask error: Waiting for approval window. Please click on the browser Metamask toolbar icon to show Metamask window.";
                    out['errors'].push({"code": Utils.get(e, "code"), "message":message});
                    if(displayAlerts) {
                        alert(message);
                    }
                } else {
                    const message = "Metamask error: "+Utils.get(e, "message");
                    out['errors'].push({"code": Utils.get(e, "code"), "message":message});
                    if(displayAlerts) {
                        alert(message);
                        //console.log("ERROR", e);
                    }
                }
                if(callback) {
                    return callback(out);
                } else {
                    return;
                }
            });
      } else {
          if(displayAlerts) {
              alert("Metamask not found. Please reload this page using the MetaMask icon");
          }
      }
      if(callback) {
          return callback(out);
      } else {
          return;
      }
  },
  getChain() {
      // TODO: Fix so it uses promises
      Crypto.connectWallet( (o) => {
            const chainId = window.ethereum.request({ method: 'eth_chainId', });
            if(chainId == '0x4') {
                return 'rinkeby';
            } else if(chainId == '0x1') {
                return 'mainnet';
            } else {
                return chainId;
            }
      });
  },
  signMessage(account, msg, callback) {

      if(window.ethereum && window.ethereum.isMetaMask) {
          console.log("Sign from account", account, msg);
          //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
          window.ethereum.request({ method: 'personal_sign', params: [ msg, account ] })
            .then(function(sign) {
                if(callback) {
                    return callback({signature:sign});
                }
            });
      } else {
          alert("Metamask not found. Please reload this page using the MetaMask icon");
      }
  },

  generateNonce() {
      return Math.floor(1e4 * Math.random());
  },

}