import React from 'react';

import Utils from '../modules/Utils';
import Errors from '../modules/Errors';
import Validate from '../modules/Validate';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import DebugBox from '../elements/DebugBox';
import Div from '../elements/Div';
import Input from '../elements/Input';0
import MetaMask from '../elements/MetaMask';
import Button from '../elements/Button';

export default class QrEmailSendButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailList:[]
        };
    }

    changeVal(valPath, event) {
        //console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        this.setState(tempState);
    }

    handleClickSendPass(ticket, nft, emailTo, plusOne) {
        let ticketType = 1;
        const nftType = Utils.get(nft, 'contract_symbol')
        //if(nftType == 'APU' || nftType == 'APMP') {
        //   ticketType = 3;
        //}
        //console.log("emailTo", emailTo, ticketType);
        const validate = Validate.email(emailTo);
        //console.log(validate);
        if(validate.errors.length > 0) {
            alert(validate.errors[0].message);
            return;
        }
        const walletId = this.state.metamaskWalletId;
        const signature = 'None';
        //console.log(nft, Utils.get(nft, 'contract_id'));
        this.postTicket(ticket, nft, walletId, ticketType, signature, emailTo, plusOne);
    }

    postTicket(ticket, nft, walletId, ticketType, signature, emailTo, plusOne) {
        console.log("NFT", nft, "ticket", ticket);
        var data = {
            test: this.state.testMode,
            ticketType: ticketType,
            listing_id: Utils.get(nft, "id"),
            emailTo: emailTo ? emailTo : '',
        }

        Api.postCustomerTicket(data, (o) => {
            console.log("o", o);
            if(true || o.success == 1) {
                this.props.change();
                //this.loadItems(this.state.metamaskWalletId);
            } else {
                alert("Error minting. "+Errors.getErrorMessages(o.errors)+". Please try again later");
                //temp[obj['num']] = 0;
                //this.setState({showMinting: temp});
            }
        });
        return;



        var metadata = Utils.get(ticket, 'metadata', '');
        if(ticketType == 2) {
            console.log("mintNftPass", ticket, metadata, Utils.get(nft, 'contract_id'));
        } else {
            console.log("generateQRTicket", ticket, metadata, Utils.get(nft, 'contract_id'));
        }
        const nftType = Utils.get(nft, 'contract_symbol')
        var idParts = metadata.split('/');
        if(idParts.length < 2) {
            alert("Missing metadata: "+JSON.stringify(idParts));
            return;
        }
        let collection_index = parseInt(idParts[idParts.length-2]);
        if(!collection_index) {
            if(nftType == 'MV3NFT') {
                collection_index = 18;
            } else if(nftType == 'LYRICAL') {
                collection_index = 19;
            }
        }

        const collection_item_index = idParts[idParts.length-1];
        //console.log("TICKET", obj, metadata);

        var data = {
            test: this.state.testMode,
            ticketType: ticketType,
            metadata: metadata,  //'https://nft.afterparty.ai/nft_metadata/1/13'
            //selectedWallet: walletId,
            selectedWallet: Utils.getRequest('wallet', walletId),
            collection_index: parseInt(collection_index),
            collection_item_index: nft['token_id'],
            symbol: nftType,
            contract_id: Utils.get(nft, 'contract_id'),
            image_url: Utils.get(obj, 'image_url', ''),
            signature: signature,
            emailTo: emailTo ? emailTo : '',
            plusOne: plusOne ? 1 : 0,
        }

        let temp = this.state.showMinting;
        temp[obj['num']] = 1;
        this.setState({showMinting: temp});

        Api.postCustomerTicket(data, (o) => {
            if(o.success == 1) {
                let temp = this.state.showMinting;
                console.log("o", o);
                temp[obj['num']] = o.data;
                const showEmailTo = emailTo ? emailTo : Utils.get(this.props, "user.email");

                if(this.state.mode == 'minting') {
                    this.setState({showMinting: temp, showMintedModal:true, emailTo:showEmailTo, ticketNum:Utils.get(o, "data.id")});
                } else {
                    this.setState({showMinting: temp, showSentModal:true, emailTo:showEmailTo, ticketNum:Utils.get(o, "data.id")});
                }
                this.loadItems(this.state.metamaskWalletId);
            } else {
                alert("Error minting. "+Errors.getErrorMessages(o.errors)+". Please try again later");
                //temp[obj['num']] = 0;
                //this.setState({showMinting: temp});
            }
        });

    }

    putTicket(ticket, nft, walletId, ticketType, signature, emailTo, cmd) {
        //console.log("TICKET", obj);
        var data = {
            cmd: cmd,
            ticketType: ticketType,
        }

        //let temp = this.state.showMinting;
        //temp[obj['num']] = 1;
        //this.setState({showMinting: temp});
        const id = Utils.get(ticket, "id");
        Api.putCustomerTicket(id, data, (o) => {
            if(Api.noErrors(o)) {
                alert("Resent pass.");
            }

        });
    }


    clickResend(ticket, nft, walletId, ticketType, signature2, emailTo) {
        //console.log("clickResend", ticket);
        const signature = 'None';
        const cmd = 'resend';
        this.putTicket(ticket, nft, false, 1, signature, emailTo, cmd);

        //this.setState({ticketNum:Utils.get(ticket, "id"), showSentModal:true, emailTo:Utils.get(this.props, "user.email")});
    }

    clickInvalidate(ticket) {
        console.log("clickInvalidate", ticket);
        //this.setState({ticketNum:Utils.get(ticket, "id"), showSentModal:true, emailTo:Utils.get(this.props, "user.email")});
    }

    render() {
        const ticket = this.props.ticket;
        const nft = this.props.nft;
        console.log("ticket", ticket);
        const plusOne = this.props.plusOne;
        const num = Utils.get(ticket, "num", 0);
        const key = "t2-" + Utils.get(ticket, "id") + num;
        const placeholder = "Email...";
        let panelTitle = Utils.get(ticket, "id") ? "" : "Enter Your Email";
        if(plusOne) {
            panelTitle = "Enter +1 Email"
        }
        const qrClaimLine1 = Utils.get(ticket, "qrClaimTitle", "Claim Your Tickets");
        const qrClaimLine2 = Utils.get(ticket, "qrClaimLine1", "You have 1 Ticket");
        const qrClaimLine3 = Utils.get(ticket, "qrClaimLine1", "To see Yung Gravy's Unplugged Performance 9/1/22");

        const sending = Utils.get(this.state, "showMinting."+num) == 1;
        return (
            <Div key={key} style={{background:"rgba(127,127,127,0.3)",borderRadius:8,margin:10,padding:10, maxWidth:200}}>
                <Div style={{textAlign:"center",}}>
                    <Div style={{fontSize:16, fontWeight:"bold",marginTop:20,marginBottom:10,}}>{qrClaimLine1}</Div>
                    <Div style={{fontSize:12, marginTop:10,marginBottom:20,}}>{qrClaimLine2}</Div>
                    <Div style={{marginBottom:20,}}>{qrClaimLine3}</Div>
                </Div>
                <Div style={{margin:6, textAlign:"center"}}>{panelTitle}</Div>
                { Utils.get(ticket, "id") ?
                    Utils.get(ticket, "status") == 2 ?
                        <Div>Used</Div>
                        :
                        Utils.get(ticket, "status") == 3 ?
                            <Div>Previously Owned</Div>
                            :
                            <Div>
                                <Button onClick={this.clickResend.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''))} color="purpleLight1" style={{width:"100%",marginBottom:12}} size="small">
                                    Resend Pass <i className="fa fa-retweet" style={{marginLeft:10, fontSize:13}} />
                                </Button>
                                { false ?
                                    <Button onClick={this.clickInvalidate.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''))} color="black" style={{width:"100%"}} size="small">
                                        Invalidate Pass <i className="fa fa-times" style={{marginLeft:10, fontSize:13}} />
                                    </Button>
                                    :
                                    null
                                }
                            </Div>
                    :
                    <Div>
                        <Input
                            value={Utils.get(this.state, `emailList.${num}`, '')}
                            onChange={this.changeVal.bind(this, `emailList.${num}`)}
                            style={{width:"100%", paddingTop:8,paddingBottom:8,}}
                            placeholder={placeholder}
                            />
                        <Button
                            onClick={this.handleClickSendPass.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''), plusOne)}
                            disabled={sending}
                            style={{width:"100%"}}
                            size="small" >
                            { sending ?
                                <span>Send Pass...</span>
                                :
                                <span>Send Pass <i className="fa fa-envelope" style={{marginLeft:10, fontSize:13}} /></span>
                            }

                        </Button>
                    </Div>
                }
            </Div>
        );

    }
}