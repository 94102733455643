import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Icon from './Icon';
import Span from './Span';

import Div from '../components/Div';

export default class Countdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eventTimeStamp: props.eventTimeStamp, // NOTE: in seconds
            countdownName: "countdownInterval_" + props.name,
            secondsLeft: false,
        }
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;

        this.setCountdownInterval();
    }

    UNSAFE_componentWillReceiveProps(newProps) {}

    componentWillUnmount() {
        const st = this.state;

        this.clearCountdownInterval();
    }

    setCountdownInterval() {
        const st = this.state;

        this.updateEventSeconds();
        if(!this[st.countdownName] && !Utils.getRequest('nocountdown')) {
            this[st.countdownName] = window.setInterval(this.updateEventSeconds.bind(this), 1000);
        }
    }

    clearCountdownInterval() {
        const st = this.state;

        window.clearInterval(this[st.countdownName]);
        this[st.countdownName] = undefined;
    }

    updateEventSeconds() {
        const st = this.state;

        const currentTimeStamp = parseInt(Date.now() / 1000);
        const secondsLeft = st.eventTimeStamp - currentTimeStamp;

        this.setState({
            secondsLeft: secondsLeft - 1,
        }, () => {
            if(secondsLeft - 1 <= 0) {
                this.clearCountdownInterval();
            }
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        const timeObj = Utils.secondsToTimeString(st.secondsLeft, "dhm_obj");

        return (
            <Div
                className="countdownElement"
                style={{...sty.countdown, ...pr.style}}
            >
                {st.secondsLeft > 0 ?
                    <Div style={{color: "white", marginTop: 10, display: "flex", gap: 4}}>
                        <Div className="timeBoxHours" style={sty.timeBox}>
                            <Div className="timeNumber" style={sty.timeNumber}>{Utils.padZeros(timeObj.hours, 2)}</Div>
                            <Div className="timeString" style={sty.timeString}>{timeObj.hoursString}</Div>
                        </Div>
                        <Div className="timeBoxMinutes" style={sty.timeBox}>
                            <Div className="timeNumber" style={sty.timeNumber}>{Utils.padZeros(timeObj.minutes, 2)}</Div>
                            <Div className="timeString" style={sty.timeString}>{timeObj.minutesString}</Div>
                        </Div>
                        <Div className="timeBoxHoursSeconds" style={sty.timeBox}>
                            <Div className="timeNumber" style={sty.timeNumber}>{Utils.padZeros(timeObj.seconds, 2)}</Div>
                            <Div className="timeString" style={sty.timeString}>{timeObj.secondsString}</Div>
                        </Div>
                    </Div>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        countdown: {
            display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
        },
        timeBox: {
            padding: "4px 8px",
            width: 67,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#3B3349",
            borderRadius: 8,
            flexDirection: "column",
            textAlign: "center",
        },
        timeNumber: {
            fontSize: 20,
            fontWeight: 600,
        },
        timeString: {
            fontSize: 12,
            fontWeight: 400,
            textTransform: "uppercase",
        },
    }
}
