import React from 'react';
import Utils from '../modules/Utils';
import Colors from '../modules/Colors';

import spinner from '../assets/images/spinner-2.gif';

import Image from './Image';

import Div from '../components/Div';

export default class BorderSeparator extends React.Component {
    render() {
        const pr = this.props;
        let sty = this.styles;

        let size = {width: pr.size ? pr.size : 40}

        let backgroundStyle = {backgroundColor: "white"};

        if(pr.color === "pinkGradient") {
            backgroundStyle = {background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)", color: "white"};
        } else if(pr.color === "indigoLighter") {
            backgroundStyle = {backgroundColor: Colors.indigoLighter};
        }

        return (
            <Div
                className="borderSeparatorElement"
                style={{...sty.borderSeparatorElement, ...backgroundStyle, ...pr.style}}
            ></Div>

        )
    }

    styles = {
        borderSeparatorElement: {
            width: "100%",
            height: 2,
            // backgroundColor: "none",
            // "#C92D7A",
        },
    }
}
