import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Input from '../elements/Input';

import Div from './Div';
import RegisterFlow from './RegisterFlow';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"

import iconTwitterThick from "../assets/images/splash/twitter 23.png"
import iconDiscordThick from "../assets/images/splash/Discord 3.png"
import iconInstagramThick from "../assets/images/splash/Discord-1.png"

import iconPurpleTwitterThick from "../assets/images/splash/twitter 2.png"
import iconPurpleDiscordThick from "../assets/images/splash/Discord 2.png"
import iconPurpleInstagramThick from "../assets/images/splash/ig 2.png"

export default class SplashSocialMediaIcons extends React.Component {
    onClickSocialMediaIcon(socialIcon) {
        switch(socialIcon) {
            case "twitter":
                window.open("https://www.twitter.com/afterparty", "_blank");
                break;
            case "discord":
                window.open("https://discord.gg/afterpartyNFT", "_blank");
                break;
            case "instagram":
                window.open("https://www.instagram.com/afterparty", "_blank");
                break;
            default:
        }
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        return (
            <Div className="socialMediaIcons" style={sty.socialMediaIcons}>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                    <Image
                        src={pr.color === "purple" ? iconPurpleTwitterThick : iconTwitterThick}
                        style={{width: pr.size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "twitter")}
                    />
                </Div>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                    <Image
                        src={pr.color === "purple" ? iconPurpleDiscordThick : iconDiscordThick}
                        style={{width: pr.size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "discord")}
                    />
                </Div>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer, ...{marginRight: 0}}}>
                    <Image
                        src={pr.color === "purple" ? iconPurpleInstagramThick : iconInstagramThick}
                        style={{width: pr.size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "instagram")}
                    />
                </Div>
            </Div>
        )
    }

    styles = {
        socialMediaIcons: {
            display: "flex",
        },
        socialMediaIcon: {
            marginRight: this.props.size < 45 ? 15 : 80,
        },
    };
}
