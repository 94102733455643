import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";
import DeviceDetector from "device-detector-js";
// import Modernizer from "modernizr.js";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Analytics from '../modules/Analytics';
import FeatureSwitch from '../modules/FeatureSwitch';

import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import BorderSeparator from '../elements/BorderSeparator';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import Payments from '../elements/Payments';
import PaymentsV2, {
    cleanUrlQueryStrings
} from '../elements/PaymentsV2';


import SplashFooter from './SplashFooter';
import Div from './Div';
import CustomerProfile from './CustomerProfile';
import Chatbox from './Chatbox';
import ModalNFT from './ModalNFT';
import Scroller from './Scroller';
import ProfileMenu from './ProfileMenu';
import Profile from './Profile';
import Post from './Post';
import Leaderboard from './Leaderboard';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';
import followUser from '../assets/icons/follow-user.svg';

import commentIconWhite from '../assets/icons/comment pink.png';
import nftIconWhite from '../assets/icons/nft white.png';
import leaderboardIconWhite from '../assets/icons/leaderboard white.png';
import starIconWhite from '../assets/icons/star white.png';
import usersIconWhite from '../assets/icons/users white.png';

import commentIconPink from '../assets/icons/comment pink.png';
import nftIconPink from '../assets/icons/nft pink.png';
import leaderboardIconPink from '../assets/icons/leaderboard pink.png';
import starIconPink from '../assets/icons/star pink.png';
import usersIconPink from '../assets/icons/users pink.png';
import infoIcon from '../assets/icons/info.png';

import AgoraVideoCall from "./AgoraVideoCall";
const AGORA_APP_ID = 'b0dc8c2769cd4f8389486454d1dfb958';

export default class RoomV3 extends React.Component {
    constructor(props) {
        super(props);
        const userId = Utils.get(props, "user.id");
        const customersVip = Utils.get(props, "roomItem.customers_vip", []);
        const customersVipDict = Utils.arrayToObject(customersVip, "id");

        this.state = {
            value: '',
            wallet: null,
            mode: Utils.getRequest('host') == 1 ? 'host' : 'guest',
            showLeaderboardTab: Utils.getRequest('leaderboard') == 0 ? false : true,
            hostPicture: true,
            guestsOnline: 0,
            onlineUsers: Utils.get(props, "roomItem.onlineRoomUsers", []),
            sessionDuration: 0,
            chatboxContent: "messages", //"messages", // "participants",
            showSionModal: false,
            currentSoin: {
                name: "",
                description: "",
                image_url: "",
                created_at: "",
            },
            currentSoinIndex: -1,

            videoIcons: {
                video: {
                    isShown: true,
                    isOn: true,
                },
                audio: {
                    isShown: true,
                    isOn: true,
                },
                tv: {
                    isShown: false,
                    isOn: false,
                },
                desktop: {
                    isShown: true,
                    isOn: true,
                },
                exit: {
                    isShown: true,
                },
                eventEnd: {
                    isShown: true , // NOTE not being used
                },
            },

            liveStreamDuration: null,

            agoraActive: false, //Utils.getRequest('agora') == 1 ? true : true,
            agoraKey: 0,
            xrayMode: Utils.getRequest('xray') ? true : false,
            collapseXray: parseInt(Utils.getCookie('collapseXray')),
            showHelpQuestions: true,
            showProfileModal: false,

            mostRecentMessage: {
                body: "",
                created_at: "",
            },
            posting: false,
            post: "",
            expandMostRecentMessageInput: false,

            subscribeSuccess: false,
            videoMuted: {},
            audioMuted: {},

            selectedTabMobileRoom: props.screen.mobile ? "none" : Utils.getCookie("selectedTabMobileRoom", "participants"),
            isChatboxInputAbove: props.screen.mobile ? true : false,

            isHost: Utils.get(props, "roomItem.insiders.isHost"),
            isVip: customersVipDict[userId] ? true : false,
            isCohost: Utils.get(props, "roomItem.insiders.isCohost"),
            isMod: Utils.get(props, "roomItem.insiders.isMod"),

            // customersVip: [],

            vipIsInLiveStream: false,
            vipsInitialized: false,

            showLivestreamMenu: false,

            showComingSoonModal: false,

            isHandRaised: false,

            selectedVipId: null,
            lastLiveVip: null,
            viewedVipId: null,

            showAcceptInviteOverlay: false,

            disableAllInviteButtons: false,

            showInviteToStreamPopUp: false,

            showStreamAlertModal: false,
            streamAlertMessage: "",

            isHandRaisedToTop: false,

            showWithdrawInviteButton: false,

            showRemoveGuestButton: false,

            joinedAudio: false,

            userDevice: null,

            showLivestreamHasEndedModal: false,
            numPeopleInStream: 0,

            guestCountdown: false,
            errors: [],
            testWindowHeight: "40%",
            customersVipDict: customersVipDict,
            userHandUpDict: {},
            isAndroid: Utils.get(window, "navigator.userAgentData.platform") == 'Android' || Utils.get('forcejoin'),

            showTipModalType: false, //"tipchooser", // tipinfo tipchooser payment tipentermsg notvip
            leaderboardKey: 1, // Used to refresh leaderboard
            showIntroModal: false,
            vipChangeVersion: 0,

            chatTabSelected: "fan",

            handUpOrderDict: {}, // NOTE: map of {customerId: arrayIndex}
            handUpSignalExtraStrDict: {}, // NOTE: map of {customerId: signal.extra_str}
        };
        ll.silent = Utils.getRequest("silent", true);
        this.emojis = ["🎉", "😍", "❤️", "🔥", "👏", "😂"];
        //window.startConference = this.startConference;
        this.hostRef = React.createRef();
        const urlPath = Utils.get(document, "location.pathname", "");
        let channel = "mychannel";
        // const parts =  urlPath.split("-");
        //   console.log("LISTING", parts);
        //   if(parts.length >= 2) {
        //       const id = parts[parts.length-1];
        //       channel = id;
        //   }

        this.roomCrc = Utils.getUrlCrc();

        this.videoProfile = "480p_4";
        // this.channel = channel;
        this.channel = this.roomCrc;
        this.transcode = "interop";
        this.attendeeMode = "video";
        this.baseMode = "avc";
        this.appId = AGORA_APP_ID;
        if(window.location.host.indexOf('localhost') == -1) {
            if(window.location.protocol.indexOf('https') == -1) {
                if(Utils.getRequest('test') != 1) {
                    alert("Video chat won't work with an HTTP -- must be HTTPs");
                }
            }
        }

        this.guestInterval = null;

        // windwow.consolelog
        // Utils.activateLogger();
        Utils.addMultiTabCheck(Utils.get(location, "pathname"), function() {
            alert("This room is already open in another tab. Unexpected results may occur.");
            /*
                let errors = this.state.errors;
                errors.push({id:29784, msg:"This room is already open in another tab. Unexpected results may occur."});
                this.setState({errors:errors});
                */
        });
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;

        // const detected = Utils.detectInAppBrowser(window.navigator.userAgent);
        // console.log("detected +++", detected);

        const deviceDetector = new DeviceDetector();
        // const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
        let userDevice = deviceDetector.parse(window.navigator.userAgent);
        // userDevice.os.name = "Android";
        console.log("userDevice +++", userDevice);

        clearInterval(this.guestInterval);

        this.setState({
            userDevice: userDevice,
        });

        //this.showJoinAlertMessage();
        // TODO: Remove this hack and use the npm package
        if(Utils.getRequest('nochat') == 2) {
            const firebaseUrl = '//www.gstatic.com/firebasejs/4.5.0/firebase.js';
            var head= document.getElementsByTagName('head')[0];
            var script= document.createElement('script');
            script.type= 'text/javascript';
            script.src= firebaseUrl;
            head.appendChild(script);
            const roomId = Utils.get(pr.roomItem, "id");
            // TODO: Yuck! Refactor
            setTimeout(this.connectToFirebase.bind(this, roomId), 1000);

        }

        // supports checking for stripe v3 redirect
        // e.g. /webapp/room/luke_s_afterparty_6_14-3988876182?other=test&payment_intent=pi_3NLWbCJwtuvY8RLr0Yku6pG5&payment_intent_client_secret=pi_3NLWbCJwtuvY8RLr0Yku6pG5_secret_MbhN6Csn9HQLTghrvBqSB2Mih&redirect_status=succeeded&paymentType=tipPayment
        if(Utils.getRequest('payment_intent') && Utils.getRequest('redirect_status')) {
            const redirectStatus = Utils.getRequest('redirect_status')

            if (redirectStatus === "succeeded") {
                if (Utils.getRequest('paymentType') === 'tipPayment') {
                    // ensure the correct UI shows on tip e.g.g orange vs plain message
                    const tipAmount = Utils.getRequest('computedAmount')
                    this.setState({
                        tipAmount,
                    });

                    this.postClickHandler('paidTip')
                    // uncomment as an alternative to default if needed
                    // this.postClickHandler('paidTipSuccess')

                    // clear here since default success modal is a post and tricky to clean query strings from that
                    cleanUrlQueryStrings()
                } else if (Utils.getRequest('paymentType') === 'upgradeVipChatPayment') {
                    // currently does nothing but here for future use
                    this.postClickHandler('paidUpgradeVipChat')
                }
            }

            if (redirectStatus === "failed") {
                this.postClickHandler('paymentFailed')
            }
            /**
             * clear all query strings
             * - Note this was moved to the modal inside renderTipModal
             * so on close it clears instead.
             * - kept here in case we need to move it back for reference
             */
            // cleanUrlQueryStrings()
        }
    }

    connectToFirebase(roomId) {
        // Initialize Firebase
        // TODO: Load info from backend
        roomId = 68;
        const firebaseConfig = {
            apiKey: "AIzaSyAHessW8QY7o4-LRLTlFDE8t6JzzNwJDc8",
            authDomain: "liveroom-test.firebaseapp.com",
            databaseURL: "https://liveroom-test-default-rtdb.firebaseio.com/",
            projectId: "liveroom-test",
            storageBucket: "liveroom-test.appspot.com",
            messagingSenderId: "156309140195",
            appId: "1:156309140195:web:3c6de38dcff61f9c2ecbd6",
            measurementId: "G-96ZPNDLBX9"
        };
        window.firebase.initializeApp(firebaseConfig);
        var dbRef = firebase.database();
        var roomChatSignalRef = dbRef.ref('room-'+roomId+'/room_chat_signals');
        window.roomChatSignalRef = roomChatSignalRef;
        roomChatSignalRef.on("child_added", (rcsRow) => {
          //console.log("ADD", rcsRow.key, rcsRow.val());
          var item = rcsRow.val();
          item['checkId'] = rcsRow.key;
          this.handleSignal(item);
        });
        roomChatSignalRef.on("child_changed", (rcsRow) => {
          //console.log("CHANGE", rcsRow.key, rcsRow.val());
          var item = rcsRow.val();
          item['checkId'] = rcsRow.key;
          this.handleSignal(item);
        });

        //this.addRCS();
    }

    // TODO: Remove! Just for testing
    addRCS() {
        window.roomChatSignalRef.push({
            id: 3,
            cmd: 'refresh',
            room_id: 1,
            customer_id: 1,
            extra_id: 1,
            extra_str: 1,
        })
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        const customersVip = Utils.get(newProps.roomItem, "customers_vip", []);
        const customersVipDict = Utils.arrayToObject(customersVip, "id");

        this.setState({
            isHost: Utils.get(newProps, "roomItem.insiders.isHost"),
            isVip: customersVipDict[newProps.user.id] ? true : false,
            isCohost: Utils.get(newProps, "roomItem.insiders.isCohost"),
            isMod: Utils.get(newProps, "roomItem.insiders.isMod"),
            customersVip: customersVip,
            customersVipDict: customersVipDict,
            onlineUsers: Utils.get(newProps, "roomItem.onlineRoomUsers", []),
        }, () => {
            if(customersVip.length) {
                this.createSortedTable();
            }
        });

        if(!st.vipsInitialized && customersVip.length) {
            this.setState({vipsInitialized: true});
        }
        // if(Utils.get(newProps, "roomItem.isLive") && !st.isHost) {
            // this.handleStartAgora();
        // }
    }

    handleLeaderboardUpdate(leaderboardData) {
        this.setState({
            leaderboardData: leaderboardData,
        }, () => {
            this.createSortedTable();
        });
    }

    createSortedTable() {
        const pr = this.props;
        const st = this.state;

        let handUpOrder = [];

        // NOTE: first time
        let frList = [];
        let vipList = [];
        let gaList = [];

        // NOTE: hand raise accepted 1 or more times
        let frList2 = [];
        let vipList2 = [];
        let gaList2 = [];

        const leaders = Utils.get(st.leaderboardData, "leaders", []);
        const vips = Utils.deepCopy(st.customersVip, true);

        // NOTE: sort leaderboad into fr, vip, and ga
        leaders.map((leader, leaderIndex) => {
            const vip = st.customersVipDict[leader.customer_id];

            if(Utils.get(vip, "id") == leader.customer_id) {
                // NOTE: vip is on the leaderboard
                if(vip.host_subtype == Constant.VIP_TYPE_FRONT_ROW) {
                    if(this.handupFirstTime(leader.customer_id)) {
                        frList.push(vip);
                    } else {
                        frList2.push(vip);
                    }
                } else if(vip.host_subtype == Constant.VIP_TYPE_REGULAR) {
                    if(this.handupFirstTime(leader.customer_id)) {
                        vipList.push(vip);
                    } else {
                        vipList2.push(vip);
                    }
                } else { //if(vip.host_subtype == Constant.VIP_TYPE_LEADERBOARD) {
                    if(this.handupFirstTime(leader.customer_id)) {
                        gaList.push(vip);
                    } else {
                        gaList2.push(vip);
                    }
                }
            }
        });

        let frListMap = Utils.arrayToObject(frList, "id");
        let vipListMap = Utils.arrayToObject(vipList, "id");
        let gaListMap = Utils.arrayToObject(gaList, "id");

        let frListMap2 = Utils.arrayToObject(frList2, "id");
        let vipListMap2 = Utils.arrayToObject(vipList2, "id");
        let gaListMap2 = Utils.arrayToObject(gaList2, "id");

        // NOTE: sort vips NOT in leaderboard into fr and vip
        vips.map((vip, i) => {
            if(vip.host_subtype == Constant.VIP_TYPE_FRONT_ROW) {
                if(!frListMap[vip.id] && !frListMap2[vip.id]) { // NOTE: make sure to not inject duplicates
                    if(this.handupFirstTime(vip.id)) {
                        frList.push(vip);
                    } else {
                        frList2.push(vip);
                    }
                }
            } else if(vip.host_subtype == Constant.VIP_TYPE_REGULAR) {
                if(!vipListMap[vip.id] && !vipListMap2[vip.id]) { // NOTE: make sure to not inject duplicates
                    if(this.handupFirstTime(vip.id)) {
                        vipList.push(vip);
                    } else {
                        vipList2.push(vip);
                    }
                }
            } else { // NOTE: type 3
                if(!gaListMap[vip.id] && !gaListMap2[vip.id]) { // NOTE: make sure to not inject duplicates
                    if(this.handupFirstTime(vip.id)) {
                        gaList.push(vip);
                    } else {
                        gaList2.push(vip);
                    }
                }
            }
        });

        // console.log("frList >>>>", frList);
        // console.log("vipList >>>>", vipList);
        // console.log("gaList >>>>", gaList);
        //
        // console.log("frList2 >>>>", frList2);
        // console.log("vipList2 >>>>", vipList2);
        // console.log("gaList2 >>>>", gaList2);

        handUpOrder = frList.concat(vipList, gaList, frList2, vipList2, gaList2);

        // NOTE: handUpOrder should be transformed to key is customerId and val is arrayIndex
        let handUpOrderDict = {};
        handUpOrder.map((customer, index) => {
            handUpOrderDict[customer.id] = index + 1;
        });

        // console.log("handUpOrderDict >>>>", handUpOrderDict);
        this.setState({
            handUpOrderDict: handUpOrderDict,
        });
    }

    handupFirstTime(customerId) {
        // NOTE: in handleSignal_handup, create state var handUpSignalExtraStrDict, key is customerId and value is extra_str
        const st = this.state;

        const extraStr = st.handUpSignalExtraStrDict[customerId];

        if(!extraStr) {
            return true;
        } else if(parseInt(extraStr) > 0) {
            return false;
        }
    }

    // getIndexInArray(id, ar) {
    //     for(var i = 0; i < ar.length; i++) {
    //         if(ar[i].id == id) {
    //             return i;
    //         }
    //     }
    //
    //     return -1;
    // }

    showJoinAlertMessage(joinMessage) {
        let streamAlertMessage = joinMessage ? joinMessage : "Welcome to Afterparty!  Click OK to join the stream";

        this.setState({
            showStreamAlertModal: true,
            streamAlertMessage: streamAlertMessage,
        });
    }

    updateStats() {
         window.client.getSessionStats((o) => {
            this.setState({guestsOnline: o["UserCount"], sessionDuration: o["Duration"]});
        });
    }

    openProfileModal() {
        this.setState({showProfileModal: true});
    }

    closeProfileModal() {
        this.setState({showProfileModal: false});
    }

    closeStreamAlertModal() {
        this.setState({
            showStreamAlertModal: false,
            streamAlertMessage: "",
            joinedAudio: true,
        });
        if(window.clickJoinStream) {
            var callback = window.clickJoinStream;
            window.clickJoinStream = false;
            callback();
        }
    }

    getElementByDataId(id) {
        const element = document.querySelector(`[data-id="ag-item-${id}"]`);
        //console.log("element ====", element);
        return element;
    }

    restoreHostOnlyStream() {
        const st = this.state;

        const vipId = st.selectedVipId; //st.lastLiveVip ? st.lastLiveVip : st.selectedVipId;
        console.log("vip::restoreHostOnlyStream lastvip: ", st.lastLiveVip, " selectedVip:", st.selectedVipId);

        if(!vipId) {
            return;
        }

        let vipElement = this.getElementByDataId(vipId);
        let cohostsElement = this.getElementByDataId(0);

        // set the VIP element to a placeholder id, temp-ag-item-{id} 15
        vipElement.id = `temp2-ag-item-0`;

        // set id of the cohosts element to ag-item-15
        console.log("switchVipElementId:: set vip: ", vipElement, " cohost:", cohostsElement);
        cohostsElement.id = `ag-item-${vipId}`;
    }

    activateVipStreamMode(customerId) {
        const st = this.state;

        let vipElement = this.getElementByDataId(customerId);
        let cohostsElement = this.getElementByDataId(0);
        console.log("vip:: activateVipStreamMode vipId: ", customerId, " vipElement: ", vipElement, ' cohostElement: ', cohostsElement);

        if(!customerId) {
            return;
        }


        // set the VIP element to a placeholder id, temp-ag-item-{id} 15
        vipElement.id = `temp2-ag-item-0`;
        cohostsElement.id = `ag-item-${customerId}`;
    }

    initializeAudioVideoDict() {
        const pr = this.props;
        const st = this.state;

        let audioMuted = st.audioMuted;
        let videoMuted = st.videoMuted;
        audioMuted[Utils.get(pr, 'user.id')] = 0;
        videoMuted[Utils.get(pr, 'user.id')] = 0;
        this.setState({
            audioMuted: audioMuted,
            videoMuted: videoMuted,
        });
    }

    handleStartAgora(dontSendChatSignal, clickedStartLiveStream) {
        clickedStartLiveStream = clickedStartLiveStream ? true : false;
        const pr = this.props;
        const st = this.state;

        const roomId = Utils.get(pr.roomItem, "id");
        const eventId = Utils.get(pr.roomItem, "event.id");
        const data = {
            event_type: 1, // Join
            room_id: roomId,
            event_id: eventId,
        }

        // NOTE: this is mainly a note to the backend to say that a user is joining the room
        Api.postCustomerEvent(data, (o) => { console.log("Joined"); });

        let tempState = {};
        tempState['clickedStartLiveStream'] = clickedStartLiveStream;
        tempState['showLivestreamMenu'] = false;
        tempState['showLivestreamHasEndedModal'] = false;

        if(st.isHost && !dontSendChatSignal) {
            this.putSignal("startallagora", null, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 1, (res) => {
                if(!Utils.get(res, "errors").length) {
                    this.putSignal("audiomuted", Utils.get(pr, 'user.id'), Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, 0);
                    this.putSignal("videomuted", Utils.get(pr, 'user.id'), Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, 0);

                    this.initializeAudioVideoDict();
                } else {
                    console.log("ERROR startallagora", res);
                }
            });
        }

        // if(!st.agoraActive) {
            // NOTE: agoraKey specifically solves a problem that pops up only on certain browsers
            tempState['agoraActive'] = true;
            tempState['agoraKey'] = st.agoraKey+1;
            if(!dontSendChatSignal) {
                tempState['clickedStartLiveStream'] = true;
            }
        // }
        this.setState(tempState);
    }

    handleStopAgora(dontSendChatSignal) {
        const pr = this.props;
        const st = this.state;

        const roomId = Utils.get(pr.roomItem, "id");
        const eventId = Utils.get(pr.roomItem, "event.id");
        const data = {
            event_type: 10, // Join TODO this should be 0? Exit?
            room_id: roomId,
            event_id: eventId,
        }
        Api.postCustomerEvent(data, (o) => {
            console.log("Host Exit Livestream");
        });

        if(st.isHost) {
            window.startStopStreamWithoutMountingAgora(false, null);

            if(!dontSendChatSignal) {
                this.putSignal("startallagora", null, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0, (res) => {
                    // this.setMuteAudioVideo(Utils.get(pr, 'user.id'), 1); // NOTE mute
                    // this.setMuteAudioVideo(st.selectedVipId, 1); // NOTE mute
                    this.putSignal("activatecohost", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);
                });

                this.putSignal("livestreamended", null, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 1, (res) => {

                });
            }
        } else {
            this.unpublishStreams();
        }

        this.setState({
            agoraActive: false,
            agoraKey: null,
            showLivestreamMenu: false,
            showLivestreamHasEndedModal: true,
        });
    }

    handleChangeAgoraClient(newClient, clientId) {
        console.log("New client >>>>", newClient);
        this.agoraClient = newClient;
        this.setState({clientId: clientId});
    }
    handleChangeAgoraStream(newStream) {
        console.log("New stream >>>>", newStream);
        this.agoraStream = newStream;
        window.localAgoraStream = newStream;
        // let tempState = {};
        // let videoIcons = this.state.videoIcons;
        // videoIcons.video.isOn = !newStream['video'].muted;
        // videoIcons.audio.isOn = !newStream['audio'].muted;
        // tempState['videoIcons'] = videoIcons;
        // this.setState(tempState);
        // this.setState({
        //     :
        // });
    }
    handleChangeAgoraEvent(eventType, eventInfo) {
        ll._("AGORA EVENT", "yellow", eventType, eventInfo);
    }

    handleCloseHelpQuestions() {
        this.setState({showHelpQuestions: false});
    }

    handleVideoClick(e) {
        const st = this.state;

        let videoOn = st.videoIcons.video.isOn;
        if(this.agoraStream && this.agoraStream['video']) {
            if(!this.agoraStream['video'].muted) {
                this.agoraStream['video'].setMuted(true);
                videoOn = false;
            } else {
                this.agoraStream['video'].setMuted(false);
                videoOn = true;
            }
        } else {
            ll._("There is no Agora video stream", "red");
        }

        let videoIcons = st.videoIcons;
        videoIcons.video.isOn = videoOn;

        this.setState(videoIcons);
    }

    handleAudioClick(e) { // NOTE OLD UNUSED
        const st = this.state;

        let microphoneOn = st.videoIcons.audio.isOn;
        if(this.agoraStream && this.agoraStream['audio']) {
            if(!this.agoraStream['audio'].muted) {
                this.agoraStream['audio'].setMuted(true);
                microphoneOn = false;
            } else {
                this.agoraStream['audio'].setMuted(false);
                microphoneOn = true;
            }
        } else {
            ll._("There is no Agora audio stream", "red");
        }

        let videoIcons = st.videoIcons;
        videoIcons.audio.isOn = microphoneOn;

        this.setState(videoIcons);
    }

    handleShareScreenClick(e) {
        let shareScreen = this.state.videoIcons.tv.isOn;
        if(this.agoraStream) {
            if(shareScreen) {
                //this.agoraStream.disableAudio();
                shareScreen = false;
            } else {
                //this.agoraStream.enableAudio();
                shareScreen = true;
            }
        } else {
            console.log("There is no Agora stream");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.tv.isOn = shareScreen;

        this.setState(videoIcons);
    }

    unpublishStreams() {
        try {
            if(this.agoraClient && this.agoraStream['video']) {
                ll._("Unpublishing video stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['video']);
            }
            if(this.agoraClient && this.agoraStream['audio']) {
                ll._("Unpublishing audio stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['audio']);
            }
            if(this.agoraClient) {
                window.agoraClient = agoraClient;
                this.agoraClient.leave();
            }
            // TODO: Enable when screen sharing is activated
            /*
            if (this.state.stateSharing) {
                this.shareClient && this.shareClient.unpublish(this.shareStream);
                this.shareStream && this.shareStream.close();
            }
            */
        } finally {
          this.setState({ readyState: false });
          this.client = null;
          this.localStream = null;
        }
    }

    handleExitClick(e) {
        if (e && e.currentTarget.classList.contains("disabled")) {
          return;
        }
        try {
            this.unpublishStreams();
            if(this.agoraClient) {
                this.agoraClient.leave(
                    () => { alert("You have left the room."); },
                    () => { alert("Leave failed."); }
                );
            };
        } finally {
            // redirect to index
            window.location.reload();
        }
    }

    handleSignal_startallagora(signal) {
        const pr = this.props;
        const st = this.state;
        const extraId = signal.extra_id;
        const customerId = signal.customer_id;
        if(extraId == 1) {
            ll._("Trying to start ALL agora stream from startallagora... >>>>>", "yellow");
            const streamStartTime = Utils.get(signal, "created_at").split(" ")[1];
            let currentTimeString = moment().utc().format().split("T")[1].split("Z")[0];

            const liveStreamDuration = Utils.convertTimeToMinutesAndSeconds(streamStartTime, currentTimeString);
            this.setState({
                liveStreamDuration: liveStreamDuration
            });

            if(!st.agoraActive) {
                ll._("Starting ALL agora stream from startallagora... >>>>>", "green");
                if(st.isAndroid) {
                    this.showJoinAlertMessage("Click OK to join the Livestream");
                    window.clickJoinStream = () => {
                        this.handleStartAgora(true, Utils.getBrowserId(true) == signal.browser_id);
                    };
                } else {
                    this.handleStartAgora(true, Utils.getBrowserId(true) == signal.browser_id);
                    setTimeout(() => {
                        this.showJoinAlertMessage("Now click OK to listen in");
                    }, 2000);
                }
            }
        } else if(extraId == 0) {
            if(st.agoraActive) {
                this.handleStopAgora(true);
            }
        }
    }
    handleSignal_audiomuted(signal) {
        const pr = this.props;
        const st = this.state;

        const extraId = signal.extra_id;
        const customerId = signal.customer_id;

        let audioMuted = Utils.deepCopy(st.audioMuted);
        if(audioMuted[customerId] != extraId) {
            if(audioMuted[customerId] == 1) {
                setTimeout(() => {
                    this.showJoinAlertMessage("Now click OK to listen in");
                }, 2000);
            }

            audioMuted[customerId] = extraId;
        }
        if(Utils.get(pr, 'user.id') == customerId) {
            window.muteMyStream("muteaudio", extraId == 1);
        }
        this.setState({audioMuted: audioMuted});
    }

    handleSignal_videomuted(signal) {
        const pr = this.props;
        const st = this.state;
        const customerId = signal.customer_id;
        const extraId = signal.extra_id;
        let videoMuted = Utils.deepCopy(st.videoMuted);
        videoMuted[customerId] = extraId;
        if(Utils.get(pr, 'user.id') == customerId) {
            window.muteMyStream("mutevideo", extraId == 1);
        }
        this.setState({videoMuted: videoMuted});
    }

    handleSignal_vipchange(signal) {
        const extraId = Utils.get(signal, 'extra_id');
        const pr = this.props;
        const st = this.state;
        const roomCrc = Utils.getUrlCrc();
        if(roomCrc && extraId > st.vipChangeVersion) {
            console.log("VIP change reload");
            this.setState({vipChangeVersion:extraId});
            window.addVip();
        }
    }

    handleSignal_handup(signal) {
        const pr = this.props;
        const st = this.state;
        const extraId = signal.extra_id;
        const extraStr = signal.extra_str;
        const customerId = signal.customer_id;
        if(customerId == 0) {
            console.log("ERROR!!!!!!!!!! -- customer is zero!", signal);
        }
        let tempState = {userHandUpDict: st.userHandUpDict};
        tempState['userHandUpDict'][customerId] = signal;

        tempState['handUpSignalExtraStrDict'] = st.handUpSignalExtraStrDict;
        tempState['handUpSignalExtraStrDict'][customerId] = extraStr;

        let elementContainer1 = document.getElementById(`ag-item-${customerId}`);
        let elementContainer = this.getElementByDataId(customerId);
        let element = document.getElementById(`handup-container-${customerId}`);
        const handUpOrderNum = st.handUpOrderDict[customerId] ? st.handUpOrderDict[customerId] : 10000;

        if(extraId == 1) {
            if(element) {
                element.style.display = "flex";
                if(extraStr >= 1) {
                    element.firstChild.src = "https://dhwk6gixvyfl3.cloudfront.net/upload_14c85fb405a98f085523bf884dfb0246.png";
                    element.style["background-color"] = "rgba(255, 0, 0, 0.7)";
                }

                elementContainer.style["order"] = handUpOrderNum - 1000;
                elementContainer.setAttribute("data-order", handUpOrderNum - 1000);
            }

            // This is the line -- customer ID must be 0 and then it does it
            //console.log("THISLINE", customerId, $(".vipAvatar").first());
            //$(`#ag-item-${customerId}`).insertBefore($(".vipAvatar").first());

            if(Utils.get(pr, 'user.id') == customerId) {
                tempState['isHandRaised'] = true;
            }
        } else if(extraId == 0) {
            if(element) {
                element.style.display = "none";
                //console.log("elementContainer.style RESETTING", extraId, elementContainer, elementContainer.getAttribute('data-order'));
                elementContainer.style["order"] = handUpOrderNum;
                elementContainer.setAttribute("data-order", handUpOrderNum);
                //console.log("elementContainer.style RESETTING2", elementContainer.style.order);
            }

            if(Utils.get(pr, 'user.id') == customerId) {
                tempState['isHandRaised'] = false;
            }
        }
        console.log("HAND");
        this.setState(tempState);
    }

    handleSignal_invitecohost(signal) {
        const pr = this.props;
        const st = this.state;
        const extraId = signal.extra_id;
        const customerId = signal.customer_id;
        if(extraId == 1) {
            this.setState({
                selectedVipId: customerId,
                showWithdrawInviteButton: true,
                disableAllInviteButtons: true,
            });

            if(Utils.get(pr, 'user.id') == customerId) {
                this.setState({
                    showAcceptInviteOverlay: true,
                });
            }
        } else if(extraId == 0) {
            if(Utils.get(pr, 'user.id') == customerId) {
                this.setState({
                    showAcceptInviteOverlay: false,
                    showWithdrawInviteButton: false,
                });
            }
        }
    }
    handleSignal_withdrawinvite(signal) {
        const pr = this.props;
        const st = this.state;
        const extraId = signal.extra_id;
        const customerId = signal.customer_id;
        // NOTE: if 0, do nothing, state simply needs to be disabled
        if(extraId == 1) {
            // TODO: this might need to be cookied instead
            this.setState({
                selectedVipId: null,
                disableAllInviteButtons: false,
                showAcceptInviteOverlay: false,
                showWithdrawInviteButton: false,
            });
            if(st.isHost) {
                this.putSignal("withdrawinvite", null, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);
            }
        }
    }
    handleSignal_activatecohost(signal) {
        const pr = this.props;
        const st = this.state;
        const extraId = signal.extra_id;
        const extraStr = signal.extra_str;
        const customerId = signal.customer_id;
        let hostContainerElement = document.getElementById("hostContainer");
        let cohostsContainerElement = document.getElementById("cohostsContainerId");
        const remainingVipTime =  parseInt(extraStr) - Utils.getUnixTimestamp();

        this.createSortedTable();

        if(extraId == 1) {
            this.setState({
                guestCountdown: extraStr,
            });
            if(!st.vipIsInLiveStream && st.agoraActive) {
                this.activateVipStreamMode(customerId);
                this.setState({
                    selectedVipId: customerId,
                    lastLiveVip: customerId,
                    disableAllInviteButtons: true,
                }, () => {
                    if(st.isHost) {
                        // Check if time has expired and, if so, kick the VIP
                        if(remainingVipTime < 0) {
                            this.removeGuestFromStream(this);
                        }
                    }
                });

                hostContainerElement.style.height = "50%";
                hostContainerElement.className = hostContainerElement.className + " heightFiftyPercent";

                cohostsContainerElement.style.display = "block";
                ll._("50% height and display block is applied >>>", "green", hostContainerElement);

                let imTheVip = Utils.get(pr, 'user.id') == customerId;
                if(imTheVip) {
                    ll._("activatecohost::Starting agora stream from RoomV3...", "yellow");
                    setTimeout(() => {
                        window.startStopStreamWithoutMountingAgora(true, customerId);
                        this.setState({
                            isHandRaised: false,
                        });
                    }, 2000);
                }

                this.setState({
                    vipIsInLiveStream: true,
                });
                this.showRemoveGuestButton(true);

            } else {
                if(st.isHost) {
                    // Check if time has expired and, if so, kick the VIP
                    if(remainingVipTime < 0) {
                        this.removeGuestFromStream(this);
                    }
                }
            }
        } else if(extraId == 0) {
            if(st.vipIsInLiveStream) {
                this.restoreHostOnlyStream();
                hostContainerElement.style.height = "100%";
                hostContainerElement.className = "";

                cohostsContainerElement.style.display = "none";

                if(Utils.get(pr, 'user.id') == customerId) {
                    ll._("Stopping agora stream from RoomV3 >>>...", "red");
                    window.startStopStreamWithoutMountingAgora(false, customerId);
                    this.setState({
                        showLivestreamMenu: false,
                    });
                } else {
                    window.removeUserFromStreamDict(customerId);
                }

                this.setState({
                    vipIsInLiveStream: false,
                    selectedVipId: null,
                    disableAllInviteButtons: false
                });

                this.showRemoveGuestButton(false);
            }
        }
    }

    handleSignal_refresh(signal) {
        const pr = this.props;
        const st = this.state;
        const customerId = signal.customer_id;
        console.log("handleSignal_refresh", customerId);
        if(!customerId) {
            location.reload();
        } else if(Utils.get(pr, 'user.id') == customerId) {
            location.reload();
        }
    }
    handleSignal_refreshga(signal) {
        const pr = this.props;
        const st = this.state;
        const customerId = signal.customer_id;
        console.log("handleSignal_refreshga", customerId);

        if(!customerId) {
            if(!st.isHost && !st.isVip && !st.vipChatActivated) {
                location.reload();
            } else {
                ll._("Host or VIP -- do not refresh");
            }
        } else if(Utils.get(pr, 'user.id') == customerId) {
            if(!st.isHost && !st.isVip && !st.vipChatActivated) {
                location.reload();
            } else {
                ll._("Host or VIP -- do not refresh");
            }
        }
    }
    handleSignal_badgenotify(signal) {
        const pr = this.props;
        const st = this.state;
        Api.postAnalytics({signal_id: signalId, type: "display_notify"});

        let listing = null;
        let index = -1;

        for(let i = 0; i < pr.roomItem.listings.length; ++i) {
            if(pr.roomItem.listings[i].id === signal.extra_id) {
                listing = pr.roomItem.listings[i];
                index = i;
                break;
            }
        }

        pr.handleShowRoomNotification(listing, signal);
    }
    handleSignal_acceptinvite(signal) {
        const pr = this.props;
        const st = this.state;
        // TODO: handle accept invite, disable all other invite buttons

        this.createSortedTable();

        this.setState({
            showAcceptInviteOverlay: false,
            showWithdrawInviteButton: false,
        });
    }
    handleSignal_declineinvite(signal) {
        const pr = this.props;
        const st = this.state;
        // TODO: handle withdraw invite, enable all other invite buttons

        if(st.isHost) {
            this.setState({
                showStreamAlertModal: true,
                streamAlertMessage: "VIP declined invitation"
            });
        }

        this.setState({
            showAcceptInviteOverlay: false,
            showWithdrawInviteButton: false,
            disableAllInviteButtons: false,
            selectedVipId: null,
        });
    }

    handleSignal_livestreamended(signal) {
        // Do nothing
    }

    handleSignal_vipchatactivated(signal) {
        //console.log("VIP CHAT ACTIVATED", Utils.get(signal, "customer_id"), Utils.get(this.props, "user.id"))
        if(Utils.get(signal, "customer_id") == Utils.get(this.props, "user.id")) {
            console.log("VIP CHAT ACTIVATED")
            this.setState({
                vipChatActivated: Utils.get(signal, "extra_id"),
            });
        }
    }


    handleSignal(signal) {
        //console.log("HANDLE SIGNAL", signal);
        const pr = this.props;
        const st = this.state;
        const userId = Utils.get(pr, "user.id");
        if(!userId) {
            return;
        }

        if(!window.chatSignals) {
           window.chatSignals = {};
        }
        window.chatSignals[signal['id']] = signal;

        const signalId = signal['id'];
        var key = "s-" + signalId;

        const cmd = signal.cmd;
        const cmdType = signal.cmd_type;
        if(cmdType == Constant.ROOM_CMD_TYPE_COOKIED) {
            if(Utils.getCookie(key)) {
                // Already ran signal
                return;
            }
            // Cookie so it doesn't run again
            Utils.setCookie(key, 1);
        }
        const handledCmds = ['startallagora', 'audiomuted', 'videomuted', 'handup', 'invitecohost', 'withdrawinvite', 'vipchatactivated',
            'activatecohost', 'refresh', 'refreshga', 'badgenotify', 'acceptinvite', 'declineinvite',  'vipchange', 'livestreamended',];
        const cmdIdx = handledCmds.indexOf(cmd);
        if(cmdIdx != -1) {
            return this['handleSignal_'+cmd](signal);
        } else {
            console.log("No handler found for signal command: "+cmd);
        }
    }

    handleClearAllChatSignals() {
        // TODO: Delete chat signals
        alert('Cleared');
    }

    acceptInviteToStream() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            showAcceptInviteOverlay: false,
        });

        this.putSignal("invitecohost", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);

        Api.postRoomChat({room_id: pr.roomItem.id}, (xxx) => {
            if(Utils.get(xxx, "signal")) {
                // NOTE: see if the Host withdrew the invite before acceptance
                let stillInvited = true;
                const signals = xxx.signal;
                for(var i = 0; i < signals.length; i++) {
                    const signal = signals[i];
                    if(signal.cmd === "withdrawinvite" && signal.extra_id == 1) {
                        stillInvited = false;
                        break;
                    }
                }

                if(!stillInvited) {
                    return;
                }

                let extraStrHandupAcceptCount;

                for(const key in window.chatSignals) {
                    const signal = window.chatSignals[key];
                    if(signal.customer_id == st.selectedVipId) {
                        extraStrHandupAcceptCount = (Utils._int(signal.extra_str, 0) + 1) + "";
                    }
                }

                this.putSignal("acceptinvite", st.selectedVipId, Constant.ROOM_CMD_TYPE_COOKIED, null, (acceptRes) => {
                    console.log("acceptRes", acceptRes);
                    this.setState({
                        showAcceptInviteOverlay: false,
                    });
                    const vipCountdownSeconds = parseInt(Utils.get(pr.roomItem, "vip_countdown_seconds", 65));

                    const limitOfVipVisit = Utils.getUnixTimestamp() + vipCountdownSeconds;
                    this.putSignal("activatecohost", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 1, false, limitOfVipVisit);

                    this.setMuteAudioVideo(st.selectedVipId, 0); // NOTE unmute

                    this.putSignal("handup", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, 0, (handupRes) => {
                        this.setState({
                            isHandRaised: false,
                        });

                        this.createSortedTable();
                    }, extraStrHandupAcceptCount);
                });

            }
        })
    }

    setMuteAudioVideo(userId, extraId) {
        // NOTE: extraId is optional, it will dictate state of
        if(!userId) {
            return;
        }
        this.putSignal("audiomuted", userId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, extraId);
        this.putSignal("videomuted", userId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, extraId);
    }

    declineInviteToStream() {
        const st = this.state;

        this.setState({
            showAcceptInviteOverlay: false,
        });

        this.putSignal("declineinvite", st.selectedVipId, Constant.ROOM_CMD_TYPE_COOKIED, null, (res) => {
            console.log("res", res);
            this.setState({
                showAcceptInviteOverlay: false,
            });

            this.putSignal("handup", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, 0, (res) => {
                this.setState({
                    isHandRaised: false,
                });
            });

            this.putSignal("invitecohost", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);

            if(st.isHandRaised) {
                this.putSignal("handup", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, 0);
            }
        });
    }

    selectTab(chatboxContent) {
        this.setState({chatboxContent: chatboxContent})
    }

    closeSoinModal() {
        this.setState({showSionModal: false});
    }

    backToEvents() {
        this.props.history.push("/home");
    }

    handleSubscribeClick(customer) {
        const st = this.state;
        const pr = this.props;

        const isCurrentlyFollowing = pr.user.following[customer.id];

        const data = {
            from_customer_id: Utils.get(pr, 'user.id'),
            to_customer_id: customer.id,
            follow: !isCurrentlyFollowing ? true : false,
        };

        let user = JSON.parse(JSON.stringify(pr.user));
        user.following[`${data.to_customer_id}`] = data;
        pr.setUser(user);

        Api.postFollow(data, (response) => {
            if(response.errors.length) {
                console.log("ERROR", response.data.errors);
                return;
            }

            let user = JSON.parse(JSON.stringify(pr.user));
            user.following = response.data;

            pr.setUser(user);
        });
    }

    chooseTabIcon(isSelectedTab, iconName) {
        switch(iconName) {
            case "star":
                return isSelectedTab ? starIconWhite : starIconPink
            case "comment":
                return isSelectedTab ? commentIconWhite : commentIconPink
            case "nft":
                return isSelectedTab ? leaderboardIconWhite : leaderboardIconPink
            case "users":
                return isSelectedTab ? usersIconWhite : usersIconPink
        }
    }

    showRemoveGuestButton(show) {
        setTimeout(() => {
            this.setState({
                showRemoveGuestButton: show,
            });
        }, 4000);
    }

    removeGuestFromStream() {
        const st = this.state;

        this.setState({
            showLivestreamMenu: false,
        });

        // NOTE make it so you can't click remove guest multiple times
        this.showRemoveGuestButton(true);

        // NOTE: saved separate variable to fix any possible async issue of st.selectedVipId being set to null
        const currentVipId = st.selectedVipId;
        this.putSignal("activatecohost", currentVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);
        this.setMuteAudioVideo(currentVipId, 0); // NOTE unmute
    }

    renderNoAudioOverlayHost() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const host = Utils.get(pr.roomItem, "insiders.host", {});
        if(st.audioMuted[host.id]) {
            return this.renderNoAudioOverlay(true);
        }

        return null;
    }

    renderNoAudioOverlayCoHost() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.vipIsInLiveStream && st.audioMuted[st.selectedVipId]) {
            return this.renderNoAudioOverlay(false);
        }

        return null;
    }

    renderNoAudioOverlay(isHost) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let noAudioStyle = null;
        let marginTop = null;
        if(isHost) {
            marginTop = {marginTop: pr.screen.mobile ? 55 : 20};
            noAudioStyle = {...sty.noAudioOverlay, ...marginTop};
        } else {
            console.log("marginTop cohost >>>", $("#hostContainer").height() + 20);
            marginTop = {marginTop: $("#hostContainer").height() + 20};
            noAudioStyle = {...sty.noAudioOverlay, ...marginTop};
        }

        return(
            <Icon
                className="noAudioOverlayCoHost"
                icon="audio-slash"
                style={noAudioStyle}
                size={18}
            />
        );
    }

    renderNoVideoOverlayHost() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const host = Utils.get(pr.roomItem, "insiders.host", {});
        if(st.videoMuted[host.id]) {
            return (
                <Div style={sty.noVideoOverlay}>
                    <Icon
                        className="newVideoControl"
                        icon="video-slash"
                        style={sty.videoIconOverlay}
                        size={40}
                    />
                </Div>
            );
        }
    }

    renderNoVideoOverlayCoHost() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.vipIsInLiveStream && st.videoMuted[st.selectedVipId]) {
            return (
                <Div style={{...sty.noVideoOverlay, ...{marginTop: 450}}}>
                    <Icon
                        className="newVideoControl"
                        icon="video-slash"
                        style={sty.videoIconOverlay}
                        size={40}
                    />
                </Div>
            );
        }
    }

    renderHostTile(type, imageUrl, title, size) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let tileStyle = {width:640,borderRadius:100,overflow:"hidden"};
        let nameBoxStyle = sty.nameBox;
        if(size) {
            tileStyle['width'] = size;
            tileStyle['height'] = size;
            if(size <= 200) {
                nameBoxStyle = sty.nameBoxSmall;
            }
        } else if(type=="sub") {
            tileStyle['width'] = 200;
            tileStyle['height'] = 200;
            nameBoxStyle = sty.nameBoxSmall;
        }

        let remoteContainer = StyleUtils.getMediaStyle('remoteContainer', sty.remoteContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return type == "host" ?
            <Div key={`host-tile-${title}`} className={`host-tile-${title}`} id="remote-container" ref={this.hostRef} style={remoteContainer}>
                {st.hostPicture ?
                    <Div style={{width: "100%"}}>
                        {false ?
                            <Image style={tileStyle} src={imageUrl} />
                            :
                            this.renderVideobox()
                        }
                    </Div>
                    :
                    null
                }
            </Div>
            :
            null
        ;
    }

    renderCustomer(guest) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const isMicrophone = guest["speak"];

        return (
            <CustomerProfile
                key={`ap_guest_${guest['id']}`}
                name={guest.full_name}
                username={guest.username}
                // image_url={guest.image_url}
                image_url={guest.photo}
                style={{width: "50%"}}
                microphoneIcon={isMicrophone}
                questionIcon
                size={56}
                width={pr.screen.width}
            />
        )
    }

    renderCustomers() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventRowDateCol = StyleUtils.getMediaStyle('eventRowDateCol', sty.eventRowDateCol, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomer(customerVip)
                })}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomer(customer)
                })}
            </Div>
        )
    }

    handleChatboxButtonClick(buttonType) {
        console.log("buttonType", buttonType);
        //Analytics.triggerGa();
        if(buttonType == 'gift') {
            this.setState({showTipModalType:"tipchooser"});
        } else if(buttonType == 'tipinfo') {
            this.setState({showTipModalType:"tipinfo"});
        } else if(buttonType == 'tipinfo2') {
            this.setState({showTipModalType:"tipinfo2"});
        } else if(buttonType == 'chatboxinfo') {
            this.setState({showTipModalType:"chatboxinfo"});
        } else if(buttonType == 'notvip') {
            this.setState({showTipModalType:"notvip"});
        }
    }

    handleToggleMedia(isLocal, mediaType) {
        const pr = this.props;
        const st = this.state;

        const userId = isLocal ? Utils.get(pr, 'user.id') : st.selectedVipId;

        let mediaMuted = Utils.deepCopy(mediaType == "audio" ? st.audioMuted : st.videoMuted);

        if(mediaMuted[userId]) { // NOTE if media is muted, unmute
            mediaMuted[userId] = 0;
        } else {
            mediaMuted[userId] = 1;
        }

        if(isLocal) {
            if(this.agoraStream && this.agoraStream[mediaType]) {
                if(!this.agoraStream[mediaType].muted) {
                    this.agoraStream[mediaType].setMuted(true);
                } else {
                    this.agoraStream[mediaType].setMuted(false);
                }
            } else {
                ll._("There is no Agora audio stream", "red");
                return;
            }
        }

        if(mediaType == "audio") {
            this.setState({audioMuted: mediaMuted});
        } else {
            this.setState({videoMuted: mediaMuted});
        }
        this.putSignal(`${mediaType}muted`, userId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, mediaMuted[userId]);
    }

    chatAction(cmd, customerId) {
        const st = this.state;

        let mutes = st.audioMuted;
        let muted = 1;
        console.log("mutes >>>>", mutes);
        mutes[customerId] = mutes[customerId] ? false : true;
        this.setState({audioMuted: mutes});
        if(!mutes[customerId]) {
            ll._("Unmute >>>>", "green");
            muted = 0;
        }

        this.putSignal("muted", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, muted);
    }

    openSoinModal(listing, i) {
        console.log("openSoinModal", listing, i);
        this.setState({
            showSionModal: true,
            currentSoin: listing,
            currentSoinIndex: i,
        });
    }

    goToSignIn(noResetRoom, guestParam) {
        const pr = this.props;

        if(!noResetRoom) {
            pr.resetRoomLoaded();
        }
        let url = `/login?next=${pr.history.location.pathname.slice(1)}`;
        if(Utils.getRequest('guest')) {
            url += "^guest="+Utils.getRequest('guest');
        }
        url += "&refresh=1";

        //Utils.gotoRoute(pr, url);
        pr.history.push(url);
    }

    renderManageGuests(guests) {
        return guests.map((guest) => {
            return (
                <Div key={`ap_guest_${guest['id']}`} style={{position:"relative", display:"flex"}}>
                    <Div style={{margin:8,borderRadius:8,overflow:"hidden",backgroundImage: 'url('+guest["photo"]+')',backgroundSize: "cover",height: 48, width:48,color: "#f5f5f5"}}>
                    </Div>
                    <Div style={{flex: 3,fontSize:10,textAlign:"center",marginTop:20,color:"black"}}>
                        {guest["username"]}
                    </Div>
                </Div>
            );
        });
    }

    renderStats() {
        const pr = this.props;
        const st = this.state;

        return (
            <Div>
                <Div>Guests: {pr.roomItem && pr.roomItem["guests"] ? pr.roomItem["guests"].length : 0}</Div>
                <Div>Guests online: {st.guestsOnline}</Div>
            </Div>
        );
    }

    handleOnlineUsersChange(onlineUsers, userCount) {
        /*
        const customerDict = Utils.arrayToObject(this.props.roomItem["customers"], "id");
        let ou = [];
        for(var idx in onlineUsers) {
            if(idx in customerDict) {
                ou.push(customerDict[idx]);
            }
        }
        */
        this.setState({
            onlineUsers: onlineUsers,
            numPeopleInStream: userCount,
        });
    }

    selectVipForStream(vipId) {
        const pr = this.props;
        const st = this.state;

        if(!st.agoraActive) {
            return;
        }

        this.setState({
            showInviteToStreamPopUp: true,
        });

        this.setState({
            viewedVipId: vipId,
        });

        if(st.isHost && !st.disableAllInviteButtons) {
            this.setState({
                selectedVipId: vipId,
            });
        }
    }


    renderVideoIcons() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="videoIconsContainer" style={sty.videoIconsContainer}>
                {/* {st.videoIcons.video.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        size={24}
                        icon={st.videoIcons.video.isOn ? "video" : "video-slash"}
                        onClick={this.handleVideoClick.bind(this)}
                    />
                    :
                    null
                }

                {st.videoIcons.audio.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        icon={st.videoIcons.audio.isOn ? "audio" : "audio-slash"}
                        onClick={this.handleAudioClick.bind(this)}
                    />
                    :
                    null
                } */}

                {st.videoIcons.tv.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Share Screen"
                        icon={st.videoIcons.tv.isOn ? "tv" : "times"}
                        onClick={this.handleShareScreenClick.bind(this)}
                    />
                    :
                    null
                }

                {st.videoIcons.desktop.isShown && false ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Switch Display"
                        icon={st.videoIcons.desktop.isOn ? "desktop" : "power-off"}
                    />
                    :
                    null
                }

                {st.videoIcons.exit.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Leave"
                        icon="sign-out"
                        onClick={this.handleExitClick.bind(this)}
                    />
                    :
                    null
                }

                {pr.roomItem.insiders.host.id === Utils.get(pr, 'user.id') ?
                    <Div style={sty.videoEndEventButton}>
                        <Icon title="End Event" icon="times" size={16} />
                    </Div>
                    :
                    null
                }
            </Div>
        )
    }

  //   renderHostControls() {
  //       const pr = this.props;
  //       const st = this.state;
  //       const sty = this.styles;
  //
  //       const listings = pr.roomItem['listings'] ? pr.roomItem['listings'] : [];
  //
  //       const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
  //
  //       return (
  //         <Div className="hostControls" style={null}>
  //             {this.renderHostTile("host", pr.roomItem["image_url"], pr.roomItem['insiders']['host']["username"], 200) }
  //             { false ?
  //                   this.renderVideoIcons()
  //                   :
  //                   null
  //             }
  //             {false ?
  //                 <Div>
  //                     <Div style={Styles.subhead}>Moderators</Div>
  //                     <Div style={{display:"flex"}}>
  //                       {pr.roomItem['insiders']['moderators'].map((mod) => {
  //                           return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
  //                       })}
  //                     </Div>
  //                 </Div>
  //                 :
  //                 null
  //             }
  //             <Div style={{...afterpartyRoomInfoContainer, ...{marginTop: 30}}}>
  //                 <Div
  //                   style={{...Styles.subhead, ...{marginBottom: 20}}}
  //               >
  //                   Admission Nfts
  //               </Div>
  //                 <Div style={sty.admissionSoins}>
  //                     <Scroller
  //                       items={this.renderSoinsInScroller()}
  //                       numSlots={6}
  //                       centerSlidePercentage={100}
  //                       showThumbs={false}
  //                       useKeyboardArrows={true}
  //                       autoPlay={false}
  //                       emulateTouch={true}
  //                       showStatus={false}
  //                       showIndicators={false}
  //                       whiteArrows={true}
  //                       minHeight={120}
  //                       marginLeft={40}
  //                       scrollerStyle={{display: "flex", flexDirection: "row", width: "100%"}}
  //                   />
  //                 </Div>
  //             </Div>
  //
  //             { false ?
  //                 <Div style={afterpartyRoomInfoContainer}>
  //                     <Div style={Styles.subhead}>Stats</Div>
  //                     {this.renderStats()}
  //                 </Div>
  //                 :
  //                 null
  //             }
  //         </Div>
  //     )
  // }

    renderSoinsInScroller() {
      const listings = this.props.roomItem['listings'] ? this.props.roomItem['listings'] : [];
      return (
          listings.map((listing, i) => {
              return (
                  <Div
                      key={`${i}-${Math.random()}`}
                      style={{
                          // clipPath:"url(#bloated-circle-125)",
                          width: 125,
                          height: 125,
                          marginRight: 20,
                          flexShrink: 0,
                          backgroundImage: `url(${listing['image_url']})`,
                          cursor: "pointer",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 32,
                      }}
                      onClick={this.openSoinModal.bind(this, listing, i)}
                  >
                  </Div>
              )
          })
      )
  }

    renderChatboxTabs(showHelpTab) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const tabStyle = StyleUtils.getMediaStyle('tabStyle', sty.tabStyle, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="tabsContainer" style={sty.tabsContainer}>
                <Div
                    style={st.chatboxContent == "messages" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "messages")}
                >
                    Messages
                </Div>
                <Div
                    style={st.chatboxContent == "participants" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "participants")}
                >
                    Participants
                </Div>
                {showHelpTab ?
                    <Div
                        style={st.chatboxContent == "help" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                        onClick={this.selectTab.bind(this, "help")}
                    >
                        Help
                    </Div>
                    :
                    null
                }
            </Div>
       );
  }

    renderCustomerInHostMode(guest) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const isMicrophone = guest["speak"];

        const hostButtonStyles = {
            ...Styles.btn,
            ...Styles.btnTiny,
        };

        const guestId = guest['id'];

        return (
            <Div key={`customer-in-host-${guestId}`} style={{display: "flex", width: "100%", alignItems: "center"}}>
                <CustomerProfile
                    key={`ap_guest_${guestId}`}
                    name={guest.full_name}
                    username={guest.username}
                    image_url={guest.photo}
                    style={{marginRight: "auto"}}
                    microphoneIcon={isMicrophone}
                    size={56}
                    width={pr.screen.width}
                    questionIcon
                />
                { false ?
                    <Button
                        style={{marginRight: 8}}
                        onClick={this.chatAction.bind(this, "kick", guestId)}
                        color="white"
                        size="small"
                    >
                        Kick
                    </Button>
                    :
                    null
                }
                { false ?
                    <Button
                        style={{marginRight: 0}}
                        onClick={this.chatAction.bind(this, "block", guestId)}
                        color="black"
                        size="small"
                    >
                        Block
                    </Button>
                    :
                    null
                }
            </Div>
        )
    }

    renderCustomersInHostMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomerInHostMode(customerVip)
                })}
                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomerInHostMode(customer)
                })}
            </Div>
        )
    }

  renderVideobox() {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      let attendeeMode = Utils.get(pr.roomItem, "insiders.isInsider") ? "video" : "audience";
      if(st.mode == 'host') {
          attendeeMode = 'video';
      }
      //attendeeMode = 'video';
      const host = Utils.get(pr.roomItem, "insiders.host", []);
      const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);
      const customers = Utils.get(pr.roomItem, "customers", []);
      const customersVip = Utils.get(pr.roomItem, "customers_vip", []);
      const vipDict = Utils.arrayToObject(customersVip, 'id');
      if(Utils.get(pr, 'user.id') in vipDict) {
          attendeeMode = 'video';
      }
      let users = Utils.arrayToObject([host], 'id');
      Utils.arrayToObject(cohosts, 'id', users);
      Utils.arrayToObject(customers, 'id', users);
      Utils.arrayToObject(customersVip, 'id', users);
      //ll._("renderVideobox agora with users (host/customer/vip): ", "blue", users);
      const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");

      const videoContainer = StyleUtils.getMediaStyle('videoContainer', sty.videoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
      const vipPlaceholder = [{id: 0}];

      return (
          <Div style={videoContainer} className="AgoraCall videoContainer">
              <AgoraVideoCall
                key={`agora-${st.agoraKey}`}
                screen={pr.screen}
                videoProfile={this.videoProfile}
                channel={this.channel}
                transcode={this.transcode}
                attendeeMode={attendeeMode}
                baseMode={this.baseMode}
                appId={this.appId}
                roomItem={pr.roomItem}
                roomBackgroundImageUrl={Utils.get(pr, "roomItem.waiting_room_image_url")}
                // uid={this.uid}
                displayMode={'afterparty'}
                customerId={Utils.get(pr.user, 'id')}
                changeLocalStream={this.handleChangeAgoraStream.bind(this)}
                changeClient={this.handleChangeAgoraClient.bind(this)}
                user={pr.user}
                users={users} // list of users by customer id
                host={host}
                // cohosts={cohosts}
                cohosts={vipPlaceholder}
                customersVip={customersVip}
                agoraActive={st.agoraActive}
                agoraEvents={this.handleChangeAgoraEvent.bind(this)}
                version="v2"
                selectTabRoomMobile={this.selectTabRoomMobile.bind(this)}
                isInsider={isInsider}
                selectVipForStream={this.selectVipForStream.bind(this)}
                selectedVipId={st.selectedVipId}
                clickedStartLiveStream={st.clickedStartLiveStream}
              />
          </Div>
      )
     }

    renderCustomersInChatboxHostMode() {
        const pr = this.props;
        const sty = this.styles;

        // {pr.roomItem['insiders']['cohosts'].map((host) => {
        //     return this.renderHostTile("sub", host["photo"], host["username"], 100);
        // })}
        // {pr.roomItem['insiders']['mods'].map((mod) => {
        //     return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
        // })}

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem.customers || pr.roomItem.customers_vip ?
                    this.renderCustomersInHostMode()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    renderCustomersInChatboxGuestMode() {
        const pr = this.props;
        const sty = this.styles;

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem["customers"] || pr.roomItem["customers_vip"] ?
                    this.renderCustomers()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    renderTestInfo() {
        const pr = this.props;
        const st = this.state;

        if(!st.xrayMode) {
            return null;
        }
        if(Utils.get(pr, "roomItem.xrayAvailable") != 1) {
            return null;
        }

        if(!Utils.get(pr, "roomItem.id")) {
            return;
        }

        const userId = Utils.get(pr, "user.id");
        const roomId = Utils.get(pr, "roomItem.id");

        const elUser = $("#ag-item-" + userId);
        let elUserStatus = "No element";
        if(elUser && elUser[0]) {
            elUserStatus = StyleUtils.colorBoolean(elUser[0].notPlayingVideo);
        }

        let collapseButton = (
            <Button
                size="small"
                style={{marginRight: 8}}
                onClick={() => {
                    Utils.setCookie('collapseXray', (this.state.collapseXray ? 0 : 1) );
                    this.setState({collapseXray: !this.state.collapseXray})}
                }
            >
                Collapse
            </Button>
        )
        if(st.collapseXray) {
            return (
                <Div style={{position: "absolute", width: 100, bottom: 0, zIndex: 1055,display:"flex", backgroundColor: Colors.purpleDark, padding: 10, borderRadius: 8, marginBottom:8, flexWrap: "wrap",}}>
                    {collapseButton}
                </Div>
            )
        }
        const groupEnd = {marginBottom:10};
        //ll.silent = true;
        const agoraStats = this.agoraClient ? this.agoraClient.getRTCStats() : {};

        let stats = [
            {id:0, title: "Updated", val: Utils.time('hh:mm:ss'), style:groupEnd},
            {id:1, title: "Room", val:  `${pr.roomItem.title} / ${pr.roomItem.id}`},
            {id:2, title: "Customer", val:  String(Utils.get(pr.user, 'username') + " / " + userId + " / " + Utils.generateClientUid(userId) ), style:groupEnd},

            {id:3, title: "Host", val:  String(Utils.get(pr, "roomItem.insiders.host.username", '-') +"/" + Utils.get(pr, 'roomItem.insiders.host.id', '-'))},
            {id:4, title: "Cohost", val:  String("-")},
            {id:5, title: "# VIPs", val:  String(Utils.get(pr, "roomItem.customers_vip", []).length), style:groupEnd},
            //{id:6, title: "Mode", val:  String(st.mode), style:groupEnd},

            {id:73, title: "isInsider", val: Utils.get(pr, "roomItem.insiders.isInsider"), display:  StyleUtils.colorBoolean(Utils.get(pr, "roomItem.insiders.isInsider"))},
            {id:7, title: "isHost", val: st.isHost, display:  StyleUtils.colorBoolean(st.isHost)},
            {id:71, title: "isVip", val: st.isVip, display:  StyleUtils.colorBoolean(st.isVip)},
            {id:72, title: "isSelectedVip", val:  st.selectedVipId == userId, display:  StyleUtils.colorBoolean(st.selectedVipId == userId)},
            {id:8, title: "User NotPlayg", val:  `${elUser[0] ? String(elUser[0].notPlayingVideo) : "No Element"}`, display:elUserStatus, style:groupEnd},

            {id:9, title: "Agora Active", val:st.agoraActive, display:  StyleUtils.colorBoolean(st.agoraActive)},
            {id:10, title: "Agora Client ID", val:  `${st.clientId ? st.clientId : "Null"}`},
            {id:11, title: "LocalStream", val:  `${this.agoraStream ? "Exists" : "Null"}`},
            //{id:12, title: "Mode", val:  `${attendeeMode}`, style:groupEnd},
            {id:13, title: "W/H", val:  `${pr.screen.width} h: ${pr.screen.height} wt: ${StyleUtils.getWidthType(pr.screen.width)}`},

            {id:20, title: "joinedAudio", val:st.joinedAudio, display:  StyleUtils.colorBoolean(st.joinedAudio)},
            {id:21, title: "vipIsIn LiveStream", val:st.vipIsInLiveStream, display: StyleUtils.colorBoolean(st.vipIsInLiveStream)},
            {id:22, title: "isHandRaised", val: st.isHandRaised, display:  StyleUtils.colorBoolean(st.isHandRaised)},
            {id:23, title: "selectedVipId", val:  st.viewedVipId},
            {id:24, title: "viewedVipId", val:  `${elUser[0] ? String(elUser[0].notPlayingVideo) : "No Element"}`},
            {id:25, title: "Out Avail BandWi", val:  `${this.agoraClient ? this.agoraClient.getRTCStats().OutgoingAvailableBandwidth : "No Stats"} Kbps`},
            {id:26, title: "Send Bitrate", val:  `${this.agoraClient ? this.agoraClient.getRTCStats().SendBitrate / 1000: "No Stats"} Kb`},
            {id:27, title: "Recv Bitrate", val:  `${this.agoraClient ? this.agoraClient.getRTCStats().RecvBitrate / 1000: "No Stats"} Kb`},
            {id:28, title: "Call Duraction", val:  Utils.get(agoraStats, "Duration")},
            {id:29, title: "Network Qual Interval", val:  `${this.agoraClient ? this.agoraClient._networkQualityInterval : "No Stats"}`},
            {id:30, title: "Network Qual Sensitivity", val:  `${this.agoraClient ? this.agoraClient._networkQualitySensitivity : "No Stats"}`, style:groupEnd},
            {id:31, title: "OS Name", val:  `${Utils.get(st, "userDevice.os.name")}`},
            {id:32, title: "Device Type", val:  `${Utils.get(st, "userDevice.device.type")}`},
            {id:42, title: "Web Client Type", val:  `${Utils.get(st, "userDevice.client.type")}`},
            {id:43, title: "Web Client Name", val:  `${Utils.get(st, "userDevice.client.name")}`},
            {id:44, title: "isAndroid", val: st.isAndroid, display: StyleUtils.colorBoolean(st.isAndroid), style:groupEnd},
        ]
        if(!window.testStats) {
            window.testStats = stats;
        }
        // Highlight changes since last update
        for(var idx in window.testStats) {
            if(testStats[idx]['title'] == "Updated") {
                //console.log("DIFF", window.testStats[idx]['title'], window.testStats[idx]['val'], stats[idx]['val']);

            }
            if(window.testStats[idx]['val'] != stats[idx]['val']) {
                //console.log("DIFF", window.testStats[idx]['title'], window.testStats[idx]['val'], stats[idx]['val']);
                stats[idx]['style'] = {borderRight:"1px solid yellow", borderBottom:"1px solid yellow"};
            }
        }
        //window.testStats = stats;
        if(!window.chatSignals) {
           window.chatSignals = {};
        }
        let cSignals = [];
        for(var idx in window.chatSignals) {
            cSignals.push(window.chatSignals[idx]);
        }
        const onlineCustomerIds = st.onlineUsers.join(",")

        return (
            <Div className="testInfoBox" style={{ ...this.styles.testInfoBox, ...{maxHeight: this.state.testWindowHeight} }}>
                <Div style={{marginBottom: 10, display: "flex", gap:8}}>
                    {collapseButton}
                    <Button
                        size="small"
                        onClick={() => {this.setState({testWindowHeight:"40%"});} }
                    >
                        Short Window
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {this.setState({testWindowHeight:"80%"});} }
                    >
                        Tall Window
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {Api.pushLogsToServer()}}
                    >
                        Push Logs
                    </Button>
                </Div>



                <Div style={{display:"flex",flexDirection:"row",}}>
                    <Div className="statsPanel" style={this.styles.testInfoPanel}>
                        {stats.map((stat) => {
                            return (
                                <Div key={`stat_row_${stat['id']}`} style={ { ...this.styles.testInfoStatRow, ...stat['style'] } }>
                                    <Div style={{flex:1,color:"lightgray"}}>{stat['title']}</Div>
                                    <Div style={{flex:2,}}>{stat['display'] ? stat['display'] : stat['val']}</Div>
                                </Div>
                            );
                        })}
                    </Div>
                    { true ?
                        <Div style={this.styles.testInfoPanel}>
                            <Div style={{flex:1, display:"flex", flexDirection: "column"}}>
                                {this.printStreamDict()}
                                {this.printNotPlayingVideo()}
                            </Div>
                        </Div>
                        :
                        null
                    }
                    { true ?
                        <Div style={ { ...this.styles.testInfoPanel, ...{minWidth:400} } }>
                            <Div
                                onClick={ () => {
                                    Utils.gotoRoute(pr, "/chorus/room_chat_signal?room_id="+roomId, '_blank')}
                                }
                                style={{ ...Styles.ap_text__subheading3, ...{cursor:"pointer"} }}
                                >
                                Chat Signals
                            </Div>
                            <Div style={{margin:8,padding:8,borderRadius:12,minHeight:"90%", backgroundColor:"rgba(0,0,0,0.3)"}}>
                                {cSignals.map((stat) => {
                                    return (
                                        <Div key={`chat_signal_row_${stat['id']}`} style={ { ...this.styles.testInfoStatRow, ...stat['style'] } }>
                                            <Div title="Already run -- click to delete" style={this.styles.testInfoStatCol}>
                                                {stat['cmd_type'] == 1 && Utils.getCookie("s-"+stat['id']) ?
                                                    <span onClick={ () => { Utils.deleteCookie("s-"+stat['id']); } }><Icon icon="check" size={10} /></span>
                                                    :
                                                    ""
                                                }
                                            </Div>
                                            <Div
                                                onClick={ () => {
                                                    Utils.gotoRoute(pr, "/chorus/room_chat_signal?room_id="+roomId+"&ids="+stat['id'], '_blank')}
                                                }
                                                title="ID"
                                                style={{ ...this.styles.testInfoStatCol, ...{cursor:"pointer"} }}>{stat['id']}</Div>
                                            <Div title="Cmd Type" style={this.styles.testInfoStatCol}>{stat['cmd_type'] == 1 ? "1x" : stat['cmd_type']}</Div>
                                            <Div title="Cmd" style={this.styles.testInfoStatCol}>{stat['cmd']}</Div>
                                            <Div title="Customer ID" style={this.styles.testInfoStatCol}>{Utils.get(stat, 'customer_username', '-')}</Div>
                                            <Div title="Customer ID" style={this.styles.testInfoStatCol}>{Utils.get(stat, 'customer_id', '-')}</Div>
                                            <Div title="Browser ID" style={this.styles.testInfoStatCol}>{Utils.get(stat, 'browser_id', '-')}</Div>
                                            <Div title="Extra ID" style={this.styles.testInfoStatCol}>{Utils.get(stat, 'extra_id', '-')}</Div>
                                        </Div>
                                    );
                                })}
                            </Div>
                            { false ?
                                <Div style={{marginTop:6,marginBottom:6,}}>
                                    <Button onClick={this.handleClearAllChatSignals.bind(this)} size="small">Clear All Chat Signals</Button>
                                </Div>
                                :
                                null
                            }

                        </Div>
                        :
                        null
                    }
                    { true ?
                        <Div style={ { ...this.styles.testInfoPanel, ...{minWidth:300} } }>
                            <Div
                                onClick={ () => { Utils.gotoRoute(pr, "/chorus/customer?ids="+onlineCustomerIds, '_blank')} }
                                style={{ ...Styles.ap_text__subheading3, ...{cursor: "pointer", borderBottom: "1px solid gray"} }}>
                                    Online Users ({st.onlineUsers.length})
                            </Div>
                            <Div style={{margin:8,padding:8,borderRadius:12,minHeight:"90%", backgroundColor:"rgba(0,0,0,0.3)",display:"flex",flexDirection:"row",gap:"2px 6px"}}>
                                {st.onlineUsers.map((customerId) => {
                                    let customer = {id:customerId};
                                    return (
                                        <Div onClick={ () => {
                                                Utils.gotoRoute(pr, "/chorus/customer?ids="+customer['id'], '_blank')}
                                            }
                                            key={`online_user_row_${customer['id']}`} style={{cursor:"pointer", borderBottom:"1px solid gray"}}>
                                            <Div title="ID" style={this.styles.testInfoStatCol}>{customer['id']}</Div>
                                        </Div>
                                    );
                                })}
                            </Div>

                        </Div>
                        :
                        null
                    }
                </Div>
            </Div>
        );
    }

    renderErrorMsg() {
        if(!this.state.errors || this.state.errors.length == 0) {
            return null;
        }
        return (
            <Div style={{padding:12,textAlign:"center",margin:6,borderRadius:6,width:"100%",color:"black",backgroundColor:"yellow"}}>
                Error: {Utils.get(this.state.errors, '0.msg')}
            </Div>
        );
    }

    printStreamDict() {
        let print = [];
        let sd = window.streamDict;
        if(!sd || Object.keys(sd).length == 0) {
            return <Div  style={{marginBottom:12, marginLeft:12}}>  No streamDict</Div>;
        }

        for(const id in sd) {
            let videoMuted = "null";
            let audioMuted = "null";

            let isLocal = undefined;
            if(Utils.get(sd[id], "0.muted") === true || Utils.get(sd[id], "0.muted") === false) {
                isLocal = true;
                videoMuted = Utils.get(sd[id], "0.muted", "null").toString();
                audioMuted = Utils.get(sd[id], "1.muted", "null").toString();
            } else {
                isLocal = false;
                videoMuted = (Utils.get(sd[id], "0", null) == null) ? "null" : "Exists"
                audioMuted = (Utils.get(sd[id], "1", null) == null) ? "null" : "Exists"
            }
            print.push(
                <Div key={`printStreamDict-${id}`}>
                    <Div style={{marginLeft: 15}}>ID: {id}</Div>
                    <Div style={{marginLeft: 30}}>{isLocal ? "Video Muted: " : "Video Track:  "} {videoMuted}</Div>
                    <Div style={{marginLeft: 30}}>{isLocal ? "Audio Muted: " : "Audio Track:  "} {audioMuted}</Div>
                </Div>
            );
        }

        return (
            <Div className="streamDict Panel">
                <Div style={Styles.ap_text__subheading3}>streamDict</Div>

                <Div className="streamDictPrint" style={{marginBottom:12, marginLeft:12}} data-length={Object.keys(window.streamDict).length}>
                    {print}
                </Div>
            </Div>
        )
    }

    printNotPlayingVideo() {
        let print = [];
        let sd = window.streamDict;
        if(!sd) {
            return "No streamDict";
        }

        let vipsDict = {};
        const vips = Utils.get(this.props, "roomItem.customers_vip", []);
        for(var idx in vips) {
            var vip = vips[idx];
            vipsDict['ag-item-'+vip['id']] = vip;
        }


        let jHost = $(".hostAvatar");
        let jCohost = $(".cohostAvatar");
        let jVips = $(".vipAvatar");
        if(jHost[0]) {
            print.push(
                <Div style={{borderBottom:"1px solid blue"}}>
                    Host: {jHost[0].id}: {StyleUtils.colorBoolean(!jHost[0].notPlayingVideo)}
                </Div>
            )
        }
        if(jCohost[0]) {
            print.push(
                <Div style={{borderBottom:"1px solid purple"}}>
                    VIP: {jCohost[0].id}: {StyleUtils.colorBoolean(!jCohost[0].notPlayingVideo)}
                </Div>
            )
        }

        print.push(<br/>)

        for(var i = 0; i < jVips.length; i++) {
            if(jVips[i]) {
                print.push(
                    <Div>
                        {jVips[i].id} / {Utils.get(vipsDict[jVips[i].id], 'username', '')} : {StyleUtils.colorBoolean(!jVips[i].notPlayingVideo)}
                    </Div>
                )
            }
        }

        return (
            <Div className="notPlaying Panel">
                <Div style={Styles.ap_text__subheading3}>Playing (DOM)</Div>
                <Div className="noPlayPrint" style={{marginBottom:12, marginLeft:12}} data-length={jVips.length}>
                    {print}
                </Div>
            </Div>
        );
    }


    renderJoinMeeting() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const joinMeetingButton = StyleUtils.getMediaStyle('joinMeetingButton', sty.joinMeetingButton, sty, StyleUtils.getWidthType(pr.screen.width));
        const joinMessage = Utils.get(this.props.roomItem, "event.join_message");

        if(!st.agoraActive) {
            return (
                <Div>
                    { false ?
                        <Div style={{color:"black", fontSize:18, textAlign:"center", marginBottom:18}}>
                            Event will start at 3:00pm PST
                        </Div>
                        :
                        null
                    }
                    { joinMessage && joinMessage.length > 1 ?
                        <Div style={{color:"black", fontSize:18, textAlign:"center", marginBottom:18}}>
                            {joinMessage}
                        </Div>
                        :
                        null
                    }
                </Div>
            )
        } else if(st.agoraActive && !pr.screen.mobile) {
            // return <Div style={{height: 78}}></Div>
            return null;
        } else {
            return null;
        }
    }

    handleMostRecentMessage(message) {
        this.setState({mostRecentMessage: message});
    }

    changePost(e) {
        this.setState({post: e.target.value});
    }

    keyDownPost(e) {
        if(e.key === 'Enter') {
            this.handleChatPost();
            return;
        }
    }

    handleChatPost(message, messageType, tipAmount) {
        const st = this.state;
        const pr = this.props;

        message = message ? message : st.post;
        messageType = messageType != 2 ? 1 : 2;
        // messageType 2 can send a blank message
        if(messageType != 2 && (message.length == 0 || message == st.lastPost || st.posting)) {
            return;
        }

        let data = {
            body: message,
            room_id: pr.roomItem["id"],
            messageType: messageType,
            tipAmount: tipAmount,
        };
        this.setState({posting: true});

        Api.postRoomChat(data, (o) => {
             if(o && o.data) {
                 let tempState = {
                     mostRecentMessage: o.data[o.data.length - 1],
                     post: "",
                     lastPost: st.post,
                     posting: false,
                 }
                 if(tipAmount) {
                     tempState['leaderboardKey'] = this.state.leaderboardKey + 1;
                 }
                 this.setState(tempState);
             }
        });
    }

    toggleLivestreamMenu() {
        this.setState({
            showLivestreamMenu: !this.state.showLivestreamMenu,
        });
    }

    handleCloseComingSoonModal() {
        this.setState({
            showComingSoonModal: false,
        });
    }

    showComingSoonModal() {
        this.setState({
            showComingSoonModal: true,
        });
    }

    raiseHand() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.handleHandUpDown();
    }

    renderUsername(message, customerDict) {
        let username = message['customer_id'];
        if(message['username']) {
            username = message['username'];
        } else if(message['customer_id'] in customerDict) {
            username = customerDict[message['customer_id']]['username'];
        }
        return `@${Utils.formatUsername(username)}`;

    }

    renderAvatar(message, customerDict) {
        const sty = this.styles;

        let avatarStyle = null;
        const avatarBaseStyle = {backgroundSize: "cover", backgroundPosition: "center"};
        const defaultApLogoImage = "/images/afterparty_logo_big_landscape.jpg";
        if(message.photo) {
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${Colors.magenta}`, backgroundImage: `url(${message.photo})`},};
        } else {
            const avatarColor = Colors.colorIndex[message['customer_id'] % Colors.colorIndex.length];
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${avatarColor}`, backgroundImage: `url("${defaultApLogoImage}")`}};
        }

        return <Div style={avatarStyle} className="avatarStyle"></Div>
    }

    renderCustomerBadges(customerId) {

        const badgeTypes = this.props.roomItem["badgeTypes"];
        const customer = this.state.customersVipDict[customerId];
        const badgeList = Utils.get(customer, 'badges', []);
        //console.log("BADGECHECK", customer,badgeList);
        if(badgeList.length > 0) {
            //return <Div>BADGE</Div>
        } else {
            return null; //<Div>NO</Div>;
        }
        let activeBadges = [];

        //console.log("BADGES", badgeList, badgeTypes);
        const height = 18
        for(var idx in badgeList) {
            const curBadge = badgeList[idx];
            if(curBadge in badgeTypes) {
                activeBadges.push(<img key={`userbadge-${customerId}-${Math.random()}`} style={{height:height,paddingTop:10,paddingBottom:10,paddingLeft:10}} src={badgeTypes[badgeList[idx]]['image_url']} />)
            } else {
                console.log("Missing badge");
            }
        }
        if(activeBadges.length > 0) {
            //badges = activeBadges;
        }
        return activeBadges;

    }
    renderBadges(message, customerDict) {
        const pr = this.props;
        const badgeTypes = pr.roomItem["badgeTypes"];

        let badges = null;

        if(message['customer_id'] in customerDict && badgeTypes && customerDict[message['customer_id']]['badges'].length > 0 ) {
            const badgeList = customerDict[message['customer_id']]['badges'];
            let activeBadges = [];
            for(var idx in badgeList) {
                if(badgeList[idx] in badgeTypes) {
                    activeBadges.push(<img key={`${message.id}-${Math.random()}`} style={{width:24,height:24,padding:2}} src={badgeTypes[badgeList[idx]]['image_url']} />)
                }
            }
            if(activeBadges.length > 0) {
                badges = activeBadges;
            }
        }
        return badges;
    }

    closeInviteVipToStreamModal() {
        this.setState({
            showInviteToStreamPopUp: false,
        });
    }

    handleToggleMostRecent() {
        this.setState({expandMostRecentMessageInput: !this.state.expandMostRecentMessageInput});
    }

    addEmojiToChat(emoji) {
        this.setState({post: this.state.post += emoji});
    }

    selectTabRoomMobile(tabName) {
        Utils.setCookie("selectedTabMobileRoom", tabName);
        this.setState({
            selectedTabMobileRoom: tabName,
            autoScroll: false,
        });
    }

    changeChatDirection() {
        const st = this.state;

        this.setState({
            isChatboxInputAbove: !st.isChatboxInputAbove,
            autoScroll: false,
        });
    }

    withdrawInviteToStream() {
        const st = this.state;

        this.setState({
            selectedVipId: null,
            disableAllInviteButtons: false,
            showAcceptInviteOverlay: false,
            showWithdrawInviteButton: false,
            showInviteToStreamPopUp: false,
        });

        this.putSignal("withdrawinvite", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 1);
        this.putSignal("invitecohost", st.selectedVipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 0);
    }

    getDefaultSignal(cmd, customerId, cmdType, extraId) {
        ll._("Sending Signal::cmd, customerId, extraId", "pink", cmd, customerId, extraId);
        let modalData = {
            cmd: cmd,
            roomCrc: this.roomCrc,
            browser_id: Utils.getBrowserId(true),
        };

        if(customerId != null) {
            modalData.customerId = customerId;
        }
        if(cmdType != null) {
            modalData.cmd_type = cmdType;
        }
        if(extraId != null) {
            modalData.extra_id = extraId;
        }
        return modalData;
    }


    putSignal(cmd, customerId, cmdType, extraId, callback, extraStr) {
        ll._("Sending Signal::cmd, customerId, extraId", "pink", cmd, customerId, extraId);
        let modalData = {
            cmd: cmd,
            roomCrc: this.roomCrc,
        };

        if(customerId != null) {
            modalData.customerId = customerId;
        }
        if(cmdType != null) {
            modalData.cmd_type = cmdType;
        }
        if(extraId != null) {
            modalData.extra_id = extraId;
        }
        if(extraStr != null) {
            modalData.extra_str = extraStr;
        }

        Api.putRoomChatSignal(this.roomCrc, modalData, (response) => {
            if(callback) {
                callback(response);
            }

            if(Utils.get(response, "success")) {
            } else {
                console.log("putSignal()::SUCCESS IS 0 (is this broken?)");
            }
        });
    }

    inviteUserToStream(vipId) {
        const st = this.state;

        if(vipId) {
            this.setState({
                selectedVipId: vipId,
            });
        }

        this.setState({
            disableAllInviteButtons: true,
            showInviteToStreamPopUp: false,
            showWithdrawInviteButton: true,
        });

        vipId = vipId ? vipId : st.selectedVipId;
        this.putSignal("invitecohost", vipId, Constant.ROOM_CMD_TYPE_TOGGLE_ALL, 1);

        // let modalData = {
        //     cmd: "invitecohost",
        //     roomCrc: this.roomCrc,
        //     extra_id: 1,
        //     customerId: vipId ? vipId : st.selectedVipId,
        // };
        //
        // Api.putRoomChatSignal(this.roomCrc, modalData, (response) => {
        //     if(Utils.get(response, "success")) {
        //     } else {
        //         console.log("ERROR INVITING USER TO STREAM");
        //     }
        // });
    }

    setChatTabSelected(chatTabSelected) {
        // NOTE: currently unused, but might be useful in the future
        this.setState({
            chatTabSelected: chatTabSelected,
        });
    }

    renderMostRecentMessage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.selectedTabMobileRoom != "none") {
            return null;
        }

        const customerDict = Utils.arrayToObject(pr.roomItem["customers"], "id");

        // const roundedBorders = !st.expandMostRecentMessageInput ? {borderBottomLeftRadius: 22, borderBottomRightRadius: 22, overflow: "hidden"} : null;
        const roundedBorders = {borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: "hidden"};

        const inputContainer = StyleUtils.getMediaStyle('inputContainer', sty.inputContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const recentMessageContainer = StyleUtils.getMediaStyle('recentMessageContainer', sty.recentMessageContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const messageStyle = StyleUtils.getMediaStyle('messageStyle', sty.messageStyle, sty, StyleUtils.getWidthType(pr.screen.width));

        const messageContainer = StyleUtils.getMediaStyle('messageContainer', sty.messageContainer, sty, StyleUtils.getWidthType(pr.width));
        // const messageStyle = StyleUtils.getMediaStyle('message', sty.message, sty, StyleUtils.getWidthType(pr.width));


        if(pr.screen.width > 992) {
            return null;
        }

        const messageTime = moment.utc(st.mostRecentMessage['created_at']).local().format("LT");

        const isSuperChat = Utils.get(st.mostRecentMessage, "message_type") == Constant.FAN_TO_FAN_HOST_SEES_BOTH;
        if(!Utils.get(st.mostRecentMessage, "body") && !isSuperChat) {
            return null;
        }

        let abbrMessage = st.mostRecentMessage['body'];
        if(abbrMessage.length > 105) {
            abbrMessage = abbrMessage.slice(0, 105) + "...";
        }

        if(isSuperChat) {
            return (
                <Div style={recentMessageContainer} className="recentMessageContainer">
                    <Div className="messageContainer superChatContainer gradientBorderPinkOrangeCss" style={sty.messageSuperStyle}>
                        <Div className="superSendImageUsernameContainer" style={sty.superSendImageUsernameContainer}>
                            {st.mostRecentMessage.body.length ?
                                null
                                :
                                this.renderAvatar(st.mostRecentMessage, customerDict)
                            }
                            <Image
                                className="superChatImageFromProdChat"
                                style={{width: 89, marginRight: 8}}
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_2671c3fca8cbe9e9a3308c1d3c6bd221.png"
                            />
                            <Div style={sty.superChatCost} className="superChatCost">
                                ${Utils.get(st.mostRecentMessage, "json_data.paidAmount")}
                            </Div>
                            <Div>
                                &nbsp;by {this.renderUsername(st.mostRecentMessage, customerDict)}
                            </Div>
                            {/* <Image
                                style={{...sty.infoIcon, ...StylesPlatform.cursorPointer}}
                                className="infoIcon"
                                src={infoIcon}
                                onClick={this.showSuperSendInfo.bind(this)}
                            /> */}
                            {/* {message.body.length ?
                                null
                                :
                                <Div className="messageDate" style={{...sty.messageDate, ...{marginLeft: "auto"}}}>
                                    {messageTime}
                                </Div>
                            } */}
                        </Div>
                        {st.mostRecentMessage.body.length ?
                            <Div className="customerInfoContainer" style={sty.customerInfoContainer}>
                                {/* {this.renderBadges(st.mostRecentMessage, customerDict)} */}
                                {this.renderAvatar(st.mostRecentMessage, customerDict)}
                                <Div className="customerName" style={sty.customerName} data-id={Utils.get(st.mostRecentMessage, "customer_id")}>
                                    <Div className="message" style={messageStyle} dangerouslySetInnerHTML={{__html: abbrMessage}} />
                                </Div>
                                {/* <Div className="messageDate" style={sty.messageDate}>
                                    {messageTime}
                                </Div> */}
                            </Div>
                            :
                            null
                        }
                    </Div>
                </Div>
            )
        } else {
            return (
                <Div className="recentMessageContainer" style={recentMessageContainer} onClick={this.selectTabRoomMobile.bind(this, "chatbox")}>
                    <Div className="customerInfoContainer" style={sty.customerInfoContainer}>

                        <Div className="customerName" style={sty.customerName}>
                            {this.renderAvatar(st.mostRecentMessage, customerDict)}
                        </Div>
                        <Div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                            <Div style={{display: "flex", width: "100%", fontSize: 12, fontWeight: 400}}>
                                {this.renderUsername(st.mostRecentMessage, customerDict)}
                                <Div className="messageDate" style={sty.messageDate}>
                                    {messageTime}
                                </Div>
                            </Div>

                            <Div className="messageStyle" style={messageStyle} dangerouslySetInnerHTML={{__html: abbrMessage}}>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            )
        }
    }

    handleHandUpDown() {
        const pr = this.props;
        const st = this.state;

        this.putSignal("handup", Utils.get(pr, 'user.id'), Constant.ROOM_CMD_TYPE_TOGGLE_CUSTOMER, st.isHandRaised ? 0 : 1, (res) => {
            this.setState({
                isHandRaised: !st.isHandRaised,
            });
        });

        // let modalData = {
        //     cmd: st.isHandRaised ? "handdown" : "handup",
        //     roomCrc: this.roomCrc,
        //     customerId: Utils.get(pr, 'user.id'),
        // };
        //
        // Api.putRoomChatSignal(this.roomCrc, modalData, (response) => {
        //     if(Utils.get(response, "success")) {
        //         this.setState({
        //             isHandRaised: !st.isHandRaised,
        //         });
        //     } else {
        //         console.log("ERROR: ", modalData);
        //     }
        //
        // });
    }

    handleMuteClick(customerOrCustomers) {
        const st = this.state;

        let audioMuted = JSON.parse(JSON.stringify(st.audioMuted));

        let customers = [];
        if(!customerOrCustomers.length) {
            customers = [customerOrCustomers]
        } else {
            customers = customerOrCustomers;
        }

        for(let i = 0; i < customers.length; ++i) {
            let customer = customers[i];
            let muteAction = "mute";

            if(customer.id && audioMuted[customer.id]) {
                muteAction = "unmute";
                delete audioMuted[customer.id];
            } else {
                muteAction = "mute";
                audioMuted[customer.id] = true;
            }

            let modalData = {
                cmd: muteAction,
                roomCrc: this.roomCrc,
                customerId: customer.id,
            };

            Api.putRoomChatSignal(this.roomCrc, modalData, (response) => {
            });
        }

        this.setState({audioMuted: audioMuted});
    }

    handleHideVideoClick(customer) {
        const st = this.state;

        let videoMuted = JSON.parse(JSON.stringify(st.videoMuted))

        if(videoMuted[customer.id]) {
            delete videoMuted[customer.id];
        } else {
            videoMuted[customer.id] = true;
        }

        this.setState({videoMuted: videoMuted});
    }

    closeLiveStreamHasEndedModal() {
        this.setState({
            showLivestreamHasEndedModal: false,
        });
    }

    renderParticipantsIcons(customer, isVip) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return null;

        return (
            <Div className="participantIcons" style={sty.participantIcons}>
                {st.isHost || st.isCohost || st.isMod ?
                    <Icon
                        title="toggle audio"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        size={20}
                        icon={st.audioMuted[customer.id] ? "audio-slash" : "audio"}
                        // onClick={this.handleMuteClick.bind(this, customer)}
                    />
                    :
                    null
                }

                {st.isMod /*|| isVip*/ ?
                    <Icon
                        title="toggle video"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        size={20}
                        icon={st.videoMuted[customer.id] ? "video-slash" : "video"}
                        // onClick={this.handleHideVideoClick.bind(this, customer)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="refresh user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="recycle"
                        color="white"
                        size={20}
                        // onClick={this.chatAction.bind(this, "refresh", customer.id)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="kick user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="eject"
                        color="white"
                        size={20}
                        // onClick={this.chatAction.bind(this, "kick", customer.id)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="block user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="close"
                        color="white"
                        size={20}
                        // onClick={this.chatAction.bind(this, "block", customer.id)}
                    />
                    :
                    null
                }
            </Div>
        )
    }

    renderMobileCloseButton(isNoGradient, isNoBorderRadius, isFixed) {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        if(!pr.screen.mobile) {
            return null;
        }

        let isNoGradientStyle = null;
        if(isNoGradient) {
            isNoGradientStyle = {background: "none"};
        }

        let isNoBorderRadiusStyle = null;
        if(isNoBorderRadius) {
            isNoBorderRadiusStyle = {borderRadius: 0};
        }

        let isFixedStyle = null;
        if(isFixed) {
            isFixedStyle = {position: "fixed", marginTop: -34, top: "auto"};
        }

        return (
            <Div
                className="closeButtonBottomContainer"
                style={{...sty.closeButtonBottomContainer, ...isNoGradientStyle, ...isNoBorderRadiusStyle, ...isFixedStyle, ...StylesPlatform.cursorPointer}}
            >
                <Icon
                    className="closeTabbedBottom"
                    icon="close"
                    size={24}
                    style={{marginRight: -4}}
                    onClick={this.selectTabRoomMobile.bind(this, "none")}
                />
            </Div>
        )
    }

    renderHostLivestreamInfo() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        const host = pr.roomItem.insiders["host"];

        return (
            <Div className="hostInfoContainer" style={sty.hostInfoContainer}>
                <Div className="hostLabel" style={sty.hostLabel}>Host</Div>
                { host.id ?
                    <Div className="customerListName" style={sty.customerListName}>@{Utils.shortenString(host.username, 20)}</Div>
                    :
                    <Div className="customerListName" style={sty.customerListName}>No host selected</Div>
                }
                <Div className="customerLiveDuration" style={sty.customerLiveDuration}>
                    Live stream duration: {st.liveStreamDuration ? st.liveStreamDuration : "0:00"}
                </Div>
                {st.numPeopleInStream ?
                    <Div className="customerLiveDuration" style={sty.customerLiveDuration}>
                        {st.numPeopleInStream ? st.numPeopleInStream : 32} people in stream
                    </Div>
                    :
                    null
                }
            </Div>
        )
    }

    renderWithdrawInviteButton() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.isHost) {
            return null;
        }

        return (
            <Button
                className="mainLivestreamButton participantsWithdrawInvite"
                size="smaller"
                type="outlined"
                style={{width: "100%", fontWeight: 400, marginBottom: 30}}
                onClick={this.withdrawInviteToStream.bind(this)}
                disabled={!st.showWithdrawInviteButton || st.vipIsInLiveStream}
            >
                Withdraw Invite {st.customersVipDict[st.selectedVipId] ? "to @" + st.customersVipDict[st.selectedVipId]['username'] : st.selectedVipId}
            </Button>
        )
    }

    renderTabbedSelector() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        let tabbedContent = null;

        const listings = pr.roomItem['listings'] ? pr.roomItem['listings'] : [];
        const host = pr.roomItem.insiders["host"];
        const cohosts = pr.roomItem.insiders["cohosts"];
        const vips = Utils.get(pr.roomItem, "customers_vip", []);
        //console.log("vips", vips);
        const customers = pr.roomItem["customers"];

        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const tabbedSelectorContainer = StyleUtils.getMediaStyle('tabbedSelectorContainer', sty.tabbedSelectorContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const participantsScrollContainer = StyleUtils.getMediaStyle('participantsScrollContainer', sty.participantsScrollContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const tabbedHideShowContainer = StyleUtils.getMediaStyle('tabbedHideShowContainer', sty.tabbedHideShowContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const tabsRoom = StyleUtils.getMediaStyle('tabsRoom', sty.tabsRoom, sty, StyleUtils.getWidthType(pr.screen.width));
        let tabsRoomContent = StyleUtils.getMediaStyle('tabsRoomContent', sty.tabsRoomContent, sty, StyleUtils.getWidthType(pr.screen.width));
        const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");

        const mobileTabs = [
            {
                tabName: "vips",
                iconName: "star",
            },
            {
                tabName: "chatbox",
                iconName: "comment",
            },
            {
                tabName: "nfts",
                iconName: "nft",
            },
            {
                tabName: "participants",
                iconName: "users",
            },
        ];

        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        let inviteableUsers = [];
        const vipDict = Utils.arrayToObject(vips, 'id');

        if(st.onlineUsers) {
            for (var i = 0; i < st.onlineUsers.length; i++) {
                if(vipDict[st.onlineUsers[i]]) {
                    inviteableUsers.push(st.onlineUsers[i]);
                }
            }
        }

        return (
            <Div className="tabbedSelectorContainer" style={tabbedSelectorContainer}>
                <Div className="tabsRoomContent" style={tabsRoomContent}>

                    {/* NOTE: HOST */}
                    <Div
                        className="tabbedHideShowContainer hide-scrollbar"
                        style={{...tabbedHideShowContainer, ...{display: st.selectedTabMobileRoom === "vips" ? "block" : "none"}}}
                    >
                        {this.renderMobileCloseButton(true)}
                        <Div className="hostInfoContainerOuter" style={sty.hostInfoContainerOuter}>
                            <Div
                                className="customerPhoto"
                                style={{...sty.customerPhoto, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                            ></Div>
                            {this.renderHostLivestreamInfo()}
                        </Div>

                        {/* {st.isMod || st.isHost ? this.renderParticipantsIcons(host) : null} */}
                    </Div>

                    {/* NOTE: CHATBOX */}
                    <Div
                        className="tabbedHideShowContainer hide-scrollbar"
                        style={{display: st.selectedTabMobileRoom === "chatbox" ? "block" : "none"}}
                    >
                        <Chatbox
                            screen={pr.screen}
                            roomId={pr.roomItem["id"]}
                            roomChats={pr.roomItem["roomChats"]}
                            roomItem={pr.roomItem}
                            badgeTypes={pr.roomItem["badgeTypes"]}
                            // handleOnlineUsersChange={this.handleOnlineUsersChange.bind(this)}
                            scrollContainerStyle={scrollContainerStyle}
                            handleSignal={this.handleSignal.bind(this)}
                            user={pr.user}
                            customerId={Utils.get(pr.user, 'id')}
                            handleMostRecentMessage={this.handleMostRecentMessage.bind(this)}
                            width={pr.screen.width}
                            isDisplayed={st.selectedTabMobileRoom === "chatbox"}
                            style={pr.screen.mobile ? {position: "absolute", zIndex: 1002, bottom: 0, left: 0} : null}
                            closeChatbox={this.selectTabRoomMobile.bind(this, "none")}
                            handleButtonClick={this.handleChatboxButtonClick.bind(this)}
                            isHost={st.isHost}
                            isVip={st.isVip}
                            artist={Utils.get(pr, "roomItem.artist", {})}
                            tabSwitchSignal={st.tabSwitchSignal}
                            noChatSignals={st.noChatSignals}
                            setChatTabSelected={this.setChatTabSelected.bind(this)}
                        />
                    </Div>

                    {/* NOTE: NFTS */}
                    <Div
                        className="tabbedHideShowContainer hide-scrollbar"
                        style={{...tabbedHideShowContainer, ...participantsScrollContainer, ...{display: st.selectedTabMobileRoom === "nfts" ? "block" : "none", paddingBottom: 0}}}
                    >
                        {this.renderMobileCloseButton(false, false, true)}
                        <Div className="tabbedContainerHeaderCollectibles" style={sty.tabbedContainerHeaderCollectibles}>
                            {st.showLeaderboardTab ? "Leaderboard" : "Collectibles"}
                        </Div>
                        <Div className="withdrawButtonContainer" style={{marginTop: 10, marginBottom: -10}}>
                            {this.renderWithdrawInviteButton()}
                        </Div>
                        {st.showLeaderboardTab ?
                            <Div  key={`leaderboard-container-${this.state.leaderboardKey}`} className={`leaderboard-container`}>
                                <Leaderboard
                                    user={pr.user}
                                    screen={pr.screen}
                                    type={Constant.LEADERBOARD_TYPE_ROOM}
                                    inviteToStream={this.inviteUserToStream.bind(this)}
                                    isHost={st.isHost}
                                    isLive={st.agoraActive}
                                    vipIsInLiveStream={st.vipIsInLiveStream}
                                    style={{marginTop: 20}}
                                    artistId={Utils.get(pr, "roomItem.artist.id")}
                                    userHandUpDict={st.userHandUpDict}
                                    onlineUsers={inviteableUsers}
                                    disableAllInviteButtons={st.disableAllInviteButtons}
                                    handleLeaderboardUpdate={this.handleLeaderboardUpdate.bind(this)}
                                />
                            </Div>
                            :
                            listings.map((listing, i) => {
                                return (
                                    <Div key={`listing-${listing['id']}`} className="roomNfts" style={sty.roomNfts}>
                                        <Div
                                            className="roomNft"
                                            style={{
                                                width: 50,
                                                height: 50,
                                                marginRight: 20,
                                                backgroundImage: `url(${listing['image_url']})`,
                                                cursor: "pointer",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                borderRadius: 100,
                                                border: "2px solid rgba(85, 81, 179, 1)",
                                            }}
                                            onClick={this.openSoinModal.bind(this, listing, i)}
                                        >
                                        </Div>
                                        <Div style={{textAlign: "center", whiteSpace: "wrap", fontSize: 18}}>{listing.title}</Div>
                                    </Div>
                                )
                            })
                        }
                    </Div>

                    {/* NOTE: PARTICIPANTS ...sty.noBorderRadius */}
                    <Div
                        className="tabbedHideShowContainer hide-scrollbar"
                        style={{...tabbedHideShowContainer, ...participantsScrollContainer, ...{display: st.selectedTabMobileRoom === "participants" ? "block" : "none"}}}
                    >
                        {this.renderMobileCloseButton(false, false, true)}
                        {st.isMod ?
                            <Button
                                size="small"
                                onClick={this.chatAction.bind(this, "refresh")}
                                style={{marginTop: 30, marginRight: "auto", marginBottom: 20, marginLeft: "auto"}}
                            >
                                REFRESH ALL
                            </Button>
                            :
                            null
                        }

                        <Div className="tabbedContainerHeader" style={{...sty.tabbedContainerHeader, ...{marginBottom: 20}}}>Host</Div>
                        <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                            <Div className="customerContainer" style={{...sty.customerContainer, ...{alignItems: "center", marginBottom: 28}}}>
                                <Div
                                    className="customerPhoto"
                                    style={{...sty.customerPhoto, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                ></Div>

                                {this.renderHostLivestreamInfo()}

                                {st.isMod || st.isHost ? this.renderParticipantsIcons(host) : null}
                            </Div>
                        </Div>

                        <Div className="greyLineSeparator" style={{width: "100%", height: 2, backgroundColor: "#5A5A5A"}}></Div>

                        {/* <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Co-Hosts</Div>
                        <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                            {cohosts.map((customer, customerIndex) => {
                                return (
                                    <Div key={`customer-${customerIndex}`} className="customerContainer" style={sty.customerContainer}>
                                        <Div
                                            className="customerPhoto"
                                            style={{...sty.customerPhoto, ...{backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                        ></Div>
                                        <Div style={sty.customerListName}>@{customer.username}</Div>
                                        {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer) : null}
                                    </Div>
                                )
                            })}
                        </Div> */}

                        <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Front Row</Div>
                        {this.renderWithdrawInviteButton()}

                        <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                            {vips.map((customer, customerIndex) => {
                                let disabledInviteButton = true;

                                if(st.disableAllInviteButtons) {
                                    disabledInviteButton = true;
                                } else if(st.onlineUsers) {
                                    for(var i = 0; i < st.onlineUsers.length; i++) {
                                        if(customer.id == pr.user.id) {
                                            disabledInviteButton = true;
                                        } else if(customer.id == st.onlineUsers[i]) {
                                            disabledInviteButton = false;
                                        }
                                    }
                                }

                                return (
                                    <Div key={`customer-${customerIndex}`} className="customerContainer" style={sty.customerContainer}>
                                        <Div
                                            className="customerPhoto"
                                            style={{...sty.customerPhoto, ...{backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                        >
                                        { Utils.get(st.userHandUpDict[customer.id], "extra_id") == 1 ?
                                            Utils.get(st.userHandUpDict[customer.id], "extra_str") >= 1 ?
                                                <Div style={{...sty.handUpOverlayIconGeneric, ...{backgroundColor: "rgba(255, 0, 0, 0.55)"}}}>
                                                    <Image style={{width: 40}} src={"https://dhwk6gixvyfl3.cloudfront.net/upload_14c85fb405a98f085523bf884dfb0246.png"} className="handUpInVipRight" />
                                                </Div>
                                                :
                                                <Div style={{...sty.handUpOverlayIconGeneric, ...{backgroundColor: "rgba(0, 0, 255, 0.55)"}}}>
                                                    <Image style={{width: 40}} src={"https://chorusuploads.s3.us-west-1.amazonaws.com/upload_abed636bfef256a88f9805c75d60879f.png"} className="handUpInVipRight" />
                                                </Div>
                                            :
                                            null
                                        }

                                        </Div>
                                        <Div className="vipMessageButtonContainer" style={{width: "100%"}}>
                                            <Div style={{display:"flex",flexDirection:"row"}}>
                                                <Div style={{ ...sty.customerListName, ...{marginRight:"none"} }}>@{Utils.shortenString(Utils.formatUsername(customer.username), 20)}</Div>
                                                <Div style={{marginTop: -10}}>{this.renderCustomerBadges(customer.id)}</Div>
                                            </Div>
                                            {st.isHost ?
                                                // NOTE: invite in participants tab
                                                <Button
                                                    size="small"
                                                    color="pinkGradient"
                                                    style={{paddingLeft: 30, paddingRight: 30}}
                                                    onClick={this.inviteUserToStream.bind(this, customer.id)}
                                                    disabled={disabledInviteButton}
                                                >
                                                    Invite to stream
                                                </Button>
                                                :
                                                null
                                            }

                                            {Utils.get(customer, "room_question") ?
                                                <Div className="vipMessage" style={sty.vipMessage}>
                                                    {customer.room_question}
                                                </Div>
                                                :
                                                null
                                            }


                                        </Div>
                                        {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer, true) : null}
                                    </Div>
                                )
                            })}
                        </Div>

                        <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Participants</Div>
                        <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                            {customers.map((customer, customerIndex) => {
                                if(Utils.get(pr.roomItem, "customersVipDict")[customer.id] || host.id == customer.id) {
                                    return null;
                                }
                                return (
                                    <Div key={`customer-${customerIndex}`} className="customerContainer" style={{...sty.customerContainer, ...{alignItems: "center", marginBottom: 20}}}>
                                        <Div
                                            className="customerPhoto"
                                            style={{...sty.customerPhoto, ...{width: 32, height: 32, backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                        ></Div>
                                        <Div style={{...sty.customerListName, ...{fontWeight: 400}}}>@{Utils.shortenString(customer.username, 20)}</Div>
                                        {/* { isInsider ?
                                            <Div style={Styles.videoIconsContainer}>
                                                <Icon
                                                    style={Styles.videoIconsIcon}
                                                    size={16}
                                                    icon={!st.audioMuted[customer['id']] ? "microphone" : "microphone-slash"}
                                                    onClick={this.chatAction.bind(this, "mute", customer['id'])}
                                                />

                                            </Div>
                                            :
                                            null
                                        } */}
                                        {/* {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer) : null} */}
                                    </Div>
                                )
                            })}
                        </Div>
                    </Div>
                </Div>
                <Div className="tabsRoom" style={tabsRoom}>
                    {mobileTabs.map((mobileTab, mobileTabIndex) => {
                        const isSelectedTab = st.selectedTabMobileRoom === mobileTab.tabName;

                        return (
                            <Div
                                key={`tabbed-selector-${mobileTabIndex}`}
                                className="tabsRoomIconContainer"
                                style={{
                                    ...sty.tabsRoomIconContainer,
                                    ...StylesPlatform.cursorPointer,
                                }}
                            >
                                <Image
                                    style={sty.tabRoomMobileIcon}
                                    src={this.chooseTabIcon(isSelectedTab, mobileTab.iconName)}
                                    onClick={this.selectTabRoomMobile.bind(this, mobileTab.tabName)}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderGearMenu() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;
        const userId = Utils.get(pr, "user.id");

        let clickHandler = () => {};
        let clickTitle = "Exit Livestream";
        let liveStreamButtonDisabled = false;

        let showGearMenu = false;

        if(st.isHost || st.isVip && st.vipIsInLiveStream) {
            showGearMenu = true;
        }

        if(!showGearMenu) {
            return null;
        }

        if(st.isHost) {
            if(st.agoraActive) {
                clickHandler = this.handleStopAgora.bind(this);
            } else {
                clickHandler = this.handleStartAgora.bind(this);
                clickTitle = "Start Livestream";
            }
        } else if(st.isVip) {
            if(st.agoraActive) {
                if(userId == st.selectedVipId && st.vipIsInLiveStream) {
                    clickHandler = this.removeGuestFromStream.bind(this);
                }
            } else {
                clickTitle = "Start Livestream";
                liveStreamButtonDisabled = true;
            }
        }

        /*
        if(st.agoraActive) {
            if(st.isHost) {
                clickHandler = this.handleStopAgora.bind(this);
            } else if(userId == st.selectedVipId && st.vipIsInLiveStream) {
                clickHandler = this.removeGuestFromStream.bind(this);
            } else if(st.isVip) {
                clickTitle = "Start Livestream";
                liveStreamButtonDisabled = true;
            }
        } else {
            if(st.isHost) {
                clickHandler = this.handleStartAgora.bind(this);
                clickTitle = "Start Livestream";
            } else if(st.isVip) {
                clickTitle = "Start Livestream";
                liveStreamButtonDisabled = true;
            }
        }
        */

        const menuGear = StyleUtils.getMediaStyle('menuGear', sty.menuGear, sty, StyleUtils.getWidthType(pr.screen.width));
        const mobileMenu = StyleUtils.getMediaStyle('mobileMenu', sty.mobileMenu, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <>
                <Icon
                    icon="gear"
                    size={24}
                    style={{...menuGear, ...StylesPlatform.cursorPointer, ...Styles.dropShadow}}
                    onClick={this.toggleLivestreamMenu.bind(this)}
                />

                {st.showLivestreamMenu || (!st.agoraActive && st.isHost) ?
                    <Div className="mobileMenu" style={mobileMenu}>
                        <Div className="mobileMenuTitleRow" style={sty.mobileMenuTitleRow}>
                            <Div className="mobileMenuTitle" style={sty.mobileMenuTitle}>
                                You
                            </Div>

                            {st.agoraActive && (st.isHost || userId == st.selectedVipId) ?
                                <>
                                    <Icon
                                        className="newAudioControl"
                                        icon={st.audioMuted[Utils.get(pr, 'user.id')] ? "audio-slash" : "audio"}
                                        style={{...{flexShrink: 0}, ...StylesPlatform.cursorPointer}}
                                        size={20}
                                        onClick={this.handleToggleMedia.bind(this, true, "audio")}
                                    />

                                    <Icon
                                        className="newVideoControl"
                                        icon={st.videoMuted[Utils.get(pr, 'user.id')] ? "video-slash" : "video"}
                                        style={{...sty.videoMobile, ...StylesPlatform.cursorPointer}}
                                        size={20}
                                        onClick={this.handleToggleMedia.bind(this, true, "video")}
                                    />
                                </>
                                :
                                null
                            }

                        </Div>
                        <Div className="grayBorderSeparator" style={sty.greyBorderSeparator} />

                        {st.isHost && st.vipIsInLiveStream && st.showRemoveGuestButton ?
                            <>
                                <Div className="mobileMenuTitleRow" style={sty.mobileMenuTitleRow}>
                                    <Div className="mobileMenuTitle" style={sty.mobileMenuTitle}>
                                        Guest
                                    </Div>
                                    <Icon
                                        icon={st.audioMuted[st.selectedVipId] ? "audio-slash" : "audio"}
                                        style={{...{flexShrink: 0}, ...StylesPlatform.cursorPointer}}
                                        size={20}
                                        onClick={this.handleToggleMedia.bind(this, false, "audio")}
                                    />

                                    <Icon
                                        icon={st.videoMuted[st.selectedVipId] ? "video-slash" : "video"}
                                        style={{...sty.videoMobile, ...StylesPlatform.cursorPointer}}
                                        size={20}
                                        onClick={this.handleToggleMedia.bind(this, false, "video")}
                                    />
                                </Div>
                                <Button
                                    size="small"
                                    type="outlined"
                                    style={{width: "100%", fontWeight: 400, marginTop: 20}}
                                    onClick={this.removeGuestFromStream.bind(this)}
                                >
                                    Remove guest
                                </Button>
                                <Div className="grayBorderSeparator" style={sty.greyBorderSeparator} />
                            </>
                            :
                            null
                        }

                        <Button
                            className="mainLivestreamButton"
                            size="small"
                            color="pinkGradient"
                            style={{width: "100%"}}
                            onClick={clickHandler}
                            disabled={liveStreamButtonDisabled}
                        >
                            {clickTitle}
                        </Button>

                        {/* NOTE in gear menu */}
                        {st.isHost ?
                            <Button
                                className="mainLivestreamButton"
                                size="smaller"
                                type="outlined"
                                style={{width: "100%", fontWeight: 400, marginTop: 10}}
                                onClick={this.withdrawInviteToStream.bind(this)}
                                disabled={!st.showWithdrawInviteButton}
                            >
                                Withdraw Invite
                            </Button>
                            :
                            null
                        }

                    </Div>
                    :
                    null
                }
            </>
        )
    }

    renderRaiseHand() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.agoraActive) {
            return null;
        }

        if(st.isHost || !st.isVip) {
            return null;
        }

        if(st.vipIsInLiveStream && st.selectedVipId == Utils.get(pr, 'user.id')) {
            return null;
        }

        let handUp = StyleUtils.getMediaStyle('handUp', sty.handUp, sty, StyleUtils.getWidthType(pr.screen.width));

        if(st.isHandRaised) {
            handUp.background = "#C43737";
        } else {
            handUp.background = "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)";
        }

        return (
            <Div
                className="handUp" style={{...handUp, ...StylesPlatform.cursorPointer}}
                onClick={this.raiseHand.bind(this)}
            >
                <Icon
                    icon={st.isHandRaised ? "hand-down" : "hand"}
                    size={22}
                />
            </Div>
        )
    }

    renderInviteVipToStream() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.showInviteToStreamPopUp) {
            return null;
        }

        if(!st.agoraActive) {
            return null;
        }

        const vips = pr.roomItem["customers_vip"];

        // NOTE: find the VIP
        let vip = null;
        for (var i = 0; i < vips.length; i++) {
            vip = vips[i];
            if(vip.id == st.viewedVipId) {
                break;
            }
        }

        const inviteStreamMarginTop = st.isVip ? {marginTop: 50} : {marginTop: 15};

        return (
            <Div className="inviteVipToStream" style={{...sty.inviteVipToStream, ...inviteStreamMarginTop}}>
                <Div
                    className="inviteVipCloseButton"
                    style={{...sty.inviteVipCloseButton, ...StylesPlatform.cursorPointer}}
                    onClick={this.closeInviteVipToStreamModal.bind(this)}
                >
                    ×
                </Div>
                <Div className="vipInviteName" style={sty.vipInviteName}>
                    @{Utils.formatUsername(vip.username)}
                </Div>
                <Div className="vipInviteComment" style={sty.vipInviteComment}>
                    {vip.room_question}
                </Div>
                {/* NOTE: invite pop up */}
                {!st.vipIsInLiveStream && st.isHost ?
                    vip.id == st.viewedVipId && st.disableAllInviteButtons ?
                        <Button
                            className="withdrawInviteButton"
                            size="small"
                            color="pinkGradient"
                            style={{paddingLeft: 30, paddingRight: 30, width: "100%"}}
                            onClick={this.withdrawInviteToStream.bind(this)}
                        >
                            Withdraw Invite
                        </Button>
                        :
                        <Button
                            size="small"
                            className="inviteToStreamButtonPopUp"
                            color="pinkGradient"
                            style={{paddingLeft: 30, paddingRight: 30, width: "100%"}}
                            onClick={this.inviteUserToStream.bind(this)}
                            disabled={st.disableAllInviteButtons}
                        >
                            Invite to stream
                        </Button>
                    :
                    null
                }
            </Div>
        )
    }

    renderStreamAlertModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.showStreamAlertModal) {
            return null;
        }

        // NOTE this is needed for hand raise
        const inviteStreamMarginTop = st.isVip ? {marginTop: 50} : {marginTop: 15};

        return (
            <Div className="streamAlertModal" style={{...sty.streamAlertModal, ...inviteStreamMarginTop}}>
                <Div
                    className="streamAlertCloseButton"
                    style={{...sty.inviteVipCloseButton, ...StylesPlatform.cursorPointer}}
                    onClick={this.closeStreamAlertModal.bind(this)}
                >
                    ×
                </Div>
                <Div className="inviteVipToStream" style={sty.vipInviteName}>
                    {st.streamAlertMessage}
                </Div>
                {/* <Div className="inviteVipToStream" style={sty.vipInviteComment}>
                    {vip.room_question}
                </Div> */}
                {/* NOTE: stream alert pop up */}
                <Button
                    size="small"
                    color="pinkGradient"
                    style={{paddingLeft: 30, paddingRight: 30, width: "100%"}}
                    onClick={this.closeStreamAlertModal.bind(this)}
                >
                    OK
                </Button>
            </Div>
        )
    }

    renderAcceptInviteOverlay() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.isHost) {
            return null;
        }

        if(st.selectedVipId != Utils.get(pr, 'user.id')) {
            return null;
        }

        if(!st.showAcceptInviteOverlay) {
            return null;
        }

        const host = Utils.get(pr.roomItem, "insiders.host", {});

        return (
            <Modal
                screen={pr.screen}
                className=""
                style={sty.acceptInviteOverlay}
                fullScreen
                hideCloseButton
            >
                <Div style={{fontSize: 24, textAlign: "center", paddingLeft: 20, paddingRight: 20, marginBottom: 20}}>
                    @{Utils.get(host, "username")} has invited you to go live with them!
                </Div>
                <Button
                    color="pinkGradient"
                    onClick={this.acceptInviteToStream.bind(this)}
                >
                    Accept Invite
                </Button>
                <Button
                    type="outlined"
                    style={{minWidth: 180, fontWeight: 400, marginTop: 20}}
                    onClick={this.declineInviteToStream.bind(this)}
                >
                    Decline
                </Button>
            </Modal>
        )
    }

    closeModal(modalVar) {
        let tempState = {};
        tempState[modalVar] = false;
        this.setState(tempState);
    }


    renderIntroModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(true || !this.state.showIntroModal) {
            return null;
        }

        const roomId = Utils.get(pr.roomItem, "id");
        const cookieKey = "intro_video_"+roomId;
        // If Agora is active or they are the host, don't display the video
        if(st.agoraActive || st.isHost) {
            return null;
        }
        if(Utils.empty(Utils.get(pr.roomItem, 'intro_video_url')) || Utils.getCookie(cookieKey)) {
            return null;
        }
        const autoPlay = true;
        const src = Utils.get(pr.roomItem, 'intro_video_url');

        return (
            <Modal
                screen={pr.screen}
                className=""
                style={sty.acceptInviteOverlay}
                onRequestClose={() => {
                    Utils.setCookie(cookieKey, true);
                    this.closeModal("showIntroModal");
                } }
            >
                <Div style={{fontSize: 24, textAlign: "center", paddingLeft: 20, paddingRight: 20, marginBottom: 20}}>
                    <video
                        src={src}
                        autoPlay={autoPlay}
                        //muted={muted}
                        //playsInline={playsInline}
                        //poster={poster}
                        //controls={controls}
                        style={{width:"100%"}}
                        //loop={loop}
                        playsInline={true}
                        webkit-playsinline="true"
                    ></video>

                </Div>
            </Modal>
        )
    }

    postClickHandler(target, value) {
        console.log("CLICK", target, value);
        if(target == 'chooseTip') {
            let tempState = {};
            tempState['showTipModalType'] = "tipchooser";
            //Analytics.triggerGa();
            this.setState(tempState);
        } else if(target == 'selectTip') {
            let tempState = {};
            const tipAmount = parseInt(value.replace("option",""));
            tempState['tipAmount'] = tipAmount;
            tempState['showTipModalType'] = "paymentTip";
            tempState['noChatSignals'] = true;
            console.log("CLICK setState", tempState, );
            this.setState(tempState);
        } else if(target == 'upgradeVipChat') {
            let tempState = {};
            tempState['noChatSignals'] = true;
            tempState['showTipModalType'] = "paymentActivateChatHost";
            this.setState(tempState);
        } else if(target == 'paidUpgradeVipChat') {
            let tempState = {};
            // moved from renderTipModal
            tempState['showTipModalType'] = false;
            tempState['tabSwitchSignal'] = 1;
            tempState['noChatSignals'] = false;
            this.setState(tempState);
        } else if(target == 'paidTip') {
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = "tipentermsg";
            this.setState(tempState);
        } else if(target == 'paidTipSuccess') {
            // an alternative to tip succee for payments. See renderTipModal > tipentermsg2
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = "tipentermsg2";
            this.setState(tempState);
        } else if(target == 'paymentFailed') {
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = "paymentFailed";
            this.setState(tempState);
        } else if(target == 'paidActivateChatHost') {
            // Deprecated. Remove once stripe is activated.
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = false;
            const roomId = Utils.get(this.props.roomItem, "id");
            Api.postUpgradeVipChat({'room_id':roomId}, (o) => {
                this.setState(tempState);
            });
        } else if(target == 'submitMessage') {
            //console.log("value", value);
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = false;
            this.setState(tempState);
            this.handleChatPost(value, 2, this.state.tipAmount);
        }
    }

    renderTipModal(type) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st['showTipModalType']) {
            return null;
        }
        const modalType = st['showTipModalType'];
        let body;
        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        let postId;
        let isDragon = false;
        let hideCloseButton = false;

        if(modalType == 'tipinfo') {
            postId = 130;
        } else if(modalType == 'tipinfo2') {
            postId = 134;
        } else if(modalType == 'chatboxinfo') {
            postId = 132;
        } else if(modalType == 'tipchooser') {
            postId = 128;
        } else if(modalType == 'paymentTip') {
            // TODO: delete when v3 is ready to be removed
            body = (
                <Div className="paymentsWrapper" style={{width: "100%"}}>
                    <Payments
                        roomId = {Utils.get(this.props, "roomItem.id")}
                        amount={st.tipAmount}
                        paymentType={"tipPayment"}
                        completePayment={ () => { this.postClickHandler('paidTip'); } }
                        onRequestClose={ () => this.setState({showTipModalType: false, noChatSignals:false}) }
                    />
                    { false ?
                        <Button onClick={() => { this.postClickHandler('paidTip') }}>Pay</Button>
                        :
                        null
                    }
                </Div>
            );
            const paymentType = "tipPayment"
            const roomId = Utils.get(this.props, "roomItem.id")
            const amount = st.tipAmount
            return (
                <PaymentsV2
                    metadata={{
                        user: Utils.get(pr, 'user'),
                        artist: Utils.get(pr, "roomItem.artist")
                    }}
                    modalProps={{
                        screen: this.props.screen,
                        isDragon: false,
                        onRequestClose: () => {
                            this.setState({
                                showTipModalType: false,
                                noChatSignals:false,
                            });
                        },
                    }}
                    stripeProps={{
                        showButtons: true,
                        amount,
                        // defaults to 1
                        // quantity,
                        roomId,
                        paymentType,
                        paymentSuccess: (paymentIntent) => {
                            this.postClickHandler('paidTip')
                        },
                        paymentMethodSuccessModal: false,
                        paymentMethodSuccess: (paymentIntent) => {
                            this.postClickHandler('paidTip')
                        },
                        onRequestClose: () => {
                            this.setState({
                                showTipModalType: false,
                                noChatSignals:false,
                            });
                        },
                    }}
                    paypalProps={{
                        showButtons: true,
                        // paypal seems to support float as string or number e.g. "2.00" or 3.99
                        // require for paypal to work
                        amount,
                        // defaults to 1
                        // quantity,
                        paymentSuccess: () => {
                            this.postClickHandler('paidTip')
                        },
                        paymentType,
                    }}
                />
            )
        } else if(modalType == 'paymentActivateChatHost') {
            // TODO: delete when v3 is ready to be removed
            body = (
                <Div className="paymentsWrapper" style={{width: "100%"}}>
                    <Payments
                        roomId = {Utils.get(this.props, "roomItem.id")}
                        amount={3.99}
                        paymentType={"upgradeVipChatPayment"}
                        completePayment={
                            () => {
                                console.log('PAYPAL complete');
                                this.postClickHandler('paidUpgradeVipChat')
                            }
                        }

                    />
                    { false ?
                        <Button onClick={() => { this.postClickHandler('paidActivateChatHost') }}>Pay</Button>
                        :
                        null
                    }
                </Div>
            );
            const paymentType = "upgradeVipChatPayment"
            const roomId = Utils.get(this.props, "roomItem.id")
            const amount = 3.99
            return (
                <PaymentsV2
                    metadata={{
                        user: Utils.get(pr, 'user'),
                        artist: Utils.get(pr, "roomItem.artist")
                    }}
                    modalProps={{
                        screen: this.props.screen,
                        isDragon: false,
                        onRequestClose: () => {
                            this.setState({
                                showTipModalType: false,
                                noChatSignals:false,
                            });
                        },
                    }}
                    stripeProps={{
                        showButtons: true,
                        amount,
                        // defaults to 1
                        // quantity,
                        roomId,
                        paymentType,
                        paymentSuccess: (paymentIntent) => {
                            this.postClickHandler('paidUpgradeVipChat')
                        },
                        paymentMethodSuccessModal: false,
                        paymentMethodSuccess: (paymentIntent) => {
                            this.postClickHandler('paidUpgradeVipChat')
                        },
                        onRequestClose: () => {
                            this.setState({
                                showTipModalType: false,
                                noChatSignals:false,
                            });
                        },
                    }}
                    paypalProps={{
                        showButtons: true,
                        // paypal seems to support float as string or number e.g. "2.00" or 3.99
                        // require for paypal to work
                        amount,
                        // defaults to 1
                        // quantity,
                        paymentSuccess: () => {
                            this.postClickHandler('paidUpgradeVipChat')
                        },
                        paymentType,
                    }}
                />
            )
        } else if(modalType == 'tipentermsg') {
            postId = 129;
            hideCloseButton = true;
        } else if(modalType == 'tipentermsg2') {
            /**
             * alternative option for tip when tip send message was not working
             * - keeping for potential use for post-payment redirect flow.
             * See (redirectStatus === "succeeded") top of file
             */
            body = (
                <Div>Thanks for the SuperSend!</Div>
            )
        } else if(modalType == 'paymentFailed') {
            body = (
                <Div style={{fontSize: 28, color:Colors.orange, marginBottom: 8, marginTop: "auto"}}>We're sorry. Something went wrong with your payment. Please try again.</Div>
            )
        } else if(modalType == 'notvip') {
            postId = 131;
            isDragon = true;
        } else {
            return null;
        }

        if(postId) {
            body = (
                <Div>
                    <Post
                        {...localGlobalProps}
                        postId={postId}
                        postClickHandler={this.postClickHandler.bind(this)}
                    />
                </Div>
            );
        }

        return (
            <Modal
                key={`tip_modal_${postId}`}
                screen={pr.screen}
                className="postModalBody"
                hideCloseButton={hideCloseButton}
                onRequestClose={() => {
                    this.setState({showTipModalType: false, noChatSignals:false});
                    // needed to clear stripe redirect query strings
                    cleanUrlQueryStrings()
                }}
                color="indigo"
                style={{maxWidth: 500, backgroundColor: isDragon ? Colors.dragon : Colors.indigo}}
            >
                {body}
            </Modal>
        )
    }


    renderLiveStreamHasEndedModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.showLivestreamHasEndedModal) {
            return null;
        }

        const host = Utils.get(pr.roomItem, "insiders.host", {});
        let liveStreamEndMessage = `@${Utils.get(host, "username")} has ended the livestream! Thank you so much for attending, we'll see you next time`;

        if(st.isHost) {
            liveStreamEndMessage = "You have ended the livestream!  You did great, thanks for throwing an Afterparty"
        }

        return (
            <Modal
                screen={pr.screen}
                style={sty.acceptInviteOverlay}
                fullScreen
                hideCloseButton
            >
                <Div
                    className="livestreamHasEndedMessage"
                    style={{fontSize: 24, textAlign: "center", paddingLeft: 20, paddingRight: 20, maxWidth: 700, marginBottom: 20}}
                >
                    {liveStreamEndMessage}
                </Div>
                <Button
                    color="pinkGradient"
                    onClick={this.closeLiveStreamHasEndedModal.bind(this)}
                >
                    OK
                </Button>
            </Modal>
        )
    }

    renderAudioMuted() {
        audioMuted[customerId]
    }

    renderGuestCountDown() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const host = Utils.get(pr.roomItem, "insiders.host", {});

        if(!st.vipIsInLiveStream) {
            return null;
        }

        let dynamicCountdownStyle = null;
        if(pr.screen.mobile) {
            dynamicCountdownStyle = {
                marginLeft: 170,
            };
        } else if(st.audioMuted[host.id]) {
            dynamicCountdownStyle = {
                marginTop: 20,
                marginLeft: 70,
            }
        }
        const remainingSec =  st.guestCountdown - Utils.getUnixTimestamp();

        return (
            <Div
                className="guestCountdownContainer"
                style={{...sty.guestCountdownContainer, ...dynamicCountdownStyle}}
            >
                Time remaining: {remainingSec > 0 ? remainingSec : 0 }
                {remainingSec <= 5 && (Utils.get(pr, 'user.id') == st.selectedVipId || st.isHost)  ?
                    <Div style={{marginLeft: 4}}>
                        Say goodbye!
                    </Div>
                    :
                    null
                }
            </Div>
        );
    }

    renderMode(isHostMode) { // GUESTMODE
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomItem) {
            return null;
        }

        let artistName = Utils.get(pr.roomItem, 'artist.title')
        const guestCount = pr.roomItem && pr.roomItem["guests"] ? pr.roomItem["guests"].length : 0;
        let hosts = Utils.get(pr.roomItem, 'insiders.mods');
        hosts = hosts ? hosts : [];

        const guestModeContainer = StyleUtils.getMediaStyle('guestModeContainer', sty.guestModeContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const videoNftContainer = StyleUtils.getMediaStyle('videoNftContainer', sty.videoNftContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const liveInfoContainer = StyleUtils.getMediaStyle('liveInfoContainer', sty.liveInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxHeader = StyleUtils.getMediaStyle('chatboxHeader', sty.chatboxHeader, sty, StyleUtils.getWidthType(pr.screen.width));

        const hideVipSideContainer = st.selectedTabMobileRoom === "chatbox" && pr.screen.mobile ? {opacity: 0} : null;

        return (
            <Div className="guestModeContainer" style={guestModeContainer}>

                <Div className="videoNftContainer" style={videoNftContainer}>
                    {!st.agoraActive && !st.isHost ?
                        <Div className="hostStartMessage" style={{...sty.hostStartMessage, ...{marginTop: pr.screen.mobile ? 55 : 15}}}>
                            Stream will start soon
                        </Div>
                        :
                        null
                    }

                    {this.renderGuestCountDown()}
                    {this.renderNoAudioOverlayHost()}
                    {this.renderNoAudioOverlayCoHost()}
                    {/* {this.renderNoVideoOverlayHost()} */}
                    {/* {this.renderNoVideoOverlayCoHost()} */}
                    {this.renderGearMenu()}

                    {this.renderStreamAlertModal()}
                    <Div
                        className="vipSideContainer"
                        style={{...sty.vipSideContainer, ...hideVipSideContainer}}
                    >
                        {this.renderInviteVipToStream()}
                        {this.renderRaiseHand()}
                        <Div
                            className="vipTitle"
                            style={sty.vipTitle}
                        >
                            Front Row
                        </Div>

                        <Div
                            className="vipsSide hide-scrollbar"
                            id="tab-container-vips"
                            style={sty.vipsSide}
                        />
                    </Div>

                    {st.customersVipDict[st.selectedVipId] && st.vipIsInLiveStream ?
                        <Div className="cohostLiveLabel" style={sty.cohostLiveLabel}>
                            @{Utils.shortenString(st.customersVipDict[st.selectedVipId].username, 20)}
                        </Div>
                        :
                        null
                    }


                    <Div style={{maxWidth: 1600, width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        {/* {this.renderTestInfo()} */}
                        {this.renderJoinMeeting()}
                        {this.renderHostTile("host", pr.roomItem["image_url"], artistName)}
                        {/* {this.renderHostTile("sub", pr.roomItem["image_url"], artistName)} */}

                        {/* <Div className="hostsRoomContainer" style={{display: "flex"}}>
                            {hosts.map((host) => {
                                return this.renderHostTile("sub", host["photo"], host["username"], 100);
                            })}
                        </Div> */}
                    </Div>
                    {false && isHostMode && st.agoraActive ?
                        this.renderVideoIcons()
                        :
                        null
                    }
                </Div>

                <Div className="liveInfoContainer" style={liveInfoContainer}>
                    {this.renderTabbedSelector()}
                </Div>

                {pr.screen.mobile ? this.renderMostRecentMessage() : null}
            </Div>
        );
    }

    renderRoomNotFound(notFoundErrorId) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomLoaded) {
            return null;
        }
        console.log("ROOM NOT FOUND", notFoundErrorId, Utils.get(pr, "roomItem"));
        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
            roomItem: Utils.get(pr, "roomItem"),
        }

                // For non-freemium, you don't own. For freemium, no more seats. For freemium timed, the 5 minute preview is up.
        const ID_BLACKLISTED = 23947219;

        return (
            <Div style={sty.roomNotFound}>
                {this.renderAfterpartyLogo()}

                {notFoundErrorId == 453424331 || notFoundErrorId == 453424332 || notFoundErrorId == 23947219 ?
                    <>
                                <Div style={{textAlign: "center"}}>
                                    { !Utils.get(pr.user, "id") ?
                                        <Div>
                                            <Div>Please sign in</Div>
                                            <Div>to access</Div>
                                        </Div>
                                        :
                                        <Div>
                                            <Div>You don't</Div>
                                            <Div>have access to</Div>
                                        </Div>
                                    }
                                    <Div style={{margin:20,color:Colors.magenta}}>{Utils.get(pr, "roomItem.eventTitle")}</Div>
                                    {notFoundErrorId == 453424332 ?
                                        <Div style={{fontSize:24}}>No Remaining Free Seats. Please purchase a ticket to enter.</Div>
                                        :
                                        null
                                    }
                                    {notFoundErrorId == ID_BLACKLISTED ?
                                        <Div style={{fontSize:24}}>You have violated our terms of service.</Div>
                                        :
                                        null
                                    }
                                </Div>
                        { !Utils.get(pr.user, "id") ?
                                    <Button style={{marginTop: 10}} onClick={this.goToSignIn.bind(this, true)}>
                                        Sign In / Register
                                    </Button>
                                    :
                                    <Div>
                                        {notFoundErrorId != ID_BLACKLISTED ?
                                            <Button style={{marginTop: 50}} onClick={Utils.gotoRoute.bind(this, pr, Utils.get(pr, "roomItem.purchaseUrl"))}>
                                                Purchase Access
                                            </Button>
                                            :
                                            null
                                        }
                                        <Button style={{marginTop: 50}} onClick={Utils.gotoRoute.bind(this, pr, "/p/afterparty_contact_form")}>
                                            Need Help?
                                        </Button>
                                    </Div>
                        }
                    </>
                    :
                    Utils.get(pr.user, "id") ?
                    <>
                                <Div style={{textAlign: "center"}}>Room Not Found</Div>
                                <Button style={{marginTop: 10}} onClick={this.backToEvents.bind(this)}>
                                    Back To Events
                                </Button>
                    </>
                    :
                    <>
                                <Div style={{textAlign: "center"}}>Room Not Accessible</Div>
                                <Button style={{marginTop: 10}} onClick={this.goToSignIn.bind(this, true, Utils.getRequest('guest'))}>
                                    Please Sign In
                                </Button>
                    </>

                }

            </Div>
        )
    }

    renderModes(isInsider, mode) {
        const isHostMode = isInsider || mode == 'host';
        return this.renderMode(isHostMode);
    }

    getBuyListing() {
        const pr = this.props;

        let buyListing = false;
        const listings = Utils.get(pr.roomItem, 'listings', []);

        for(var idx in listings) {
            const listing = listings[idx];
            if(Utils.get(listing, "bitclout_username") && listing['id'] != 75) {
                buyListing = listing;
                break;
            }
        }
        return buyListing;
    }

    renderAfterpartyLogo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <>
                {pr.screen.mobile ?
                    <AfterpartyLogo
                        style={{...{position: "fixed", zIndex: 1000, top: 20, left: 20}, ...Styles.dropShadow}}
                        history={pr.history}
                        screen={pr.screen}
                        width={140}
                    />
                    :
                    <AfterpartyLogo
                        style={{position: "absolute", zIndex: 1000, top: 20, left: 20}}
                        history={pr.history}
                        screen={pr.screen}
                        width={200}
                    />
                }
            </>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const signalAvailable = false;

        const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");
        let buyListing = this.getBuyListing();

        const host = Utils.get(pr.roomItem, "insiders.host", {username: ""});
        const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);

        const afterpartyRoomComponent = StyleUtils.getMediaStyle('afterpartyRoomComponent', sty.afterpartyRoomComponent, sty, StyleUtils.getWidthType(pr.screen.width));

        if(pr.roomNotFound) {
            return this.renderRoomNotFound(pr.roomNotFound);
        }

        return (
            <Div className="afterpartyRoomV3Component" style={afterpartyRoomComponent}>
                <DebugBox
                    title2="RoomV3"
                    show={this.state}
                    />
                {/* <DebugBox show={st} /> */}
                {this.renderTestInfo()}

                {pr.screen.mobile ?
                    null
                    :
                    <Div className="livestreamTitle" style={sty.livestreamTitle}>
                        {Utils.get(pr.roomItem, "title", "")}
                    </Div>
                }

                {this.renderAfterpartyLogo()}
                {this.renderErrorMsg()}

                {signalAvailable ?
                    <Div
                        className="signalBar"
                        style={{
                            position: "absolute",
                            top: 128,
                            right: 28,
                            height: 90,
                            zIndex: 1000,
                            borderRadius: 10,
                            width: 4,
                            background: "linear-gradient(180deg, #4BE06C 0%, #E04629 100%)",
                        }}
                    />
                    :
                    null
                }


                {pr.roomItem ?
                    this.renderModes(isInsider, st.mode)
                    :
                    null
                }

                {pr.screen.mobile ?
                    null
                    :
                    <>
                        <BorderSeparator
                            color="pinkGradient"
                            style={{marginTop: 100}}
                        />
                        <SplashFooter
                            screen={pr.screen}
                            history={pr.history}
                        />
                    </>
                }

                {st.showSionModal ?
                    <ModalNFT
                        screen={pr.screen}
                        nft={st.currentSoin}
                        onRequestClose={this.closeSoinModal.bind(this)}
                    />
                    :
                    null
                }

                {st.showProfileModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeProfileModal.bind(this)}
                        color="purple"
                        wide
                    >
                        <Profile
                            screen={pr.screen}
                            style={{padding: "20px 0 0"}}
                            hidePageBack
                            user={pr.user}
                            history={pr.history}
                            userLoaded={pr.userLoaded}
                        />
                    </Modal>
                    :
                    null
                }
                {pr.showBitCloutBuyModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={pr.handleBuyModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <iframe style={sty.buyIframe} src={`https://bitclout.com/u/${Utils.get(buyListing, "bitclout_username", "afterparty")}/buy`} />
                        </Div>
                    </Modal>
                    :
                    null
                }

                {pr.showFollowModal ?
                    <Modal
                        screen={pr.screen}
                        title={<><Image src={followUser} style={{width: 20, marginBottom: 6}} /> &nbsp;Following</>}
                        onRequestClose={pr.handleFollowModalClose.bind(this)}
                        color="purple"
                        style={{maxWidth: 500}}
                    >
                        <Div style={sty.followSubtitles}>Host</Div>
                        <Div className="followRow" style={sty.followRow}>
                            <Div
                                className="followImage"
                                style={{...sty.followImage, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                            ></Div>
                            {host.username}
                            <Button
                                style={{marginLeft: "auto"}}
                                color={pr.user.following[host.id] ? "magenta" : "white"}
                                size="small"
                                onClick={this.handleSubscribeClick.bind(this, host)}
                            >
                                {pr.user.following[host.id] ? "Following" : "Follow"}
                            </Button>
                        </Div>

                        {cohosts.length > 0 ?
                            <Div style={sty.followSubtitles}>Co-Hosts</Div>
                            :
                            null
                        }
                        {cohosts.map((cohost, cohostIndex) => {
                            const isFollowing = pr.user.following[cohost.id];

                            return (
                                <Div key={`cohost-${cohostIndex}`} className="followRow" style={sty.followRow}>
                                    <Div
                                        className="followImage"
                                        style={{...sty.followImage, ...{backgroundImage: `url('${cohost.photo ? cohost.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                    ></Div>
                                    {cohost.username}
                                    <Button
                                        style={{marginLeft: "auto"}}
                                        color="white"
                                        color={isFollowing ? "magenta" : "white"}
                                        size="small"
                                        onClick={this.handleSubscribeClick.bind(this, cohost)}
                                        noBorder
                                    >
                                        {isFollowing ? "Following" : "Follow"}
                                    </Button>
                                </Div>
                            )
                        })}
                    </Modal>
                    :
                    null
                }

                {st.showComingSoonModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.handleCloseComingSoonModal.bind(this)}
                        title="Coming Soon"
                    >
                        <Div>This feature is currently in development</Div>
                        <br/>
                        <Button
                            color="pinkGradient"
                            onClick={this.handleCloseComingSoonModal.bind(this)}
                        >
                            OK
                        </Button>
                    </Modal>
                    :
                    null
                }

                {this.renderAcceptInviteOverlay()}
                {this.renderLiveStreamHasEndedModal()}
                {this.renderTipModal()}
                {this.renderIntroModal()}
            </Div>
        );
    }

    styles = {
        afterpartyRoomComponent: {
            minHeight: "100vh",
            backgroundColor: Colors.indigo,
            color: "white",
            fontFamily: "Nyata",
            fontWeight: 400,
            // height: "calc(100vh - env(safe-area-inset-bottom))",
        },
        afterpartyRoomComponent_md: {
        },
        afterpartyRoomComponent_sm: {
            height: "100vh",
            overflow: "hidden",
        },
        afterpartyRoomComponent_xs: {
            height: "100vh",
            overflow: "hidden",
        },
        videoIconsContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: this.props.screen.mobile ? Colors.purple : "none",
            borderRadius: 100,
            marginBottom: 5,
            padding: 3,
        },
        videoIconsIcon: {
            padding: "10px 10px",
            margin: "0 10px",
            cursor: "pointer",
        },
        remoteContainer: {
            position: "relative",
            margin: 0,
            borderRadius: 14,
            overflow: "hidden",
            justifyContent: "center",
        },
        remoteContainer_md: {
            borderRadius: 0,
        },
        app: {
            backgroundColor: "#000",
            textAlign: "left",
            fontFamily: "'Montserrat', sans-serif",
        },
        leftCol: {
            minHeight: 850,
            maxWidth: 220,
            backgroundColor:"white",
            paddingLeft: 0,
            paddingRight: 0,
        },
        leftColBody: {
            paddingTop: 20,
            justifyContent: "center",
            display: "flex",
            flexDirection:"col",
        },
        rightCol: {
            paddingLeft:0,
            background:"#FBF8F4",
            minHeight:800,
            color:"black",
            padding: 20,
            width: "100%",
        },
        joinMeetingButton: {
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 500,
            width: "100%",
            padding: "16px 14px 17px",
        },
        joinMeetingButton_md: {
            marginBottom: 5,
        },
        joinMeetingButton_sm: {
            marginBottom: 5,
        },
        joinMeetingButton_xs: {
            marginBottom: 5,
        },
        eventBanner: {
            position: "relative",
            width: "100%",
        },
        eventBannerInfobox: {
            color: "white",
            position: "absolute",
            bottom: 55,
            left: 47,
            textAlign: "center",
        },
        nameBox: {
            position:"absolute",
            bottom: 50,
            left: 0,
            width: 200,
            height: 26,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        nameBoxSmall: {
            position:"absolute",
            bottom: 20,
            left: 0,
            width: 100,
            height: 23,
            fontSize: 10,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        videoContainer: {
            // minWidth: 565,
            //minHeight: 400,
            // background: "rgba(255,255,255,0.1)",
            // backgroundColor: Colors.purpleBackground,
            borderRadius: 22,
        },
        videoContainer_md: {
            borderRadius: 8,
            marginBottom: 0,
        },
        videoContainer_sm: {
            borderRadius: 0,
            // marginBottom: 5,
        },
        videoContainer_xs: {
            borderRadius: 0,
            // marginBottom: 5,
        },
        hostModeContainer: {
            display: "flex",
            flexWrap: "wrap",
            padding: "20px 40px",
            // maxWidth: 1600,
            marginLeft: "auto",
            marginRight: "auto",
        },
        hostModeContainer_lg: {
            padding: "20px 20px",
        },
        hostModeContainer_md: {
            padding: "20px 20px",
            flexDirection: "column",
        },
        hostModeContainer_xs: {
            padding: "5px 10px",
        },
        videoNftContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flex: 1,
            position: "relative",
            borderRadius: 36,
            overflow: "hidden",
        },
        videoNftContainer_lg: {
            width: "100%",
        },
        videoNftContainer_md: {
            width: "100%",
            paddingRight: 5,
        },
        videoNftContainer_sm: {
            width: "100%",
            paddingRight: 5,
        },
        videoNftContainer_xs: {
            width: "100%",
            paddingRight: 0,
            borderRadius: 0,
            overflow: "visible",
        },
        roomTitleContainer: {
            display: "flex",
            padding: "10px 0 0 0",
        },
        roomTitleContainer_xs: {
            padding: "0",
        },
        eventTitle: {
            fontWeight: "bold",
            fontSize: 18,
        },
        eventTitle_xs: {
            fontSize: 12,
        },
        artistTitle: {
            fontSize: 40,
            fontWeight: "bold",
            marginBottom: 15,
        },
        artistTitle_xs: {
            fontSize: 26,
        },
        eventTimeDuration: {
            padding: "8px 14px",
            borderRadius: 8,
            backgroundColor: Colors.purpleBackground,
            fontSize: 12,
        },
        guestModeContainer: {
            display: "flex",
            padding: "20px 80px",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
            maxWidth: 1100,
            gap: 20,
        },
        guestModeContainer_lg: {
            padding: "20px 20px",
            gap: 0,
        },
        guestModeContainer_md: {
            padding: "10px 5px 5px",
            gap: 0,
        },
        guestModeContainer_sm: {
            padding: "10px 5px 5px",
            gap: 0,
        },
        guestModeContainer_xs: {
            padding: 0,
            flexDirection: "column",
            gap: 0,
        },

        nftSoinContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
        },
        eventIconCircle: {
            borderRadius: "100%",
            height: 27,
            width: 27,
            backgroundColor: Colors.purpleLightBackground,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
        },
        chevronIcon: {
            fontSize: 18,
            marginLeft: -2,
        },
        profile: {
            width: 50,
            height: 50,
            backgroundColor: Colors.purpleLightBackground,
            borderRadius: 16,
            marginLeft: "auto",
        },

        customersContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
            flexDirection: "column",
        },

        hostsAndCohosts: {
            display: "flex",
            flexWrap: "wrap",
        },

        afterpartyRoomInfoContainer: {
            backgroundColor: Colors.purpleBackground,
            padding: 30,
            borderRadius: 24,
            marginBottom: 30,
         },
         afterpartyRoomInfoContainer_md: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_sm: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_xs: {
             padding: 10,
         },

         tabbedHideShowContainer: {
             position: "relative",
             backgroundColor: Colors.indigoLight,
             width: "100%",
             borderTopLeftRadius: 36,
             borderTopRightRadius: 36,
             padding: 34,
             paddingBottom: 10,
         },
         tabbedHideShowContainer_xs: {
             // backgroundColor: Colors.indigo,
             backgroundColor: Colors.translucentIndigo,
         },

         admissionSoins: {
             paddingTop: 16,
         },

        liveInfoContainer: {
            borderRadius: 36,
            width: "100%",
            position: "relative",
            backgroundColor: Colors.indigoLight,
            display: "flex",
            flexDirection: "column",
            flex: 1,
        },
        liveInfoContainer_md: {
            // backgroundColor: "none",
            // marginLeft: 0,
            // marginBottom: 0,
            // maxWidth: "100%",
            // height: "auto",
            // position: "unset",
        },
        liveInfoContainer_sm: {
            // backgroundColor: "none",
            // position: "unset",
        },
        liveInfoContainer_xs: {
            backgroundColor: "none",
            position: "fixed",
            bottom: 0,
            zIndex: 1003,
            borderRadius: 0,
        },
        scrollContainerStyle_lg: {
            padding: "15px 15px 0 15px",
        },
        chatboxHeader: {
            padding: "20px 20px 15px 20px",
            borderBottom: `1px solid ${Colors.purpleLight}`,
        },
        chatboxHeader_xs: {
            padding: "20px 15px 0 15px",
        },
        tabsContainer: {
            display: "flex",
            width: "100%",
            // backgroundColor: Colors.purpleLight,
            backgroundColor: Colors.purpleLightest,
            borderRadius: 14,
            fontSize: "bold",
            color: Colors.purple,
            marginBottom: 15,
        },
        tabSelected: {
            backgroundColor: Colors.purpleLighter,
            borderRadius: 14,
        },
        tabStyle: {
            cursor: "pointer",
            padding: 15,
            width: "50%",
            textAlign: "center",
        },
        tabStyle_xs: {
            padding: 10,
        },

        innerParticipantsContainer: {
            display: "flex",
            flexWrap: "wrap",
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 254px)",
            padding: "0 24px 24px 24px",
        },
        innerParticipantsContainer_sm_landscape: {
        },
        innerParticipantsContainer_xs_landscape: {
        },
        innerParticipantsContainer_xs: {
            flexDirection: "column",
            flexWrap: "nowrap",
            height: 452,
            padding: "0 15px 15px 15px",
        },

        roomNotFound: {
            fontFamily: "Nyata",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 48,
            color: "white",
            backgroundColor: Colors.indigo,
            width: "100%",
            height: "100vh",
        },
        blogBody: {
            overflow: "scroll",
            height: "calc(100vh - 254px)",
            paddingTop: 20,
            paddingRight: 20,
        },
        blogBody_xs: {
            height: 452,
        },

        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 10,
        },
        recentMessageContainer: {
            padding: "6px 10px",
            backgroundColor: Colors.indigoLight,
            color: "white",
            wordWrap: "break-word",
            borderTopLeftRadius: 36,
            borderTopRightRadius: 36,
            borderBottom: "1px solid #444"
        },
        // recentMessageContainer_sm: {
        //     width: "calc(100% - 40px)",
        // },
        recentMessageContainer_xs: {
            display: "flex",
            position: "fixed",
            bottom: 68,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 15,
            paddingBottom: 15,
            width: "100%",
            // background: "linear-gradient(180deg, rgba(39, 29, 55, 0) 29.17%, #0F0420 89.06%)",
            backgroundColor: Colors.translucentBlack,
            zIndex: 200,
        },
        customerInfoContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "auto",
        },
        customerIcon: {
            overflow: "hidden",
            backgroundSize: "cover",
            width: 32,
            height: 32,
            backgroundColor: Colors.purpleLight,
            marginRight: 10,
            borderRadius: 100,
            flexShrink: 0,
        },
        customerName: {
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 12,
            whiteSpace: "nowrap",
        },
        messageDate: {
            fontSize: 12,
            marginLeft: "auto",
            whiteSpace: "nowrap",
        },
        messageStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: "white",
            width: "100%",
            overflowWrap: "anywhere",
        },
        messageStyle_md: {
            fontSize: 12,
        },
        messageStyle_sm: {
            fontSize: 12,
        },
        messageStyle_xs: {
            fontSize: 12,
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 15,
        },
        plusMinusButton: {
            color: "black",
            padding: 10,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "15px 20px 20px",
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
        },
        inputContainer_xs: {
            padding: "8px 15px 15px",
        },
        inputAndSendIconContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
        },
        emoji: {
            padding: "0 8px"
        },
        buyIframeContainer: {
            width:"100%",
            height: 500,
            overflowX: "hidden",
            overflowY: "scroll",
        },
        buyIframe: {
            width:"100%",
            height:600,
            borderRadius:32,
        },

        tabbedSelectorContainer: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
        tabbedSelectorContainer_md: {
        },
        tabbedSelectorContainer_sm: {
        },
        tabbedSelectorContainer_xs: {
        },
        tabsRoomContent: {
            color: "white",
            height: "100%",
        },
        tabsRoomContent_md: {
        },
        tabsRoomContent_sm: {
        },
        tabsRoomContent_xs: {
        },
        tabsRoom: {
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: Colors.indigo,
            padding: "15px 30px",
            width: "100%",
            borderBottomLeftRadius: 36,
            borderBottomRightRadius: 36,
            border: `3px solid ${Colors.indigoLight}`,
        },
        tabsRoom_xs: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: "none",
            backgroundColor: Colors.translucentIndigo,
        },

        tabRoomMobileIcon: {
            width: 32,
            height: 32,

        },
        tabsRoomIconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            padding: 3,
        },
        arrowContainer: {
            paddingBottom: 8,
            display: "flex",
            color: "white",
            fontWeight: "bold",
        },
        tabbedContainerHeader: {
            marginTop: 15,
            marginBottom: 30,
            textAlign: "left",
            fontSize: 24,
        },

        tabbedContainerHeaderCollectibles: {
            marginBottom: 5,
            textAlign: "center",
            fontSize: 24,
        },
        customerOuterContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        customerContainer: {
            display: "flex",
            width: "100%",
            marginBottom: 26,
        },
        customerPhoto: {
            width: 82,
            height: 82,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 200,
            marginRight: 12,
            flexShrink: 0,
            position: "relative",
        },
        customerListName: {
            marginRight: "auto",
            fontWeight: 600,
            fontSize: 18,
            marginBottom: 5,
        },
        roomNftBadge: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: -5,
            right: -5,
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            borderRadius: "100%",
            fontSize: 10,
            width: 35,
            height: 35,
            lineHeight: "11px",
        },
        followSubtitles: {
            marginRight: "auto",
            marginBottom: 10,
            fontSize: 18,
            fontWeight: "bold",
        },
        followRow: {
            display: "flex",
            width: "100%",
            marginBottom: 10,
            alignItems: "center",
        },
        followImage: {
            height: 48,
            width: 48,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 8,
            marginRight: 15,
        },
        participantIcons: {
            width: 120,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "right",
        },
        participantIcon: {
            padding: 10,
        },
        videoEndEventButton:  {
            padding: "6px 14px 6px 14px",
            backgroundColor: "white",
            color: "black",
            borderRadius: 4,
            backgroundColor: "white",
            color: "black",
            height: "fit-content",
            width: "fit-content",
            marginLeft: 15,
            marginRight: 20,
        },

        livestreamTitle: {
            fontSize: 40,
            fontWeight: 800,
            textAlign: "center",
            paddingTop: 60,
        },

        menuBars: {
            position: "absolute",
            zIndex: 1000,
            top: 24,
            right: 20,
        },

        menuGear: {
            position: "absolute",
            zIndex: 1000,
            top: 20,
            right: 20,
        },

        menuGear_xs: {
            top: 72,
            position: "fixed",
        },

        mobileMenu: {
            position: "absolute",
            zIndex: 1002,
            top: 20,
            right: 55,
            width: 165,
            minHeight: 100,
            padding: 14,
            backgroundColor: Colors.indigo,
            borderRadius: 15,
        },
        mobileMenu_xs: {
            top: 65,
        },

        handUp: {
            height: 36,
            width: 36,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        handUp_xs: {
        },

        vipSideContainer: {
            position: "absolute",
            zIndex: 1001,
            bottom: 63,
            right: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        vipTitle: {
            marginTop: 15,
            marginBottom: 15,
            backgroundColor: Colors.magenta,
            padding: "4px 12px",
            borderRadius: 100,
            fontSize: 12,
        },

        vipsSide: {
            // width: 80,
            height: 234,
            overflowY: "scroll",
            display: "grid",
        },

        mobileMenuTitleRow: {
            display: "flex",

        },
        mobileMenuTitle: {
            fontSize: 17,
            marginRight: "auto",
        },

        videoMobile: {
            marginLeft: 13,
            flexShrink: 0,
        },

        greyBorderSeparator: {
            backgroundColor: "#373737",
            height: 2,
            marginTop: 15,
            marginBottom: 15
        },

        noBorderRadius: {
            borderRadius: 0,
        },

        closeButtonBottomContainer: {
            position: "absolute",
            left: 0,
            top: 0,
            height: 60,
            zIndex: 1001,
            background: "linear-gradient(180deg, #0F0420 25%, rgba(0,0,0,0) 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
            paddingRight: 30,
            borderTopLeftRadius: 36,
            borderTopRightRadius: 36,
            fontSize: 24,
        },

        participantsScrollContainer: {
            height: 616,
            overflow: "scroll",
        },
        participantsScrollContainer_xs: {
            // height: "calc(100vh - 68px)",
            height: "calc(100vh - 250px)",
            // borderRadius: 0,
        },

        hostInfoContainerOuter: {
            display: "flex",
            alignItems: "center",
        },

        hostInfoContainer: {
        },

        hostLabel: {
            backgroundColor: Colors.orange,
            padding: "4px 18px",
            color: "white",
            borderRadius: 100,
            marginBottom: 4,
            width: "fit-content",
            fontWeight: 400,
        },

        roomNfts: {
            marginBottom: 10,
            display: "flex",
            width: "100%",
            padding: "14px 12px",
            backgroundColor: Colors.indigoLight,
            borderRadius: 12,
            alignItems: "center",
        },

        vipMessage: {
            fontWeight: 400,
            fontSize: 11,
            padding: 8,
            backgroundColor: Colors.indigoLight,
            borderRadius: "0px 10px 10px 10px",
            marginTop: 8,
        },

        customerLiveDuration: {
            fontSize: 12,
            fontWeight: 400,
        },

        inviteVipToStream: {
            position: "absolute",
            width: 220,
            backgroundColor: Colors.indigo,
            borderRadius: 10,
            padding: "12px 14px",
            zIndex: 201,
            right: 70,
            marginTop: 50,
        },

        streamAlertModal: {
            position: "absolute",
            width: 220,
            backgroundColor: Colors.indigo,
            borderRadius: 10,
            padding: "12px 14px",
            zIndex: 1020,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },

        vipInviteName: {
            fontSize: 17,
            fontWeight: 400,
            marginBottom: 10,
        },
        vipInviteComment: {
            fontSize: 11,
            marginBottom: 10,
        },

        inviteVipCloseButton: {
            position: "absolute",
            right: 10,
            top: 5,
            color: Colors.magenta,
            fontSize: 20,
        },

        acceptInviteOverlay: {
            position: "absolute",
            display: "flex",
            inset: 0,
            backgroundColor: "rgba(0,0,0,0.8)",
            zIndex: 1500,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        noAudioOverlay: {
            position: "absolute",
            zIndex: 1001,
            marginTop: 55,
            marginLeft: 20,
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: 8,
            padding: 10,
        },
        noVideoOverlay: {
            position: "absolute",
            zIndex: 1001,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: 150,
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: 30,
        },

        hostStartMessage: {
            position: "absolute",
            zIndex: 1001,
            marginLeft: 20,
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: 8,
            padding: 10,
        },

        videoIconOverlay: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        testInfoBox: {
            position: "absolute",
            width: "100%",
            bottom: 0,
            zIndex: 1055,
            backgroundColor: Colors.purpleDark,
            padding: 10,
            borderRadius: 8,
            marginBottom:8,
            flexWrap: "wrap",
            fontFamily: "sans-serif",
            fontSize: 12,
            maxHeight: "40%",
            overflowY: "scroll",
        },
        testInfoPanel: {
            padding:12,
            margin:12,
            borderRadius:12,
            fontWeight:"normal",
            display: "flex",
            marginBottom: 20,
            backgroundColor:"rgba(0,0,0,0.3)",
            flexDirection:"column",
            minWidth: 300,
        },
        testInfoStatRow: {
            display: "flex",
            flexDirection:"row",
            gap: "0px 20px",
        },
        testInfoStatCol: {
            color:"lightgray",
            width:"100%",
        },
        guestCountdownContainer: {
            position: "absolute",
            zIndex: 1001,
            marginTop: 20,
            minWidth: 35,
            textAlign: "center",
            display: "flex",
            marginLeft: 20,
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: 8,
            padding: 10,
        },
        errorMsg: {
            padding:12,
            textAlign:"center",
            margin:6,
            borderRadius:6,
            width:"100%",
            color:"black",
            backgroundColor:"paleyellow"
        },

        messageContainer: {
            color: "white",
            marginBottom: 20,
            wordWrap: "break-word",
        },

        infoIcon: {
            width: 17,
            marginLeft: 8,
        },

        superChatCost: {
            fontSize: 18,
            fontWeight: 700,

            background: "linear-gradient(180deg, #F4A868 0%, #F87B55 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            webkitTextStroke: "1px white",
        },

        superSendImageUsernameContainer: {
            display: "flex",
            alignItems: "center",
        },

        messageSuperStyle: {
            background: "rgba(248, 124, 85, 0.3)",
            borderRadius: 22,
            padding: 13,
        },

        handUpOverlayIconGeneric: {
            position: "absolute",
            top: 0,
            left: 0,
            width: 82,
            height: 82,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        cohostLiveLabel: {
            padding: "4px 8px",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            top: "50%",
            marginTop: 23,
            left: 10,
            zIndex: 1000,
            borderRadius: 4,
            transform: "translateY(-50%)",
        },
    };
}
