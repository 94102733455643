import Utils from './Utils';

export default {
    getErrorMessages(errors, returnArray, dataVal, overrideMessages) {
        if(!errors) {
            errors = [];
        }

        if(!overrideMessages) {
            overrideMessages = {}
        }

        overrideMessages["20404"] = "Phone number "+dataVal+" is invalid";

        let errorList = [];
        for(var idx in errors) {
            let errorObj = errors[idx];

            if(overrideMessages[errorObj.id]) {
                errorObj.message = overrideMessages[errorObj.id];
            }
            errorList.push(errorObj);
        }
        if(returnArray) {
            console.log("errorList >>>>", errorList);
            return errorList;
        } else {
            return errorList.join(", ");
        }
    }
}
