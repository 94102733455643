import React, { Fragment } from 'react';
import Scroller from './Scroller';


export default class PlaylistScroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
    };

  }

  handlePlaylistClick(playlist) {
      this.props.handlePlaylistClick(playlist);
  }

  renderPlaylistIcons() {
      if(!this.props.playlists) {
          return null;
      }

      let iconWidth = 80;
      let iconHeight = 80;
      let fontSize = 12;
      if(this.props.mobile) {
          iconWidth = 40;
          iconHeight = 40;
          fontSize = 9;
      }

      return this.props.playlists.map( (item) => {
            return (
                 <div
                    key={item["id"]}
                    className="playlistTileNew"
                    style={this.props.showActive && item["id"] == this.props.selectedPlaylistId ? this.styles.playlistTileActive : this.styles.playlistTile}
                    onClick={this.handlePlaylistClick.bind(this,item)}
                    title={item["description"]}
                    >
                      <div>
                        {!this.props.showActive && this.props.selectedPlaylists.indexOf(item["id"]) != -1 ?
                          <div style={this.styles.playlistCheck}>
                            <i className="fa fa-check" />
                          </div>
                          :
                          null
                        }
                      </div>

                        <img style={{width: iconWidth, height:iconHeight}} src={item["image_url"]} />
                        <div style={{color:"white",fontSize:fontSize}}>{item["title"]}</div>
                </div>
            );
      });

  }


  render() {
    const minHeight = this.props.mobile ? 90 : 120;
    const marginLeft = this.props.mobile ? 0 : 40;
    return (
      <Scroller
        items={this.renderPlaylistIcons()}
        numSlots={this.props.numSlots}
        centerSlidePercentage={this.props.centerSlidePercentage}
        showThumbs={false}
        useKeyboardArrows={true}
        autoPlay={false}
        emulateTouch={true}
        showStatus={false}
        showIndicators={false}
        whiteArrows={true}
        minHeight={minHeight}
        marginLeft={marginLeft}
        />
    );
  }


  styles = {
    playlistCheck: {
      background: "rgb(0, 188, 223)",
      position: "absolute",
      right: -8,
      top: 8,
      width: 24,
      height: 24,
      fontSize: 18,
      borderRadius: "50%",
      color: "white",
    },
    playlistAdded: {
      background: "red",
      position: "absolute",
      right: -8,
      top: 8,
      width: 48,
      height: 24,
      fontSize: 10,
      borderRadius: "25%",
      color: "white",
      padding: 4,
    },
    playlistTile: {
        float:"left",
        margin: "6px 20px 12px 20px",
        userSelect: "none",
        position:"relative",
        paddingBottom: 6,
    },
    playlistTileActive: {
        float:"left",
        margin: "6px 20px 12px 20px",
        userSelect: "none",
        position:"relative",
        borderBottom: "2px solid white",
        paddingBottom: 6,
    },

  };
}
