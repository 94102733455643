import React, { Fragment } from 'react';

import moment, { max } from 'moment'

export default class SoinCard extends React.Component {

  handlePosterClick() {
      if(this.props.onClick) {
          return this.props.onClick(this.props.mediaItem);
      }
  }


  render() {
      const listing = this.props.mediaItem;
      // console.log("SOINCARD", listing);
      const size = this.props.size ? this.props.size : 180;
      const borderRadius = 0; //parseInt(size*.1);
      const captionHeight = parseInt(size*.4);
      const fontSize = parseInt(size*.06);
      const trackName = listing["title"]; //album["tracks"][0]["title"];
      const artistName = listing["artist_name"]; //album["artist"]["name"];
      const remainingPrints = listing["total_prints"] - listing["prints_sold"];
      //size = 180;
      let ts = moment();
      let formatDate = ts.format('h') + "h " + ts.format('m') + "m " + ts.format('s') + "s";
      let timeRemaing = "2h 35m";

      var a = moment([2021, 2, 6]);
      var b = moment([2021, 2, 4]);
      var hours = a.diff(b, 'hours');
      var minutes = a.diff(b, 'minutes');
      var seconds = a.diff(b, 'seconds');
      var sec = seconds % 60;
      window.sec = seconds;
      const hideRemaining = this.props.hideRemaining;


        /*
        (function timerLoop() {
          $(".difference > span").text(moment().to(then));
          $(".countdown").text(countdown(then).toString());
          requestAnimationFrame(timerLoop);
        })();
        */

      formatDate = a.diff(b, 'hours') + "h " + a.diff(b, 'minutes') + "m " + sec + "s "; // 1
      formatDate = listing["remaining"];

      return <div
        key={`card-${listing['id']}`}
        onClick={this.handlePosterClick.bind(this, listing)}
        style={{position:"relative",marginTop:6,borderRadius:borderRadius,overflow:"hidden",textAlign:"left"}}>
            { listing["listing_status"] == 2 ?
            <div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>{formatDate}</div>
            :
            null
            }
                <img style={{width:size, height:size, filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"}} src={listing["image_url"]} />
                <div style={{whiteSpace: "nowrap", textAlign:"left",marginTop:19}}>
                    <div style={{fontSize:14,fontWeight:"bold"}}>
                        {trackName}
                    </div>
                    <div style={{fontSize:12,color:"gray",marginTop:4}}>
                        {artistName}
                    </div>
                    { listing["listing_status"] == 2 && !hideRemaining ?
                        <div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>
                            {remainingPrints} of {listing["total_prints"]} left
                        </div>
                        :
                        null
                    }
                </div>
        </div>;
  }


}
