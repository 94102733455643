import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Div from '../elements/Div';
import Button from '../elements/Button';
import Span from '../elements/Span';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import FlashMessage from '../elements/FlashMessage';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import BorderSeparator from '../elements/BorderSeparator';
import QrEmailSendButton from '../elements/QrEmailSendButton';
import AfterpartyLogo from '../elements/AfterpartyLogo';

import AfterpartyCarousel from '../elements/AfterpartyCarousel';
import ll from '../modules/ll';

import SoinCard from './SoinCard';
import EventCard from './EventCard';
import ModalRegister from './ModalRegister';
import Scroller from './Scroller';
import WalletTable from './WalletTable';
import SplashFooter from './SplashFooter';
import NftItem from './NftItem';

import ticketProfileBackgroundImage from "../assets/images/ticketflow/bg-profile@2x.jpg";
import apLogo from "../assets/images/ticketflow/ap logo.png";

export default class MyWallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: false,
            showRegisterModal: false,
            showTicketModal: false,

            bitCloutAddress: "",
            flashMessage: false,
            selectedTicket: null,
            expandedAvailable: -1,

            showCollectibleModal: false,
            selectedCollectible: null,
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;

        // NOTE: hack to reload profile after minting or after claim login
        pr.change('mywallet', false, true);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let profile = false;

        if (!newProps.public) {
            profile = Utils.get(newProps.user, "id") ? newProps.user : null;
        } else {
            profile = newProps.publicProfile ? newProps.publicProfile : false;
        }

        if (!profile && newProps.userLoaded && !newProps.public) {
            newProps.history.push("/login?next=mywallet");
            window.location.reload();
        }
    }

    editProfile() {
        this.setState({ showRegisterModal: true });
    }

    verifyEmail() {
        const data = {
            email: this.props.user.email,
            validate_step: "email_verify",
            format: "json",
        };

        Api.postRegister(data, (response) => {
            if (response == null) {
                return;
            }

            if (Utils.get(response, "success")) {
                this.setState({ flashMessage: true });
            } else {
                this.setState({ errorMessages: Utils.get(response, "errors", "") });
            }
        });
    }

    handleChangeTickets() {
        this.props.change('mywallet', false, true);
    }

    handleLogoutClick() {
        Api.getLogout(() => {
            this.props.history.push("/login");
            window.location.reload();
        });
    }

    handleExpandedAvailableToggle() {
        this.setState({ expandedAvailable: this.state.expandedAvailable * -1 });
    }

    closeRegisterModal() {
        this.setState({ showRegisterModal: false });
    }

    closeTicketModal() {
        this.setState({ showTicketModal: false });
    }

    getNewUserAfterRegisterSuccess(newUser) {
        console.log("newUser", newUser);
        // this.props.setUser(newUser);
    }

    handleBackToEvents() {
        const pr = this.props;

        pr.history.push('/tickets/1');
        pr.change('tickets');

        return;

        if (Utils.isDemoMode()) {
            pr.history.push('/guardian?stage=marketing');
            pr.change('guardian');
        } else {
            pr.history.push('/home');
            pr.change('home');
        }

    }

    handlePosterClick(item) {
        if (item['json'] && item['json'].indexOf('permalink') != -1) {
            console.log("PERMALINK");
            let data = JSON.parse(item['json']);
            window.open(data['permalink'], "_blank");
            return;
        }
        item["thumbnail"] = item["cover_big"];
        this.setState({
            selectedMediaItem: item,
        }, () => {
            this.props.history.push('/nft/' + item["name"] + "-" + item["crc"])
            this.props.change('item', item);
        });
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        if (!accounts && bitCloutPublicKey) {
            Api.putMusicWallet({
                params: {
                    user: {
                        bitclout_public_key: bitCloutPublicKey
                    }
                }
            });
            this.setState({
                bitCloutAddress: bitCloutPublicKey,
                registeredSuccess: true,
            });
        } else {
            Api.putMusicWallet({
                params: {
                    user: {
                        wallet_id: accounts[0]
                    }
                }
            });
            this.setState({ wallet: accounts });
        }
    }

    handleArtistClick(item) {
        const nftUrl = '/soin/' + item['name'] + '-' + item['crc'];
        console.log("nftUrl", nftUrl);
        this.props.history.push(nftUrl);
        //this.change('event', event);
    }

    buyMore() {
        console.log("buyMore");
    }

    handleNewSoinClick(artist) {
        const featured = {};

        this.setState({
            selectedMediaItem: artist,
        }, () => {
            let newRoute = `seller/${artist.id}/newnft/${artist.id}`;
            this.props.change(newRoute, artist);
            this.props.history.push(`/${newRoute}`)
        });
    }

    handleCreateWalletClick() {
        console.log("Create wallet");
        Api.postCreateCustodialWallet({}, (o) => {
            console.log(Utils.get(o, 'data.walletId.wallet_id'));
            this.setState({ custodialWalletId: Utils.get(o, 'data.walletId.wallet_id'), showCreateWalletModal: true });
        });
    }

    handleViewTicketClick(item) {
        this.setState({ showTicketModal: true, selectedTicket: item });
    }

    openCollectibleModal(item) {
        this.setState({
            showCollectibleModal: true,
            selectedCollectible: item,
        });
    }

    closeCollectibleModal() {
        this.setState({
            showCollectibleModal: false,
            selectedCollectible: null,
        });
    }

    renderPassStatus(item) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let nftStatus = "";
        const masterCollectionId = Utils.get(item, "master_collection_id");
        if (masterCollectionId >= 7) {
            nftStatus = ""; //"Unrevealed";
        }

        if (parseInt(item.nft_style) >= 1) {
            if (masterCollectionId == 9) {
                if (item.nft_style == 1) {
                    nftStatus = "Level 1";
                } else {
                    nftStatus = "Winning Pass!";
                }
            } else {
                if (item.nft_style == 2) {
                    nftStatus = "Winning Pass!";
                } else {
                    nftStatus = "Level " + item.nft_style;
                }
            }
        }

        return (
            <Div
                className="passStatus"
                style={sty.passStatus}
            >
                {nftStatus}
            </Div>
        )
    }

    renderQrCode() {
        const images = [
            'qrcode_00117843cf5892dd484dbb0ddc33deb7.png',
            'qrcode_0011d7abc9c133b44fb0c55e1431883b.png',
            'qrcode_0013ce1b18e1663dc6b3c5fd8f84354f.png',
            'qrcode_0036f4d0be500f74d36c02ffbfdaeaee.png',
            'qrcode_003c5be36290b3df748c5c84f72e292a.png',
            'qrcode_004ca4893594a5126918735799afadc2.png',
            'qrcode_00589b77b6f5b3645c294ff6aac6a553.png',
            'qrcode_005a6ed37d692759f32f670ef5a71481.png',
            'qrcode_00680ec6708e63507399c5d1e0525b48.png',
            'qrcode_006de4b26a8b9cd9c3d9c430cf3fca4d.png',
            'qrcode_0099e71b9377910229ab4dbe784e1fe3.png',
            'qrcode_009d24223db0583121555e0ee12fd251.png',
            'qrcode_00bacdb07b3ee3353df57902afd56948.png',
            'qrcode_00e170fed06a956d4dce7b69f25c9d85.png',
            'qrcode_00e27aed5881fd671ea1b6cd0563c96f.png',
            'qrcode_011116c4f912d3466496a68403344332.png',
            'qrcode_0124b9bfda3be490d576bb00b6276498.png',
            'qrcode_012fc6c6bc5afa056f2e9f7ea4b532e1.png',
            'qrcode_017157217bce7990565e605fb6d4e712.png',
            'qrcode_0173381204a63d7ec1b986faee07b746.png',
            'qrcode_0176583d6e4a532f79d6c36648240300.png',
            'qrcode_01a24f066c4859b7e5180299df7b2135.png',
            'qrcode_01d9640d5fccb911778bf62876562d7a.png',
            'qrcode_01dc6918996270227a2ed86e1026e59a.png',
            'qrcode_01dfe98804f508e836eebe53fe92a3bd.png',
            'qrcode_01e8a12ad090dc800efcb462fd4b6df2.png',
            'qrcode_01ed9a8c6808eb180ba9706bb8696fe3.png',
            'qrcode_02033ffda0697f93ced015a6ba152001.png',
            'qrcode_02345254ef3ec0ff96418a0f34599285.png',
            'qrcode_02468f9cfeca14bf607c97d036aa2f90.png',
            'qrcode_0246de357fea9c902086c27729baa253.png',
            'qrcode_024e0da9ab0a8319ec4a40c867bb5948.png',
            'qrcode_02734d58d0cc9a92b96b8189c4ca6522.png',
            'qrcode_027f2fcaccfe7a28c7e4ff4e23d28288.png',
            'qrcode_02969259b910925d3dc524e2e21df691.png',
            'qrcode_029b48ea1aa7bfb0cc077ca655b0f74d.png',
            'qrcode_02cebdcbf2d39a1912f711f526a794cf.png',
            'qrcode_02d6773f795388d6ef534283ebdf0836.png',
            'qrcode_02f17846face039f4ef45e0de9fa8ae7.png',
        ];
        var ticketId = this.state.selectedTicket ? this.state.selectedTicket['id'] : 0
        var idx = ticketId % images.length;

        const imagePath = "/images/qrcodes/" + images[idx]; //"/images/qrcodes/qrcode_00117843cf5892dd484dbb0ddc33deb7.png";
        return (
            <Image src={imagePath} />
        )

    }

    renderWalletBar() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const selectedWallet = Utils.get(pr.user, 'wallets.0');
        return (
            <Div style={{ display: "flex" }}>
                <Div style={this.styles.connectWalletButton}>
                    <Button
                        color="orange"
                        style={{ width: 60, height: 60, minWidth: "auto" }}
                        onClick={this.handleCreateWalletClick.bind(this)}
                    >+</Button>
                </Div>
                <Div style={this.styles.connectWalletInfo}>
                    <Div style={{ marginTop: 6, marginRight: 8, borderRadius: 24, width: 24, height: 24, backgroundColor: "red" }}></Div>
                    {!this.state.custodialWalletId ?
                        <Div>
                            <Div style={{ fontWeight: "bold" }}>{Utils.abbreviateHash(Utils.get(selectedWallet, 'wallet_id'))}</Div>
                            <Div>MetaMask</Div>
                        </Div>
                        :
                        <Div>
                            <Div style={{ fontWeight: "bold" }}>{Utils.abbreviateHash(this.state.custodialWalletId)}</Div>
                            <Div>Afterparty Wallet</Div>
                        </Div>
                    }

                </Div>
            </Div>
        )
    }

    renderProfileHeader(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};

        const profileImageAndInfoContainer = StyleUtils.getMediaStyle('profileImageAndInfoContainer', sty.profileImageAndInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileHeader" style={sty.profileHeader}>
                <BloatedCircleDefs
                    scales={[50, 100, 200]}
                />
                <Div className="profileImageAndInfoContainer" style={profileImageAndInfoContainer}>
                    {user["photo"] ?
                        <Div style={{ marginBottom: 20, display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Image style={{ width: 200, borderRadius: 100 }} src={user["photo"]} />
                        </Div>
                        :
                        null
                    }

                    <Div className="profileInfo" style={sty.profileInfo}>
                        <Div style={{ fontSize: 36, fontWeight: "bold" }}>{user["full_name"]}</Div>
                        <Div style={{ fontSize: 18, fontWeight: "bold" }}>@{user["username"]}</Div>
                        {pr.public ? null : <Div>{user["email"]}</Div>}
                        {/* pr.public ? null : <Div>{Utils.formatPhoneNumber(user["primary_phone1"])}</Div> */}
                    </Div>
                </Div>

                {pr.public ?
                    null
                    :
                    <Div style={sty.profileButtons}>
                        <Button
                            size="small"
                            color="purpleLight1"
                            onClick={this.editProfile.bind(this)}
                            style={{ width: "100%", flex: 1, minWidth: "auto", backgroundColor: "#271D37" }}
                        >
                            Edit Profile
                        </Button>
                        {false ?
                            <Button
                                size="small"
                                color="purpleLight1"
                                onClick={this.verifyEmail.bind(this)}
                                style={{ width: "100%", flex: 1, minWidth: "auto", }}
                            >
                                Verify Email
                            </Button>
                            :
                            null
                        }
                        <Button
                            size="small"
                            color="purpleLight1"
                            onClick={this.handleLogoutClick.bind(this)}
                            style={{ width: "100%", flex: 1, minWidth: "auto", backgroundColor: "#271D37" }}
                        >
                            Sign Out
                        </Button>
                    </Div>
                }
            </Div>
        );
    }
    renderNewWallets() {
        return null;
    }
    renderNewNfts() {
        const pr = this.props;
        const st = this.state;

        return (
            <Div>
                <Div style={{ display: "flex", width: "100%", position: "relative", height: 200 }}>
                    <Div style={this.styles.artistTilesContainer}>
                        {pr.user.items.map((o) => {
                            return (
                                <Div key={`nftid-${o.id}`} style={this.styles.artistTile}>
                                    <Div
                                        onClick={this.handleArtistClick.bind(this, o)}
                                        style={this.styles.artistTileImageContainer}
                                    >
                                        <Image style={this.styles.artistTileImage} src={o.image_url} />
                                        {false ?
                                            <Div style={this.styles.artistTileTitle}>{o.title}</Div>
                                            :
                                            null
                                        }
                                    </Div>
                                    <Div
                                        onClick={this.handleViewTicketClick.bind(this, o)}
                                        style={{ textDecoration: "underline", marginTop: 8 }}
                                    >
                                        View Ticket
                                    </Div>
                                </Div>
                            )
                        })}
                    </Div>
                </Div>
            </Div>
        );
    }

    renderProfileWallets() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let wallets = Utils.get(pr.user, "wallets", []);

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));


        return (
            <Div>
                <Div style={this.styles.sectionHeadline}>Your Wallets</Div>
                {this.renderWalletBar()}
                <Div style={this.styles.sectionHeadline}>Your Afterparty Collections</Div>
                {this.renderNewNfts()}
            </Div>
        );

        return (
            <Div className="profileWalletsContainer" style={profileSectionContainer}>
                <Div style={this.styles.profileSectionHeadline}>Wallets</Div>
                <Div>
                    <WalletTable
                        screen={pr.screen}
                        handleSetWallet={this.handleSetWallet.bind(this)}
                        user={pr.user}
                    />
                </Div>
            </Div>
        );
    }

    renderUtopians(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (!pr.utopians) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Utopians</Div>
                <Div style={{ paddingRight: 30, paddingBottom: 30, paddingLeft: 30 }}>
                    Utopians Info
                    <ConnerCarousel
                        screen={pr.screen}
                        items={pr.user.items}
                        imagePath="artist_image_url"
                    />
                    {/* {pr.utopians.map((utopian, utopianIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`utopian-${utopianIndex}`}>
                                <Image
                                    style={{width: 200}}
                                    src={utopian.image_url}
                                />
                            </Div>
                        )
                    })} */}
                </Div>
            </Div>
        )
    }

    renderGuardians(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (!pr.guardians) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Guardians</Div>
                <Div style={{ paddingRight: 30, paddingBottom: 30, paddingLeft: 30 }}>
                    Guardians Info
                    {pr.guardians.map((guardian, guardianIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`guardian-${guardianIndex}`}>
                                <Image
                                    style={{ width: 200 }}
                                    src={guardian.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderMintPasses(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (!pr.mintpasses) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Mint Passes</Div>
                <Div style={{ paddingRight: 30, paddingBottom: 30, paddingLeft: 30 }}>
                    Mint Passes Info
                    {pr.mintpasses.map((mintpass, mintpassIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`mintpass-${mintpassIndex}`}>
                                <Image
                                    style={{ width: 200 }}
                                    src={mintpass.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderTickets(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (!pr.festivalpasses) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Tickets</Div>
                <Div style={{ paddingRight: 30, paddingBottom: 30, paddingLeft: 30, display: "flex", justifyContent: "space-around" }}>
                    Tickets Info
                    {pr.festivalpasses.map((ticket, ticketIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`ticket-${ticketIndex}`}>
                                <Image
                                    style={{ width: 200 }}
                                    src={ticket.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }


    renderProfileEvents(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};
        //return <Div>EVENTS</Div>;
        let renderedTiles = [];
        let myEvents = [];
        if (user.events) {
            myEvents = user.events;
            renderedTiles = myEvents.map((event, idx) => {
                return <EventCard
                    key={`${idx}-EventCard`}
                    mediaItem={event}
                    width={240}
                    height={180}
                    hideRemaining
                    onClick={this.handlePosterClick.bind(this)}
                />
            });
        } else {
            ll._("No user events", "red", user);
        }

        let centerMode = true;
        let slidePercentage = 20;

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Events</Div>
                <Div style={{ paddingRight: 30, paddingBottom: 30, paddingLeft: 30 }}>
                    {myEvents.length > 0
                        ?
                        <Carousel
                            key="MarketplaceScroller"
                            showThumbs={false}
                            useKeyboardArrows
                            emulateTouch
                            showStatus={false}
                            showIndicators={false}
                            centerMode={centerMode}
                            centerSlidePercentage={slidePercentage}
                        >
                            {renderedTiles}
                        </Carousel>
                        :
                        <Div>No events yet</Div>
                    }
                </Div>
            </Div>
        );
    }

    renderMyCollection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};
        let renderedTiles = [];
        let myCollection = [];
        if (user.items) {
            myCollection = user.items;
            //console.log("Render user items", myCollection);
            renderedTiles = myCollection.map((album, idx) => {
                return (
                    <SoinCard
                        key={`${idx}-SoinCard`}
                        mediaItem={album}
                        size={180}
                        hideRemaining
                        onClick={this.handlePosterClick.bind(this)}
                    />
                )
            });
        } else {
            console.log("No user items", user);
        }
        let slidePercentage = 20;
        let transitionTime = 350;
        let centerMode = true;

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div style={sty.headline}>My Collection</Div>
                <Div style={{ padding: 30 }}>
                    {myCollection.length > 0
                        ?
                        <Carousel
                            key="MarketplaceScroller"
                            showThumbs={false}
                            useKeyboardArrows
                            emulateTouch
                            showStatus={false}
                            showIndicators={false}
                            centerMode={centerMode}
                            centerSlidePercentage={slidePercentage}
                        >
                            {renderedTiles}
                        </Carousel>
                        :
                        <Div>No items yet</Div>
                    }
                </Div>
            </Div>
        )
    }

    renderCollections(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    NFT Collections
                </Div>
                {user.collections && user.collections.length > 0 ?
                    this.renderMyCollection(user)
                    :
                    <Div>No NFTs yet</Div>
                }
            </Div>
        );
    }

    renderMyNfts(myNfts) {
        let renderedTiles = [];
        if (myNfts) {
            //console.log("Render user items", myCollection);
            renderedTiles = myNfts.map((album, idx) => {
                return <SoinCard
                    key={idx}
                    mediaItem={album}
                    size={180}
                    hideRemaining={true}
                    onClick={this.handlePosterClick.bind(this)}
                />
            });
        } else {
            console.log("No user items", this.props.user);
        }
        let slidePercentage = 20;
        let transitionTime = 350;
        let centerMode = true;

        return <Div style={this.styles.sectionHead}>
            <Div style={this.styles.headline}>My NFTs</Div>
            <Div style={{ padding: 30 }}>
                {myNfts.length > 0
                    ?
                    <Carousel
                        key={"MarketplaceScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                    >
                        {renderedTiles}
                    </Carousel>
                    :
                    <Div>No items yet</Div>
                }
            </Div>
        </Div>;
    }

    renderOtherNfts() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const nfts = Utils.get(pr, "user.opensea", []);

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    Other NFTs
                </Div>
                {nfts.length > 0 ?
                    <Div style={{ textAlign: "left" }}>
                        {this.renderMyNfts(nfts)}
                    </Div>
                    :
                    <Div>You don't own any NFTs yet</Div>
                }
            </Div>
        );
    }

    renderArtists(reppedArtists) {
        return reppedArtists.map((artist, artistIndex) => {
            return (
                <Div key={`${artistIndex}-${artist.id}`} style={{ display: "flex", padding: 20 }}>
                    <Div style={{ marginRight: 20 }}>
                        <img style={{ width: 128, height: 128, borderRadius: 20, overflow: "hidden" }} src={artist["image_url"]} />
                    </Div>
                    <Div style={{ marginRight: 20 }}>
                        {artist["title"]}
                    </Div>
                    <Div style={{ marginRight: 20 }}>
                        <Button
                            onClick={this.handleNewSoinClick.bind(this, artist)}
                            size="small"
                        >
                            Create Afterparty NFT
                        </Button>
                    </Div>
                </Div>
            )
        });
    }

    renderArtistNftManagement() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const reppedArtists = Utils.get(pr, "seller.reppedArtists");

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    Artist NFT Management
                </Div>
                {pr.user && pr.user["id"] && reppedArtists && reppedArtists.length > 0 ?
                    <Div>
                        <Div>
                            Current active items/auctions: None
                        </Div>
                        <Div>
                            {this.renderArtists(reppedArtists)}
                        </Div>
                        <Button
                            onClick={this.handleNewSoinClick.bind(this)}
                        >
                            Register Additional Artists
                        </Button>
                        <Div className='row' style={sty.button}>
                        </Div>
                    </Div>
                    :
                    <Div style={{ textAlign: "left", marginTop: 30 }}>
                        To mint custom Soins, you need to be a registered artist or an artist representative.
                    </Div>
                }
            </Div>
        );
    }

    renderTable() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const tableRows = [0, 1, 2, 3, 4]

        return (
            <Div className="tableContainer" style={sty.tableContainer}>
                <Div className="tableHeader" style={sty.tableHeader}>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Published NFTs
                    </Div>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Submitted NFTs
                    </Div>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Drafts
                    </Div>
                </Div>
                <Div className="tableContents" style={sty.tableContents}>
                    {tableRows.map((tableRow, tableRowIndex) => {
                        return (
                            <Div key={`${tableRowIndex}`} className="tableRow" style={sty.tableRow}>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    PUBLISHED
                                </Div>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    SUBMITTED
                                </Div>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    DRAFTS
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderCarousel() {
        const pr = this.props;
        let items = [
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
            { name: "hello" },
        ];

        return (
            <ConnerCarousel
                items={items}
                size={250}
            />
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let profile = false;
        // If not a public profile, display user info
        if (!pr.public) {
            profile = Utils.get(pr.user, "email") ? pr.user : null;
        } else {
            // Public profile -- check if profile is loaded
            profile = pr.publicProfile ? pr.publicProfile : false;
        }

        const profileComponent = StyleUtils.getMediaStyle('profileComponent', sty.profileComponent, sty, StyleUtils.getWidthType(pr.screen.width));
        const ticketPassHeader = StyleUtils.getMediaStyle('ticketPassHeader', sty.ticketPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const apLogoPassHeader = StyleUtils.getMediaStyle('apLogoPassHeader', sty.apLogoPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassTitle = StyleUtils.getMediaStyle('profilePassTitle', sty.profilePassTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassSubtitle = StyleUtils.getMediaStyle('profilePassSubtitle', sty.profilePassSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const passContainer = StyleUtils.getMediaStyle('passContainer', sty.passContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const itemContainer = StyleUtils.getMediaStyle('itemContainer', sty.itemContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const artistCatalogItem = StyleUtils.getMediaStyle('artistCatalogItem', sty.artistCatalogItem, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        if (!profile && pr.userLoaded && !pr.public) {
            return null;
        }

        const apPass = Utils.get(st.selectedTicket, "master_collection_id") == 7 ? true : false;
        const nftStyle = Utils.get(st.selectedTicket, "nft_style");

        let title = "Unknown";
        let description = "Unknown";

        if (Utils.get(st.selectedTicket, "id") > 1816) {
            title = Utils.get(st.selectedTicket, "title");
            description = Utils.get(st.selectedTicket, "description");
        } else {
            // TODO: we need to remove this hard coding but have it all come from the backend
            title = "Afterparty Pass";
            description = "The Afterparty Pass is your chance to win a night of the Afterparty Experience. 10 Lucky Afterparty Pass holders will win an “Afterparty Golden Pass” which is a tradable ticket to the Afterparty event on Thursday, June 16th. The event will feature a Sushi Dinner, Open Bar, and an exclusive performance from an Afterparty community member, all taking place at the Afterparty House in Los Angeles. The Afterparty Pass will also be unlock future exclusive experiences. The earlier you get involved, the greater your chances of winning! Must be 18 years or older to qualify for the winning prize.";

            if (!apPass) {
                title = "Afterparty Pass";
                description = "The Afterparty Pass is your chance to attend an intimate, acoustic performance at the Afterparty House on June 16th. 10 lucky passholders will win a “salem ilese Golden Pass” which is a tradable ticket to the Afterparty event on Thursday, June 16th. The event will feature a Sushi Dinner, Open Bar, and an exclusive performance from salem herself, all taking place at the Afterparty House in Los Angeles. The salem ilese Golden Pass will also be able to unlock future exclusive experiences. Must be 18 years or older to qualify for the winning prize.";
                if (nftStyle == null || nftStyle == 0 || nftStyle == 1 || nftStyle == 2) {
                    title = Utils.get(st.selectedTicket, "title");
                    description = Utils.get(st.selectedTicket, "description");
                }
            } else if (nftStyle == 2) {
                title = "Afterparty Golden Pass";
                description = Utils.get(st.selectedTicket, "description");
            }
        }

        return (
            <Div className="profileComponent" style={{ ...profileComponent, ...pr.style }}>
                <BloatedCircleDefs scales={[128]} />
                {st.flashMessage ?
                    <FlashMessage
                        type="info"
                        message="Email Sent"
                        subMessage="Check your email and click the link to verify your account."
                        duration={8000}
                    />
                    :
                    null
                }

                {pr.hidePageBack || true ?
                    null
                    :
                    <Div className="afterpartyPageBack" style={sty.afterpartyPageBack} onClick={this.handleBackToEvents.bind(this)}>
                        <Div style={{ ...StylesPlatform.cursorPointer }}>
                            <Icon icon="chevron-left" size={16} style={sty.chevronIcon} />
                        </Div>
                    </Div>
                }

                {profile ?
                    <>
                        {/* {this.renderProfileHeader(profile)} */}
                        {/* <Div> */}
                        {/* {!pr.public ?
                                this.renderProfileWallets()
                                :
                                null
                            } */}
                        {/* {this.renderCarousel()}
                            this.renderUtopians(profile)}
                            {this.renderGuardians(profile)}
                            {this.renderMintPasses(profile)}
                            {this.renderTickets(profile)}
                            {this.renderProfileEvents(profile)}
                            {this.renderCollections(profile)}
                            {this.renderOtherNfts(profile)}
                            {this.renderArtistNftManagement(profile) */}
                        {/* </Div> */}
                        <Div
                            className="ticketProfileHeaderContainer"
                            style={sty.ticketProfileHeaderContainer}
                        >
                            <Div className="myWalletContainer" style={sty.myWalletContainer}>
                                {/* <Div className="profilePassTitle" style={profilePassTitle}>
                                    Wallet
                                </Div> */}

                                <Div style={{ padding: 20 }}></Div>
                                <Div className="profilePassSubtitle" style={profilePassSubtitle}>
                                    My Collection
                                </Div>

                                {pr.user.items.length ?
                                    null
                                    :
                                    <Div className="myCollectionEmptyState" style={sty.myCollectionEmptyState}>
                                        <Div style={{ fontSize: 24, paddingBottom: 15 }}>
                                            Browse Afterparty Creators to add to your Collection
                                        </Div>
                                        <Button
                                            color="pinkGradient"
                                            onClick={Utils.gotoRoute.bind(this, pr, "/p/creator_directory")}
                                            style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 40 }}
                                        >
                                            Collect & Win
                                        </Button>
                                    </Div>
                                }

                                <Div className="itemContainer" style={itemContainer}>
                                    {Utils.get(pr, "user.items", []).map((item, itemIndex) => {
                                        return (
                                            <Div
                                                className="passContainer"
                                                key={`passContainer-${itemIndex}`}
                                                style={passContainer}
                                            >
                                                <Image
                                                    style={{ ...sty.ticketPassImage, ...StylesPlatform.cursorPointer }}
                                                    className="ticketPassImage"
                                                    src={Utils.get(item, "image_url")}
                                                    onClick={this.openCollectibleModal.bind(this, item)}
                                                />
                                                {/* {Utils.get(item, "master_collection_id") == 7 ?
                                                    <Div>
                                                        <Div
                                                            className="passTitle"
                                                            style={sty.passTitle}
                                                        >
                                                            Afterparty Pass
                                                        </Div>
                                                        {this.renderPassStatus(item)}
                                                    </Div>
                                                    :
                                                    <Div>
                                                        <Div
                                                            className="passTitle"
                                                            style={sty.passTitle}
                                                        >
                                                            {false && Utils.get(item, "artist_catalog_item_id") ? Utils.get(item, "artist_title") : Utils.get(item, "title")}
                                                        </Div>
                                                        <Button
                                                            className="creatorProfileLinkTitle"
                                                            style={{fontWeight: 300, minWidth: 180, textAlign: "center"}}
                                                            type="text"
                                                            onClick={() => { Utils.gotoRoute(pr, "p/creator_profile/artist_id-" + Utils.get(item, "es_artist_id")); }}
                                                        >
                                                            {Utils.get(item, "artist_name", "")}
                                                        </Button>
                                                        <Div
                                                            className="passStatus"
                                                            style={sty.passStatus}
                                                        >
                                                            {this.renderPassStatus(item)}
                                                        </Div>

                                                    </Div>
                                                } */}
                                                {Utils.get(item, "nft_state") == 2 && Utils.get(item, "nft_style") >= 2 ?
                                                    <Div>
                                                        <Div>
                                                            <Div onClick={this.handleExpandedAvailableToggle.bind(this)} style={{ width: 220, marginTop: 10, marginBottom: 10, padding: 10, paddingTop: 12, borderRadius: 20, border: "1px solid white", display: "flex", flexDirection: "row" }}>
                                                                <Div>
                                                                    {this.state.expandedAvailable < 0 ?
                                                                        <Icon icon="plus" size={16} style={sty.chevronIcon} />
                                                                        :
                                                                        <Icon icon="minus" size={16} style={sty.chevronIcon} />
                                                                    }
                                                                </Div>
                                                                <Div>Available Passes</Div>
                                                            </Div>
                                                        </Div>
                                                        {this.state.expandedAvailable > 0 ?
                                                            <QrEmailSendButton
                                                                nft={item}
                                                                ticket={Utils.get(item, "tickets.0")}
                                                                change={this.handleChangeTickets.bind(this)}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </Div>
                                                    :
                                                    null
                                                }

                                                <Div
                                                    className="artistCatalogItem"
                                                    style={artistCatalogItem}
                                                >
                                                    {Utils.get(item, "artist_catalog_item_title", "Artist Catalog Item")}
                                                </Div>
                                                {Utils.get(item, "generation_phrase", []).length ?
                                                    <Div className="generationPhrase" style={sty.italicsCatalogItem}>
                                                        "{Utils.get(item, "generation_phrase")}"
                                                    </Div>
                                                    :
                                                    null
                                                }

                                                <Div
                                                    className="artistName"
                                                    style={{ ...sty.artistName, ...StylesPlatform.cursorPointer }}
                                                    onClick={Utils.gotoRoute.bind(this, pr, `/p/creator_profile/${Utils.get(item, "artist_name", "afterparty_artist")}/artist_id-${Utils.get(item, "artist_id")}`)}
                                                >
                                                    {Utils.get(item, "artist_title", "Artist Name")}
                                                </Div>

                                                {Utils.get(item, "nft_style") != 11 ?
                                                    <Div className="buttonPaddingContainer" style={{ paddingLeft: 16, paddingRight: 16, width: "100%" }}>
                                                        <Button
                                                            className="viewCollectibleButton"
                                                            style={sty.viewCollectibleButton}
                                                            onClick={this.openCollectibleModal.bind(this, item)}
                                                            type="outlined"
                                                            size={pr.screen.mobile ? "small" : null}
                                                        >
                                                            View Collectible
                                                        </Button>
                                                    </Div>
                                                    :
                                                    <>
                                                    <Div className="buttonPaddingContainer shoutoutItem" style={{ paddingLeft: 16, paddingRight: 16, width: "100%"}}>
                                                        <Button
                                                            className="viewShoutoutButton"
                                                            style={sty.viewCollectibleButton}
                                                            onClick={() => { Utils.gotoRoute(pr, `/share/${Utils.get(item, "crc")}`); }}
                                                            type="outlined"
                                                            size={pr.screen.mobile ? "small" : null}
                                                        >
                                                            View Shoutout
                                                        </Button>
                                                        <Button
                                                            className="viewCollectibleButton viewCollectibleShoutoutButton"
                                                            style={sty.viewCollectibleButton}
                                                            onClick={this.openCollectibleModal.bind(this, item)}
                                                            type="outlined"
                                                            size={pr.screen.mobile ? "small" : null}
                                                        >
                                                            View Collectible
                                                        </Button>
                                                    </Div>
                                                    </>
                                                    
                                                }

                                            </Div>
                                        )
                                    })}
                                </Div>

                                {pr.user.items.length && pr.user.following.length ?
                                    <Div className="profilePassSubtitle" style={profilePassSubtitle}>
                                        Following
                                    </Div>
                                    :
                                    null
                                }

                                {pr.user.items.length && pr.user.following.length ?
                                    null
                                    :
                                    <Div className="myCollectionEmptyState" style={sty.myCollectionEmptyState}>
                                        <Div style={{ fontSize: 24, paddingBottom: 15 }}>
                                            Browse Afterparty Creators to add to your Collection
                                        </Div>
                                        <Button
                                            color="pinkGradient"
                                            onClick={Utils.gotoRoute.bind(this, pr, "/p/creator_directory")}
                                            style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 40 }}
                                        >
                                            Follow & Collect
                                        </Button>
                                    </Div>
                                }

                                <Div className="itemContainer followingContainer" style={itemContainer}>
                                    {Utils.get(pr, "user.following", []).map((followObj, followIndex) => {
                                        return (
                                            <Div
                                                className="passContainer"
                                                key={`passContainer-${followIndex}`}
                                                style={passContainer}
                                            >
                                                <Image
                                                    style={sty.ticketPassImage}
                                                    className="ticketPassImage"
                                                    src={Utils.get(followObj, "artist_image_url")}
                                                />

                                                <Div
                                                    className="artistCatalogItem"
                                                    style={artistCatalogItem}
                                                >
                                                    {Utils.get(followObj, "artist_title", "Artist Name")}
                                                </Div>

                                                <Div className="buttonPaddingContainer" style={{ paddingLeft: 16, paddingRight: 16, width: "100%" }}>
                                                    <Button
                                                        className="viewCollectibleButton"
                                                        style={sty.viewCollectibleButton}
                                                        onClick={Utils.gotoRoute.bind(this, pr, `/p/creator_profile/artist_name/artist_id-${Utils.get(followObj, "artist_id")}`)}
                                                        type="outlined"
                                                        size={pr.screen.mobile ? "small" : null}
                                                    >
                                                        View Profile
                                                    </Button>
                                                </Div>
                                            </Div>
                                        )
                                    })}
                                </Div>

                                {pr.screen.mobile ?
                                    null
                                    :
                                    <Div style={{ padding: 60 }}></Div>
                                }
                            </Div>

                            {/* <Div className="" style={{paddingLeft: 20, paddingRight: 20}}>
                                <Button
                                    onClick={this.buyMore.bind(this)}
                                    color="pinkGradient"
                                    style={{width: "100%", maxWidth: 335, marginTop: 40, marginLeft: "auto", marginRight: "auto"}}
                                >
                                    Buy More
                                </Button>
                            </Div> */}
                        </Div>
                        <BorderSeparator
                            color="pinkGradient"
                        />
                        <SplashFooter
                            screen={pr.screen}
                            footerColor={Colors.indigo}
                            history={pr.history}
                        />
                    </>
                    :
                    null
                }
                {!profile && pr.userLoaded ?
                    !pr.public ?
                        <Div style={{ minHeight: 1200 }}>
                            Please sign in to see profile
                        </Div>
                        :
                        <Div>
                            Profile not found.
                        </Div>
                    :
                    null
                }

                {/* this.renderTable() */}

                {st.showRegisterModal ?
                    <ModalRegister
                        screen={pr.screen}
                        title="Edit Profile"
                        fields="username,email,phone,password"
                        isProfile
                        user={pr.user}
                        onRequestClose={this.closeRegisterModal.bind(this)}
                        getNewUserAfterRegisterSuccess={this.getNewUserAfterRegisterSuccess.bind(this)}
                    />
                    :
                    null
                }
                {st.showTicketModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeTicketModal.bind(this)}
                        className="biddingModal"
                        color="indigo"
                        fullScreen
                    >
                        <NftItem
                            screen={pr.screen}
                            user={pr.user}
                            setUser={pr.setUser.bind(this)}
                            listing={st.selectedTicket}
                            history={pr.history}
                            mobile={pr.screen.mobile}
                            inModal
                        />
                    </Modal>
                    :
                    null
                }

                {st.showCollectibleModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeCollectibleModal.bind(this)}
                        style={{ maxWidth: 480 }}
                        className="biddingModal"
                        color="indigo"
                    >
                        <Div style={{ padding: 8 }}></Div>
                        <Div
                            className="passContainer"
                            style={{ ...passContainer, ...{ width: pr.screen.mobile ? "100%" : 400 } }}
                        >
                            <Image
                                style={sty.ticketPassImage}
                                className="ticketPassImage"
                                src={Utils.get(st.selectedCollectible, "image_url")}
                            />

                            <Div
                                className="artistCatalogItem"
                                style={{ ...artistCatalogItem, ...{ textOverflow: "clip", whiteSpace: "normal", overflow: "auto" } }}
                            >
                                {Utils.get(st.selectedCollectible, "artist_catalog_item_title", "Artist Catalog Item")}
                            </Div>

                            <Div
                                className="artistName"
                                style={{ ...sty.artistName, ...StylesPlatform.cursorPointer }}
                                onClick={Utils.gotoRoute.bind(this, pr, `/p/creator_profile/${Utils.get(st.selectedCollectible, "artist_name", "afterparty_artist")}/artist_id-${Utils.get(st.selectedCollectible, "artist_id")}`)}
                            >
                                {Utils.get(st.selectedCollectible, "artist_title", "Artist Name")}
                            </Div>

                            <Div
                                className="ownedBy"
                                style={sty.ownedBy}
                            >
                                Owned By: You
                            </Div>

                            <Div
                                className="artistDescription"
                                style={sty.artistDescription}
                            >
                                {Utils.get(st.selectedCollectible, "description", "Description")}
                            </Div>

                            <Div className="buttonPaddingContainer" style={{ paddingLeft: 16, paddingRight: 16, width: "100%" }}>
                                <Button
                                    className="viewCollectibleButton"
                                    style={sty.viewCollectibleButton}
                                    onClick={Utils.gotoRoute.bind(this, pr, Utils.getCollectionOrCollectibleUrl(Utils.get(st.selectedCollectible, "artist_catalog_item_collection_id"), Utils.get(st.selectedCollectible, "artist_catalog_item_id")))}
                                    type="outlined"
                                    size={pr.screen.mobile ? "small" : null}
                                >
                                    Collect More & Earn Points
                                </Button>
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        profileComponent: {
            fontFamily: "Nyata",
            fontWeight: 400,
            width: "100%",
            // padding: 40,
            cursor: "default",
            backgroundColor: "#0F0420",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            color: "white",
            minHeight: "100vh",
            marginTop: 72,
        },
        profileComponent_md: {
            // padding: 20,
            borderTopLeftRadius: 0,
        },
        profileComponent_sm: {
            // padding: 15,
            borderTopLeftRadius: 0,
        },
        profileComponent_xs: {
            // padding: 10,
            // paddingTop: 20,
            borderTopLeftRadius: 0,
        },

        afterpartyProfile_md: {
            padding: 10,
            borderTopLeftRadius: 0,
        },
        myWalletContainer: {
            maxWidth: 1100,
            marginLeft: "auto",
            marginRight: "auto",
        },
        profileHeader: {
            //display: "flex",
            marginBottom: 20,
            marginTop: 100,
        },
        profileImageAndInfoContainer: {
            display: "flex",
        },
        profileImageAndInfoContainer_sm: {
            flexDirection: "column",
        },
        profileInfo: {
            display: "flex",
            marginTop: 10,
            flexDirection: "column",
            paddingLeft: 15,
            marginBottom: 20,
        },
        profileButtons: {
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row", //"column",
            width: "100%",
            gap: 12,
        },
        profileSectionContainer: {
            backgroundColor: Colors.purpleLight1,
            marginBottom: 30,
            padding: 20,
            borderRadius: 20,
        },
        profileSectionContainer_sm: {
            padding: 10,
            borderRadius: 10,
        },
        profileSectionHeadline: {
            fontSize: 24,
            fontWeight: "bold",
            color: "white",
            marginBottom: 20,
        },
        profileSubSectionContainer: {
            backgroundColor: Colors.purpleLight2,
            marginBottom: 30,
            padding: 20,
            borderRadius: 10,
            textAlign: "left",
        },
        chevronIcon: {
            marginRight: 10,
        },
        afterpartyPageBack: {
            marginBottom: 15,
        },
        headline: {
            fontSize: 16,
        },

        tableContainer: {
            borderRadius: 20,
            border: "1px solid white",
            padding: 20,
        },
        tableHeader: {
            display: "flex",
        },
        tableColumn: {
            flex: 1,
            padding: 10,
        },
        tableContents: {
        },
        tableRow: {
            display: "flex",
        },
        sectionHeadline: {
            fontSize: 18,
            fontWeight: "bold",
            marginTop: 22,
            marginBottom: 12,
            color: "white",
        },
        artistTilesContainer: {
            width: "100%",
            height: 200,
            position: "absolute",
            overflowX: "auto",
            overflowY: "hidden",
            display: "flex",
            background: "black",
            borderRadius: 20,
        },
        artistTile: {
            textAlign: "center",
            paddingTop: 10,
            paddingLeft: 10,
            position: "relative",
        },
        artistTileCheck: {
            width: 16,
            height: 16,
            borderRadius: 8,
            backgroundColor: "red",
            position: "absolute",
            right: 10,
            top: 16,
            color: "white",
            fontSize: 11,
        },
        artistTileImageContainerCheck: {
            overflow: "hidden",
            boxSizing: "border-box",
            border: "1px solid red",
            margin: 6,
            width: 64,
            height: 64,
            borderRadius: 64,
            backgroundColor: "orange",
        },
        artistTileImageContainer: {
            overflow: "hidden",
            margin: 6,
            width: 128,
            height: 128,
            position: "relative",
            overflow: "hidden",
            borderRadius: 12,
            //backgroundColor:"orange"
        },
        artistTileImage: {
            width: 128,
            height: 128,
            borderRadius: 12,
        },
        artistTileTitle: {
            position: "absolute",
            bottom: 13,
            left: 10,
            fontWeight: "bold",
            fontSize: 14,
        },
        connectWalletButton: {
            marginRight: 8,
        },
        connectWalletInfo: {
            backgroundColor: "black",
            borderRadius: 12,
            color: "white",
            display: "flex",
            padding: 13,
            width: "100%",
        },
        showTicketModalInnerContainer: {
            maxWidth: 415,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },

        ticketProfileHeaderContainer: {
            backgroundPosition: "center",
            backgroundSize: "cover",
            minHeight: 800,
        },
        passStatus: {
            color: "white",
            fontSize: 18,
            textAlign: "center",
        },
        passTitle: {
            backgroundColor: "rgba(238, 110, 98, 0.2)",
            width: "fit-content",
            paddingTop: 7,
            paddingBottom: 7,
            paddingLeft: 14,
            paddingRight: 14,
            borderRadius: 100,
            color: "#EE6E62",
            fontSize: 12,
            marginTop: 15,
            marginBottom: 15,
            textAlign: "center",
            minWidth: 180,
        },
        passContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 260,
            backgroundColor: Colors.indigoLight,
            borderRadius: 8,
            overflow: "hidden",
        },
        passContainer_md: {
            width: "47%",
        },
        ticketPassHeader: {
            backgroundColor: "rgba(0,0,0,0.2)",
            width: "100%",
            height: 120,
            display: "flex",
            alignItems: "center",
        },
        ticketPassHeader_md: {
            height: 72,
        },
        apLogoPassHeader: {
            width: 243,
            marginTop: 5,
            marginLeft: 30,
        },
        apLogoPassHeader_md: {
            width: 136,
            marginLeft: 20,
        },
        profilePassTitle: {
            fontSize: 56,
            textAlign: "center",
            paddingTop: 60,
            paddingBottom: 100,
        },
        profilePassTitle_md: {
            fontSize: 32,
            textAlign: "left",
            paddingLeft: 20,
            paddingTop: 30,
            paddingBottom: 30,
        },
        profilePassSubtitle: {
            fontSize: 36,
            paddingBottom: 20,
        },
        profilePassSubtitle_md: {
            fontSize: 24,
            paddingLeft: 20,
            paddingBottom: 10,
        },
        ticketPassImage: {
            width: "100%",
        },
        itemContainer: {
            display: "flex",
            gap: 20,
            flexWrap: "wrap",
            marginBottom: 40,
            alignItems: "flex-start",
        },
        itemContainer_md: {
            // gap: 10,
            // justifyContent: "center",
            padding: 20

        },
        viewCollectibleButton: {
            marginBottom: 16,
            border: "1px solid white",
            fontWeight: 300,
            width: "100%",
        },
        artistCatalogItem: {
            fontSize: 18,
            fontWeight: 600,
            color: "white",
            alignSelf: "start",
            paddingLeft: 16,
            paddingRight: 16,
            width: "100%",
            paddingTop: 16,
            paddingBottom: 8,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "auto",
        },
        artistCatalogItem_md: {
            fontSize: 16,
            fontWeight: 400,
        },
        artistName: {
            alignSelf: "start",
            color: "#918C99",
            paddingLeft: 16,
            paddingRight: 16,
            width: "100%",
            paddingTop: 2,
            paddingBottom: 10,
            fontSize: 17,
            fontWeight: 300,
            textDecoration: "underline",
        },
        ownedBy: {
            alignSelf: "start",
            color: "white",
            paddingLeft: 16,
            paddingRight: 16,
            width: "100%",
            fontSize: 17,
            fontWeight: 300,
        },
        artistDescription: {
            alignSelf: "start",
            color: "white",
            paddingLeft: 16,
            paddingRight: 16,
            width: "100%",
            paddingTop: 2,
            paddingBottom: 10,
            fontSize: 17,
            fontWeight: 300,
        },
        myCollectionEmptyState: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        italicsCatalogItem: {
            fontStyle: "italic",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            textAlign: "center",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 4,
            paddingBottom: 4,
        },
    };
}
