import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Icon from '../elements/Icon';

import Div from './Div';

export default class SocialLinks extends React.Component {
    onClickSocialMediaIcon(linkType, linkUrl) {
        const pr = this.props;
        Utils.gotoRoute(pr, linkUrl, "_blank");
    }

    renderLinkItem(item) {
        const pr = this.props;
        let sty = this.styles;
        let imgSrc = Utils.get(item, "image_url");
        let linkType = Utils.get(item, "type");
        let linkUrl = Utils.get(item, "url");

        if(linkType == "custom" || linkType == "weblink") {
            linkType = "link";
        }
        return (
            <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                <Icon
                    icon={`${linkType.toLowerCase()}-circle`}
                    size={pr.size ? pr.size : 44}
                    onClick={this.onClickSocialMediaIcon.bind(this, linkType, linkUrl)}
                />
                <Div className="socialIconText" style={sty.socialIconText}>
                    {item.title}
                </Div>
            </Div>
        );
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        let socialLinks = Utils.get(pr, "artistCustomer.social_link_json_data");

        if(Utils.empty(socialLinks) && pr.type == "fixed") {
            return (
                <Div className="noLinks">
                    No social links yet
                </Div>
            );
        }

        if(pr.type == "overflow" && !pr.screen.mobile) {
            return null;
        }

        let linkElements = [];
        for(var idx in socialLinks) {
            const socialLink = socialLinks[idx];

            if(pr.type == "fixed" && pr.screen.mobile && idx >= 5) {
                continue;
            } else if(pr.type == "overflow" && idx < 5) {
                continue;
            }

            const defaultFieldLength = Utils.get(socialLink, "defaultLength", 0);
            if(defaultFieldLength == Utils.get(socialLink, "url", "").length) {
                continue;
            }

            linkElements.push(this.renderLinkItem(socialLink));
        }


        if(pr.type == "fixed") {
            return (
                <Div className="socialMediaIconsComponentFixed" style={pr.screen.mobile ? sty.socialMediaIconsComponentFixed : sty.socialMediaIconsComponent}>
                    {linkElements}
                </Div>
            )
        }

        return (
            <Div className="socialMediaIconsComponent" style={sty.socialMediaIconsComponent}>
                {linkElements}
            </Div>
        );
    }

    styles = {
        socialMediaIconsComponent: {
            display: "flex",
            gap: 8,
            alignItems: "flex-start",
            flexWrap: "wrap",
            color: "white",
        },
        socialMediaIconsComponentFixed: {
            display: "flex",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            justifyContent: "space-evenly",
            bottom: 0,
            left: 0,
            position: "fixed",
            width: "100%",
            padding: 16,
            backgroundColor: Colors.indigoLight,
            color: "white",
        },
        socialMediaIcon: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 8,
            maxWidth: 50,
        },
        socialIconText: {
            fontSize: 10,
            textAlign: "center",
            textTransform: "uppercase",
        },
    };
}
