import React from 'react';
import Lottie from "lottie-react-web"

import Styles from '../modules/Styles';
import Utils from '../modules/Utils';
import StyleUtils from '../modules/StyleUtils';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';
import Constant from '../modules/Constant';

import Div from './Div';
import Image from './Image';
import Icon from './Icon';

import nineDotMenuImage from "../assets/images/splash/Menu.png"
import hamburgerMenuLottie from "../assets/lf30_editor_w1syhzm5.json";

export default class NineDotMenu extends React.Component {
    getMenuColor() {
        let menuColorStyle = {backgroundColor: Colors.magenta}

        switch (this.props.color) {
            case "magenta":
                menuColorStyle = {backgroundColor: Colors.magenta};
                break;
        }

        return menuColorStyle;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let menuColorStyle = this.getMenuColor();

        let dropShadow = null;
        if(pr.selected == "room") {
            dropShadow = Styles.dropShadow;
        }

        let isFixed = null;
        if(pr.isFixed) {
            isFixed = {position: "fixed"};
        }

        if(pr.size == "small") {
            return (
                <Icon
                    className="menuBars1"
                    style={{...sty.nineDotMenuImage, ...sty.smallMenu, ...pr.style, ...dropShadow, ...isFixed}}
                    onClick={pr.openSplashMenu.bind(this)}
                    icon="bars"
                    size={20}
                />
            )
        }
        return (
            <Icon
                className="menuBars2"
                style={{...sty.nineDotMenuImage, ...menuColorStyle, ...pr.style}}
                onClick={pr.openSplashMenu.bind(this)}
                icon="bars"
            />

            // <Div onClick={pr.openSplashMenu.bind(this)}>
            //     <Lottie
            //         style={sty.hamburgerMenu}
            //         height={24}
            //         width={24}
            //         // direction={st.isForward ? 1 : -1}
            //         direction={1}
            //         options={{
            //             animationData: hamburgerMenuLottie,
            //             loop: false,
            //             autoplay: true,
            //         }}
            //     />
            // </Div>
        )
    }

    styles = {
        nineDotMenuImage: {
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            color: "white",
            padding: "12px 14px",
            borderRadius: 8,
        },
        smallMenu: {
            top: 20,
            right: 20,
            padding: 0,
        },
    }
}
