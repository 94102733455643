import React, {useRef} from 'react';
import { Link } from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';

import Div from './Div';
import Image from '../elements/Image';
import Button from '../elements/Button';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Icon from '../elements/Icon';

import ConnectWallet from '../components/ConnectWallet';

import soinLogo from '../assets/soin-beige-logo.png';
import apLogo from '../assets/afterparty-logo300.png';


export default class DashboardSoinFlyout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      demo: false,
      expanded: this.props.navbar ? false : true,

      isStopped: true,
      isPaused: true,
      isForward: false,
    };

    if(Utils.getRequest('demo')) {
      this.state['demo'] = true;
    }
  }

  clickMenu(menu) {
      const pr = this.props;

      if(menu.name === "event") {
          pr.eventPageBack();
          return;
      }

      const sel = menu.name;
      pr.history.push(`/${sel}`);
      pr.change(sel);

      if(pr.navbar) {
          this.setState({expanded: false});
      }
  }

  handleLogout() {
      Api.getLogout(() => { window.location.reload(); });
  }

  isSelectedMenu(menuOption) {
      const pr = this.props;

      if(pr.selected === menuOption.name) {
          return true;
      } else if(menuOption.children && menuOption.children.indexOf(pr.selected) != -1) {
          return true;
      }

      return false;
  }

  renderMenu() {
      const pr = this.props;
      const sty = this.styles;

    const active = { background: '#FEF5FA', color: '#8980C8', fontWeight: "bold", };
    const activeColor = { color: '#EF4CA4' };
    const normalColor = { color: '#EEEEEE' };
    const activeIcon = pr.selected === 'home' ? { color: '#EF4CA4' } : { color: '#Eeeeee' };
    const activeHome = pr.selected === 'home' ? active : {color:'#484A83'};
    const demoMode = Utils.getCookie('demomode') == 1;

    let menuOptions = [
      {
        id: 1,
        name: 'home',
        title: 'Home',
        group: 'Discover',
        icon: 'home',
        enabled: false,
        env: ['site'],
        darkswitch: 1000,
        children: ['item', 'soinlisting'],
        minUserLevel: 90
      },
      {
        id: 2,
        name: 'afterparty',
        title: 'Afterparty',
        icon: 'bullhorn',
        enabled: true,
        env: ['site', 'ap'],
        // children: ['room', 'event'],
      },
      {
        id: 17,
        name: 'nft',
        title: 'Creator NFT',
        icon: 'certificate',
        enabled: true,
        env: ['site'],
      },
      {
        id: 19,
        name: 'marketplace',
        title: 'Marketplace',
        icon: 'money',
        enabled: demoMode,
        env: ['site', 'ap'],
        // children: ['room', 'event'],
      },
      {
        id: 3,
        name: 'popularcollections',
        title: 'Popular Collections',
        icon: 'money',
        group: 'Collections',
        enabled: false,
        env: ['site'],
      },
      {
        id: 4,
        name: 'bestof2021',
        title: 'Best of 2021',
        icon: 'ellipsis-h',
        group: 'Collections',
        enabled: false,
        env: ['site'],
        indent: 2,
      },
      {
        id: 5,
        name: 'mycollections',
        newtab: true,
        href: '/chorus/museum/1',
        title: 'My Collections',
        icon: 'money',
        group: 'Collections',
        enabled: false,
        env: ['site'],
      },
      {
        id: 6,
        name: 'owned',
        title: 'Owned',
        icon: 'ellipsis-h',
        enabled: false,
        env: ['site', 'ap'],
        indent: 2,
      },
      {
        id: 7,
        name: 'watchlist',
        title: 'Watchlist',
        icon: 'ellipsis-h',
        enabled: false,
        env: ['site', 'ap'],
        indent: 2,
      },
      {
        id: 8,
        name: 'seller/3/newnft/3',
        title: 'Create Afterparty NFT',
        icon: 'money',
        enabled: true,
        env: ['site', 'ap'],
        loginRequired: true,
        minUserLevel: 90,
      },
      {
        id: 9,
        name: 'profile',
        title: 'Profile',
        icon: 'user',
        enabled: true,
        env: ['site']
      },
      {
        id: 10,
        name: 'settings',
        title: 'Settings',
        icon: 'cog',
        enabled: true,
        env: ['ap']
      },
      {
        id: 11,
        name: 'tos',
        title: 'Terms of Service',
        icon: 'external-link',
        enabled: true,
        env: ['site']
      },
      {
        id: 12,
        name: 'privacypolicy',
        title: 'Privacy Policy',
        icon: 'external-link',
        enabled: true,
        env: ['site']
      },
      {
        id: 13,
        name: 'dmca',
        title: 'DMCA',
        icon: 'external-link',
        enabled: true,
        env: ['site']
      },
      {
        id: 16,
        name: 'blog',
        title: 'Blog',
        icon: 'sticky-note',
        enabled: false,
        env: ['site']
      },
    ];

    if(pr.selected === "room") {
        const event = {
            id: 22,
            name: 'event',
            title: 'Back',
            icon: 'chevron-left',
            enabled: true,
            env: ['site', 'ap'],
        };

        menuOptions.unshift(event);
    }


    const leftMenu = {
      version: 1.0,
      options: menuOptions
    };
    const env = 'site';
    const darkSwitch = pr.user ? pr.user.company_id : 0;
    let iconCol = pr.version > 5 ? "col" : "col-xl-2 col-1";
    const optionStyle =  sty.optionV6 ;
    const loggedIn = pr.user && pr.user.email;
    let group = null;

    return (
      <Div style={sty.optionList}>
        {menuOptions.map((menuOption) => {
          return menuOption.enabled &&
            menuOption.env.indexOf(env) != -1 &&
            //(!menuOption.darkswitch || (menuOption.darkswitch && darkSwitch === 1000)) &&
            (!menuOption.loginRequired || (menuOption.loginRequired && loggedIn)) &&
            Utils.get(pr.user, "ul", 0) >= Utils.get(menuOption, "minUserLevel", 0)
            ? (
              <Div style={sty.tile} key={menuOption.id}>
                { !menuOption.href ?
                    <Div
                      className='optionRow'
                      onClick={this.clickMenu.bind(this, menuOption)}
                      style={
                        this.isSelectedMenu(menuOption)
                          ? { ...optionStyle, ...active }
                          : { ...optionStyle }
                      }
                      title={menuOption.title}
                    >
                      <Div
                        style={
                        this.isSelectedMenu(menuOption)
                          ? {...sty.menuItemIcon, ...activeColor}
                          : {...sty.menuItemIcon, ...normalColor}
                        }
                        >
                        <i className={`fa fa-${menuOption.icon}`}></i>
                      </Div>
                      <Div style={this.styles.menuItemText}>
                        <Div>{menuOption.title}</Div>
                      </Div>
                    </Div>
                    :
                    <Div
                      className='optionRow'
                      style={
                        this.props.selected === menuOption.name
                          ? { ...optionStyle, ...active }
                          : { ...optionStyle }
                      }
                      title={menuOption.title}
                    >
                      <Div style={{...sty.menuItemIcon, ...activeIcon}}>
                        <i className={`fa fa-${menuOption.icon}`}></i>
                      </Div>
                      <a
                        rel='noopener noreferrer'
                        target='_blank'
                        href={menuOption.href}
                        style={sty.menuItemText}
                      >
                        {menuOption.title}
                      </a>
                    </Div>
                }

              </Div>
            ) : null;
        })}
      </Div>
    );
  }

  handleSetWallet(accounts) {
    alert("Setting default wallet to: " + accounts);
    Api.putMusicWallet({params:{user:{wallet_id: accounts[0]}}});
    this.setState({wallet: accounts});
  }

    // TODO: Refactor into menu dynamic creation
    renderUserProfile() {
        const sty = this.styles;

        const user = this.props.user;
        const notLoggedIn = !Utils.get(this.props.user, 'email');
        return (
            <Div>
              <Div style={sty.profileImageContainer} onClick={this.clickMenu.bind(this,{name:"profile"})}>
                { user["photo"] ?
                      <Image className="profileImage" style={{...sty.profileImage, ...StylesPlatform.cursorPointer}} src={user["photo"]} />
                      :
                      null
                }
              </Div>
              <Div style={{fontSize:14, textAlign:"center", paddingTop: 10, marginBottom: -6}}>
                {user["email"]}
              </Div>
              {notLoggedIn ?
                <Div>
                    <Div className="menuItem" style={{...sty.menuItem, ...{paddingRight: 32, paddingBottom: 0, paddingTop: 0}}}>
                        <Div className="menuItemText" style={sty.menuItemText}>
                            <Button color="white" size="small" style={{width: 150, marginLeft: "auto", marginRight: "auto"}} onClick={() => {this.props.history.push('/login')}}>
                                Sign In
                            </Button>
                        </Div>
                    </Div>
                    <Div className="menuItem" style={{...sty.menuItem, ...{paddingRight: 32}}}>
                        <Div className="menuItemText" style={sty.menuItemText}>
                            <Button size="small" style={{width: 150, marginLeft: "auto", marginRight: "auto"}} onClick={() => {this.props.history.push('/register')}}>
                                Register
                            </Button>
                        </Div>
                    </Div>
                </Div>
                :
                <Div className="menuItem" style={{...sty.menuItem, ...{paddingRight: 32}}}>
                    <Button onClick={this.handleLogout.bind(this)} size="small" style={{minWidth: 100}}>
                        Sign Out
                    </Button>
                </Div>
              }
          </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const privacyLink = (
            <a
                rel='noopener noreferrer'
                color='white'
                target='_blank'
                href='https://soindrop.com/privacy-policy/'
                style={{color: '#9696A9'}}
            >
                <i className="fa fa-lock" style={sty.iconGutter}></i>
                {st.expanded ? "Privacy Policy" : null}
            </a>
        );
        const termsLink = (
            <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://soindrop.com/terms-of-service/'
                style={{color: '#9696A9'}}
            >
                <i className="fa fa-pen"></i>
                {st.expanded ? "Terms & Conditions" : null}
            </a>
        );
        const homeLink = (
            <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://soindrop.com'
                style={{ color: '#9696A9' }}
            >
                <i className="fa fa-home" style={sty.iconGutter}></i>
                {st.expanded ? "Homepage" : null}
            </a>
        );
        const loginLink = (
            <a href='/webapp/login' style={{ color: '#9696A9' }}>
                Sign In
            </a>
        );
        const registerLink = (
            <a href='/webapp/register' style={{ color: '#9696A9' }}>
                Register
            </a>
        );

        const demoStyle = {}; // this.state.demo ? {filter:"blur(4px)"} : {};
        const fullName = st.demo || !pr.user ? 'John Morrisson' : pr.user.full_name;
        let iconCol = true || pr.version > 5 ? "col" : "col-xl-2 col-1";
        const textStyle = pr.version > 5 ? {textAlign:"center"} : {};
        const optionStyle = pr.version > 5 ? sty.optionV6 : sty.option;
        const hiText = 'Hi' + fullName ? ', ' + fullName : '';
        const flyoutContainer = StyleUtils.getMediaStyle('flyoutContainer', sty.flyoutContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="dashboardSoinFlyoutComponent" style={{...sty.dashboardSoinFlyoutComponent, ...pr.style}}>
                <BloatedCircleDefs scales={[96]} />
                {this.renderMenu()}
                {this.renderUserProfile()}
            </Div>
        );
    }

    styles = {
        dashboardSoinFlyoutComponent: {
            width: "100%",
        },
        headlineLight: {
            color: '#fefefe',
            fontWeight: 'bold',
            fontSize: 32,
            padding: 12,
            letterSpacing: '0.75px'
        },
        subhead: {
            width: '100%',
            fontFamily: 'Open Sans',
            fontSize: 16,
            color: '#B1B1C0'
        },
        subheadContainer: {
            padding: '6px 25% 36px 25%'
        },
        swirlTopLeft: {
            textAlign: 'left',
            marginLeft: -15,
        },
        swirlBottomRight: {
            textAlign: 'right',
            marginRight: -15,
        },
        city1: {
            textAlign: 'center'
        },
        optionList: {
            textAlign: 'left',
        },
        option: {
            display: "flex",
            flexWrap: "wrap",
            paddingTop: 16,
            paddingBottom: 16,
            color: '#9696A9',
            cursor: 'pointer',
            paddingLeft: 30,
        },
        optionV6: {
            display: "flex",
            flexWrap: "wrap",
            paddingTop: 16,
            paddingBottom: 16,
            //textAlign: "center",
            paddingLeft: 30,
            color: 'rgb(105, 114, 121)',
            cursor: 'pointer',
        },
        iconGutter: {
            marginRight: 6,
        },

        headline: {
            marginTop: 16,
            fontSize: 17,
            fontWeight: "bold",
        },
        menuItemContainer: {
            marginTop:30,
            fontSize: 12,
            margin: 0,
            borderBottom: this.props.screen.mobile ? "1px solid #999" : "none",
        },
        menuHeaderText: {
            fontWeight: "bold",
            fontSize: 12,
            marginBottom: 0,
            paddingTop: 20,
            paddingBottom: 15,
            paddingLeft: 29,
        },
        menuItem: {
            display: "flex",
            flexDirection:"row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 32,
            paddingTop: 16,
            paddingBottom: 16,
            color:"#697279",
        },
        menuItemSelected: {
            display: "flex",
            flexDirection:"row",
            paddingLeft: 32,
            paddingTop: 16,
            paddingBottom: 16,
            color:"black",
            backgroundColor: "#FBF8F4",
            fontWeight:"bold",
            fontSize: 12,
        },
        menuItemIcon: {
            flex: 1,
        },
        menuItemText: {
            flex: 6,
        },
        profileImageContainer: {
            justifyContent: "center",
            display: "flex",
        },
        profileImage: {
            width: 70,
            height: 70,
            overflow: "hidden",
            // margin: 6,
            borderRadius: 10,
            // clipPath:"url(#bloated-circle-96)",
        },
    };
}
