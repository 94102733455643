import React from 'react';

import Utils from '../modules/Utils';
import Image from '../elements/Image';

import Div from '../components/Div';

export default class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step:  Utils.getRequest("step", 1),
            steps: [
                {id:1, imageUrl: "/images/banners/Banner_Guardians_Presale.png", target:"/guardian?stage=marketing", },
                {id:2, imageUrl: "/images/banners/Banner_Guardians_Register.png", target:"/guardian?stage=sale", },
            ],
            route: Utils.get(this.props, "route", 'home'),
        };
    }

    handleClick() {
        const stepInfo = this.state.steps[this.state.step - 1];
        this.props.history.push(stepInfo['target']);
    }

    render() {
        if(this.props.route != "splash") {
            return null;
        }
        const mobile = true;
        let msg = "Guardians Go on Sale Tomorrow!";
        const step =this.state.step;
        const stepInfo = this.state.steps[step - 1];
        const imageUrl = stepInfo['imageUrl'];
        let style = {...this.styles.container, ...{background: "url('"+imageUrl+"')"}}; //{...this.styles.container,...this.props.style, ,}
        if(mobile) {
            style['width'] = 320;
            style['height'] = 100;
        }
        console.log("imageUrl", style);

        return  <Div onClick={this.handleClick.bind(this)} className="banner" style={style}>
                </Div>;
    }

    styles = {
        container: {
            position: "absolute",
            left: "50%",
            top: 20,
            transform: "translate(-50%, 0)",
            width: '100%',
            padding: 20,
            background: "#FFF9D6",
            height:360,
            color: "black",
            height: 300,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
        },
    };
}
