import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Constant from '../modules/Constant';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';
import StylesPlatform from '../modules/StylesPlatform';
import GlobalState from '../modules/GlobalState';
import Errors from '../modules/Errors';

import Div from '../elements/Div';
import DebugBox from '../elements/DebugBox';
import Dropdown from '../elements/Dropdown';
import MetaMask from '../elements/MetaMask';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Icon from '../elements/Icon';
import Span from '../elements/Span';
import Checkbox from '../elements/Checkbox';
import Carousel from '../elements/Carousel';
import Collapsible from '../elements/Collapsible';
import LoadingBar from '../elements/LoadingBar';
import TermsOfService from '../elements/TermsOfService';
import PrivacyPolicy from '../elements/PrivacyPolicy';
import WebappLoginRegister from '../elements/WebappLoginRegister';
import SplashMenu from '../elements/SplashMenu';
import FaqButton from '../elements/FaqButton';
import ErrorBox from '../elements/ErrorBox';

import ConnectWallet from './ConnectWallet';

import headerImage1 from "../assets/images/ticketflow/header image 1.jpg"
import apLogoWhiteSmall1 from "../assets/images/ticketflow/ap logo white small 1.png"
import apLogoWhite1 from "../assets/images/ticketflow/ap logo white 1.png"
import guardianTicketPass from "../assets/images/ticketflow/guardian ticket pass.png"
import gaTicketPass from "../assets/images/ticketflow/ga ticket pass.png"
import guardianGradientBackground from "../assets/images/ticketflow/gradient background.jpg"
import arrowLeft from "../assets/images/ticketflow/arrow left.png"
import paymentSuccessBadge from "../assets/images/ticketflow/payment success badge.png"
import paymentWaiting from "../assets/images/ticketflow/payment waiting.png"
import paymentLoading from "../assets/images/ticketflow/payment loading.png"
import paymentError from "../assets/images/ticketflow/payment error.png"
import mainClickPass from "../assets/images/ticketflow/main click pass.png"
import audioIcon from "../assets/images/ticketflow/audio.png"
import backCarat from "../assets/images/ticketflow/back carat.png"
import loadingNft from "../assets/images/ticketflow/loading nft.png"
import videoPoster from "../assets/images/ticketflow/video poster.png"
import errorIcon from "../assets/images/ticketflow/error icon.png"
import plusSign from "../assets/images/ticketflow/plus sign.png";
import defaultPhoto from "../assets/images/ticketflow/ga ticket pass.png";

export default class TicketFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketData: null,
            eventData: null,
            cardsDict: null,

            ticketFlowBody: "festivalInfoPage", //festivalInfoPage, phoneEmailPrompt, verifyCode, ticketInfoAndQuantity, artistSelection, paymentSummaryMethodSelect, paymentSuccess, viewNftShare
            ticketCountVip: 0,
            ticketCountGa: 0,
            paymentType: "creditCard", // creditCard, ethereum

            isLogin: true,

            ticketStage: Utils.getRequest("stage", 0),
            alreadyClaimed: Utils.getRequest("alreadyClaimed", 0),

            newCustomerId: null,
            verificationCode: "",

            learnMoreModal: false,

            vipSoldOut: false,
            gaSoldOut: false,

            learnMoreModal: false,
            termsOfServiceModal: false,
            privacyPolicyModal: false,
            mainPageInfoModal: false,
            claimingWindowModal: false,

            claimingOver: false,

            mainPageInfoModalTitle: "",
            mainPageInfoModalDescription: "",
            mainPageInfoModalName: "",

            itemSelected: 0,

            ticketFlowBodyMap: null,

            firstNameText: "",
            lastNameText: "",
            phoneNumberText: "",
            emailText: "",
            passwordText: "",
            agreeToTerms: false,

            audioOn: false,

            phoneEmailPromptIsValid: false,

            tempPercent: 0,
            nftInfo: null,

            checkMintingStatusCounter: 0,
            crc: Utils.getUrlCrc("2332521106"),

            showSplashMenu: false,

            faqs: [],

            usernameText: Utils.get(props, "user.username", ""),
            isEditUsername: false,
            editUsernameErrors: [],
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        GlobalState.subscribe("ticketStage", this.setState.bind(this));
        GlobalState.run();

        // NOTE: if this, then it's in devmode and we have to make the API call``
        if(true || window.pageInfo == "{{ pageInfo }}") {
            Api.getAfterPartyEvent(st.crc, (res) => {
                const cards = Utils.get(res, "data.post_data.json_data.cards");
                const cardsDict = Utils.arrayToObject(cards, "props.title");

                const faqs = this.generateFaqs(cardsDict);

                this.setState({
                    eventData: Utils.get(res, "data"),
                    eventStatus: Utils.get(res, "data.status"),
                    cardsDict: cardsDict,
                    faqs: faqs,
                });

                this.processEvent(Utils.get(res, "includes"));
            });
        } else {
            const data = JSON.parse(window.pageInfo);
            this.processEvent(data);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        // if(newProps.user.id) {
        //     this.handleEventStatus();
        // }
    }

    generateFaqs(cardsDict) {
        let tempFaqs = {};

        for(let idx in cardsDict) {
            if(Utils.get(cardsDict[idx], "title").substr(0, 4) === "faq_") {
                const cardTitle = Utils.get(cardsDict[idx], "title");

                const parts = cardTitle.split("_");
                const id = parseInt(parts[1]);
                const type = parts[2];

                if(!tempFaqs[id]) {
                    tempFaqs[id] = {id: id, title: "", description: "", name: Utils.urlSafeName(cardTitle)}
                }

                if(type == "q") {
                    tempFaqs[id].title = Utils.get(cardsDict[idx], "props.description");
                } else if(type == "a") {
                    tempFaqs[id].description = Utils.get(cardsDict[idx], "props.description");
                }
            }
        }

        return Object.values(tempFaqs);
    }

    goTo(path) {
        if(path == "") {
            return;
        }

        this.props.history.push(`/${path}`);
        // pr.change(path);
    }

    setNextBodyAndGo(nextBody) {
        this.nextUrlBody(nextBody);
        this.setState({
            ticketFlowBody: nextBody,
        });
    }

    handleEventStatus() {
        const pr = this.props;
        const st = this.state;

        const userIsLoggedIn = Utils.get(pr.user, "id") ? true : false;
        if(st.eventStatus === Constant.EVENT_STATUS_CLAIMING_NOT_YET_OPEN) { // 1
        } else if(st.eventStatus === Constant.EVENT_STATUS_CLAIMING_OPEN) { // 2
            if(userIsLoggedIn) {
                //this.setNextBodyAndGo("loadAfterpartyPass");
                //this.startMintingAlreadyLoggedIn();
            } else {
                this.setState({
                    claimingWindowModal: false,
                    claimingOver: false,
                });
            }
        } else if(st.eventStatus === Constant.EVENT_STATUS_CLAIMING_CLOSED) { // 3
            // NOTE: claiming is closed but winners and loser aren't revealed yet
            if(!userIsLoggedIn) {
                // this.setNextBodyAndGo("phoneEmailPrompt");
                this.setState({
                    claimingWindowModal: true,
                    claimingOver: true,
                    // isLogin: true,
                });
            }
        } else if(st.eventStatus === Constant.EVENT_STATUS_CLAIMING_REVEALED) { // 4
            // NOTE: it's revealed and you're logged in, the redirect to the profile
            if(!userIsLoggedIn) {
                // this.setNextBodyAndGo("phoneEmailPrompt");
                this.setState({
                    claimingWindowModal: false,
                    claimingOver: true,
                    // isLogin: true,
                });
            }
        }
    }

    processEvent(data) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            ticketData: data,
        });

        let tempTicketFlowBodyMap = [
            {id: "14738", name: "festivalInfoPage"},
            {id: "27484", name: "phoneEmailPrompt"},
            {id: "33466", name: "verifyCode"},
            {id: "49743", name: "loadAfterpartyPass"},
            {id: "53884", name: "viewNftShare"},
        ];

        const reducedTicketFlowBodyMap = tempTicketFlowBodyMap.map((ticket) => {
            return ticket.name;
        });

        const ticketFlowBodyToLoad = reducedTicketFlowBodyMap ? reducedTicketFlowBodyMap[st.ticketStage] : null;

        this.setState({
            ticketFlowBodyMap: reducedTicketFlowBodyMap,
            ticketFlowBody: ticketFlowBodyToLoad,
        }, () => {
            console.log("Switching to ticket stage "+st.ticketStage);
            //return;
            switch(st.ticketStage) {
                case "0":

                    break;
                case "1":
                    // if(st.claimingOver) {
                    //     this.nextUrlBody("festivalInfoPage");
                    //     this.setState({
                    //         ticketFlowBody: "festivalInfoPage",
                    //     });
                    // }
                    break;
                case "2":
                    // this.nextUrlBody("phoneEmailPrompt");
                    // this.setState({
                    //     ticketFlowBody: "phoneEmailPrompt",
                    // });
                    break;
                case "3":
                    // this.nextUrlBody("phoneEmailPrompt");
                    // this.setState({
                    //     ticketFlowBody: "phoneEmailPrompt",
                    // });
                    break;
                case "4":
                    // if(st.claimingOver || !Utils.get(pr, "user.id")) {
                    //     this.nextUrlBody("festivalInfoPage");
                    //     this.setState({
                    //         ticketFlowBody: "festivalInfoPage",
                    //     });
                    // }
                    break;
            }

            this.handleEventStatus();
        });
    }

    onLoginToggle(isLogin) {
        this.setState({
            isLogin: isLogin,
        });
    }

    toggleAudio() {
        let audioOn = !this.state.audioOn;
        let vid = document.getElementById("video1");
        vid.muted = audioOn;

        this.setState({audioOn: audioOn});
    }

    verificationCodeChange(inputNumber, e) {
        let state = this.state;

        const inputValue = e.target.value;

        state["verificationCode" + inputNumber] = inputValue;

        this.setState(state, () => {
            if(inputValue.length > 0 && inputNumber < 4) {
                $("#codeInput" + (inputNumber + 1)).focus();
            } else if(inputValue.length === 0 && inputNumber >= 1) {
                $("#codeInput" + (inputNumber - 1)).focus();
            }
        });
    }

    usernameChange(e) {
        this.setState({usernameText: e.target.value});
    }

    editUsername() {
        const pr = this.props;
        const st = this.state;

        if(st.isEditUsername) {
            if(st.usernameText != Utils.get(pr, "user.username")) {
                if(st.usernameText.length < 4) {
                    this.setState({
                        editUsernameErrors: [{id:9990, message:"User name must be at least 4 characters."}],
                    });
                    console.log(this.state.editUsernameErrors);
                    return;
                }
                this.setState({
                    editUsernameErrors: [],
                });
                Api.putProfile({params: {user: {username: st.usernameText}}}, (res) => {
                    if(Utils.get(res, "success")) {
                        pr.setUser(res);
                        this.setState({
                            isEditUsername: false,
                        });
                    } else {
                        this.setState({
                            editUsernameErrors: Utils.get(res, "errors"),
                        });
                    }
                });
            } else {
                this.setState({
                    isEditUsername: false,
                });
            }
        } else {
            this.setState({
                isEditUsername: true,
            });
        }
    }

    showLeaderboardRules() {
        this.setState({
            showLeaderboardRules: true,
        });
    }

    closeLeaderboardRules() {
        this.setState({
            showLeaderboardRules: false,
        });
    }

    viewLeaderboard() {
        this.goTo("p/yung_gravy_event_page?leaderboard=scroll");
    }

    checkMintingStatus() {
        const st = this.state;

        this.setState({
            checkMintingStatusCounter: st.checkMintingStatusCounter + 1,
        });

        if(st.checkMintingStatusCounter >= 1) {
            if(window.mintingInterval) {
                window.clearInterval(window.mintingInterval);
            }

            const nextBody = "viewNftShare";
            this.nextUrlBody(nextBody);

            this.setState({
                ticketFlowBody: nextBody,
                nftInfo: null,
            });
        }

        Api.getMintingStatus(st.mintCrc, (response) => {
            let percentDone = Utils.get(response, "data.percentDone");

            if(percentDone == 100) {
                if(window.mintingInterval) {
                    window.clearInterval(window.mintingInterval);
                }

                const nextBody = "viewNftShare";
                this.nextUrlBody(nextBody);

                Api.apiLoadProfile(pr.setUser.bind(this));

                this.setState({
                    ticketFlowBody: nextBody,
                    nftInfo: Utils.get(response, "data.nftInfo"),
                });
            } else {
                this.setState({
                    mintingPercent: percentDone,
                });
            }
        });
    }

    startMintingStatusInterval() {
        const st = this.state;

        if(window.mintingInterval) {
            window.clearInterval(window.mintingInterval);
        }

        this.setState({
            checkMintingStatusCounter: 0,
        });
        this.checkMintingStatus();
        window.mintingInterval = setInterval(this.checkMintingStatus.bind(this), 5000);
    }

    onConfirmVerificationCodeSignUp(response) {
        const pr = this.props;
        const st = this.state;

        if(Utils.get(response, "success") == 1) {
            if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_OPEN) {
                const mintCrc = Utils.get(response, "data.mint_crc");
                this.setNextBodyAndGo("loadAfterpartyPass");

                this.setState({
                    mintCrc: mintCrc,
                    usernameText: Utils.get(pr, "user.username"),
                }, () => {
                    this.startMintingStatusInterval();
                });
            } else if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_CLOSED) {
                this.goTo("mywallet");
            } else if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_REVEALED) {
                this.goTo("mywallet");
            }
        }
    }

    onConfirmVerificationCodeLogin(response) {
        const pr = this.props;
        const st = this.state;

        if(Utils.get(response, "success") == 1) {
            this.setState({
                usernameText: Utils.get(pr, "user.username"),
            });

            if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_OPEN) {
                this.setNextBodyAndGo("loadAfterpartyPass");
                this.startMintingAlreadyLoggedIn();
            } else if(st.isLogin) {
                if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_CLOSED) {
                    this.goTo("mywallet");
                } else if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_REVEALED) {
                    this.goTo("mywallet");
                }
            }
        }
    }

    toggleAgreeToTerms() {
        this.setState({agreeToTerms: !this.state.agreeToTerms});
    }

    plusMinusTickets(ticketType, amount) {
        let st = this.state;

        // NOTE: if ticketType is sold out
        if((ticketType === "vip" && st.vipSoldOut) ||
           (ticketType === "ga" && st.gaSoldOut)) {
            return;
        }

        if(ticketType === "vip") {
            st.ticketCountVip = st.ticketCountVip + amount;
            if(st.ticketCountVip < 0) {
                st.ticketCountVip = 0;
            }
        } else if(ticketType === "ga") {
            st.ticketCountGa = st.ticketCountGa + amount;
            if(st.ticketCountGa < 0) {
                st.ticketCountGa = 0;
            }
        }

        this.setState(st);
    }

    selectPaymentType(paymentType) {
        this.setState({paymentType: paymentType});
    }

    getTicketTitle(ticketType) {
        switch(ticketType) {
            case "vip":
                return "VIP TICKET";
            case "ga":
                return "GA TICKET";
        };
    }

    ticketBackButton() {
        const pr = this.props;
        const st = this.state;

        // let guardianTicketFlowStage = Utils.getRequest("stage", 0);
        let guardianTicketFlowStage = st.ticketStage;
        guardianTicketFlowStage--;

        if(guardianTicketFlowStage === -1) {
            return;
        }
        const baseUrl = window.location.pathname.replace("/webapp", "");
        let extraParams = Api.getReqParams(true, {stage: guardianTicketFlowStage});
        pr.history.push(`${baseUrl}${extraParams}`);

        const ticketFlowBodyToLoad = st.ticketFlowBodyMap[guardianTicketFlowStage];

        this.setState({
            ticketFlowBody: ticketFlowBodyToLoad,
            ticketStage: guardianTicketFlowStage,
        });
    }

    handleViewPassClick() {
        const pr = this.props;
        const st = this.state;

        this.goTo("mywallet");
    }

    nextUrlBody(nextBody) {
        const pr = this.props;
        const st = this.state;

        const nextBodyIndex = st.ticketFlowBodyMap.indexOf(nextBody);
        this.setState({
            ticketStage: nextBodyIndex,
        });
        const baseUrl = window.location.pathname.replace("/webapp", "");
        let extraParams = Api.getReqParams(true, {stage: nextBodyIndex});
        pr.history.push(`${baseUrl}${extraParams}`);
    }

    ticketBodyChange(currentBody, nextBody) {
        const pr = this.props;
        const st = this.state;

        if(!st.claimingOver) {
            this.nextUrlBody(nextBody);
        }
        const userIsLoggedIn = Utils.get(pr.user, "id") ? true : false;

        switch(currentBody) {
            case "festivalInfoPage":
                if(st.claimingOver) {
                    this.setState({
                        claimingWindowModal: true,
                    });
                } else {
                    if(userIsLoggedIn) {
                        this.setNextBodyAndGo("loadAfterpartyPass");
                        this.startMintingAlreadyLoggedIn();
                    } else {
                        this.setState({
                            ticketFlowBody: nextBody,
                        });
                    }
                }
                break;
            case "phoneEmailPrompt":

                this.setState({
                    ticketFlowBody: nextBody,
                });
                break;
            case "verifyCode":

                if(nextBody === "ticketInfoAndQuantity") {

                }

                if(nextBody === "loadAfterpartyPass") {

                }

                this.setState({
                    // ticketFlowBody: "ticketInfoAndQuantity",
                    ticketFlowBody: nextBody,
                });
                break;
            case "ticketInfoAndQuantity":

                this.setState({
                    // ticketFlowBody: "artistSelection",
                    ticketFlowBody: nextBody,
                });
                break;
            case "artistSelection":

                this.setState({
                    // ticketFlowBody: "paymentSummaryMethodSelect",
                    ticketFlowBody: nextBody,
                });
                break;
            case "paymentSummaryMethodSelect":

                this.setState({
                    // ticketFlowBody: "paymentSuccess",
                    ticketFlowBody: nextBody,
                });
                break;
            case "paymentSuccess":

                this.setState({
                    // ticketFlowBody: "viewNftShare",
                    ticketFlowBody: nextBody,
                });
                break;
        }
    }

    onRequestLearnMoreModalClose() {
        this.setState({learnMoreModal: false});
    }

    onRequestTermsOfServiceClose() {
        this.setState({termsOfServiceModal: false});
    }

    onRequestPrivacyPolicyClose() {
        this.setState({privacyPolicyModal: false});
    }

    onClaimingWindowModalClose() {
        this.setState({claimingWindowModal: false});
    }

    learnMore(ticketType) {
        const st = this.state;

        switch(ticketType) {
            case "vip":
                if(st.vipSoldOut) {
                    Utils.newTab("https://google.com");
                } else {
                    this.setState({learnMoreModal: true});
                }
                break;
            case "ga":
                if(st.gaSoldOut) {
                    Utils.newTab("https://google.com");
                } else {
                    this.setState({learnMoreModal: true});
                }
                break;
        }
    }

    showTermsOfServiceModal() {
        this.setState({termsOfServiceModal: true});
    }

    getNextPath() {
        return this.props.history.location.pathname.substring(1);
    }

    onClickSignInHere() {
        const pathname = this.getNextPath();

        this.onClaimingWindowModalClose();
        this.goTo(`login?next=${pathname}`);
    }

    showSplashMenu() {
        const pr = this.props;

        if(Utils.get(pr, "user.id")) {
            this.setState({
                showSplashMenu: true,
            });
        }
    }

    closeSplashMenu() {
        this.setState({
            showSplashMenu: false,
        });
    }

    renderPassTile(showClickRegion) {
        const st = this.state;
        const sty = this.styles;

        let artistAnimationUrl = Utils.get(st.cardsDict, "level_0_animation_url.props.description");
        let artistImageUrl = Utils.get(st.cardsDict, "level_0_image_url.props.description");

        return (
            <Div className="nftAnimation" style={sty.nftAnimation}>
                <video
                    className="333"
                    src={artistAnimationUrl}
                    autoPlay
                    muted
                    playsInline
                    poster={artistImageUrl}
                    // controls
                    style={{width: "100%",}}
                    loop
                ></video>
            </Div>
        )
    }

    renderPassHeader() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let audioOpacity = 0.5;

        if(st.ticketFlowBody === "phoneEmailPrompt" ||
            st.ticketFlowBody === "verifyCode" ||
            st.ticketFlowBody === "loadAfterpartyPass"
        ) {
            audioOpacity = 0;
        } else if(st.audioOn) {
            audioOpacity = 1;
        }

        audioOpacity = 1;

        let backButtonOpacity = {opacity: st.ticketFlowBody === "festivalInfoPage" ? 0 : 1};

        let userPhotoStyle = Utils.get(pr.user, "photo") ? {borderRadius: 100, border: `2px solid ${Colors.magenta}`, opacity: 1, height: 40, width: 40} : null;

        let ticketFlowHeaderImage = apLogoWhiteSmall1;

        if(Utils.get(pr.user, "email")) {
            ticketFlowHeaderImage = Utils.get(pr.user, "photo", defaultPhoto);
        }

        return (
            <Div className="backButtonAndMenuContainer" style={sty.backButtonAndMenuContainer}>
                <Div className="festivalInfoBackButton" style={sty.festivalInfoBackButton}>
                    {st.ticketFlowBody === "phoneEmailPrompt" ?
                        <Image
                            className="ticketBackButton"
                            style={{...sty.ticketBackButton, ...backButtonOpacity, ...StylesPlatform.cursorPointer}}
                            onClick={this.ticketBackButton.bind(this)}
                            src={backCarat}
                        />
                        :
                        null
                    }

                </Div>

                <Image
                    className="afterpartyLogoTop"
                    style={{maxWidth: 124, width: "100%"}}
                    src={apLogoWhite1}
                />

                <Image
                    className="audioIcon"
                    style={{...sty.audioStyle, ...{opacity: 0.5/*audioOpacity*/}, ...userPhotoStyle, ...StylesPlatform.cursorPointer}}
                    // onClick={this.toggleAudio.bind(this)}
                    // src={audioIcon}
                    src={ticketFlowHeaderImage}
                    onClick={this.showSplashMenu.bind(this)}
                />

                {st.showSplashMenu ?
                    <SplashMenu
                        setUser={pr.setUser}
                        selected={pr.selected}
                        screen={pr.screen}
                        history={pr.history}
                        user={pr.user}
                        change={pr.change.bind(this)}
                        closeSplashMenu={this.closeSplashMenu.bind(this)}
                    />
                    :
                    null
                }

            </Div>
        )
    }

    renderPassTitle() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="passTitleContainer" style={sty.passTitleContainer}>
                <Div className="passSubtitleText" style={sty.passSubtitleText}>
                    {Utils.get(st.cardsDict, "heading_subtitle.props.description")}
                </Div>
                <Div className="passTitleText" style={sty.passTitleText}>
                    {Utils.get(st.cardsDict, "heading_title.props.description")}
                </Div>
            </Div>
        )
    }

    renderEventStatusMessageAndButton() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let isClaimPass = false;
        const userIsLoggedIn = Utils.get(pr, "user.id") ? true : false;

        let buttonPath = `login?next=${this.getNextPath()}`;
        let buttonText = "Sign In / Register";

        if(userIsLoggedIn) {
            buttonPath = "mywallet";
            buttonText = "Go to My Wallet";
        }

        let claimingMessage = "";
        switch (st.eventStatus) {
            case Constant.EVENT_STATUS_CLAIMING_NOT_YET_OPEN: // NOTE: 1
                claimingMessage = Utils.get(st.cardsDict, "event_status_1_message.props.description", "Claiming not yet open");
                break;
            case Constant.EVENT_STATUS_CLAIMING_OPEN: // NOTE: 2
                claimingMessage = Utils.get(st.cardsDict, "event_status_2_message.props.description", "");

                buttonPath = "";
                buttonText = Utils.get(st.cardsDict, "event_status_2_claim_button.props.description", "Click to Claim Pass");
                isClaimPass = true;
                break;
            case Constant.EVENT_STATUS_CLAIMING_CLOSED: // NOTE: 3
                claimingMessage = Utils.get(st.cardsDict, "event_status_3_message.props.description", "Claiming period has closed, check back later for Reveal");
                break;
            case Constant.EVENT_STATUS_CLAIMING_REVEALED: // NOTE: 4
                if(userIsLoggedIn) {
                    claimingMessage = Utils.get(st.cardsDict, "event_status_4_message_loggedin.props.description", "Reveal is now live!  If you entered, check your wallet below to see if you won");
                } else {
                    claimingMessage = Utils.get(st.cardsDict, "event_status_4_message_loggedout.props.description", "This claim is now closed, sign up below to stay tuned for upcoming experiences!");
                }
                break;
        }

        return (
            <Div className="eventStatusMessageContainer" style={{textAlign: "center", marginTop: 0,}}>
                {claimingMessage.length ?
                    <Div style={{marginBottom: 20}}>
                        {claimingMessage}
                    </Div>
                    :
                    null
                }

                {isClaimPass ?
                    <Button
                        className="eventStatusMessageButton"
                        style={sty.eventStatusMessageButton}
                        color="pinkGradient"
                        onClick={this.ticketBodyChange.bind(this, "festivalInfoPage", "phoneEmailPrompt")}
                    >
                        {buttonText}
                    </Button>
                    :
                    <Button
                        className="eventStatusMessageButton"
                        style={sty.eventStatusMessageButton}
                        color="pinkGradient"
                        onClick={this.goTo.bind(this, buttonPath)}
                    >
                        {buttonText}
                    </Button>
                }
            </Div>
        )
    }

    renderFestivalInfoPage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const videoFestivalInfoStyle = {width: "100%"};
        let overlayBackgroundImageMobile = pr.screen.mobile ? {backgroundImage: `url("${Utils.get(st.ticketData, "steps.0.data.background_video_overlay_url_mobile")}")`} : null;
        let rectangleOverlayResponsiveStyle = {maxWidth: pr.screen.mobile ? "100%" : 540}

        const mintPassInfo = (
            <>
                {this.renderPassHeader()}
                {this.renderPassTitle()}
                {this.renderPassTile(true)}
                {this.renderEventStatusMessageAndButton()}
                <Div className="bottomGradient" style={sty.bottomGradient}></Div>
            </>
        );

        const festivalInfoPage = (
            <Div className="festivalInfoSection" style={sty.festivalInfoSection}>
                <Div className="festivalInfoSectionTitle" style={sty.festivalInfoSectionTitle}>
                    {Utils.get(st.cardsDict, "description_title.props.description")}
                </Div>
                <br />
                <Div className="festivalInfoSectionSubtitle" style={sty.festivalInfoSectionSubtitle}>
                    {Utils.get(st.cardsDict, "description_body.props.description")}
                </Div>
                <Div className="faqContainer" style={{marginTop:20, display:"flex", flexDirection:"column", gap:20}}>
                    {st.faqs.map((faq, faqIndex) => {
                        return (
                            <FaqButton
                                key={`faq-${faqIndex}`}
                                screen={pr.screen}
                                title={faq.title}
                                description={faq.description}
                                name={faq.name}
                            />
                        );
                    })}
                </Div>
            </Div>
        )
        let mainImageContainerStyle = Object.assign({}, sty.mainImageContainer);

        if(!pr.screen.mobile) {
            mainImageContainerStyle.height = 580;
        }

        return (
            <>
                <Div className="mainImageContainer" style={mainImageContainerStyle}>
                    {pr.screen.mobile ?
                        <video
                            className="mainBackgroundVideoMobile"
                            src={Utils.get(st.cardsDict, "background_video_mobile.props.description", null)}
                            autoPlay
                            muted
                            playsInline
                            poster={Utils.get(st.cardsDict, "background_image_mobile.props.description", videoPoster)}
                            // controls
                            style={videoFestivalInfoStyle}
                            loop
                        ></video>
                        :
                        null
                    }

                    {pr.screen.mobile ?
                        <Div className="rectangleOverlay" style={{...sty.rectangleOverlayInfo, ...overlayBackgroundImageMobile, ...rectangleOverlayResponsiveStyle}}>
                            {mintPassInfo}
                        </Div>
                        :
                        mintPassInfo
                    }
                </Div>
                {festivalInfoPage}
            </>
        )
    }

    startMintingAlreadyLoggedIn() {
        const pr = this.props;
        const st = this.state;

        const verifyCodeData = {
            new_crc: true,
            nextStep: "mint_" + st.crc,
        };

        Api.postVerifyCode(verifyCodeData, (response) => {
            if(Utils.get(response, "success")) {
                const mintCrc = Utils.get(response, "data.mint_crc");
                const errorDict = Utils.arrayToObject(Utils.get(response, "errors"), "id");
                const alreadyClaimed = 394726 in errorDict ? true : false;

                const baseUrl = window.location.pathname.replace("/webapp", "");
                let extraParams = Api.getReqParams(true, {alreadyClaimed: alreadyClaimed ? 1 : 0});
                pr.history.push(`${baseUrl}${extraParams}`);

                this.setState({
                    mintCrc: mintCrc,
                    alreadyClaimed: alreadyClaimed,
                }, () => {
                    this.startMintingStatusInterval();
                });
            }
        })
    }

    onLoginPhone(response) {}

    onLoginEmail(response) {
        const pr = this.props;
        const st = this.state;

        if(Utils.get(response, "success") == 1) {
            this.setState({
                usernameText: Utils.get(pr, "user.username"),
            });

            if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_OPEN) {
                this.setNextBodyAndGo("loadAfterpartyPass");
                this.startMintingAlreadyLoggedIn();
            } else if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_CLOSED) {
                this.goTo("mywallet");
            } else if(st.eventStatus == Constant.EVENT_STATUS_CLAIMING_REVEALED) {
                this.goTo("mywallet");
            }
        }
    }

    onSignUpPhone(response) {
        if(Utils.get(response, "success")) {
            this.setState({
                newCustomerId: Utils.get(response, "data.id"),
                usernameText: Utils.get(this.props, "user.username"),
            });
        }
    }

    renderPhoneEmailPrompt() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        // TODO: Make it load name from event
        const saleName = st.crc == "2332521106" ? "sale1" : "sale2";
        const showLogin = st.eventStatus == Constant.EVENT_STATUS_CLAIMING_OPEN ? false : true;

        return (
            <WebappLoginRegister
                isLogin={showLogin}
                screen={pr.screen}
                history={pr.history}
                user={pr.user}
                setUser={pr.setUser.bind(this)}
                onLoginToggle={this.onLoginToggle.bind(this)}
                showTermsOfServiceModal={this.showTermsOfServiceModal.bind(this)}
                loginFormData={{
                    registerFlow: saleName,
                }}
                signUpFormData={{
                    registerFlow: saleName,
                }}
                verifyCodeData={{
                    nextStep: "mint_" + st.crc, // st.isLogin ? null : "mint_"+saleName,
                }}
                onLoginPhone={this.onLoginPhone.bind(this)}
                onLoginEmail={this.onLoginEmail.bind(this)}
                onSignUpPhone={this.onSignUpPhone.bind(this)}
                onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLogin.bind(this)}
                onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUp.bind(this)}
            />
        );
    }

    renderClaimingWindowOver() {
        return (
            <Div
                className=""
                style={{}}
            >
                <Div
                    style={{fontSize: 32, textAlign: "center", marginTop: 170, marginBottom: 20}}
                    className=""
                >
                    Claiming Window Is Over
                </Div>
                <Div
                    style={{textAlign: "center", marginBottom: 40}}
                    className=""
                >
                    If you entered the contest, you'll be notified when the reveal happens. Good luck!
                </Div>
                <Button
                    type="outlined"
                    style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}
                    onClick={this.onClaimingWindowModalClose.bind(this)}
                >
                    Okay
                </Button>
                <Div
                    style={{textAlign: "center", marginTop: 30, display: "flex", justifyContent: "center"}}
                    className=""
                >
                    Already claimed?&nbsp;
                    <Button
                        type="text"
                        onClick={this.onClickSignInHere.bind(this)}
                    >
                        Sign in here
                    </Button>
                </Div>
            </Div>
        )
    }

    renderPlusMinusTicketButtons(ticketType) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let soldOutStyle = null;

        if(ticketType === "vip" && st.vipSoldOut) {
            soldOutStyle = {backgroundColor: Colors.gray2};
        } else if(ticketType === "ga" && st.gaSoldOut) {
            soldOutStyle = {backgroundColor: Colors.gray2};
        }

        return (
            <Div
                style={sty.plusMinusButtonRow}
                className="plusMinusButtonRow"
            >
                <Div
                    style={sty.plusMinusButton}
                    className="plusMinusButton"
                    onClick={this.plusMinusTickets.bind(this, ticketType, -1)}
                >
                    -
                </Div>
                <Div>
                    {ticketType === "vip" ?
                        st.ticketCountVip
                        :
                        st.ticketCountGa
                    }
                </Div>
                <Div
                    style={{...sty.plusMinusButton, ...soldOutStyle}}
                    className="plusMinusButton"
                    onClick={this.plusMinusTickets.bind(this, ticketType, 1)}
                >
                    +
                </Div>

            </Div>
        )
    }

    renderTicketInfoAndQuantity() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{padding: 25}}>
                <Image
                    className="apLogoWhite1"
                    src={apLogoWhite1}
                    style={sty.apLogoWhite1}
                />
                <Div className="ticketFlowTitle" style={sty.ticketFlowTitle}>
                    NFT Art & Music Festival
                </Div>

                <Div className="infoContainer" style={sty.infoContainer}>
                    <Div className="iconContainer" style={sty.iconContainer}>
                        <Image
                            className="guardianTicketIcon"
                            style={sty.guardianTicketIcon}
                            src={apLogoWhiteSmall1}
                        />
                    </Div>
                    <Div className="textInfoContainer" style={sty.textInfoContainer}>
                        <Div className="textInfoMain" style={sty.textInfoMain}>
                            October 29 + 30, 2022
                        </Div>
                        <Div className="textInfoSmall" style={sty.textInfoSmall}>
                            6:00PM - 2:00AM
                        </Div>
                    </Div>
                </Div>

                <Div className="infoContainer" style={sty.infoContainer}>
                    <Div className="iconContainer" style={sty.iconContainer}>
                        <Image
                            className="guardianTicketIcon"
                            style={sty.guardianTicketIcon}
                            src={apLogoWhiteSmall1}
                        />
                    </Div>
                    <Div className="textInfoContainer" style={sty.textInfoContainer}>
                        <Div className="textInfoMain" style={sty.textInfoMain}>
                            Banc of California
                        </Div>
                        <Div className="textInfoSmall" style={sty.textInfoSmall}>
                            Downtown Los Angeles
                        </Div>
                    </Div>
                </Div>

                <Div className="ticketContainer" style={sty.ticketContainer}>
                    <Image
                        style={sty.ticketImage}
                        className="ticketImage"
                        src={guardianTicketPass}
                    />
                    <Div>
                        <Div>GUARDIAN VIP TICKET</Div>
                        <Div style={{marginBottom: 12}}>
                            2022 VIP pass + yearly first look access to LA festivals
                        </Div>
                        <Div className="ticketFooter" style={sty.ticketFooter}>
                            <Div style={{marginRight: 12}}>
                                {st.vipSoldOut ? "Sold out" : "$499"}
                            </Div>
                            <Button
                                size="small"
                                onClick={this.learnMore.bind(this, "vip")}
                                color="red"
                            >
                                {st.vipSoldOut ? "View on secondary" : "Learn more"}
                            </Button>
                        </Div>
                    </Div>

                </Div>

                {this.renderPlusMinusTicketButtons("vip")}

                <Div className="ticketContainer" style={sty.ticketContainer}>
                    <Image
                        style={sty.ticketImage}
                        className="ticketImage"
                        src={gaTicketPass}
                    />
                    <Div>
                        <Div>GA FESTIVAL TICKET</Div>
                        <Div style={{marginBottom: 12}}>
                            2022 LA Festival General Admission + option for artist-specific ticket design
                        </Div>
                        <Div className="ticketFooter" style={sty.ticketFooter}>
                            <Div style={{marginRight: 12}}>
                                {st.gaSoldOut ? "Sold out" : "$299"}
                            </Div>
                            <Button
                                size="small"
                                onClick={this.learnMore.bind(this, "ga")}
                                color="red"
                            >
                                {st.gaSoldOut ? "View on secondary" : "Learn more"}
                            </Button>
                        </Div>
                    </Div>

                </Div>

                {this.renderPlusMinusTicketButtons("ga")}

                {st.ticketCountVip > 0 || st.ticketCountGa > 0 ?
                    <Button
                        onClick={this.ticketBodyChange.bind(this, "ticketInfoAndQuantity", "artistSelection")}
                        color="red"
                    >
                        Next Page
                    </Button>
                    :
                    null
                }
            </Div>
        )
    }

    selectCarouselItem(item, itemPosition) {
        this.setState({
            itemSelected: itemPosition,
            // itemSelected: item.id,
        });
    }

    renderArtistSelection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const carouselItems = [];
        // const userItems = pr.user.items;
        const userItems = [
            {
                artist_name: "The Weeknd",
            },
            {
                artist_name: "Drake",
            },
            {
                artist_name: "Future",
            },
            {
                artist_name: "Justin Bieber",
            },
            {
                artist_name: "Chris Jackson",
            },
            {
                artist_name: "Aries",
            },
            {
                artist_name: "Brakence",
            },
            {
                artist_name: "Corbin",
            },
            {
                artist_name: "XYLO",
            },
        ];

        for (var i = 0; i < userItems.length; i++) {
            let userItem = userItems[i];
            let userItemImage = {
                borderRadius: 500,
                height: 100,
                width: 100,
                // backgroundImage: `url(${userItem.artist_image_url})`,
                backgroundImage: `url("https://pbs.twimg.com/profile_images/1478367769187926016/0QSuatcL_400x400.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginBottom: 15,
            };

            carouselItems.push(
                <Div
                    key={`${i}-myItem`}
                    className={`carouselItem id-${userItem.id}`}
                    style={{...sty.carouselItemContainer, ...StylesPlatform.cursorPointer}}
                    onClick={this.selectCarouselItem.bind(this, userItem, i)}
                >
                    <Div
                        style={userItemImage}
                    >
                        {i === st.itemSelected ?
                        // {userItem.id === st.itemSelected ?
                            <Div className="selectedCheck" style={sty.selectedCheck}>
                                √
                            </Div>
                            :
                            null
                        }
                    </Div>
                    <Div className="artistName" style={sty.artistName}>
                        {userItem.artist_name}
                    </Div>
                </Div>
            );
        }

        return (
            <Div style={{padding: 25}}>
                <Div>
                    <Div>Select Artist</Div>
                    <Div>Skip</Div>
                </Div>
                <Div>
                    Selecting an artist is an optional add-on to any Afterparty ticket. If selected, your Afterparty Festival Ticket will feature a creative asset hand-picked by the artist, and will enable lasting benefits through this artist.
                    <br /><br />
                    Please note: this program is in pilot, and we are testing with select artists. Not all Festival performers will have a custom ticket asset available. Selections are final, and to recieve another artist's ticket, a secondary market purchase or trade will be required.
                </Div>
                <Div>
                    <Div>Ticket 1: GUARDIAN VIP TICKET</Div>
                    <Carousel
                        screen={pr.screen}
                        // items={pr.user.items}
                        // imagePath="artist_image_url"
                        size={100}
                    >
                        {carouselItems}
                    </Carousel>
                </Div>

                <Div style={{...sty.bottomOverlayWithButton, ...{marginLeft: -25}}}>
                    <Button
                        style={{width: "100%"}}
                        onClick={this.ticketBodyChange.bind(this, "artistSelection", "paymentSummaryMethodSelect")}
                        color="red"
                    >
                        <Div className="buttonWithArrowContainer" style={sty.buttonWithArrowContainer}>
                            NEXT PAGE
                            <Div className="circleArrowButton" style={sty.circleArrowButton}>
                                =≥
                            </Div>
                        </Div>
                    </Button>
                </Div>
            </Div>
        )
    }

    renderPaymentSummaryMethodSelect() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let tickets = [
            {
                type: "vip",
                artist: "Young Thug",
                quantity: 1,
                price: 499,
                image_url: guardianTicketPass,
            },
            {
                type: "ga",
                artist: "Justin Beiber",
                quantity: 2,
                price: 299,
                image_url: guardianTicketPass,
            },
            {
                type: "ga",
                artist: "Elohim",
                quantity: 1,
                price: 299,
                image_url: guardianTicketPass,
            },
        ];

        let subtotal = 0;
        let tax = 0 || 235;

        return (
            <Div style={{padding: 25}}>
                <Div>Summary</Div>

                <Div>
                    {tickets.map((ticket, ticketIndex) => {
                        let ticketTitle = this.getTicketTitle(ticket.type);
                        subtotal += ticket.price;

                        return (
                            <Div className="ticketRow" style={sty.ticketRow}>
                                <Image
                                    className="ticketRowImage"
                                    style={sty.ticketRowImage}
                                    src={ticket.image_url}
                                />
                                <Div>
                                    <Div>
                                        {ticketTitle}
                                    </Div>
                                    <Div>
                                        Artist: {ticket.artist}
                                    </Div>
                                    <Div>
                                        {ticket.quantity} x ${ticket.price}
                                    </Div>
                                </Div>

                            </Div>
                        )
                    })}
                </Div>

                <Div className="costRow" style={sty.costRow}>
                    <Div>Subtotal</Div>
                    <Div>${subtotal}.00</Div>
                </Div>
                <Div className="costRow" style={sty.costRow}>
                    <Div>Tax</Div>
                    <Div>${tax}.00</Div>
                </Div>
                <Div className="costRow" style={sty.costRow}>
                    <Div>Total</Div>
                    <Div>${subtotal + tax}.00</Div>
                </Div>

                <Div style={{...sty.bottomOverlayWithButton, ...{marginLeft: -25}}}>
                    <Div className="" style={{textAlign: "center", marginBottom: 15}}>
                        Pay with
                    </Div>

                    <Div className="paymentMethodButtonContainer" style={sty.paymentMethodButtonContainer}>
                        <Button
                            className="paymentButton"
                            style={(st.paymentType === "creditCard" ? sty.paymentButtonSelected : sty.paymentButton)}
                            onClick={this.selectPaymentType.bind(this, "creditCard")}
                        >
                            <Div>Credit Card</Div>
                            <Div>$X.XXX</Div>
                        </Button>
                        <Button
                            className="paymentButton"
                            style={(st.paymentType === "ethereum" ? sty.paymentButtonSelected : sty.paymentButton)}
                            onClick={this.selectPaymentType.bind(this, "ethereum")}
                        >
                            <Div>Ethereum</Div>
                            <Div>.XXX ETH</Div>
                        </Button>
                    </Div>

                    <Button style={{width: "100%"}} color="red" onClick={this.ticketBodyChange.bind(this, "paymentSummaryMethodSelect", "paymentSuccess")}>
                        <Div className="buttonWithArrowContainer" style={sty.buttonWithArrowContainer}>
                            CHECKOUT
                            <Div className="circleArrowButton" style={sty.circleArrowButton}>
                                =≥
                            </Div>
                        </Div>
                    </Button>
                </Div>

            </Div>
        )
    }

    getTicketStatus(ticket) {
        switch (ticket.status) {
            case "loading":
                return "Please wait while your ticket is generated";
            case "waiting":
                return "Your ticket is in the queue";
            case "finished":
                return "Your ticket was successfully generated";
        }
    }

    getPaymentStatusIcon(ticket) {
        switch (ticket.status) {
            case "loading":
                return paymentLoading;
            case "waiting":
                return paymentWaiting;
            case "finished":
                return paymentSuccessBadge;
            case "error":
                return paymentError;
        }
    }

    renderLoadAfterpartyPass() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="loadAfterpartyPassContainer"
                style={{padding: 30}}
            >
                {this.renderPassTitle()}
                {this.renderPassTile(false)}
                <Div
                    className="pleaseWait"
                    style={{textAlign: "center", marginTop: 30}}
                >
                    {Utils.get(st.cardsDict, "loading_screen_message.props.description", "Please wait while we load your Afterparty Pass")}
                </Div>
            </Div>
        )
    }

    renderPaymentSuccess() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let tickets = [
            {
                type: "vip",
                artist: "Young Thug",
                quantity: 1,
                price: 499,
                image_url: guardianTicketPass,
                status: "finished",
            },
            {
                type: "ga",
                artist: "Justin Beiber",
                quantity: 2,
                price: 299,
                image_url: guardianTicketPass,
                status: "loading",
            },
            {
                type: "ga",
                artist: "Elohim",
                quantity: 1,
                price: 299,
                image_url: guardianTicketPass,
                status: "waiting",
            },
        ];

        return (
            <Div style={{padding: 25}}>
                <Div
                    className="paymentSuccessBadgeContainer"
                    style={sty.paymentSuccessBadgeContainer}
                >
                    <Image
                        className="paymentSuccessBadge"
                        src={paymentSuccessBadge}
                        style={sty.paymentSuccessBadge}
                    />
                    <Div>
                        <Div>Payment received</Div>
                        <Div>
                            Successful Payment for:<br/>
                            1 VIP Guardian & 2 GA Tickets
                        </Div>
                    </Div>
                </Div>

                <Div>
                    {tickets.map((ticket, ticketIndex) => {
                        let ticketTitle = this.getTicketTitle(ticket.type);

                        return (
                            <Div className="ticketRow" style={sty.ticketRow}>
                                <Div
                                    className=""
                                    style={{position: "relative"}}
                                >
                                    <Image
                                        src={this.getPaymentStatusIcon(ticket)}
                                        style={sty.paymentStateIcon}
                                    />

                                    <Image
                                        className="ticketRowImage"
                                        style={sty.ticketRowImage}
                                        src={ticket.image_url}
                                    />
                                </Div>
                                <Div>
                                    <Div>
                                        {ticketTitle}
                                    </Div>
                                    <Div>
                                        Artist: {ticket.artist}
                                    </Div>
                                    <Div>
                                        {this.getTicketStatus(ticket)}
                                    </Div>
                                </Div>

                            </Div>
                        )
                    })}
                </Div>

                <Button
                    onClick={this.ticketBodyChange.bind(this, "paymentSuccess", "viewNftShare")}
                    color="red"
                    style={{width: "100%"}}
                >
                    NEXT
                </Button>
            </Div>
        )
    }

    renderViewNftShare() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let overlayBackgroundImageMobile = pr.screen.mobile ? {backgroundImage: `url("${Utils.get(st.ticketData, "steps.0.data.background_video_overlay_url_mobile")}")`} : null;
        let artistImageUrl = Utils.get(st.cardsDict, "level_0_image_url.props.description");
        let artistAnimationUrl = Utils.get(st.cardsDict, "level_0_animation_url.props.description");
        let rectangleOverlayResponsiveStyle = {
            maxWidth: pr.screen.mobile ? "100%" : 540,
            position: "fixed",
            overflowY: "scroll",
        }

        const viewNftSharePage = (
            <Div
                className="viewNftShare"
                style={sty.viewNftSharePage}
            >
                {this.renderPassHeader()}
                {this.renderPassTitle()}

                {st.alreadyClaimed == 1 ?
                    <Div className="alreadyClaimedText" style={{fontSize: 28, fontWeight: 500}}>
                        You've already claimed!
                        {/* <br /> */}
                        {/* Click below to view in your Wallet */}
                    </Div>
                    :
                    <Div className="youClaimedText" style={{fontSize: 28, fontWeight: 500}}>
                        {Utils.get(st.cardsDict, "congrats_line_1.props.description", "You've claimed an")}
                        {/* <br /> */}
                        {/* {Utils.get(st.cardsDict, "congrats_line_2.props.description", "Afterparty Pass")} */}
                    </Div>
                }

                {Utils.get(st.cardsDict, "share_to_win_enabled.props.description", false) ?
                    <>
                        <Div
                            className="shareToWinDescription"
                            style={sty.shareToWinDescription}
                        >
                            {Utils.get(st.cardsDict, "share_to_win_description.props.description", "Share to Win")}
                        </Div>
                        <Input
                            inputType="placeholderLabel"
                            placeholder="Shareable Link"
                            value={Utils.get(st.cardsDict, "share_to_win_unique_link.props.description")}
                            placeholderLabelStyle={{marginTop: 20, marginBottom: 10, maxWidth: 350, marginLeft: "auto", marginRight: "auto"}}
                            readOnly
                        />

                        <Button
                            className="leaderboardRulesButton"
                            style={sty.leaderboardRulesButton}
                            type="text"
                            onClick={this.showLeaderboardRules.bind(this)}
                        >
                            Leaderboard rules
                        </Button>
                        <Div
                            className="shareToWinUsernameText"
                            style={sty.shareToWinUsernameText}
                        >
                            {Utils.get(st.cardsDict, "share_to_win_username_text.props.description", "Afterparty Pass")}
                        </Div>
                        <Div
                            className="editUsernameContainer"
                            style={sty.editUsernameContainer}
                        >
                            {st.isEditUsername ?
                                <Input
                                    inputType="placeholderLabel"
                                    value={st.usernameText}
                                    placeholder="Username"
                                    onChange={this.usernameChange.bind(this)}
                                />
                                :
                                <Div
                                    className="usernameTextStyle"
                                    style={sty.usernameTextStyle}
                                >
                                    {st.usernameText}
                                </Div>
                            }

                            <Button
                                style={{minWidth: 60, marginLeft: 20, marginBottom: 10}}
                                onClick={this.editUsername.bind(this)}
                                size="small"
                            >
                                {st.isEditUsername ? "Save" : "Edit"}
                            </Button>
                        </Div>
                        <ErrorBox
                            errorMessages={st.editUsernameErrors}
                        />
                        <Button
                            style={{marginLeft: "auto", marginRight: "auto", marginTop: 10}}
                            size="small"
                            onClick={this.viewLeaderboard.bind(this)}
                        >
                            {Utils.get(st.cardsDict, "share_to_win_view_leaderboard_button.props.description", "View Leaderboard")}
                        </Button>
                        <Div style={{padding: 20}}></Div>
                    </>
                    :
                    null
                }

                {st.alreadyClaimed == 1 ?
                    <Div className="alreadyClaimedText2" style={{fontSize: 24, fontWeight: 500}}>
                        {/* You've already claimed! */}
                        {/* <br /> */}
                        Click below to view in your Wallet
                    </Div>
                    :
                    <Div className="youClaimedText2" style={{fontSize: 24, fontWeight: 500}}>
                        {/* {Utils.get(st.cardsDict, "congrats_line_1.props.description", "You've claimed an")} */}
                        {/* <br /> */}
                        {Utils.get(st.cardsDict, "congrats_line_2.props.description", "Afterparty Pass")}
                    </Div>
                }

                <Div className="revealDateText" style={{fontSize: 14, fontWeight: 400, marginTop: 10,}}>
                    {Utils.get(st.cardsDict, "reveal_date.props.description")}
                </Div>

                <Div className="nftAnimation" style={sty.nftAnimation}>
                    <video
                        className="222"
                        src={artistAnimationUrl}
                        autoPlay
                        muted
                        playsInline
                        poster={artistImageUrl}
                        // controls
                        style={{width: "100%",}}
                        loop
                    ></video>
                </Div>

                <Div className="afterpartyPassText" style={{marginBottom: 50}}>
                    {Utils.get(st.cardsDict, "listing_title.props.description", "Afterparty Pass")} {st.nftInfo ? `#${Utils.get(st.nftInfo, "token_id")}` : ""}
                </Div>
                <Button
                    color="pinkGradient"
                    style={sty.viewAfterpartyPassButton}
                    onClick={this.handleViewPassClick.bind(this)}
                >
                    {Utils.get(st.cardsDict, "view_button_text.props.description", "View Button")}
                </Button>
                <Div style={{padding: 20}}></Div>
            </Div>
        )

        return (
            <Div className="mainImageContainer" style={null}>
                {pr.screen.mobile ?
                    <video
                        className="444"
                        // id="video1"
                        // src={Utils.get(st.ticketData, "steps.0.data.background_video_url_mobile")}
                        src={Utils.get(st.cardsDict, "background_video_mobile.props.description", null)}
                        autoPlay
                        muted
                        playsInline
                        poster={Utils.get(st.cardsDict, "background_image_mobile.props.description", videoPoster)}
                        style={sty.videoShareNftStyle}
                        loop
                    ></video>
                    :
                    null
                }

                {pr.screen.mobile ?
                    <Div className="rectangleOverlayMobile" style={{...sty.rectangleOverlayShare, ...overlayBackgroundImageMobile, ...rectangleOverlayResponsiveStyle}}>
                        {viewNftSharePage}
                    </Div>
                    :
                    viewNftSharePage
                }
            </Div>

        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // let mainPageInfoModalDescriptionArray = st.mainPageInfoModalDescription.split("\n");
        let overlayBackgroundImage = !pr.screen.mobile ? {backgroundImage: `url("${Utils.get(st.ticketData, "steps.0.data.background_video_overlay_url")}")`} : null;

        const leaderboardRulesHtml = Utils.get(st.ticketData, "docs.leaderboard_rules.body", "Leaderboard Rules");

        const portraitContents = (
            <Div className="guardianTicketFlowComponent" style={sty.guardianTicketFlowComponent}>
                <DebugBox
                    show={this.state}
                />

                {st.ticketFlowBody == "festivalInfoPage" ? this.renderFestivalInfoPage() : null}
                {st.ticketFlowBody == "viewNftShare" ? this.renderViewNftShare() : null}

                {st.ticketFlowBody == "festivalInfoPage" ||
                st.ticketFlowBody == "viewNftShare" ?
                    null
                    :
                    this.renderPassHeader()
                }
                <Div className="mainBodyContainer" style={sty.mainBodyContainer}>
                    {st.ticketFlowBody == "phoneEmailPrompt" ? this.renderPhoneEmailPrompt() : null}
                    {st.ticketFlowBody == "ticketInfoAndQuantity" ? this.renderTicketInfoAndQuantity() : null}
                    {st.ticketFlowBody == "artistSelection" ? this.renderArtistSelection() : null}
                    {st.ticketFlowBody == "paymentSummaryMethodSelect" ? this.renderPaymentSummaryMethodSelect() : null}
                    {st.ticketFlowBody == "loadAfterpartyPass" ? this.renderLoadAfterpartyPass() : null}
                    {/* st.ticketFlowBody == "paymentSuccess" ? this.renderPaymentSuccess() : null */}
                </Div>

                {st.learnMoreModal ?
                    <Modal
                        onRequestClose={this.onRequestLearnMoreModalClose.bind(this)}
                        className="learnMoreModal"
                        color="black"
                        screen={pr.screen}
                    >
                        <Image
                            src={guardianTicketPass}
                            style={{height: 123, width: 123}}
                        />

                        Afterparty Guardians Collection

                        First Look Access to every
                        Afterparty LA Festival

                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus finibus vel nulla et posuere. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.
                    </Modal>
                    :
                    null
                }

                {st.termsOfServiceModal ?
                    <Modal
                        onRequestClose={this.onRequestTermsOfServiceClose.bind(this)}
                        className="termsOfServiceModal"
                        color="black"
                        screen={pr.screen}
                        style={{maxHeight:"80%", overflowY:"scroll",}}
                    >
                        <TermsOfService />
                    </Modal>
                    :
                    null
                }

                {st.privacyPolicyModal ?
                    <Modal
                        onRequestClose={this.onRequestPrivacyPolicyClose.bind(this)}
                        className="privacyPolicyModal"
                        color="black"
                        screen={pr.screen}
                    >
                        <PrivacyPolicy />
                    </Modal>
                    :
                    null
                }

                {st.claimingWindowModal ?
                    <Modal
                        onRequestClose={this.onClaimingWindowModalClose.bind(this)}
                        className="claimingWindowModal"
                        color="indigo"
                        screen={pr.screen}
                        fullScreen
                    >
                        <Image
                            className="afterpartyLogoTop"
                            style={{width: 124, marginRight: "auto", marginTop: -25}}
                            onClick={this.ticketBackButton.bind(this)}
                            src={apLogoWhite1}
                        />
                        <Div
                            className="modalTitle"
                            style={sty.modalTitle}
                        >
                            {st.mainPageInfoModalTitle}
                        </Div>
                        <br />
                        {this.renderClaimingWindowOver()}
                    </Modal>
                    :
                    null
                }

                {st.showLeaderboardRules ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeLeaderboardRules.bind(this)}
                        className="leaderboardRulesModal"
                        color="purple"
                        title="Leaderboard Rules"
                    >
                        <Div
                            style={{width: "100%"}}
                            dangerouslySetInnerHTML={{ __html: leaderboardRulesHtml }}
                        >
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        );

        if(pr.screen.mobile) {
            return portraitContents;
        } else {
            return (
                <Div className="desktopContainer" style={sty.desktopContainer}>
                    <Div
                        className="mainContainer"
                        style={{...sty.mainContainer, ...{backgroundImage: `url("${Utils.get(st.ticketData, "steps.0.data.background_image_url")}")`}}}
                    >
                        {!Utils.getRequest("novideo") ?
                            <video
                                className="mainBackgroundVideoDesktop"
                                src={Utils.get(st.cardsDict, "background_video.props.description", null)}
                                autoPlay
                                muted
                                playsInline
                                poster={Utils.get(st.cardsDict, "background_image.props.description", videoPoster)}
                                style={{width: "100%", objectFit: "cover", width: "calc(100vw - 540px)", height: "100vh", position: "fixed", top: 0, left: 0}}
                                loop
                            ></video>
                            :
                            null
                        }
                        <Div
                            className="overlayBackgroundContainer"
                            style={{...sty.overlayBackgroundContainer, ...overlayBackgroundImage}}
                        ></Div>
                    </Div>
                    <Div className="portraitContainer" style={sty.portraitContainer}>
                        {portraitContents}
                    </Div>
                </Div>
            )
        }
    }

    styles = {
        guardianTicketFlowComponent: {
            color: "white",
            backgroundColor: Colors.indigo,
            minHeight: "100vh",
            fontFamily: "Nyata",
            fontWeight: 300,
        },
        desktopContainer: {
            display: "flex",
            width: "100%",
        },
        mainContainer: {
            width: "100%",
            position: "relative",
            backgroundColor: Colors.eggplant,
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        portraitContainer: {
            flexShrink: 0,
            width: 540,
            backgroundColor: Colors.indigo,
        },

        mainImageContainer: {
            // backgroundImage: `url("${headerImage1}")`,
            // backgroundPosition: "center",
            // backgroundSize: "cover",
            // minHeight: 600,
            height: 650,
            //height: 500,
            overflow: "hidden",
        },
        mainImageContainer_xs: {
            height: 650,
            overflow: "hidden",
        },

        clickPassContainer: {
            position: "relative",
        },
        clickPassImage: {
            display: "flex",
            width: 244,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 300,
            opacity: 1,
        },
        clickPassSpecificImage: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 128,
            height: 128,
            borderRadius: 1000,
        },
        clickPassSpecificImageOverlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 128,
            height: 128,
            borderRadius: 1000,
            backgroundColor: "rgba(15,4,32,0.3)",
            textAlign: "center",
            fontSize: 12,
        },
        rectangleOverlayInfo: {
            display: "flex",
            flexDirection: "column",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            width: "100%",
            height: 650,
        },
        rectangleOverlayShare: {
            display: "flex",
            flexDirection: "column",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
        },
        videoShareNftStyle: {
            width: "100%",
            objectFit: "cover",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
        },
        overlayBackgroundContainer: {
            display: "flex",
            flexDirection: "column",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
        },
        viewAfterpartyPassButton: {
            maxWidth: 256,
            width: "100%",
            marginTop: 10,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 30,
        },
        bottomGradient: {
            height: 30,
            width: "100%",
            background: "linear-gradient(rgba(15, 4, 32, 0), rgba(15, 4, 32, 0.5), rgb(15, 4, 32))",
            marginTop: "auto",
        },

        festivalInfoSectionTitle: {
            fontFamily: "Nyata",
            fontWeight: 300,
            fontSize: 18,
        },
        festivalInfoSectionSubtitle: {
            fontFamily: "Nyata",
            fontWeight: 500,
            fontSize: 16,
            color: Colors.gray,
            fontWeight: 300,
        },

        backButtonAndMenuContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingTop: 25,
            marginBottom: 25,
        },
        ticketBackButton: {
            width: 9,
            padding: 10,
        },
        festivalInfoBackButton: {
            display: "flex",
            alignItems: "center",
            fontSize: 24,
        },
        apLogoWhiteSmall1: {
            width: "100%",
        },
        apLogoWhite1: {
            width: 118,
        },
        passTitleContainer: {
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: -20,
        },
        passTitleText: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 48,
            marginTop: 5,
            marginBottom: 25,
        },
        passSubtitleText: {
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 340,
            marginLeft: "auto",
            marginRight: "auto",
        },
        ticketFlowTitle: {
            fontSize: 45,
            marginBottom: 20,
        },
        infoContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
        },
        mainBodyContainer: {
            // backgroundImage: `url("${guardianGradientBackground}")`,
            backgroundSize: "cover",
            // height: "calc(100vh - 220px)",
            // minHeight: "100vh",
            // overflowY: "scroll",
            backgroundPosition: "top",
        },
        textInfoContainer: {

        },
        textInfoMain: {
            fontSize: 16,
            marginBottom: 5,
        },
        textInfoSmall: {
            fontSize: 12,
        },
        iconContainer: {
            width: 48,
            height: 48,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 14,
        },
        guardianTicketIcon: {
            width: 25,
        },
        modalTitle: {
            fontFamily: "Nyata",
            fontWeight: 500,
            fontSize: 32,
            marginRight: "auto",
            marginTop: 50,
        },
        modalDescription: {
            fontFamily: "Nyata",
            fontWeight: 300,
            color: Colors.gray,
        },
        logoButtonContainer: {
            width: 40,
            height: 40,
            padding: 8,
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            backdropFilter: "blur(16px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        festivalInfoSection: {
            zIndex: 1000,
            padding: 20,
        },
        bottomOverlayWithButton: {
            position: "fixed",
            background: "linear-gradient(rgba(0,0,0,0), #0E0E0E)",
            padding: 15,
            paddingTop: 100,
            bottom: 0,
            width: "100%",
        },
        inputStyle: {
            marginBottom: 10,
        },
        inputLabel: {
            marginBottom: 15,
            fontSize: 18,
        },
        inputCodeStyle: {
            border: "none",
            color: "white",
            backgroundColor: "black",
            borderRadius: 12,
            width: 50,
            marginRight: 20,
        },
        agreeToTermsContainer: {
            display: "flex",
            marginTop: 20,
            marginLeft: 20,
            marginBottom: 30,
        },
        ticketContainer: {
            display: "flex",
            backgroundColor: Colors.offBlack,
            borderRadius: 15,
            // paddingTop: 22,
            // paddingLeft: 17,
            // paddingRight: 17,
            padding: 20,
            marginBottom: 5,
        },
        ticketImage: {
            height: 80,
            width: 80,
            marginRight: 18,
        },
        ticketFooter: {
            display: "flex",
            alignItems: "center",
        },
        plusMinusButtonRow: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: Colors.offBlack,
            borderRadius: 150,
            padding: 10,
            marginBottom: 10,
        },
        plusMinusButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 30,
            width: 30,
            backgroundColor: Colors.red,
            borderRadius: 100,
        },
        buttonWithArrowContainer: {
            display: "flex",
            alignItems: "center",
            width: "65%",
            marginLeft: "auto",
        },
        circleArrowButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
            width: 30,
            backgroundColor: "#A5422F",
            borderRadius: 100,
            marginLeft: "auto",
        },
        costRow: {
            display: "flex",
            justifyContent: "space-between",
        },
        ticketRow: {
            display: "flex",
            backgroundColor: Colors.offBlack,
            marginBottom: 10,
            padding: 16,
            borderRadius: 15,
        },
        ticketRowImage: {
            height: 66,
            width: 66,
            marginRight: 22,
            borderRadius: 300,
        },
        paymentMethodButtonContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 20,
        },
        paymentButton: {
            borderRadius: 15,
            flexDirection: "column",
            borderColor: "white",
            borderWidth: 2,
            borderStyle: "solid",
            backgroundColor: "none",
            background: "none",
        },
        paymentButtonSelected: {
            borderRadius: 15,
            flexDirection: "column",
            backgroundColor: Colors.red,
            borderWidth: 0,
        },
        paymentSuccessBadgeContainer: {
            display: "flex",
            marginBottom: 20,
        },
        paymentSuccessBadge: {
            height: 54,
            width: 54,
            marginRight: 23,
        },
        paymentStateIcon: {
            position: "absolute",
            width: 66,
            height: 66,
            padding: 13,
            boxSizing: "border-box",
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: 100,
            border: "1px solid white",
        },
        carouselItemContainer: {
            marginLeft: 10,
            marginRight: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        selectedCheck: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 25,
            width: 25,
            backgroundColor: Colors.magenta,
            borderRadius: 40,
            marginLeft: "auto",
        },
        audioStyle: {
            // maxWidth: 124,
            // width: 16,
            // height: 14,
            height: 20,
        },
        errorMessageRowContainer: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            width: 250,
        },
        errorMessageRow: {
            color: Colors.red,
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
        },
        viewNftSharePage: {
            padding: 25,
            paddingTop: 0,
            minHeight: "calc(100vh - 180px)",
            textAlign: "center",
        },
        orSeparator: {
            fontSize: 32,
            fontWeight: 500,
            textAlign: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        toggleLoginButton: {
            display: "flex",
            justifyContent: "center",
            marginTop: 13,
            marginBottom: 15,
            textAlign: "center",
        },
        nftAnimation: {
            width: 200,
            height: 200,
            backgroundColor: "white",
            borderRadius:20,
            overflow:"hidden",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 20,
            filter: "drop-shadow(0 0 0.75rem black)",
        },
        eventStatusMessageButton: {
            marginLeft: "auto",
            marginRight: "auto",
        },

        shareToWinDescription: {
            marginTop: 25,
            fontSize: 20,
            maxWidth: 280,
            marginLeft: "auto",
            marginRight: "auto",
        },
        leaderboardRulesButton: {
            marginTop: 20,
            marginBottom: 20,
            fontWeight: 400,
        },
        shareToWinUsernameText: {
            fontSize: 16,
            maxWidth: 280,
            marginLeft: "auto",
            marginRight: "auto",
        },
        editUsernameContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
        },
        usernameTextStyle: {
            fontSize: 20,
            marginBottom: 10,
        },
    };
}
