import React from 'react'

import Utils from '../modules/Utils'
import Api from '../modules/Api'
import Styles from '../modules/Styles'
import Errors from '../modules/Errors'

import Modal from '../elements/Modal'
import Button from '../elements/Button'
import Icon from '../elements/Icon'

import Div from './Div'

export default class BuySoin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div>
                BuySoin Component
            </Div>
        )
    }
}
