import React from 'react';


export default class BloatedCircleDefs extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const scales = this.props.scales ? this.props.scales : [100];
        window.myRef = this.myRef;
        let defs = '';
        for(let idx in scales) {
            const scale = scales[idx];
            const scaleX = (scale * 0.58) / 100.00;
            const scaleY = (scale * 0.58) / 100.00;
            const itemSel = "bloated-circle-"+scale;
            defs += '<clipPath id="'+itemSel+'" transform="scale('+scaleX+' '+scaleY+')"> <path fill="#FFFFFF" stroke="#000000" stroke-width="1.5794" stroke-miterlimit="10" d="m 171.97917,87.3125 c 0,66.14583 -23.8125,85.98958 -85.989614,85.98958 C 23.8125,173.30208 -4.6380939e-6,150.8125 -3.2466666e-5,87.3125 -6.0295238e-5,23.8125 21.166667,1.322914 85.989556,1.322914 c 64.822944,0 85.989614,19.843753 85.989614,85.989586 z"></path> </clipPath>';
        }
        let def = document.createElement("div");
        def.setAttribute("id", 'bloatedCircleDefs');
        const svgDef = '<svg height="0" width="0"> <defs> '+defs+' </defs> </svg>';
        def.innerHTML = svgDef;
        this.myRef.current.appendChild(def);
    }

    render() {
        return <div id="bc-canvas" ref={this.myRef} style={this.styles.bloatedCircleTest}>
            </div>;
    }

    styles = {
        bloatedCircleTest: {
            height: 0,
        }
    }
}
