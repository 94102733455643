import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';
import Spinner from '../elements/Spinner';
import ImageBckgrnd from '../elements/ImageBckgrnd';
import FlashMessage from '../elements/FlashMessage';
import StripePayment from '../elements/StripePayment';

import Div from './Div';
import Login from './Login';
import ConnectWallet from './ConnectWallet';
import WalletTable from './WalletTable';
import ProfileMenu from './ProfileMenu';
import RegisterFlow from './RegisterFlow';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';

import afterpartyLogoNew from '../assets/afterparty-logotype.png';

export default class AfterpartyEvent extends React.Component {
    constructor(props) {
        super(props);
        this.isIrlEvent = Utils.get(props.eventItem, "event_type") === Constant.EVENT_TYPE_IRL;
        this.isCompRegisterEvent = Utils.get(props.eventItem, "event_type", 1) === Constant.EVENT_TYPE_COMP_CODE || Utils.get(props.eventItem, "event_type", 1) === Constant.EVENT_TYPE_COMP_CODE_UTOPIAN;

        this.state = {
            value: "",

            isRsvpSuccess: false,

            showRegisterFlowModal: this.isIrlEvent || this.isCompRegisterEvent ? true : false,
            usernameText: "",
            emailText: "",
            agreeToTerms: false,
            passwordText: "",
            confirmPasswordText: "",
            // rsvpModalBody: "username", // username, email, verifyEmail, password, success, login
            registerModalBody: "username",

            emailLoginText:"",
            passwordLoginText: "",
            errorMessages: [],
            rsvpModalMessage: "",

            walletAccounts: null,

            bitCloutAddress: null,
            registeredSuccess: false,
            showRsvpNftModal: false,
            isEventTime: false,
            eventMinutesLeft: null,
            eventSecondsLeft: null,

            letAllIn: false,
            stripeInfo: false,
        };

        this.countdownInterval = null;
        window.setInterval(() => {
           this.setState({letAllIn:window.letAllIn});
        }, 5000);

        //this.isIrlEvent = isIrlEvent;
        if(this.isIrlEvent) {
            this.state.registerModalBody = "irlForm";
        } else if(this.isCompRegisterEvent) {
            this.state.registerModalBody = "compRegisterForm";
        }

        if(Utils.get(props.eventItem, "event_type") === 2) {
            Api.getStripeIntent((o) => {
                console.log("getStripeIntent", o);
                this.setState({stripeInfo:o.data});
            });
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const eventSecondsLeft = parseInt(Utils.get(nextProps.eventItem, "GoLive.secondsLeft"));
        const eventMinutesLeft = Utils.get(nextProps.eventItem, "GoLive.minutesLeft");
        this.setState({
            eventSecondsLeft: eventSecondsLeft,
            eventMinutesLeft: eventMinutesLeft,
        }, () => {
            if(!this.countdownInterval) {
                this.countdownInterval = window.setInterval(this.updateEventSeconds.bind(this), 1000);
            }
        });
    }

    updateEventSeconds() {
        const st = this.state;

        this.setState({
            eventSecondsLeft: st.eventSecondsLeft - 1,
            eventMinutesLeft: st.eventMinutesLeft,
        }, () => {
            if(st.eventSecondsLeft <= 0) {
                window.clearInterval(this.countdownInterval);
                this.countdownInterval = undefined;
            }

            if(st.eventSecondsLeft % 60 === 0) {
                this.setState({eventMinutesLeft: st.eventMinutesLeft - 1});
            }
        });
    }

    backToEvents() {
        const pr = this.props;
        pr.history.push('/home');
        pr.change('home');
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        const pr = this.props;

        let data = null;
        if(!accounts && bitCloutPublicKey) {
            data = {params:{user:{bitclout_public_key:bitCloutPublicKey}}};
        } else {
            data = {params:{user:{wallet_id:accounts[0]}}};
        }

        Api.putMusicWallet(data, (res) => {
          if(res.errors.length) {
              ll._("ERROR wallet did not save", "red");
              return;
          }

          let updatedUser = res.data.user;
          pr.setUser(updatedUser);

          this.onRequestRegisterModalClose();

          this.setState({walletConnectedFlashMessage: true});
      });
    }

    handleGotoProfile() {
        const pr = this.props;
        pr.history.push('/profile');
        pr.change('profile');
    }

    handleRoomClick() {
        const pr = this.props;

        const override = (this.props.eventItem['override_code'] && Utils.getRequest('guest') == this.props.eventItem['override_code']);
        let suffix = '';
        if(override) {
            suffix = "?guest=" + this.props.eventItem['override_code'];
        }
        const event = this.props.eventItem;
        this.setState({
            selectedEventItem: event, // TODO "selectedEventItem" is never used, but leave it
        }, () => {
            pr.history.push('/room/'+event['room']['name']+'-'+event['room']['crc']+suffix);
            pr.change('room', event['room']);
        });
    }

    handleRsvpClick() {
        const data = {id: this.props.eventItem['id']};
        Api.postRsvp(data, (response) => {
            if(response == null) {
                return;
            }
            if(Utils.get(response, "success")) {
                this.setState({
                    showRsvpNftModal: true,
                    isRsvpSuccess: true,
                });
            } else {
                this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    handleListingClick(listing) {
        const bitcloutUsername = Utils.get(listing, "bitclout_username", false);
        if(bitcloutUsername) {
            const listingUrl = `https://diamondapp.com/u/${bitcloutUsername}?feedTab=Global`;
            window.open(listingUrl, '_blank');
        }

        return;

      if(listing['json'] && listing['json'].indexOf('permalink') != -1) {
          let data = JSON.parse(listing['json']);
          window.open( data['permalink'], "_blank");
          return;
      }

      listing["thumbnail"] = listing["cover_big"];
      this.setState({
              selectedEventItem: listing, // TODO "selectedEventItem" is never used, but leave it
      }, () => {
        this.props.history.push('/soin/'+listing["name"]+"-"+listing["crc"])
        this.props.change('item', listing);
      });
    }

    handleUpcomingEventsLink() {
        let sel = 'afterparty';
        this.props.history.push('/'+sel)
        this.props.change(sel);
    }

  closeRsvpNftModal() {
      this.setState({showRsvpNftModal: false});
      window.location.reload();
  }

  onRequestRegisterModalClose() {
      this.setState({
        showRegisterFlowModal: false,
        registerModalBody: "username",
        errorMessages: [],
        rsvpModalMessage: "",
      });
  }

  onRequestRegisterModalOpen(modalBody = "username") {
      this.setState({
          showRegisterFlowModal: true,
          registerModalBody: modalBody,
      });
  }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "login"
        });
    }

    getWalletTypes() {
        const event = this.props.eventItem;
        const listings = Utils.get(event, "listings", []);

        let walletTypes = [];
        for(var idx in listings) {
            const listing = listings[idx];
            const bcKey = Utils.get(listing, 'bitclout_public_key', "");
            if(bcKey.length > 0) { // if it's a bitclout wallet, add bitclout
                walletTypes.push("bitclout");
            } else {  // if it's a metamask wallet, add bitclout
                walletTypes.push("metamask");
            }
        }

        return walletTypes;
    }

    resendEmail() {
        const data = {
            email: st.emailText,
            validate_step: "email_verify",
            format: "json",
        };

        Api.postRegister(data, (response) => {
            if(response == null) {
                return;
            }
            if(Utils.get(response, "success")) {
                this.setState({rsvpModalMessage: `An email has been sent to ${data.email}`});
            } else {
                this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    setRegisteredSuccess(isSuccessful) {
        this.setState({
            registeredSuccess: isSuccessful,
        });
    }

    renderFeedColumn() {
        // Check if event has a feed
        const listings = Utils.get(this.props, "eventItem.listings", []);
        if(listings.length == 0) {
            return null;
        }
        //const feeds = Utils.pluck(listings, "bitclout_feed");
        // Loop through the maximum posts (20) and interweave postings of each feed
        let posts = [];
        for(var idx=0;idx<20;idx++) {
            for(var idxListing in listings) {
                var listing = listings[idxListing];
                if(listing['bitclout_feed'] && listing['bitclout_feed'][idx]) {
                    if(listing['bitclout_feed'][idx]['Body']) {
                        const info = {
                            body:listing['bitclout_feed'][idx]['Body'],
                            username:listing['bitclout_username'],
                            image_url:listing['image_url'],
                            post_image: Utils.get(listing['bitclout_feed'][idx], 'ImageURLs.0'),
                        };
                        posts.push(info);
                    }
                }
            }
        }
        ll._("Listings", "blue", listings);
        let out = [];
        for(var idx in posts) {
            out.push(<Div key={`feedrow-${idx}`} style={this.styles.feedRow}>
                        <Div>
                            <Image style={{borderRadius:8, width:48,height:48,marginRight:12}} src={posts[idx]['image_url']} />
                        </Div>
                        <Div>
                            <Div style={this.styles.feedUsername}>{posts[idx]['username']}</Div>
                            <Div>{posts[idx]['body']}</Div>
                            { posts[idx]['post_image'] && posts[idx]['post_image'] != "https://i.imgur.com/XzIatAR.jpg" ?
                                <Image style={{maxWidth:200,maxHeight:200,margin:6,borderRadius:10}} src={posts[idx]['post_image']} />
                                :
                                null
                            }
                        </Div>

                    </Div>);
        }
        return out.length > 0 ?
            <Div style={this.styles.feedContainer}>
                <Div style={this.styles.feedRow}>
                    <Image style={{width:120}} src="/images/bitclout_logo_white.svg" />
                </Div>
                <Div>
                    {out}
                </Div>
            </Div>
            :
            null
        ;
    }

    renderSoinCheck() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const event = this.props.eventItem;
        const listings = Utils.get(event, "listings", []);
        const customerListings = Utils.arrayToObject(event["customer_listings"], "id");
        const haveNft = !(event["customer_listings"] == undefined || event["customer_listings"].length == 0);
        const user = pr.user ? pr.user : {};
        let greeting = "";
        let customerPos = -1;
        let customerPublicKey = "";
        if(user.username) {
            greeting = user.username;
            customerPublicKey = user.bitclout_public_key;
        }
        // If this is an NFT event, they can RSVP. If Bitclout, they need to have all of the Creator Coin amounts required
        let canRsvp = haveNft;
        let qualifies = 0;
        let walletTypes = [];
        for(var idx in listings) {
            const listing = listings[idx];
            //ll._("CAN ATTEND", "purple", listing, Utils.get(listing, 'current_user.can_attend'));
            if(Utils.get(listing, 'current_user.can_attend')) {
                qualifies++;
            }
        }
        // TODO: Remove this mega-hack that gets info from ConnectWallet interval check to verify balance of coin
        if(window.qualifies) {
            qualifies += window.qualifies;
        }
        // TODO: This hack lets in AP events with 3 or more people -- fix to always require AP token and then a mix of others
        var minListingNum = 1; //listings.length;
        if(qualifies >= minListingNum) {
            canRsvp = true;
        }
        const hasRsvped = false;

        const admitNfts = StyleUtils.getMediaStyle('admitNfts', sty.admitNfts, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const textAlign = StyleUtils.getMediaStyle('textAlign', sty.textAlign, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        const isTenMinutesBeforeEvent = st.eventMinutesLeft <= 10;
        const isTwoHoursBeforeEvent = st.eventMinutesLeft <= 120;

        return (
            <Div className="admitNftsContainer" style={sty.admitNftsContainer}>
                {canRsvp ?
                    <>
                        <Div>Congratulations, {greeting}!</Div>
                        {!hasRsvped ?
                            <Div style={{display:"flex",justifyContent:"center",marginTop:10}}>
                                {Utils.get(pr.eventItem, "customer_rsvped") ?
                                    <Div style={{margin: "0 auto"}}>
                                        <Div className="eventRsvpSucess" style={sty.eventRsvpSucess}>
                                            <Div>YOU'RE RSVP'd TO THIS EVENT</Div>
                                            <Div style={{position:"relative"}}>
                                                <Icon icon="certificate" style={{marginLeft: 8}}/>
                                                <Icon icon="check" size="10" style={{position:"absolute",left:11, top:5, color:Colors.pink, }}/>
                                            </Div>
                                        </Div>
                                        {isTenMinutesBeforeEvent || st.isEventTime || (isTwoHoursBeforeEvent && Utils.get(pr.eventItem, "insiders.isInsider", false)) ?
                                            <Button
                                                style={{marginTop: 15, width: 282}}
                                                color="purpleLight1"
                                                onClick={this.handleRoomClick.bind(this)}
                                            >
                                                Enter Room
                                            </Button>
                                            :
                                            <Div style={{marginTop: 15, fontSize: 14}}>
                                                You can enter the room 10 minutes before the event will start
                                            </Div>
                                        }

                                    </Div>
                                    :
                                    <Button onClick={this.handleRsvpClick.bind(this)} style={{marginTop: 10}}>
                                        RSVP Now
                                    </Button>
                                }
                            </Div>
                            :
                            null
                        }
                    </>
                    :
                    Utils.get(pr.eventItem, "need_to_own_text") && Utils.get(pr.eventItem, "need_to_own_text").length > 1 ?
                        <Div style={textAlign}>{Utils.get(pr.eventItem, "need_to_own_text")}</Div>
                        :
                        <Div style={textAlign}>You need to own or purchase Creator Coins to RSVP for this event:</Div>
                }
                <Div className="admitNfts" style={admitNfts}>
                    {listings.map((listing) => {
                        if(!listing["holdersDict"]) {
                            return null;
                        }

                        return (
                            <Div key={`listing-${listing["id"]}`} onClick={this.handleListingClick.bind(this, listing)} >
                                <Div style={{position:"relative"}}>
                                    <Image style={sty.listingImage} src={listing['image_url']} title={listing['title']} />
                                    {listing['holdersDict'][customerPublicKey] ?
                                        <Div style={sty.admitNftCaption}>{(listing['holdersDict'][customerPublicKey]['pos']-1) + " of " + listing['holders'].length}</Div>
                                        :
                                        null
                                    }
                                    {Utils.get(listing, 'current_user.can_attend') ?
                                        <Div style={sty.badgeContainerCheck}>
                                            <Icon
                                                icon="check"
                                                color={sty.badgeIcon}
                                                size={18}
                                                title="You have enough Creator Coin to RSVP"
                                            />
                                        </Div>
                                        :
                                        <Div>
                                            <Div style={sty.badgeContainerLock}>
                                                <Icon
                                                    icon="lock"
                                                    color={sty.badgeIcon}
                                                    title="You don't have enough Creator Coin to RSVP"
                                                    size={18}
                                                />
                                            </Div>
                                            <Div style={sty.badgeContainerLockMoney}>
                                                <Icon
                                                    icon="dollar"
                                                    color={sty.badgeIcon}
                                                    title="Click here to buy more Creator Coin"
                                                    size={18}
                                                />
                                            </Div>
                                        </Div>
                                    }
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        );
    }

    renderArtistTitle() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let artistTitle = Utils.get(pr.eventItem, 'room.artist.title');

        const eventArtistTitle = StyleUtils.getMediaStyle('eventArtistTitle', sty.eventArtistTitle, sty, StyleUtils.getWidthType(pr.screen.width));

        if(!pr.eventLoaded) {
            return (
                <Spinner
                    containerStyle={{alignItems: "left", padding: "23px 0"}}
                />
            )
        }

        if(artistTitle) {
            return (
                <Div className="eventArtistTitle" style={artistTitle.split(" ")[0].length < 9 ? eventArtistTitle : {...eventArtistTitle, ...{fontSize: 48}}}>
                    {artistTitle}
                </Div>
            )
        }

        return null;
    }

    chooseCustomerTypeText() {
        const pr = this.props;

        if(!Utils.get(pr.eventItem, "insiders", false)) {
            return "Guest";
        }

        if(Utils.get(pr.eventItem, "insiders.isHost", false)) {
            return "Host"
        }

        if(Utils.get(pr.eventItem, "insiders.isCohost", false)) {
            return "Co-Host"
        }

        if(Utils.get(pr.eventItem, "insiders.isMod", false)) {
            return "Mod"
        }

        return "Guest";
    }

    handleEventTrigger() {
        console.log("Time for event!");
        this.setState({isEventTime: true});
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const event = pr.eventItem;
        const isTenMinutesBeforeEvent = st.eventMinutesLeft <= 10;
        const isTwoHoursBeforeEvent = st.eventMinutesLeft <= 120;
        const alphaletters = /[A-Z]/;
        //console.log("MATCH", event['event_date'], event['event_date'].match(alphaletters));
        let comingSoon = false;
        // If event is coming soon or TBD
        if(event['event_date'] && event['event_date'].match(alphaletters)) {
            comingSoon = true;
        }


        let wallets = Utils.get(pr.user, "wallets", []);

        const eventTitle = StyleUtils.getMediaStyle('eventTitle', sty.eventTitle, sty, StyleUtils.getWidthType(pr.screen.width));
        const mainEventImage = StyleUtils.getMediaStyle('mainEventImage', sty.mainEventImage, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const eventInfoContainer = StyleUtils.getMediaStyle('eventInfoContainer', sty.eventInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const feedColumn = StyleUtils.getMediaStyle('feedColumn', sty.feedColumn, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyLogoType = StyleUtils.getMediaStyle('afterpartyLogoType', sty.afterpartyLogoType, sty, StyleUtils.getWidthType(pr.screen.width));
        const afterpartyLogoTypeLoaderScreen = StyleUtils.getMediaStyle('afterpartyLogoTypeLoaderScreen', sty.afterpartyLogoTypeLoaderScreen, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventButtonContainer = StyleUtils.getMediaStyle('eventButtonContainer', sty.eventButtonContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'xs'));
        const signInButton = StyleUtils.getMediaStyle('signInButton', sty.signInButton, sty, StyleUtils.getWidthType(pr.screen.width, 'xs'));
        const profileIconContainer = StyleUtils.getMediaStyle('profileIconContainer', sty.profileIconContainer, sty, StyleUtils.getWidthType(pr.screen.width));

        const rsvpNftModal = StyleUtils.getMediaStyle('rsvpNftModal', sty.rsvpNftModal, sty, StyleUtils.getWidthType(pr.screen.width));
        const rsvpNftAfterpartyText = StyleUtils.getMediaStyle('rsvpNftAfterpartyText', sty.rsvpNftAfterpartyText, sty, StyleUtils.getWidthType(pr.screen.width));
        const rsvpNftEventTitleImageContainer = StyleUtils.getMediaStyle('rsvpNftEventTitleImageContainer', sty.rsvpNftEventTitleImageContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const rsvpNftEventTitle = StyleUtils.getMediaStyle('rsvpNftEventTitle', sty.rsvpNftEventTitle, sty, StyleUtils.getWidthType(pr.screen.width));
        const rsvpNftImage = StyleUtils.getMediaStyle('rsvpNftImage', sty.rsvpNftImage, sty, StyleUtils.getWidthType(pr.screen.width));
        const verifiedRsvp = StyleUtils.getMediaStyle('verifiedRsvp', sty.verifiedRsvp, sty, StyleUtils.getWidthType(pr.screen.width));

        // const leftColumnCss = "linear-gradient(to right, rgba(230, 100, 101, 0.8), rgba(145, 152, 229, 0.6), rgba(0, 0, 0, 0))"
        // const leftColumnCss = "linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))"
        const leftColumnCss = pr.eventItem.left_column_css;
        const gradient = {background: leftColumnCss};
        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        const rsvpNftImageUrl = {backgroundImage: `url(${Utils.get(pr.eventItem, "room.artist.image_url")})`};

        const walletTypesTemp = this.getWalletTypes();
        //console.log("EVENTITEM -- 1", pr.eventItem);

        const eventTitleText = Utils.get(pr.eventItem, 'title');
        const eventType = Utils.get(pr.eventItem, "event_type", 1);

        return (
            <Div className="afterpartyEventComponent" style={{overflow: "hidden"}}>
                <BloatedCircleDefs
                    scales={[50, 96, 100, 200]}
                />

                {pr.eventItem ?
                    <Div className="mainEventImage" style={{...mainEventImage, ...{width: pr.screen.width, height: pr.screen.height}, ...{backgroundImage: `url(${pr.eventItem["image_url"]})`}}}>
                        <Div className="eventInfoContainer" style={{...eventInfoContainer, ...gradient}}>

                            {!this.isIrlEvent ?
                                <Div className="afterpartyPageBack" style={sty.afterpartyPageBack} onClick={this.backToEvents.bind(this)}>
                                    <Div style={sty.afterpartyIconCircle}>
                                        <Icon icon="chevron-left" size={16} style={sty.chevronIcon} />
                                    </Div>
                                </Div>
                                :
                                null
                            }

                            <Image className="afterpartyLogo" style={afterpartyLogoType} src={afterpartyLogoNew} />

                            {st.eventSecondsLeft > 0 ?
                                <Div style={{color: "white", marginTop: 10, marginBottom: 10}}>
                                    <Icon icon="clock-o" style={{marginRight: 10, cursor: "default"}} size={20} />
                                    <Span>{Utils.secondsToTimeString(st.eventSecondsLeft, "dhm")}</Span>
                                </Div>
                                :
                                null
                            }

                            {eventType === Constant.EVENT_TYPE_COMP_CODE || eventType === Constant.EVENT_TYPE_COMP_CODE_UTOPIAN ?
                                null
                                :
                                <Div className="eventTitle" style={eventTitle}>
                                    {pr.eventItem.title} &nbsp;
                                </Div>
                            }

                            {this.renderArtistTitle()}
                            {st.stripeInfo ?
                                <StripePayment
                                    stripeMode="test"
                                    stripeInfo={this.state.stripeInfo}
                                />
                                :
                                null
                            }

                            {Utils.get(pr.eventItem, "subtitle", "").length ?
                                <Div className="eventSubtitle" style={sty.eventSubtitle}>
                                    {pr.eventItem.subtitle}
                                </Div>
                                :
                                null
                            }

                            <Div className="eventDescription" style={sty.eventDescription}>
                                {pr.eventItem.description}
                            </Div>



                            {pr.eventItem.event_type === 1 ?
                                <Div className="eventSeatsRemaining" style={{color:"white", marginBottom: 20}}>
                                    {pr.eventItem.seats_total - pr.eventItem.customer_rsvps > 0 ?
                                        `${pr.eventItem.seats_total - pr.eventItem.customer_rsvps} seats remaining`
                                        :
                                        "No seats available."
                                    }
                                </Div>
                                :
                                null
                            }


                            {!st.registeredSuccess ?
                                <Div>
                                    {pr.user.email || this.isIrlEvent || this.isCompRegisterEvent ?
                                        null
                                        :
                                        <Div className="eventButtonContainer" style={eventButtonContainer}>
                                            <Button
                                                onClick={this.onRequestRegisterModalOpen.bind(this, "username")}
                                            >
                                                REGISTER
                                            </Button>
                                            <Button
                                                color="white"
                                                style={signInButton}
                                                onClick={this.showLoginModalBody.bind(this)}
                                            >
                                                SIGN IN
                                            </Button>
                                        </Div>
                                    }
                                </Div>
                                :
                                <Div style={{color: "white", fontSize: 16}}>
                                    {st.bitCloutAddress ?
                                        <Div>
                                            You are registered for this event with BitClout Address: {`${st.bitCloutAddress.substring(0,6)}...${st.bitCloutAddress.substring(st.bitCloutAddress.length - 6)}`}
                                        </Div>
                                        :
                                        <Div>
                                            You are registered for this event
                                        </Div>
                                    }

                                    {Utils.get(pr.eventItem, "insiders.isInsider", false) ?
                                        <Button
                                            style={{marginTop: 25, width: 400}}
                                            color="purpleLight1"
                                            onClick={this.handleRoomClick.bind(this)}
                                        >
                                            Enter Room as Host or Co-Host
                                        </Button>
                                        :
                                        null
                                    }
                                </Div>
                            }

                            {Utils.get(pr.user, "email") && (eventType !== Constant.EVENT_TYPE_COMP_CODE && eventType !== Constant.EVENT_TYPE_COMP_CODE_UTOPIAN) ?
                                <Div>
                                    <Button
                                        onClick={this.onRequestRegisterModalOpen.bind(this, "success")}
                                        style={{marginTop: 15, maxWidth: 400}}
                                        color="magenta"
                                        className="pulse"
                                    >
                                        BUY
                                    </Button>
                                </Div>
                                :
                                null
                            }

                            {Utils.get(this.props, 'user.email') && (this.state.letAllIn || Utils.getRequest('have') == 1 || (pr.eventItem['override_code'] && Utils.getRequest('guest') == pr.eventItem['override_code']) ) ?
                                <Button
                                    onClick={this.handleRoomClick.bind(this)}
                                    style={{marginTop: 15, maxWidth: 400}}
                                    color="purpleLight1"
                                    className="pulse"
                                >
                                    Enter Room as {this.chooseCustomerTypeText()}
                                </Button>
                                :
                                null
                            }

                            {pr.eventItem.event_type === Constant.EVENT_TYPE_ONLINE ?
                                <Div>
                                    {this.renderSoinCheck()}
                                </Div>
                                :
                                null
                            }

                            {Utils.get(pr.user, "email") && (eventType !== Constant.EVENT_TYPE_COMP_CODE && eventType !== Constant.EVENT_TYPE_COMP_CODE_UTOPIAN) && Utils.get(pr.user, "wallets", "").length ?
                                <Div className="profileSectionContainer" style={profileSectionContainer}>
                                    <Div style={sty.profileSectionHeadline}>Wallets</Div>
                                    <Div>
                                        <WalletTable
                                            screen={pr.screen}
                                            style={{marginTop: 15, marginBottom: 20}}
                                            handleSetWallet={this.handleSetWallet.bind(this)}
                                            user={pr.user}
                                            eventItem={pr.eventItem}
                                            walletTypes={this.getWalletTypes()}
                                        />
                                    </Div>
                                </Div>
                                :
                                null
                            }

                        </Div>
                        {/* <Div className="feedColumn" style={{...feedColumn,...{position:"relative"}}}>
                            <Div style={{flex: 1}} />
                            <Div style={{flex: 4}}>
                                {this.renderFeedColumn()}
                            </Div>
                            <Div style={{flex: 1}} />

                        </Div> */}
                    </Div>
                    :
                    <Div className="eventNotFoundOrLoadedContainer" style={sty.eventNotFoundOrLoadedContainer}>
                        {pr.eventLoaded ?
                            <Div className="afterpartyPageBack" style={sty.afterpartyPageBack} onClick={this.backToEvents.bind(this)}>
                                <Div style={{...sty.afterpartyIconCircle, ...{backgroundColor: Colors.pink}}}>
                                    <Icon icon="chevron-left" size={16} style={sty.chevronIcon} />
                                </Div>
                                <Div style={{marginTop: 2, marginRight: 20}}>Events Page</Div>
                            </Div>
                            :
                            null
                        }

                        <Image style={sty.afterpartyLogoTypeLoaderScreen} src="/images/afterparty-logotype.png" />
                        {pr.eventLoaded ?
                            <Div style={{color: "white"}}>
                                Event not found.
                            </Div>
                            :
                            <Spinner
                                message="Loading Event..."
                                messageStyle={{color: "white"}}
                            />
                        }
                    </Div>
                }

                <RegisterFlow
                    user={pr.user}
                    screen={pr.screen}
                    history={pr.history}
                    usernameModalTitle={<Div>Create an account to RSVP for the <br/>{eventTitleText} Event</Div>}
                    successModalMessage={<Div>Already have {pr.eventItem.title} social token?{pr.screen.mobile ? null : <br/>}</Div>}
                    registerModalBody={st.registerModalBody}
                    showRegisterFlowModal={st.showRegisterFlowModal}
                    eventItem={pr.eventItem}
                    isPhoneInput
                    successMessageData={Utils.get(pr.eventItem, "json_data.successBody", {})}
                    setUser={pr.setUser.bind(this)}
                    onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
                    handleSetWallet={this.handleSetWallet.bind(this)}
                    setRegisteredSuccess={this.setRegisteredSuccess.bind(this)}
                />

                {st.showRsvpNftModal ?
                    <Modal
                        screen={pr.screen}
                        user={pr.user}
                        onRequestClose={this.closeRsvpNftModal.bind(this)}
                        style={rsvpNftModal}
                    >
                        <Div style={rsvpNftAfterpartyText}>Afterparty</Div>

                        <Div style={rsvpNftEventTitleImageContainer}>
                            <Div className="rsvpNftEventTitle" style={rsvpNftEventTitle}>
                                {pr.eventItem['title']}
                            </Div>
                            <Div
                                className="rsvpNftImage"
                                style={{ ...rsvpNftImage, ...rsvpNftImageUrl }}
                            ></Div>
                        </Div>

                        <Div style={sty.rsvpNftFooterContainer}>
                            <Div className="verifiedRsvp" style={verifiedRsvp}>
                                <Div style={{marginTop: 3}}>VERIFIED</Div>
                                <Div style={{position:"relative"}}>
                                    <Icon icon="certificate" style={{marginLeft: 8}}/>
                                    <Icon icon="check" size="10" style={{position:"absolute",left:11, top:5, color:"white", }}/>
                                </Div>
                            </Div>
                            { comingSoon ?
                                <Div style={sty.topCoinHolder}>Coming Soon!</Div>
                                :
                                <Div style={sty.topCoinHolder}>Top Coin Holder</Div>
                            }
                        </Div>
                    </Modal>
                    :
                    null
                }

            </Div>
        )
    }

    styles = {
        mainEventImage: {
            display: "flex",
            backgroundSize: "cover",
            backgroundColor: "black",
            backgroundPosition: "top",
            overflowY: "scroll",
            overflowX: "hidden",
        },
        mainEventImage_md: {
            flexDirection: "column",
        },
        feedColumn: {
            // flex: 6,
            padding: 50,
            display: "flex"
        },
        feedColumn_lg: {
            padding: "50px 30px 50px 20px",
        },
        feedColumn_md: {
            position: "relative",
            paddingTop: 0,
            paddingRight: 25,
            paddingBottom: 60,
            paddingLeft: 0,
        },
        afterpartyLogoType: {
            maxWidth: 350,
            paddingTop: 30,
            paddingBottom: 20,
            width: "100%",
            marginLeft: -15,
        },
        afterpartyLogoType_sm: {
            marginLeft: "auto",
            marginRight: "auto",
            marginLeft: -15,
        },
        afterpartyLogoType_xs: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        afterpartyLogoTypeLoaderScreen: {
            maxWidth: 350,
            width: "100%",
        },
        eventInfoContainer: {
            color: "white",
            display: "flex",
            flexDirection: "column",
            flex: 4,
            padding: "40px 0 40px 80px",
            marginTop: "auto",
            marginBottom: "auto",
            minHeight: "100vh",
        },
        eventInfoContainer_lg: {
            padding: "40px 0 40px 50px",
            minHeight: "auto",
        },
        eventInfoContainer_md: {
            padding: "40px 50px 40px 50px",
            width: "100%",
            minHeight: "auto",
        },
        eventInfoContainer_sm: {
            padding: "30px 30px 30px 30px",
            width: "100%",
            minHeight: "auto",
        },
        eventInfoContainer_xs: {
            padding: "20px 20px 20px 20px",
            width: "100%",
            // minHeight: `calc(${this.props.screen.height}px - 100px)`,
            minHeight: "auto",
        },
        eventTitle: {
            fontSize: 36,
            fontWeight: "bold",
        },
        eventTitle_xs: {
            fontSize: 28,
        },
        eventArtistTitle: {
            fontSize: 64,
            fontWeight: "bold",
        },
        eventArtistTitle_xs: {
            fontSize: 54,
        },
        eventSubtitle: {
            fontSize: 16,
        },
        eventDescription: {
            fontSize: 16,
            marginTop: 60,
            marginBottom: 20,
            maxWidth: 400,
        },
        eventRsvpSucess: {
            display: "flex",
            alignItems: "center",
            padding: "10px 14px",
            backgroundColor: Colors.pink,
            borderRadius: 8,
            width: "fit-content",
            fontWeight: 700,
            margin: "0 auto",
        },
        eventButtonContainer: {
            display: "flex",
            width: "fit-content",
        },
        eventButtonContainer_xs: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
        },
        signInButton: {
            marginLeft: 20,
        },
        signInButton_xs: {
            marginLeft: 0,
            marginTop: 15,
        },
        eventNotFoundOrLoadedContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: Colors.purple,
        },
        container: {
            textAlign: "left",
            fontFamily: "'Montserrat', sans-serif",
            backgroundColor: "#FDFCFC",
        },
        eventBanner: {
            position: "relative",
            width: "100%",
        },
        eventBannerInfobox: {
            color: "white",
            position: "absolute",
            bottom: 55,
            left: 47,
            textAlign: "center",
        },
        listingImage: {
            width: 96,
            height: 96,
            borderRadius: 32,
            overflow: "hidden",
            margin: 6,
            // clipPath:"url(#bloated-circle-96)",
            // WebkitClipPath:"url(#bloated-circle-96)",
            cursor: "pointer",
        },
        feedContainer: {
            backgroundColor:"black",
            color:"white",
            borderRadius: 20,

        },
        feedRow: {
            padding:16,
            display: "flex",
            borderBottom: "1px solid gray",
            margin: 10,
        },
        feedUsername: {
            fontWeight:"bold",
            marginBottom: 4,
        },
        admitNftsContainer: {
            display: "flex",
            flexWrap: "wrap",
            marginTop: 14,
            backgroundColor:"rgba(0,0,0,0.7)",
            border: "1px solid rgba(255,255,255,0.2)",
            borderRadius: 20,
            padding: 20,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            color: "white",
            maxWidth: 400,
        },
        admitNfts: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 14,
        },
        admitNfts_sm: {
            justifyContent: "center"
        },
        textAlign: {
            textAlign: "left",
        },
        textAlign_sm: {
            textAlign: "center",
        },
        admitNftCaption: {
            color: "white",
            textAlign:"center",
        },
        badgeContainerCheck: {
            position: "absolute",
            right: 5,
            top: 5,
            width:30,
            height:30,
            borderRadius:15,
            backgroundColor: Colors.purple,
            color:"white",
            textAlign: "center",
            paddingTop: 5,
            border: "1px solid white",
        },
        badgeContainerLock: {
            position: "absolute",
            right: 5,
            top: 5,
            width:30,
            height:30,
            borderRadius:15,
            backgroundColor: "#B94F5D",
            color:"white",
            textAlign: "center",
            paddingTop: 5,
            border: "1px solid white",
        },
        badgeContainerLockMoney: {
            position: "absolute",
            right: 5,
            top: 40,
            width:30,
            height:30,
            borderRadius:15,
            backgroundColor: "#B94F5D",
            color:"white",
            textAlign: "center",
            paddingTop: 5,
            border: "1px solid white",
        },
        badgeIcon: {
        },
        profileIconContainer: {
            position: "absolute",
            top: 30,
            right: 30,
        },
        profileIconContainer_md: {
            // left: 50,
            right: "auto",
            paddingBottom: 50,
            marginTop: -35,
            display: "flex",
            alignItems: "center",
        },
        profileIconContainer_sm: {
            // left: 30,
            right: "auto",
            paddingBottom: 30,
            marginTop: -30,
            display: "flex",
            alignItems: "center",
        },
        profileIconContainer_xs: {
            display: "flex",
            // left: 20,
            right: "auto",
            paddingBottom: 16,
            marginTop: -30,
            alignItems: "center",
        },
        afterpartyPageBack: {
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            cursor: "pointer",
            marginBottom: 10,
            color: "white",
            width: 155,
        },
        afterpartyIconCircle: {
            borderRadius: 13,
            height: 27,
            width: 27,
            backgroundColor: Colors.purple,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
        },
        chevronIcon: {
            fontSize: 18,
            marginLeft: -2,
        },
        rsvpNftModal: {
            backgroundSize: "cover",
            backgroundImage: "url(/images/top_holder_gradient.jpg)",
            color: "white",
            maxWidth: 420,
        },
        rsvpNftModal_sm: {

        },
        rsvpNftModal_xs: {

        },
        rsvpNftAfterpartyText: {
            marginLeft: -16,
            marginRight: "auto",
            fontWeight: "bold",
            fontSize: 24,
        },
        rsvpNftAfterpartyText_sm: {
            marginLeft: 0,

        },
        rsvpNftAfterpartyText_xs: {
            marginLeft: 0,
            marginBottom: 40,
        },
        rsvpNftEventTitleImageContainer: {
            display: "flex",
            position: "relative",
            marginTop: -30,
            width: "100%",
            marginBottom: 30,
        },
        rsvpNftEventTitleImageContainer_sm: {

        },
        rsvpNftEventTitleImageContainer_xs: {

        },
        rsvpNftEventTitle: {
            position: "absolute",
            color: "white",
            fontSize: 48,
            marginTop: 80,
            fontWeight: "bold",
            left: -34,
            padding: "10px 20px",
            width: "fit-content",
            bottom: -27,
            lineHeight: "54px",
            backgroundColor: "rgba(0,0,0,0.6)",
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
        },
        rsvpNftEventTitle_sm: {
            left: -6,
        },
        rsvpNftEventTitle_xs: {
            left: -6,

        },
        rsvpNftImage: {
            width: 260,
            height: 260,
            marginRight: -34,
            marginLeft: "auto",
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
        },
        rsvpNftImage_sm: {
            marginRight: -15,
        },
        rsvpNftImage_xs: {
            marginRight: -15,
        },
        rsvpNftFooterContainer: {
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
        },
        verifiedRsvp: {
            display: "flex",
            alignItems: "center",
            padding: "0 14px",
            color: Colors.pink,
            backgroundColor: "white",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            width: "fit-content",
            fontWeight: 700,
            marginLeft: -84,
        },
        verifiedRsvp_sm: {
            marginLeft: -83,
        },
        verifiedRsvp_xs: {
            marginLeft: -18,
        },
        topCoinHolder: {
            marginLeft: 10,
            fontSize: 24,
            fontWeight: "bold",
        },
        profileSectionContainer: {
            backgroundColor: Colors.purpleLight1Semi,
            marginTop: 30,
            marginBottom: 30,
            padding: 10,
            paddingTop: 20,
            borderRadius: 20,
            maxWidth: 500,
            width: "100%",
        },
        profileSectionContainer_sm: {
            padding: 10,
            borderRadius: 10,
        },
        profileSectionHeadline: {
            fontSize: 24,
            fontWeight: "bold",
            color:"white",
            marginBottom:10,
        },
        profileSubSectionContainer: {
            backgroundColor: Colors.purpleLight2,
            marginBottom: 30,
            padding: 20,
            borderRadius: 10,
            textAlign: "left",
        },
    };
}
