import React, { Fragment } from 'react';

import axios from 'axios';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Crypto from '../modules/Crypto';

import Div from './Div';
//import Image from './Image';

import metamaskLogo from '../assets/metamask.png';

export default class DashboardDiscordVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        dirty: false,
    };

  }

  handleConnectMetamaskClick() {
      Crypto.connectWallet((walletInfo) => {
              if(walletInfo['metamaskActive'] && walletInfo['connectedWallet']) {
                  const nonce = Crypto.generateNonce();
                  const message = "Please verify ownership of the wallet that contains your Soin with this nonce: " + nonce;
                  const account = wallets['wallets'][0];
                  Crypto.signMessage(account, message, (sign) => {
                          alert("Signature Verified! -- "+JSON.stringify(sign));
                  });
              } else {
                  alert("Metamask wallet not connected.");
              }
      });
  }


  render() {

    return (
      <div className="col itemContainer"
        style={
          this.props.style
            ? { ...this.styles.container, ...this.props.style, }
            : { ...this.styles.container }
        }

      >
        <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
            <div className="col">
                <Div style={{width:400}}>Connect the wallet that contains your Soins to access the Owner-only areas of the Soin Discord server.</Div>
                <Div style={this.styles.walletContainer} onClick={this.handleConnectMetamaskClick.bind(this)}>
                    <img style={{width:48,height:48,marginRight:20}} src={metamaskLogo} />
                    <Div>Connect MetaMask Wallet</Div>
                </Div>
            </div>
        </div>

      </div>
    );
  }
  styles = {
    container: {
      background:"#FBF8F4",
      padding: 20,
      textAlign: "left",
      paddingBottom: 150,
      minHeight: 800,
    },
    walletContainer: {
        margin: 10,
        border: "1px solid gray",
        padding:20,
        borderRadius:5,
        justifyContent:"center",
        width: 280,
        display: "flex",
        background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
        color: "white",
    },


  };
}
