import React from 'react';

import moment, { max } from 'moment'
import Lottie from "lottie-react-web"

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import ll from '../modules/ll';

import Image from '../elements/Image';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';
import Span from '../elements/Span';
import Spinner from '../elements/Spinner';
import Button from '../elements/Button';

import Div from './Div';
import ProfileMenu from './ProfileMenu';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import eventRow from '../assets/afterparty-event-row.jpg';
import ModalRegister from './ModalRegister';
import ModalFestival2021a from './ModalFestival2021a';
import afterpartyUpcomingEventsBanner from '../assets/images/afterparty-banner-landscape.jpg';
import bellLottie from '../assets/7615-bell-notification.json';
import ConfettiGenerator from "confetti-js";

export default class AfterpartyUpcomingEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        wallet: null,
        showModal: false,
        registerModelTitle: null,
        testMode: false,
        loopBell: true,
        confettiHeight: 0,
        confettiWidth: 0,
    };

  }

    componentDidMount() {
        setTimeout(() => {
            this.setState({loopBell: false})
        }, 5000);

        this.createConfettiInterval = window.setInterval(this.calculateConfettiHeight.bind(this), 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.createConfettiInterval);
    }

    createConfetti() {
        var confettiElement = document.getElementById('my-canvas');
        var confettiSettings = {
            target: confettiElement,
            max: 50,
            speed: 10,
            colors: [[101,93,191],[255,198,73],[205,25,123],[49,51,115],[102,45,145]],
            rotate: true,
            height: this.state.confettiHeight,
            width: this.state.confettiWidth,
        };
        var confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
    }

    calculateConfettiHeight() {
        const st = this.state;

        let confettiHeight = 0;
        let confettiWidth = 0;
        const upcomingEventsContainer = $(".upcomingEventsContainer");
        const previousEventsText = $(".previousEventsText");

        if(previousEventsText && upcomingEventsContainer) {
            const pePos = previousEventsText.position();
            const uePos = upcomingEventsContainer.position();
            if(pePos && uePos) {
                confettiHeight = pePos.top - uePos.top;
                confettiWidth = upcomingEventsContainer.outerWidth();
                if(st.confettiHeight !== confettiHeight || st.confettiWidth !== confettiWidth) {
                    this.setState({
                        confettiHeight: confettiHeight,
                        confettiWidth: confettiWidth,
                    }, () => {
                        this.createConfetti();
                    });
                }
            }
        }
    }

    handleEventClick(event) {
        // If no listing_ids, even is a simple banner
        if(event && event['listing_ids']) {
            ll._("Go to event" + event['name'] + " crc:"+event['crc']);
            if(this.props.history) {
                this.props.history.push('/event/'+event['name']+'-'+event['crc']); //+item["name"]+"-"+item["crc"])
            }
            this.props.change('event', event);
        } else {
            ll._("No listing IDs for event", "red");
        }
    }

    handleRegisterSuccess(newUser) {
        if(this.state.registerType == "afterpartynft") {
            this.setState({showModal:2});
        } else {
            this.setState({showModal:3});
        }
        //this.props.setUser(newUser);
    }

  handleRegisterClick(type, event) {
      let title = "Register to Join Waitlist";
      let modalNum = 1;
      if(type == "afterpartynft") {
          title = "Register for Afterparty NFT";
          // If they are already registered and logged in, display second dialog
          if(Utils.get(this.props, "user.email")) {
              modalNum = 2;
                const data = {id:999};
                Api.postRsvp(data, (response) => {});
          } else {
              modalNum = 1;
          }
      } else if(type == "notify") {
          if(Utils.get(this.props, "user.email")) {
              modalNum = 4;
              const data = {id:996};
              Api.postRsvp(data, (response) => {});
          } else {
              modalNum = 1;
          }
      } else {
          if(Utils.get(this.props, "user.email")) {
              modalNum = 3;
              const data = {id:998};
              Api.postRsvp(data, (response) => {});
          }
      }
      this.setState({
          registerType: type,
          registerModelTitle: title,
          showModal: modalNum,
      });
  }

  closeRegisterModal() {
    this.setState({showModal: false});
  }

  handleGotoProfile() {
      const pr = this.props;
      pr.history.push('/profile');
      pr.change('profile');
  }

  renderHeroEvent(events) {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      if(pr.screen.mobile) {
          return null;
      }

      const whiteGradientButtonExtras = {color: "#F374B9", width:100, paddingTop:7, paddingBottom:7, paddingLeft:6, paddingRight:6, fontSize:11, letterSpacing: 3, marginBottom:30,};
      const now = moment();
      const event = events[0] ? events[0] : {};
      if(event) {
          let eventDate = 'TBD';
          let eventPassed = false;
          let until = false;
          if(event['event_date']) {
              const eventParts = event['event_date'].split('-');
              eventDate = moment([eventParts[0], eventParts[1]-1, eventParts[2], event['event_start_time'], 0, 0]);
              // Check if it is a day past
              const eventDate2 = moment([eventParts[0], eventParts[1]-1, parseInt(eventParts[2])+1, event['event_start_time'], 0, 0]);
              //console.log("ED", event['title'], eventDate2);
              eventPassed = now > eventDate2 ? true : false;
              until = Utils.calcTimeStr(now, eventDate, "dhm");
          }
      }

      const ctaTitle = "Wait List";
      const ctaSubtitle = "+ Join";
      const gradient = this.styles.waitingList;
      let bannerStyle = {backgroundImage: `url(${event['image_url'] ? event['image_url'] : afterpartyUpcomingEventsBanner})`};
      // let bannerStyle = {backgroundImage: `url(${afterpartyUpcomingEventsBanner})`};

      if(!event['event_date']) {
          bannerStyle['backgroundPosition'] = "center center";
          bannerStyle['width'] = "100%";
      }

      const heroBannerImage = StyleUtils.getMediaStyle('heroBannerImage', sty.heroBannerImage, sty, StyleUtils.getWidthType(pr.screen.width));

      return (
          <Div className="heroEventContainer" style={Styles.heroEventContainer}>
              <Div key={`event-row-${event['id']}`} onClick={this.handleEventClick.bind(this, event)}>
                    <Div style={{display:"flex"}}>
                        <Div style={{flex:1, position:"relative", color:"white"}}>
                            <Div className="heroBannerImage" style={{...heroBannerImage, ...bannerStyle}}>
                            </Div>
                            { event['event_date'] ?
                                <Div style={{position:"absolute",top:0,right:0,height: 360,width:150,background:"rgba(88,79,185,0.5)"}}>
                                    <Div style={{flex:1, textAlign:"center",padding:20}}>
                                        <Div style={{fontSize:64,fontWeight:"bold",lineHeight: "59px"}}>{eventDate == 'TBD' ? eventDate : eventDate.format('D')}</Div>
                                        <Div style={{fontSize:12,}}>{eventDate == 'TBD' ? eventDate : eventDate.format('MMMM')}</Div>
                                    </Div>
                                    <Div style={{flex:1, textAlign:"center",padding:20}}>
                                        <Div style={{fontSize:28,fontWeight:"bold",lineHeight: "29px"}}>{event['seats_total']}</Div>
                                        <Div style={{fontSize:12,fontWeight:"bold",}}>seats</Div>
                                    </Div>
                                    <Div style={{flex:1, textAlign:"center",padding:20}}>
                                        <Div style={{fontSize:28,fontWeight:"bold",lineHeight: "29px"}}>{parseInt(event['seats_total']*0.21)}</Div>
                                        <Div style={{fontSize:12,fontWeight:"bold",}}>left</Div>
                                    </Div>

                                </Div>
                                :
                                null
                            }

                        </Div>
                        { event['event_date'] ?
                        <Div style={{flex:8,padding:20, paddingTop:70, color:"white",}}>
                            <Div style={{marginLeft:100}}>
                                <Div style={{...Styles.whiteGradientButton,...whiteGradientButtonExtras}}>
                                    FEATURED
                                </Div>

                                <Div style={{fontSize: 31, fontWeight: "bold",}}>{event['title']}</Div>

                                    <Div style={{fontSize: 14}}>{event['event_start_time']} - {event['event_end_time']} PST
                                    { !eventPassed ?
                                        <Span>/ starts in {until}</Span>
                                        :
                                        null
                                    }
                                    </Div>
                            </Div>
                            <Div style={{display:"flex"}}>
                                <Div style={{flex:1, textAlign:"center",padding:20, display:"none" }}>
                                    { event['id'] % 2 == 0 ?
                                        <Div style={{}}>
                                            <Div><i style={{fontSize:84,color:"green"}} className="fa fa-check"></i></Div>
                                            <Div>You own the Soin to attend</Div>
                                        </Div>
                                        :
                                        <Div style={{display:"flex", justifyContent:"center"}}>
                                            <Div style={Styles.btnMagenta}>Buy Soin to attend</Div>
                                        </Div>
                                    }
                                </Div>
                                <Div style={{...gradient,...{padding:12,marginTop:10,display:"none"}}}>
                                    <Div style={{fontSize:12,fontWeight:"bold",marginBottom:6,}}>{ctaTitle}</Div>
                                    <Div style={{fontSize:12,fontWeight:"bold",borderRadius:12,border:"1px solid black"}}>{ctaSubtitle}</Div>
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                        }
                    </Div>
              </Div>
          </Div>
      );
  }

    renderEvent(event) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let eventDate, eventPassed, until, eventDateText;
        let eventStartTimeFormatted = "";
        let eventEndTimeFormatted = "";

        let alphaletters = /[A-Z]/;
        //console.log("MATCH", event['event_date'], event['event_date'].match(alphaletters));
        if(event['event_date'] && event['event_date'].match(alphaletters)) {
            eventDateText = event['event_date'];
        } else if(event['event_date']) {
            const eventParts = event['event_date'].split('-');
            eventDate = moment([eventParts[0], eventParts[1]-1, eventParts[2], event['event_start_time'], 0, 0]);
            const now = moment();
            // Check if it is a day past
            const eventDate2 = moment([eventParts[0], eventParts[1]-1, parseInt(eventParts[2])+1, event['event_start_time'], 0, 0]);
            eventPassed = now > eventDate2 ? true : false;
            until = Utils.calcTimeStr(now, eventDate, "dhm_short");

            const eventStartTime = Utils.stringToHoursAndMinutes(event['event_start_time']);
            const eventEndTime = Utils.stringToHoursAndMinutes(event['event_end_time']);

            // eventStartTimeFormatted = moment.utc([
            eventStartTimeFormatted = moment([
                eventParts[0],
                eventParts[1] - 1,
                eventParts[2],
                eventStartTime[0],
                eventStartTime[1],
                0,
            ]).local().format("LT");

            // eventEndTimeFormatted = moment.utc([
            eventEndTimeFormatted = moment([
                eventParts[0],
                eventParts[1] - 1,
                eventParts[2],
                eventEndTime[0],
                eventEndTime[1],
                0,
            ]).local().format("LT");
        }

        const ctas = [
            {title:"Wait List", subtitle:"+ Join", gradient:this.styles.waitingList},
            {title:"Open", subtitle:"RSVP", gradient:this.styles.openList},
            {title:"In-progress", subtitle:"+ Join", gradient:this.styles.inprogressList},
            {title:"Over", subtitle:"Watch", gradient:this.styles.overList},
        ];
        const idx = event['id'] % ctas.length;
        const ctaTitle = ctas[idx]['title'];
        const ctaSubtitle = ctas[idx]['subtitle'];
        const gradient = ctas[idx]['gradient'];

        // Responsive styles
        const eventRow = StyleUtils.getMediaStyle('eventRow', sty.eventRow, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const eventImage = StyleUtils.getMediaStyle('eventImage', sty.eventImage, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventRowTitle = StyleUtils.getMediaStyle('eventRowTitle', sty.eventRowTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const eventRowDate = StyleUtils.getMediaStyle('eventRowDate', sty.eventRowDate, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const eventDataOverlay = StyleUtils.getMediaStyle('eventDataOverlay', sty.eventDataOverlay, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventTitle = StyleUtils.getMediaStyle('eventTitle', sty.eventTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const eventTime = StyleUtils.getMediaStyle('eventTime', sty.eventTime, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const dayAndMonth = StyleUtils.getMediaStyle('dayAndMonth', sty.dayAndMonth, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const rsvpButtonContainer = StyleUtils.getMediaStyle('rsvpButtonContainer', sty.rsvpButtonContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        const imageUrl = event['image_thumbnail_url'] && event['image_thumbnail_url'].length > 0 ? event['image_thumbnail_url'] : event['image_url'];
        return (
            <Div className="eventRow" style={{...eventRow, ...StylesPlatform.cursorPointer}} key={`event-row-${event['id']}`} onClick={this.handleEventClick.bind(this, event)}>
                <Div className="eventImage" style={{...eventImage, ...{backgroundImage: `url(${imageUrl})`}}}>
                    <Div className="eventDataOverlay" style={eventDataOverlay}>
                        {eventDate ? eventDate.format('MMM').toUpperCase() : 'Coming soon'} {eventDate ? eventDate.format('D') : ''}
                    </Div>
                </Div>
                <Div className="eventRowTitle" style={eventRowTitle}>
                    <Div className="eventTitle" style={eventTitle}>
                        {event['title']}
                    </Div>
                    {!eventPassed ?
                        <Div style={{fontSize: 14}}>{parseInt(event['seats_total']-event['customer_rsvps'])} seats available</Div>
                        :
                        null
                    }
                    <Div style={{fontSize: 14}}>{event['event_requirements']}</Div>
                </Div>
                <Div className="eventRowDate" style={sty.eventRowDate}>
                    {eventDate ?
                        <>
                            {!pr.screen.mobile ?
                                <Div className="dayAndMonth" style={dayAndMonth}>
                                    {eventDate ? eventDate.format('MMMM').toUpperCase() : ''} {eventDate ? eventDate.format('Do, ddd') : ''}
                                </Div>
                                :
                                null
                            }

                            <Div className="eventTime" style={eventTime}>
                                {/* {Utils.stringToHoursAndMinutes(event['event_start_time'])} - {Utils.stringToHoursAndMinutes(event['event_end_time'])} PST */}
                                {pr.screen.mobile ? eventDate.format('dddd') : null} <Div className="eventTimeFormatted" style={sty.eventTimeFormatted}>{eventStartTimeFormatted} - {eventEndTimeFormatted}</Div>
                                {/* {eventDate.format('HH:mm:SS')} - {Utils.stringToHoursAndMinutes(event['event_end_time'])} PST */}
                            </Div>
                            {false && !eventPassed ?
                                <Div className="eventStartsIn" style={{fontSize:14,}}>Starts in {until}</Div>
                                :
                                null
                            }
                        </>
                        :
                        eventDateText ?
                            <Div className="eventDateText" style={ {...dayAndMonth, ...{fontSize:20} } }>
                                {eventDateText}
                            </Div>
                            :
                            null
                    }

                </Div>
                <Div style={rsvpButtonContainer}>
                    { !eventPassed ?
                        <Button color="purpleLight1" style={{minWidth: 0, width: "100%", margin: "0 auto"}}>RSVP</Button>
                        :
                        <Div style={{width: 140}}></Div> // NOTE needed for spacing if RSVP button is hidden
                    }
                </Div>

                <Div className="ctaTitleContainer" style={{flex:2, display:"none",}}>
                    <Div style={{...gradient,...{padding:12,marginTop:42,}}}>
                        <Div style={{fontSize:14,fontWeight:"bold",marginBottom:6,}}>{ctaTitle}</Div>
                        <Div style={{fontSize:14,fontWeight:"bold",borderRadius:12,border:"1px solid black"}}>{ctaSubtitle}</Div>
                    </Div>
                </Div>
            </Div>
        );
    }

  renderRegisterCta() {
      return (
          <Div className="registerCtaContainer" style={{display:"flex", justifyContent:"center", paddingLeft:10,paddingRight:10, paddingTop: 20}}>
            <Div onClick={this.handleRegisterClick.bind(this, "afterpartynft")} style={{...Styles.btnWhiteOutline,...{fontWeight:"bold",fontSize:18,paddingLeft:20,paddingRight:20}}}>
                Click to Unlock the Afterparty Festival NFT
            </Div>
          </Div>
      )
  }

  renderEvents() {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      if(!pr.events) {
          return (
              <Spinner style={{height: 400}} message="Loading Events..." />
          );
      }
      if(pr.events.length == 0) {
          return (
              <Div style={Styles.sectionHead}>No Upcoming Events for those settings</Div>
          );
      }
      let currentEvents = [];
      let pastEvents = [];

      const now = moment();
      for(var idx in pr.events) {
          const event = pr.events[idx];
          let eventPassed = false;
          if(event['event_date']) {
              const eventParts = event['event_date'].split('-');
              const eventDate = moment([eventParts[0], eventParts[1]-1, eventParts[2], event['event_start_time'], 0, 0]);
              // Check if it is a day past
              const eventDate2 = moment([eventParts[0], eventParts[1]-1, parseInt(eventParts[2])+1, event['event_start_time'], 0, 0]);
              //console.log("ED", event['title'], eventDate2);
              eventPassed = now > eventDate2 ? true : false;
          }
          if(idx == 0) {
              continue;
          } else if(!eventPassed) {
              currentEvents.push(event);
          } else {
              pastEvents.push(event);
          }
      }

      const eventsSectionHeadText = StyleUtils.getMediaStyle('eventsSectionHeadText', sty.eventsSectionHeadText, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
      const upcomingEventsTitleRow = StyleUtils.getMediaStyle('upcomingEventsTitleRow', sty.upcomingEventsTitleRow, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
      const notifyMeButton = StyleUtils.getMediaStyle('notifyMeButton', sty.notifyMeButton, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

      return (
          <Div className="eventsContainer">
              { false ?
                  <Div style={{display:"flex", marginTop: 30,}}>
                      <Div style={{...Styles.sectionHead2,...{flex:4}}}>Upcoming Events</Div>
                      <Div>
                          <Div style={sty.filterContainer}>
                            <Div>Timezone <i className="fa fa-angle-down"></i></Div>
                          </Div>
                          <Div style={sty.filterContainer}>
                            <Div>Genre <i className="fa fa-angle-down"></i></Div>
                          </Div>
                          <Div style={sty.filterContainer}>
                            <Div>All events <i className="fa fa-angle-down"></i></Div>
                          </Div>
                      </Div>
                  </Div>
                  :
                  null
              }

              <Div className="upcomingEventsTitleRow" style={upcomingEventsTitleRow}>
                <Div
                    className="upcomingEventsText"
                    style={{...Styles.sectionHead2, ...eventsSectionHeadText, ...{flex:"none"} }}
                >
                    Upcoming Events
                </Div>
                <Button
                    onClick={this.handleRegisterClick.bind(this, "notify")}
                    color="pink"
                    size="small"
                    style={notifyMeButton}
                >
                    <Div style={{marginLeft: "auto", position: "relative"}}>Notify Me</Div>
                    <Div style={{marginRight: "auto", marginLeft: 8}}>
                        <Lottie
                            height={20}
                            width={20}
                            options={{
                                animationData: bellLottie,
                                loop: st.loopBell,
                                autoplay: true,
                            }}
                        />
                    </Div>
                </Button>
              </Div>
              <Div style={{marginTop: pr.screen.width > 450 ? 38 : 20}}>
                {currentEvents.length ?
                    currentEvents.map( (event, idx) => {
                        return this.renderEvent(event);
                    })
                    :
                    <Div style={{fontSize:18, marginBottom:35}}>Announcing more next week!</Div>
                }
              </Div>
              <Div className="previousEventsText" style={{...Styles.sectionHead2,...eventsSectionHeadText}}>
                  Previous Events
              </Div>
              <Div style={{marginTop: pr.screen.width > 450 ? 38 : 20}}>
                {pastEvents.map( (event, idx) => {
                        return this.renderEvent(event);
                    })
                }
              </Div>
          </Div>
      );
  }

    renderCreatorRegisterCta() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const registerCtaContainer = StyleUtils.getMediaStyle('registerCtaContainer', sty.registerCtaContainer, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="creatorRegistserCtaContainer" style={registerCtaContainer}>
                <Div style={sty.registerCtaLeftCol}>
                    <Div style={sty.registerCtaTitle}>
                        Want to host your own event?
                    </Div>
                    <Div style={sty.registerCtaSubtitle}>
                        We're slowly growing access to host Afterparty Events.
                    </Div>

                </Div>
                <Div style={sty.registerCtaRightCol}>
                    <Button
                        style={{marginTop: 20}}
                        color="pink"
                        size="small"
                        onClick={this.handleRegisterClick.bind(this, "waitlist")}
                    >
                        Join Waitlist
                    </Button>
                    <Div style={{marginTop:10}}>534 people on the waitlist</Div>
                </Div>
            </Div>
        );
    }


  render() {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

    const events = pr.events && pr.events.length > 0 ? pr.events : [];

    const upcomingEventsContainer = StyleUtils.getMediaStyle('upcomingEventsContainer', sty.upcomingEventsContainer, sty, StyleUtils.getWidthType(pr.screen.width));

    return (
      <Div className="afterpartyUpcomingEventsComponent" style={sty.afterpartyUpcomingEventsComponent}>

            {this.renderHeroEvent(events)}
            <canvas id="my-canvas" style={{...sty.confetti, ...{height: st.confettiHeight, width: st.confettiWidth}}}></canvas>

            <DebugBox show={st} />
            {st.testMode ?
                <Div>Width: {pr.screen.width} height:{pr.screen.height} WT: {pr.screen.widthType}</Div>
                :
                null
            }
            {false ?
                this.renderRegisterCta()
                :
                null
            }

            <Div id="upcomingEventsContainer" className="upcomingEventsContainer" style={upcomingEventsContainer}>
                {this.renderEvents()}
            </Div>

            {this.renderCreatorRegisterCta()}

            {st.showModal == 1 ?
                <ModalRegister
                    screen={pr.screen}
                    title={st.registerModelTitle}
                    onRequestClose={this.closeRegisterModal.bind(this)}
                    fields="username,email,password,wallet"
                    getNewUserAfterRegisterSuccess={this.handleRegisterSuccess.bind(this)}

                />
                :
                null
            }
            {st.showModal == 2 ?
                <ModalFestival2021a
                    screen={pr.screen}
                    current_user_afterpartynft={pr.current_user_afterpartynft}
                    user={pr.user}
                    onRequestClose={this.closeRegisterModal.bind(this)}
                />
                :
                null
            }
            {st.showModal == 3 ?
                <Modal
                    screen={pr.screen}
                    user={pr.user}
                    onRequestClose={this.closeRegisterModal.bind(this)}
                >
                    <Div style={Styles.subhead}>You've been added to the Waitlist</Div>
                    <Div style={{marginBottom:40}}>We'll contact your soon</Div>
                </Modal>
                :
                null
            }
            {st.showModal == 4 ?
                <Modal
                    screen={pr.screen}
                    user={pr.user}
                    onRequestClose={this.closeRegisterModal.bind(this)}
                >
                    <Div style={Styles.subhead}>Thanks!</Div>
                    <Div style={{marginBottom:40}}>We'll notify you soon!</Div>
                </Modal>
                :
                null
            }
      </Div>
    );
  }
  styles = {
    afterpartyUpcomingEventsComponent: {
      color: "black",
      textAlign: "left",
      fontFamily: "'Montserrat', sans-serif",
      width: "100%",
      background: "#FBF8F4",
      minHeight: 800,
      paddingBottom: 40,
    },
    leftCol: {
      minHeight: 850,
      maxWidth: 220,
      backgroundColor:"white",
      color: "black",
      paddingLeft: 0,
      paddingRight: 0,
    },
    leftColBody: {
      paddingTop: 20,
      justifyContent: "center",
      display: "flex",
      flexDirection:"column",
    },
    notifyMeButton: {
        width: 150,
        justifyContent: "center",
        marginLeft: 0,
        marginRight: 0,
        zIndex: 102,
    },
    notifyMeButton_sm: {
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 102,
    },

    heroBannerImage: {
        overflow:"hidden",
        backgroundSize: "cover",
        width: 525,
        height: 300,
        color: "#f5f5f5",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundRepeat: "no-repeat",
    },
    heroBannerImage_md: {
        height: 200,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    heroBannerImage_sm: {
        height: 250,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    heroBannerImage_xs: {
        height: 120,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },

    upcomingEventsTitleRow: {
        display: "flex",
        alignItems: "center",
        marginTop: 20,
        marginBottom: 30,
    },
    upcomingEventsTitleRow_sm: {
        display: "block",
    },

    eventsSectionHeadText: {
        flex: 4,
        marginRight: 60,
    },
    eventsSectionHeadText_sm: {
        marginBottom: 10,
    },
    eventTimeFormatted: {
        color: "#aaa",
        marginLeft: 5,
    },

    upcomingEventsContainer: {
        padding: "20px 75px",
    },
    upcomingEventsContainer_md: {
        padding: 20,
    },
    upcomingEventsContainer_sm: {
        padding: 15,
    },
    upcomingEventsContainer_xs: {
        padding: 15,
    },
    btnLarge: {
        height: 64,
        fontSize: 18,
        paddingTop: 18,
        borderRadius: 12,
    },
    waitingList: {
        width: 100,
        backgroundColor:"#FBE7F1", color:"black", textAlign:"center",borderRadius:6,
    },
    openList: {
        width: 100,
        backgroundColor:"pink", color:"black", textAlign:"center",borderRadius:6,
    },
    inprogressList: {
        width: 100,
        backgroundColor:"lavender", color:"black", textAlign:"center",borderRadius:6,
    },
    overList: {
        width: 100,
        backgroundColor:"salmon", color:"black", textAlign:"center",borderRadius:6,
    },
    filterContainer: {
        backgroundColor: "#F2EFFC",
        color: "#CBC7EA",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 30,
        borderRadius: 8,
        fontWeight: "bold",
    },
    registerCtaContainer: {
        borderRadius: 20,
        backgroundColor: Colors.purpleLight4,
        display: "flex",
        marginTop:0,
        marginLeft:20,
        marginBottom:40,
        marginRight:20,
    },
    registerCtaContainer_sm: {
        flexDirection: "column",
    },
    registerCtaContainer_xs: {
        flexDirection: "column",
    },
    registerCtaLeftCol: {
        color: Colors.purpleDark,
        padding: 40,
        paddingTop: 30,
        flex: 9,
    },
    registerCtaTitle: {
        fontSize: 36,
        fontWeight: "bold",
        marginBottom: 10,
    },
    registerCtaSubtitle: {
        fontSize: 18,
    },
    registerCtaRightCol: {
        padding: 40,
        flex: 3,
        textAlign: "center",
    },
    heroEventContainer: {
        position: "relative",
    },
    eventRow: {
        display: "flex",
        alignItems: "center",
        marginBottom: 60,
        marginLeft: 0,
        position: "relative",
    },
    eventRow_sm: {
        flexDirection: "column",
        // borderBottom: "2px solid gray",
        alignItems: "flex-start",
        // marginBottom: 44,
        marginBottom: 0,
        marginLeft: 0,
    },
    eventImage: {
        borderRadius: 10,
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 150,
        width: 190,
        color: "#f5f5f5",
        marginRight: 30,
        position: "relative",
    },
    eventImage_xs: {
        height: 170,
        width: "100%",
        marginRight: 0,
        backgroundPosition: "top",
        borderRadius: 18,
    },
    eventDataOverlay: {
        position: "absolute",
        display: "none",
        right: 10,
        bottom: 10,
        borderRadius: 12,
        color: Colors.purple,
        backgroundColor: "white",
        paddingTop: 10,
        paddingRight: 20,
        paddingBottom: 10,
        paddingLeft: 20,
        fontWeight: "bold",
        fontSize: 20,
    },
    eventDataOverlay_xs: {
        display: "block",
    },
    eventRowTitle: {
        flex: 4.8,
        padding: "0 20px 0 0",
        zIndex:50,
    },
    eventRowTitle_sm: {
        paddingTop: 10,
        paddingRight: 0,
        paddingBottom: 10,
        paddingLeft: 0,
    },
    eventRowDate: {
        flex: 3,
        paddingRight: 20,
    },
    eventRowDate_sm: {
    },
    eventTime: {
        display: "flex",
        fontSize: 16,
    },
    eventTitle: {
        fontSize: 28,
        fontWeight: "bold",
        marginBottom: 0,
    },
    eventTitle_sm: {
        fontSize: 24,
        marginBottom: 5,
    },
    dayAndMonth: {
        fontSize: 28,
        fontWeight: "bold"
    },
    dayAndMonth_sm: {
        fontSize: 24,
    },
    rsvpButtonContainer: {
        display: "flex",
        alignItems: "center",
        width: 140,
        zIndex: 101,
    },
    rsvpButtonContainer_sm: {
        width: "100%",
        padding: "20px 50px",
        zIndex: 101,
    },
    confetti: {
        position:"absolute",
        width: "100%",
    }
  };
}
