import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';

import ReactPlayer from "react-player";

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';
import Spinner from '../elements/Spinner';
import DebugBox from '../elements/DebugBox';

import Div from './Div';
import Login from './Login';
import ConnectWallet from './ConnectWallet';
import WalletTable from './WalletTable';
import ProfileMenu from './ProfileMenu';
import ModalRegister from './ModalRegister';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';

export default class AfterpartyEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            showModal: false,
        };
    }

  closeRegisterModal() {
      this.setState({showModal: false});
  }


    backToEvents() {
        const pr = this.props;
        pr.history.push('/home');
        pr.change('home');
    }
    handleRegisterSuccess(newUser) {
        console.log("Show modal 2");
        this.setState({showModal:2});
        return true;
    }

      handleRegisterClick(type, event) {
          let title = "Register to Join Waitlist";
          let modalNum = 1;
          // If they are already registered and logged in, display second dialog
          if(Utils.get(this.props, "user.email")) {
              modalNum = 2;
                const data = {id:997};
                Api.postRsvp(data, (response) => {
                });
          } else {
              modalNum = 1;
          }
          this.setState({
              registerType: type,
              registerModelTitle: title,
              showModal: modalNum,
          });
      }

    renderImageCol() {
        const thumbnail = "/webapp/static/media/drops_transparent.22c9bc05.gif";
        const nftRender = "/images/ap-nft-spin.1.mp4";
        const imageWidth = this.props.screen.width < 960 ? this.props.screen.width : 550;
        const borderColor = {};
        return (
                <Div className='' style={{...this.styles.posterContainer, ...borderColor}}>
                    <ReactPlayer
                        type="video/mp4"
                        url={nftRender}
                        width={imageWidth+"px"}
                        height={imageWidth+"px"}
                        playing={true}
                        controls={false}
                        loop={true}
                        muted={true}
                      />
                </Div>
        )

    }

    renderInfoCol() {
        return (
            <Div style={{flex:1, padding:40,}}>
                <DebugBox
                    show={this.state}
                    />

                <Div style={{...Styles.headline,...{marginBottom:20} }}>Creator NFTs</Div>
                <Div style={{marginBottom:20, fontSize:18}}>Coming Soon!</Div>
                {this.renderCreatorRegisterCta()}
            </Div>
        )

    }

    renderCreatorRegisterCta() {
        const sty = this.styles;
        const registerCtaContainer = StyleUtils.getMediaStyle('registerCtaContainer', sty.registerCtaContainer, sty, StyleUtils.getWidthType(this.state.width));

        return (
            <Div style={registerCtaContainer}>
                <Div style={sty.registerCtaLeftCol}>
                    <Div style={sty.registerCtaTitle}>
                        Want to issue an Afterparty Creator NFT?
                    </Div>
                    <Div style={sty.registerCtaSubtitle}>
                        Sign up and we'll contact you about creating your own.
                    </Div>

                </Div>
                <Div style={sty.registerCtaRightCol}>
                    <Div onClick={this.handleRegisterClick.bind(this, "waitlist")} style={{...Styles.btnMagenta,...{marginTop:20,}}}>Join Waitlist</Div>
                </Div>
            </Div>
        );
    }


    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const container = StyleUtils.getMediaStyle('container', sty.container, sty, StyleUtils.getWidthType(pr.screen.width));
        return (
            <Div style={container}>
                {this.renderImageCol()}
                {this.renderInfoCol()}
                <Div>
                    {this.state.showModal == 1 ?
                        <ModalRegister
                            screen={pr.screen}
                            title={this.state.registerModelTitle}
                            onRequestClose={this.closeRegisterModal.bind(this)}
                            fields={"username,email,password,wallet"}
                            getNewUserAfterRegisterSuccess={this.handleRegisterSuccess.bind(this)}

                        />
                        :
                        null
                    }
                    {this.state.showModal == 2 ?
                        <Modal
                            screen={pr.screen}
                            user={this.props.user}
                            onRequestClose={this.closeRegisterModal.bind(this)}
                        >
                            <Div style={Styles.subhead}>You've been added to the Waitlist</Div>
                            <Div style={{marginBottom:40}}>We'll contact your soon</Div>
                        </Modal>
                        :
                        null
                    }
                </Div>
            </Div>
        )
    }

    styles = {
        container: {
            display: "flex",
        },
        container_sm: {
            display: "flex",
            flexDirection:"column",
        },
        container_xs: {
            display: "flex",
            flexDirection:"column",
        },
        registerCtaContainer: {
            borderRadius: 20,
            backgroundColor: Colors.purpleLight4,
            display: "flex",
            marginBottom:40,
        },
        registerCtaContainer_sm: {
            flexDirection: "column",
        },
        registerCtaContainer_xs: {
            flexDirection: "column",
        },
        registerCtaLeftCol: {
            color: Colors.purpleDark,
            padding: 40,
            paddingTop: 30,
            flex: 9,
        },
        registerCtaTitle: {
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 10,
        },
        registerCtaSubtitle: {
            fontSize: 18,
        },
        registerCtaRightCol: {
            padding: 40,
            paddingTop:0,
            flex: 3,
            textAlign: "center",
        },
        posterContainer: {
            position:"relative",
            borderRadius: 20,
            overflow: "hidden",
        },
    };
}
