import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import ll from '../modules/ll';

import Input from '../elements/Input';
import ErrorBox from '../elements/ErrorBox';
import Button from '../elements/Button';

import WalletTable from './WalletTable';
import Div from './Div';

export default class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1,
            },
            croppedBlob: null,
            croppedImageUrl: null,
            imageUploadSuccess: false,

            usernameRegistration: Utils.get(props, "user.username", ""),
            emailRegistration: Utils.get(props, "user.email", ""),
            phoneRegistration: Utils.get(props, "user.primary_phone1", ""),
            verifyPhoneRegistration: "",
            passwordRegistration: "",
            retypePasswordRegistration: "",

            isRegisterError: false,
            isRegisterSuccess: false,
            errorMessages: [],

            walletAccounts: [],
        };
        let fieldList = props.fields ? props.fields : "username,email,phone,password,wallet"; // ,avatar
        this.state.fields = fieldList.split(",");

    }

    uploadImage() {
        const st = this.state;

        let formData = new FormData();
        const croppedFile = new File([st.croppedBlob], st.croppedBlob.name, {
            type: st.croppedBlob.type,
        });

        formData.append("File", croppedFile);
        formData.append("Filetype", croppedFile.type);
        formData.append("Size", croppedFile.size);
        formData.append("lastModifiedDate", croppedFile.lastModifiedDate);

        // ll._("File >>>", croppedFile);
        // ll._("Filetype >>>", croppedFile.type);
        // ll._("Size >>>", croppedFile.size);
        // ll._("lastModifiedDate >>>", croppedFile.lastModifiedDate);
        //
        // ll._("uploadImage::formData >>>", formData);

        Api.postProfileImage(formData, (response) => {
            // ll._("postProfileImage::response >>>", response);

            if(!response || (response && response.errors.length > 0)) {
                this.setState({errorMessages: Utils.get(response, "errors", null)});
            } else {
                this.setState({
                    imageUploadResponse: response.data,
                    imageUploadSuccess: true,
                });
            }
        });
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                // console.log("e.target.files[0] >>>", e.target.files[0]);
                this.setState({
                    src: reader.result,
                    selectedFile: e.target.files[0],
                    imageUploadSuccess: false,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({crop: crop});
    };

    async makeClientCrop(crop) {
        // console.log("makeClientCrop >>>", crop);
        if(this.imageRef && crop.width && crop.height) {
            // console.log("this.imageRef >>>", this.imageRef);
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "cropped_" + this.state.selectedFile.name,
            );
            this.setState({croppedImageUrl: croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const st = this.state;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                blob.lastModifiedDate = new Date();
                this.setState({croppedBlob: blob});
                window.URL.revokeObjectURL(this.fileUrl);
                // console.log("blob >>>", blob);
                // var reader = new FileReader();
                // reader.readAsDataURL(blob);
                // reader.onloadend = () => {
                //     var base64data = reader.result;
                //     this.setState({image_data: base64data});
                //     // console.log("base64data >>> ", base64data);
                // }
                // console.log("blob >>>", blob);

                // var formData = new FormData();
                // formData.append("image", blob);

                // formData.append("File", base64data);
                // formData.append("Filetype", blob.type);
                // formData.append("Size", blob.size);
                // formData.append("lastModifiedDate", blob.lastModifiedDate);

                // console.log("formData >>>", formData.get("image"));
                // this.setState({form_data: formData});

                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    usernameRegistrationChange(e) {
        this.setState({usernameRegistration: e.target.value});
    }

    emailRegistrationChange(e) {
        this.setState({emailRegistration: e.target.value});
    }

    phoneRegistrationChange(e) {
        this.setState({phoneRegistration: e.target.value});
    }

    verifyPhoneRegistrationChange(e) {
        this.setState({verifyPhoneRegistration: e.target.value});
    }

    passwordRegistrationChange(e) {
        this.setState({passwordRegistration: e.target.value});
    }

    retypePasswordRegistrationChange(e) {
        this.setState({retypePasswordRegistration: e.target.value});
    }

    throwError(errorMessage) {
        const errors = [{
            id: 234324,
            message: errorMessage,
        }];

        this.setState({
            isRegisterError: true,
            isRegisterSuccess: false,
            errorMessages: errors,
        });
    }

    handleSaveOrRegister() {
        const pr = this.props;
        const st = this.state;

        // NOTE if there is a cropped image present, fail validation
        if(st.croppedImageUrl && !st.imageUploadSuccess) {
            this.throwError("");
            return;
        }

        // NOTE password validation
        if(st.passwordRegistration.length < 4 || st.passwordRegistration !== st.retypePasswordRegistration) {
            this.throwError("Passwords must match and be greater then 4 characters");
            return;
        }

        // NOTE phone validation
        if(st.phoneRegistration.length < 10) {
            this.throwError("Phone number is required");
            return;
        }

        // NOTE phone validation
        if(st.phoneRegistration !== st.verifyPhoneRegistration) {
            this.throwError("Phone numbers must match");
            return;
        }

        let user = {
            username: st.usernameRegistration,
            email: st.emailRegistration,
            primary_phone1: st.phoneRegistration,
            password: st.passwordRegistration,
            retypepassword: st.retypePasswordRegistration,
            format: "json",
        };

        if(Utils.get(st.imageUploadResponse, "fname")) {
            user.photo = `/images/user_avatars/${st.imageUploadResponse.fname}`;
        } else if(pr.user) {
            user.photo = pr.user.photo;
        }

        if(pr.isProfile) {
            Api.putProfile(user, (response) => {
                if(Utils.get(response, "errors", "").length) {
                    const errors = response.errors;
                    const regErrors = {
                        isRegisterError: true,
                        isRegisterSuccess: false,
                        errorMessages: errors,
                    };
                    console.log(regErrors);

                    this.setState(regErrors);
                } else {
                    if(Utils.getRequest('v') == 3) {
                        window.location.href = "/v3/index.html?show=1";
                        return;
                    } else {
                        window.location.reload();
                    }
                    // console.log("user updated:", response);
                    // const registerMessage = {
                    //     message: "Update success",
                    //     type: "success",
                    //     subMessage: "You have updated your profile.",
                    // };
                    // Utils.setCookie("flashMessage", JSON.stringify(registerMessage));
                    // this.setState({
                    //     isRegisterError: false,
                    //     isRegisterSuccess: true,
                    //     errorMessages: [],
                    // }, () => {
                    //     pr.getNewUserAfterRegisterSuccess(response.data);
                    // });
                }
            });
        } else {
            for(let i = 0; i < st.walletAccounts.length; ++i) {
                user[`wallet_id_${i + 1}`] = st.walletAccounts[i];
            }

            Api.postRegister(user, (response) => {
                if(Utils.get(response, "errors", "").length) {
                    const errors = response.errors;
                    const regErrors = {
                        isRegisterError: true,
                        isRegisterSuccess: false,
                        errorMessages: errors,
                    };
                    console.log(regErrors);

                    this.setState(regErrors);
                } else {
                    console.log("new user created:", response);
                    if(Utils.getRequest('v') == 3) {
                        window.location.href = "/v3/index.html?show=1";
                        return;
                    }

                    const registerMessage = {
                        message: "Registration success",
                        type: "success",
                        subMessage: "Please login to use your account."
                    };
                    Utils.setCookie("flashMessage", JSON.stringify(registerMessage));
                    this.setState({
                        isRegisterError: false,
                        isRegisterSuccess: true,
                        errorMessages: [],
                    }, () => {
                        pr.getNewUserAfterRegisterSuccess(response.data);
                        if(pr.history) {
                            pr.history.push('/login');
                            pr.change('login');
                        }
                    });
                }
            });
        }
    }

    handleSetWallet(accounts) {
        this.setState({walletAccounts: accounts});
    }

    goToSignIn() {
        const pr = this.props;

        pr.history.push("/login");
        pr.change("login");
    }

    renderSuccessMessage() {
        return (
            <Div style={{color: "green", textAlign: "center", marginTop: 20}}>
                User successfully registered!
            </Div>
        )
    }

    renderImageUploadSuccessMessage() {
        return (
            <Div style={{color: "green", textAlign: "center", marginTop: 9, marginBottom: 20}}>
                Image uploaded successfully!
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const hideWallet = true; // pr.screen && pr.screen.width < 768 ? true : false;

        // console.log("croppedImageUrl >>>", st.croppedImageUrl);

        return (
            <Div className="registerComponent" style={{...sty.container, ...pr.style}}>
                <Div
                    className="registerTitle"
                    style={Styles.purpleHeadline}
                >
                    {pr.title ? pr.title : "Registration"}
                </Div>

                <Div
                    className="registerSubtitle"
                    style={sty.registerSubtitle}
                >
                    Already registered? <Button type="text" style={{marginLeft: 5}} onClick={this.goToSignIn.bind(this)}>Sign in</Button>
                </Div>

                {st.fields.indexOf("avatar") != -1 && false ?
                    <Div style={{display: "flex", flexDirection: "column"}}>
                        <label className="fileUploadContainer" style={sty.chooseFileButton}>
                            Choose Profile Picture
                            <input
                                style={sty.fileUploadInput}
                                type="file"
                                accept="image/*"
                                onChange={this.onSelectFile.bind(this)}
                            />
                        </label>
                        {st.src ?
                            <ReactCrop
                                src={st.src}
                                crop={st.crop}
                                style={{width: 500, marginBottom: 15}}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                            :
                            null
                        }

                        {st.croppedImageUrl ?
                            <img alt="Crop" style={sty.croppedImage} src={st.croppedImageUrl} />
                            :
                            null
                        }

                        {st.croppedImageUrl && !st.imageUploadSuccess ?
                            <Button style={sty.uploadImageButton} onClick={this.uploadImage.bind(this)}>
                                Upload Profile Picture
                            </Button>
                            :
                            null
                        }

                        {st.imageUploadSuccess ? this.renderImageUploadSuccessMessage() : null}
                    </Div>
                    :
                    null
                }


                {st.fields.indexOf("username") != -1 ?
                    <Input
                        type="text"
                        placeholder="Username"
                        value={st.usernameRegistration}
                        onChange={this.usernameRegistrationChange.bind(this)}
                    />
                    :
                    null
                }

                {st.fields.indexOf("email") != -1 ?
                    <Input
                        type="email"
                        placeholder="Email"
                        value={st.emailRegistration}
                        onChange={this.emailRegistrationChange.bind(this)}
                        readOnly={pr.isProfile}
                    />
                    :
                    null
                }

                {st.fields.indexOf("phone") != -1 ?
                    <Div style={this.styles.twoColumn} >
                        <Input
                            type="tel"
                            placeholder="Phone Number"
                            style={{marginRight: 10}}
                            value={st.phoneRegistration}
                            onChange={this.phoneRegistrationChange.bind(this)}
                        />
                        <Input
                            type="tel"
                            placeholder="Repeat Phone"
                            style={{marginLeft: 10}}
                            value={st.verifyPhoneRegistration}
                            onChange={this.verifyPhoneRegistrationChange.bind(this)}
                        />
                    </Div>
                    :
                    null
                }

                {st.fields.indexOf("password") != -1 ?
                    <Div style={this.styles.twoColumn} >
                        <Input
                            type="password"
                            placeholder="Password"
                            style={{marginRight: 10}}
                            value={st.passwordRegistration}
                            onChange={this.passwordRegistrationChange.bind(this)}
                        />
                        <Input
                            type="password"
                            placeholder="Repeat Password"
                            style={{marginLeft: 10}}
                            value={st.retypePasswordRegistration}
                            onChange={this.retypePasswordRegistrationChange.bind(this)}
                        />
                    </Div>
                    :
                    null
                }

                {st.fields.indexOf("wallet") != -1 && !hideWallet ?
                    <WalletTable
                        screen={pr.screen}
                        handleSetWallet={this.handleSetWallet.bind(this)}
                    />
                    :
                    null
                }

                {st.isRegisterError ?
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        style={{paddingTop: 0, paddingBottom: 15}}
                    />
                    :
                    null
                }

                {st.isRegisterSuccess ? this.renderSuccessMessage() : null}

                {/* <a target="_blank" href={`https://conner.soindrop.com/images/user_avatars/${st.imageUploadResponse ? st.imageUploadResponse.fname : null}`}>image</a> */}

                <Button
                    className="registerSaveButton"
                    style={{width: "fit-content", marginLeft: "auto", marginRight: "auto", marginBottom: 30, marginTop: 20}}
                    onClick={this.handleSaveOrRegister.bind(this)}
                >
                    {pr.isProfile ? "Save" : "Register"}
                </Button>
            </Div>
        );
    }

    styles = {
        container: {
            maxWidth: 400,
        },
        registerSubtitle: {
            display: "flex",
            justifyContent: "center",
            color: Colors.pink,
            marginTop: -10,
            marginBottom: 20,
        },
        connectWallet: {
            display: "flex",
            alignItems: "center",
        },
        button: {
            width: 200,
        },
        fileUploadInput: {
            display: "none",
        },
        chooseFileButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: Colors.white,
            backgroundColor: Colors.pink,
            border: "none",
            boxSizing: "border-box",
            cursor: "pointer",
            padding: "15px 20px 14px",
            borderRadius: 14,
            fontSize: 16,
            width: 220,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 15,
        },
        croppedImage: {
            display: "flex",
            width: 250,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 15,
        },
        uploadImageButton: {
            marginBottom: 15,
            marginLeft: "auto",
            marginRight: "auto",
        },
        twoColumn: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: 400,
            width: "100%"
        },
    };
}
