import Utils from './Utils';

export default {
    minLength(text, minimum, errorField) {
        let validateTextResponse = {
            success: 0,
            errors: [],
        };

        const errorFieldText = errorField ? errorField : "Text";
        const minLengthText = minimum ? minimum : 3;

        if(text.length < minLengthText) {
            const errorMessageObject = Utils.addErrorSuccessMessage(236574, `${errorFieldText} must be at least 3 characters.`, true);
            validateTextResponse.errors.push(errorMessageObject)
            return validateTextResponse;
        }

        validateTextResponse.success = 1;

        return validateTextResponse;
    },
    email(emailText, returnOut, returnAll) {
        let validateEmailResponse = {
            success: 0,
            errors: [],
        };

        if(emailText.length < 3) {
            const errorMessageObject = Utils.addErrorSuccessMessage(236574, "Email must be at least 3 characters.", true);
            validateEmailResponse.errors.push(errorMessageObject)
            if(!returnAll) {
                return validateEmailResponse;
            }
        }
        if(!emailText.includes("@")) {
            const errorMessageObject = Utils.addErrorSuccessMessage(5487349, "Invalid email address.", true);
            validateEmailResponse.errors.push(errorMessageObject)
            if(!returnAll) {
                return validateEmailResponse;
            }
        }
        if(emailText.includes(" ")) {
            const errorMessageObject = Utils.addErrorSuccessMessage(768947, "The email can't have any spaces.", true);
            validateEmailResponse.errors.push(errorMessageObject)
            if(!returnAll) {
                return validateEmailResponse;
            }
        }

        validateEmailResponse.success = 1;

        return validateEmailResponse;
    },
    phone(phoneText) {
        let validatePhoneResponse = {
            success: 0,
            errors: [],
        };

        phoneText = phoneText.replace("+", "");

        if(phoneText.length < 4) {
            const errorMessageObject = Utils.addErrorSuccessMessage(236574, "Phone number is too short.", true);
            validatePhoneResponse.errors.push(errorMessageObject)
            return validatePhoneResponse;
        }
        if(phoneText.includes(" ")) {
            const errorMessageObject = Utils.addErrorSuccessMessage(768947, "The phone number can't have any spaces.", true);
            validatePhoneResponse.errors.push(errorMessageObject)
            return validatePhoneResponse;
        }

        validatePhoneResponse.success = 1;

        return validatePhoneResponse;
    },
    month(monthText) {
        let validateMonthResponse = {
            success: 0,
            errors: [],
        };

        if(monthText.length == 0 || monthText.length > 2) {
            const errorMessageObject = Utils.addErrorSuccessMessage(9473644, "Month must be 1 or 2 digits.", true);
            validateMonthResponse.errors.push(errorMessageObject)
            return validateMonthResponse;
        }
        if(parseInt(monthText) < 1 || parseInt(monthText) > 12) {
            const errorMessageObject = Utils.addErrorSuccessMessage(587348, "Month must be between 1 and 12.", true);
            validateMonthResponse.errors.push(errorMessageObject)
            return validateMonthResponse;
        }

        validateMonthResponse.success = 1;

        return validateMonthResponse;
    },
    day(dayText) {
        let validateDayResponse = {
            success: 0,
            errors: [],
        };

        if(dayText.length == 0 || dayText.length > 2) {
            const errorMessageObject = Utils.addErrorSuccessMessage(2342333, "Day must be 1 or 2 digits.", true);
            validateDayResponse.errors.push(errorMessageObject)
            return validateDayResponse;
        }
        if(parseInt(dayText) < 1 || parseInt(dayText) > 31) {
            const errorMessageObject = Utils.addErrorSuccessMessage(56433433, "Day must be between 1 and 31.", true);
            validateDayResponse.errors.push(errorMessageObject)
            return validateDayResponse;
        }

        validateDayResponse.success = 1;

        return validateDayResponse;
    },
    year(yearText) {
        let validateYearResponse = {
            success: 0,
            errors: [],
        };

        if(yearText.length != 4) {
            const errorMessageObject = Utils.addErrorSuccessMessage(44445556, "Year must be 4 digits.", true);
            validateYearResponse.errors.push(errorMessageObject)
            return validateYearResponse;
        }
        if(parseInt(yearText) < 1900 || parseInt(yearText) > 2200) {
            const errorMessageObject = Utils.addErrorSuccessMessage(456456, "Year must be between 1900 and 2200.", true);
            validateYearResponse.errors.push(errorMessageObject)
            return validateYearResponse;
        }

        validateYearResponse.success = 1;

        return validateYearResponse;
    },
    process(rules, returnOut) {
        return false;
    },
    valid(rules, returnOut, curState) {
        let valid = true;
        console.log(rules);
        for(var idx in rules) {
            const rule = rules[idx];
            if(rule['type'] == "accept") {
                const field = Utils.get(rule, "field");
                let val = Utils.get(curState, field);
                if(!val) {
                    valid = false;
                }
            }
        }
        //console.log("VALIDATE", valid);
        return valid;
    }
}
