import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Constant from '../modules/Constant';

import moment, { max } from 'moment';

import '../App.css';

import Div from '../elements/Div';
import Spinner from '../elements/Spinner';
import Image from '../elements/Image';
import Banner from '../elements/Banner';
import LoadingPage from '../elements/LoadingPage';
import WebsiteHeader from '../elements/WebsiteHeader';
import DebugBox from '../elements/DebugBox';

import StaticSplash from './StaticSplash';
import SplashUtopian from './SplashUtopian';
import SplashFestival from './SplashFestival';
import SplashPlatform from './SplashPlatform';
import SplashMarketplace from './SplashMarketplace';
import SplashMarketplace2 from './SplashMarketplace2';
import SplashCollection from './SplashCollection';
import Tickets from './Tickets';
import MintUtopian from './MintUtopian';
import Guardian from './Guardian';
import TestDev from './TestDev';

import PrivacyPolicy from './PrivacyPolicy';
import Profile from './Profile';
import Settings from './Settings';
import MyWallet from './MyWallet';
import Post from './Post';
import DashboardSoinItem from './DashboardSoinItem';
import CreatorDashboard from './CreatorDashboard';
import SplashFooter from './SplashFooter';
import AiChatbox from './AiChatbox';
import ProgressiveWebApp from './ProgressiveWebApp';
import AudioShoutoutPage from './AudioShoutoutPage';


import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import boredApeMenuImage from "../assets/images/ticketflow/profile-img@2x.png"

export default class AfterpartyStaticHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            routeParams: Utils.get(props, "match.params", {}),

            selected: "afterparty",
            mediaItem: false,

            events: false,
            upcomingLoaded: false,

            eventNotFound: false,

            seller: null,
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const selected = pr.route ? pr.route : 'afterparty';
        console.log("SELECTED ROUTE", selected);

        this.setState({selected: selected}, () => {
            this.handleRoute(selected);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';

        if(this.state.selected != selected) {
            this.handleRoute(selected);
            this.setState({selected: selected});
        }
    }

  processMusic(response, error) {
      const pr = this.props;
      const st = this.state;

      if(!response) {
          console.log("No response to process >>>");
          return;
      }
      try {
      let tempState;
      if(st.selected == 'item' || st.selected == 'nftlisting' || st.selected == 'soinlisting' || st.selected.includes("newnft")) {
          tempState = {
              mediaItem: response.data,
              includes: response.includes,
              //relatedSoins: data.includes.relatedSoins,
          };
          var related = Utils.get(response, "includes.relatedSoins");
          if(related) {
              tempState["relatedSoins"] = related;
          }
          console.log("soinlisting tempState", tempState);
      } else if(st.selected == 'search') {
          tempState = {
              results: response["data"],
          };
      } else if(this.state.selected == 'seller') {
          tempState = {
              seller: response["data"],
          };
      } else {
          let drops = response["data"]["drops"];
          var now = moment();
          for(var idx in drops) {
              var drop = drops[idx];
              //const secs = Math.floor(Math.random() * (drop["id"] * 16000));
              var then = moment(drop['drop_date_begin']); //moment().add(secs, 'seconds');
              if(now.isBefore(then)) {
                  //drop["until"] = moment().to(then);
                  drop["until"] = this.calcTimeStr(now, then);
              } else {
                  drop["remaining"] = this.calcTimeStr(now, moment(drop['drop_date_end'])); //moment().to(moment(drop['drop_date_end']));
              }
              //console.log("WHEN", moment().to(then));

          }
          console.log("mediaItem response >>>", response);
          tempState = {
              drops: Utils.get(response, "data.drops"),
              marketplace: Utils.get(response, "data.marketplace"),
              mycollection: Utils.get(response, "data.mycollection"),
              featured: Utils.get(response, "data.featured.0"),
              // For testing item screen
              mediaItem: Utils.get(response, "data.drops.0"),
              seller: Utils.get(response, "data.reppedArtists"),
          };
      }
      this.setState(tempState);
      } catch(e) {
          console.log("ERROR", e);
      }
  }


    handleRoute(selected) {
        const st = this.state;

        if(selected == 'event') {
        } else if(selected == 'afterparty') {
            Api.getAfterPartyEvents(this.processEvents.bind(this));
        } else if(this.state.selected == 'creator') {
            Api.getSeller(this.processMusic.bind(this));
        } else if(selected == 'guardian') {
            const eventCrc = 1364929401;
            Api.getAfterPartyEvent(eventCrc, this.processEvents.bind(this));
        } else if(selected == 'publicprofile') {
        } else {
            //Api.apiLoadMusic(this.processMusic.bind(this));
        }
    }

    processEvents(data, error) {
        ll._("process Events","green", data, error);
        if(data && data.errors && data.errors.length == 0) {
            let tempState = {
                events: data.data,
                upcomingLoaded: true,
                current_user_afterpartynft: data.current_user_afterpartynft,
                ad: Utils.get(data, "includes.ad"),
                guardian_faq: Utils.get(data, "includes.guardians_faq"),
                //relatedSoins: data.includes.relatedSoins,
            };
            this.setState(tempState);
        }
    }

    change(selection, obj, dirtyProfile) {
        const st = this.state;
        ll._('Changing selection to: '+selection, "blue", dirtyProfile); //, obj, st.musicLoaded);
        if(selection !== 'logout') {
            let tempState = {
                selected: selection,
                showMobileFlyout: false,
                eventLoaded: false,
            };

            let correctKey = selection === "room" ? "roomItem" : "eventItem";

            // tempState[correctKey] = obj; //TODO need to fix
            this.setState(tempState, () => {
                //console.log("Checking for reload", selection, _self.state.musicLoaded);
                if(dirtyProfile) {
                    console.log("apiLoadProfile");
                    Api.apiLoadProfile(this.props.setUser.bind(this));
                }
                if((selection == 'afterparty' || selection == 'home') && !st.upcomingLoaded) {
                    Api.getAfterPartyEvents(this.processEvents.bind(this));
                } else if(selection === 'event') {
                    this.setState({eventItem: obj})

                    if(!st.eventLoaded) {
                        const eventId = Utils.getUrlCrc();

                        if(eventId) {
                            Api.getAfterPartyEvent(eventId, this.processEvent.bind(this));
                        } else {
                            console.log("Event not found");
                            this.setState({eventNotFound: true});
                        }
                    }
                } else if((selection === 'item' || selection === 'soinlisting') && !obj.relatedSoins) {
                    console.log("LOAD RELATED SOINS");
                    //Api.apiGetListing(obj["id"], this.processMusic.bind(_self));
                }
                //console.log("Changing end", tempState);

            });
        } else {
            console.log("Changing logout");

            this.setState({ showLogout: true, showMobileFlyout: false, mediaItem: obj });
        }
    }

    setMetamaskWalletId(metamaskWalletId) {
        this.setState({
            metamaskWalletId: metamaskWalletId,
        });
    }

    renderSettingsPage(localGlobalProps) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.selected === 'settings') {
            if(pr.userLoaded) {
                return (
                    <Settings
                        {...localGlobalProps}
                    />
                );
            } else {
                return (
                    <LoadingPage
                        message="Loading Settings..."
                    />
                );
            }
        }

        return null;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let afterpartyStaticHomeComponent = {position:"relative"};
        const localGlobalProps = {
            setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }
        //console.log("SELECTED", st.selected);

        return (
            <Div className="afterpartyStaticHomeComponent" style={afterpartyStaticHomeComponent}>
                {Utils.isDemoMode() ?
                    <Banner
                        history={this.props.history}
                        route={this.props.route}
                    />
                    :
                    null
                }

                <WebsiteHeader
                    screen={pr.screen}
                    user={pr.user}
                    userLoaded={pr.userLoaded}
                    setUser={pr.setUser.bind(this)}
                    selected={st.selected}
                    history={pr.history}
                    change={this.change.bind(this)}
                    metamaskWalletId={st.metamaskWalletId}
                    headerType={pr.headerType}
                />

                {st.selected === 'splash' ?
                    <StaticSplash
                        {...localGlobalProps}
                        includes={pr.includes}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                    />
                    :
                    null
                }

                {st.selected === 'utopian' ?
                    <SplashUtopian
                        {...localGlobalProps}
                        includes={pr.includes}
                        events={st.events}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                    />
                    :
                    null
                }

                {st.selected === 'festival' ?
                    <SplashFestival
                        {...localGlobalProps}
                        includes={pr.includes}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                    />
                    :
                    null
                }
                {st.selected === 'platform' ?
                    <SplashPlatform
                        {...localGlobalProps}
                        includes={pr.includes}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                    />
                    :
                    null
                }
                {st.selected === 'festivalpasses' ?
                    <Tickets
                        {...localGlobalProps}
                        mode="minting"
                        events={st.events}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                        setMetamaskWalletId={this.setMetamaskWalletId.bind(this)}
                    />
                    :
                    null
                }
                {st.selected === 'festivaltickets' ?
                    <Tickets
                        {...localGlobalProps}
                        mode="ticket"
                        events={st.events}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                        setMetamaskWalletId={this.setMetamaskWalletId.bind(this)}
                    />
                    :
                    null
                }
                {st.selected === 'mintutopian' ?
                    <MintUtopian
                        {...localGlobalProps}
                        events={st.events}
                        current_user_afterpartynft={st.current_user_afterpartynft}
                    />
                    :
                    null
                }

                {st.selected === 'guardian' ?
                    <Guardian
                        {...localGlobalProps}
                        route="guardian"
                        guardian_faq={st.guardian_faq}
                        ad={st.ad}
                    />
                    :
                    null
                }

                {st.selected === 'marketplace' ?
                    <SplashMarketplace
                        {...localGlobalProps}
                        route="guardian"
                        guardian_faq={st.guardian_faq}
                        ad={st.ad}
                    />
                    :
                    null
                }
                {st.selected === 'marketplace2' ?
                    <SplashMarketplace2
                        {...localGlobalProps}
                        route="guardian"
                        guardian_faq={st.guardian_faq}
                        ad={st.ad}
                    />
                    :
                    null
                }
                {st.selected === 'collection' ?
                    <SplashCollection
                        {...localGlobalProps}
                        route="guardian"
                        guardian_faq={st.guardian_faq}
                        ad={st.ad}
                    />
                    :
                    null
                }
                {st.selected === 'profile' ?
                    <Div
                        className="afterpartyProfileWrapper"
                        style={{width: "100%"}}
                    >
                        <Profile
                            {...localGlobalProps}
                            seller={st.seller}
                        />
                    </Div>
                    :
                    null
                }

                {this.renderSettingsPage(localGlobalProps)}

                {st.selected === 'aichat' ?
                    <AiChatbox
                        isPublic={pr.isPublic}
                        screen={pr.screen}
                        history={pr.history}
                        isPublic={pr.isPublic}
                        user={pr.user}
                        setUser={pr.setUser.bind(this)}
                        userLoaded={pr.userLoaded}
                        width={pr.screen.width}
                    />
                    :
                    null
                }
                {st.selected === "share" ?
                    <AudioShoutoutPage
                        shoutoutCrc={Utils.get(pr, "match.params.shoutout_crc")}
                        screen={pr.screen}
                        history={pr.history}
                        user={pr.user}
                        userLoaded={pr.userLoaded}
                    />
                    :
                    null
                }
                {st.selected === 'mywallet' ?
                    <Div
                        className="afterpartyProfileWrapper"
                        style={{width: "100%"}}
                    >
                        <MyWallet
                            {...localGlobalProps}
                            seller={st.seller}
                        />
                    </Div>
                    :
                    null
                }

                {(st.selected === 'item' || st.selected === 'soinlisting') ?
                    <DashboardSoinItem
                        {...localGlobalProps}
                        mediaItem={st.mediaItem}
                        includes={st.includes}
                        listing={st.mediaItem.listing}
                        relatedSoins={st.mediaItem.relatedSoins}
                    />
                    :
                    null
                }

                {(st.selected === 'soinlisting') ?
                    <DashboardSoinItem
                        {...localGlobalProps}
                        mediaItem={st.mediaItem}
                        includes={st.includes}
                        listing={st.mediaItem.listing}
                        relatedSoins={st.mediaItem.relatedSoins}
                    />
                    :
                    null
                }

                {(st.selected === 'post') ?
                    <Post
                        {...localGlobalProps}
                        mediaItem={st.mediaItem}
                        includes={st.includes}
                        listing={st.mediaItem.listing}
                        relatedSoins={st.mediaItem.relatedSoins}
                        postId={pr.postId}
                    />
                    :
                    null
                }

                {(st.selected === "testdev") ?
                    <TestDev
                        {...localGlobalProps}
                    />
                    :
                    null
                }
                {(st.selected === "creator") ?
                    <CreatorDashboard
                        {...localGlobalProps}
                        seller={st.seller}
                    />
                    :
                    null
                }
                {(st.selected === "pwa") ?
                    <ProgressiveWebApp
                        {...localGlobalProps}
                    />
                    :
                    null
                }

                {pr.footer &&
                    <SplashFooter
                        screen={pr.screen}
                        history={pr.history}
                    />
                }
            </Div>
        );
    }

    styles = {
        afterpartyStaticHomeComponent: {},
        registerContainer: {
            padding: "60px 15px",
            margin: "0 auto",
            maxWidth: 400,
            width: "100%",
        },
    };
}
