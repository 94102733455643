import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Icon from './Icon';

import Div from '../components/Div';

export default class ErrorBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getErrorColor() {
        switch(this.props.color) {
            case "white":
                return {color: "white"};
            default:
                return {color: Colors.redError};
        }
    }

    getSuccessColor() {
        return {color: Colors.neonGreen};
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        if(!Utils.get(pr.errorMessages, "length") && !Utils.get(pr.successMessages, "length")) {
            return null;
        }

        let successMessages = pr.successMessages;
        if(Utils.get(pr.errorMessages, "length")) {
            successMessages = [];
        }

        return (
            <Div
                className="errorBoxElement"
                style={{...sty.container, ...pr.style}}
            >
                {successMessages.map((success, idx) => {
                    return (
                        <Div
                            key={`success-${success.id}`}
                            className="successMessageContainer"
                            style={{...sty.messageContainer, ...this.getSuccessColor()}}
                        >
                            <Icon icon="check-circle" style={{marginRight: 4}} size={18} /> {success.message}
                        </Div>
                    )
                })}
                {pr.errorMessages.map((error, idx) => {
                    return (
                        <Div
                            key={`error-${error.id}`}
                            className="errorMessageContainer"
                            style={{...sty.messageContainer, ...pr.errorMessageStyle, ...this.getErrorColor()}}
                        >
                            <Icon icon="error" style={{marginRight: 4}} size={18} /> {error.message}
                        </Div>
                    );
                })}
            </Div>
        )
    }

    styles = {
        container: {
            padding: 10,
            textAlign: "center"
        },
        messageContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    };
}
