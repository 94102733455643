import React from 'react';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import ll from '../modules/ll';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';

import Div from './Div';
import ConnectWallet from './ConnectWallet';
import ProfileImage from './ProfileImage';

export default class WalletTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            walletExpanded: false,
            moveButtonsEnabled: true,
            wallets: [],
            walletExpanded: {
                "1": false,
                "2": false,
            }
        };

        let organizedWallets = this.organizeWallets(props);
        this.state.wallets = organizedWallets;
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let organizedWallets = this.organizeWallets(newProps);
        this.setState({
            wallets: organizedWallets,
        });
    }

    organizeWallets(props) {
        let wallets = [];
        if(props.wallets) {
            wallets = Utils.get(props, "wallets", []);
        } else if(props.user) {
            wallets = Utils.get(props.user, "wallets", []);
        }

        let organizedWallets = {
            "1": [],
            "2": [],
        };

        for(let i = 0; i < wallets.length; ++i) {
            var curWallet = wallets[i];
            if(curWallet.wallet_type_id in organizedWallets) {
                organizedWallets[curWallet.wallet_type_id].push(wallets[i]);
            }
        }

        for(let walletType in organizedWallets) {
            let defaultWalletNotInOrder = false;

            for(let walletIndex = 0; walletIndex < organizedWallets[walletType].length; walletIndex++) {
                let wallet = organizedWallets[walletType][walletIndex];

                if(walletIndex === 0 && wallet.default_wallet) {
                    break;
                } else {
                    defaultWalletNotInOrder = true;
                }

                if(defaultWalletNotInOrder && wallet.default_wallet) {
                    let tempWallet = organizedWallets[walletType][0];
                    organizedWallets[walletType][0] = wallet;
                    organizedWallets[walletType][walletIndex] = tempWallet;
                }
            }
        }

        return organizedWallets;
    }

    toggleWalletList(walletType) {
        let walletExpanded = this.state.walletExpanded;
        walletExpanded[walletType] = !walletExpanded[walletType];
        this.setState({walletExpanded: walletExpanded});
    }

    makeDefaultWallet(walletType, walletIndex) {
        const st = this.state;

        let wallets = st.wallets;
        let newDefaultWallet = wallets[walletType][walletIndex];
        let oldDefaultWallet = wallets[walletType][0];

        newDefaultWallet.default_wallet = 1; // true
        oldDefaultWallet.default_wallet = 0; // false

        wallets[walletType][0] = newDefaultWallet;
        wallets[walletType][walletIndex] = oldDefaultWallet;

        let data = {
            wallets: wallets,
        }

        this.setState({
            wallets: wallets,
        });

        // Api.putMusicWallet(data, (response) => { // TODO need API call
        //     this.setState({
        //         wallets: wallets,
        //     });
        // })
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        if(this.props.handleSetWallet) {
            this.props.handleSetWallet(accounts, bitCloutPublicKey);
        } else {
            if(!accounts && bitCloutPublicKey) {
                Api.putMusicWallet({
                    params: {
                        user: {
                            bitclout_public_key: bitCloutPublicKey
                        }
                    }
                });
                this.setState({
                    bitCloutAddress: bitCloutPublicKey,
                    registeredSuccess: true,
                });
            } else {
                Api.putMusicWallet({
                    params: {
                        user: {
                            wallet_id: accounts[0]
                        }
                    }
                });
                this.setState({wallet: accounts});
            }
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let wallets = st.wallets;

        if(!Object.keys(wallets).length) {
            return (
                <Div>No wallets in table</Div>
            )
        }

        const walletTableContainer = StyleUtils.getMediaStyle('walletTableContainer', sty.walletTableContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const walletTable = StyleUtils.getMediaStyle('walletTable', sty.walletTable, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const afterpartyProfile = StyleUtils.getMediaStyle('afterpartyProfile', sty.afterpartyProfile, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const walletDropdownButton = StyleUtils.getMediaStyle('walletDropdownButton', sty.walletDropdownButton, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const connectWalletStyle = StyleUtils.getMediaStyle('connectWalletStyle', sty.connectWalletStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        let walletsTable = [];

        for(let walletType in wallets) {
            let walletIndent = false;
            let walletsRowExpanded = st.walletExpanded[walletType];

            for(let walletIndex = 0; walletIndex < wallets[walletType].length; walletIndex++) {
                let wallet = wallets[walletType][walletIndex];
                let walletIdString = Utils.abbreviateHash(Utils.get(wallet, "wallet_id", ""), 6);

                if(pr.screen.width <= 324) {
                    walletIdString = Utils.abbreviateHash(Utils.get(wallet, "wallet_id", ""), 4);
                }

                // Responsive styles
                let walletPicColumn = StyleUtils.getMediaStyle('walletPicColumnLarge', sty.walletPicColumnLarge, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
                let walletIdColumn = StyleUtils.getMediaStyle('walletIdColumn', sty.walletIdColumn, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
                let walletRow = StyleUtils.getMediaStyle('walletRow', sty.walletRow, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

                if(walletIndex !== 0) {
                    walletIndent = true;
                    walletPicColumn = StyleUtils.getMediaStyle('walletPicColumn', sty.walletPicColumn, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
                }

                let walletRowObject = (
                    <Div key={wallet.wallet_id} style={sty.walletOuterRow} className="walletOuterRow">
                        <Div style={{...walletRow, ...pr.rowStyle, ...{marginLeft: walletIndent ? 30 : 0}}}>
                            <Div style={{...sty.walletColumn, ...walletPicColumn}}>
                                {pr.screen.width > 480 ?
                                    <ProfileImage
                                        hash={Utils.get(wallet, "wallet_id", "")}
                                        src={wallet.wallet_image}
                                        size={48}
                                    />
                                    :
                                    null
                                }
                                <Div className="walletIdColumn" style={walletIdColumn}>{walletIdString}</Div>
                            </Div>
                            <Div style={sty.walletInnerRow}>
                                <Div style={{...sty.walletColumn, ...sty.walletTypeColumn}}>
                                    {walletType == 1 ? "MetaMask" : Api.getBitcloutTitle()}
                                </Div>
                                <Div style={{...sty.walletColumn, ...sty.walletDefaultColumn}}>
                                    {wallet.default_wallet ?
                                        <Div style={{margin: "0 auto"}}>
                                            Default
                                        </Div>
                                        :
                                        <Button
                                            onClick={this.makeDefaultWallet.bind(this, walletType, walletIndex)}
                                            style={{marginLeft: "auto", marginRight: "auto", color: "white"}}
                                            size="text"
                                        >
                                            {pr.screen.width > 768 ? "Make Default" : "Default"}
                                        </Button>
                                    }

                                </Div>
                                {walletIndex === 0 && wallets[walletType].length > 1?
                                    <Icon
                                        className="walletDropdownButton"
                                        icon={walletsRowExpanded ? "chevron-down" : "chevron-right"}
                                        style={walletDropdownButton}
                                        onClick={this.toggleWalletList.bind(this, walletType)}
                                        size={15}
                                    />
                                    :
                                    <Div style={{width: 32, height: 32}}></Div>
                                }

                            </Div>

                        </Div>
                    </Div>
                );

                if(walletsRowExpanded) {
                    walletsTable.push(walletRowObject);
                } else if(!walletsRowExpanded && walletIndex === 0) {
                    walletsTable.push(walletRowObject);
                }
            } // for wallet of walletType
        } // for each walletType

        return (
            <Div className="walletTableComponent" style={pr.style}>
                <BloatedCircleDefs
                    scales={[50, 100, 200]}
                />

                {walletsTable.length ?
                    <Div className="walletTable" style={walletTable}>
                        {walletsTable}
                    </Div>
                    :
                    null
                }

                <ConnectWallet
                    style={walletsTable.length == 0 ? {justifyContent: "center"} : null}
                    screen={pr.screen}
                    user={pr.user}
                    eventItem={pr.eventItem}
                    handleSetWallet={this.handleSetWallet.bind(this)}
                    walletTypes={pr.walletTypes ? pr.walletTypes : ["bitclout", "metamask"]}
                />
            </Div>
        );
    }

    styles = {
        walletTable: {
            marginBottom: 20,
            width: "100%",
        },
        walletTable_sm: {
            marginBottom: 10,
        },
        walletRow: {
            display: "flex",
            alignItems: "center",
            color: "white",
            backgroundColor: Colors.purpleLight2Semi,
            borderRadius: 14,
            width: "100%",
            padding: 10,
            overflow: "hidden",
        },
        walletRow_sm: {
            padding: 6,
        },
        walletOuterRow: {
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
        },
        walletDropdownButton: {
            backgroundColor: Colors.purple,
            color: "white",
            borderRadius: 100,
            width: 32,
            height: 32,
            textAlign: "center",
            padding: 9,
            cursor: "pointer",
            flexShrink: 0,
        },
        walletColumn: {
            display: "flex",
            height: "100%",
            paddingRight: 10,
        },
        walletInnerRow: {
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        walletPic: {
            width: 50,
            height: 50,
            clipPath:"url(#bloated-circle-50)",
        },
        walletPicColumnLarge: {
            width: 346,
            alignItems: "center",
        },
        walletPicColumnLarge_sm: {
            width: 243,
        },
        walletPicColumn: {
            width: 300,
            alignItems: "center",
        },
        walletPicColumn_sm: {
            width: 175,
        },
        walletTypeColumn: {
            width: "50%",
        },
        walletIdColumn: {
            fontWeight: 700,
            marginLeft: 10,
        },
        walletIdColumn_sm: {
            marginLeft: 3,
        },
        walletDefaultColumn: {
            width: "50%",
            borderRight: "none",
            justifyContent: "center",
        },
        moveButtons: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: 5,
        },
    };
}
