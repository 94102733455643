import React from 'react';
import { useEffect, useRef } from "react";
import Lottie from "lottie-react-web"
// import DeviceDetector from "device-detector-js";

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';

import Div from '../elements/Div';
import Span from '../elements/Span';
import Input from '../elements/Input';
import Icon from '../elements/Icon';
import Button from '../elements/Button';
import Spinner from '../elements/Spinner';
import Modal from '../elements/Modal';
import Image from '../elements/Image';
import WebappLoginRegister from '../elements/WebappLoginRegister';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import DebugBox from '../elements/DebugBox';

import pwaApLogo from '../assets/images/pwaApLogo.png';
import pwaDirection1 from '../assets/images/pwaDirection1.png';
import pwaDirection2 from '../assets/images/pwaDirection2.png';
import pwaDirection3 from '../assets/images/pwaDirection3.png';
import pwaIosSettingsImage from '../assets/images/pwaIosSettingsImage.png';
import pwaIosSafariImage from '../assets/images/pwaIosSafariImage.png';
import pwaIosShare from '../assets/images/pwaIosShare.png';
import pwa2500Credits from '../assets/images/pwa2500Credits.png';
import pwa1000Credits from '../assets/images/pwa1000Credits.png';
import pwaBell from '../assets/images/pwa bell.png';
import pwaGet50Interactions from '../assets/images/pwa get 50 interactions.png';

import pwaStep1 from '../assets/images/pwaStep1.png';
import pwaStep2 from '../assets/images/pwaStep2.png';
import pwaStep3 from '../assets/images/pwaStep3.png';
import pwaStep4 from '../assets/images/pwaStep4.png';

import pwaQrCode from '../assets/images/pwaQrCode.png';

export default class ProgressiveWebApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            txtStatus: "",
            serviceWorkerStatus: "LOADING", //NOTE: LOADING, UNREGISTERED, REGISTERED
            showPwaModal: false,
            success: false,
            errorText: "",
            nextRoute: Utils.getRequest("next") ? Utils.getRequest("next").replace("^", "?") : "home",

            pwaState: Utils.getRequest("pwaState"), // wrongbrowser, ios16, ios17, android, success
        };

        console.log("this.state.pwaState >>>>>", this.state.pwaState);

        // TODO: detect which platform and browser youre in
        // console.log("thing 1 >>>>>", Utils.getDeviceType());
        this.deviceType = Utils.getDeviceType();
        // console.log("thing 2 >>>>>", Utils.getClientName());
        this.clientName = Utils.getClientName();
    }

    async componentDidMount() {
        const pr = this.props;
        const st = this.state;
        let _self = this;

        // NOTE: serviceWorker is the PWA
        this.checkNotificationStatusWrapper()

        if('serviceWorker' in navigator && 'PushManager' in window) {
            console.log("timeout started");
            setTimeout(() => {
                console.log("timeout finished");
                this.registerServiceWorker();
            }, 2000);
            // this.registerServiceWorker();
            if(false && !window.swReg) { // NOTE: leave this false here because App.js was unregistering all service workers
                navigator.serviceWorker.getRegistrations().then( (registrations) => {
                    for (let registration of registrations) {
                        console.log("registration", registration);
                        window.swReg = registration;
                        break;
                        //registration.unregister()
                    }
                    if(!window.swReg) {
                        this.registerServiceWorker();
                    } else {
                        this.setState({
                            txtStatus:"Service worker available.",
                            serviceWorkerStatus: "REGISTERED",
                        });
                    }
                })
            }
        } else {
            console.log("ELSE STATEMENT");
            this.setState({
                txtStatus:"No service worker available.",
                serviceWorkerStatus: "UNREGISTERED",
            });
        }
    }

    async UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        if(newProps.userLoaded && !Utils.get(newProps, "user.email")) {
            Utils.gotoRoute(pr, "login?next=pwa");
        }

        this.checkNotificationStatusWrapper()
    }

    componentWillUnmount() {
        const pr = this.props;
    }

    async checkNotificationStatusWrapper() {
        const pr = this.props;

        const notificationsEnabled = await this.checkNotificationStatus();
        if (notificationsEnabled) {
            console.log("Notifications are enabled and subscribed. >>>>");
            // Proceed with logic knowing notifications are enabled
            Utils.gotoRoute(pr, "/private/aichat");
        } else {
            console.log("Notifications are not enabled/subscribed. >>>>");
            // Maybe prompt the user to enable notifications or handle accordingly
        }
    }

    registerServiceWorker() {
        console.log("registerServiceWorker >>>>>");
        this.setState({txtStatus:"Loading service worker..."});
        navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
        .then( (swReg) => {
            console.log("swReg >>>>>", swReg);
            this.swReg = swReg;
            window.swReg = swReg;
            console.log("window.swReg >>>>>", window.swReg);
            console.log("window.swReg.pushManager >>>>>", window.swReg.pushManager);

            console.log('Service Worker is registered', swReg);
            this.setState({
                txtStatus:"Service Worker is registered. Ready to subscribe.",
                serviceWorkerStatus: "REGISTERED",
            });
        })
        .catch( (error) => {
            this.setState({
                txtStatus:'Service Worker Error: '+ error,
                serviceWorkerStatus: "UNREGISTERED",
            });
        });
    }
    unregisterServiceWorker() {
        navigator.serviceWorker.getRegistrations().then( (registrations) => {
            let swCount = 1;
            for (let registration of registrations) {
                console.log("unregister", registration);
                registration.unregister();
                this.setState({
                    txtStatus:"Unregistered "+swCount+" service worker(s)",
                    serviceWorkerStatus: "UNREGISTERED",
                });
                swCount++;
            }
        })
    }

    async copyAfterpartyPwaUrl() {
        const url = 'https://afterparty.com/pwa';
        try {
            await navigator.clipboard.writeText(url);
            console.log('Text copied to clipboard');
        } catch (err) {
            console.error('Error in copying text: ', err);
        }
    }

    isNotificationSupported() {
      if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return false;
      }

      if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return false;
      }

      return true;
    }

    handleSubscribe() {
        const pr = this.props;

        this.setState({
            txtStatus:"Subscribing...",
            errorText: "",
        });
        var _self = this;

       // navigator.serviceWorker.ready.then( function(registration) {
            _self.setState({txtStatus:'SW ready. Sending to service worker...'});
            //console.log("registration", registration);
            window.swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BH64JUFonxPyUh4vtZVMMNkKW5E_72pDe5-Gvx6jH7lx4jp6KWY-5GlvKfBp_QOz8QmPbXjj52zL6Ed3oTnKKFo'
            })
            .then(function(subscription)  {
                _self.setState({txtStatus:'Subscribed. POSTing subscription endpoint.'});
                fetch('/subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        subscription: subscription
                    })
                }).then((o) => {
                    if(Utils.getCookie("pwaNotificationStatus") == 1) {
                        const next = Utils.getRequest("next");
                        Utils.gotoRoute(pr, next ?? "private/aichat");
                    }
                    _self.setState({
                        txtStatus: 'Notifications activated.',
                        showButtonTypes: true,
                        serviceWorkerStatus: "REGISTERED",
                        success: true,
                    });

                    // NOTE: data for networkCallForCredits
                    const jsonData = {
                        customerId: Utils.get(pr, "user.id"),
                    };
                    const getCreditsData = {
                        form_id: 10,
                        json: JSON.stringify(jsonData),
                    };

                    // NOTE: networkCallForCredits and then reload the user with updated creadits
                    Api.postFormDataRow(getCreditsData, function(res) {
                        Api.apiLoadProfile(function(profileRes) {
                            pr.setUser(profileRes, function() {
                                Utils.setCookie("pwaNotificationStatus", "1");
                                _self.setState({
                                    success: true,
                                });
                            });
                        });
                    });
                });
            })
            .catch( (error) => {
                _self.setState({
                    txtStatus:'Failed to subscribe:' + error,
                    errorText: "Allowing Notifications failed, perhaps you need to reset notifications in your settings on your device",
                    serviceWorkerStatus: "UNREGISTERED",
                });
            });
        //});
    }

    allowNotifications(allowNotifications) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const next = Utils.getRequest("next");

        if(allowNotifications) {
            this.handleSubscribe();
        } else {
            Utils.setCookie("pwaNotificationStatus", "1");
            Utils.gotoRoute(pr, next ?? "private/aichat?test=1");
        }
    }

    async checkNotificationStatus(type) {
        console.log("type >>>>", type);
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
            console.log("Service Worker or PushManager not available");
            return false;
        }
    
        try {
            const permission = Notification.permission;
            if (permission !== 'granted') {
                console.log("Notifications not granted");
                return false;
            }
    
            const subscription = await navigator.serviceWorker.ready.then(reg => reg.pushManager.getSubscription());
            return subscription !== null;
        } catch (error) {
            console.error("Error checking notification status", error);
            return false;
        }
    }    

    renderPwaApLogo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Image
                src={pwaApLogo}
                style={{width: 82, marginTop: 40}}
            />
        );
    }

    renderIsNotSmartphone() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="isNotSmartphoneContainer" style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", marginTop: 100, maxWidth: 280, alignSelf: "center"}}>
                <Image
                    style={{width: 65, marginBottom: 16}}
                    src={pwaBell}
                />
                <Div style={{fontWeight: 400, fontSize: 28, textAlign: "center", marginBottom: 20}}>
                    Turn on Notifications and Eearn 2500 Credits
                </Div>
                <Image
                    style={{width: 160, marginBottom: 24}}
                    src={pwaGet50Interactions}
                />
                
                <Div style={{display: "flex", flexDirection: "column", gap: 16, marginBottom: 24}}>
                    <Div className="pwaStepRow1" style={sty.pwaStepRow}>
                        <Image src={pwaStep1} style={{width: 24}} />
                        <Div>
                            Go to <Span style={{fontWeight: 500}}>afterparty.com/pwa</Span>
                        </Div>
                    </Div>
                    <Div className="pwaStepRow2" style={sty.pwaStepRow}>
                        <Image src={pwaStep2} style={{width: 24}} />
                        <Div>
                            Tap <Span style={{fontWeight: 500}}>Add to Home Screen</Span>
                        </Div>
                    </Div>
                </Div>
                <Div className="pwaQrContainer" style={{width: 180, padding: 16, background: "linear-gradient(180deg, #6321EF 0%, #8600B1 100%)", borderRadius: 24}}>
                    <Image
                        className="pwaQrCode"
                        style={{width: "100%", borderRadius: 8}}
                        src={pwaQrCode}
                    />
                </Div>
                <Button
                    onClick={Utils.gotoRoute.bind(this, pr, st.nextRoute)}
                    style={{marginTop: 24}}
                    color="pinkGradient"
                >
                    Back to Ai Chat
                </Button>
            </Div>
        );
    }

    renderIosButNotSafari() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: 16, fontWeight: 400}}>
                {this.renderPwaApLogo()}
                <Div style={{fontSize: 24, textAlign: "center"}}>
                    Open in Safari to Install
                </Div>
                <Div style={{fontSize: 14, textAlign: "center", maxWidth: 255, marginLeft: "auto", marginRight: "auto"}}>
                    Wrong browser detected, please open afterparty.com in Safari to install this app.
                </Div>

                <Div className="pwaStepRow1" style={{...sty.pwaStepRow, ...sty.pwaStepRowCenter}}>
                    <Image src={pwaStep1} style={{width: 24}} />
                    <Div>
                        Open <Span style={{fontWeight: 500}}>Safari</Span>
                    </Div>
                    <Image
                        src={pwaIosSafariImage}
                        style={{width: 36}}
                    />
                </Div>
                <Div className="pwaStepRow2" style={{...sty.pwaStepRow, ...sty.pwaStepRowCenter}}>
                    <Image
                        src={pwaStep2}
                        style={{width: 24}}
                    />
                    <Div>
                        Go to <Span style={{fontWeight: 500}}>afterparty.com/pwa</Span>
                    </Div>
                </Div>
                <Button
                    onClick={this.copyAfterpartyPwaUrl.bind(this)}
                    color="white"
                    style={{width: "100%", fontWeight: 500, marginTop: 8}}
                >
                    <Icon
                        icon="copy"
                        style={{marginRight: 8}}
                    />
                    Copy afterparty.com/pwa
                </Button>
            </Div>
        );
    }

    renderIosButVersionLessThanIdeal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: 16, fontWeight: 400}}>
                {this.renderPwaApLogo()}
                <Div style={{fontSize: 24, textAlign: "center"}}>
                    Software Update Required to Install Afterparty
                </Div>
                <Div style={{fontSize: 14, textAlign: "center"}}>
                    To update iOS on yourn iPhone or iPad
                </Div>

                <Div className="pwaStepRow1" style={sty.pwaStepRow}>
                    <Image src={pwaStep1} style={{width: 24}} />
                    <Div>
                        Open the <Span style={{fontWeight: 500}}>Settings</Span> app
                    </Div>
                    <Image src={pwaIosSettingsImage} style={{width: 36}} />
                </Div>
                <Div className="pwaStepRow2" style={sty.pwaStepRow}>
                    <Image src={pwaStep2} style={{width: 24}} />
                    <Div>
                        Tap <Span style={{fontWeight: 500}}>General</Span>
                    </Div>
                </Div>
                <Image src={pwaDirection1} style={{width: "100%"}} />
                <Div className="pwaStepRow3" style={sty.pwaStepRow}>
                    <Image src={pwaStep3} style={{width: 24}} />
                    <Div>
                        Tap <Span style={{fontWeight: 500}}>Software Update</Span>
                    </Div>
                </Div>
                <Image src={pwaDirection2} style={{width: "100%"}} />
                <Div className="pwaStepRow4" style={sty.pwaStepRow}>
                    <Image src={pwaStep4} style={{width: 24}} />
                    <Div>
                        Tap <Span style={{fontWeight: 500}}>Install Now</Span>
                    </Div>
                </Div>

                Mobile Safari, version is {Utils.getOsVersion()}
            </Div>
        );
    }

    renderIosCorrectButNoServiceWorker() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: 16, fontWeight: 400}}>
                {this.renderPwaApLogo()}
                <Div style={{fontSize: 24, textAlign: "center"}}>
                    Add To Home Screen<br/>and Get 2500 Credits!
                </Div>
                <Div className="orangeBanner" style={sty.orangeBanner}>
                    Get +50 Interactions
                </Div>

                <Div className="pwaStepRow1" style={sty.pwaStepRow}>
                    <Image src={pwaStep1} style={{width: 24}} />
                    <Div>
                        Open the <Span style={{fontWeight: 500}}>Share Icon</Span> below
                    </Div>
                    <Image src={pwaIosShare} style={{width: 36}} />
                </Div>
                <Div className="pwaStepRow2" style={sty.pwaStepRow}>
                    <Image src={pwaStep2} style={{width: 24}} />
                    <Div>
                        Tap <Span style={{fontWeight: 500}}>Add to Home Screen</Span>
                    </Div>
                </Div>
                <Image src={pwaDirection3} style={{width: "100%"}} />
                <Div className="pwaStepRow3" style={sty.pwaStepRow}>
                    <Image src={pwaStep3} style={{width: 24}} />
                    <Div>
                        Navigate to App on homepage <Span style={{fontWeight: 500}}>open and Enable Notifications</Span>
                    </Div>
                </Div>

                {/* Mobile Safari, version is {Utils.getOsVersion()} */}
            </Div>
        );
    }

    renderIosCorrectWithServiceWorker() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: 16, fontWeight: 400}}>
                {this.renderPwaApLogo()}
                <Div style={{fontSize: 24, textAlign: "center"}}>
                    Get Notified When You
                    <br/>
                    Get a Message
                </Div>
                <Div style={{fontSize: 14, textAlign: "center", marginBottom: 10}}>
                    Don’t miss a message and stay up to date.
                </Div>

                <Div style={{display: "flex", gap: 8}}>
                    <Button
                        onClick={this.allowNotifications.bind(this, false)}
                        type="outlined"
                        style={{minWidth: 140}}
                    >
                        Don't Allow
                    </Button>
                    <Button
                        onClick={this.allowNotifications.bind(this, true)}
                        color="white"
                        style={{minWidth: 140}}
                    >
                        Allow
                    </Button>
                </Div>

                {st.errorText && st.errorText.length ?
                    <Div className="errorText" style={{marginTop: 24, textAlign: "center", color: Colors.redError}}>
                        {st.errorText}
                    </Div>
                    :
                    null
                }
                {/* Mobile Safari, version is {Utils.getOsVersion()} */}
            </Div>
        );
    }

    getRequestIsTrue() {
        const st = this.state;

        switch(st.pwaState) {
            case "wrongbrowser":
                return this.renderIosButNotSafari();
            case "ios16":
                return this.renderIosButVersionLessThanIdeal();
            case "ios17":
                return this.renderIosCorrectButNoServiceWorker();
            case "ios17sw":
                return this.renderIosCorrectWithServiceWorker();
            case "android":
                return true;
            case "success":
                return this.renderSuccessBody();
            default:
                return false;
        };
    }

    renderPwaBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!Utils.isSmartPhone()) {
            return this.renderIsNotSmartphone();
        }

        let body = null;

        if(Utils.getOsName() == "iOS" && Utils.getClientName() != "Mobile Safari" || this.getRequestIsTrue()) {
            body = this.renderIosButNotSafari();
        } else if(Utils.getClientName() == "Mobile Safari") {
            if(parseFloat(Utils.getOsVersion()) < 16.4) {
                body = this.renderIosButVersionLessThanIdeal();
            } else if(parseFloat(Utils.getOsVersion()) >= 16.4 && st.serviceWorkerStatus == "UNREGISTERED") {
                body = this.renderIosCorrectButNoServiceWorker();
            } else {
                body = this.renderIosCorrectWithServiceWorker();
            }
        } else if(Utils.getOsName() == "Android") {
            body = this.renderIosCorrectWithServiceWorker();
        }

        return body;
    }

    renderSuccessBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const next = Utils.getRequest("next");

        return (
            <Div className="pwaSuccessPage" style={sty.pwaSuccessPage}>
                <Image
                    style={{width: 120, marginBottom: 16}}
                    src={pwa1000Credits}
                />
                <Div style={{fontSize: 24, textAlign: "center"}}>
                    Success!
                </Div>
                <Div style={{fontSize: 14, textAlign: "center", maxWidth: 255, marginLeft: "auto", marginRight: "auto"}}>
                    Thanks for turning on notifications! We’ve
                    <br/>
                    added 1000 credits to your account!
                </Div>
                <Button
                    onClick={Utils.gotoRoute.bind(this, pr, next ?? "private/aichat?test=1")}
                    color="pinkGradient"
                    style={{marginLeft: "auto", marginRight: "auto", marginTop: 16}}
                    size="small"
                >
                    Back to AI Chat
                </Button>
            </Div>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = Utils.deepCopy(this.styles);

        // console.log("navigator.serviceWorker >>>>", navigator.serviceWorker);

        if(!pr.userLoaded) {
            return (
                <Div className="progressiveWebAppComponent" style={sty.progressiveWebAppComponent}>
                    <Div className="pwaLoadingPage" style={sty.pwaLoadingPage}>
                        <Spinner
                            size={50}
                            message="Loading Afterparty App..."
                        />
                    </Div>
                </Div>
            );
        }

        return (
            <Div className="progressiveWebAppComponent" style={sty.progressiveWebAppComponent}>
                {pr.screen.mobile && Utils.getRequest("reload") == 1 ?
                    <Button
                        onClick={() => {window.location.reload()}}
                        color="white"
                        size="small"
                        style={{margin: "10px auto", marginBottom: -20}}
                    >
                        Reload The App
                    </Button>
                    :
                    null
                }

                {st.success ?
                    this.renderSuccessBody()
                    :
                    st.pwaState ? this.getRequestIsTrue() : this.renderPwaBody()
                }
                { Utils.getRequest('test') == 17 ?
                    <Div style={{borderTop:"3px solid red",marginTop:20}}>
                        <Div style={{color: Colors.neonGreen, textAlign: "center", padding: 20}}>
                            {st.serviceWorkerStatus.toUpperCase()}
                        </Div>
                        {Utils.get(pr, "user.id") ?
                            <Div style={this.styles.containerSubscribe}>
                                <Div>
                                    Install to get 2000 credits.
                                </Div>
                                <Div>
                                    Open your Safari menu and tap
                                </Div>
                                <Div style={{fontSize:30}}>
                                    "Add to Home Screen"
                                </Div>
                                <Div>
                                    <Button
                                        onClick={this.handleSubscribe.bind(this)}
                                    >
                                        Subscribe to Notifications
                                    </Button>
                                </Div>
                                <Div>
                                    {this.state.txtStatus}
                                </Div>
                                {true || this.state.showButtonTypes ?
                                    <Div>

                                        <Button
                                            onClick={this.unregisterServiceWorker.bind(this)}
                                            >
                                            Unregister
                                        </Button>
                                    </Div>
                                    :
                                    null
                                }

                            </Div>
                            :
                            <Div>
                                Register or login to subscribe
                            </Div>
                        }
                    </Div>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        progressiveWebAppComponent: {
            display: "flex",
            fontWeight: 400,
            background: Colors.indigo,
            padding: "72px 20px 20px 20px",
            minHeight: Utils.cssCalc(),
            color: "white",
            flexDirection: "column",
        },
        containerSubscribe: {
            padding: "0px 40px",
            color:"white",
            display:"flex",
            flexDirection:"column",justifyContents:"center",alignItems:"center", gap:40,
        },
        pwaStepRow: {
            display: "flex",
            marginLeft: 24,
            marginRight: "auto",
            gap: 8,
            alignItems: "center",
        },
        pwaStepRowCenter: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        orangeBanner: {
            borderRadius: 88,
            background: "linear-gradient(180deg, #E8B365 0%, #E98D07 100%)",
            padding: "2px 10px",
        },
        pwaSuccessPage: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            marginTop: 50,
        },
        pwaLoadingPage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
        },
    };
}
