import React from 'react'

import Utils from '../modules/Utils'
import Api from '../modules/Api'
import Styles from '../modules/Styles'
import Errors from '../modules/Errors'
import StylesPlatform from '../modules/StylesPlatform'
import StyleUtils from '../modules/StyleUtils'

import Modal from '../elements/Modal'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import BloatedCircleDefs from '../elements/BloatedCircleDefs';

import Div from './Div'
import BuySoin from './BuySoin'
import SoinMuseum from './SoinMuseum'

export default class ModalNFT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            soinModalView: "main",
        }
    }

    openNftLink() {
        const nft = this.props.nft;
        if(nft['json'].indexOf('permalink') != -1) {
            let data = JSON.parse(nft['json']);
            window.open(data['permalink'], "_blank");
            return;
        }
    }

    buySoin() {
        this.openNftLink();
    }

    viewSoinInMuseum() {
        this.setState({soinModalView: "soinMuseum"});
    }

    backToMain() {
        this.setState({soinModalView: "main"});
    }

    renderModalNFTComponents() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const nftMainContainer = StyleUtils.getMediaStyle('nftMainContainer', sty.nftMainContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const nftImage = StyleUtils.getMediaStyle('nftImage', sty.nftImage, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const nftInfo = StyleUtils.getMediaStyle('nftInfo', sty.nftInfo, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        switch(st.soinModalView) {
            case "main":
                return (
                    <Div className="modalNftMainView" style={nftMainContainer}>
                        <Div>
                            <img
                                className="nftImage"
                                src={pr.nft.image_url}
                                title={pr.nft.title}
                                style={{...sty.nftImage, ...StylesPlatform.cursorPointer}}
                                onClick={this.openNftLink.bind(this)}
                            />
                        </Div>
                        <Div className="nftInfo" style={nftInfo}>
                            <Div style={sty.nftTitle}>{pr.nft.title}</Div>
                            <Div style={sty.nftDescription}>{pr.nft.description}</Div>
                            {false ?
                                <Div style={sty.nftCreated}>{pr.nft.created_at}</Div>
                                :
                                null
                            }
                        </Div>
                    </Div>
                )
            case "buySoin":
                return (
                    <BuySoin />
                )
            case "soinMuseum":
                return (
                    <SoinMuseum />
                )
        }
    }

    renderBackToMain() {
        return (
            <Button onClick={this.backToMain.bind(this)}>
                <Icon icon="chevron-left" style={{marginRight: 14}} size={20} /> Back
            </Button>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                onRequestClose={pr.onRequestClose.bind(this)}
                className="ModalNftComponent"
                color="purple"
            >
                <BloatedCircleDefs
                    scales={[200, 300]}
                />
                {st.soinModalView !== "main" ? this.renderBackToMain() : null}

                {this.renderModalNFTComponents()}

                {st.soinModalView === "main" ?
                    <Div className="nftSoinButtons" style={sty.nftSoinButtons}>
                        <Button style={sty.buyButton} onClick={this.buySoin.bind(this)}>
                            Buy <Icon icon="check-square" style={{marginLeft: 15}} />
                        </Button>
                        { false ?
                            <Button color="white" style={sty.viewInMuseumButton} onClick={this.viewSoinInMuseum.bind(this)}>
                                View In Museum
                            </Button>
                            :
                            null
                        }
                    </Div>
                    :
                    null
                }
            </Modal>
        );
    }

    styles = {
        buyButton: {
            marginLeft: 10,
            marginRight: 10,
        },
        viewInMuseumButton: {
            marginLeft: 10,
            marginRight: 10,
        },
        nftSoinButtons: {
            display: "flex",
        },
        nftMainContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 40,
        },
        nftMainContainer_sm: {
            flexDirection: "column",
        },
        nftImage: {
            marginBottom: 0,
            width: 300,
            clipPath:"url(#bloated-circle-300)",
        },
        nftImage_sm: {
            marginBottom: 20,
            width: 200,
            clipPath:"url(#bloated-circle-200)",
        },
        nftInfo: {
            marginLeft: 40,
            paddingRight: 20,
            marginTop: 0,
        },
        nftInfo_sm: {
            marginLeft: 0,
            paddingRight: 0,
            marginTop: 20,
        },
        nftTitle: {
            fontSize: 20,
            marginBottom: 14,
        },
        nftDescription: {
            fontSize: 14,
            marginBottom: 6,
        },
        nftCreated: {
            fontSize: 10,
        },

    }
}
