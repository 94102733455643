import React from 'react';
import ReactModal from 'react-modal';

import Styles from '../modules/Styles';

import videoSlashSolid from '../assets/icons/video-slash.png';
import videoSolid from '../assets/icons/video.png';

import audioSlashSolid from '../assets/icons/audio-slash.png';
import audioSolid from '../assets/icons/audio.png';

import gear from '../assets/icons/gear.png';
import hand from '../assets/icons/hand.png';
import handDown from '../assets/icons/hand down.png';
import close from '../assets/icons/close.png';
import lock from '../assets/icons/lock.png';
import personalInfo from '../assets/icons/personal-info.png';
import wallet from '../assets/icons/wallet.png';
import error from '../assets/icons/error.png';
import checkCircle from '../assets/icons/check-circle.png';
import dotsVertical from '../assets/icons/dots-vertical.png';
import questionMark from '../assets/icons/question-mark.png';
import smileyFace from '../assets/icons/smiley-face.png';
import apLink from '../assets/icons/ap-link.png';
import settingsDelete from '../assets/icons/settings-delete.png';
import apChevronUp from '../assets/icons/ap-chevron-up.png';
import apChevronDown from '../assets/icons/ap-chevron-down.png';
import apChevronLeft from '../assets/icons/ap-chevron-left.png';
import apChevronRight from '../assets/icons/ap-chevron-right.png';
import waveform from '../assets/icons/waveform.png';
import shareUp from '../assets/icons/share-up.png';

import instagram from '../assets/icons/social_icons/instagram.png';
import tiktok from '../assets/icons/social_icons/tiktok.png';
import youtube from '../assets/icons/social_icons/youtube.png';
import twitter from '../assets/icons/social_icons/twitter.png';
import twitch from '../assets/icons/social_icons/twitch.png';
import spotify from '../assets/icons/social_icons/spotify.png';
import patreon from '../assets/icons/social_icons/patreon.png';
import applemusic from '../assets/icons/social_icons/applemusic.png';
import discord from '../assets/icons/social_icons/discord.png';
import onlyfans from '../assets/icons/social_icons/onlyfans.png';

import instagramCircle from '../assets/icons/social_icons_circles/instagram.png';
import tiktokCircle from '../assets/icons/social_icons_circles/tiktok.png';
import youtubeCircle from '../assets/icons/social_icons_circles/youtube.png';
import twitterCircle from '../assets/icons/social_icons_circles/twitter.png';
import twitchCircle from '../assets/icons/social_icons_circles/twitch.png';
import spotifyCircle from '../assets/icons/social_icons_circles/spotify.png';
import patreonCircle from '../assets/icons/social_icons_circles/patreon.png';
import applemusicCircle from '../assets/icons/social_icons_circles/applemusic.png';
import discordCircle from '../assets/icons/social_icons_circles/discord.png';
import onlyfansCircle from '../assets/icons/social_icons_circles/onlyfans.png';
import linkCircle from '../assets/icons/social_icons_circles/link.png';

export default class Icon extends React.Component {
    render() {
        const pr = this.props;
        let sty = this.styles;
        let fontSize = pr.size ? {fontSize: pr.size} : null;
        let width = pr.size ? {width: pr.size} : null;
        let color = pr.color ? {color: pr.color} : null;

        switch(pr.icon) {
            case "video-slash":
                return <img {...pr} src={videoSlashSolid} alt={pr.alt ?? "Video button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "video":
                return <img {...pr} src={videoSolid} alt={pr.alt ?? "Video button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "audio-slash":
                return <img {...pr} src={audioSlashSolid} alt={pr.alt ?? "Audio button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "audio":
                return <img {...pr} src={audioSolid} alt={pr.alt ?? "Audio button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "gear":
                return <img {...pr} src={gear} alt={pr.alt ?? "Settings button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "hand":
                return <img {...pr} src={hand} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "hand-down":
                return <img {...pr} src={handDown} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "close":
                return <img {...pr} src={close} alt={pr.alt ?? "Close button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "lock":
                return <img {...pr} src={lock} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "lock-black":
                return <img {...pr} src={lock} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "personal-info":
                return <img {...pr} src={personalInfo} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "wallet":
                return <img {...pr} src={wallet} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "error":
                return <img {...pr} src={error} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "check-circle":
                return <img {...pr} src={checkCircle} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "question-mark":
                return <img {...pr} src={questionMark} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "smiley-face":
                return <img {...pr} src={smileyFace} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "ap-link":
                return <img {...pr} src={apLink} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "settings-delete":
                return <img {...pr} src={settingsDelete} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "instagram":
                return <img {...pr} src={instagram} alt={pr.alt ?? "Instagram social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "tiktok":
                return <img {...pr} src={tiktok} alt={pr.alt ?? "TikTok social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "youtube":
                return <img {...pr} src={youtube} alt={pr.alt ?? "YouTube social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "twitter":
                return <img {...pr} src={twitter} alt={pr.alt ?? "Twitter social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "twitch":
                return <img {...pr} src={twitch} alt={pr.alt ?? "Twitch social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "patreon":
                return <img {...pr} src={patreon} alt={pr.alt ?? "Patreon social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "discord":
                return <img {...pr} src={discord} alt={pr.alt ?? "Discord social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "spotify":
                return <img {...pr} src={spotify} alt={pr.alt ?? "Spotify social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "onlyfans":
                return <img {...pr} src={onlyfans} alt={pr.alt ?? "OnlyFans social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "applemusic":
                return <img {...pr} src={applemusic} alt={pr.alt ?? "Apple Music social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "instagram-circle":
                return <img {...pr} src={instagramCircle} alt={pr.alt ?? "Instagram social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "tiktok-circle":
                return <img {...pr} src={tiktokCircle} alt={pr.alt ?? "TikTok social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "youtube-circle":
                return <img {...pr} src={youtubeCircle} alt={pr.alt ?? "YouTube social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "twitch-circle":
                return <img {...pr} src={twitchCircle} alt={pr.alt ?? "Twitch social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "patreon-circle":
                return <img {...pr} src={patreonCircle} alt={pr.alt ?? "Patreon social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "discord-circle":
                return <img {...pr} src={discordCircle} alt={pr.alt ?? "Discord social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "spotify-circle":
                return <img {...pr} src={spotifyCircle} alt={pr.alt ?? "Spotify social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "onlyfans-circle":
                return <img {...pr} src={onlyfansCircle} alt={pr.alt ?? "OnlyFans social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "applemusic-circle":
                return <img {...pr} src={applemusicCircle} alt={pr.alt ?? "Apple Music social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "twitter-circle":
                return <img {...pr} src={twitterCircle} alt={pr.alt ?? "Twitter social icon"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "link-circle":
                return <img {...pr} src={linkCircle} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "ap-chevron-up":
                return <img {...pr} src={apChevronUp} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "ap-chevron-down":
                return <img {...pr} src={apChevronDown} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "ap-chevron-left":
                return <img {...pr} src={apChevronLeft} alt={pr.alt ?? "Back button"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "ap-chevron-right":
                return <img {...pr} src={apChevronRight} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "dots-vertical":
                return <img {...pr} src={dotsVertical} alt={pr.alt} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "waveform":
                return <img {...pr} src={waveform} alt={pr.alt ?? "Music waveform"} style={{...sty, ...pr.style, ...width, ...color}} />;
            case "share-up":
                return <img {...pr} src={shareUp} alt={pr.alt ?? "Share button"} style={{...sty, ...pr.style, ...width, ...color}} />;
        }

        return (
            <i
                {...pr}
                className={`fa fa-${pr.icon} ${pr.className}`}
                style={{...sty, ...pr.style, ...fontSize, ...color}}
            >
                {pr.children}
            </i>
        );
    }

    styles = {
        fontSize: 24,
    }
}
