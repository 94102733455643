import React from 'react'

import Utils from '../modules/Utils'
import Api from '../modules/Api'
import Colors from '../modules/Colors'
import Styles from '../modules/Styles'
import Errors from '../modules/Errors'
import Image from '../elements/Image';
import ReactPlayer from "react-player";

import Modal from '../elements/Modal'

import Div from './Div'
import Register from './Register'

export default class ModalFestival2021a extends React.Component {
    getNewUserAfterRegisterSuccess(newUser) {
        const pr = this.props;
        pr.getNewUserAfterRegisterSuccess(newUser);
        pr.onRequestClose();
    }

    render() {
        const pr = this.props;
        console.log("RANK", Utils.get(this.props, "current_user_afterpartynft.rank"));
        return (
            <Modal
                onRequestClose={pr.onRequestClose.bind(this)}
            >
                <Div>
                    { true ?
                        <Image style={{width:250}} src={"/images/afterparty-soin-front-512w.png"} />
                        :
                        <Div style={{width:250}}>
                            <ReactPlayer
                                type="video/mp4"
                                url={"/images/afterparty-festival-1-coin-spin-1.4.mp4"}
                                width="250px"
                                height="250px"
                                playing={true}
                                controls={false}
                                loop={true}
                                muted={true}
                          />

                         </Div>
                    }
                </Div>
                <Div style={this.styles.headline}>Festival NFT Giveaway</Div>
                <Div style={this.styles.rankContainer}>
                    <Div style={{flex:3,paddingLeft:10,}}>You Currently Rank</Div>
                    <Div style={{flex:1,fontSize:20,lineHeight:"20px",}}>{Utils.get(this.props, "current_user_afterpartynft.rank")}</Div>
                </Div>

                <Div style={this.styles.info}>💎 Over 10 TOTAL Creator Coins across all Festival Creators get Platinum NFT</Div>
                <Div style={this.styles.info}>🏆 Over 4 TOTAL Creator Coins across all Festival Creators get Gold NFT</Div>
                <Div style={this.styles.boost}>Boost your score by holding more Afterparty coin and artist coins</Div>
            </Modal>
        );
    }
    styles = {
        headline: {
            padding: 20,
            fontSize: 36,
            fontWeight: "bold",
        },
        rankContainer: {
            color: "white",
            width: "50%",
            fontSize: 14,
            fontWeight: "bold",
            backgroundImage: "linear-gradient(135deg, #323273 0%, #CE177B 36.57%, #FFC749 77.62%, #FFC749 100%)",
            borderRadius: 20,
            //height: 40,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: "flex",
            marginBottom: 20,
        },
        info: {
            marginBottom: 10,
        },
        boost: {
            marginBottom: 10,
            color: Colors.magenta,
        },
    };
}
