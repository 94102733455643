import React from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';
import GlobalState from '../modules/GlobalState';

import Div from '../components/Div';
import Image from './Image';
import Span from './Span';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        /*
        if(!window.already) {
            window.already = true;
            GlobalState.push("ticketStage", 0);
        }
        */
    }
    componentDidMount() {

    }

    getButtonColors() {
        const pr = this.props;
        const sty = this.styles;

        if(pr.size == "text") {
            switch(pr.color) {
                case "white":
                    return sty.whiteTextButton;
                default:
                    return sty.purpleTextButton;
            }
        }

        switch(pr.color) {
            case "pink":
                return sty.pinkButton;
            case "orange":
                return sty.orangeButton;
            case "red":
                return sty.redButton;
            case "transparentPinkButton":
                return sty.transparentPinkButton;
            case "white":
                return sty.whiteButton;
            case "black":
                return sty.blackButton;
            case "indigoLight":
                return {...sty.purpleButton, ...{backgroundColor:Colors.indigoLight}};
            case "purple":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purple}};
            case "purpleTextWhiteButton":
                return sty.purpleTextWhiteButton;
            case "purpleLight1":
                return sty.purpleButton;
            case "purpleLight2":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight2}};
            case "purpleLight3":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight3, color: "black"}};
            case "purpleLight4":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight4, color: "black"}};
            case "purpleLight5":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight5, color: "black"}};
            case "pinkGradient":
                return {background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)", color: "white"};
            case "redGradient":
                return {background: "linear-gradient(180deg, #FF007E 0%, #FF3D00 100%)", color: "white"};
            default:
                return sty.pinkButton;
        }
    }

    getButtonSize() {
        const sty = this.styles;

        switch(this.props.size) {
            case "smaller":
                return sty.smallerButton;
            case "small":
                return sty.smallButton;
            case "text":
                return sty.textButton;
            default:
                return sty.defaultButton;
        }
    }

    onClick() {
        const pr = this.props;

        if(pr.disabled) {
            return;
        }

        if(pr.onClick) {
            pr.onClick();
        }
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        const buttonColors = this.getButtonColors();
        const buttonSize = this.getButtonSize();

        let buttonStyles = {...sty.button, ...StylesPlatform.cursorPointer, ...buttonColors, ...buttonSize};

        let disabledStyle = pr.disabled ? sty.disabled : null;

        if(pr.color === "pinkGradient" && pr.disabled) {
            disabledStyle = {opacity: 0.3};
        }

        if(pr.type === "outlined" && pr.disabled) {
            disabledStyle = {opacity: 0.3};
        }

        if(pr.type === "text" && pr.disabled) {
            disabledStyle = {opacity: 0.3};
        }

        let cursorPointer = StylesPlatform.cursorPointer;

        if(pr.disabled) {
            cursorPointer = StylesPlatform.cursorAuto;
        }

        let subtitleStyle = Utils.deepCopy(sty.subtitleStyle);
        if(Utils.get(pr, "subtitleColor")) {
            subtitleStyle.color = Utils.get(pr, "subtitleColor")
        }

        if(pr.type === "text") {
            if(pr.isSpan) {
                return (
                    <Span
                        onClick={this.onClick.bind(this)}
                        style={{...sty.textButton, ...pr.style, ...cursorPointer}}
                        className={`buttonElement ${Utils.get(pr, "className", "")}`}
                    >
                        {pr.children}
                    </Span>
                )
            } else {
                return (
                    <Div
                        onClick={this.onClick.bind(this)}
                        style={{...sty.textButton, ...pr.style, ...disabledStyle, ...cursorPointer}}
                        className={`buttonElement ${Utils.get(pr, "className", "")}`}
                    >
                        {pr.children}
                    </Div>
                )
            }
        }

        if(pr.type === "close") {
            return (
                <Div
                    onClick={this.onClick.bind(this)}
                    style={{...sty.closeButton, ...pr.style, ...cursorPointer}}
                    className={`buttonElement ${Utils.get(pr, "className", "")}`}
                >
                    ×
                </Div>
            )
        }

        if(pr.type === "splash") {
            return (
                <Div
                    onClick={this.onClick.bind(this)}
                    style={{...sty.splashButton, ...pr.style, ...cursorPointer}}
                    className={`buttonElement ${Utils.get(pr, "className", "")}`}
                >
                    {pr.children}
                </Div>
            )
        }

        if(pr.type === "outlined") {
            return (
                <Div
                    onClick={this.onClick.bind(this)}
                    style={{...buttonStyles, ...sty.outlinedButton, ...pr.style, ...disabledStyle, ...cursorPointer}}
                    className={`buttonElement ${Utils.get(pr, "className", "")}`}
                >
                    {pr.children}
                </Div>
            )
        }

        if(pr.subtitle) {
            return (
                <Div
                    onClick={this.onClick.bind(this)}
                    style={{...buttonStyles, ...sty.subtitleOuterStyle, ...{padding: "8px 20px"}, ...disabledStyle, ...pr.style, ...cursorPointer}}
                    className={`buttonElement ${Utils.get(pr, "className", "")}`}
                >
                    <Div>
                        {pr.children}
                    </Div>
                    <Div className="subtitleButtonText" style={subtitleStyle}>
                        {pr.subtitle}
                    </Div>
                </Div>
            )
        }

        return (
            <Div
                onClick={this.onClick.bind(this)}
                style={{...buttonStyles, ...disabledStyle, ...pr.style, ...cursorPointer}}
                className={`buttonElement ${Utils.get(pr, "className", "")}`}
            >
                {pr.children}
            </Div>
        );
    }

    styles = {
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            flexShrink: 0,
            fontWeight: "bold",
            width: "fit-content",
            fontWeight: 400,
            fontFamily: "Nyata",
        },
        subtitleOuterStyle: {
            flexDirection: "column",
        },
        subtitleStyle: {
            fontSize: 12,
        },
        defaultButton: {
            padding: "15px 20px 14px 20px",
            borderRadius: 100,
            fontSize: 16,
            minWidth: 180,
        },
        smallButton: {
            padding: "9px 14px 9px 14px",
            borderRadius: 100,
            fontSize: 14,
            minWidth: 44,
            fontWeight: 400,
        },
        smallerButton: {
            padding: "2px 14px",
            borderRadius: 100,
            fontSize: 14,
            minWidth: 44,
            fontWeight: 400,
        },
        textButton: {
            fontWeight: 600,
            textDecoration: "underline",
        },
        outlinedButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            border: "1px solid white",
            borderRadius: 100,
            background: "none",
            width: "fit-content",
            maxWidth: 400,
            fontWeight: 400,
        },
        splashButton: {
            fontFamily: "Mont Demo",
            fontSize: 20,
            borderRadius: 100,
            fontWeight: 700,
            width: "fit-content",
            paddingTop: 18,
            paddingBottom: 14,
            paddingLeft: 35,
            paddingRight: 35,
            minWidth: 250,
            textAlign: "center",
        },
        disabled: {
            backgroundColor: "#aaa",
            color: "#555",
        },

        // Button colors
        pinkButton: {
            backgroundColor: Colors.magenta,
            color: "white",
        },
        orangeButton: {
            backgroundColor: "#E04629",
            color: "white",
        },

        transparentPinkButton: {
            backgroundColor: "rgba(201, 45, 122 ,0.5)",
            color: "white",
        },
        whiteButton: {
            backgroundColor: "white",
            color: Colors.indigoLighter,
            border: this.props.noBorder ? "none" : "1px solid #ccc",
        },
        blackButton: {
            backgroundColor: "black",
            color: "white",
        },
        purpleButton: {
            backgroundColor: Colors.purpleLight1,
            color: "white",
        },
        purpleTextWhiteButton: {
            backgroundColor: "white",
            color: Colors.purpleLight1,
        },
        whiteTextButton: {
            color: "white",
        },
        purpleTextButton: {
            color: Colors.purple,
        },
        redButton: {
            backgroundColor: Colors.red,
        },
        closeButton: {
            fontSize: 32,
            color: Colors.white,
            writable: true,
        },
    };
}
