import React from 'react';

import ReactAudioPlayer from 'react-audio-player';
import Lottie from "lottie-react-web"

import Api from '../modules/Api';
import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';

import Image from './Image';
import Modal from './Modal';
import Icon from './Icon';
import Spinner from './Spinner';
import Button from './Button';
import AfterpartyLogo from './AfterpartyLogo';
import Checkbox from './Checkbox';

import waveformLottie from '../assets/waveform.json';
import waveformLottieBlack from '../assets/waveform-black.json';
import waveformLoadLottie from '../assets/lottie/waveform load.json';
import shareShoutout from '../assets/images/shoutout/share-shoutout.png';
import copyShoutout from '../assets/images/shoutout/copy-shoutout.png';
import downloadShoutout from '../assets/images/shoutout/download-shoutout.png';
import defaultProfileImage from "../assets/images/defaultProfileImage.png"

import Div from '../components/Div';

export default class AudioChip extends React.Component {
    constructor(props) {
        super(props);
        // NOTE: `format` types: playerAndWaveform, fullAudioChip

        this.state = {
            isPlaying: false,

            showAudioChipMenu: false,

            showIgSharePage: props.showIgSharePage ?? false,
            showSecondShareModal: false,

            isTextHidden: false,
            showCopyMessage: false,
        };

        if (props.localAudioPlayer) {
            const audioUrl = Utils.get(props, "src");
            this.audioPlayer = new Audio(audioUrl);
            this.audioPlayer.addEventListener('ended', this.onAudioEnded.bind(this));
            this.childAudioEndedCallbacks = [];
        }
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        this.setupAudioPlayer();

        if (pr.setAudioEndedCallback) {
            pr.setAudioEndedCallback(this.stopPlaying.bind(this));
        } else {
            this.setAudioEndedCallback(this.stopPlaying.bind(this));
        }

        this.setupLocalAudioPlayer(pr);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        if (!pr.playFlag && newProps.playFlag) {
            // NOTE: if the firstplay cookie changes from undefined to 1...
            if (pr.autoPlay) {
                // NOTE: if autoPlay is on, play the sound, which will trigger the cookie to be set
                if (!st.isPlaying) {
                    // NOTE: if it's already playing from componentDidMount, dont play again in UNSAFE_componentWillReceiveProps
                    pr.playAudio(pr.src);
                }
                this.setState({
                    isPlaying: true,
                });
            } else if (!pr.autoPlay && this.getHasFirstPlay() != 1) {
                // NOTE: otherwise, don't play the sound, but still set the cookie
                this.setFirstPlayMessage();
            }
        }

        this.setupLocalAudioPlayer(newProps);
    }

    componentWillUnmount() {
        this.stopPlaying();
    }

    flashClipboardButton() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            showCopyMessage: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    showCopyMessage: false,
                });
            }, 3000);
        });
    }

    playAudio(src) {
        if (src) {
            this.audioPlayer.src = src;
        }
        this.audioPlayer.play();
    }

    pauseAudio() {
        this.audioPlayer.pause();
    }

    setChildAudioEndedCallback(callback) {
        this.childAudioEndedCallbacks.push(callback);
    }

    onAudioEnded() {
        if (this.childAudioEndedCallbacks) {
            this.childAudioEndedCallbacks.forEach(callback => {
                if (callback) {
                    callback();
                }
            });
        }
    }

    setAudioEndedCallback(callback) {
        if (callback && this.childAudioEndedCallbacks) {
            this.childAudioEndedCallbacks.push(callback);
        }
    }

    onAudioEnded() {
        this.childAudioEndedCallbacks.forEach(callback => {
            if (callback) {
                callback();
            }
        });
    }

    igStoryShare() {
        window.location.href = "instagram://story-camera";
    }

    getHasFirstPlay() {
        const pr = this.props;
        if (!Utils.get(pr, "user.id")) {
            return null;
        }

        const userId = Utils.get(pr, "user.id");
        const artistId = Utils.get(pr, "artist.id");

        return Utils.getCookie(`has_already_firstplay_${userId}_${artistId}`);
    }

    getFirstPlayMessage() {
        const pr = this.props;
        const userId = Utils.get(pr, "user.id");
        if (!userId) {
            return null;
        }
        const artistId = Utils.get(pr, "artist.id");
        const roomChatId = Utils.get(pr, "roomChat.id");

        return Utils.getCookie(`firstplay_${userId}_${artistId}_${roomChatId}`);
    }

    setFirstPlayMessage() {
        const pr = this.props;
        const userId = Utils.get(pr, "user.id");
        if (!userId) {
            return null;
        }
        const artistId = Utils.get(pr, "artist.id");
        const roomChatId = Utils.get(pr, "roomChat.id");

        Utils.setCookie(`firstplay_${userId}_${artistId}_${roomChatId}`, 1);
    }

    setupLocalAudioPlayer(newProps) {
        const pr = this.props;

        if (pr.localAudioPlayer && !this.audioPlayer.src && newProps.src) {
            this.audioPlayer.src = newProps.src;
        }
    }

    setupAudioPlayer() {
        const pr = this.props;
        const st = this.state;

        if (Utils.empty(pr.src)) {
            return;
        }

        if (pr.isWelcome) {
            // NOTE: do nothing
        } else {
            if (pr.autoPlay && this.getFirstPlayMessage() != 1) {
                // NOTE: if firstplay is NOT found, then play the sound and set the cookie
                pr.playAudio(pr.src);
                this.setFirstPlayMessage();
                this.setState({
                    isPlaying: true,
                });
            } else if (!pr.autoPlay && this.getHasFirstPlay() != 1) {
                // NOTE: if autoplay is off, set the cookie anyways
                this.setFirstPlayMessage();
            }
        }
    }

    stopPlaying() {
        // console.log("stopPlaying >>>>>", this.props.roomChat.id);
        this.setState({
            isPlaying: false,
        });
    }

    showSecondShareModal() {
        this.setState({
            showSecondShareModal: true,
        });
    }

    playSound() {
        const pr = this.props;
        const st = this.state;

        Utils.setCookie("audioChipPulse", 1);

        let isPlaying = st.isPlaying;

        let timeDelay = 2000;
        if (pr.isShoutout) {
            timeDelay = 0;
        }
        if (st.showIgSharePage) {
            this.setState({ isPlaying: !isPlaying });
            setTimeout(() => {
                if (isPlaying) {
                    if (pr.pauseAudio) {
                        pr.pauseAudio();
                    } else {
                        this.pauseAudio();
                    }
                } else {
                    if (pr.playAudio) {
                        pr.playAudio(pr.src);
                    } else {
                        this.playAudio(pr.src);
                    }
                }
            }, timeDelay);
        } else {
            // NOTE: this only happens when the user clicks the play button
            if (isPlaying) {
                if (pr.pauseAudio) {
                    pr.pauseAudio();
                } else {
                    this.pauseAudio();
                }
                this.setState({ isPlaying: false });
            } else {
                if (pr.playAudio) {
                    pr.playAudio(pr.src);
                } else {
                    this.playAudio(pr.src);
                }
                this.setState({ isPlaying: true });
            }

            // NOTE: if the user plays the audio message, the cookie should be set
            this.setFirstPlayMessage();

            if (pr.audioPlayCallback) {
                const artistId = Utils.get(pr, "artist.id");
                const roomChatId = Utils.get(pr, "roomChat.id");

                pr.audioPlayCallback(artistId, roomChatId);
            }
        }
    }

    getAudioLength() {
        const pr = this.props;
        const st = this.state;


        if (pr.audioPlayer || this.audioPlayer) {
            let duration = "";
            if (pr.audioPlayer) {
                duration = Utils.get(pr, "audioPlayer.duration", "");
            } else {
                duration = Utils.get(this, "audioPlayer.duration", "");
            }

            if (isNaN(duration)) {
                return "0:00";
            }

            const minutes = Math.trunc(duration / 60);
            let seconds = Math.trunc(duration % 60);
            seconds = seconds < 10 ? "0" + seconds : seconds;

            return `${minutes}:${seconds}`;
        } else {
            return "";
        }
    }

    showAudioChipMenu() {
        this.setState({
            showAudioChipMenu: true,
        });
    }

    hideAudioChipMenu() {
        this.setState({
            showAudioChipMenu: false,
        });
    }

    shareToInstagram() {
        this.setState({
            showIgSharePage: true,
        });
    }

    closeIgSharePage() {
        this.setState({
            showIgSharePage: false,
            showSecondShareModal: false,
        });
    }

    toggleHideText() {
        this.setState({
            isTextHidden: !this.state.isTextHidden,
        });
    }

    replayMessage() {
        this.setState({
            showSecondShareModal: false,
        });
    }

    getWaveformColor() {
        const pr = this.props;
        const st = this.state;
        if (pr.audioChipColor == "black") {
            return waveformLottieBlack;
        }

        return waveformLottie;
    }

    submitFeedback(feedbackType) {
        const pr = this.props;
        const st = this.state;

        const feedbackData = [{
            feedback_type_id: feedbackType.id,
            feedback_type_title: feedbackType.title,
            room_chat_id: Utils.get(pr, "roomChat.id"),
            artist_id: Utils.get(st.artist, "id"),
            feedback_value: 1,
        }];

        Api.postAiFeedback(feedbackData, (res) => {
            if (Utils.get(res, "errors", []).length) {
                console.log("ERRORS: ", Utils.get(res, "errors"));
            }

            this.setState({
                showAudioChipMenu: false,
            });

            if (pr.submitFeedback) {
                pr.submitFeedback(feedbackType);
            }
        });
    }

    ignorePulse() {
        const ignoreUrlArray = ["p/creator_shoutouts"];
        for (let index = 0; index < ignoreUrlArray.length; index++) {
            const ignoreUrlString = ignoreUrlArray[index];
            if (Utils.getCurrentUrl().includes(ignoreUrlString)) {
                return true;
            }
        }

        return false;
    }

    renderChatBubbleLottie() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE: this is beneath the message
        return (
            <Lottie
                width={50}
                options={{
                    animationData: chatLottie,
                    loop: true,
                    autoplay: true,
                }}
                speed={1}
                style={{ margin: 0 }}
            />
        );
    }

    renderPlayPauseButton(size) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const sizeStyle = {
            width: size ?? 32,
            height: size ?? 32,
        }

        return (
            <>
                {st.isPlaying ?
                    <Image
                        src="https://dhwk6gixvyfl3.cloudfront.net/upload_24d87e497d22ef63266e25e2f300843b.png"
                        className="pauseButton playerAndWaveformOnly"
                        style={{ ...sty.playerButton, ...StylesPlatform.cursorPointer, ...sizeStyle }}
                        onClick={this.playSound.bind(this)}
                    />
                    :
                    <Image
                        src="https://dhwk6gixvyfl3.cloudfront.net/upload_501ccc49c8b908bdc4ac6eeb394a780e.png"
                        className={`playerButton playerAndWaveformOnly ${Utils.getCookie("audioChipPulse") == 1 || this.ignorePulse() ? "" : "pinkPulse"}`}
                        style={{ ...sty.playerButton, ...StylesPlatform.cursorPointer, ...sizeStyle }}
                        onClick={this.playSound.bind(this)}
                    />
                }
            </>
        )
    }

    renderPlayerAndWaveform() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE: player and player And Wave form Only
        return (
            <Div className="audioChipElement" style={sty.audioChipElementMinimal}>
                <Div className="playAreaContainer" style={sty.playAreaContainer}>
                    {this.renderPlayPauseButton()}
                    {pr.isLoading || pr.src == undefined ?
                        <Spinner
                            message="Loading Audio Clip"
                            size={24}
                        />
                        :
                        <Lottie
                            width={86}
                            options={{
                                animationData: this.getWaveformColor(),
                                loop: st.isPlaying,
                                autoplay: false,
                            }}
                            style={{ margin: 0, marginLeft: 5 }}
                            speed={st.isPlaying ? 1 : 0}
                        />
                    }
                </Div>
            </Div>
        );
    }

    renderIgSharePage() {
        const pr = this.props;
        const st = this.state;
        let sty = Utils.deepCopy(this.styles);

        let igShareInnerContainer = Utils.deepCopy(sty.igShareInnerContainer);
        if (!pr.text || (st.isTextHidden && st.isPlaying)) {
            igShareInnerContainer.marginTop = "auto";
        }

        // Jaden image
        let backgroundImage = "";
        let bgOverride = Utils.get(pr, "artist.json_data.aiShareBackgroundImageUrl");
        if (bgOverride) {
            backgroundImage = "url(" + bgOverride + ")";
        }

        let igSharePage = sty.igSharePage;
        if (pr.screen.mobile) {
            igSharePage.height = Utils.cssCalc();
        }

        if (pr.isShoutout) {
            const igSharePageShoutoutOverrideStyle = {
                position: "static",
                padding: 12,
            };

            igSharePage = {
                ...igSharePage,
                ...igSharePageShoutoutOverrideStyle,
                ...{ height: Utils.cssCalc(106) },
                ...{ borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
            }
        }

        let igShareBody = (
            <Div
                className="igSharePage"
                style={{ ...igSharePage, ...{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: backgroundImage, backgroundColor: "black" } }}
            >
                {pr.text ?
                    <>
                        {st.isTextHidden && st.isPlaying ?
                            null
                            :
                            <Div className="igShareText" style={sty.igShareText}>
                                {pr.text}
                            </Div>
                        }
                    </>
                    :
                    null
                }

                <Div className="igShareInnerContainer" style={igShareInnerContainer}>
                    <Div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 24, }}>
                        <Image
                            src={pr.avatar}
                            className="artistAvatar"
                            style={sty.artistAvatar}
                        />
                        <Div className="igShareArtistTitle" style={{ fontSize: 17, padding: 8, marginRight: 10, fontWeight: 400 }}>
                            {Utils.get(pr, "artist.title")}
                        </Div>
                        <Div className="aiBadge" style={sty.aiBadge}>
                            AI
                        </Div>
                    </Div>
                    <Div className="igShareBody" style={sty.igShareBody}>
                        "{pr.body}"
                    </Div>
                    <Div className="waveformContainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: -20 }}>
                        {/* NOTE: IG Share page */}
                        {this.renderPlayPauseButton(50)}
                        <Lottie
                            width={196}
                            options={{
                                animationData: waveformLottie,
                                loop: st.isPlaying,
                                autoplay: false,
                            }}
                            speed={st.isPlaying ? 1 : 0}
                            style={{ margin: 0 }}
                        />
                        <Div className="timestamp" style={{ ...sty.timestamp, ...{ fontSize: 19, marginLeft: 0 } }}>
                            {this.getAudioLength()}
                        </Div>
                    </Div>
                    <Div className="generatedByText" style={{ fontWeight: 300, marginBottom: 8, marginTop: -20 }}>
                        Generated by
                    </Div>
                    <AfterpartyLogo
                        width={92}
                    />
                </Div>

                {!st.isPlaying && !pr.isShoutout ?
                    <Modal
                        screen={pr.screen}
                        className="showCreateSignInModal"
                        onRequestClose={() => { this.setState({ showCreateSignInModal: false }); }}
                        hideCloseButton
                        bottom
                    >
                        {st.showSecondShareModal ?
                            <>
                                <Button
                                    type="outlined"
                                    size="small"
                                    style={{ backgroundColor: "Color.indigoLight" }}
                                    onClick={this.replayMessage.bind(this)}
                                >
                                    REPLAY MESSAGE <Icon icon="refresh" size={15} style={{ marginLeft: 6 }} />
                                </Button>
                                <Div style={{ fontSize: 20, fontWeight: 400, marginTop: 10, marginBottom: 16 }}>
                                    Once you stop your screen recording:
                                </Div>

                                <Button
                                    color="pinkGradient"
                                    style={{ width: "100%", marginBottom: 8 }}
                                    onClick={this.igStoryShare.bind(this)}
                                >
                                    Share to Instagram <Icon icon="instagram" size={15} style={{ marginLeft: 10 }} />
                                </Button>
                                <Button
                                    type="outlined"
                                    style={{ width: "100%" }}
                                    onClick={this.closeIgSharePage.bind(this)}
                                >
                                    Back to Chat
                                </Button>
                            </>
                            :
                            <>
                                <Div
                                    style={{
                                        padding: 20,
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={this.closeIgSharePage.bind(this)}
                                >
                                    <Icon
                                        icon="chevron-left"
                                        size={20}
                                        style={null}
                                    />
                                    <Div style={{ fontWeight: 300, fontSize: 18, marginLeft: 8, }}>
                                        Back
                                    </Div>
                                </Div>
                                <Div
                                    className="otherModalTitle"
                                    style={sty.otherModalTitle}
                                >
                                    TO SHARE MESSAGE
                                </Div>
                                <Div
                                    className="stepText"
                                    style={sty.stepText}
                                >
                                    1. Start Your Screen Recording
                                </Div>
                                <Div
                                    className="stepText"
                                    style={sty.stepText}
                                >
                                    2. Tap to Play Audio Below
                                </Div>
                                <Div className="hideMessageRow" style={sty.hideMessageRow}>
                                    <Checkbox
                                        checked={st.isTextHidden}
                                        onClick={this.toggleHideText.bind(this)}
                                        style={{ marginBottom: 0, marginRight: 10 }}
                                    />
                                    <Div
                                        className="otherModalTitleHideMessageText"
                                        style={{ ...sty.otherModalTitle, ...{ marginBottom: 0 } }}
                                    >
                                        Hide My Message
                                    </Div>
                                </Div>
                                <Button
                                    onClick={() => { this.playSound(); this.showSecondShareModal(); }}
                                    color="pinkGradient"
                                    style={{ width: "100%" }}
                                >
                                    Play
                                </Button>
                            </>
                        }
                    </Modal>
                    :
                    null
                }
            </Div>
        );

        const artistTitle = Utils.get(pr, "artist.title");
        const shareTitle = `${artistTitle} Custom Message`;
        const shareText = `${artistTitle} has something to tell you. Tap to see their message on Afterparty!`;
        const shareUrl = Utils.getCurrentUrl();
        const artistId = Utils.get(pr, "artist.id");

        if (pr.isShoutout) {
            return (
                <Div className="shoutoutContainer" style={sty.shoutoutContainer}>
                    {igShareBody}
                    <Div className="isShoutoutFooter" style={sty.isShoutoutFooter}>
                        {pr.screen.mobile ?
                            <Image
                                id="shoutouts_share_sharebutton"
                                className="shoutoutButtons"
                                style={sty.shoutoutButtons}
                                src={shareShoutout}
                                onClick={Utils.shareTray.bind(this, shareTitle, shareText, shareUrl)}
                            />
                            :
                            null
                        }

                        <Div style={{ position: "relative" }}>
                            {st.showCopyMessage ?
                                <Div style={sty.copyToClipboardContainer}>
                                    Copied to Clipboard!
                                </Div>
                                :
                                null
                            }
                            <Image
                                id="shoutouts_share_copy"
                                className="shoutoutButtons"
                                style={sty.shoutoutButtons}
                                src={copyShoutout}
                                onClick={Utils.copyUrl.bind(this, this.flashClipboardButton.bind(this))}
                            />
                        </Div>

                        <Button
                            id="shoutouts_share_generatemore"
                            color="redGradient"
                            style={{ alignSelf: "start" }}
                            onClick={Utils.gotoRoute.bind(this, pr, `p/creator_shoutouts/artist_id-${artistId}`)}

                        >
                            Generate More
                        </Button>
                    </Div>
                </Div>
            );
        }

        // NOTE: had to do this for position fixed bug
        return (
            <Modal
                screen={pr.screen}
                className="igShareBodyModal"
                hideCloseButton
                fullScreen
            >
                {igShareBody}
            </Modal>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (pr.format == "playerAndWaveformOnly") {
            return this.renderPlayerAndWaveform();
        }

        if (st.showIgSharePage) {
            return this.renderIgSharePage();
        }

        let audioChipElement = Utils.deepCopy(sty.audioChipElement);
        if (pr.backgroundImage) {
            audioChipElement.backgroundImage = `url("${pr.backgroundImage}")`;
        } else {
            audioChipElement.background = "linear-gradient(180deg, rgba(93, 57, 172, 0.70) 0%, rgba(253, 115, 73, 0.70) 100%)";
        }

        return (
            <Div className="audioChipElementWrapper" style={sty.audioChipElementWrapper}>
                <Image
                    src={pr.avatar}
                    className="artistAvatar"
                    style={sty.artistAvatar}
                />
                <Div className="audioChipElement" style={audioChipElement}>
                    {/* <Div
                    className="modalMenuContainer"
                    style={{ position: "absolute", top: 25, left: 16 }}
                >
                    {pr.hideAudioChipMenu || !pr.roomChat ?
                        null
                        :
                        st.showAudioChipMenu ?
                            <Icon
                                icon="close"
                                size={20}
                                style={{ ...{ marginLeft: -8 }, ...StylesPlatform.cursorPointer }}
                                onClick={this.hideAudioChipMenu.bind(this)}
                            />
                            :
                            <Icon
                                icon="dots-vertical"
                                size={5}
                                style={{ ...{ marginLeft: -8, paddingLeft: 8, paddingRight: 8 }, ...StylesPlatform.cursorPointer }}
                                onClick={this.showAudioChipMenu.bind(this)}
                            />
                    }

                </Div> */}

                    {st.showAudioChipMenu ?
                        <Div
                            className="audioMenu"
                            style={{ ...sty.audioMenu, ...StylesPlatform.cursorPointer }}
                        >
                            {Utils.get(pr, "feedback.1", []).map((feedbackType, index) => {
                                let audioMenuRow = Utils.deepCopy(sty.audioMenuRow);
                                if (index == Utils.get(pr, "feedback.1", []).length - 1) {
                                    audioMenuRow.borderBottom = "none";
                                }

                                return (
                                    <Div
                                        key={`audioMenuRow-${index}`}
                                        className="imageFeedbackTypeRow"
                                        style={{ ...audioMenuRow, ...StylesPlatform.cursorPointer, ...{ paddingTop: 12, paddingBottom: 12 } }}
                                        onClick={this.submitFeedback.bind(this, feedbackType)}
                                    >
                                        {feedbackType.title.replace("${artist.title}", Utils.get(pr, "artist.title"))}
                                    </Div>
                                );
                            })}
                            {/* <Div
                            className="audioMenuRow"
                            style={sty.audioMenuRow}
                            onClick={this.submitFeedback.bind(this, {id: -100})}
                        >
                            Like Reply
                            <Icon icon="thumbs-up" />
                        </Div>
                        <Div
                            className="audioMenuRow"
                            style={sty.audioMenuRow}
                            onClick={this.submitFeedback.bind(this, {id: -101})}
                        >
                            Dislike Reply
                            <Icon icon="thumbs-down" />
                        </Div>
                        <Div
                            className="audioMenuRow"
                            style={sty.audioMenuRow}
                            onClick={this.submitFeedback.bind(this, {id: -102})}
                        >
                            Report Reply
                            <Icon icon="flag" />
                        </Div>
                        <Div
                            className="audioMenuRow"
                            style={{...sty.audioMenuRow, ...{borderBottom: "none"}}}
                            onClick={this.submitFeedback.bind(this, {id: -103})}
                        >
                            New Topic
                            <Icon icon="refresh" />
                        </Div> */}
                        </Div>
                        :
                        null
                    }
                    <Div className="audioBody" style={sty.audioBody}>
                        {pr.body}
                    </Div>

                    <Div className="playAreaContainer" style={sty.playAreaContainer}>
                        {st.isPlaying ?
                            <Image
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_24d87e497d22ef63266e25e2f300843b.png"
                                className="pauseButton mainPlayButton"
                                style={{ ...sty.playerButton, ...StylesPlatform.cursorPointer }}
                                onClick={this.playSound.bind(this)}
                            />
                            :
                            <Image
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_501ccc49c8b908bdc4ac6eeb394a780e.png"
                                className={`playerButton mainPlayButton ${Utils.getCookie("audioChipPulse") == 1 ? "" : "pinkPulse"}`}
                                style={{ ...sty.playerButton, ...StylesPlatform.cursorPointer }}
                                onClick={this.playSound.bind(this)}
                            />
                        }

                        {pr.isLoading || pr.src == undefined ?
                            <Spinner
                                style={{ height: 79, marginLeft: -12, }}
                                size={32}
                            />
                            :
                            // NOTE: main Lottie for audio chip
                            <Lottie
                                width={100}
                                options={{
                                    animationData: this.getWaveformColor(),
                                    loop: st.isPlaying,
                                    autoplay: false,
                                }}
                                speed={st.isPlaying ? 1 : 0}
                            />
                        }

                        {Utils.get(pr, "screen.mobile") && !pr.hideShare ?
                            <Icon
                                id="sharemessagebutton"
                                style={{ ...sty.shareUpIcon, ...StylesPlatform.cursorPointer }}
                                icon="share-up"
                                size={18}
                                onClick={this.shareToInstagram.bind(this)}
                            />
                            :
                            null
                        }

                        {/* <Div className="timestamp" style={sty.timestamp}>
                            {this.getAudioLength()}
                        </Div> */}
                    </Div>
                </Div>
            </Div>
        );
    }

    styles = {
        audioChipElementWrapper: {
            display: "flex",
            alignItems: "end",
            gap: 4,
        },
        audioChipElement: {
            width: "100%",
            backgroundColor: Colors.indigo,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            maxWidth: 212,
            padding: 12,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            boxShadow: "0px 0px 24px 0px rgba(255, 255, 255, 0.12)",
        },
        audioChipElementMinimal: {
            width: "100%",
            maxWidth: 190,
        },
        playAreaContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: -10,
            marginBottom: -23,
        },
        artistAvatar: {
            width: 20,
            height: 20,
            borderRadius: 200,
            backgroundColor: "black",
        },
        aiBadge: {
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
            borderRadius: 5,
            marginLeft: -10,
            padding: 5,
            width: "fit-content",
            fontSize: 11,
        },
        imageAiContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        playerButton: {
            width: 32,
            height: 32,
        },
        waveform: {
            width: 86,
            height: 16,
            marginLeft: "auto",
        },
        timestamp: {
            fontWeight: 400,
            opacity: 0.66,
            marginLeft: "auto",
        },
        audioMenu: {
            borderRadius: 16,
            backgroundColor: Colors.indigoLighter,
            borderRadius: 16,
            position: "absolute",
            width: 198,
            zIndex: 2,
            top: 56,
            left: 0,
            fontWeight: 300,
        },
        audioMenuRow: {
            padding: "8px 16px",
            borderBottom: "1px solid #B1A7C2",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 400,
            opacity: .5,
        },
        audioBody: {
            fontSize: 16,
            color: "#D8D3E0",
            fontWeight: 300,
            paddingBottom: 4,
        },
        shareUpIcon: {
            marginBottom: 4,
            marginLeft: 4,
            marginRight: 4,
        },
        igSharePage: {
            zIndex: 1000,
            height: "100vh",
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            padding: 8,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            color: "white",
            maxHeight: 850,
        },
        igShareInnerContainer: {
            textAlign: "center",
            padding: "16px 24px",
            backgroundColor: "rgba(65, 56, 80, 0.80)",
            borderRadius: 24,
            marginBottom: "auto",
        },
        igShareText: {
            backgroundColor: Colors.indigoLighter,
            maxWidth: 210,
            fontSize: 14,
            padding: 8,
            borderRadius: "16px 16px 0 16px",
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 16,
        },
        igShareBody: {
            fontSize: 30,
            fontWeight: 400,
        },
        hiddenButton: {
            marginBottom: 10,
        },

        otherModalTitle: {
            color: "#9E91B3",
            fontSize: 12,
            fontWeight: 400,
            marginBottom: 16,
        },
        stepText: {
            fontSize: 18,
            fontWeight: 300,
            marginBottom: 16,
        },
        hideMessageRow: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 16,
        },
        shoutoutContainer: {
            maxWidth: 475,
        },
        isShoutoutFooter: {
            width: "100%",
            backgroundColor: Colors.indigo,
            padding: 20,
            display: "flex",
            gap: 12,
            justifyContent: "center",
            // position: "fixed",
            // bottom: 0,
        },
        shoutoutButtons: {
            width: 47,
            cursor: "pointer",
        },
        copyToClipboardContainer: {
            position: "absolute",
            top: -54,
            left: -10,
            textAlign: "center",
            fontWeight: 400,
            fontSize: 12,
            color: "white",
            padding: 8,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: 8,
            textAlign: "center",
        },
    }
}
