import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Span from '../elements/Span';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import FlashMessage from '../elements/FlashMessage';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import WebappHeader from '../elements/WebappHeader';

import AfterpartyCarousel from '../elements/AfterpartyCarousel';
import ll from '../modules/ll';

import SoinCard from './SoinCard';
import EventCard from './EventCard';
import ModalRegister from './ModalRegister';
import Scroller from './Scroller';
import WalletTable from './WalletTable';
import SplashFooter from './SplashFooter';
import Div from './Div';

import ticketProfileBackgroundImage from "../assets/images/ticketflow/bg-profile@2x.jpg";
import apLogo from "../assets/images/ticketflow/ap logo.png";
import greenTag from "../assets/images/ticketflow/green tag.png";
import collectionHeroImage from "../assets//images/ticketflow/sale1_revealed_gold_front_transpparent.png";

export default class SplashCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: false,
            showRegisterModal: false,
            showTicketModal: false,

            bitCloutAddress: "",
            flashMessage: false,
            drops: [],
        };
        const collectionCrc = Utils.getUrlCrc();
        Api.getCollection(collectionCrc, this.processCollection.bind(this), );
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let profile = false;

        if(!newProps.public) {
            profile = Utils.get(newProps.user, "email") ? newProps.user : null;
        } else {
            profile = newProps.publicProfile ? newProps.publicProfile : false;
        }

        if(!profile && newProps.userLoaded && !newProps.public) {
            newProps.history.push("/login");
            window.location.reload();
        }
    }

  processCollection(response, error) {
      const pr = this.props;
      const st = this.state;

      if(!response) {
          return;
      }
      let tempState;

      tempState = {
          collection: Utils.get(response, "data.collection", {}),
          drops: Utils.get(response, "data.items", []),
      };
      this.setState(tempState);
  }

    editProfile() {
        this.setState({showRegisterModal: true});
    }

    handleLogoutClick() {
        Api.getLogout(() => {
            this.props.history.push("/login");
            window.location.reload();
        });
    }

    closeRegisterModal() {
        this.setState({showRegisterModal: false});
    }

    closeTicketModal() {
        this.setState({showTicketModal: false});
    }

    getNewUserAfterRegisterSuccess(newUser) {
        console.log("newUser", newUser);
        // this.props.setUser(newUser);
    }

    handleBackToEvents() {
        const pr = this.props;

        pr.history.push('/tickets/1');
        pr.change('tickets');

        return;

        if(Utils.isDemoMode()) {
            pr.history.push('/guardian?stage=marketing');
            pr.change('guardian');
        } else {
            pr.history.push('/home');
            pr.change('home');
        }

    }

    handlePosterClick(item) {
        if(item['json'] && item['json'].indexOf('permalink') != -1) {
            console.log("PERMALINK");
            let data = JSON.parse(item['json']);
            window.open( data['permalink'], "_blank");
            return;
        }
        item["thumbnail"] = item["cover_big"];
        this.setState({
                selectedMediaItem: item,
            }, () => {
                this.props.history.push('/soin/'+item["name"]+"-"+item["crc"])
                this.props.change('item', item);
        });
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        if(!accounts && bitCloutPublicKey) {
            Api.putMusicWallet({
                params: {
                    user: {
                        bitclout_public_key: bitCloutPublicKey
                    }
                }
            });
            this.setState({
                bitCloutAddress: bitCloutPublicKey,
                registeredSuccess: true,
            });
        } else {
            Api.putMusicWallet({
                params: {
                    user: {
                        wallet_id: accounts[0]
                    }
                }
            });
            this.setState({wallet: accounts});
        }
    }

    handleArtistClick(item) {
        const nftUrl = '/soin/'+item['name']+'-'+item['crc'];
        console.log("nftUrl", nftUrl);
        this.props.history.push(nftUrl);
        //this.change('event', event);
    }

    buyMore() {
        console.log("buyMore");
    }

    handleNewSoinClick(artist) {
        const featured = {};

        this.setState({
            selectedMediaItem: artist,
        }, () => {
            let newRoute = `seller/${artist.id}/newnft/${artist.id}`;
            this.props.change(newRoute, artist);
            this.props.history.push(`/${newRoute}`)
        });
    }

    handleCreateWalletClick() {
        console.log("Create wallet");
        Api.postCreateCustodialWallet({}, (o) => {
            console.log(Utils.get(o, 'data.walletId.wallet_id'));
            this.setState({custodialWalletId:Utils.get(o, 'data.walletId.wallet_id'), showCreateWalletModal: true});
        });
    }

    handleViewTicketClick(item) {
        //this.setState({showTicketModal:true, selectedTicket:item});
        this.props.history.push('/nft/'+Utils.get(item, 'name')+"-"+Utils.get(item, 'crc'));
    }

    renderPassStatus(item) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let nftStatus = "Unrevealed";

        switch (item.nft_style) {
            case 1:
                nftStatus = "Level 1"
                break;
            case 2:
                nftStatus = "Golden Pass"
                break;
        }

        return (
            <Div
                className="passStatus"
                style={sty.passStatus}
            >
                {nftStatus}
            </Div>
        )
    }

    renderCollectionInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const collectionInfoContainer = StyleUtils.getMediaStyle('collectionInfoContainer', sty.collectionInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, "md"));
        const collectionHeroInfoBox = StyleUtils.getMediaStyle('collectionHeroInfoBox', sty.collectionHeroInfoBox, sty, StyleUtils.getWidthType(pr.screen.width, "md"));

        return (
            <Div className="collectionInfoContainer" style={collectionInfoContainer}>
                <Div style={{...sty.collectionHeroInfoBoxes, ...{justifyContent: "right"}}}>
                    <Image className="collectionHeroImage" style={{width:"100%", maxWidth: 400}} src={Utils.get(st.collection, "image_url")} />
                </Div>
                <Div style={{...sty.collectionHeroInfoBoxes, ...{justifyContent: "center", flexDirection: "column", alignItems: "left"}}}>
                    <Div className="collectionHeroTitle" style={{fontSize:48, fontWeight:"bold",}}>
                        {Utils.get(st.collection, "title")}
                    </Div>
                    <Div>
                        {Utils.get(st.collection, "description")}
                    </Div>
                </Div>
                <Div style={sty.collectionHeroInfoBoxes}>
                    <Div style={collectionHeroInfoBox}>
                        <Div>Total Supply</Div>
                        <Div style={{fontSize:48, fontWeight:900}}>{Utils.get(st.collection, "total_supply")}</Div>
                    </Div>

                </Div>
            </Div>
        )
    }

    renderCollectionItems() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const profileComponent = StyleUtils.getMediaStyle('profileComponent', sty.profileComponent, sty, StyleUtils.getWidthType(pr.screen.width));
        const ticketPassHeader = StyleUtils.getMediaStyle('ticketPassHeader', sty.ticketPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const apLogoPassHeader = StyleUtils.getMediaStyle('apLogoPassHeader', sty.apLogoPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassTitle = StyleUtils.getMediaStyle('profilePassTitle', sty.profilePassTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassSubtitle = StyleUtils.getMediaStyle('profilePassSubtitle', sty.profilePassSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const itemContainer = StyleUtils.getMediaStyle('itemContainer', sty.itemContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const passContainer = StyleUtils.getMediaStyle('passContainer', sty.passContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div>
                <Div className="itemContainer" style={itemContainer}>
                    {st.drops.map((item, itemIndex) => {
                        return (
                            <Div
                                className="passContainer"
                                key={`passContainer-${itemIndex}`}
                                style={passContainer}
                            >
                                {item.listing_status == 4 ?
                                    <Image
                                        className="greenTag"
                                        src={greenTag}
                                        style={sty.greenTag}
                                    />
                                    :
                                    null
                                }
                                <Image
                                    style={{...sty.ticketPassImage, ...StylesPlatform.cursorPointer}}
                                    src={Utils.get(item, "image_thumbnail_url") ? Utils.get(item, "image_thumbnail_url") : Utils.get(item, "image_url")}
                                    onClick={this.handleViewTicketClick.bind(this, item)}
                                />
                                {Utils.get(item, "master_collection_id") == 7 ?
                                    <Div>
                                        <Div
                                            className="passTitle"
                                            style={sty.passTitle}
                                        >
                                            Afterparty Pass
                                        </Div>
                                        {this.renderPassStatus(item)}
                                    </Div>
                                    :
                                    <Div>
                                        <Div
                                            className="passTitle"
                                            style={sty.passTitle}
                                        >
                                            {Utils.get(item, "title")}
                                        </Div>
                                        <Div
                                            className="passStatus"
                                            style={sty.passStatus}
                                        >&nbsp;
                                        </Div>
                                    </Div>
                                }
                                {item.listing_status == 4 ?
                                    <Div className="initialPrice">
                                        ${Utils.commas(item.initial_price/100)}
                                    </Div>
                                    :
                                    null
                                }
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let profile = false;
        // If not a public profile, display user info
        if(!pr.public) {
            profile = Utils.get(pr.user, "email") ? pr.user : null;
        } else {
            // Public profile -- check if profile is loaded
            profile = pr.publicProfile ? pr.publicProfile : false;
        }

        const splashCollectionComponent = StyleUtils.getMediaStyle('splashCollectionComponent', sty.splashCollectionComponent, sty, StyleUtils.getWidthType(pr.screen.width));
        const ticketPassHeader = StyleUtils.getMediaStyle('ticketPassHeader', sty.ticketPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const apLogoPassHeader = StyleUtils.getMediaStyle('apLogoPassHeader', sty.apLogoPassHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassTitle = StyleUtils.getMediaStyle('profilePassTitle', sty.profilePassTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const profilePassSubtitle = StyleUtils.getMediaStyle('profilePassSubtitle', sty.profilePassSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const passContainer = StyleUtils.getMediaStyle('passContainer', sty.passContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="splashCollectionComponent" style={{...splashCollectionComponent, ...pr.style}}>
                <BloatedCircleDefs scales={[128]} />
                {st.flashMessage ?
                    <FlashMessage
                        type="info"
                        message="Email Sent"
                        subMessage="Check your email and click the link to verify your account."
                        duration={8000}
                    />
                    :
                    null
                }

                {pr.hidePageBack || true ?
                    null
                    :
                    <Div className="afterpartyPageBack" style={sty.afterpartyPageBack} onClick={this.handleBackToEvents.bind(this)}>
                        <Div style={{...StylesPlatform.cursorPointer}}>
                            <Icon icon="chevron-left" size={16} style={sty.chevronIcon} />
                        </Div>
                    </Div>
                }
                <Div
                    className="ticketProfileHeaderContainer"
                    style={{...sty.ticketProfileHeaderContainer, ...{backgroundImage: `url("${ticketProfileBackgroundImage}")`}}}
                >
                    {this.renderCollectionInfo()}
                    {this.renderCollectionItems()}

                    {/* <Div className="" style={{paddingLeft: 20, paddingRight: 20}}>
                        <Button
                            onClick={this.buyMore.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%", maxWidth: 335, marginTop: 40, marginLeft: "auto", marginRight: "auto"}}
                        >
                            Buy More
                        </Button>
                    </Div> */}
                </Div>

                <Div className="passBorderSeparator" style={sty.passBorderSeparator}></Div>
                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                    footerColor={Colors.indigo}
                />
                {!profile && pr.userLoaded ?
                    !pr.public ?
                        <Div style={{minHeight: 1200}}>
                            Please sign in to see profile
                        </Div>
                        :
                        <Div>
                            Profile not found.
                        </Div>
                    :
                    null
                }

                {/* this.renderTable() */}

                {st.showRegisterModal ?
                    <ModalRegister
                        screen={pr.screen}
                        title="Edit Profile"
                        fields="username,email,phone,password"
                        isProfile
                        user={pr.user}
                        onRequestClose={this.closeRegisterModal.bind(this)}
                        getNewUserAfterRegisterSuccess={this.getNewUserAfterRegisterSuccess.bind(this)}
                    />
                    :
                    null
                }
                {st.showTicketModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeTicketModal.bind(this)}
                        className="biddingModal"
                        color="indigo"
                        fullScreen
                    >
                        <Div
                            className="showTicketModalInnerContainer"
                            style={sty.showTicketModalInnerContainer}
                        >
                            <Div
                                style={{fontSize: 32, marginTop: 50}}
                            >
                                Afterparty Pass
                            </Div>
                            <Div style={{marginTop:20, marginBottom:20}}>
                                Owned by you
                            </Div>
                            {/* {this.renderQrCode()} */}
                            <Image
                                style={{...this.styles.artistTileImage, ...{marginTop: 40, marginBottom: 40}}}
                                src={st.selectedTicket.image_url}
                            />
                            <Div style={{marginTop:20, marginBottom:20, textAlign: "center"}}>
                                Congratulations! You have won an Afterparty Golden Pass, and a night of the Afterparty Experience this Wednesday, June 8th. You will receive an email with event details shortly. Can’t make it? Feel free to list your Pass on the Afterparty Marketplace, by going to Afterparty.com/marketplace. Must be 18 years or older to qualify for event access.
                            </Div>
                        </Div>

                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        splashCollectionComponent: {
            fontFamily: "Nyata",
            fontWeight: 400,
            width: "100%",
            // padding: 40,
            cursor: "default",
            backgroundColor: "#0F0420",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            color: "white",
            minHeight: "100vh",
            marginTop: 72,
        },
        splashCollectionComponent_md: {
            // padding: 20,
            borderTopLeftRadius: 0,
        },
        splashCollectionComponent_sm: {
            // padding: 15,
            borderTopLeftRadius: 0,
        },
        splashCollectionComponent_xs: {
            // padding: 10,
            // paddingTop: 20,
            borderTopLeftRadius: 0,
        },

        afterpartyProfile_md: {
            padding: 10,
            borderTopLeftRadius: 0,
        },
        profileHeader: {
            //display: "flex",
            marginBottom: 20,
            marginTop: 100,
        },
        profileImageAndInfoContainer: {
            display: "flex",
        },
        profileImageAndInfoContainer_sm: {
            flexDirection: "column",
        },
        profileInfo: {
            display: "flex",
            marginTop: 10,
            flexDirection: "column",
            paddingLeft: 15,
            marginBottom:20,
        },
        profileButtons: {
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row", //"column",
            width: "100%",
            gap: 12,
        },
        profileSectionContainer: {
            backgroundColor: Colors.purpleLight1,
            marginBottom: 30,
            padding: 20,
            borderRadius: 20,
        },
        profileSectionContainer_sm: {
            padding: 10,
            borderRadius: 10,
        },
        profileSectionHeadline: {
            fontSize: 24,
            fontWeight: "bold",
            color:"white",
            marginBottom:20,
        },
        profileSubSectionContainer: {
            backgroundColor: Colors.purpleLight2,
            marginBottom: 30,
            padding: 20,
            borderRadius: 10,
            textAlign:"left",
        },
        chevronIcon: {
            marginRight: 10,
        },
        afterpartyPageBack: {
            marginBottom: 15,
        },
        headline: {
            fontSize: 16,
        },

        tableContainer: {
            borderRadius: 20,
            border: "1px solid white",
            padding: 20,
        },
        tableHeader: {
            display: "flex",
        },
        tableColumn: {
            flex: 1,
            padding: 10,
        },
        tableContents: {
        },
        tableRow: {
            display: "flex",
        },
        sectionHeadline: {
            fontSize: 18,
            fontWeight:"bold",
            marginTop:22,
            marginBottom:12,
            color:"white",
        },
        artistTilesContainer: {
            width:"100%",
            height: 200,
            position:"absolute",
            overflowX:"auto",
            overflowY:"hidden",
            display:"flex",
            background:"black",borderRadius:20,
        },
        artistTile: {
            textAlign:"center",
            paddingTop: 10,
            paddingLeft: 10,
            position: "relative",
        },
        artistTileCheck: {
            width:16,
            height:16,
            borderRadius:8,
            backgroundColor:"red",
            position:"absolute",
            right: 10,
            top: 16,
            color:"white",
            fontSize: 11,
        },
        artistTileImageContainerCheck: {
            overflow: "hidden",
            boxSizing: "border-box",
            border: "1px solid red",
            margin: 6,
            width: 64,
            height: 64,
            borderRadius: 64,
            backgroundColor: "orange"
        },
        artistTileImageContainer: {
            overflow: "hidden",
            margin: 6,
            width: 128,
            height: 128,
            position: "relative",
            overflow: "hidden",
            borderRadius: 12,
            //backgroundColor:"orange"
        },
        artistTileImage: {
            width: 128,
            height: 128,
            borderRadius: 12,
        },
        artistTileTitle: {
            position: "absolute",
            bottom: 13,
            left: 10,
            fontWeight: "bold",
            fontSize: 14,
        },
        connectWalletButton: {
            marginRight: 8,
        },
        connectWalletInfo: {
            backgroundColor: "black",
            borderRadius: 12,
            color: "white",
            display: "flex",
            padding: 13,
            width: "100%",
        },
        showTicketModalInnerContainer: {
            maxWidth: 415,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },

        // XXX:
        ticketProfileHeaderContainer: {
            backgroundPosition: "center",
            backgroundSize: "cover",
            minHeight: 800,
        },
        passStatus: {
            color: "white",
            textAlign: "center",
            fontSize: 18,
        },
        passTitle: {
            backgroundColor: "rgba(238, 110, 98, 0.2)",
            width: "fit-content",
            textAlign: "center",
            paddingTop: 7,
            paddingBottom: 7,
            paddingLeft: 14,
            paddingRight: 14,
            borderRadius: 100,
            color: "#EE6E62",
            fontSize: 12,
            marginTop: 15,
            marginBottom: 10,
        },
        passContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 45,
        },
        passContainer_md: {
            marginLeft: 20,
            width: 140,
        },
        ticketPassHeader: {
            backgroundColor: "rgba(0,0,0,0.2)",
            width: "100%",
            height: 120,
            display: "flex",
            alignItems: "center",
        },
        ticketPassHeader_md: {
            height: 72,
        },
        apLogoPassHeader: {
            width: 243,
            marginTop: 5,
            marginLeft: 30,
        },
        apLogoPassHeader_md: {
            width: 136,
            marginLeft: 20,
        },
        profilePassTitle: {
            fontSize: 56,
            textAlign: "center",
            paddingTop: 60,
            paddingBottom: 100,
        },
        profilePassTitle_md: {
            fontSize: 32,
            textAlign: "left",
            paddingLeft: 20,
            paddingTop: 30,
            paddingBottom: 30,
        },
        profilePassSubtitle: {
            fontSize: 36,
            textAlign: "center",
            paddingBottom: 40,
        },
        profilePassSubtitle_md: {
            fontSize: 24,
            textAlign: "left",
            paddingLeft: 20,
            paddingBottom: 40,
        },
        passBorderSeparator: {
            width: "100%",
            height: 2,
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)", color: "white"
            // backgroundColor: "none",
            // "#C92D7A",
        },
        walletConnectedProfileStatus: {
            marginLeft: "auto",
            paddingRight: 125,
            display: "flex",
            alignItems: "center",
        },
        ticketPassImage: {
            // filter: "drop-shadow(0 0 0.75rem black)",
            width: 140,
            height: 140,
            borderRadius: 8,
        },

        itemContainer: {
            display: "flex",
            gap: 56,
            justifyContent: "left",
            flexWrap: "wrap",
            marginBottom: 40,
            padding: 60,
        },
        itemContainer_md: {
            gap: 20,
            padding: 15,
            justifyContent: "left",
        },

        collectionInfoContainer: {
            paddingTop: 20,
            display:"flex",
        },
        collectionInfoContainer_md: {
            flexDirection: "column",
        },

        collectionHeroInfoBoxes: {
            display: "flex",
            flex: 1,
            padding: 20,
            gap: 20,
            alignItems: "center",
        },

        collectionHeroInfoBox: {
            display: "flex",
            flex: 1,
            textAlign: "center",
            borderRadius: 20,
            border: "1px solid gray",
            flexDirection: "column",
            height: 130,
            maxWidth: 180,
            width: "100%",
            justifyContent: "center",
            flexShrink: 0,
        },
        collectionHeroInfoBox_md: {

        },
        greenTag: {
            position: "absolute",
            top: -15,
            right: -15,
            width: 40,
            height: 40,

        },
    };
}
