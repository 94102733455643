import React from 'react';
import ReactModal from 'react-modal';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import Icon from './Icon';
import Button from './Button';
import Image from './Image';
import Modal from './Modal';

import Div from '../components/Div';

import plusSign from "../assets/images/ticketflow/plus sign.png";
import apLogoWhite1 from "../assets/images/ticketflow/ap logo white 1.png"

export default class FaqButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainPageInfoModal: false,
            mainPageInfoModalTitle: "",
            mainPageInfoModalDescription: "",
            mainPageInfoModalName: "",
        }
    }

    showMainPageInfoModal() {
        const pr = this.props;

        this.setState({
            mainPageInfoModal: true,
            mainPageInfoModalTitle: pr.title,
            mainPageInfoModalDescription: pr.description,
            mainPageInfoModalName: pr.name,
        });
    }

    onMainPageInfoModalClose() {
        this.setState({mainPageInfoModal: false});
    }


    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        let mainPageInfoModalDescriptionArray = st.mainPageInfoModalDescription.split("\n");

        return (
            <Div
                className="faqButtonElement"
                style={{...sty.faqButtonStyle, ...pr.style}}
            >
                <Button
                    className="faqButton"
                    type="outlined"
                    onClick={this.showMainPageInfoModal.bind(this)}
                >
                    {pr.title}
                    <Image
                        style={{height: 24, width: 24, marginLeft: 10}}
                        src={plusSign}
                    />
                </Button>

                {st.mainPageInfoModal ?
                    <Modal
                        onRequestClose={this.onMainPageInfoModalClose.bind(this)}
                        className="mainPageInfoModal"
                        color="indigo"
                        screen={pr.screen}
                        fullScreen
                        fullScreenLeft
                    >
                        <Image
                            className="afterpartyLogoTop"
                            style={{width: 124, marginRight: "auto", marginTop: -25}}
                            src={apLogoWhite1}
                        />
                        <Div
                            className="modalTitle"
                            style={sty.modalTitle}
                        >
                            {st.mainPageInfoModalTitle}
                        </Div>
                        <br />
                        <Div
                            className="modalDescription"
                            style={sty.modalDescription}
                        >
                            {mainPageInfoModalDescriptionArray.map((mainPageInfoModalDescriptionItem, mainPageInfoModalDescriptionKey) => {
                                return (
                                    <>
                                        <Div key={`mainPageInfoModalDescription-${mainPageInfoModalDescriptionKey}`}>
                                            {mainPageInfoModalDescriptionItem}
                                        </Div>
                                        <br />
                                    </>
                                )
                            })}
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        faqButtonStyle: {},
        modalTitle: {
            fontFamily: "Nyata",
            fontWeight: 500,
            fontSize: 32,
            marginRight: "auto",
            marginTop: 50,
        },
        modalDescription: {
            fontFamily: "Nyata",
            fontWeight: 300,
            color: Colors.gray,
        },
    }
}
