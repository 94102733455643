import React from 'react';

import Styles from '../modules/Styles';
import Utils from '../modules/Utils';
import StyleUtils from '../modules/StyleUtils';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';
import Constant from '../modules/Constant';

import Div from './Div';
import DebugBox from './DebugBox';
import Image from './Image';
import WebappHeader from './WebappHeader';
import NineDotMenu from './NineDotMenu';
import SplashMenu from './SplashMenu';

import DashboardSoinFlyout from '../components/DashboardSoinFlyout';

export default class WebsiteHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSplashMenu: false,
        };
    }

    openSplashMenu() {
        this.setState({
            showSplashMenu: true,
        });
    }

    closeSplashMenu() {
        this.setState({
            showSplashMenu: false,
        });
    }

    renderSplashMenu() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const roomStyle = pr.selected === "room" ? {zIndex: 1003} : null;

        if(st.showSplashMenu) {
            return (
                <SplashMenu
                    setUser={pr.setUser}
                    selected={pr.selected}
                    screen={pr.screen}
                    history={pr.history}
                    user={pr.user}
                    userLoaded={pr.userLoaded}
                    change={pr.change.bind(this)}
                    closeSplashMenu={this.closeSplashMenu.bind(this)}
                    style={roomStyle}
                />
            )
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const roomStyle = pr.selected === "room" ? {zIndex: 1000} : null;

        if(pr.headerType === undefined || pr.headerType === Constant.HEADER_TYPE_WEBAPP_HEADER) {
            return (
                <WebappHeader
                    screen={pr.screen}
                    user={pr.user}
                    userLoaded={pr.userLoaded}
                    setUser={pr.setUser.bind(this)}
                    selected={pr.selected}
                    history={pr.history}
                    change={pr.change.bind(this)}
                    metamaskWalletId={pr.metamaskWalletId}
                />
            )
        } else if(pr.headerType === Constant.HEADER_TYPE_NO_HEADER) {
            return null;
        } else if(pr.headerType === Constant.HEADER_TYPE_SOIN_FLYOUT) {
            return null;
        } else if(pr.headerType === Constant.HEADER_TYPE_NINE_DOT) {
            return (
                <>
                    { false ?
                        <DebugBox title="WebsiteHeader" show={pr} />
                        :
                        null
                    }
                    <NineDotMenu
                        openSplashMenu={this.openSplashMenu.bind(this)}
                        color={pr.selected === "home" ? "magenta" : null}
                        selected={pr.selected}
                        style={roomStyle}
                        size={pr.selected === "room" && pr.screen.mobile ? "small" : null}
                        isFixed={pr.selected === "room" && pr.screen.mobile}
                    />
                    {this.renderSplashMenu()}
                </>
            )
        }
    }

    styles = {
        websiteHeader: {}
    }
}
