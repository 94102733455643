import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';

import Div from '../components/Div';

export default class Blog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    renderPP() {
        return (
<Div>
    <h1 style={{marginBottom:20}}>Afterparty Blog</h1>
    { this.props.blogs ?
        <Div>
        {this.props.blogs.map( (item) => {
              return <Div style={this.styles.blogBody} dangerouslySetInnerHTML={{ __html: item['body'] }} />;

        })}
        </Div>
        :
        null
    }
</Div>
        );
    }


    render() {
        return (
            <Div style={this.styles.container}>
                {this.renderPP()}
            </Div>
        );
    }

    styles = {
        container: {
            padding: 20,
        },
    };
}
