import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import ImageInput from '../components/ImageCropper/ImageInput';

import Icon from './Icon';

import Div from '../components/Div';

export default class ImageUploaderElement extends React.Component {
    inputUpload(event) {
        this.props.onChange(event.target.files[0])
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        if(pr.screen.mobile) {
            let inputElement;
            return (
                <>
                    <input
                        style={{display: "none"}}
                        id="file_input"
                        ref={input => (inputElement = input)}
                        accept="image/*"
                        type="file"
                        multiple
                        name="file"
                        onChange={this.inputUpload.bind(this)}
                    />
                    <Div
                        onClick={() => inputElement.click()}
                    >
                        {pr.children}
                    </Div>
                </>

            )
        } else {
            return (
                <ImageInput
                    imageData={pr.imageData}
                    type="admin"
                    name="photo"
                    aspectRatio={pr.aspectRatio}
                    label="Add Photo"
                    screen={pr.screen}
                    onChange={pr.onChange.bind(this)}
                >
                    {pr.children}
                </ImageInput>
            );
        }
    }

    styles = {
        checkbox: {
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 20,
            height: 20,
            cursor: "pointer",
            flexShrink: 0,
        }
    }
}
