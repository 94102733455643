export default {
    trigger(eventName, data, includeUserInfo) {
        try {
            const gaData = {'event':eventName,'user_email':Utils.get(data, "Email", ''),'user_phone':Utils.get(data, "Phone number", '')};
            //console.log("gaData", gaData);
            window.dataLayer.push(gaData);
        } catch(err) {

        }
    },
    triggerGa(params) {
        console.log("TRIGGER");
        const quantity = 1;
        const pricePerTicket = 6.0;

        let gtmData = {
            'event':'view_item_list',
            'purchase.user_email':"john@afterparty.com",
            'purchase.user_phone':"310-555-1212",
            'artist':10,
            'event_id': 11,
            'room_id':12,
            'ecommerce':{
                'value':quantity*pricePerTicket,
                'currency':"USD",
                'items':[
                    {
                        item_name: "SuperSend",
                        price: 1.00,
                    },
                    {
                        item_name: "SuperSend",
                        price: 2.00
                    },
                    {
                        item_name: "SuperSend",
                        price: 6.00
                    }
                ]
            }
        };
        //const gtmData = {'event':'ticket_purchase', 'purchase.user_email':Utils.get(this.props, "user.email"), 'purchase.user_phone':Utils.get(this.props, "user.primary_phone1"), 'onsale_event_id':'gravy_sep1', 'quantity':quantity, 'value':quantity*pricePerTicket, 'currency':'USD', 'artist':'yung gravy'};
        if(window.dataLayer) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push(gtmData);
            console.log("GA ticket", gtmData);
        } else {
            console.log("GA not loaded in time!");
        }
    },

    triggerAmplitude(pr, st, amplitudeName) {
        
    },
    triggerAmplitudeLogin(userId) {
        const eventProperties = {
            user_id: parseInt(userId),
            url: window.document.location.href,
        };
        const eventName = 'onboarding_client_login_completed';
        try {
            amplitude.track(eventName, eventProperties);
        } catch (error) {
        }
        console.log("AMPLITUDE", eventName, eventProperties, userId);
    },
    triggerAmplitudeLoginFail() {
        const eventProperties = {
            url: window.document.location.href,
        };
        const eventName = 'onboarding_client_login_error';
        try {
            amplitude.track(eventName, eventProperties);
        } catch (error) {
        }
        console.log("AMPLITUDE", eventName, eventProperties);
    },
    triggerAmplitudeRegister(userId) {
        const eventProperties = {
            user_id: parseInt(userId),
            url: window.document.location.href,
        };
        const eventName = 'onboarding_client_register_complete';
        try {
            amplitude.track(eventName, eventProperties);
        } catch (error) {
        }
        console.log("AMPLITUDE", eventName, eventProperties, userId);
    },
    triggerAmplitudeRegisterFail() {
        const eventProperties = {
            url: window.document.location.href,
        };
        const eventName = 'onboarding_client_register_error';
        try {
            amplitude.track(eventName, eventProperties);
        } catch (error) {
        }
        console.log("AMPLITUDE", eventName, eventProperties);
    },
    triggerAmplitudeChatMessage(pr, st) {
        const artist = st.allArtists[st.selectedCreatorIndex];
        const eventProperties = {
            artist: artist.name,
            user_id: pr.user.id,
            url: window.document.location.href,
        };
        amplitude.track("engagement_client_message_sent", eventProperties);
    },
}
