import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';
import ReactCrop from "react-image-crop";

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import ll from '../modules/ll';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import DebugBox from '../elements/DebugBox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import FlashMessage from '../elements/FlashMessage';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import AfterpartyCarousel from '../elements/AfterpartyCarousel';
import AfterpartyLogo from '../elements/AfterpartyLogo';

import SoinCard from './SoinCard';
import EventCard from './EventCard';
import ModalRegister from './ModalRegister';
import Scroller from './Scroller';
import WalletTable from './WalletTable';
import Div from './Div';
import SplashFooter from './SplashFooter';

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 60,
                aspect: 1,
            },
            croppedBlob: null,
            croppedImageUrl: null,
            imageUploadSuccess: false,

            profile: false,
            showTicketModal: false,

            bitCloutAddress: "",
            flashMessage: false,

            showImageUploaderModal: false,

            usernameText: Utils.get(props, "user.username", ""),
            isEditUsername: false,
            editUsernameErrors: [],
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let profile = false;

        if(!newProps.public) {
            profile = Utils.get(newProps, "user.email") ? newProps.user : null;
            this.setState({
                usernameText: Utils.get(newProps, "user.username"),
            });
        } else {
            profile = newProps.publicProfile ? newProps.publicProfile : false;
        }

        if(!profile && newProps.userLoaded && !newProps.public) {
            newProps.history.push("/login");
            window.location.reload();
        }
    }

    uploadImage() {
        const pr = this.props;
        const st = this.state;

        let formData = new FormData();
        const croppedFile = new File([st.croppedBlob], st.croppedBlob.name, {
            type: st.croppedBlob.type,
        });

        formData.append("file", croppedFile);
        formData.append("Filetype", croppedFile.type);
        formData.append("Size", croppedFile.size);
        formData.append("lastModifiedDate", croppedFile.lastModifiedDate);

        Api.postProfileImage(formData, (res) => {
            const photo = `${Api.getCdnUrl()}${Utils.get(res, "file.fname")}`;

            Api.putProfile({params: {user: {photo: photo}}}, (res) => {
                if(Utils.get(res, "success")) {
                    Api.apiLoadProfile(pr.setUser.bind(this));
                    this.setState({
                        showImageUploaderModal: false,
                        imageUploadSuccess: true,
                    });
                } else {
                    this.setState({errorMessages: Utils.get(res, "errors", null)});
                }
            });
        });
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                // console.log("e.target.files[0] >>>", e.target.files[0]);
                this.setState({
                    src: reader.result,
                    selectedFile: e.target.files[0],
                    imageUploadSuccess: false,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({crop: crop});
    };

    async makeClientCrop(crop) {
        // console.log("makeClientCrop >>>", crop);
        if(this.imageRef && crop.width && crop.height) {
            // console.log("this.imageRef >>>", this.imageRef);
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "cropped_" + this.state.selectedFile.name,
            );
            this.setState({croppedImageUrl: croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const st = this.state;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                blob.lastModifiedDate = new Date();
                this.setState({croppedBlob: blob});
                window.URL.revokeObjectURL(this.fileUrl);

                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    editProfile() {
        this.setState({showRegisterModal: true});
    }

    verifyEmail() {
        const data = {
            email: this.props.user.email,
            validate_step: "email_verify",
            format: "json",
        };

        Api.postRegister(data, (response) => {
            if(response == null) {
                return;
            }

            if(Utils.get(response, "success")) {
                this.setState({flashMessage: true});
            } else {
                this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    handleLogoutClick() {
        Api.getLogout(() => {
            this.props.history.push("/login");
            window.location.reload();
        });
    }

    closeTicketModal() {
        this.setState({showTicketModal: false});
    }

    getNewUserAfterRegisterSuccess(newUser) {
        console.log("newUser", newUser);
        // this.props.setUser(newUser);
    }

    handleBackToEvents() {
        const pr = this.props;

        pr.history.push('/tickets/1');
        pr.change('tickets');

        return;

        if(Utils.isDemoMode()) {
            pr.history.push('/guardian?stage=marketing');
            pr.change('guardian');
        } else {
            pr.history.push('/home');
            pr.change('home');
        }

    }

    handlePosterClick(item) {
        if(item['json'] && item['json'].indexOf('permalink') != -1) {
            console.log("PERMALINK");
            let data = JSON.parse(item['json']);
            window.open( data['permalink'], "_blank");
            return;
        }
        item["thumbnail"] = item["cover_big"];
        this.setState({
                selectedMediaItem: item,
            }, () => {
                this.props.history.push('/soin/'+item["name"]+"-"+item["crc"])
                this.props.change('item', item);
        });
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        if(!accounts && bitCloutPublicKey) {
            Api.putMusicWallet({
                params: {
                    user: {
                        bitclout_public_key: bitCloutPublicKey
                    }
                }
            });
            this.setState({
                bitCloutAddress: bitCloutPublicKey,
                registeredSuccess: true,
            });
        } else {
            Api.putMusicWallet({
                params: {
                    user: {
                        wallet_id: accounts[0]
                    }
                }
            });
            this.setState({wallet: accounts});
        }
    }

    handleArtistClick(item) {
        const nftUrl = '/soin/'+item['name']+'-'+item['crc'];
        console.log("nftUrl", nftUrl);
        this.props.history.push(nftUrl);
        //this.change('event', event);
    }


    handleNewSoinClick(artist) {
        const featured = {};

        this.setState({
            selectedMediaItem: artist,
        }, () => {
            let newRoute = `seller/${artist.id}/newnft/${artist.id}`;
            this.props.change(newRoute, artist);
            this.props.history.push(`/${newRoute}`)
        });
    }

    handleCreateWalletClick() {
        console.log("Create wallet");
        Api.postCreateCustodialWallet({}, (o) => {
            console.log(Utils.get(o, 'data.walletId.wallet_id'));
            this.setState({custodialWalletId:Utils.get(o, 'data.walletId.wallet_id'), showCreateWalletModal: true});
        });
    }
    handleViewTicketClick(item) {
        this.setState({showTicketModal:true, selectedTicket:item});
    }

    renderImageUploadSuccessMessage() {
        return (
            <Div style={{color: "green", textAlign: "center", marginTop: 9, marginBottom: 20}}>
                Image uploaded successfully!
            </Div>
        );
    }

    usernameChange(e) {
        this.setState({usernameText: e.target.value});
    }

    editUsername() {
        const pr = this.props;
        const st = this.state;

        if(st.isEditUsername) {
            if(st.usernameText != Utils.get(pr, "user.username")) {
                if(st.usernameText.length < 4) {
                    this.setState({
                        editUsernameErrors: [{id:9990, message:"User name must be at least 4 characters."}],
                    });
                    return;
                }
                this.setState({
                    editUsernameErrors: [],
                });
                Api.putProfile({params: {user: {username: st.usernameText}}}, (res) => {
                    if(Utils.get(res, "success")) {
                        pr.setUser(res);
                        this.setState({
                            isEditUsername: false,
                        });
                    } else {
                        this.setState({
                            editUsernameErrors: Utils.get(res, "errors"),
                        });
                    }
                });
            } else {
                this.setState({
                    isEditUsername: false,
                });
            }
        } else {
            this.setState({
                isEditUsername: true,
            });
        }
    }

    renderQrCode() {
        const images = [
            'qrcode_00117843cf5892dd484dbb0ddc33deb7.png',
            'qrcode_0011d7abc9c133b44fb0c55e1431883b.png',
            'qrcode_0013ce1b18e1663dc6b3c5fd8f84354f.png',
            'qrcode_0036f4d0be500f74d36c02ffbfdaeaee.png',
            'qrcode_003c5be36290b3df748c5c84f72e292a.png',
            'qrcode_004ca4893594a5126918735799afadc2.png',
            'qrcode_00589b77b6f5b3645c294ff6aac6a553.png',
            'qrcode_005a6ed37d692759f32f670ef5a71481.png',
            'qrcode_00680ec6708e63507399c5d1e0525b48.png',
            'qrcode_006de4b26a8b9cd9c3d9c430cf3fca4d.png',
            'qrcode_0099e71b9377910229ab4dbe784e1fe3.png',
            'qrcode_009d24223db0583121555e0ee12fd251.png',
            'qrcode_00bacdb07b3ee3353df57902afd56948.png',
            'qrcode_00e170fed06a956d4dce7b69f25c9d85.png',
            'qrcode_00e27aed5881fd671ea1b6cd0563c96f.png',
            'qrcode_011116c4f912d3466496a68403344332.png',
            'qrcode_0124b9bfda3be490d576bb00b6276498.png',
            'qrcode_012fc6c6bc5afa056f2e9f7ea4b532e1.png',
            'qrcode_017157217bce7990565e605fb6d4e712.png',
            'qrcode_0173381204a63d7ec1b986faee07b746.png',
            'qrcode_0176583d6e4a532f79d6c36648240300.png',
            'qrcode_01a24f066c4859b7e5180299df7b2135.png',
            'qrcode_01d9640d5fccb911778bf62876562d7a.png',
            'qrcode_01dc6918996270227a2ed86e1026e59a.png',
            'qrcode_01dfe98804f508e836eebe53fe92a3bd.png',
            'qrcode_01e8a12ad090dc800efcb462fd4b6df2.png',
            'qrcode_01ed9a8c6808eb180ba9706bb8696fe3.png',
            'qrcode_02033ffda0697f93ced015a6ba152001.png',
            'qrcode_02345254ef3ec0ff96418a0f34599285.png',
            'qrcode_02468f9cfeca14bf607c97d036aa2f90.png',
            'qrcode_0246de357fea9c902086c27729baa253.png',
            'qrcode_024e0da9ab0a8319ec4a40c867bb5948.png',
            'qrcode_02734d58d0cc9a92b96b8189c4ca6522.png',
            'qrcode_027f2fcaccfe7a28c7e4ff4e23d28288.png',
            'qrcode_02969259b910925d3dc524e2e21df691.png',
            'qrcode_029b48ea1aa7bfb0cc077ca655b0f74d.png',
            'qrcode_02cebdcbf2d39a1912f711f526a794cf.png',
            'qrcode_02d6773f795388d6ef534283ebdf0836.png',
            'qrcode_02f17846face039f4ef45e0de9fa8ae7.png',
        ];
        var ticketId = this.state.selectedTicket ? this.state.selectedTicket['id'] : 0
        var idx = ticketId % images.length;

        const imagePath = "/images/qrcodes/" + images[idx]; //"/images/qrcodes/qrcode_00117843cf5892dd484dbb0ddc33deb7.png";
        return (
            <Image src={imagePath} />
        )

    }

    renderWalletBar() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const selectedWallet = Utils.get(pr.user, 'wallets.0');

        return (
            <Div style={{display: "flex", alignItems: "center"}}>
                <Div style={sty.connectWalletButton}>
                    <Button
                        color="magenta"
                        size="small"
                        style={{width:40, height:40, minWidth:"auto", fontSize: 30, fontWeight: 300}}
                        onClick={this.handleCreateWalletClick.bind(this)}
                        >+</Button>
                </Div>
                <Div style={sty.connectWalletInfo}>
                    <Div style={{marginTop:6, marginRight:8, borderRadius:24,width:24,height:24,backgroundColor:"red"}}></Div>
                    { !st.custodialWalletId ?
                        <Div>
                            <Div style={{fontWeight:"bold"}}>
                                {Utils.abbreviateHash(Utils.get(selectedWallet, 'wallet_id'))}
                            </Div>
                            <Div>MetaMask</Div>
                        </Div>
                        :
                        <Div>
                            <Div style={{fontWeight:"bold"}}>
                                {Utils.abbreviateHash(st.custodialWalletId)}
                            </Div>
                            <Div>Afterparty Wallet</Div>
                        </Div>
                    }
                </Div>
            </Div>
        )
    }

    showImageUploaderModal() {
        this.setState({
            showImageUploaderModal: true,
        });
    }

    closeImageUploaderModal() {
        this.setState({
            showImageUploaderModal: false,
        });
    }

    renderProfileHeader(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};

        const profileImageAndInfoContainer = StyleUtils.getMediaStyle('profileImageAndInfoContainer', sty.profileImageAndInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileHeader" style={sty.profileHeader}>
                <BloatedCircleDefs
                    scales={[50, 100, 200]}
                />
                <Div className="profileImageAndInfoContainer" style={profileImageAndInfoContainer}>
                    {user.photo ?
                        <Image
                            className="profilePicture"
                            style={{width: 200, borderRadius: 20, marginBottom: 20}}
                            src={user.photo}
                        />
                        :
                        null
                    }

                    <Div className="profileInfo" style={sty.profileInfo}>
                        <Div className="usernameText" style={{fontSize: 36, fontWeight: "bold"}}>
                            {user.first_name} {user.last_name}
                        </Div>
                        <Div
                            className="editUsernameContainer"
                            style={sty.editUsernameContainer}
                        >
                            {st.isEditUsername ?
                                <Input
                                    inputType="placeholderLabel"
                                    value={st.usernameText}
                                    placeholder="Username"
                                    onChange={this.usernameChange.bind(this)}
                                />
                                :
                                <Div
                                    className="usernameTextStyle"
                                    style={sty.usernameTextStyle}
                                >
                                    @{st.usernameText}
                                </Div>
                            }

                            <Button
                                style={{minWidth: 60, marginLeft: 20}}
                                onClick={this.editUsername.bind(this)}
                                size="small"
                            >
                                {st.isEditUsername ? "Save" : "Edit"}
                            </Button>
                        </Div>
                        {pr.public ?
                            null
                            :
                            <Div className="emailText">
                                {user.email}
                            </Div>
                        }

                        <Button
                            className="changePhotoButton"
                            type="text"
                            color={Colors.magenta}
                            onClick={this.showImageUploaderModal.bind(this)}
                        >
                            Change photo
                        </Button>
                        {/* pr.public ? null : <Div>{Utils.formatPhoneNumber(user["primary_phone1"])}</Div> */}
                    </Div>
                </Div>

            </Div>
        );
    }
    renderNewWallets() {
        return null;
    }
    renderNewNfts() {
        const pr = this.props;
        const st = this.state;

        return (
            <Div>
                <Div style={{display:"flex", width:"100%",position:"relative", height:200}}>
                    <Div style={this.styles.artistTilesContainer}>
                    {pr.user.items.map( (o) => {
                        return <Div key={`nftid-${o.id}`} style={this.styles.artistTile}>
                                <Div
                                     onClick={this.handleArtistClick.bind(this, o)}
                                    style={this.styles.artistTileImageContainer}>
                                    <Image style={this.styles.artistTileImage} src={o.image_url} />
                                    { false ?
                                        <Div style={this.styles.artistTileTitle}>{o.title}</Div>
                                        :
                                        null
                                    }
                                </Div>
                                { false ?
                                    <Div
                                        onClick={this.handleViewTicketClick.bind(this, o)}
                                        style={{textDecoration:"underline", marginTop:8}}>View Ticket</Div>
                                    :
                                    null
                                }
                        </Div>
                    })}
                    </Div>
                </Div>
            </Div>
        );
    }

    renderProfileWallets() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let wallets = Utils.get(pr.user, "wallets", []);

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div>
                <Div style={this.styles.sectionHeadline}>Your Wallets</Div>
                {this.renderWalletBar()}
                <Div style={this.styles.sectionHeadline}>Your Afterparty Collections</Div>
                {this.renderNewNfts()}
            </Div>
        );

        return (
            <Div className="profileWalletsContainer" style={profileSectionContainer}>
                <Div style={this.styles.profileSectionHeadline}>Wallets</Div>
                <Div>
                    <WalletTable
                        screen={pr.screen}
                        handleSetWallet={this.handleSetWallet.bind(this)}
                        user={pr.user}
                    />
                </Div>
            </Div>
        );
    }

    renderUtopians(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.utopians) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Utopians</Div>
                <Div style={{paddingRight: 30, paddingBottom: 30, paddingLeft: 30}}>
                    Utopians Info
                    <ConnerCarousel
                        screen={pr.screen}
                        items={pr.user.items}
                        imagePath="artist_image_url"
                    />
                    {/* {pr.utopians.map((utopian, utopianIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`utopian-${utopianIndex}`}>
                                <Image
                                    style={{width: 200}}
                                    src={utopian.image_url}
                                />
                            </Div>
                        )
                    })} */}
                </Div>
            </Div>
        )
    }

    renderGuardians(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.guardians) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Guardians</Div>
                <Div style={{paddingRight: 30, paddingBottom: 30, paddingLeft: 30}}>
                    Guardians Info
                    {pr.guardians.map((guardian, guardianIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`guardian-${guardianIndex}`}>
                                <Image
                                    style={{width: 200}}
                                    src={guardian.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderMintPasses(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.mintpasses) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Mint Passes</Div>
                <Div style={{paddingRight: 30, paddingBottom: 30, paddingLeft: 30}}>
                    Mint Passes Info
                    {pr.mintpasses.map((mintpass, mintpassIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`mintpass-${mintpassIndex}`}>
                                <Image
                                    style={{width: 200}}
                                    src={mintpass.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderTickets(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.festivalpasses) {
            return null;
        }

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
                <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Tickets</Div>
                <Div style={{paddingRight: 30, paddingBottom: 30, paddingLeft: 30, display: "flex", justifyContent: "space-around"}}>
                    Tickets Info
                    {pr.festivalpasses.map((ticket, ticketIndex) => {
                        return (
                            <Div className="ticketContainer" style={sty.ticketContainer} key={`ticket-${ticketIndex}`}>
                                <Image
                                    style={{width: 200}}
                                    src={ticket.image_url}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderProfileEvents(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};
        //return <Div>EVENTS</Div>;
        let renderedTiles = [];
        let myEvents = [];
        if(user.events) {
            myEvents = user.events;
            renderedTiles = myEvents.map((event, idx) => {
                return <EventCard
                    key={`${idx}-EventCard`}
                    mediaItem={event}
                    width={240}
                    height={180}
                    hideRemaining
                    onClick={this.handlePosterClick.bind(this)}
                />
            });
        } else {
            ll._("No user events", "red", user);
        }

        let centerMode = true;
        let slidePercentage = 20;

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>Events</Div>
                <Div style={{paddingRight: 30, paddingBottom: 30, paddingLeft: 30}}>
                    {myEvents.length > 0
                        ?
                          <Carousel
                            key="MarketplaceScroller"
                            showThumbs={false}
                            useKeyboardArrows
                            emulateTouch
                            showStatus={false}
                            showIndicators={false}
                            centerMode={centerMode}
                            centerSlidePercentage={slidePercentage}
                          >
                            {renderedTiles}
                          </Carousel>
                        :
                        <Div>No events yet</Div>
                    }
                </Div>
            </Div>
        );
    }

    renderMyCollection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};
        let renderedTiles = [];
        let myCollection = [];
        if(user.items) {
            myCollection = user.items;
            //console.log("Render user items", myCollection);
            renderedTiles = myCollection.map((album, idx) => {
                return (
                    <SoinCard
                        key={`${idx}-SoinCard`}
                        mediaItem={album}
                        size={180}
                        hideRemaining
                        onClick={this.handlePosterClick.bind(this)}
                    />
                )
            });
        } else {
            console.log("No user items", user);
        }
        let slidePercentage = 20;
        let transitionTime = 350;
        let centerMode = true;

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div style={sty.headline}>My Collection</Div>
                    <Div style={{padding: 30}}>
                    {myCollection.length > 0
                        ?
                          <Carousel
                            key="MarketplaceScroller"
                            showThumbs={false}
                            useKeyboardArrows
                            emulateTouch
                            showStatus={false}
                            showIndicators={false}
                            centerMode={centerMode}
                            centerSlidePercentage={slidePercentage}
                          >
                            {renderedTiles}
                          </Carousel>
                        :
                        <Div>No items yet</Div>
                    }
                </Div>
            </Div>
        )
    }

    renderCollections(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = profile ? profile : {};

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    NFT Collections
                </Div>
                {user.collections && user.collections.length > 0 ?
                    this.renderMyCollection(user)
                    :
                    <Div>No NFTs yet</Div>
                }
            </Div>
        );
    }

    renderMyNfts(myNfts) {
        let renderedTiles = [];
        if(myNfts) {
            //console.log("Render user items", myCollection);
            renderedTiles = myNfts.map( (album,idx) => {
                 return <SoinCard
                        key={idx}
                          mediaItem={album}
                          size={180}
                          hideRemaining={true}
                          onClick={this.handlePosterClick.bind(this)}
                          />
              });
        } else {
            console.log("No user items", this.props.user);
        }
        let slidePercentage = 20;
        let transitionTime = 350;
        let centerMode = true;

        return <Div style={this.styles.sectionHead}>
          <Div style={this.styles.headline}>My NFTs</Div>
              <Div style={{padding:30}}>
                  {myNfts.length > 0
                      ?
                        <Carousel
                          key={"MarketplaceScroller"}
                          showThumbs={false}
                          useKeyboardArrows={true}
                          emulateTouch={true}
                          showStatus={false}
                          showIndicators={false}
                          centerMode={centerMode}
                          centerSlidePercentage={slidePercentage}
                        >
                          {renderedTiles}
                        </Carousel>
                      :
                      <Div>No items yet</Div>
                  }
              </Div>
          </Div>;
    }

    renderOtherNfts() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const nfts = Utils.get(pr, "user.opensea", []);

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    Other NFTs
                </Div>
                {nfts.length > 0 ?
                    <Div style={{textAlign: "left"}}>
                        {this.renderMyNfts(nfts)}
                    </Div>
                    :
                    <Div>You don't own any NFTs yet</Div>
                }
            </Div>
        );
    }

    renderArtists(reppedArtists) {
      return reppedArtists.map((artist, artistIndex) => {
          return (
              <Div key={`${artistIndex}-${artist.id}`} style={{display: "flex", padding: 20}}>
                  <Div style={{marginRight:20}}>
                      <img style={{width:128,height:128, borderRadius:20,overflow:"hidden"}} src={artist["image_url"]} />
                  </Div>
                  <Div style={{marginRight:20}}>
                      {artist["title"]}
                  </Div>
                  <Div style={{marginRight:20}}>
                          <Button
                              onClick={this.handleNewSoinClick.bind(this, artist)}
                              size="small"
                          >
                              Create Afterparty NFT
                          </Button>
                  </Div>
             </Div>
         )
      });
    }

    renderArtistNftManagement() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const reppedArtists = Utils.get(pr, "seller.reppedArtists");

        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="profileSectionContainer" style={profileSectionContainer}>
                <Div className="profileSectionHeadline" style={sty.profileSectionHeadline}>
                    Artist NFT Management
                </Div>
                {pr.user && pr.user["id"] && reppedArtists && reppedArtists.length > 0 ?
                    <Div>
                        <Div>
                            Current active items/auctions: None
                        </Div>
                        <Div>
                            {this.renderArtists(reppedArtists)}
                        </Div>
                        <Button
                            onClick={this.handleNewSoinClick.bind(this)}
                        >
                            Register Additional Artists
                        </Button>
                        <Div className='row' style={sty.button}>
                        </Div>
                    </Div>
                    :
                    <Div style={{textAlign:"left", marginTop:30}}>
                        To mint custom Soins, you need to be a registered artist or an artist representative.
                    </Div>
                }
            </Div>
        );
    }

    renderTable() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const tableRows = [0, 1, 2, 3, 4]

        return (
            <Div className="tableContainer" style={sty.tableContainer}>
                <Div className="tableHeader" style={sty.tableHeader}>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Published NFTs
                    </Div>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Submitted NFTs
                    </Div>
                    <Div className="tableColumn" style={sty.tableColumn}>
                        Drafts
                    </Div>
                </Div>
                <Div className="tableContents" style={sty.tableContents}>
                    {tableRows.map((tableRow, tableRowIndex) => {
                        return (
                            <Div key={`${tableRowIndex}`} className="tableRow" style={sty.tableRow}>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    PUBLISHED
                                </Div>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    SUBMITTED
                                </Div>
                                <Div className="tableColumn" style={sty.tableColumn}>
                                    DRAFTS
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderCarousel() {
        const pr = this.props;
        let items = [
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
            {name: "hello"},
        ];

        return (
            <ConnerCarousel
                items={items}
                size={250}
            />
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let profile = false;
        // If not a public profile, display user info
        if(!pr.public) {
            profile = Utils.get(pr.user, "email") ? pr.user : null;
        } else {
            // Public profile -- check if profile is loaded
            profile = pr.publicProfile ? pr.publicProfile : false;
        }

        const profileComponent = StyleUtils.getMediaStyle('profileComponent', sty.profileComponent, sty, StyleUtils.getWidthType(pr.screen.width));

        if(!profile && pr.userLoaded && !pr.public) {
            return null;
        }

        return (
            <Div className="profileComponent" style={{...profileComponent, ...pr.style}}>
                <Div className="profileContainer" style={sty.profileContainer}>
                    <BloatedCircleDefs scales={[128]} />
                    <DebugBox
                        show={this.props}
                    />
                    {st.flashMessage ?
                        <FlashMessage
                            type="info"
                            message="Email Sent"
                            subMessage="Check your email and click the link to verify your account."
                            duration={8000}
                        />
                        :
                        null
                    }

                    {profile ?
                        <>
                            {this.renderProfileHeader(profile)}
                            <Div>
                                {!pr.public ?
                                    this.renderProfileWallets()
                                    :
                                    null
                                }
                                {/* {this.renderCarousel()} */}
                                {/* this.renderUtopians(profile)}
                                {this.renderGuardians(profile)}
                                {this.renderMintPasses(profile)}
                                {this.renderTickets(profile)}
                                {this.renderProfileEvents(profile)}
                                {this.renderCollections(profile)}
                                {this.renderOtherNfts(profile)}
                                {this.renderArtistNftManagement(profile) */}
                            </Div>
                        </>
                        :
                        null
                    }
                    {!profile && pr.userLoaded ?
                        !pr.public ?
                            <Div style={{minHeight: 1200}}>
                                Please sign in to see profile
                            </Div>
                            :
                            <Div>
                                Profile not found.
                            </Div>
                        :
                        null
                    }

                    {/* this.renderTable() */}

                    {st.showRegisterModal ?
                        <ModalRegister
                            screen={pr.screen}
                            title="Edit Profile"
                            fields="username,email,phone,password"
                            isProfile
                            user={pr.user}
                            onRequestClose={this.closeRegisterModal.bind(this)}
                            getNewUserAfterRegisterSuccess={this.getNewUserAfterRegisterSuccess.bind(this)}
                        />
                        :
                        null
                    }
                    {st.showTicketModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.closeTicketModal.bind(this)}
                            className="biddingModal"
                            color="black"
                            title={"Afterparty Ticket"}
                        >
                            <Div style={{marginTop:20, marginBottom:20}}>Here is your ticket for entry into the Festival</Div>
                            {this.renderQrCode()}
                            <Div style={{marginTop:20, marginBottom:20}}>This is only valid while you own the NFT.</Div>
                        </Modal>
                        :
                        null
                    }

                    {st.showImageUploaderModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.closeImageUploaderModal.bind(this)}
                            className="imageUploaderModal"
                            title="Change Profile Picture"
                        >
                            <Div style={{display: "flex", flexDirection: "column"}}>
                                <label className="fileUploadContainer" style={{...sty.chooseFileButton, ...StylesPlatform.cursorPointer}}>
                                    Choose Profile Picture
                                    <input
                                        style={sty.fileUploadInput}
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile.bind(this)}
                                    />
                                </label>

                                <Div className="afterpartyCrop" style={{display: "flex", gap: 20}}>
                                    {st.src ?
                                        <ReactCrop
                                            src={st.src}
                                            crop={st.crop}
                                            style={{width: 250, marginBottom: 20}}
                                            ruleOfThirds
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                        :
                                        null
                                    }

                                    {st.croppedImageUrl ?
                                        <img alt="Crop" style={sty.croppedImage} src={st.croppedImageUrl} />
                                        :
                                        null
                                    }
                                </Div>

                                {st.croppedImageUrl && !st.imageUploadSuccess ?
                                    <Button style={sty.uploadImageButton} onClick={this.uploadImage.bind(this)}>
                                        Upload Profile Picture
                                    </Button>
                                    :
                                    null
                                }

                                {/* {st.imageUploadSuccess ? this.renderImageUploadSuccessMessage() : null} */}
                            </Div>
                        </Modal>
                        :
                        null
                    }
                </Div>
                <SplashFooter
                    screen={pr.screen}
                    footerColor={Colors.indigo}
                    history={pr.history}
                />
            </Div>
        );
    }

    styles = {
        profileComponent: {
            width: "100%",
            padding: 40,
            cursor: "default",
            backgroundColor: "#0F0420",
            //borderTopLeftRadius: 30,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            color: "white",
            minHeight: "100vh",

        },
        profileComponent_md: {
            padding: 20,
            borderTopLeftRadius: 0,
        },
        profileComponent_sm: {
            padding: 15,
            borderTopLeftRadius: 0,
        },
        profileComponent_xs: {
            padding: 10,
            paddingTop: 20,
            borderTopLeftRadius: 0,
        },

        afterpartyProfile_md: {
            padding: 10,
            borderTopLeftRadius: 0,
        },
        profileHeader: {
            //display: "flex",
            marginBottom: 20,
            marginTop: 100,
        },
        profileImageAndInfoContainer: {
            display: "flex",
            marginBottom: 40,
        },
        profileImageAndInfoContainer_sm: {
            flexDirection: "column",
        },
        profileInfo: {
            display: "flex",
            marginTop: 10,
            flexDirection: "column",
            paddingLeft: 25,
            marginBottom:20,
            justifyContent: "center",
        },
        profileButtons: {
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row", //"column",
            width: "100%",
            gap: 12,
        },
        profileSectionContainer: {
            backgroundColor: Colors.purpleLight1,
            marginBottom: 30,
            padding: 20,
            borderRadius: 20,
        },
        profileSectionContainer_sm: {
            padding: 10,
            borderRadius: 10,
        },
        profileSectionHeadline: {
            fontSize: 24,
            fontWeight: "bold",
            color:"white",
            marginBottom:20,
        },
        profileSubSectionContainer: {
            backgroundColor: Colors.purpleLight2,
            marginBottom: 30,
            padding: 20,
            borderRadius: 10,
            textAlign:"left",
        },
        chevronIcon: {
            marginRight: 10,
        },
        headline: {
            fontSize: 16,
        },

        tableContainer: {
            borderRadius: 20,
            border: "1px solid white",
            padding: 20,
        },
        tableHeader: {
            display: "flex",
        },
        tableColumn: {
            flex: 1,
            padding: 10,
        },
        tableContents: {
        },
        tableRow: {
            display: "flex",
        },
        sectionHeadline: {
            fontSize: 18,
            fontWeight:"bold",
            marginTop:22,
            marginBottom:12,
            color:"white",
        },
        artistTilesContainer: {
            width:"100%",
            height: 200,
            position:"absolute",
            overflowX:"auto",
            overflowY:"hidden",
            display:"flex",
            background:Colors.indigoLight,
            borderRadius:20,
        },
        artistTile: {
            textAlign:"center",
            paddingTop: 10,
            paddingLeft: 10,
            position: "relative",
        },
        artistTileCheck: {
            width:16,
            height:16,
            borderRadius:8,
            backgroundColor:"red",
            position:"absolute",
            right: 10,
            top: 16,
            color:"white",
            fontSize: 11,
        },
        artistTileImageContainerCheck: {
            overflow: "hidden",
            boxSizing: "border-box",
            border: "1px solid red",
            margin:6,
            width:64,
            height:64,
            borderRadius:64,
            backgroundColor:"orange"
        },
        artistTileImageContainer: {
            overflow: "hidden",
            margin:6,
            width:128,
            height:128,
            position:"relative",
            overflow:"hidden",
            borderRadius:12,
            //backgroundColor:"orange"
        },
        artistTileImage: {
            width:128,height:128,
        },
        artistTileTitle: {
            position: "absolute",
            bottom: 13,
            left: 10,
            fontWeight: "bold",
            fontSize:14,
        },
        connectWalletButton: {
            marginRight: 10,
        },
        connectWalletInfo: {
            backgroundColor: Colors.indigoLight,
            borderRadius: 12,
            color: "white",
            display:"flex",
            padding: 13,
            width: "100%",
        },

        chooseFileButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            flexShrink: 0,
            fontWeight: "bold",
            width: "fit-content",
            fontWeight: 400,
            fontFamily: "Nyata",
            color: Colors.white,
            backgroundColor: Colors.magenta,
            padding: "15px 20px 14px",
            borderRadius: 100,
            fontSize: 16,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            marginBottom: 20,
        },
        croppedImage: {
            display: "flex",
            width: 250,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 15,
        },
        uploadImageButton: {
            marginBottom: 15,
            marginLeft: "auto",
            marginRight: "auto",
        },
        fileUploadInput: {
            display: "none",
        },
        usernameEditButton: {
            color: Colors.magenta,
            fontSize: 16,
            marginLeft: 10,
            // fontWeight: 300,
        },
        editUsernameContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        usernameTextStyle: {
            fontSize: 18,
        },
        profileContainer: {
            maxWidth: 1000,
            marginLeft: "auto",
            marginRight: "auto",
        },
    };
}
