import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import Dropdown from '../elements/Dropdown';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';
import Spinner from '../elements/Spinner';
import ImageBckgrnd from '../elements/ImageBckgrnd';
import FlashMessage from '../elements/FlashMessage';
import StripePayment from '../elements/StripePayment';

import Div from './Div';
import Login from './Login';
import ConnectWallet from './ConnectWallet';
import WalletTable from './WalletTable';
import ProfileMenu from './ProfileMenu';

export default class RegisterFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameText: "",
            lastNameText: "",
            usernameText: "",
            emailText: "",
            emailShadowText: "",
            emailShadowId: 0,
            phoneText: "",
            confirmPhoneText: "",
            agreeToTerms: false,
            passwordText: "",
            confirmPasswordText: "",
            verificationText: "",

            referredByText: "",
            compCodeText: "",
            instagramHandleText: "",

            plusOneNameText: "",
            plusOneEmailText: "",

            registerModalBody: props.registerModalBody ? props.registerModalBody : "username", // irlForm, username, email, verifyEmail, password, success, payment, wireSuccess, stripeSuccess, login

            selectedQuantity: {value: "1", text: "1"},

            emailLoginText: "",
            passwordLoginText: "",
            errorMessages: [],
            registerModalMessage: "",

            walletConnectedFlashMessage: false,

            stripeInfo: false,
            stripeSelected: false,
            isCreditCard: false,
            isWire: false,
            showPaymentModalNextButton: false,

            showReturningUser: false,
        }

        this.utopianQuantities = [
            {
                value: "1",
                text: "1",
            },
            {
                value: "2",
                text: "2",
            },
            {
                value: "3",
                text: "3",
            },
            {
                value: "4",
                text: "4",
            },
            {
                value: "5",
                text: "5",
            },
        ];

        if(Utils.getRequest("test") == 11) {
            this.utopianQuantities.push({
                value: "test",
                text: "test",
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        if(!pr.showRegisterFlowModal) { // NOTE: modal is not there
            this.setState({
                registerModalBody: newProps.registerModalBody,
            });
        }
    }

    onRequestRegisterModalClose() {
        const pr = this.props;

        this.setState({
            registerModalBody: "username",
            usernameText: "",
            emailText: "",
            phoneText: "",
            passwordText: "",
            confirmPasswordText: "",
            agreeToTerms: false,

            emailLoginText:"",
            passwordLoginText: "",
            errorMessages: [],
            registerModalMessage: "",
        }, () => {
            pr.onRequestRegisterModalClose();
        });
    }

    firstNameChange(e) {
        this.setState({firstNameText: e.target.value});
    }

    lastNameChange(e) {
        this.setState({lastNameText: e.target.value});
    }

    usernameChange(e) {
        this.setState({usernameText: e.target.value});
    }

    emailChange(e) {
        this.setState({
            emailText: e.target.value,
            showReturningUser: false,
            emailShadowText: "",
        });
    }

    phoneChange(e) {
        this.setState({phoneText: e.target.value});
    }

    confirmPhoneChange(e) {
        this.setState({confirmPhoneText: e.target.value});
    }

    passwordChange(e) {
        this.setState({passwordText: e.target.value});
    }

    confirmPasswordChange(e) {
        this.setState({confirmPasswordText: e.target.value});
    }

    verificationChange(e) {
        this.setState({verificationText: e.target.value});
    }

    emailLoginChange(e) {
        this.setState({emailLoginText: e.target.value});
    }

    passwordLoginChange(e) {
        this.setState({passwordLoginText: e.target.value});
    }

    toggleAgreeToTerms() {
        this.setState({agreeToTerms: !this.state.agreeToTerms});
    }

    referredByChange(e) {
        this.setState({referredByText: e.target.value});
    }

    compCodeChange(e) {
        this.setState({compCodeText: e.target.value});
    }

    plusOneNameChange(e) {
        this.setState({plusOneNameText: e.target.value});
    }

    plusOneEmailChange(e) {
        this.setState({plusOneEmailText: e.target.value});
    }

    instagramHandleChange(e) {
        this.setState({instagramHandleText: e.target.value});
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    toggleCreditCardOption() {
        const pr = this.props;
        const st = this.state;

        const itWillBeCreditCard = !st.isCreditCard;
        console.log("st.selectedQuantity.value >>>", st.selectedQuantity);
        if(itWillBeCreditCard) {
            Api.getStripeIntent(st.selectedQuantity.value, (o) => {
                if(o.success) {
                    this.setState({
                        isCreditCard: itWillBeCreditCard,
                        isWire: false,
                        stripeInfo: o.data,
                    });
                } else {
                    console.log("ERRORS: ", o.errors);
                }
            });
        }
    }

    toggleWireOption() {
        const st = this.state;

        this.setState({
            isCreditCard: false,
            isWire: !st.isWire,
        })
    }

    stripePaymentCallback(stripeResponse) {
        this.setState({
            showPaymentModalNextButton: true,
        });
    }

    onSelectQuantity(selectedQuantity) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            selectedQuantity: selectedQuantity,
        }, () => {
            if(st.isCreditCard) {
                Api.getStripeIntent(selectedQuantity.value, (o) => {
                    if(o.success) {
                        this.setState({
                            stripeInfo: o.data,
                        });
                    } else {
                        console.log("ERRORS: ", o.errors);
                    }
                });
            }
        });
    }

    goToLogin() {
        this.setState({registerModalBody: "login"});
    }

    goToRegister() {
        this.setState({registerModalBody: "username"});
    }

    // firstNameMinLength
    // lastNameMinLength
    // email
    // phoneMinLength
    // phoneSame
    // agreeToTerms

    validateForm(validations = []) {
        const pr = this.props;
        const st = this.state;

        let validateAll = false;

        if(!validations.length) {
            validateAll = true;
        }

        if((validations.includes("firstNameMinLength") && st.firstNameText.length < 3) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(9384545, "First name must be at least 3 characters.")});
            return false;
        }
        if((validations.includes("lastNameMinLength") && st.lastNameText.length < 3) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(9384598, "Last name must be at least 3 characters.")});
            return false;
        }

        if(validations.includes("email") || validateAll) {
            if(st.emailText.length < 3) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(236574, "Email must be at least 3 characters.")});
                return false;
            }
            if(!st.emailText.includes("@")) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(5487349, `Invalid email address.`)});
                return false;
            }
            if(!st.emailText.includes(".")) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(5487349, `Invalid email address.`)});
                return false;
            }
            if(st.emailText.includes(" ")) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(768947, "The email can't have any spaces.")});
                return false;
            }
        }

        if((validations.includes("phoneMinLength") && pr.isPhoneInput && st.phoneText.length < 10) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(232992, "Phone must be at least 10 characters.")});
            return false;
        }
        if((validations.includes("phoneSame") && pr.isPhoneInput && st.confirmPhoneText != st.phoneText) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(232992, "Phone numbers must match.")});
            return false;
        }
        if((validations.includes("phoneSameOptional") && pr.isPhoneInput && st.confirmPhoneText != st.phoneText && st.confirmPhoneText.length > 0) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(232992, "Phone numbers must match.")});
            return false;
        }

        if(validations.includes("compCode")) {
            if(st.compCodeText.length < 3) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(9384545, "Invalid Festival Access code.")});
                return false
            }
            if(st.compCodeText.includes(" ")) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(768947, "Festival access code can't have any spaces.")});
                return false;
            }
        }
        if(validations.includes("instagramHandle")) {
            if(st.instagramHandleText.length < 3) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(9384545, "Invalid Instagram handle.")});
                return false
            }
            if(st.instagramHandleText.includes(" ")) {
                this.setState({errorMessages: Utils.addErrorSuccessMessage(768947, "The Instagram handle can't have any spaces.")});
                return false;
            }
        }

        if((validations.includes("agreeToTerms") && !st.agreeToTerms) || validateAll) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(768947, "You must agree to the Terms of Service.")});
            return false;
        }

        return true;
    }

    registerModalBodyChange(currentRsvpModalBody, nextRsvpModalBody) {
        const pr = this.props;
        const st = this.state;
        let isAsync = false; // NOTE this will be true unless page validation needs network call

        this.setState({
            registerModalMessage: "",
            errorMessages: [],
        });

        switch(currentRsvpModalBody) {
            case "irlForm":
                const irlFormValidations = [
                    "firstNameMinLength",
                    "lastNameMinLength",
                    "email",
                    "phoneMinLength",
                    "phoneSameOptional",
                    "agreeToTerms",
                ];

                const isValid = this.validateForm(irlFormValidations);

                if(!isValid) {
                    return;
                }

                let formData = {
                    username: `${st.firstNameText}_${st.lastNameText}`,
                    first_name: st.firstNameText,
                    last_name: st.lastNameText,
                    email: st.emailText,
                    event_id: Utils.get(pr.eventItem, "id"),
                    event_type: Utils.get(pr.eventItem, "event_type"),
                    primary_phone1: st.phoneText,
                    noLogin: 1,
                    format: "json",
                };

                Api.postRegister(formData, (response) => {
                    if(response == null) {
                        return;
                    }

                    if(Utils.get(response, "success")) {
                        console.log("SUCCESS: ", response);
                        this.setState({registerModalBody: nextRsvpModalBody});
                    } else {
                       this.setState({errorMessages: Utils.get(response, "errors", "")});
                    }
                });

                break;
            case "irlFormEnd":
                this.setState({
                    firstNameText: "",
                    lastNameText: "",
                    emailText: "",
                    phoneText: "",
                    confirmPhoneText: "",
                    agreeToTerms: false,
                    showReturningUser: false,
                    registerModalBody: nextRsvpModalBody,
                });
                break;
            case "compRegisterForm":
                const eventType = Utils.get(pr.eventItem, "event_type", 1);

                let compRegisterFormValidations = [
                    "firstNameMinLength",
                    "lastNameMinLength",
                    "email",
                    "phoneMinLength",
                    "phoneSame",
                    "compCode",
                    "agreeToTerms",
                ];

                if(eventType == Constant.EVENT_TYPE_COMP_CODE) {
                    compRegisterFormValidations.push("instagramHandle");
                }

                const isCompRegisterValid = this.validateForm(compRegisterFormValidations);

                if(!isCompRegisterValid) {
                    return;
                }
                let username = `${st.firstNameText}_${st.lastNameText}`;
                // If this is a duplicate, use the generated shadow ID that matches the one put in their email
                if(st.emailShadowId != 0) {
                    username += "_" + st.emailShadowId;
                } else {
                    // Otherwise, add random number to make sure two John Smith users can register
                    username += "_" + Math.floor(Math.random() * 10000)+1;
                }

                let compRegisterFormData = {
                    username: username,
                    first_name: st.firstNameText,
                    last_name: st.lastNameText,
                    email: st.emailText,
                    email_shadow: st.emailShadowText,
                    event_id: Utils.get(pr.eventItem, "id"),
                    event_type: eventType,
                    primary_phone1: st.phoneText,
                    password: "TESTpassword",
                    retypepassword: "TESTpassword",
                    referrer_id: st.referredByText,
                    comp_code: st.compCodeText,
                    assistant: st.plusOneNameText,
                    assistant_email: st.plusOneEmailText,
                    instagram_handle: st.instagramHandleText,
                    // noLogin: 1,
                    format: "json",
                };

                Api.postRegister(compRegisterFormData, (response) => {
                    if(response == null) {
                        return;
                    }

                    if(Utils.get(response, "success")) {
                        console.log("SUCCESS: ", response);
                        this.setState({registerModalBody: nextRsvpModalBody});
                    } else {
                       this.setState({errorMessages: Utils.get(response, "errors", "")});
                    }
                });
                break;
            case "compRegisterFormEnd":
                this.setState({
                    firstNameText: "",
                    lastNameText: "",
                    emailText: "",
                    phoneText: "",
                    confirmPhoneText: "",
                    referredByText: "",
                    compCodeText: "",
                    instagramHandleText: "",
                    plusOneName: "",
                    plusOneEmail: "",
                    agreeToTerms: false,
                    registerModalBody: nextRsvpModalBody,
                });
                break;
            case "username":
                // isAsync = true; // NOTE validation needs network call
                if(st.usernameText.length < 3) {
                    this.setState({errorMessages: Utils.addErrorSuccessMessage(9384520, "Username must be at least 3 characters.")});
                    return;
                }

                const data = {
                    username: st.usernameText,
                    validate_step: "username",
                }
                Api.postRegister(data, (response) => {
                    if(response == null) {
                        return;
                    }
                    if(Utils.get(response, "success")) {
                        this.setState({registerModalBody: nextRsvpModalBody});
                    } else {
                        this.setState({errorMessages: Utils.get(response, "errors", "")});
                    }
                });
                break;
            case "email":
                const regularFormValidations = [
                    "firstNameMinLength",
                    "lastNameMinLength",
                    "email",
                    "phoneMinLength",
                    "phoneSame",
                    "agreeToTerms",
                ];

                this.validateForm(regularFormValidations);

                const dataEmail = {
                    email: st.emailText,
                    validate_step: "email",
                }

                if(pr.isPhoneInput) {
                    dataEmail.primary_phone1 = st.phoneText;
                }

                Api.postRegister(dataEmail, (response) => {
                    if(response == null) {
                        return;
                    }

                    if(Utils.get(response, "success")) {
                        this.setState({
                            registerModalBody: nextRsvpModalBody,
                        });
                    } else {
                        this.setState({errorMessages: Utils.get(response, "errors", "")});
                    }
                });
                break;
            case "password":
                const dataPassword = {
                    password: st.emailText,
                    validate_step: "password",
                }
                Api.postRegister(dataPassword, (response) => {
                    if(response == null) {
                        return;
                    }
                    if(Utils.get(response, "success")) {
                        this.setState({registerModalBody: nextRsvpModalBody});
                    } else {
                        this.setState({errorMessages: Utils.get(response, "errors", "")});
                    }
                });
                break;
            case "verifyEmail": // NOTE no validation yet
                break;
            case "success":
                break;
            case "payment":
                if(st.isCreditCard) {
                    const utopianCostCreditCard = 11075;
                    const selectedQuantityValue = Number(st.selectedQuantity.value);
                    Api.sendPaymentConfirmation(selectedQuantityValue, `$${Utils.commas(utopianCostCreditCard * selectedQuantityValue)}`);
                }

                this.setState({registerModalBody: nextRsvpModalBody});
                break;
            case "stripeSuccess":
                break;
            default:
        }

        if(false && !isAsync) {
            this.setState({registerModalBody: nextRsvpModalBody});
        }
    }

    getWalletTypes() {
        const pr = this.props;

        let listings = [];

        if(pr.listings) {
            listings = pr.listings;
        } else if(pr.eventItem) {
            listings = Utils.get(pr.eventItem, "listings", []);
        }

        let walletTypes = [];
        for(var idx in listings) {
            const listing = listings[idx];
            const bcKey = Utils.get(listing, 'bitclout_public_key', "");
            if(bcKey.length > 0) { // if it's a bitclout wallet, add bitclout
                walletTypes.push("bitclout");
            } else {  // if it's a metamask wallet, add bitclout
                walletTypes.push("metamask");
            }
        }
        //ll._("Available wallet types: ", "blue", walletTypes);
        return walletTypes;
    }

    confirmVerificationCode() {
        const pr = this.props;
        const st = this.state;

        if(st.verificationText.length == 0) {
            alert("You need to enter a verification code!");
            return;
        }

        Api.getVerifyCode(st.verificationText, false, (o) => {
            console.log("getVerifyCode:", o);
            if(o.success == 1) {
                this.setState({
                    registerModalBody: "payment",
                });
            } else {
                console.log("ERRORS: ", o.errors);
                alert(Utils.get(o.errors, "0.message"));
            }
        });
    }

    resendVerificationCode() {
        const pr = this.props;
        console.log("resendVerificationCode >>>", pr.user);
        Api.resendVerifyCode({}, (res) => {
            // alert(`Verify code has been resent to ${Utils.formatPhoneNumber(Utils.get(pr.user, "primary_phone1", ""))}!  If you didn't receive the message, email support@afterparty.com`);
            alert(`Verify code has been resent!  If you didn't receive the message, email support@afterparty.com`);
            console.log(res);
        });
    }

    handleRegister() {
        const pr = this.props;
        const st = this.state;

        if(st.passwordText.length < 3 || st.confirmPasswordText.length < 3) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(13837, "Your password must be at least 3 characters.")});
            return;
        }

        if(st.passwordText !== st.confirmPasswordText) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(874379, "Your passwords do not match.")});
            return;
        }

        let data = {
            username: st.usernameText,
            email: st.emailText,
            password: st.passwordText,
            retypepassword: st.confirmPasswordText,
            // wallet_id: st.wallet_id,
            format: "json",
        };

        if(pr.isPhoneInput) {
            data.primary_phone1 = st.phoneText;
        }

        Api.postRegister(data, (response) => {
            if(response == null) {
                return;
            }

            if(Utils.get(response, "success")) {
                // pr.setUser(response);
                Api.apiLoadProfile(pr.setUser.bind(this));

                const eventType = Utils.get(pr.eventItem, "event_type", 1);
                switch(eventType) {
                    case 1:
                        this.registerModalBodyChange("password", "success");
                        break;
                    case 2:
                        this.registerModalBodyChange("password", "success");
                        break;
                    case 3:
                        break;
                    default:
                        this.registerModalBodyChange("password", "success");
                }
            } else {
                this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    handleLoginClick() {
        const pr = this.props;
        const st = this.state;
        this.setState({errorMessages: []});

        const email = st.emailLoginText;
        const password = st.passwordLoginText;

        const data = {
            email: email,
            password: password,
            format: "json",
        };

        if(email.length < 3 || password.length < 3) {
            this.setState({errorMessages: Utils.addErrorSuccessMessage(9023842, "Email and password required.")});
            return;
        }

        Api.postLogin(data, (response) => {
            if(response && response.success) {
                if(pr.isReloadOnLogin) { // NOTE most likely not used, only as last resort if login is not working
                    window.location.reload();
                    return;
                }
                Api.apiLoadProfile(pr.setUser.bind(this));
                if(pr.setRegisteredSuccess) {
                    pr.setRegisteredSuccess(true);
                }

                this.setState({
                    registerModalBody: "success",
                });
            } else if(response) {
                console.log("ERRORS", response.errors);
                this.setState({errorMessages: response.errors});
            }
        });
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        const pr = this.props;

        let data = null;
        if(!accounts && bitCloutPublicKey) {
            data = {params: {user: {bitclout_public_key: bitCloutPublicKey}}};
        } else {
            data = {params: {user: {wallet_id: accounts[0]}}};
        }

        Api.putMusicWallet(data, (res) => {
            if(!res || res.errors.length) {
                ll._("ERROR wallet did not save", "red");
                return;
            }

            let updatedUser = res.data.user;
            console.log("updatedUser >>>", updatedUser);
            pr.setUser(updatedUser);

            this.onRequestRegisterModalClose();

            this.setState({walletConnectedFlashMessage: true}, () => {
                if(pr.connectWalletCallback) {
                    pr.connectWalletCallback();
                }
            });
        });
    }

    onBlurEmailInput() {
        const st = this.state;

        if(!st.emailText.length) {
            return;
        }

        const data = {
            email: st.emailText,
            validate_step: "email",
        }

        Api.postRegister(data, (response) => {
            if(response == null) {
                return;
            }
            if(Utils.get(response, "success")) {
                console.log("SUCCESS: new user email");
            } else {
                this.setState({
                    showReturningUser: true,
                });
            }
        });
    }

    clickYesItsMe() {
        const pr = this.props;

        const data = {
            event_type: 3, // Join
            room_id: Utils.get(pr, "eventItem.room_id", null),
            event_id: Utils.get(pr, "eventItem.id"),
        }

        Api.postCustomerEvent(data, (response) => {
            if(Utils.get(response, "success")) {
                this.setState({
                    registerModalBody: "irlFormEnd",
                });
            } else {
                this.setState({
                    errorMessages: Utils.get(response, "errors", ""),
                });
            }
        });
    }

    handleExistingUser(isExistingUser) {
        console.log("isExistingUser", isExistingUser);
        const pr = this.props;
        const st = this.state;

        if(!isExistingUser) {
            this.setState({emailText: "", showReturningUser: false});
        } else {
            switch(Utils.get(pr.eventItem, "event_type")) {
                case Constant.EVENT_TYPE_COMP_CODE:
                case Constant.EVENT_TYPE_COMP_CODE_UTOPIAN:
                    const emailSplit = st.emailText.split("@");
                    const eventId = Utils.get(pr.eventItem, "id");
                    const randomId = Math.floor(Math.random() * 10000)+1;
                    const variableNumber = `e${eventId}_${randomId}`;
                    const emailShadowText = `${emailSplit[0]}+${variableNumber}@${emailSplit[1]}`;

                    this.setState({
                        emailShadowText: emailShadowText,
                        emailShadowId: randomId,
                    });
                    break;
                default:
                    // TODO: fix for other event types
            }
        }
    }

    clickYesItsMe() {
        const pr = this.props;

        const data = {
            event_type: Constant.EVENT_TYPE_IRL, // Join
            room_id: Utils.get(pr, "eventItem.room_id", null),
            event_id: Utils.get(pr, "eventItem.id"),
        }

        Api.postCustomerEvent(data, (response) => {
            if(Utils.get(response, "success")) {
                this.setState({
                    registerModalBody: "irlFormEnd",
                });
            } else {
                this.setState({
                    errorMessages: Utils.get(response, "errors", ""),
                });
            }
        });
    }

    renderIrlFormModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="registerModalBodyContainer" style={sty.registerModalBodyContainer}>
                <Div className="registerModalTitle" style={sty.registerModalTitle}>
                    Welcome to the Afterparty
                </Div>
                <Div className="emailInputContainer" style={sty.emailInputContainer}>
                    <Input
                        value={st.emailText}
                        onChange={this.emailChange.bind(this)}
                        onBlur={this.onBlurEmailInput.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="Email address"
                        color="purple"
                    />
                    {st.showReturningUser ?
                        <Div className="isThisYouContainer" style={sty.isThisYouContainer}>
                            <Div style={{marginRight: 10}}>Have you checked in before?</Div>
                            <Button
                                size="small"
                                onClick={this.clickYesItsMe.bind(this)}
                            >
                                Yes I Have
                            </Button>
                        </Div>

                        :
                        null
                    }
                    <Input
                        value={st.firstName}
                        onChange={this.firstNameChange.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="First name"
                        color="purple"
                    />
                    <Input
                        value={st.lastName}
                        onChange={this.lastNameChange.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="Last name"
                        color="purple"
                    />
                    {pr.isPhoneInput ?
                        <Input
                            value={st.phoneText}
                            onChange={this.phoneChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder="Phone"
                            color="purple"
                        />
                        :
                        null
                    }
                    {pr.isPhoneInput ?
                        <Input
                            value={st.confirmPhoneText}
                            onChange={this.confirmPhoneChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder="Re-enter phone"
                            color="purple"
                        />
                        :
                        null
                    }

                    <Div style={sty.agreeToTermsContainer}>
                        <Div style={{alignSelf: "flex-start", marginTop: 3}}>
                            <Checkbox
                                color="white"
                                checked={st.agreeToTerms}
                                style={{marginLeft: 10, marginRight: 20}}
                                onClick={this.toggleAgreeToTerms.bind(this)}
                            />
                        </Div>
                        <Div
                            style={{cursor: "pointer"}}
                            onClick={this.toggleAgreeToTerms.bind(this)}
                        >
                            I acknowledge Afterparty’s <a style={Styles.alink} href="/webapp/privacypolicy" target="_blank">privacy policy</a> {pr.screen.mobile ? null : <br/>}and agree to Afterparty’s <a style={Styles.alink} href="/webapp/tos" target="_blank">terms of service</a>, including the arbitration provision linked <a style={Styles.alink} href="/webapp/tos#arbitration" target="_blank">here</a>.
                        </Div>
                    </Div>
                </Div>

                <Div style={{padding: 15}}>
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        color="white"
                    />
                </Div>
                <Button
                    className="irlNextButton"
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "irlForm", "irlFormEnd")}
                >
                    Check In
                </Button>
            </Div>
        )
    }

    renderIrlFormEndModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Welcome to the party!
                </Div>
                <Div style={sty.registerModalDescription}>
                    Thank you for coming, you'll get an email soon
                </Div>

                <Button
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "irlFormEnd", "irlForm")}
                >
                    OK
                </Button>

                {/* <Div style={sty.registerModalDescription}>
                    Please reach out to <b>support@afterparty.com</b> with any questions <br/>or requests for further information.
                </Div> */}
            </Div>
        )
    }

    renderCompRegisterFormModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const eventType = Utils.get(pr.eventItem, "event_type", 1);

        return (
            <Div className="registerModalBodyContainer" style={sty.registerModalBodyContainer}>
                <Div className="registerModalTitle" style={sty.registerModalTitle}>
                    {eventType == Constant.EVENT_TYPE_COMP_CODE_UTOPIAN ?
                        "Utopian Festival Registration"
                        :
                        "Apply for Afterparty Festival Access"
                    }
                </Div>
                <Div className="registerModalTitle" style={{...sty.registerModalTitle, ...{fontSize: 16}}}>
                    {eventType == Constant.EVENT_TYPE_COMP_CODE_UTOPIAN ?
                        "Utopians receive an artist-level all-access 2 day pass for themselves +1"
                        :
                        "March 18th - 19th, 2022"
                    }
                </Div>
                <Div className="emailInputContainer" style={sty.emailInputContainer}>
                    <Input
                        value={st.emailText}
                        onChange={this.emailChange.bind(this)}
                        onBlur={this.onBlurEmailInput.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="Email address"
                        color="purple"
                    />
                    {st.showReturningUser && !st.emailShadowText.length ?
                        <Div style={{display: "flex", alignItems: "center", paddingLeft: 22, marginTop: -10, marginBottom: 20}}>
                            <Div>There is already an account using that email, is this you?</Div>&nbsp;
                            <Button
                                size="small"
                                onClick={this.handleExistingUser.bind(this, true)}
                                style={{marginLeft: 5, marginRight: 10}}
                            >
                                Yes
                            </Button>
                            <Button
                                size="small"
                                onClick={this.handleExistingUser.bind(this, false)}
                            >
                                No
                            </Button>
                        </Div>
                        :
                        null
                    }

                    <Div style={{display: "flex"}}>
                        <Input
                            value={st.firstName}
                            onChange={this.firstNameChange.bind(this)}
                            style={{...sty.registerModalInput, ...{marginRight: 10}}}
                            placeholder="First name"
                            color="purple"
                        />
                        <Input
                            value={st.lastName}
                            onChange={this.lastNameChange.bind(this)}
                            style={{...sty.registerModalInput, ...{marginLeft: 10}}}
                            placeholder="Last name"
                            color="purple"
                        />
                    </Div>

                    <Div style={{display: "flex"}}>
                        {pr.isPhoneInput ?
                            <Input
                                value={st.phoneText}
                                onChange={this.phoneChange.bind(this)}
                                style={{...sty.registerModalInput, ...{marginRight: 10}}}
                                placeholder="Phone"
                                color="purple"
                            />
                            :
                            null
                        }
                        {pr.isPhoneInput ?
                            <Input
                                value={st.confirmPhoneText}
                                onChange={this.confirmPhoneChange.bind(this)}
                                style={{...sty.registerModalInput, ...{marginLeft: 10}}}
                                placeholder="Re-enter phone"
                                color="purple"
                            />
                            :
                            null
                        }
                    </Div>

                    <Input
                        value={st.compCodeText}
                        onChange={this.compCodeChange.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="Festival access code"
                        color="purple"
                    />
                    {eventType == Constant.EVENT_TYPE_COMP_CODE ?
                        <Input
                            value={st.instagramHandleText}
                            onChange={this.instagramHandleChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder="Instagram handle"
                            color="purple"
                        />
                        :
                        null
                    }

                    {eventType == Constant.EVENT_TYPE_COMP_CODE ?
                        <Input
                            value={st.referredByText}
                            onChange={this.referredByChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder="Referred by"
                            color="purple"
                        />
                        :
                        null
                    }

                    {eventType == Constant.EVENT_TYPE_COMP_CODE_UTOPIAN ?
                        <Input
                            value={st.plusOneName}
                            onChange={this.plusOneNameChange.bind(this)}
                            style={{...sty.registerModalInput, ...{marginRight: 10}}}
                            placeholder="Name of your +1"
                            color="purple"
                        />
                        :
                        null
                    }

                    {eventType == Constant.EVENT_TYPE_COMP_CODE_UTOPIAN ?
                        <Input
                            value={st.plusOneEmail}
                            onChange={this.plusOneEmailChange.bind(this)}
                            style={{...sty.registerModalInput, ...{marginRight: 10}}}
                            placeholder="Email of your +1"
                            color="purple"
                        />
                        :
                        null
                    }

                    <Div style={sty.agreeToTermsContainer}>
                        <Div style={{alignSelf: "flex-start", marginTop: 3}}>
                            <Checkbox
                                color="white"
                                checked={st.agreeToTerms}
                                style={{marginLeft: 10, marginRight: 20}}
                                onClick={this.toggleAgreeToTerms.bind(this)}
                            />
                        </Div>
                        <Div
                            style={{cursor: "pointer"}}
                            onClick={this.toggleAgreeToTerms.bind(this)}
                        >
                            I acknowledge Afterparty’s <a style={Styles.alink} href="/webapp/privacypolicy" target="_blank">privacy policy</a> {pr.screen.mobile ? null : <br/>}and agree to Afterparty’s <a style={Styles.alink} href="/webapp/tos" target="_blank">terms of service</a>, including the arbitration provision linked <a style={Styles.alink} href="/webapp/tos#arbitration" target="_blank">here</a>.
                        </Div>
                    </Div>
                </Div>

                <Div style={{padding: 15}}>
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        color="white"
                    />
                </Div>
                <Button
                    className="compCodeRegisterButton"
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "compRegisterForm", "compRegisterFormEnd")}
                >
                    RSVP
                </Button>
            </Div>
        )
    }

    renderCompRegisterFormEndModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const eventType = Utils.get(pr.eventItem, "event_type", 1);
        let message;

        if(eventType == Constant.EVENT_TYPE_COMP_CODE_UTOPIAN && st.compCodeText == 'UPNFT3') {
            message = <Div>
                <Div>Thank you for registering Fellow Utopian!</Div>
                <Div>We can't wait to see you and your guest in Vegas!</Div>
                <Div style={{marginBottom:20}}>You will receive your QR code access tickets at least 1 week before the event.</Div>
                <Div>If you have any questions always feel free to email us at <a style={{color:'white'}} href="mailto:events@afterparty.com">events@afterparty.com</a></Div>
                <Div>See festival information here: <a style={{color:'white'}} target='_blank' href="https://festival.afterparty.com/">https://festival.afterparty.com/</a></Div>
                <Div style={{marginBottom:20}}>Claim your <b>TWO FREE</b> Festival Passes you can give away or sell on OpenSea <a style={{color:'white'}} href="/webapp/festivalpasses">here</a></Div>
            </Div>;
        } else {
            message = "Please note that your Request for Access does not guarantee entry to the Festival. You will receive a confirmation with your Afterparty Pass NFT upon approval. Please note that the NFT is non-transferrable, and will require a valid ID for entry.";
        }

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Thank you for your RSVP!
                </Div>
                <Div style={sty.registerModalDescription}>
                    {message}
                </Div>

                <Button
                    style={{marginBottom: 30}}
                    onClick={this.newPage.bind(this, "home")}
                >
                    OK
                </Button>
            </Div>
        )
    }

    renderUsernameModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="usernameModalBody" style={sty.registerModalBodyContainer}>
                <Div style={{...sty.registerModalTitle, ...{maxWidth: 470}}}>
                    {Utils.get(pr.successMessageData, "usernameTitle") ?
                        Utils.get(pr.successMessageData, "usernameTitle")
                        :
                        pr.usernameModalTitle
                    }

                </Div>
                <Div style={sty.registerModalDescription}>
                    Enter a new username
                </Div>
                <Input
                    value={st.usernameText}
                    onChange={this.usernameChange.bind(this)}
                    style={sty.registerModalInput}
                    placeholder="Username"
                    color="purple"
                />
                <Div style={{padding: 15}}>
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        color="white"
                    />
                </Div>
                <Button
                    type="text"
                    style={{marginTop: -20, marginBottom: 20}}
                    onClick={this.goToLogin.bind(this)}
                >
                    Or login to an existing account
                </Button>
                <Button
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "username", "email")}
                >
                    Next
                </Button>
            </Div>
        )
    }

    renderEmailModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    What's your email?
                </Div>
                <Div style={sty.emailInputContainer}>
                    <Input
                        value={st.emailText}
                        onChange={this.emailChange.bind(this)}
                        style={sty.registerModalInput}
                        placeholder="Enter your email address"
                        color="purple"
                    />
                    {pr.isPhoneInput ?
                        <Input
                            value={st.phoneText}
                            onChange={this.phoneChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder={pr.cellphonePlaceholder ? pr.cellphonePlaceholder : "Enter cell phone for confirmation code (only numbers)"}
                            color="purple"
                        />
                        :
                        null
                    }
                    {pr.isPhoneInput ?
                        <Input
                            value={st.confirmPhoneText}
                            onChange={this.confirmPhoneChange.bind(this)}
                            style={sty.registerModalInput}
                            placeholder="Re-type cell phone"
                            color="purple"
                        />
                        :
                        null
                    }

                    <Div style={sty.agreeToTermsContainer}>
                        <Div style={{alignSelf: "flex-start", marginTop: 3}}>
                            <Checkbox
                                color="white"
                                checked={st.agreeToTerms}
                                style={{marginLeft: 10, marginRight: 20}}
                                onClick={this.toggleAgreeToTerms.bind(this)}
                            />
                        </Div>
                        <Div
                            style={{cursor: "pointer"}}
                            onClick={this.toggleAgreeToTerms.bind(this)}
                        >
                            I acknowledge Afterparty’s <a style={Styles.alink} href="/webapp/privacypolicy" target="_blank">privacy policy</a> {pr.screen.mobile ? null : <br/>}and agree to Afterparty’s <a style={Styles.alink} href="/webapp/tos" target="_blank">terms of service</a>, including the arbitration provision linked <a style={Styles.alink} href="/webapp/tos#arbitration" target="_blank">here</a>.
                        </Div>
                    </Div>
                </Div>

                <Div style={{padding: 15}}>
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        color="white"
                    />
                </Div>
                <Button
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "email", "password")}
                >
                    Next
                </Button>
            </Div>
        )
    }

    renderPasswordModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Enter Your Password
                </Div>
                <Input
                    value={st.passwordText}
                    onChange={this.passwordChange.bind(this)}
                    style={sty.registerModalInput}
                    placeholder="Password"
                    color="purple"
                    type="password"
                />
                <Input
                    value={st.confirmPasswordText}
                    onChange={this.confirmPasswordChange.bind(this)}
                    style={sty.registerModalInput}
                    placeholder="Confirm password"
                    color="purple"
                    type="password"
                />
                <ErrorBox
                    errorMessages={st.errorMessages}
                    color="white"
                />
                <Button
                    style={{marginBottom: 30}}
                    onClick={this.handleRegister.bind(this)}
                >
                    Next
                </Button>
            </Div>
        )
    }

    renderVerifyEmailModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Verify Your Email?
                </Div>
                <Div style={sty.registerModalDescription}>
                    Please click the verification link in<br/>
                    your email to continue.
                </Div>
                <Div style={{padding: 35}}>
                    {st.registerModalMessage}
                </Div>
                <Button
                    style={{marginBottom: 30}}
                    onClick={this.registerModalBodyChange.bind(this, "verifyEmail", "success")}
                >
                    Next
                </Button>
                {/* <Div style={sty.registerModalDescription}>
                    Didn't receive an email? <span onClick={this.resendEmail.bind(this)} style={{fontWeight: "bold", color: "white", cursor: "pointer"}}>Resend</span>
                </Div> */}
            </Div>
        )
    }

    renderPaymentModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const utopianCost = 10750;
        const utopianCostCreditCard = 11075;

        const selectedQuantity = Number(st.selectedQuantity.value);

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Step 2: Payment
                </Div>
                <Div style={sty.registerStripeModalDescription}>
                    <Div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20}}>
                        <Div>Number of Utopians</Div>
                        <Dropdown
                            style={{marginLeft: 10}}
                            selectStyle={{minWidth: 60}}
                            selected={st.selectedQuantity}
                            onSelectOption={this.onSelectQuantity.bind(this)}
                            options={this.utopianQuantities}
                            color="purple"
                        />
                    </Div>
                    <Div style={{display: "flex"}}>
                        <Checkbox
                            color="white"
                            checked={st.isWire}
                            style={{marginLeft: 10, marginRight: 20}}
                            onClick={this.toggleWireOption.bind(this)}
                        />
                        <Div>Option 1: Wire payment – (<a style={Styles.alink} target="_blank" href="https://afterparty.ai/images/Afterparty_Wire-Details-Checking-8314.pdf">Wire Instructions</a>)</Div>
                    </Div>
                    <Div style={{marginLeft: 51, marginTop: 4}}>
                        Total cost: <b>${Utils.commas(utopianCost)} × {selectedQuantity} = ${Utils.commas(utopianCost * selectedQuantity)}</b>
                    </Div>

                    <br />
                    <br />
                    <Div style={{display: "flex"}}>
                        <Checkbox
                            color="white"
                            checked={st.isCreditCard}
                            style={{marginLeft: 10, marginRight: 20, background:"red", border:"1px solid blue"}}
                            onClick={this.toggleCreditCardOption.bind(this)}
                        />
                        <Div>Option 2: Pay with credit card – $11,075 each ($10,750 + 3% CC Fee)</Div>
                    </Div>
                    <Div style={{marginLeft: 51, marginTop: 4}}>
                        Total cost: <b>${Utils.commas(utopianCostCreditCard)} × {selectedQuantity} = ${Utils.commas(utopianCostCreditCard * selectedQuantity)}</b>
                    </Div>

                </Div>
                {st.isCreditCard && st.stripeInfo ?
                    <StripePayment
                        stripeMode="test"
                        stripeInfo={st.stripeInfo}
                        stripePaymentCallback={this.stripePaymentCallback.bind(this)}
                    />
                    :
                    null
                }
                {st.showPaymentModalNextButton || st.isWire ?
                    <Button
                        style={{marginBottom: 30}}
                        onClick={this.registerModalBodyChange.bind(this, "payment", st.isWire ? "wireSuccess" : "stripeSuccess")}
                    >
                        Next
                    </Button>
                    :
                    null
                }
            </Div>
        )
    }

    renderWireSuccessModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Thank You For Your Purchase!
                </Div>
                <Div style={sty.registerModalDescription}>
                    Please confirm your transaction with <b>support@afterparty.com</b> once your<br/>wire transfer has gone through.
                </Div>
                <Div style={sty.registerModalDescription}>
                    Feel free to reach out with any additional questions as well!
                </Div>

                <Div style={sty.registerModalDescription}>
                    <a style={Styles.alink} target="_blank" href="https://afterparty.ai/images/Afterparty_Wire-Details-Checking-8314.pdf">Wire Instructions</a>
                </Div>
            </Div>
        )
    }

    renderStripeSuccessModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Thank You For Your Purchase!
                </Div>
                <Div style={sty.registerModalDescription}>
                    You will receive a text confirmation of your purchase.
                </Div>

                <Div style={sty.registerModalDescription}>
                    Please reach out to <b>support@afterparty.com</b> with any questions <br/>or requests for further information.
                </Div>
            </Div>
        )
    }

    renderSuccessModalBodyDeso() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let wallets = Utils.get(pr.user, "wallets", []);
        const successModalMessageRow = StyleUtils.getMediaStyle('successModalMessageRow', sty.successModalMessageRow, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="successModalBodyDeso" style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    Success! Welcome to Afterparty
                </Div>
                <Div style={{...sty.registerModalDescription, ...{width: 270, paddingBottom: 30}}}>
                    Afterparty gives you exclusive access to your favorite creators.{pr.screen.mobile ? null : <br/>}
                </Div>
                <Div className="successModalMessageRow" style={successModalMessageRow}>
                    <Div className="successModalColumn" style={{...sty.successModalColumn, ...{marginBottom: pr.screen.mobile && !pr.screen.mobileLandscape ? 40 : 0}}}>
                        <Div className="desoText5Dollars" style={sty.registerModalDescription}>
                            Get <b>$5</b> of free coin to join the event here:
                        </Div>

                        <ConnectWallet
                            style={null}
                            screen={pr.screen}
                            user={pr.user}
                            eventItem={pr.eventItem}
                            handleSetWallet={this.handleSetWallet.bind(this)}
                            walletTypes={this.getWalletTypes()}
                            loginButtonNameOverride="SIGNUP"
                        />
                    </Div>

                    <Div className="successModalColumn" style={sty.successModalColumn}>
                        <Div className="registerModalDescription" style={sty.registerModalDescription}>
                            Already have {pr.eventItem.title} social token?{pr.screen.mobile ? null : <br/>}
                        </Div>

                        <ConnectWallet
                            style={null}
                            screen={pr.screen}
                            user={pr.user}
                            eventItem={pr.eventItem}
                            handleSetWallet={this.handleSetWallet.bind(this)}
                            walletTypes={this.getWalletTypes()}
                            loginButtonColor="white"
                        />
                    </Div>
                </Div>
            </Div>
        )
    }

    renderSuccessModalBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let wallets = Utils.get(pr.user, "wallets", []);
        const profileSectionContainer = StyleUtils.getMediaStyle('profileSectionContainer', sty.profileSectionContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        let tempWalletTypes = this.getWalletTypes();

        const phoneNumber = Utils.get(pr.user, "primary_phone1", "").length > 0 ? Utils.formatPhoneNumber(pr.user.primary_phone1) : "(No phone number on file)";

        return (
            <Div className="successModalBody" style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>
                    {Utils.get(pr.successMessageData, "headline", "Success! Welcome to Afterparty")}
                </Div>
                <Div style={{...sty.registerModalDescription, ...{width: 270, paddingBottom: 30, fontWeight: "bold"}}}>
                    {Utils.get(pr.successMessageData, "line1") ?
                        Utils.get(pr.successMessageData, "line1")
                        :
                        <Div>Afterparty gives you exclusive access to your favorite creators.{pr.screen.mobile ? null : <br/>}</Div>
                    }
                </Div>
                <Div className="successModalMessageRow" style={sty.successModalMessageRow}>

                    <Div className="successModalColumn" style={sty.successModalColumn}>
                        <Div className="registerModalDescription" style={sty.registerModalDescription}>
                            {Utils.get(pr.successMessageData, "line2") ?
                                Utils.get(pr.successMessageData, "line2")
                                :
                                pr.successModalMessage
                            }
                        </Div>

                        {pr.isVerificationCode ?
                            <Div style={{width: "100%", width: 280}}>
                                <Div style={{marginBottom: 10}}>Verification code was sent to:<br/> {phoneNumber}</Div>
                                <Div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                                    <Input
                                        value={st.verificationText}
                                        onChange={this.verificationChange.bind(this)}
                                        style={{...sty.registerModalInput, ...{marginBottom: 0, marginRight: 10}}}
                                        placeholder="Verification code"
                                        color="purple"
                                    />
                                    <Button
                                        size="small"
                                        onClick={this.confirmVerificationCode.bind(this)}
                                    >
                                        Confirm
                                    </Button>
                                </Div>
                                <Button
                                    type="text"
                                    onClick={this.resendVerificationCode.bind(this)}
                                >
                                    Resend Code
                                </Button>
                                <br/>
                                <br/>
                            </Div>
                            :
                            null
                        }

                        {pr.isWalletTableOnSuccess ?
                            <Div className="profileSectionContainerModalBody" style={profileSectionContainer}>
                                <Div style={sty.profileSectionHeadline}>Wallets</Div>
                                <Div>
                                    <WalletTable
                                        screen={pr.screen}
                                        style={{width: "100%", marginTop: 15, marginBottom: 20}}
                                        handleSetWallet={this.handleSetWallet.bind(this)}
                                        user={pr.user}
                                        eventItem={pr.eventItem}
                                        walletTypes={this.getWalletTypes()}
                                    />
                                </Div>
                            </Div>
                            :
                            null
                        }

                    </Div>
                </Div>
            </Div>
        )
    }

    renderLoginModalBody() {
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="loginModalBody" style={sty.registerModalBodyContainer}>
                <Div style={sty.registerModalTitle}>Login to Afterparty</Div>
                <Input
                    value={st.emailLoginText}
                    onChange={this.emailLoginChange.bind(this)}
                    style={sty.registerModalInput}
                    placeholder="Email"
                    color="purple"
                />
                <Input
                    value={st.passwordLoginText}
                    onChange={this.passwordLoginChange.bind(this)}
                    style={sty.registerModalInput}
                    placeholder="Password"
                    color="purple"
                    type="password"
                />
                <Div style={{paddingBottom: 20}}>
                    <ErrorBox
                        errorMessages={st.errorMessages}
                        color="white"
                    />
                </Div>
                <Button
                    type="text"
                    style={{marginTop: -20, marginBottom: 20}}
                    onClick={this.goToRegister.bind(this)}
                >
                    Or create a new account
                </Button>
                <Button
                    style={{marginBottom: 30}}
                    onClick={this.handleLoginClick.bind(this)}
                >
                    Login
                </Button>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;

        const walletTypesTemp = this.getWalletTypes();
        let showBitClout = walletTypesTemp.indexOf("bitclout") != -1 ? true : false;

        return (
            <Div className="registerFlowComponent">

                {st.walletConnectedFlashMessage ?
                    <Div style={{position: "fixed", top: 0, left: 0, width: pr.screen.mobile || pr.screen.mobileLandscape ? "100%" : pr.screen.width - 80}}>
                        <FlashMessage
                            type="success"
                            message="Wallet connected"
                            subMessage="You've successfully connected your wallet!"
                            duration={8000}
                        />
                    </Div>
                    :
                    null
                }

                {pr.showRegisterFlowModal ?
                    <Modal
                        style={st.registerModalBody === "irlForm" || st.registerModalBody === "irlFormEnd" ? {paddingTop: 40} : null}
                        screen={pr.screen}
                        onRequestClose={this.onRequestRegisterModalClose.bind(this)}
                        className="registerFlowModalComponent"
                        color="purple"
                        hideCloseButton={st.registerModalBody === "irlForm" || st.registerModalBody === "irlFormEnd" || st.registerModalBody === "compRegisterForm" || st.registerModalBody === "compRegisterFormEnd"}
                    >
                        {st.registerModalBody === "irlForm" ? this.renderIrlFormModalBody() : null}
                        {st.registerModalBody === "irlFormEnd" ? this.renderIrlFormEndModalBody() : null}
                        {st.registerModalBody === "compRegisterForm" ? this.renderCompRegisterFormModalBody() : null}
                        {st.registerModalBody === "compRegisterFormEnd" ? this.renderCompRegisterFormEndModalBody() : null}
                        {st.registerModalBody === "username" ? this.renderUsernameModalBody() : null}
                        {st.registerModalBody === "email" ? this.renderEmailModalBody() : null}
                        {st.registerModalBody === "password" ? this.renderPasswordModalBody() : null}
                        {st.registerModalBody === "verifyEmail" ? this.renderVerifyEmailModalBody() : null}
                        {st.registerModalBody === "success" && !showBitClout ? this.renderSuccessModalBody() : null}
                        {st.registerModalBody === "success" && showBitClout ? this.renderSuccessModalBodyDeso() : null}
                        {st.registerModalBody === "payment" ? this.renderPaymentModalBody() : null}
                        {st.registerModalBody === "stripeSuccess" ? this.renderStripeSuccessModalBody() : null}
                        {st.registerModalBody === "wireSuccess" ? this.renderWireSuccessModalBody() : null}
                        {st.registerModalBody === "login" ? this.renderLoginModalBody() : null}
                    </Modal>
                    :
                    null
                }
            </Div>
        )
    }

    styles = {
        registerFlowComponent: {},
        registerFlowModalComponent: {},
        emailInputContainer: {
            maxWidth: 400,
            width: "100%",
        },
        agreeToTermsContainer: {
            display: "flex",
            alignItems: "center",
            maxWidth: 400,
            maxWidth: 400,
        },
        registerModalBodyContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        },
        registerModalTitle: {
            fontSize: 26,
            textAlign: "center",
            paddingBottom: 30,
        },
        registerModalDescription: {
            fontSize: 14,
            textAlign: "center",
            paddingBottom: 20,
        },
        registerStripeModalDescription: {
            fontSize: 14,
            textAlign: "left",
            paddingBottom: 20,
        },
        registerModalInput: {
            maxWidth: 400,
            width: "100%",
        },
        successModalMessageRow: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
        },
        successModalMessageRow_sm: {
            flexDirection: "column",
        },
        successModalColumn: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 400,
            width: "100%",
            marginRight: 10,
            marginLeft: 10,
        },
        profileSectionContainer: {
            backgroundColor: Colors.purpleLight1Semi,
            marginTop: 30,
            marginBottom: 30,
            padding: 10,
            paddingTop: 20,
            borderRadius: 20,
            maxWidth: 500,
            width: "100%",
        },
        profileSectionContainer_sm: {
            padding: 10,
            borderRadius: 10,
        },
        profileSectionHeadline: {
            fontSize: 24,
            fontWeight: "bold",
            color:"white",
            marginBottom:10,
        },
        isThisYouContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
        },
    }
}
