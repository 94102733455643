import React from 'react';
import { useEffect, useRef } from "react";

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';

import Input from '../elements/Input';
import Icon from '../elements/Icon';
import Spinner from '../elements/Spinner';
import Modal from '../elements/Modal';
import Image from '../elements/Image';

import Post from './Post';
import Div from '../components/Div';

import moment from "moment";

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';
import giftImage from '../assets/images/giftImage.png';

import lockWhite from '../assets/icons/lock-white.png';
import lockBlack from '../assets/icons/lock-black.png';
import infoIcon from '../assets/icons/info.png';

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <Div ref={elementRef} />;
};

export default class Chatbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            wallet: null,
            mode: Utils.getRequest('host') == 1 ? 'host' : 'guest',
            post: '',
            messages: [],
            messagesHost: [],
            lastPost: '',
            posting: false,
            firstLoad: true,
            autoScroll: true,
            chatTabSelected: "fan",

            showEmojiRow: false,

            showSuperSendInfoModal: false,

            vipChatActivated: false,

            lastHostMessageId: 0,
            newHostMessageNotification: false,
        };

        this.emojis = ["🎉", "😍", "❤️", "🔥", "👏", "👍", "😂"];
    }

    componentDidMount() {
        const pr = this.props;

        if(window.refreshMessages) {
            clearInterval(window.refreshMessages);
        }

        document.getElementById('scrollBottom').addEventListener("scroll", this.setAutoScrollHitBottom.bind(this));

        if(!Utils.getRequest('nochat')) {
            // NOTE refresh messages
            this.refreshMessages();
            // NOTE then refresh messages every 1 seconds
            window.refreshMessages = setInterval(this.refreshMessages.bind(this), 1000);
        } else {
            this.refreshMessages();
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        if(newProps.tabSwitchSignal != this.state.tabSwitchSignal) {
            this.setState({chatTabSelected: "host", tabSwitchSignal: newProps.tabSwitchSignal});
        }
        setTimeout(() => {
            if(pr.isChatboxInputAbove !== newProps.isChatboxInputAbove ||
                (pr.isDisplayed == false && newProps.isDisplayed == true)) {
                this.setState({autoScroll: true}, () => {
                    this.scrollChatbox(newProps.isChatboxInputAbove);
                });
            }
        }, 5);
    }

    componentWillUnmount() {
        const pr = this.props;

        if(window.refreshMessages) {
            clearInterval(window.refreshMessages);
        }

        document.getElementById('scrollBottom').removeEventListener("scroll", this.setAutoScroll);
    }

    setAutoScrollHitBottom(autoScroll) {
        const chatboxWindow = document.getElementById('scrollBottom');

         // NOTE if user scrolled to the bottom
        if(chatboxWindow.scrollHeight - chatboxWindow.scrollTop === chatboxWindow.clientHeight) {
            this.setState({autoScroll: true});
        } else {
            this.setState({autoScroll: false});
        }
    }

    scrollChatbox(isChatboxInputAbove) {
        const st = this.state;

        if(!st.autoScroll) {
            return null;
        }

        const scrollBottom = $("#scrollBottom");

        if(isChatboxInputAbove) {
            scrollBottom.animate({ scrollTop: 0 }, 500);
        } else {
            scrollBottom.animate({ scrollTop: scrollBottom.prop("scrollHeight") }, 500);
        }
    }

    refreshMessages() {
        const pr = this.props;
        const st = this.state;

        if(pr.noChatSignals) {
            return;
        }

        let data = {
            room_id: pr.roomId,
            feedType: this.state.chatTabSelected != "host" ? 1 : 2,
        };

        if(window.logs && window.logs.length > 0) {
            data['logs'] = Utils.serializeLogs(true);
        }

        if(window.throttle != undefined) {
            window.throttle++;
            if(window.throttle % 5 != 0) {
                if(window.throttle % 5 == 1) {
                    console.log("Throttled", window.throttle);
                }
                return;
            }
        }

        // NOTE: this would be a POST except the reverse proxy chache CAN'T cache POSTs,
        // so it has to be a GET method
        Api.getRoomChatLimiter(pr.roomId, (o, e, responseTime) => {
            // TODO: Refactor whole method to update both chat types, scroll, etc. and have a single set state
            // Clear the blocker for simultaneous calls
            window.postRoomChatLoading = false;

            if(responseTime > 2000) {
                if(Utils.get(pr, "isHost") || Utils.get(pr, "isVip")) {
                    console.log("Long responseTime > 2000, but Host or VIP, so not throttling", responseTime);
                    window.throttle = undefined;
                } else {
                    console.log("Long responseTime > 2000. Throttling...", responseTime);
                    window.throttle = 0;
                }
            } else {
                window.throttle = undefined;
            }
            let tempState = {firstLoad: false};
            let hostChatMessages = Utils.get(o, "datahost")
            if(hostChatMessages) {
                tempState['messagesHost'] = hostChatMessages;
            }

            if(hostChatMessages && hostChatMessages.length) {
                const lastHostChatMessage = hostChatMessages[hostChatMessages.length - 1];
                if(lastHostChatMessage.id != st.lastHostMessageId) {
                    this.setState({
                        lastHostMessageId: lastHostChatMessage.id,
                        newHostMessageNotification: true,
                    });
                }
            }

            this.setState(tempState);
            if(o && o.data) {
                let curLength = st.messages.length;
                let lastMessageId = -1;
                let lastLastMessageId = -1;
                if(curLength > 0) {
                    lastLastMessageId = Utils.get(st.messages[st.messages.length - 1], "id");
                }

                if(st.chatTabSelected == "fan" && o.data.length) {
                    const mostRecentMessage = o.data[o.data.length - 1];
                    lastMessageId = Utils.get(mostRecentMessage, "id");
                    pr.handleMostRecentMessage(mostRecentMessage);
                } else if(st.chatTabSelected != "fan" && o.datahost.length) {
                    const mostRecentMessage = o.datahost[o.datahost.length - 1];
                    lastMessageId = Utils.get(mostRecentMessage, "id");
                    pr.handleMostRecentMessage(mostRecentMessage);
                }

                if(lastMessageId != lastLastMessageId) {
                    this.setState({messages: o.data}, () => {
                        this.scrollChatbox(pr.isChatboxInputAbove);
                    });
                }

                if(pr.handleOnlineUsersChange) {
                    pr.handleOnlineUsersChange(Utils.get(o, "includes.onlineRoomUsers"), Utils.get(o, "includes.userCount"));
                }
            }

            if(o && o.signal) {
                const signals = Utils.get(o, "signal");
                // NOTE: if vipchatactivated signal and it's for the current user; basically, if the user bought VIP chat

                for(var sIndex in signals) {
                    const signal = signals[sIndex];

                    if(Utils.get(signal, "cmd") == "vipchatactivated" && Utils.get(signal, "customer_id") == Utils.get(pr, "user.id")) {
                        this.setState({
                            vipChatActivated: Utils.get(signal, "extra_id"),
                        });
                    }
                }

                if(pr.handleSignals) {
                    pr.handleSignals(o.signal);
                } else {
                    for(var sIndex in o.signal) {
                        var item = o.signal[sIndex];
                        if(pr.handleSignal) {
                            pr.handleSignal(item);
                        }
                    }
                }
            }
        });
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }

        this.setState({posting: true});

        const urls = {
            instagram: "instagram.com",
            twitter: "twitter.com",
            twitch: "twitch.tv",
        }

        let newMessage = st.post;
        const regexForUrls = /(http|https):\/\/(www\.)?(\w+)\.(\w+)(\/(\w+))?/g

        //Hello this is a website https://instagram.com/cozyboycries and this is really cool here https://spotify.com/cool https://twitter.com/cozyboycries

        const updatedMessage = newMessage.replace(/(http|https):\/\/(www\.)?(\w+)\.(\w+)(\/(\w+))?/g, (match, http, www, websiteName, domain) => {
            websiteName = websiteName.toLowerCase();
            if(urls[websiteName]) {
                return `<a href="${match}" target="_blank">${websiteName}.${domain}/...</a>`
            }

            return match;
        });

        let data = {
            body: updatedMessage,
            room_id: pr.roomId,
            feedType: this.state.chatTabSelected != "host" ? 1 : 2,
        };
        Api.postRoomChat(data, (o) => {
            let tempState = {};
            let hostChatMessages = Utils.get(o, "datahost")
            if(hostChatMessages) {
                tempState['messagesHost'] = hostChatMessages;
            }
             if(o && o.data) {
                tempState['messages'] = o.data;
                tempState['post'] = "";
                tempState['lastPost'] = st.post;
                tempState['posting'] = false;
                tempState['autoScroll'] = true;
                 this.setState(tempState, () => {
                     this.scrollChatbox(pr.isChatboxInputAbove);
                 });
             }
        });
    }

    handleToggleSelectedChatTab() {
        const pr = this.props;
        const st = this.state;

        const tab = st.chatTabSelected == "fan" ? "host" : "fan";
        const hasHostChatPermission = Utils.get(pr, "isHost") || Utils.get(pr, "isVip") || st.vipChatActivated;

        if(tab == 'host' && !hasHostChatPermission) {
            return Utils.get(pr, "handleButtonClick", () => {})('notvip');
        } else {
            pr.setChatTabSelected(tab);
            this.setState({
                chatTabSelected: tab,
                newHostMessageNotification: false,
            });
        }
    }

    changePost(e) {
        this.setState({post: e.target.value});
    }

    keyDownPost = (e) => {
        if(e.key === 'Enter') {
            this.handleChatPost();
            return;
        }
    }

    addEmojiToChat(emoji) {
        this.setState({post: this.state.post += emoji});
    }

    showSuperSendInfo() {
        this.setState({
            showSuperSendInfoModal: true,
        });
    }

    getArtistName() {
        const pr = this.props;

        return Utils.get(pr, "artist.title", "Creator").split(" ")[0]
    }

    renderUsername(message, customerDict) {
        let username = message['customer_id'];
        if(message['username']) {
            username = message['username'];
        } else if(message['customer_id'] in customerDict) {
            username = customerDict[message['customer_id']]['username'];
        }
        return Utils.shortenString(username, 20);
    }

    renderAvatar(message, customerDict) {
        const st = this.state;
        const sty = this.styles;

        let avatarStyle = null;
        const avatarBaseStyle = {backgroundSize: "cover", backgroundPosition: "center"};
        const defaultApLogoImage = "/images/afterparty_logo_big_landscape.jpg";
        // if(message['customer_id'] in customerDict && customerDict[message['customer_id']]['photo']) {
        if(message.photo) {
            // avatarStyle = {...this.styles.customerIcon, ...{backgroundImage: `url(${customerDict[message['customer_id']]['photo']})`},};
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${Colors.magenta}`, backgroundImage: `url(${message.photo})`},};
        } else {
            const avatarColor = Colors.colorIndex[message['customer_id'] % Colors.colorIndex.length];
            //console.log("COLORS", Colors.colorIndex, avatarColor, message['customer_id']);
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${avatarColor}`, backgroundImage: `url("${defaultApLogoImage}")`}};
        }
        return <Div className="avatarStyle" style={avatarStyle}></Div>
    }

    renderBadges(message, customerDict) {
        let badges = null;
        if(message['customer_id'] in customerDict && this.props.badgeTypes && customerDict[message['customer_id']]['badges'].length > 0 ) {
            const badgeList = customerDict[message['customer_id']]['badges'];
            let activeBadges = [];
            for(var idx in badgeList) {
                if(badgeList[idx] in this.props.badgeTypes) {
                    activeBadges.push(<img key={`${message.id}-${Math.random()}`} style={{width:24,height:24,padding:2}} src={this.props.badgeTypes[badgeList[idx]]['image_url']} />)
                }
            }
            if(activeBadges.length > 0) {
                badges = activeBadges;
            }
        }
        return badges;
    }

    renderChatMessages(messages, customerDict) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const messageContainer = StyleUtils.getMediaStyle('messageContainer', sty.messageContainer, sty, StyleUtils.getWidthType(pr.width));
        const messageStyle = StyleUtils.getMediaStyle('message', sty.message, sty, StyleUtils.getWidthType(pr.width));

        if(!messages.length) {
            return (
                <Div className="noChatMessages" style={{paddingTop: 30, textAlign: "center", fontSize: 20}}>
                    No chat messages yet
                </Div>
            )
        }

        return messages.map((messageUnused, messageIndex) => {
            let message = messages[messageIndex];
            if(pr.isChatboxInputAbove) { // reversing the array without mutation
                message = messages[messages.length - 1 - messageIndex]
            }

            const messageTime = moment.utc(message['created_at']).local().format("LT");
            // const messageSelectStyle = Utils.get(message, "message_type") == 2 ? {background:"purple"} : {};
            const isSuperChat = Utils.get(message, "message_type") == 2;
            //console.log("TIME", moment(message['created_at']).local().format("yyyy-MM-DD hh:mm:ss"));

            if(isSuperChat) {
                return (
                    <Div style={ {...messageContainer, ...sty.messageSuperStyle} } className="messageContainer superChatContainer gradientBorderPinkOrangeCss" key={`msg-${message['id']}`}>
                        <Div className="superSendImageUsernameContainer" style={sty.superSendImageUsernameContainer}>
                            {message.body.length ?
                                null
                                :
                                this.renderAvatar(message, customerDict)
                            }
                            <Image
                                className="superChatImageFromProdChat"
                                style={{width: 89, marginRight: 8}}
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_2671c3fca8cbe9e9a3308c1d3c6bd221.png"
                            />
                            <Div style={sty.superChatCost} className="superChatCost">
                                ${Utils.get(message, "json_data.paidAmount")}
                            </Div>
                            <Div>
                                &nbsp;by @{this.renderUsername(message, customerDict)}
                            </Div>
                            <Image
                                style={{...sty.infoIcon, ...StylesPlatform.cursorPointer}}
                                className="infoIcon"
                                src={infoIcon}
                                onClick={this.showSuperSendInfo.bind(this)}
                            />
                            {/* {message.body.length ?
                                null
                                :
                                <Div className="messageDate" style={{...sty.messageDate, ...{marginLeft: "auto"}}}>
                                    {messageTime}
                                </Div>
                            } */}
                        </Div>
                        {message.body.length ?
                            <Div className="customerInfoContainer" style={sty.customerInfoContainer}>
                                {this.renderBadges(message, customerDict)}
                                {this.renderAvatar(message, customerDict)}
                                <Div className="customerName" style={sty.customerName} data-id={Utils.get(message, "customer_id")}>
                                    <Div className="message" style={messageStyle} dangerouslySetInnerHTML={{__html: message.body}} />
                                </Div>
                                {/* <Div className="messageDate" style={sty.messageDate}>
                                    {messageTime}
                                </Div> */}
                            </Div>
                            :
                            null
                        }
                    </Div>
                );
            } else {
                return (
                    <Div className="messageContainer" key={`msg-${message['id']}`} style={messageContainer}>
                        <Div className="customerInfoContainer" style={sty.customerInfoContainer}>
                            {this.renderBadges(message, customerDict)}
                            {this.renderAvatar(message, customerDict)}
                            <Div className="customerName" style={sty.customerName} data-id={Utils.get(message, "customer_id")}>
                                @{this.renderUsername(message, customerDict)}
                                <Div className="message" style={messageStyle} dangerouslySetInnerHTML={{__html: message.body}} />
                            </Div>
                            <Div className="messageDate" style={sty.messageDate}>
                                {messageTime}
                            </Div>
                        </Div>
                    </Div>
                );
            }
        });
    }

    renderEmojiRow() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const emojiRow = StyleUtils.getMediaStyle('emojiRow', sty.emojiRow, sty, StyleUtils.getWidthType(pr.width));

        return (
            <Div className="emojiRow" style={emojiRow}>
                {this.emojis.map((emoji, emojiIndex) => {
                    return (
                        <Div
                            key={`emoji-${emoji}`}
                            className={`emoji-${emoji}`}
                            style={{...sty.emoji, ...StylesPlatform.cursorPointer}}
                            onClick={this.addEmojiToChat.bind(this, emoji)}
                        >
                            {emoji}
                        </Div>
                    )
                })}
            </Div>
        )
    }

    renderChatSwitchTabRow(chatDirection) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const roomChatType = Utils.get(pr, "roomItem.room_chat_type");
        if(roomChatType == Constant.FAN_TO_FAN || roomChatType == Constant.FAN_TO_FAN_HOST_SEES_HOST_CHAT && pr.isHost) {
            return null;
        }

        const mobile = Utils.get(pr, 'screen.mobile');
        const mobileLandscape = Utils.get(pr, 'screen.mobileLandscape');
        const chatSelected = {background: "white", color: "black"};
        const fanChatSelected = st.chatTabSelected == "fan" ? chatSelected : {};
        const hostChatSelected = st.chatTabSelected != "fan" ? chatSelected : {};

        return (
            <Div className="fanToFanChatIconContainer" style={sty.fanToFanChatIconContainer}>
                <Div className="fanToFanChatContainer" style={sty.fanToFanChatContainer}>
                    <Div
                        className="fanToFanChatSwitch"
                        onClick={this.handleToggleSelectedChatTab.bind(this)}
                        style={ { ...sty.chatTab, ...fanChatSelected } }
                    >
                        Fan-to-Fan Chat
                    </Div>
                    <Div
                        className="chatWithUserSwitch"
                        onClick={this.handleToggleSelectedChatTab.bind(this)}
                        style={ {...sty.chatTab, ...hostChatSelected} }
                    >
                        <Div className="chatWithArtistNameContainer" style={sty.chatWithArtistNameContainer}>
                            Chat with {this.getArtistName()}
                        </Div>
                        {st.vipChatActivated || Utils.get(this.props, "isHost") || Utils.get(this.props, "isVip") ?
                            null
                            :
                            st.chatTabSelected == "fan" ?
                                <Image
                                    className="lockWhite"
                                    src={lockWhite}
                                    style={sty.lockIconStyle}
                                />
                                :
                                <Image
                                    className="lockBlack"
                                    src={lockBlack}
                                    style={sty.lockIconStyle}
                                />
                        }
                        {st.newHostMessageNotification ?
                            <Div style={{color: "red", marginLeft: 6}}>•</Div>
                            :
                            null
                        }
                    </Div>
                </Div>
                <Icon
                    icon="question-mark"
                    title="Tip info"
                    size={14}
                    onClick={ Utils.get(pr, "handleButtonClick", () => {}).bind(this, st.chatTabSelected == "fan" ? "tipinfo" : "tipinfo2") }
                    style={{...sty.qestionMarkStyle, ...StylesPlatform.cursorPointer}}
                />
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customerDict = Utils.arrayToObject(pr.customers, "id");
        const iconStyle = st.posting ? {...StylesPlatform.cursorPointer, ...{color: "gray", width:36}} : {...StylesPlatform.cursorPointer, ...{width:48}};

        let chatboxStyle = StyleUtils.getMediaStyle('chatboxStyle', sty.chatboxStyle, sty, StyleUtils.getWidthType(pr.width, 'md'));
        let scrollContainer = StyleUtils.getMediaStyle('scrollContainer', sty.scrollContainer, sty, StyleUtils.getWidthType(pr.width));
        let inputContainer = StyleUtils.getMediaStyle('inputContainer', sty.inputContainer, sty, StyleUtils.getWidthType(pr.width));
        let chatboxInputBelow = StyleUtils.getMediaStyle('chatboxInputBelow', sty.chatboxInputBelow, sty, StyleUtils.getWidthType(pr.width, "md"));

        const mobileLandscape = Utils.get(pr, 'screen.mobileLandscape');

        if(mobileLandscape) {
            scrollContainer.padding = "10px 10px 0 10px";
            scrollContainer.height = "calc(100vh - 172px)";
        }

        if(st.chatTabSelected != "fan") {
            inputContainer = Utils.deepCopy(inputContainer);
            inputContainer.backgroundColor = Colors.dragon;

            if(!pr.screen.mobile) {
                scrollContainer = Utils.deepCopy(scrollContainer);
                scrollContainer.backgroundColor = "#3C0E25";
                scrollContainer.webkitMaskImage = "none";
            }
        }

        if(st.showEmojiRow) {
            scrollContainer = Utils.deepCopy(scrollContainer);
            scrollContainer.height = 457;
        }

        const roomChatType = Utils.get(pr, "roomItem.room_chat_type");
        if(roomChatType == Constant.FAN_TO_FAN || roomChatType == Constant.FAN_TO_FAN_HOST_SEES_HOST_CHAT && pr.isHost) {
            scrollContainer = Utils.deepCopy(scrollContainer);
            scrollContainer.height = 535;
        }

        const chatboxMessageInputBelow = (
            <Div className="inputContainer" style={inputContainer}>
                {st.showEmojiRow ?
                    this.renderEmojiRow()
                    :
                    null
                }
                <Div className="inputAndSendIconContainer" style={sty.inputAndSendIconContainer}>
                    <Div className="chatboxAndSendContainer" style={sty.chatboxAndSendContainer}>
                        <Input
                            className="chatboxInputBelow1"
                            value={st.post}
                            onChange={this.changePost.bind(this)}
                            onKeyDown={this.keyDownPost}
                            style={chatboxInputBelow}
                            placeholder="Say something"
                        />
                        <Icon
                            icon="paper-plane"
                            color="black"
                            size={24}
                            onClick={this.handleChatPost.bind(this)}
                            style={{...iconStyle, ...sty.sendIconStyle}}
                        />
                    </Div>
                    {pr.screen.mobile || pr.screen.mobileLandscape ?
                        null
                        :
                        <Icon
                            icon="smiley-face"
                            size={24}
                            onClick={() => { this.setState({showEmojiRow: !this.state.showEmojiRow}) }}
                            style={{...iconStyle, ...{marginLeft: 10}}}
                        />
                    }

                    <Image
                        className="giftImageButton"
                        src={giftImage}
                        style={{...{width: 36, marginLeft: 10}, ...StylesPlatform.cursorPointer}}
                        onClick={ Utils.get(pr, "handleButtonClick", () => {}).bind(this, 'gift') }
                    />
                    {/* <Div
                        style={{background:Colors.magenta,width:36,height:36,borderRadius:18, padding: "4px 7px"}}
                        onClick={ Utils.get(pr, "handleButtonClick", () => {}).bind(this, 'gift') }
                    >
                        <Icon
                            icon="gift"
                            size={24}
                            onClick={this.handleChatPost.bind(this)}
                            style={iconStyle}
                        />
                    </Div> */}
                    {/* <Icon
                        style={{width:36,height:36}}
                        icon="edit"
                        title="Chat info"
                        size={24}
                        onClick={ Utils.get(pr, "handleButtonClick", () => {}).bind(this, 'chatinfo') }
                        style={iconStyle}
                    /> */}
                </Div>
                {this.renderChatSwitchTabRow("below")}
            </Div>
        )

        return (
            <Div className="chatboxComponent" style={{...chatboxStyle, ...pr.style}}>
                {pr.screen.mobile ?
                    <Div
                        className="chatboxCloseButton"
                        onClick={pr.closeChatbox.bind(this)}
                        style={{
                            position: "absolute",
                            zIndex: 1000,
                            left: 0,
                            top: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            width: "100%",
                            paddingTop: 10,
                            paddingRight: 20,
                        }}
                    >
                        <Icon
                            size={24}
                            icon="close"
                            style={StylesPlatform.cursorPointer}
                        />
                    </Div>
                    :
                    null
                }
                <Div id="scrollBottom" className="scrollContainer" style={scrollContainer}>
                    {pr.screen.mobile && false ?
                        <Div
                            className="chatboxTopGradient"
                            style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: 60,
                                zIndex: 1000,
                                borderTop: "1px solid #444",
                                // background: "linear-gradient(180deg, #0F0420 25%, rgba(0,0,0,0) 100%)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                width: "100%",
                                paddingRight: 30,
                                fontSize: 24,
                            }}
                        >
                        </Div>
                        :
                        null
                    }

                    {st.firstLoad ?
                        <Spinner
                            message="Loading Messages"
                            style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}
                        />
                        :
                        this.renderChatMessages(st.chatTabSelected != 'fan' || (pr.isHost && roomChatType == Constant.FAN_TO_FAN_HOST_SEES_HOST_CHAT) ? st.messagesHost : st.messages, customerDict)
                    }
                </Div>

                {!pr.isChatboxInputAbove ? chatboxMessageInputBelow : null}

                {st.showSuperSendInfoModal ?
                    <Modal
                        screen={pr.screen}
                        className="showSuperSendInfoModal"
                        onRequestClose={ () => { this.setState({showSuperSendInfoModal: false}); } }
                    >
                        <Div style={{maxWidth: 343}}>
                            <Image
                                className="superChatImageFromProd"
                                style={{width: 128}}
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_2671c3fca8cbe9e9a3308c1d3c6bd221.png"
                            />
                            <Div className="superChatInfoTitle" style={{fontSize: 23, fontWeight: 400}}>
                                Get your message seen and celebrate {this.getArtistName()}!
                            </Div>
                            <br />
                            <Div className="superChatInfoDescription" style={{fontSize: 17, fontWeight: 300}}>
                                You can send a personalized message to {this.getArtistName()} along with your SuperSend, letting him know how much you appreciate his content.
                            </Div>
                            <br />
                            <Div className="superChatInfoDescription" style={{fontSize: 17, fontWeight: 300}}>
                                Grab the creator's attention and making it more likely for them to respond and engage with you. Try SuperSend today and start celebrating your favorite creators in a whole new way!
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        )
    }

    styles = {
        chatboxStyle: {
            color: "white",
            borderTopLeftRadius: 36,
            borderTopRightRadius: 36,
            backgroundColor: "indigoLight",
            overflow: "hidden",
            width: "100%",
        },
        chatboxStyle_md: {
        },
        scrollContainer: {
            fontSize: 14,
            overflowY: "scroll",
            padding: "24px 24px 0 24px",
            height: 494,
            backgroundColor: Colors.indigoLight,
        },
        scrollContainer_md: {
            // height: 370,
            backgroundColor: Colors.indigo,
        },
        scrollContainer_sm: {
            backgroundColor: Colors.indigo,
            // height: 370,
        },
        scrollContainer_xs: {
            // backgroundColor: Colors.indigo,
            height: 430,
            padding: "50px 30px 0",
            backgroundColor: Colors.translucentBlack,

        },
        messageContainer: {
            color: "white",
            marginBottom: 20,
            wordWrap: "break-word",
        },
        customerInfoContainer: {
            display: "flex",
            marginTop: 5,
        },
        customerIcon: {
            overflow: "hidden",
            backgroundSize: "cover",
            flexShrink: 0,
            width: 32,
            height: 32,
            backgroundColor: Colors.purpleLight,
            marginRight: 10,
            borderRadius: 100,
        },
        customerName: {
            marginRight: "auto",
            fontSize: 12,
            fontWeight: 500,
        },
        messageDate: {
            color: "white",
            fontSize: 10,
            whiteSpace: "nowrap"
        },
        message: {
            padding: "6px 0",
            fontSize: 14,
            color: "white",
            fontWeight: 300,
        },
        message_md: {
            fontSize: 12,
            padding: "0 0 5px 0",
        },
        message_sm: {
            fontSize: 12,
            padding: "0 0 5px 0",
        },
        message_xs: {
            fontSize: 12,
            padding: "0 0 5px 0",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "10px 16px 20px",
            backgroundColor: Colors.indigo,
            borderColor: Colors.indigoLight,
            borderLeft: `3px solid ${Colors.indigoLight}`,
            borderRight: `3px solid ${Colors.indigoLight}`,
        },
        inputContainer_md: {
        },
        inputContainer_sm: {
        },
        inputContainer_xs: {
            borderLeft: "none",
            borderRight: "none",
        },
        inputAndSendIconContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            fontSize: 12,
        },
        chatboxInputBelow: {
            marginBottom: 0,
            marginRight: 14,
            fontSize: 12,
            paddingTop: 18,
            paddingBottom: 18,
            paddingRight: 44,
            paddingLeft: 24,
            backgroundColor: "white",
            border: "none",
            color: "black",
        },
        // chatboxInputBelow_md: {
        //     marginBottom: 0,
        //     marginRight: 14,
        //     fontSize: 12,
        //     padding: 10,
        // },
        chatboxInputAbove: {
            marginBottom: 0,
            marginRight: 14,
            fontSize: 12,
            padding: 10,
            backgroundColor: Colors.indigoLighter,
            border: "none",
            color: "white",
        },
        emojiRow: {
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            fontSize: 24,
            paddingBottom: 10,
            maxWidth: 342,
            alignSelf: "baseline",
        },
        emoji: {
            padding: "0 8px",
        },
        chatTab: {
            padding: 3,
            borderRadius: 28,
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            userSelect: "none",
        },
        fanToFanChatContainer: {
            cursor: "pointer",
            padding: 4,
            borderRadius: 28,
            width: "100%",
            background: Colors.purple,
            display: "flex",
            flexDirection: "row",
            // maxWidth: 342,
        },
        fanToFanChatIconContainer: {
            display: "flex",
            width: "100%",
            marginTop: 10,
        },
        qestionMarkStyle: {
            display: "flex",
            marginLeft: 8,
            alignSelf: "center",
        },
        chatboxAndSendContainer: {
            position: "relative",
            width: "100%",
        },
        sendIconStyle: {
            position: "absolute",
            top: 14,
            right: -10,
        },
        lockIconStyle: {
            width: 9,
        },

        messageSuperStyle: {
            background: "rgba(248, 124, 85, 0.3)",
            borderRadius: 22,
            padding: 13,
        },
        superSendImageUsernameContainer: {
            display: "flex",
            alignItems: "center",
        },

        infoIcon: {
            width: 17,
            marginLeft: 8,
        },

        chatWithArtistNameContainer: {
            marginRight: 6,
            maxWidth: 130,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        superChatCost: {
            fontSize: 18,
            fontWeight: 700,

            background: "linear-gradient(180deg, #F4A868 0%, #F87B55 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            webkitTextStroke: "1px white",
        },
    };
}
