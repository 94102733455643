import React from 'react';

import Div from '../components/Div';

export default class LoadingBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const pr = this.props;
        const sty = this.styles;

        const percent = pr.percent <= 100 ? pr.percent : 100;

        return (
            <Div
                className="loadingBarElementOuter"
                style={sty.loadingBarElementOuter}
            >
                <Div
                    className={`loadingBarElement ${pr.className}`}
                    style={{...sty.loadingBarElement, ...pr.style}}
                >
                    <Div
                        className="loadingBarInner"
                        style={{...sty.loadingBarInner, ...{width: `${percent}%`}}}
                    ></Div>
                </Div>
                {pr.showPercent ?
                    <Div
                        className="percentage"
                        style={sty.percentage}
                    >
                        {percent}%
                    </Div>
                    :
                    null
                }

            </Div>
        );
    }

    styles = {
        loadingBarElementOuter: {
            display: "flex",
            alignItems: "center",
        },
        loadingBarElement: {
            width: "100%",
            height: 4,
            borderRadius: 100,
            backgroundColor: "grey",
            marginTop: 10,
            marginBottom: 10,
        },
        loadingBarInner: {
            backgroundColor: "white",
            borderRadius: 100,
            height: 4,
            width: 0,
        },
        percentage: {
            flexShrink: 0,
            color: "grey",
            marginLeft: 10,
            width: 32,
            textAlign: "right",
        }
    };
}
