export default {
    EVENT_TYPE_ONLINE: 1,
    EVENT_TYPE_STRIPE: 2,
    EVENT_TYPE_IRL: 3,
    EVENT_TYPE_COMP_CODE: 4,
    EVENT_TYPE_COMP_CODE_UTOPIAN: 5,

    EVENT_STATUS_CLAIMING_NOT_YET_OPEN: 1,
    EVENT_STATUS_CLAIMING_OPEN: 2,
    EVENT_STATUS_CLAIMING_CLOSED: 3,
    EVENT_STATUS_CLAIMING_REVEALED: 4,

    EVENT_STATUS_SALE_ACTIVE: 6,

    HEADER_TYPE_NO_HEADER: 0,
    HEADER_TYPE_SOIN_FLYOUT: 1,
    HEADER_TYPE_NINE_DOT: 2,
    HEADER_TYPE_WEBAPP_HEADER: 3,

    ROOM_CMD_TYPE_COOKIED: 1,
    ROOM_CMD_TYPE_TOGGLE_ALL: 2,
    ROOM_CMD_TYPE_TOGGLE_CUSTOMER: 3,

    TRUE: 1,
    FALSE: 0,

    LEADERBOARD_TYPE_DEFAULT: 1,
    LEADERBOARD_TYPE_ROOM: 2,

    FOLLOW_TOGGLE: "3",

    FAN_TO_FAN: 1,
    FAN_TO_FAN_HOST_SEES_BOTH: 2,
    FAN_TO_FAN_HOST_SEES_HOST_CHAT: 3,

    VIP_TYPE_REGULAR: 1,
    VIP_TYPE_FRONT_ROW: 2,
    VIP_TYPE_LEADERBOARD: 3,

    MESSAGE_TYPE_BASIC_CHAT: 1,
    MESSAGE_TYPE_SUPER_CHAT: 2,
    MESSAGE_TYPE_AUDIO_CHAT: 3,
    MESSAGE_TYPE_WAITING_FOR_AI_RESPONSE: 4,
    MESSAGE_TYPE_TOPIC_RESET: 5,
    MESSAGE_TYPE_IMAGE_STRIP: 7,

    DEFAULT_COUNTRIES: ["us", "mx", "br", "fr", "sp", "gb", "it", "ar", "de", "ca", "pt", "co", "cl", "au", "ve"],

    AI_SURVEY_BOOLEAN: 1,
    AI_SURVEY_INTEGER: 2,
    AI_SURVEY_TEXT: 3,
    AI_SURVEY_INTEGER_1_TO_5: 4,

    AI_FEEDBACK_SEND_IMAGE_TO_ARTIST: -100,

    AI_INITIAL_IMAGE_ID: -2,
    AI_INITIAL_AUDIO_ID: -1,
}
