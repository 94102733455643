import React from 'react';

import Styles from '../modules/Styles';
import Utils from '../modules/Utils';
import StyleUtils from '../modules/StyleUtils';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';
import Constant from '../modules/Constant';

import Div from './Div';
import Image from './Image';
import Button from './Button';
import WebappLoginRegister from './WebappLoginRegister';
import SplashMenu from './SplashMenu';
import Icon from './Icon';
import AfterpartyLogo from './AfterpartyLogo';

import defaultPhoto from "../assets/images/ticketflow/ga ticket pass.png";

export default class WebappHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: true,
            showLogin: false,
            showSplashMenu: false,
        };
    }

    closeSplashMenu() {
        this.setState({
            showSplashMenu: false,
        });
    }

    onLoginToggle() {
        this.setState({
            isLogin: !this.state.isLogin,
        });
    }

    onLoginPhone(response) {}

    onLoginEmail(response) {
        if(Utils.get(response, "errors", []).length) {
            return;
        }

        this.hideLogin();
    }

    onSignUpPhone(response) {}

    onConfirmVerificationCodeLogin(response) {
        if(Utils.get(response, "errors", []).length) {
            return;
        }

        this.hideLogin();
    }

    onConfirmVerificationCodeSignUp(response) {
        if(Utils.get(response, "errors", []).length) {
            return;
        }

        this.hideLogin();
    }

    toggleLogin() {
        this.setState({
            showLogin: !this.state.showLogin,
        });
    }

    showSplashMenu() {
        this.setState({
            showSplashMenu: !this.state.showSplashMenu,
        });
    }

    hideLogin() {
        this.setState({
            showLogin: false,
        });
    }

    renderWalletInfo() {
        const pr = this.props;
        const st = this.state;

        const walletId = pr.user.wallet_id;
        const metamaskWalletId = pr.metamaskWalletId;

        const walletIdAbrev = Utils.abbreviateHash(walletId);
        const metamaskWalletIdAbrev = Utils.abbreviateHash(metamaskWalletId);

        if(walletId && !metamaskWalletId) {
            return (
                <>
                    <Div title="My Afterparty Wallet">{walletIdAbrev}</Div>
                    <Div>AP Wallet connected</Div>
                </>
            )
        } else if(!walletId && metamaskWalletId) {
            return (
                <>
                    <Div title="My MetaMask Wallet">{metamaskWalletIdAbrev}</Div>
                    <Div>MetaMask connected</Div>
                </>
            )

        } else if(walletId && metamaskWalletId) {
            return (
                <>
                    <Div title="My Afterparty Wallet">Afterparty: {walletIdAbrev}</Div>
                    <Div title="My MetaMask Wallet">MetaMask: {metamaskWalletIdAbrev}</Div>
                </>
            )
        }

        return null;
    }

    renderWalletConnected() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(pr.user.wallet_id || pr.metamaskWalletId) {
            return null;

            return (
                <Div
                    className="walletConnectedProfileStatus"
                    style={{...sty.walletConnectedProfileStatus, ...StylesPlatform.cursorPointer}}
                    onClick={this.toggleLogin.bind(this)}
                >
                    <Div className="greenDot" style={sty.greenDot}></Div>
                    <Div className="walletIdContainer" style={sty.walletIdContainer}>
                        {this.renderWalletInfo()}
                    </Div>
                </Div>
            )
        }

        return null;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const webappHeader = StyleUtils.getMediaStyle('webappHeader', sty.webappHeader, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        const boredApeMenuImageStyle = StyleUtils.getMediaStyle('boredApeMenuImage', sty.boredApeMenuImage, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <>
                <Div
                    className="webappHeader"
                    style={webappHeader}
                >
                    {pr.selected == "aichat" && pr.screen.mobile ?
                        <Div style={{marginRight: "auto"}}></Div>
                        :
                        <AfterpartyLogo
                            width={pr.screen.mobile ? 50 : 200}
                            logo={pr.screen.mobile ? "small" : null}
                            style={{marginRight: "auto", marginLeft: 20}}
                            history={pr.history}
                            screen={pr.screen}
                        />
                    }
                    
                    {this.renderWalletConnected()}

                    {pr.user.email ?
                        <Image
                            style={boredApeMenuImageStyle}
                            className="boredApeMenuImageStyle"
                            src={Utils.get(pr.user, "photo", defaultPhoto)}
                        />
                        :
                        null
                    }

                    {pr.user.email ?
                        null
                        :
                        <Button
                            type="outlined"
                            size={pr.screen.mobile ? "small" : ""}
                            style={{marginRight: 16, fontWeight: 600, paddingLeft: 60, paddingRight: 60, maxWidth: pr.screen.mobile ? 160 : 400}}
                            onClick={this.toggleLogin.bind(this)}
                        >
                            SIGN IN / SIGN UP
                        </Button>
                    }

                    <Icon
                        style={{...sty.menuIconImage, ...StylesPlatform.cursorPointer, ...{backgroundColor: Colors.indigoLight}}}
                        className="menuIconImage3"
                        icon="bars"
                        onClick={this.showSplashMenu.bind(this)}
                    />

                </Div>
                {st.showLogin && !pr.user.email ?
                    <Div className="webappHeaderMenuContainer" style={sty.webappHeaderMenuContainer}>
                        <WebappLoginRegister
                            isLogin={st.isLogin}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            setUser={pr.setUser.bind(this)}
                            onLoginToggle={this.onLoginToggle.bind(this)}
                            onLoginPhone={this.onLoginPhone.bind(this)}
                            onLoginEmail={this.onLoginEmail.bind(this)}
                            onSignUpPhone={this.onSignUpPhone.bind(this)}
                            onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLogin.bind(this)}
                            onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUp.bind(this)}
                        />
                    </Div>
                    :
                    null
                }

                {st.showSplashMenu ?
                    <SplashMenu
                        setUser={pr.setUser}
                        selected={pr.selected}
                        screen={pr.screen}
                        history={pr.history}
                        user={pr.user}
                        userLoaded={pr.userLoaded}
                        change={pr.change.bind(this)}
                        closeSplashMenu={this.closeSplashMenu.bind(this)}
                    />
                    :
                    null
                }
            </>
        );
    }

    styles = {
        webappHeader: {
            position: "fixed",
            top: 0,
            backgroundColor: Colors.indigo,
            width: "100%",
            height: 72,
            display: "flex",
            alignItems: "center",
            fontFamily: "Nyata",
            color: "white",
            fontWeight: 300,
            zIndex: 100,
        },
        ticketPassHeader_md: {
            height: 72,
        },
        apLogoPassHeaderSmall: {
            width: 36,
            marginLeft: 20,
            marginRight: "auto",
        },
        walletConnectedProfileStatus: {
            paddingRight: 15,
            display: "flex",
            alignItems: "center",
            color: "white",
        },
        greenDot: {
            backgroundColor: Colors.neonGreen,
            width: 6,
            height: 6,
            borderRadius: 100,
            marginRight: 10,
        },
        boredApeMenuImage: {
            width: 40,
            height: 40,
            borderRadius: 100,
            marginRight: 20,
        },
        boredApeMenuImage_md: {
            top: 20,
            right: 20,
            width: 32,
            height: 32,
            marginRight: 10,
        },
        webappHeaderMenuContainer: {
            maxWidth: 515,
            paddingLeft: 15,
            paddingRight: 15,
            width: "100%",
            height: "calc(100vh - 72px)",
            backgroundColor: Colors.indigo,
            marginLeft: "auto",
            position: "fixed",
            right: 0,
            top: 72,
            zIndex: 1,
            overflowY: "scroll",
            filter: "drop-shadow(rgba(0, 0, 0, 0.5) -2px 0px 4px)",
        },
        walletIdContainer: {
            display: "flex",
            gap: 4,
            flexDirection: "column",
        },
        menuIconImage: {
            fontSize: 24,
            padding: "12px 14px",
            backgroundColor: Colors.magenta,
            borderRadius: 8,
            marginRight: 10,
        },
    }
}
