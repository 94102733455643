import React from 'react';

export default class H2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const pr = this.props;

        return (
            <h2 {...pr}>
                {pr.children}
            </h2>
        );
    }

    styles = {};
}
