import React, { useState } from 'react';
import * as Cookies from "js-cookie";

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import ll from '../modules/ll';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Validate from '../modules/Validate';

import Button from '../elements/Button';
import DebugBox from '../elements/DebugBox';
import Dropdown from '../elements/Dropdown';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Input from '../elements/Input';
import Textarea from '../elements/Textarea';
import FlashMessage from '../elements/FlashMessage';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import BorderSeparator from '../elements/BorderSeparator';
import ErrorBox from '../elements/ErrorBox';
import Spinner from '../elements/Spinner';

import SocialLinks from './SocialLinks';

import Div from './Div';

import imageUploadIcon from "../assets/images/image-upload.png";

export default class SocialLinksEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialLinksData: Utils.get(props, "user.social_link_json_data"),
            selectedLinkType: {
                value: "instagram",
                text: "Instagram",
            },
            isPendingLinkSave: false,
            successMessage: "",
            errorMessages: [],

            inputErrorMessages: [],

            isDirty: false,
        };

        this.defaultSocialLinks = [
            {
                title: "Instagram",
                type: "instagram",
                url: "https://www.instagram.com/",
            },
            {
                title: "Twitter",
                type: "twitter",
                url: "https://twitter.com/",
            },
            {
                title: "Tiktok",
                type: "tiktok",
                url: "https://www.tiktok.com/@",
            },
            {
                title: "Twitch",
                type: "twitch",
                url: "https://www.twitch.tv/",
            },
            {
                title: "YouTube",
                type: "youtube",
                url: "https://www.youtube.com/",
            },
            {
                title: "Website",
                type: "weblink",
                url: "https://",
            },
        ];

        this.defaultInputInfo = {
            instagram: {
                defaultUrl: "https://www.instagram.com/",
                icon: "instagram",
                defaultLength: 26,
            },
            twitter: {
                defaultUrl: "https://twitter.com/",
                icon: "twitter",
                defaultLength: 20,
            },
            tiktok: {
                defaultUrl: "https://www.tiktok.com/@",
                icon: "tiktok",
                defaultLength: 24,
            },
            twitch: {
                defaultUrl: "https://www.twitch.tv/",
                icon: "twitch",
                defaultLength: 22,
            },
            youtube: {
                defaultUrl: "https://www.youtube.com/",
                icon: "youtube",
                defaultLength: 24,
            },
            spotify: {
                defaultUrl: "https://",
                icon: "spotify",
                defaultLength: 8,
            },
            appleMusic: {
                defaultUrl: "https://",
                icon: "applemusic",
                defaultLength: 8,
            },
            discord: {
                defaultUrl: "https://discord.gg/",
                icon: "discord",
                defaultLength: 19,
            },
            patreon: {
                defaultUrl: "https://www.patreon.com/",
                icon: "patreon",
                defaultLength: 24,
            },
            onlyFans: {
                defaultUrl: "https://onlyfans.com/",
                icon: "onlyfans",
                defaultLength: 21,
            },
            weblink: {
                defaultUrl: "https://",
                icon: "link",
                defaultLength: 8,
            },
            custom: {
                defaultUrl: "https://",
                icon: "link",
                defaultLength: 8,
            },
        };

        this.socialLinkTypes = [
            {
                value: "instagram",
                text: "Instagram",
            },
            {
                value: "twitter",
                text: "Twitter",
            },
            {
                value: "tiktok",
                text: "Tiktok",
            },
            {
                value: "twitch",
                text: "Twitch",
            },
            {
                value: "youtube",
                text: "YouTube",
            },
            {
                value: "spotify",
                text: "Spotify",
            },
            {
                value: "appleMusic",
                text: "Apple Music",
            },
            {
                value: "discord",
                text: "Discord",
            },
            {
                value: "patreon",
                text: "Patreon",
            },
            {
                value: "onlyFans",
                text: "OnlyFans",
            },
            {
                value: "weblink",
                text: "Website",
            },
            {
                value: "custom",
                text: "Custom",
            },
        ];
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;

        let socialLinksData = Utils.deepCopy(Utils.get(pr, "user.social_link_json_data"), true);

        this.prepopulateSocialLinks(socialLinksData);

        window.addEventListener("beforeunload", this.handleWindowBeforeUnload.bind(this));
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let socialLinksData = Utils.deepCopy(Utils.get(newProps, "user.social_link_json_data"), true);

        this.prepopulateSocialLinks(socialLinksData);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload.bind(this));
    }

    handleWindowBeforeUnload(event) {
        const st = this.state;

        if(st.isDirty) {
            const message = "Are you sure you want to leave?";
            event.preventDefault();
            event.returnValue = message;
            return message;
        }
    }

    prepopulateSocialLinks(socialLinksData) {
        if(Utils.empty(socialLinksData)) {
            socialLinksData = Utils.deepCopy(this.defaultSocialLinks, true);
        }

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    socialLinkOnChange(socialLink, socialLinkIndex, defaultInputInfo, e) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            successMessage: "",
            isDirty: true,
        });

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);
        let val = e.target.value;

        const indexes = Utils.getIndexesOf("http", val);
        if(indexes.length >= 2) {
            val = val.slice(indexes[indexes.length - 1]);
        }

        if(val.indexOf(defaultInputInfo.defaultUrl) == -1) {
            socialLinksData[socialLinkIndex].url = defaultInputInfo.defaultUrl;
        } else {
            socialLinksData[socialLinkIndex].url = val;
        }

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    socialCustomLinkOnChange(socialLink, socialLinkIndex, defaultInputInfo, e) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            isDirty: true,
        });

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);
        const val = e.target.value;

        if(val.length > 10) {
            return;
        }

        socialLinksData[socialLinkIndex].title = val;

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    moveLinkUpDown(i, direction) {
        const pr = this.props;
        const st = this.state;

        if(i == 0 && direction == -1) {
            return;
        }

        if(i == st.socialLinksData.length - 1 && direction == 1) {
            return;
        }

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);

        const i2 = i + direction;
        [ socialLinksData[i], socialLinksData[i2] ] = [ socialLinksData[i2], socialLinksData[i] ];

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    deleteSocialLink(socialLinkIndex) {
        const pr = this.props;
        const st = this.state;

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);

        socialLinksData.splice(socialLinkIndex, 1);

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    addSocialLink() {
        const pr = this.props;
        const st = this.state;

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);

        if(socialLinksData.length >= 25) {
            return;
        }

        socialLinksData[socialLinksData.length] = {
            title: "Website",
            type: "weblink",
            url: "https://",
        };

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    onSelectLinkType(socialLinkIndex, selectedLinkType) {
        const pr = this.props;
        const st = this.state;

        let socialLinksData = Utils.deepCopy(st.socialLinksData, true);

        socialLinksData[socialLinkIndex].type = selectedLinkType.value;
        socialLinksData[socialLinkIndex].title = selectedLinkType.text;
        socialLinksData[socialLinkIndex].url = this.defaultInputInfo[selectedLinkType.value].defaultUrl;

        if(selectedLinkType.value == "custom") {
            socialLinksData[socialLinkIndex].title = "";
        }

        this.setState({
            socialLinksData: socialLinksData,
        });
    }

    saveSocialLinksData() {
        const pr = this.props;
        const st = this.state;

        let socialLinksData = Utils.deepCopy(st.socialLinksData, []);

        this.setState({
            isPendingLinkSave: true,
            successMessage: "",
            errorMessages: "",
            inputErrorMessages: [],
        });

        let inputErrorMessages = [];
        let noChange = true;
        for(var i = 0; i < st.socialLinksData.length; i++) {
            inputErrorMessages[i] = "";
            let defaultLength = this.defaultInputInfo[socialLinksData[i].type].defaultLength;
            socialLinksData[i].defaultLength = defaultLength;

            if(socialLinksData[i].url.length != defaultLength) {
                noChange = false;
            }

            if(st.socialLinksData[i].url.includes(" ")) {
                inputErrorMessages[i] = "This link is invalid.  Please re-enter the link and try again.";
                this.setState({
                    inputErrorMessages: inputErrorMessages,
                    errorMessages: [{message: "One or more links are incorrectly formatted"}],
                    isPendingLinkSave: false,
                });
                return;
            }
        }

        if(noChange) {
            this.setState({
                isPendingLinkSave: false,
                successMessage: "",
                errorMessages: [],
                inputErrorMessages: [],
            });
            return;
        }

        let profileData = {
            params: {
                user: {
                    social_link_json: JSON.stringify(socialLinksData),
                },
            },
        };

        Api.putProfile(profileData, (res) => {
            if(Utils.get(res, "success")) {
                pr.setUser(res);

                this.setState({
                    successMessage: "Saved Links!",
                    errorMessages: [],
                    inputErrorMessages: [],
                    isDirty: false,
                });
            } else {
                this.setState({
                    successMessage: "",
                    errorMessages: Utils.get(res, "errors"),
                    inputErrorMessages: [],
                });
            }

            this.setState({
                isPendingLinkSave: false,
            });
        });
    }

    renderSocialLinks() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(Utils.empty(st.socialLinksData)) {
            if(!pr.userLoaded) {
                return (
                    <Div className="loadingState">
                        Loading...
                    </Div>
                );
            } else {
                return (
                    <Div className="defaultSocialLinks">
                        {this.defaultSocialLinks.map((socialLink, socialLinkIndex) => {
                            return (
                                this.renderSocialLink(socialLink, socialLinkIndex)
                            );
                        })}
                    </Div>
                );
            }
        }

        return (
            <Div className="socialLinksContainer">
                {st.socialLinksData.map((socialLink, socialLinkIndex) => {
                    return (
                        this.renderSocialLink(socialLink, socialLinkIndex)
                    );
                })}
            </Div>
        )
    }

    renderSocialLink(socialLink, socialLinkIndex) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const defaultInputInfo = this.defaultInputInfo[socialLink.type];

        let chevronExtraUpStyle = {};
        let chevronExtraDownStyle = {};
        if(socialLinkIndex == 0) {
            chevronExtraUpStyle.opacity = 0.5;
            chevronExtraDownStyle = StylesPlatform.cursorPointer;
        } else if(socialLinkIndex == st.socialLinksData.length - 1) {
            chevronExtraUpStyle = StylesPlatform.cursorPointer;
            chevronExtraDownStyle.opacity = 0.5;
        } else {
            chevronExtraUpStyle = StylesPlatform.cursorPointer;
            chevronExtraDownStyle = StylesPlatform.cursorPointer;
        }


        const socialLinkContainer = StyleUtils.getMStyle(sty, "socialLinkContainer", pr.screen.width, "md");
        const linkInfo = StyleUtils.getMStyle(sty, "linkInfo", pr.screen.width, "md");

        return (
            <>
                <Div
                    className="socialLinkContainer"
                    key={`socialLinkIndex-${socialLinkIndex}`}
                    style={socialLinkContainer}
                >
                    <Div className="linkInfo" style={linkInfo}>
                        <Icon
                            icon="settings-delete"
                            size={19}
                            style={{...sty.deleteLinkButton, ...StylesPlatform.cursorPointer}}
                            onClick={this.deleteSocialLink.bind(this, socialLinkIndex)}
                        />

                        <Dropdown
                            style={{marginRight: 70}}
                            selectStyle={{minWidth: 156}}
                            selected={{value: socialLink.type}}
                            onSelectOption={this.onSelectLinkType.bind(this, socialLinkIndex)}
                            options={this.socialLinkTypes}
                            color="indigo"
                        />

                        <Icon
                            icon="ap-chevron-up"
                            size={12}
                            style={{...sty.chevronUpStyle, ...chevronExtraUpStyle}}
                            onClick={this.moveLinkUpDown.bind(this, socialLinkIndex, -1)}
                        />

                        <Icon
                            icon="ap-chevron-down"
                            size={12}
                            style={{...sty.chevronDownStyle, ...chevronExtraDownStyle}}
                            onClick={this.moveLinkUpDown.bind(this, socialLinkIndex, 1)}
                        />
                    </Div>

                    <Div className="linkInput" style={sty.linkInput}>
                        {socialLink.type != "custom" ?
                            <Input
                                style={sty.socialLinkInput}
                                inputType="icon"
                                inputIcon={defaultInputInfo.icon}
                                inputIconSize={18}
                                value={socialLink.url}
                                onChange={this.socialLinkOnChange.bind(this, socialLink, socialLinkIndex, defaultInputInfo)}
                            />
                            :
                            <Div className="customInputContainer" style={sty.customInputContainer}>
                                <Input
                                    style={sty.socialLinkInput}
                                    inputIconSize={18}
                                    value={socialLink.title}
                                    onChange={this.socialCustomLinkOnChange.bind(this, socialLink, socialLinkIndex, defaultInputInfo)}
                                    placeholder="Title"
                                />
                                <Input
                                    style={sty.socialLinkInput}
                                    inputType="icon"
                                    inputIcon={defaultInputInfo.icon}
                                    inputIconSize={18}
                                    value={socialLink.url}
                                    onChange={this.socialLinkOnChange.bind(this, socialLink, socialLinkIndex, defaultInputInfo)}
                                />
                            </Div>
                        }
                        <Div className="inputErrorMessage" style={sty.inputErrorMessage}>
                            {st.inputErrorMessages[socialLinkIndex]}
                        </Div>
                    </Div>
                </Div>
                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
            </>
        )
    }

    renderSaveSuccessMessage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="successMessage" style={sty.successMessage}>
                {st.successMessage}
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const buttonLinkRow = StyleUtils.getMStyle(sty, "buttonLinkRow", pr.screen.width, "md");

        return (
            <Div className="socialLinksComponent" style={{paddingBottom: 20}}>
                {this.renderSocialLinks()}

                <Div className="buttonLinkRow" style={buttonLinkRow}>
                    {pr.screen.mobile ? null : this.renderSaveSuccessMessage()}
                    <Button
                        type="outlined"
                        onClick={this.addSocialLink.bind(this)}
                    >
                        Add Another Link
                    </Button>
                    <Button
                        color="pinkGradient"
                        onClick={this.saveSocialLinksData.bind(this)}
                    >
                        {st.isPendingLinkSave ?
                            "Saving..."
                            :
                            "Save"
                        }
                    </Button>
                    {pr.screen.mobile ? this.renderSaveSuccessMessage() : null}
                </Div>

                {/* NOTE: leave for testing */}
                {/* <SocialLinks
                    artistCustomer={pr.user}
                    screen={pr.screen}
                    type="fixed"
                /> */}

                <ErrorBox
                    errorMessages={st.errorMessages}
                    errorMessageStyle={{justifyContent: pr.screen.mobile ? "center" : "flex-end"}}
                />
            </Div>
        );
    }

    styles = {
        socialLinksComponent: {
            // display: "flex",
            // alignItems: "center",
        },
        socialLinkContainer: {
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
        },
        socialLinkContainer_md: {
            flexDirection: "column",
        },
        linkInfo: {
            display: "flex",
            alignItems: "center",
        },
        linkInfo_md: {
            marginBottom: 8,
        },
        linkInput: {
            display: "flex",
            // alignItems: "center",
            width: "100%",
            flexDirection: "column",
        },
        socialLinkInput: {
            marginBottom: 0,
            maxWidth: 512,
        },
        deleteLinkButton: {
            padding: 10,
        },
        chevronUpStyle: {
            paddingRight: 11,
            paddingLeft: 16,
        },
        chevronDownStyle: {
            paddingRight: 16,
            paddingLeft: 11,
        },

        buttonLinkRow: {
            display: "flex",
            gap: 20,
            paddingTop: 20,
            paddingBottom: 20,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        buttonLinkRow_md: {
            flexDirection: "column",
            alignItems: "center",
        },
        customInputContainer: {
            display: "flex",
            gap: 10,
            width: "100%",
            maxWidth: 512,
        },
        successMessage: {
            color: Colors.neonGreen,
        },

        inputErrorMessage: {
            color: Colors.redError,
            marginTop: 6,
            fontSize: 14,
        },
    };

}
