import React, { useState, useEffect, useCallback } from 'react';
import { render } from 'react-dom';

import Utils from '../modules/Utils';
import StylesPlatform from '../modules/StylesPlatform';
import Api from '../modules/Api';

import axios from 'axios';

/**
 * Stripe v3
 */
import { loadStripe } from '@stripe/stripe-js';

import {
    PaymentRequestButtonElement,
    CardElement as CardElementV3,
    Elements as ElementsV3,
    useStripe,
    useElements,
    PaymentElement,
} from '@stripe/react-stripe-js';

/**
 * Stripe v3 END
 */

import Input from './Input';
import Button from './Button';
import Checkbox from './Checkbox';
import Div from './Div';

import Colors from '../modules/Colors';

import './StripePayment.css'

/**
 * Stripe v3
 */
const StripeV3 = (props) => {
    const [stripePromise, setStripePromise] = useState(null);
    const [newClientSecret, setNewClientSecret] = useState('');
    const { 
        stripeApiKey: publishable_key, 
        elementType, 
        clientSecret,         
        stripeInfo: { 
            savePaymentMethod,
            quantity,
            amount,
            collectable: collectableId,
            paymentType,
            roomId,
            stripeOrderId,
        },
        secondPaymentIntent,
    } = props
    const [savePaymetMethodChecked, setSavePaymetMethodChecked] = useState(savePaymentMethod);

    const loadStripePromise = async (publishable_key) => {
        // Make sure to call `loadStripe` outside of a component's render to avoid
        // recreating the `Stripe` object on every render.
        setStripePromise(await loadStripe(publishable_key))
    };

    useEffect(() => {
        // this should load when the key is available
        loadStripePromise(publishable_key);
    }, [])

    if (stripePromise) {
        if (elementType === "card") {
            return (
                <ElementsV3 stripe={stripePromise}>
                    <CheckoutFormCard {...props} />
                </ElementsV3>
            );
        }

        if (elementType === "payment-element") {
            const appearance = {
                theme: 'stripe',
            };
            const options = {
                clientSecret,
                appearance,
            };
            return (
                <ElementsV3 options={options} stripe={stripePromise}>
                    <PaymentElementForm {...props} />
                </ElementsV3>
            );
        }

        if (elementType === "payment-elementV4") {
            const updatedProps = {...props}
            updatedProps['setNewClientSecret'] = setNewClientSecret
            updatedProps['savePaymetMethodChecked'] = savePaymetMethodChecked
            updatedProps['setSavePaymetMethodChecked'] = setSavePaymetMethodChecked
            const appearance = {
                // theme: 'stripe',
                theme: 'night',
                variables: {
                    colorBackground: "#0f0420"
                }
            };

            if (savePaymetMethodChecked) {
                const options = {
                    clientSecret,
                    // clientSecret: "pi_3NVxciJwtuvY8RLr0EZS00wT_secret_d94TdTJDwqFkVxzitNAzD8pkj",
                    // clientSecret: newClientSecret,
                    appearance,
                };
                return (
                    <ElementsV3 key={1} options={options} stripe={stripePromise}>
                        <PaymentElementFormV4 {...updatedProps} />
                    </ElementsV3>
                );
            } else {
                // user does not want to save payment method so use new payment intent client secret
                if (!secondPaymentIntent) {
                    // error and cannot pay without saving payment method. e.g. unchecked "save payment method"
                    // TODO: consider error to user and third party with more details 
                    console.log('secondPaymentIntent>no', secondPaymentIntent)
                }
                const { secret: secondClientSecret } = secondPaymentIntent;
                const options = {
                    clientSecret: secondClientSecret,
                    appearance,
                };
                return (
                    // key prop is important so it distinguishes from the one above and re-renders the Payment Elemnt
                    // when the new client secret is avaialble,
                    // which will control if the user payment method gets stored or not.
                    <ElementsV3 key={2} options={options} stripe={stripePromise}>
                        <PaymentElementFormV4 {...updatedProps} />
                    </ElementsV3>
                );
            }
        }

        if (elementType === "setup-intent") {
            const updatedProps = {...props}
            updatedProps['savePaymetMethodChecked'] = savePaymetMethodChecked
            const appearance = {
                // theme: 'stripe',
                theme: 'night',
                variables: {
                    colorBackground: "#0f0420"
                }
            };

            const options = {
                clientSecret,
                appearance,
            };

            return (
                <ElementsV3 key={1} options={options} stripe={stripePromise}>
                    <PaymentElementFormV4 {...updatedProps} />
                </ElementsV3>
            );
        }

        return (
            <ElementsV3 stripe={stripePromise}>
                <CheckoutForm {...props} />
            </ElementsV3>
        );
    } else {
        return "Loading..."
    }
};

const sendStripeResponse = async (props, paymentIntent) => {
    const data = {
        paymentIntent,
        collectibleId: props.collectableId,
        quantity: props.quantity,
        paymentType: props.paymentType,
        roomId: props.roomId,
        stripeOrderId: paymentIntent.id
    }
    
    Api.storeStripeTransaction(data, (o) => {
        if (o && o.success) {
            props.paymentSuccess(paymentIntent)
        } else if (props.setMessage) {
            props.setMessage('There was a problem storing transaction')
        }
    });
}

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [message, setMessage] = useState('');

    const {
        clientSecret,
        stripeInfo: { amount, testMode, stripeCustomerId, paymentType },
        // see comment below when time comes to enabling it again here
        // stripeV1Component: StripeV1Component,
    } = props

    const title = Utils.get(props, 'selectedItem.title', 'Test')
    const finalTitle = testMode ? `TestMode: ${title}` : title

    const handleStripeError = (error) => {
        // console.log(`Payment error: ${JSON.stringify(error)}`)
        if (error.message) {
            setMessage(`${error.message}`)
        } else {
            setMessage("Something went wrong with your transaction. Please try another payment method.")
        }
    }

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: finalTitle,
                    amount,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    // e.g. pr.buttonTypeName (e.g. GOOGLE_PAY) 
                    setPaymentRequest(pr);
                }
            });

            pr.on('paymentmethod', async (ev) => {
                // ev contains the walletName (e.g. googlePay) or methodName (e.g. google-pay) and other data from stripe and the card
                const walletType = Utils.get(ev, "paymentMethod.card.wallet.type")

                // payment intent before or now - Done Before on the Post.js upon paypal modal
                // Confirm the PaymentIntent without handling potential next actions (yet).
                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                    clientSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false }
                );

                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    ev.complete('fail');
                    handleStripeError(confirmError)
                } else {
                    const updatedPaymentIntent = { ...paymentIntent, walletType, stripeCustomerId }
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and if so let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11"
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === "requires_action") {
                        // Let Stripe.js handle the rest of the payment flow.
                        // TODO: look into these actions further and how to test for them
                        const { error } = await stripe.confirmCardPayment(clientSecret);
                        if (error) {
                            // The payment failed -- ask your customer for a new payment method.
                            handleStripeError(error)
                        } else {
                            // The payment has succeeded.
                            await sendStripeResponse({ ...props, setMessage }, updatedPaymentIntent)
                        }
                    } else {
                        // The payment has succeeded.
                        await sendStripeResponse({ ...props, setMessage }, updatedPaymentIntent)
                    }
                }
            });
        }
    }, [stripe]);

    const options = {
        paymentRequest,
        // see https://stripe.com/docs/stripe-js/elements/payment-request-button?client=react#react-styling-the-element
        style: {
            paymentRequestButton: {
                type: 'default',
                // One of 'default', 'book', 'buy', or 'donate'
                // Defaults to 'default'

                theme: 'dark',
                // One of 'dark', 'light', or 'light-outline'
                // Defaults to 'dark'

                height: '55px',// matches paypal buttons
                // Defaults to '40px'. The width is always '100%'.
            },
        }
    }


    const WalletElementv3 = () => {
        return (
            <Div>
                {paymentRequest && <PaymentRequestButtonElement options={options} />}
                <div>{message}</div>
            </Div>
        )
    }

    /**
     * Below commened code is still the way we should go. Reasons its commented:
     * - Due to the lack of automatic currency conversion from stripe on our existing element
     * we defaulted to using V1 for non US users.
     * - see the implementation below using CountrySelection until we get this updated
     * - then we can bring this commented implementation back.
     * Note: if V1 gets deleted this can also be updated.
     */
    // if (paymentRequest) {
    //   return (
    //     <Div>
    //       {paymentRequest && <PaymentRequestButtonElement options={options} />}
    //       <div>{message}</div>
    //     </Div>
    //   )
    // }

    // Use a traditional checkout form as backup
    // if (StripeV1Component) {
    //   return StripeV1Component
    // } else {
    //   // If we want a customer message if the stripe wallet buttons are not enabled(no card in their wallet) by the user do so here
    //   // return 'Please connect Apple Pay or Google Pay to enable';
    //   return null
    // }

    return <WalletElementv3 />
}

const CARD_ELEMENT_OPTIONS = {
    // see https://stripe.com/docs/js/appendix/style?type=card
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: Colors.white,
            // defaults from stripe @see https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements#add-and-configure-a-component
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const CardSection = () => {
    return (
        <>
            <label style={{ color: Colors.white }}>Enter Card Details</label>
            <Div style={{ margin: "14px 0" }}>
                <CardElementV3 options={CARD_ELEMENT_OPTIONS} />
            </Div>
        </>
    );
};

const CheckoutFormCard = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('init');
    const [disableButton, setDisableButton] = useState(false);
    const {
        clientSecret,
        stripeInfo: { paymentMethodId, cardDetails, testMode, stripeCustomerId },
    } = props
    let cardPaymentResult = {}

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();
        setDisableButton(!disableButton)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // process payment if existing paymentMethod exists
        if (paymentMethodId) {
            // craft the confirm card payment with just the payment method
            cardPaymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodId,
            });
        } else {
            // craft default to store payment method for future use
            cardPaymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElementV3),
                },
                // stores payment method to customer by stripe
                setup_future_usage: 'off_session'
            });
        }

        const { paymentIntent, error: stripeError } = cardPaymentResult;

        if (stripeError) {
            // Show error to your customer (for example, insufficient funds)
            setMessage('There was an error processing payment. Try again.')

            // ux: reset state to allow user to retry
            window.setTimeout(() => {
                setMessage('')
                setDisableButton(false)
                // not failed state to save on a state change. This resets back
                // remove and change to failed if we want a customer ux failed state
                setPaymentStatus('init')
            }, 3000);
        } else {
            const updatedPaymentIntent = { ...paymentIntent, stripeCustomerId }
            // The payment has been processed!
            if (paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.

                // for UX
                setPaymentStatus('processing')
                setMessage('Payment is processing...')

                // ux: wait a 2 sec before completion
                // needed to force processing since stripe is pretty quick to respond
                window.setTimeout(async () => {
                    setMessage('Payment has succeeded!')
                    setPaymentStatus('success')

                    // store and show success modal
                    await sendStripeResponse({ ...props, setMessage }, updatedPaymentIntent)
                }, 2000);
            }
        }
    };

    const amount = Utils.get(props.stripeInfo, "amount") ? props.stripeInfo.amount / 100 : 0;

    const styles = {
        errorContainer: {
            background: '#ffffe0',
            padding: 12,
            margin: 12
        },
        entryContainer: {
            // slightly lighter version of #271d37 which is the background of the modal
            // consider moving this to a css variable to reduce future changes
            background: "#332746",
            borderRadius: 16,
            padding: 16,
            // margin: "20px 0 20px 0",
            margin: 0,
        },
        paymentStatusStyle: {
            color: "white",
        },
    };

    const paymentDetails = `Pay with: ${cardDetails.brand || 'card'} ending in ${cardDetails.last4}`
    const finalTitle = testMode ? `TestMode: ${paymentDetails}` : paymentDetails

    return (
        <div className="stripeCheckoutFormCardComponent" style={styles.entryContainer}>
            {paymentStatus === 'init' && !paymentMethodId && (
                <CardSection />
            )}
            {paymentStatus === 'init' && cardDetails && (
                <Div style={{ color: Colors.white }}>{finalTitle}</Div>
            )}
            {paymentStatus === 'init' && (
                <Button
                    disabled={disableButton}
                    style={{ marginTop: 20, width: "100%" }}
                    onClick={handleSubmit}
                >
                    Pay ${Utils.commas(amount)} With Card
                </Button>
            )}
            <div style={styles.paymentStatusStyle}>{message}</div>
        </div>
    );
}

const PaymentElementForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('init');
    const [disableButton, setDisableButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        stripeInfo: { 
            paymentType,
        },
    } = props
    let cardPaymentResult = {}
    /**
     * Reflects where the user used this component to purchase
     * e.g. collectable, artist profile, liveroom, etc
     * includes query params, such as payment_intent if used directly on the return_url
     * note: paymentType param will be removed on load from RoomV3 and Post when implemented
     */
    const url = new URL(window.location.href);
    // add new param
    url.searchParams.append('paymentType', paymentType)
    const returnUrl = url.href

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        // setDisableButton(!disableButton)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        setDisableButton(!disableButton)

        /**
         * Payment Element currently does not support an existing payment method. If we want to then may have to 
         * @see https://stripe.com/docs/payments/cash-app-pay#payment-method-cloning
         */
        cardPaymentResult = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // returns user back to original page of purchase
                return_url: returnUrl,
            },
            redirect: 'if_required',
        });

        // console.log("NONREDIRECT", cardPaymentResult)

        const {paymentIntent, error: stripeError} = cardPaymentResult;

        if (stripeError) {
            // Show error to your customer (for example, insufficient funds)
            // console.log('stripe error message: ', stripeError.message);
            setMessage('There was an error processing payment. Try again.')

            // ux: reset state to allow user to retry
            window.setTimeout(() => {
                setMessage('')
                setDisableButton(false)
                // not failed state to save on a state change. This resets back
                // remove and change to failed if we want a customer ux failed state
                setPaymentStatus('init')
            }, 3000);
            setIsLoading(false);
        } else {
          // The payment has been processed!
            if (paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical -> we have this now
                // post-payment actions.

                // for UX
                setPaymentStatus('processing')
                setMessage('Payment is processing...')

                // ux: wait a 3 sec before completion
                // needed to force processing since stripe is pretty quick to respond
                window.setTimeout(async () => {
                    setMessage('Payment has succeeded!')
                    // close modal and open success modal
                    props.paymentSuccess(paymentIntent)
                    setPaymentStatus('success')
                    setIsLoading(false);
                }, 3000)
            } else {
                /**
                 * Some payment methods require additional actions.
                 * Its recommended to let Stripe handle these automatically
                 * so these are set here to allow the user to continue their purchase just in case the Stripe UI returns actions that require a redirect out of the page
                 */
                setMessage('Follow the instructions for your selected payment method or select another. Thanks.')
                window.setTimeout(async () => {
                    setMessage('')
                    setDisableButton(false);
                    setIsLoading(false);
                    /**
                     * close purchase modal to improve UX and require user to click a new purchase
                     *  which creates a new payment intent and avoid errors
                     */
                    if (props.onRequestClose) {
                        props.onRequestClose()
                    }
                }, 3000)
            }
        };
    }

    const styles = {
        entryContainer: {
          // slightly lighter version of #271d37 which is the background of the modal
          // consider moving this to a css variable to reduce future changes
          background: "#332746",
          borderRadius: 16,
          padding: "20px",
        //   margin: "20px 0",
          margin: 0,
        },
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    // taken from StripeV1SingleButton
    const sty = {
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            flexShrink: 0,
            fontWeight: "bold",
            width: "fit-content",
            fontWeight: 400,
            fontFamily: "Nyata",
        },
        pinkButton: {
            backgroundColor: Colors.magenta,
            color: "white",
        },
        disabled: {
            backgroundColor: "#aaa",
            color: "#555",
        },
        defaultButton: {
            padding: "15px 20px 14px 20px",
            borderRadius: 100,
            fontSize: 16,
            minWidth: 180,
        },
    }
    const buttonColors = sty.pinkButton;
    const buttonSize = sty.defaultButton;
    let disabledStyle = disableButton ? sty.disabled : null;
    let buttonStyles = { ...sty.button, ...StylesPlatform.cursorPointer, ...buttonColors, ...buttonSize, ...disabledStyle };

    return (
        <form id="payment-element-form" className="stripePaymentElementFormComponent" onSubmit={handleSubmit} style={styles.entryContainer}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            {/* TODO: consolidate styles with StripeV1SingleButton, maybe into a new special component */}
            <Div style={{ marginTop: 20, width: "100%" }}>
                <button style={{ ...buttonStyles, width: "100%" }} type="submit">
                    {/* Pay now */}
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                    </span>
                </button>
            </Div>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

const PaymentElementFormV4 = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const {
        stripeInfo: { 
            paymentType,
            computedAmount,
        },
        savePaymetMethodChecked,
        setSavePaymetMethodChecked,
        type,
        stripeGtmDataHandler,
        metadata,
    } = props
    const isSetup = type === "setup"
    let defaultButtonText = isSetup ? "Add Payment Method" : `Complete Payment – $${Utils.formatMoney(computedAmount)}`

    const [message, setMessage] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('init');
    const [disableButton, setDisableButton] = useState(false);
    const [buttonText, setButtonText] = useState(defaultButtonText);
    const [isLoading, setIsLoading] = useState(false);
    let cardPaymentResult = {}
    /**
     * Reflects where the user used this component to purchase
     * e.g. collectable, artist profile, liveroom, etc
     * includes query params, such as payment_intent if used directly on the return_url
     * note: paymentType param will be removed on load from RoomV3 and Post when implemented
     */
    const url = new URL(window.location.href);
    // add new param
    url.searchParams.append('paymentType', paymentType)
    // important for liveroom tips sucesss to work. See RoomV3 Utils.getRequest('paymentType') === 'tipPayment')
    url.searchParams.append('computedAmount', computedAmount)
    const returnUrl = url.href


    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        // setDisableButton(!disableButton)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        setDisableButton(!disableButton)

        if (isSetup) {
            const { error, setupIntent } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
                redirect: 'if_required',
              });
          
              if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setMessage(error.message || 'There was an error adding payment method. Try again.')
                window.setTimeout(() => {
                    setMessage('')
                    setDisableButton(false)
                    // not failed state to save on a state change. This resets back
                    // remove and change to failed if we want a customer ux failed state
                    setPaymentStatus('init')
                }, 3000);
                setIsLoading(false);
              } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                setMessage('Processing...')
                // ux: wait a 3 sec before completion
                // needed to force processing since stripe is pretty quick to respond
                window.setTimeout(async () => {
                    setMessage('Payment Method added!')
                    // handled post-success -> close modal and open success modal
                    props.paymentSuccess(setupIntent)
                    setPaymentStatus('success')
                    setIsLoading(false);
                }, 3000)
              }
            return
        }

        /**
         * Payment Element currently does not support an existing payment method. If we want to then may have to 
         * @see https://stripe.com/docs/payments/cash-app-pay#payment-method-cloning
         */
        cardPaymentResult = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // returns user back to original page of purchase
                return_url: returnUrl,
            },
            redirect: 'if_required',
        });

        const {paymentIntent, error: stripeError} = cardPaymentResult;

        if (stripeError) {
            // Show error to your customer (for example, insufficient funds)
            // console.log('stripe error message: ', stripeError.message);
            setMessage(stripeError.message || 'There was an error processing payment. Try again.')

            // ux: reset state to allow user to retry
            window.setTimeout(() => {
                setMessage('')
                setDisableButton(false)
                // not failed state to save on a state change. This resets back
                // remove and change to failed if we want a customer ux failed state
                setPaymentStatus('init')
            }, 3000);
            setIsLoading(false);
        } else {
          // The payment has been processed!
            if (paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical -> we have this now
                // post-payment actions.

                // for UX
                setPaymentStatus('processing')
                setMessage('Payment is processing...')

                // ux: wait a 3 sec before completion
                // needed to force processing since stripe is pretty quick to respond
                window.setTimeout(async () => {
                    setMessage('Payment has succeeded!')
                    stripeGtmDataHandler({
                        stripeResponse: paymentIntent, 
                        initalPaymentIntent: props.stripeInfo, 
                        metadata
                    })
                    // close modal and open success modal
                    props.paymentSuccess(paymentIntent)
                    setPaymentStatus('success')
                    setIsLoading(false);
                }, 3000)
            } else {
                /**
                 * Some payment methods require additional actions.
                 * Its recommended to let Stripe handle these automatically
                 * so these are set here to allow the user to continue their purchase just in case the Stripe UI returns actions that require a redirect out of the page
                 */
                setMessage('Follow the instructions for your selected payment method or select another. Thanks.')
                window.setTimeout(async () => {
                    setMessage('')
                    setDisableButton(false);
                    setIsLoading(false);
                    /**
                     * close purchase modal to improve UX and require user to click a new purchase
                     *  which creates a new payment intent and avoid errors
                     */
                    if (props.onRequestClose) {
                        props.onRequestClose()
                    }
                }, 3000)
            }
        };
    }

    const styles = {
        entryContainer: {
          // slightly lighter version of #271d37 which is the background of the modal
          // consider moving this to a css variable to reduce future changes
          background: "#332746",
          borderRadius: 16,
          padding: 16,
        //   margin: "20px 0",
          margin: 0,
        },
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    // taken from StripeV1SingleButton
    const sty = {
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            flexShrink: 0,
            fontWeight: "bold",
            width: "fit-content",
            fontWeight: 400,
            fontFamily: "Nyata",
        },
        pinkButton: {
            backgroundColor: Colors.magenta,
            color: "white",
        },
        disabled: {
            backgroundColor: "#aaa",
            color: "#555",
        },
        defaultButton: {
            padding: "15px 20px 14px 20px",
            borderRadius: 100,
            fontSize: 16,
            minWidth: 180,
        },
        pinkGradient: {
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)", 
            color: "white",
        }
    }
    const buttonColors = sty.pinkGradient;
    const buttonSize = sty.defaultButton;
    let disabledStyle = disableButton ? sty.disabled : null;
    let buttonStyles = { ...sty.button, ...StylesPlatform.cursorPointer, ...buttonColors, ...buttonSize, ...disabledStyle };

    const toggleSavePaymentMethod = () => {
        setSavePaymetMethodChecked(!savePaymetMethodChecked)
    }

    const disableCheckboxIfSetup = isSetup ? {pointerEvents:'none'} : {}
    
    /**
     * Changes to Payment Element
     * - React component handles methods found in doc as props
     * @see https://stripe.com/docs/js/element/events for other events and details
     * @param {StripeExpressCheckoutElementClickEvent} event 
     * 
     * e.g.
     * ```
     * {
     *     "elementType": "payment",
     *     "collapsed": false,
     *     "empty": true,
     *     "complete": false,
     *     "value": {
     *         "type": "card"
     *     }
     * }
     * ```
     */
    const handlePaymentElementOnChange = (event) => {
        const type = Utils.get(event, 'value.type')
        if (type !== "card") {
            setButtonText("Proceed")
            return
        }
        // setButtonText(`${defaultButtonText} ${Utils.formatMoney(Utils.get(paymentIntent, "computedAmount"))}`)
        setButtonText(defaultButtonText)
    }

    return (
        <form id="payment-element-form" className="stripePaymentElementFormV4Component" onSubmit={handleSubmit} style={styles.entryContainer}>
            <PaymentElement 
                id="payment-element" 
                options={paymentElementOptions} 
                onChange={handlePaymentElementOnChange}
            />
            <Div className="agreeToTermsContainer" style={{
                display: 'flex',
                fontSize: '18px',
                margin: '20px 0'
            }}>
                <Checkbox
                    color="white"
                    checked={savePaymetMethodChecked}
                    style={{marginLeft: 10, marginRight: 10, ...disableCheckboxIfSetup}}
                    onClick={toggleSavePaymentMethod}
                />
                <Div style={{display: "flex"}}>
                    Save Payment Method
                </Div>
            </Div>
            <Div style={{ marginTop: 20, width: "100%" }}>
                <button style={{ ...buttonStyles, width: "100%" }} type="submit">
                    {/* Pay now */}
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : buttonText}
                    </span>
                </button>
            </Div>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

/**
 * Stripe v3 END
 */

export default class StripePayment extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            billing_name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            clientSecret: '',
            billingAddressSame: true,
            cc_num: '',
            cc_exp: '',
            cc_cvc: '',
            stripeObj: null,
            stripePaymentSuccess: false,
            noOrder: false,
            titleText: this.props.titleText ? this.props.titleText : "Enter your card information:",
            parentCountry: "US",
        };
        if (props.stripeMode != 'sqlite' && props.stripeInfo) {
            this.state['stripeObj'] = window.Stripe(props.stripeInfo.apikey);
            this.state['cc_num'] = 'stripe_mode: ' + props.stripeInfo.testMode;
        }
        if (Utils.getRequest("noorder")) {
            this.state['noOrder'] = true;
        }
    }

    componentDidMount() {
        this.handleScroll();
    }

    handleScroll() {
        const { index, selected } = this.props;
        const _self = this;
        if (index === selected && _self.childDiv) {
            setTimeout(() => {
                // console.log('childDiv', _self.childDiv);
                if (_self.childDiv.current) {
                    _self.childDiv.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        }
    }

    componentWillReceiveProps(newProps) {
        let tempState = {};
        if (
            newProps.stripeMode != 'sqlite' &&
            newProps.stripeInfo &&
            newProps.stripeInfo.api &&
            !this.state.stripeObj
        ) {
            // console.log('Set stripe obj', newProps);
            tempState['stripeObj'] = window.Stripe(newProps.stripeInfo.api.key);
        }
        this.setState(tempState);
    }

    handleContinue() {
        const params = {
            user: {
                billing_name: this.state.billing_name,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                cc_num: this.state.cc_num,
                cc_exp: this.state.cc_exp,
                cc_cvc: this.state.cc_cvc,
                noOrder: this.state.noOrder,
            }
        };
        axios.put(`/api/v1/profile`, { params }).then(res => {
            // console.log('RETURNED', res);
            // console.log(res.data);
            if (res.data.success) {
            }
        });
        this.props.changeScreen(4);
    }

    handleBillingCheckChange(val) {
        this.setState({ billingAddressSame: val });
    }

    changeCCNum(event) {
        this.setState({ cc_num: event.target.value });
    }
    changeCCExp(event) {
        this.setState({ cc_exp: event.target.value });
    }
    changeCCCvc(event) {
        this.setState({ cc_cvc: event.target.value });
    }
    changeBillingName(event) {
        this.setState({ billing_name: event.target.value });
    }
    changeBillingAddress(event) {
        this.setState({ address: event.target.value });
    }
    changeBillingCity(event) {
        this.setState({ city: event.target.value });
    }
    changeBillingState(event) {
        this.setState({ state: event.target.value });
    }
    changeBillingZip(event) {
        this.setState({ zip: event.target.value });
    }
    setParentCountry(country) {
        this.setState({ parentCountry: country });
    }
    paymentSuccess(paymentIntent) {
        console.log('paymentSuccess!!!!!!!!!!!!!!!!');
        if (paymentIntent) {
            // follows from sendStripeResponse
            if (this.props.onRequestClose) {
                this.props.onRequestClose()
            }
            if (this.props.stripePaymentCallback) {
                console.log('stripePaymentCallback!!!!!!!!!!!!!!!!');
                this.props.stripePaymentCallback(paymentIntent)
            }
        }
        if (this.props.handlePaymentSuccess) {
            this.props.handlePaymentSuccess();
        } else {
            this.setState({ stripePaymentSuccess: true });
        }
    }

    /**
     * Versions have to do with:
     * - our implementation history
     * - stripe
     *
     * So the newest Stripe.js is v3 and coincidentaly ours is also v3.
     * This could change in favor of our versioning.
     *
     * For example, if we decide to create a V3.2 or V4 implementation but
     * does not map to Stripe then this method accounts for that.
     */
    renderStripeVersion(version, stripeApiKey) {
        /**
         * Defaults to the wallet buttons
         */
        const StripeV3Element = (props) => {
            return (
                <StripeV3
                    clientSecret={this.props.stripeInfo.secret}
                    stripeApiKey={stripeApiKey}
                    paymentSuccess={this.paymentSuccess.bind(this)}
                    {...props}
                />
            )
        }
        switch (version) {
            // TODO: There is a bug in android only that seems to re-render when using this function call with nested elements below.
            case "V3":
                return (
                    <>
                        <StripeV3Element {...this.props} />
                        <StripeV3Element {...this.props} elementType="card" />
                    </>
                )
            case "V3_WALLET":
                return <StripeV3Element {...this.props} />
            case "V3_CARD":
                return <StripeV3Element {...this.props} elementType="card" />
            case "V2":
                return null
            case "V1":
            default:
                return <StripeV1 {...this.props} />
        }
    }

    render() {
        const pr = this.props;
        const { stripeV1Component: StripeV1Component, version } = pr

        let stripeApiKey, stripeTest;
        //console.log('PLANDATA', this.props.planData);
        if (this.props.stripeMode == 'sqlite') {
            // Do nothing
            stripeTest = ' (CC) ';
        } else if (
            this.props.stripeMode == 'test' ||
            this.props.stripeMode == 'prod'
        ) {
            stripeApiKey = this.props.stripeInfo
                ? this.props.stripeInfo.apikey
                : null;
            //console.log('stripeApiKey', stripeApiKey);
            //console.log('Secret', this.props.stripeInfo.secret);
            stripeTest = ' (test) ';
            if (
                typeof stripeApiKey == 'string' &&
                stripeApiKey.indexOf('pk_test') == -1
            ) {
                stripeTest = '';
            }
        }

        if(version === "V4") {
            return (
                <>
                    <StripeV3
                        clientSecret={this.props.stripeInfo.secret}
                        stripeApiKey={stripeApiKey}
                        paymentSuccess={this.paymentSuccess.bind(this)}
                        elementType={this.props.type === "setup" ? "setup-intent" : "payment-elementV4"}
                        {...this.props}
                    />
                </>
            )
        }

        return (
            <div ref={this.childDiv} className='stripe-container' style={{ width: "100%" }}>
                {/* TODO: There is a bug in android only that seems to re-render when using this function call with nested elements below.  */}
                {/* {this.renderStripeVersion(this.props.version, stripeApiKey)} */}
                {!this.props.hideCountry ?
                    <CountrySelection setParentCountry={this.setParentCountry.bind(this)} {...this.props} />
                    :
                    null
                }
                {this.state.parentCountry === "US" ? (
                    <>
                        <StripeV3
                            clientSecret={this.props.stripeInfo.secret}
                            stripeApiKey={stripeApiKey}
                            paymentSuccess={this.paymentSuccess.bind(this)}
                            {...this.props}
                        />
                        {version === 'V3.2' && (
                            <StripeV3
                                clientSecret={this.props.stripeInfo.secret}
                                stripeApiKey={stripeApiKey}
                                paymentSuccess={this.paymentSuccess.bind(this)}
                                elementType="payment-element"
                                {...this.props}
                            />
                        )}
                        <StripeV3
                            clientSecret={this.props.stripeInfo.secret}
                            stripeApiKey={stripeApiKey}
                            paymentSuccess={this.paymentSuccess.bind(this)}
                            elementType="card"
                            {...this.props}
                        />
                    </>
                ) : StripeV1Component}
            </div>
        );
    }

    styles = {
        headlineDarkCenter: {
            textAlign: 'center',
            color: '#032E4B',
            fontWeight: 'bold',
            fontSize: 32,
            padding: '32px 12px',
            letterSpacing: '0.75px'
        },
        subhead: {
            textAlign: 'left',
            padding: '12px'
        },
        panel: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        planPanel: {
            padding: '20px 0px'
        },
        routerPanel: {
            padding: '20px 0px'
        },
        total: {
            textAlign: 'left'
        },
        fieldContainer: {
            margin: '0 25%'
        },
        payNow: {
            margin: '20px 0px'
        },
        stripePaymentForm: {
            margin: '20px 0px'
        },
        separateBilling: {
            padding: 10,
            border: '1px solid darkgray',
            background: 'lightgray'
        },
        billingCheckbox: {
            marginBottom: 20
        }
    };
}

/**
 * Handles the prod ready implementation of initial checkout
 * 
 * Two static buttons for Apply Pay & Google Pay
 * 
 * Submits form for redirect
 * 
 * Meant to be called within the Post.js and passed into the stripePayment component with props
 */
export const StripeV1 = (props) => {
    const { styles, state, quantity } = props
    return (
        <form style={{ width: "100%" }} METHOD="POST" ACTION="/stripe/create-checkout-session">
            <input name="collectible" type="hidden" value={Utils.get(state, "form1_collectible_container")} />
            <input name="quantity" type="hidden" value={quantity} />
            <Div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input style={styles.stripeAppleSubmitButton} type="submit" value="" />
                <input style={styles.stripeGooglePaySubmitButton} type="submit" value="" />
            </Div>
        </form>
    )
}

/**
 * StripeV1 w/buttons replaced with a single solid color one
 * 
 * button styles match Button component
 * 
 * Submits form for redirect
 */
export const StripeV1SingleButton = (props) => {
    const { state, quantity } = props
    /**
     * Styles taken from react/webapp/src/elements/Button.js
     * Could not use Button component here because it did not support a basic button submit type
     * This is consider V1 and therefore soon to be legacy so no point adding support to that Button for this
     */
    const sty = {
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            flexShrink: 0,
            fontWeight: "bold",
            width: "fit-content",
            fontWeight: 400,
            fontFamily: "Nyata",
        },
        pinkButton: {
            backgroundColor: Colors.magenta,
            color: "white",
        },
        defaultButton: {
            padding: "15px 20px 14px 20px",
            borderRadius: 100,
            fontSize: 16,
            minWidth: 180,
        },
    }
    const buttonColors = sty.pinkButton;
    const buttonSize = sty.defaultButton;
    let buttonStyles = { ...sty.button, ...StylesPlatform.cursorPointer, ...buttonColors, ...buttonSize };
    return (
        <form style={{ width: "100%" }} METHOD="POST" ACTION="/stripe/create-checkout-session">
            <input name="collectible" type="hidden" value={Utils.get(state, "form1_collectible_container")} />
            <input name="quantity" type="hidden" value={quantity} />
            <Div style={{ marginTop: 20, width: "100%" }}>
                <button style={{ ...buttonStyles, width: "100%" }} type="submit">
                    International Checkout
                </button>
            </Div>
        </form>
    )
}

/**
 * Likely Temporary
 *
 * Show countries (US or Other)
 *
 * if US it shows the new wallet buttons via PaymentRequestButtonElement if user has wallet enabled in their device, & Card element
 *
 * if Other, it shows StripeV1Component which allows for international currency conversion but requires the user to redirect
 */
const CountrySelection = (props) => {
    const [country, setCountry] = useState('US');
    const { setParentCountry } = props;
    const sty = {
        selectStyle: {
            fontFamily: "Nyata",
            fontSize: 16,
            borderRadius: 8,
            padding: 8,
            minWidth: 80,
            backgroundColor: Colors.indigo,
            color: "white",
            borderColor: "#444",
        },
    }

    const onClick = useCallback(event => {
        setCountry(event.target.value)
        setParentCountry(event.target.value)
    }, [country]);

    return (
        <Div style={{ marginBottom: "14px", display: "flex" }}>
            <select
                value={country}
                onChange={onClick}
                style={sty.selectStyle}
            >
                <option value="US">&#127482;&#127480; US</option>
                <option value="Other">Other</option>
            </select>
        </Div>
    );
}
