import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Div from '../components/Div';

export default class MetaMask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metamaskActive: false,
            metamaskConnected: false,
            metamaskBusy: false,
            connectedWallet: false,
            accounts: false,
            chainId: false,
            errors: [],
            show: this.props.show,
        };
    }

    componentDidMount() {
        this.clearDetectMetaMask();
        window.detectMetaMaskInterval = setInterval(this.detectMetaMask.bind(this), 500);
    }

    componentWillUnmount() {
        this.clearDetectMetaMask();
    }

    clearDetectMetaMask() {
        if(window.detectMetaMaskInterval) {
            clearInterval(window.detectMetaMaskInterval);
            window.detectMetaMaskInterval = undefined;
        }

    }

    handleStatusChanged(status) {
        console.log("status", status);
        this.setState({metamaskStatus: status});
        if(this.props.onChange) {
            this.props.onChange("status", status);
        }
    }

    handleChainChanged(chainId) {
        console.log("network", chainId);
        this.setState({chainId: chainId});
        if(this.props.onChange) {
            this.props.onChange("chain", chainId);
        }
    }

    handleAccountsChanged(accounts) {
        console.log("accounts", accounts);
        this.setState({accounts: accounts, connectedWallet:accounts[0]});
        if(this.props.onChange) {
            this.props.onChange("accounts", accounts);
        }
    }

    handleReload() {
        window.location.reload(true);
    }

    detectMetaMask() {
        if(this.state.metamaskActive) {
            this.clearDetectMetaMask();
            return true;
        }
        if(window.ethereum && window.ethereum.isMetaMask) {
            console.log("MetaMask active. Binding events.");
            window.ethereum.on('chainChanged', this.handleChainChanged.bind(this));
            window.ethereum.on('accountsChanged', this.handleAccountsChanged.bind(this));
            let tempState = {metamaskActive: true};
            if(window.ethereum.isConnected) {
                tempState['metamaskConnected'] = true;
            }
            if(window.ethereum.chainId) {
                tempState['chainId'] = window.ethereum.chainId;
            }

            this.setState(tempState, () => {
                this.clearDetectMetaMask();
                this.findAccounts();
            });
        }

    }

    findAccounts() {
      console.log("MMB Requesting accounts");
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then( (accounts) => {
            console.log("RETURN FROM METAMASK", accounts);
            this.setState({
                metamaskBusy: false,
                accounts: accounts,
                connectedWallet:accounts[0]
            });
            if(this.props.onChange) {
                this.props.onChange("accounts", accounts);
            }
        })
        .catch( (e) => {
            console.log("MMB Error", e);
            if(Utils.get(e, "code") == -32002) {
                this.setState({
                    metamaskBusy: true,
                });
                const message = "Metamask error: Waiting for approval window. Please click on the browser Metamask toolbar icon to show Metamask window.";
            } else {
                const message = "Metamask error: "+Utils.get(e, "message");
                //out['errors'].push({"code": Utils.get(e, "code"), "message":message});
            }
        });

    }

    getChainName(chainId) {
        const chains = {
            "0x1": "Ethereum Mainnet",
            "0x3": "Ropsten testnet",
            "0x4": "Rinkeby testnet",
            "0x5": "Goerli  testnet",
            "0x2a": "Kovan testnet",
            "0x89": "Polygon Mainnet",
            "0x13881": "Polygon testnet",
        }
        window.chains = chains;
        //console.log("Check net", chainId);
        if(chainId in chains) {
            //console.log("Found net", chains[chainId]);
            return chains[chainId];
        } else {
            return '';
        }
    }

    render() {
        const mmStyle = { ...this.styles.btnMetaMaskContainer, ...this.props.style };
        return this.state.show ?
                <Div className="metaMask" style={mmStyle}>
                    { this.state.metamaskActive ?
                        <Div style={this.styles.connectedBtn} />
                        :
                        <Div style={this.styles.disconnectedBtn} />
                    }
                    <Div style={this.styles.btnMetaMask}>
                        MetaMask
                    </Div>
                    { !this.state.metamaskConnected ?
                        <Div
                            style={{marginRight:8, textDecoration:"underline", cursor:"pointer"}}
                            onClick={this.handleReload.bind(this)}>
                            Not connected, please reload.
                        </Div>
                        :
                        null
                    }
                    { this.state.connectedWallet ?
                        <Div style={{marginRight:8}}>
                            {this.props.abbr ? Utils.abbreviateHash(this.state.connectedWallet) : this.state.connectedWallet}
                        </Div>
                        :
                        this.state.metamaskBusy ?
                            <Div style={this.styles.warning}>
                                Metamask waiting for approval window.
                            </Div>
                            :
                            <Div style={this.styles.warning}>
                                No wallet detected.
                            </Div>
                    }
                    { Utils.getRequest('test') == 1 ?
                        <Div style={this.styles.warning}>
                            Test mode (Rinkeby)
                        </Div>
                        :
                        null
                    }
                    { this.state.chainId ?
                        <Div style={{marginRight:8,marginTop:3, fontSize:10,}}>
                            {this.getChainName(this.state.chainId)}
                        </Div>
                        :
                        null
                    }
                </Div>
                :
                null
    }

    styles = {
        btnMetaMaskContainer: {
            color: "#001",
            //maxWidth: 600,
            border: "1px solid #d9b382",
            background: "white",
            borderRadius: 8,
            padding: 8,
            textAlign: "center",
            display: "flex",
            maxWidth: "50%",
        },
        btnMetaMask: {
            marginRight: 6,
        },
        connectedBtn: {
            width: 16,
            height: 16,
            background: "green",
            borderRadius: 8,
            marginRight: 8,
        },
        disconnectedBtn: {
            width: 16,
            height: 16,
            background: "red",
            borderRadius: 8,
            marginRight: 8,
        },
        warning: {
            marginRight:8,
            background:"maroon",
            color: "white",
            borderRadius: 4,
            paddingLeft: 6,
            paddingRight: 6,
        },
    }
}
