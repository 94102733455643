import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';

import Spinner from '../elements/Spinner';

import Div from '../components/Div';

export default class ImageBckgrnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Div className="afterpartyHomeComponent">
                placeholder
            </Div>
        );
    }


    styles = {
    };

}
