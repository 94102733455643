import React, { useState } from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";
import { Carousel } from 'react-responsive-carousel';

import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';
import intlTelInput from 'intl-tel-input';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import ll from '../modules/ll';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Validate from '../modules/Validate';
import Constant from '../modules/Constant';

import Button from '../elements/Button';
import DebugBox from '../elements/DebugBox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import Textarea from '../elements/Textarea';
import FlashMessage from '../elements/FlashMessage';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import AfterpartyCarousel from '../elements/AfterpartyCarousel';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import BorderSeparator from '../elements/BorderSeparator';
import ErrorBox from '../elements/ErrorBox';
import Spinner from '../elements/Spinner';
import ImageUploaderElement from '../elements/ImageUploaderElement';
import EmailPhoneVerification from '../elements/EmailPhoneVerification';

import SoinCard from './SoinCard';
import EventCard from './EventCard';
import ModalRegister from './ModalRegister';
import Scroller from './Scroller';
import WalletTable from './WalletTable';
import Div from './Div';
import SplashFooter from './SplashFooter';
import Leaderboard from './Leaderboard';
import SocialLinksEdit from './SocialLinksEdit';
import ManagePayments from './ManagePayments';

// import ImageInput from "./ImageCropper/ImageInput";

import imageUploadIcon from "../assets/images/image-upload.png";
import clickToUpload from "../assets/images/click-to-upload.png";
import apLogo from "../assets/images/afterparty_logo_square.jpeg";
import defaultCoverPhoto from "../assets/images/default_coverphoto.jpeg";
import pwaApLogo from "../assets/images/pwaApLogo.png";
import afterpartyLogoSmall from "../assets/images/logos/ap logo small.png"
import defaultProfileImage from "../assets/images/defaultProfileImage.png"

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 60,
                aspect: 1,
            },
            croppedBlob: null,
            croppedImageUrl: null,
            imageUploadSuccess: false,

            verifyStartState: "startEmail",

            profile: false,

            flashMessage: false,

            showImageUploaderModal: false,
            showVerifyModal: false,

            successMessages: [],
            errorMessages: [],
            selectedSection: "personalInformation", // myLinks, password, personalInformation, wallets, banking, connectedAccounts
            isSectionSelected: false,

            passwordCurrentText: "",
            passwordText: "",
            passwordConfirmText: "",
            usernameText: Utils.get(props, "user.username", ""),
            firstNameText: Utils.get(props, "user.first_name", ""),
            lastNameText: Utils.get(props, "user.last_name", ""),
            emailText: Utils.get(props, "user.email", ""),
            phoneText: Utils.get(props, "user.primary_phone1", ""),
            websiteText: Utils.get(props, "user.website_url", ""),
            bioText: Utils.get(props, "user.bio_html", ""),
            phoneDialCode: Utils.get(props, "user.phone_dial_code", ""),
            countryCode: Utils.get(props, "user.country_code", ""),

            isCoverPhoto: false,

            imageUploadPending: false,

            hideCloseButton: true,
        };

        this.phoneInputRef = React.createRef();

        this.settingsSections = [
            {
                name: "personalInformation",
                title: "Personal information",
            },
            {
                name: "myLinks",
                title: "My Links",
            },
            {
                name: "payments",
                title: "Payment",
            },
            // {
            //     name: "password",
            //     title: "Password",
            // },
            //{
            //    name: "wallets",
            //    title: "Wallets",
            //},
            // {
            //     name: "banking",
            //     title: "Banking",
            // },
            // {
            //     name: "connectedAccounts",
            //     title: "Connected Accounts",
            // },
        ];
    }

    initProfile(props) {
        let profile = false;

        if(!props.public) {
            profile = Utils.get(props, "user.email") ? props.user : null;
            this.setState({
                usernameText: Utils.get(props, "user.username", ""),
                firstNameText: Utils.get(props, "user.first_name", ""),
                lastNameText: Utils.get(props, "user.last_name", ""),
                emailText: Utils.get(props, "user.email", ""),
                phoneText: Utils.get(props, "user.primary_phone1", ""),
                websiteText: Utils.get(props, "user.website_url", ""),
                bioText: Utils.get(props, "user.bio_html", ""),
                phoneDialCode: Utils.get(props, "user.phone_dial_code", ""),
                countryCode: Utils.get(props, "user.country_code", ""),
            });
        } else {
            profile = props.publicProfile ?? false;
        }

        if(!profile && props.userLoaded && !props.public) {
            props.history.push("/login");
            window.location.reload();
        }
    }

    componentDidMount() {
        const pr = this.props;

        this.setState({
            showVerifyModal: Utils.get(pr, "user.is_email_verified", 0) != 1,
        });

        this.initProfile(pr);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.initProfile(newProps);
    }

    viewProfile() {
        Utils.gotoRoute(this.props, "profile");
    }

    viewWallet() {
        Utils.gotoRoute(this.props, "mywallet");
    }

    infoChange(infoVar, e) {
        let tempState = Utils.deepCopy(this.state);
        tempState[infoVar] = e.target.value;

        this.setState(tempState);
    }

    uploadImage(files) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            imageUploadPending: true,
        });

        let formData = new FormData();
        const croppedFile = pr.screen.mobile ? files : files.photo.data;
        // const croppedFile = new File([st.croppedBlob], st.croppedBlob.name, {
        //     type: st.croppedBlob.type,
        // });

        formData.append("file", croppedFile);
        formData.append("Filetype", croppedFile.type);
        formData.append("Size", croppedFile.size);
        formData.append("lastModifiedDate", croppedFile.lastModifiedDate);

        Api.postProfileImage(formData, (res) => {
            const photo = `${Api.getCdnUrl()}${Utils.get(res, "data.fname")}`;
            let photoData = null;
            if(st.isCoverPhoto) {
                photoData = {params: {user: {cover_photo: photo}}};
            } else {
                photoData = {params: {user: {photo: photo}}};
            }
            Api.putProfile(photoData, (res) => {
                if(!Utils.get(res, "errors").length) {
                    Api.apiLoadProfile(pr.setUser.bind(this));
                    this.setState({
                        showImageUploaderModal: false,
                        imageUploadSuccess: true,
                        imageUploadPending: false,
                    });
                } else {
                    this.setState({
                        errorMessages: Utils.get(res, "errors", null),
                        showImageUploaderModal: false,
                        imageUploadSuccess: false,
                        imageUploadPending: false,
                    });
                }
            });
        });
    }

    verifyEmail() {
        const data = {
            email: this.props.user.email,
            validate_step: "email_verify",
            format: "json",
        };

        Api.postRegister(data, (response) => {
            if(response == null) {
                return;
            }

            if(Utils.get(response, "success")) {
                this.setState({flashMessage: true});
            } else {
                this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    resetErrorSuccessMessages() {
        this.setState({
            errorMessages: [],
            successMessages: [],
        });
    }

    resetPassword(resetAvenue) {
        const st = this.state;
        const pr = this.props;

        let forgotData = {
            format: "json",
        };

        this.resetErrorSuccessMessages();

        if(resetAvenue == "email") {
            forgotData.email = pr.user.email;

            let validateEmailResponse = Validate.email(st.emailText);

            if(validateEmailResponse.errors?.length) {
                this.setState({
                    errorMessages: validateEmailResponse.errors,
                    successMessages: [],
                });

                return;
            }
        } else if(resetAvenue == "phone") {
            forgotData.primary_phone1 = pr.user.primary_phone1;

            let validatePhoneResponse = Validate.phone(st.phoneText);

            if(validatePhoneResponse.errors?.length) {
                this.setState({
                    errorMessages: validatePhoneResponse.errors,
                    successMessages: [],
                });

                return;
            }
        } else {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(375348, "Fill in a password or email"),
                successMessages: [],
            });

            return;
        }

        Api.postPasswordReset(forgotData, (res) => {
            if(Utils.get(res, "errors", "").length) {
                const errors = res.errors;
                const forgotErrors = {
                    errorMessages: errors,
                    successMessage: [],
                };

                this.setState(forgotErrors);
            } else {
                this.setState({
                    errorMessages: [],
                    successMessages: [{id: 487393, message: res?.data?.message}],
                });
            }
        });
    }

    saveUpdatePassword() {
        const pr = this.props;
        const st = this.state;

        this.resetErrorSuccessMessages();

        let profileData = {
            params: {
                user: {},
            },
        };

        if(st.passwordText != st.passwordConfirmText) {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(45745, "New passwords don't match"),
            });
            return;
        }

        if(st.passwordText.length < 4) {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(45746, "Password must be at least 4 characters"),
            });
            return;
        }

        profileData.params.user.password = st.passwordConfirmText;

        Api.putProfile(profileData, (res) => {
            if(Utils.get(res, "success")) {
                pr.setUser(res);
                this.setState({
                    successMessages: Utils.addErrorSuccessMessage(39348, "Password updated"),
                    errorMessages: [],
                    passwordText: "",
                    passwordConfirmText: "",
                });
            } else {
                this.setState({
                    successMessages: [],
                    errorMessages: Utils.addErrorSuccessMessage(948884, "Password update failed"),
                    passwordText: "",
                    passwordConfirmText: "",
                });
            }
        });
    }

    getIntlPhoneNumber() {
        const st = this.state;

        let formattedPhoneNumber = "";

        formattedPhoneNumber = this.telInput.getNumber();

        const dialCode = $(".iti__active").attr("data-dial-code");

        if(!formattedPhoneNumber.includes("+")) {
            formattedPhoneNumber = `+${dialCode}${formattedPhoneNumber}`
        }

        return formattedPhoneNumber;
    }

    getPhoneDialCode() {
        const phoneDialCode = $(".iti__active").attr("data-dial-code");
        return phoneDialCode;
    }

    getCountryCode() {
        const countryCode = $(".iti__active").attr("data-country-code");
        return countryCode;
    }

    saveUpdateProfileSetPhone() {
        const st = this.state;

        this.setState({
            // phoneEmailPromptErrors: [],
            phoneText: this.getIntlPhoneNumber(),
            phoneDialCode: this.getPhoneDialCode(),
            countryCode: this.getCountryCode(),
        }, () => {
            this.saveUpdateProfile();
        });
    }

    saveUpdateProfile() {
        const pr = this.props;
        const st = this.state;

        this.resetErrorSuccessMessages();

        let profileData = {
            params: {
                user: {},
            },
        };

        if(st.firstNameText != Utils.get(pr, "user.first_name")) {
            const validateFirstName = Validate.minLength(st.firstNameText, 4, "First name");
            if(validateFirstName.errors.length) {
                this.setState({errorMessages: validateFirstName.errors});
                return;
            }

            profileData.params.user.first_name = st.firstNameText;
        }

        if(st.lastNameText != Utils.get(pr, "user.last_name")) {
            const validateLastName = Validate.minLength(st.lastNameText, 4, "Last name");
            if(validateLastName.errors.length) {
                this.setState({errorMessages: validateLastName.errors});
                return;
            }

            profileData.params.user.last_name = st.lastNameText;
        }

        if(st.emailText != Utils.get(pr, "user.email")) {
            const validateEmail = Validate.email(st.emailText);
            if(validateEmail.errors.length) {
                this.setState({errorMessages: validateEmail.errors});
                return;
            }

            profileData.params.user.email = st.emailText;
        }

        if(st.phoneText != Utils.get(pr, "user.primary_phone1")) {
            const validatePhone = Validate.phone(st.phoneText);
            if(validatePhone.errors.length) {
                this.setState({errorMessages: validatePhone.errors});
                return;
            }

            profileData.params.user.primary_phone1 = this.getIntlPhoneNumber();
        }

        if(st.websiteText != Utils.get(pr, "user.website_url")) {
            profileData.params.user.website_url = st.websiteText;
        }

        if(st.bioText != Utils.get(pr, "user.bio_html")) {
            profileData.params.user.bio_html = st.bioText;
        }

        if(st.usernameText != Utils.get(pr, "user.username")) {
            const validateUsername = Validate.minLength(st.usernameText, 4, "Username");
            if(validateUsername.errors.length) {
                this.setState({errorMessages: validateUsername.errors});
                return;
            }

            profileData.params.user.username = st.usernameText;
        }

        if(Utils.isObjectEmpty(profileData.params.user)) {
            this.setState({
                successMessages: Utils.addErrorSuccessMessage(98457, "Nothing to update"),
                errorMessages: [],
            });
            return;
        }

        Api.getPhoneValidation(st.phoneText, (phoneResponse) => {
            if(Utils.get(phoneResponse, "errors", []).length) {
                this.setState({
                    errorMessages: Errors.getErrorMessages(phoneResponse.errors, true, st.phoneText),
                });
            } else {
                Api.putProfile(profileData, (res) => {
                    if(Utils.get(res, "success")) {
                        pr.setUser(res);
                        this.setState({
                            successMessages: Utils.addErrorSuccessMessage(39348, "Profile updated"),
                            errorMessages: [],
                        });
                    } else {
                        this.setState({
                            successMessages: [],
                            errorMessages: Utils.addErrorSuccessMessage(957489, "Profile failed to update"),
                        });
                    }
                });
            }
        });
    }

    showVerifyModal(mode, hideCloseButton) {
        let verifyStartState = "startEmail";
        if(mode == "phone") {
            verifyStartState = "startPhone";
        }
        this.setState({
            showVerifyModal: true,
            verifyStartState: verifyStartState,
            hideCloseButton: false,
        });
    }

    showImageUploaderModal(isCoverPhoto) {
        this.setState({
            isCoverPhoto: isCoverPhoto == true,
        });
    }

    closeImageUploaderModal() {
        this.setState({
            showImageUploaderModal: false,
        });
    }

    changeSelectedSection(sectionName) {
        this.resetErrorSuccessMessages();
        this.setState({
            selectedSection: sectionName,
            isSectionSelected: true,
        });
    }

    goBackToSelection() {
        this.setState({
            isSectionSelected: false,
        });
    }

    saveProfileInfo() {

    }

    renderSettingsSideBar() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const iconsList = ["personal-info", "ap-link", "lock", "wallet"];

        const settingsSideBar = StyleUtils.getMStyle(sty, "settingsSideBar", pr.screen.width, "md");

        if(pr.screen.mobile && st.isSectionSelected) {
            return null;
        }

        return (
            <Div className="settingsSideBar" style={settingsSideBar}>
                <Div className="settingsTitle" style={sty.settingsTitle}>
                    Settings
                </Div>
                {this.settingsSections.map((section, sectionIndex) => {
                    let sideBarItemStyle = Utils.deepCopy(sty.sideBarItem);
                    if(section.name == st.selectedSection && !pr.screen.mobile) {
                        sideBarItemStyle = {...sideBarItemStyle, ...sty.sideBarItemSelected};
                    }
                    return (
                        <Div
                            key={`section-${sectionIndex}`}
                            className="sideBarItem"
                            style={{...sideBarItemStyle, ...StylesPlatform.cursorPointer}}
                            onClick={this.changeSelectedSection.bind(this, section.name)}
                        >
                            <Icon
                                icon={iconsList[sectionIndex]}
                                size={14}
                            />
                            <Div className="sectionTitle" style={sty.sectionTitle}>
                                {section.title}
                            </Div>

                            {pr.screen.mobile ?
                                <Icon
                                    icon="chevron-right"
                                    size={14}
                                    style={{marginLeft: "auto"}}
                                />
                                :
                                null
                            }
                        </Div>
                    )
                })}
            </Div>
        )
    }

    renderBackToSettingsSelection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.screen.mobile) {
            return null;
        } else if(pr.screen.mobile && !st.isSectionSelected) {
            return null;
        }

        return (
            <Div style={{backgroundColor: Colors.indigo}} onClick={this.goBackToSelection.bind(this)}>
                <Icon
                    icon="chevron-left"
                    color="white"
                    size={14}
                    style={{padding: "14px 20px"}}
                />
                Back to Settings
            </Div>
        )
    }

    renderSettingsPage(profile) {
        const pr = this.props;
        const st = this.state;

        if(pr.screen.mobile && !st.isSectionSelected) {
            return null;
        }

        switch (this.state.selectedSection) {
            case "personalInformation":
                return this.renderSettingsInfo(profile);
            case "myLinks":
                return this.renderMyLinks(profile);
            case "password":
                return this.renderPasswordSection(profile);
            case "wallets":
                return this.renderWalletsSection(profile);
            case "banking":
                return this.renderBankingSection(profile);
            case "connectedAccounts":
                return this.renderConnectedAccountsSection(profile);
            case "payments":
                return this.renderPayments(profile);
        }
    }

    phoneInputRefFunc = (phoneInputRef) => {
        if(phoneInputRef) {
            this.telInput = intlTelInput(phoneInputRef, {
              // options here
              initialCountry: Utils.get(this.props, "user.country_code", "us"),
              preferredCountries: Constant.DEFAULT_COUNTRIES,
              formatOnDisplay: false,
              geoIpLookup: function(success, failure) {
                  fetch('https://ipinfo.io')
                    .then(response => response.json())
                    .then(json => success(json.country))
                    .catch(failure);
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
            });
        }
    }

    renderSettingsInfo(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const settingsInfoContainer = StyleUtils.getMStyle(sty, "settingsInfoContainer", pr.screen.width, "md");
        const infoRow = StyleUtils.getMStyle(sty, "infoRow", pr.screen.width, "md");
        const infoLeftCol = StyleUtils.getMStyle(sty, "infoLeftCol", pr.screen.width, "md");
        const profileInfoRow = StyleUtils.getMStyle(sty, "profileInfoRow", pr.screen.width, "md");
        const viewButtonsContainer = StyleUtils.getMStyle(sty, "viewButtonsContainer", pr.screen.width, "md");
        const settingsFullName = StyleUtils.getMStyle(sty, "settingsFullName", pr.screen.width, "md");

        return (
            <Div
                className="settingsInfoContainer"
                style={settingsInfoContainer}
            >
                <Div
                    className="settingsInfoCoverPhoto"
                    style={{...sty.settingsInfoCoverPhoto, ...{backgroundImage: `url("${pr.user.cover_photo ? pr.user.cover_photo : defaultCoverPhoto}")`}}}
                >
                    {/* NOTE COVER PHOTO */}
                    <ImageUploaderElement
                        imageData={st.src?.photo?.src}
                        type="admin"
                        name="photo"
                        aspectRatio={3}
                        label="Add Photo"
                        screen={pr.screen}
                        onChange={(files) => {this.uploadImage(files, true);}}
                    >
                        <Button
                            onClick={this.showImageUploaderModal.bind(this, true)}
                            color="white"
                            style={{marginLeft: "auto"}}
                        >
                            Edit Cover Photo
                        </Button>
                    </ImageUploaderElement>


                    {/* <ImageInput
                        imageData={st.src?.photo?.src}
                        type="admin"
                        name="photo"
                        aspectRatio={3}
                        label="Add Photo"
                        screen={pr.screen}
                        onChange={(files) => {this.uploadImage(files, true);}}
                    >

                    </ImageInput>

                    <Input
                        onChange={(files) => {this.uploadImage(files, true);}}
                    /> */}
                </Div>
                <Div className="settingsInfoInnerContainer" style={sty.settingsInfoInnerContainer}>
                    <Div
                        className="profileInfoRow"
                        style={profileInfoRow}
                    >
                        <Image
                            className="settingsImage"
                            style={sty.settingsImage}
                            src={Utils.get(pr, "user.photo", defaultProfileImage)}
                        />
                        {/* <AfterpartyLogo
                            defaultProfileImage
                            width={100}
                        /> */}
                        <Div
                            className="settingsUserNames"
                            style={sty.settingsUserNames}
                        >
                            <Div className="settingsFullName" style={settingsFullName}>
                                {pr.user.first_name} {pr.user.last_name}
                            </Div>
                            <Div className="settingsUsername" style={sty.settingsUsername}>
                                @{pr.user.username}
                            </Div>
                            {pr.user.is_email_verified == 1 ?
                                <Div style={{color: Colors.neonGreen}}>
                                    Email is verified ✓
                                </Div>
                                :
                                null
                            }
                            {pr.user.is_phone_verified == 1 ?
                                <Div style={{color: Colors.neonGreen}}>
                                    Phone is verified ✓
                                </Div>
                                :
                                null
                            }
                        </Div>
                        <Div className="viewButtonsContainer" style={viewButtonsContainer}>
                            {/* <Button
                                onClick={this.viewProfile.bind(this)}
                                color="white"
                                style={pr.screen.mobile ? {marginBottom: 10} : null}
                            >
                                View Profile
                            </Button> */}
                            <Button
                                onClick={this.viewWallet.bind(this)}
                                color="white"
                                style={pr.screen.mobile ? null : {marginLeft: 20}}
                            >
                                View My Collection
                            </Button>
                        </Div>
                    </Div>

                    <Div className="personalInfoTitle" style={sty.personalInfoTitle}>
                        <Div className="personalInfoText" style={sty.personalInfoText}>
                            Personal information
                        </Div>
                        <Div className="personalInfoSubtitle" style={sty.personalInfoSubtitle}>
                            Update your photo and personal details.
                        </Div>
                        {st.imageUploadPending ?
                            <Div style={{display: "flex", alignItems: "center", gap: 10, marginTop: 10}}>
                                <Spinner
                                    containerStyle={{alignItems: "start", width: "auto"}}
                                    spinnerStyle={{marginBottom: 0}}
                                    size={25}
                                />
                                <Div
                                    style={{
                                        fontWeight: 300,
                                        fontSize: 17,
                                        color: Colors.neonGreen,
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    Uploading photo...
                                </Div>
                            </Div>

                            :
                            null
                        }
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Your Profile Picture
                        </Div>
                        <Div className="infoRightCol" style={{...sty.infoRightCol, ...{display: "flex", maxWidth: 512,}}}>
                            <Image
                                className="settingsUploadAvatar"
                                style={sty.settingsUploadAvatar}
                                src={Utils.get(pr, "user.photo", defaultProfileImage)}
                            />
                            <Div
                                className="uploadContainerTarget"
                                style={{...sty.uploadContainerTarget, ...StylesPlatform.cursorPointer}}
                                onClick={this.showImageUploaderModal.bind(this, false)}
                            >
                                <ImageUploaderElement
                                    imageData={st.src?.photo?.src}
                                    type="admin"
                                    name="photo"
                                    aspectRatio={1}
                                    label="Add Photo"
                                    screen={pr.screen}
                                    onChange={(files) => {this.uploadImage(files);}}
                                >
                                    <Image
                                        className="uploadImageIcon"
                                        aspectRatio={1}
                                        src={imageUploadIcon}
                                        style={{width: 46}}
                                        alt="img"
                                    />
                                </ImageUploaderElement>
                                <Image
                                    style={{width: 86}}
                                    src={clickToUpload}
                                />
                                <Div className="imageUploadType" style={{color: "#7C738C", fontWeight: 300, fontSize: 12}}>
                                    SVG, PNG, JPG or GIF (max. 800x800px)
                                </Div>
                            </Div>
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Username
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Input
                                value={st.usernameText}
                                style={sty.inputStyle}
                                placeholder="Username"
                                onChange={this.infoChange.bind(this, "usernameText")}
                            />
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            First Name
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Input
                                value={st.firstNameText}
                                style={sty.inputStyle}
                                placeholder="First Name"
                                onChange={this.infoChange.bind(this, "firstNameText")}
                            />
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Last Name
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Input
                                value={st.lastNameText}
                                style={sty.inputStyle}
                                placeholder="Last Name"
                                onChange={this.infoChange.bind(this, "lastNameText")}
                            />
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Email
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Input
                                value={st.emailText}
                                style={sty.inputStyle}
                                placeholder="Email"
                                onChange={this.infoChange.bind(this, "emailText")}
                                readOnly
                            />
                            {/* <Button
                                className="updateEmailButton"
                                style={{width: 70, minWidth: 70, marginLeft: 12}}
                                color="white"
                                size="small"
                                onClick={this.showVerifyModal.bind(this, "email")}
                            >
                                Update
                            </Button> */}
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={{...infoRow, ...{paddingBottom: 10}}}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Phone Number
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <input
                                className="internationalPhoneInput"
                                ref={this.phoneInputRefFunc}
                                type="tel"
                                style={{marginBottom: 0}}
                                value={st.phoneText}
                                onChange={this.infoChange.bind(this, "phoneText")}
                                placeholder="Phone Number"
                                readOnly
                            />
                            {Utils.get(pr, "user.is_phone_verified") == 1 ?
                                null
                                :
                                <Button
                                    className="updatePhoneButton"
                                    style={{width: 70, minWidth: 70, marginLeft: 12, marginBottom: 10,height: 37}}
                                    color="white"
                                    size="small"
                                    onClick={this.showVerifyModal.bind(this, "phone")}
                                >
                                    Verify
                                    {/* {Utils.get(pr, "user.is_phone_verified") == 1 ? "Update" : "Verify"} */}
                                </Button>
                            }
                        </Div>
                    </Div>

                    {false &&
                        <>
                            <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                            <Div className="infoRow" style={infoRow}>
                                <Div className="infoLeftCol" style={infoLeftCol}>
                                    Reset Password
                                </Div>
                                <Div className="infoRightCol" style={sty.infoRightCol}>
                                    <Div style={{display: "flex", maxWidth: 512, flexWrap: "wrap", gap: 20}}>
                                        {/* <Button
                                            style={{flex: 1}}
                                            className="sendSmsButton"
                                            color="pinkGradient"
                                            onClick={this.resetPassword.bind(this, "phone")}
                                        >
                                            Send SMS
                                        </Button> */}
                                        <Button
                                            style={{flex: 1}}
                                            className="sendEmailButton"
                                            color="pinkGradient"
                                            onClick={this.resetPassword.bind(this, "email")}
                                        >
                                            Send Email
                                        </Button>
                                    </Div>
                                </Div>
                            </Div>
                        </>
                    }

                    <BorderSeparator color="indigoLighter" style={{height: 1}} />
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Website
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Input
                                value={st.websiteText}
                                style={sty.inputStyle}
                                placeholder="Website"
                                onChange={this.infoChange.bind(this, "websiteText")}
                            />
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol}>
                            Your Bio
                        </Div>
                        <Div className="infoRightCol" style={sty.infoRightCol}>
                            <Textarea
                                value={st.bioText}
                                style={sty.inputStyle}
                                placeholder="Add a short bio..."
                                onChange={this.infoChange.bind(this, "bioText")}
                            />
                        </Div>
                    </Div>

                    <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                    <Div className="infoRow" style={infoRow}>
                        <Div className="infoLeftCol" style={infoLeftCol} />
                        <Div className="infoRightCol" style={{...sty.infoRightCol, ...{maxWidth: 512,}}}>
                            <Button
                                color="pinkGradient"
                                style={{marginLeft: "auto"}}
                                onClick={this.saveUpdateProfileSetPhone.bind(this)}
                            >
                                Save
                            </Button>
                        </Div>
                    </Div>

                    {this.renderErrorMessages()}
                </Div>

                {/*  {st.flashMessage ?
                    <FlashMessage
                        type="info"
                        message="Email Sent"
                        subMessage="Check your email and click the link to verify your account."
                        duration={8000}
                    />
                    :
                    null
                } */}

                {!profile && pr.userLoaded ?
                    !pr.public ?
                        <Div style={{minHeight: 1200}}>
                            Please sign in to see profile
                        </Div>
                        :
                        <Div>
                            Profile not found.
                        </Div>
                    :
                    null
                }
            </Div>
        )
    }

    renderMyLinks(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const settingsInfoContainer = StyleUtils.getMStyle(sty, "settingsInfoContainer", pr.screen.width, "md");

        return (
            <Div
                className="settingsInfoContainer"
                style={{...settingsInfoContainer, ...{paddingLeft: 32, paddingRight: 32}}}
            >
                <Div className="personalInfoTitle" style={sty.personalInfoTitle}>
                    <Div className="personalInfoText" style={sty.personalInfoText}>
                        My Links
                    </Div>
                    <Div className="personalInfoSubtitle" style={sty.personalInfoSubtitle}>
                        Add and update your links. For Verified Creators, the top 5 links will always show up at the bottom of your artist page, and the rest will be in the Additional Links section. For fans, these links will be used to improve your Afterparty experience.
                    </Div>
                </Div>

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <SocialLinksEdit
                    user={pr.user}
                    screen={pr.screen}
                    userLoaded={pr.userLoaded}
                    setUser={pr.setUser.bind(this)}
                />
            </Div>
        );
    }

    renderPasswordSection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const settingsInfoContainer = StyleUtils.getMStyle(sty, "settingsInfoContainer", pr.screen.width, "md");
        const infoRow = StyleUtils.getMStyle(sty, "infoRow", pr.screen.width, "md");
        const infoLeftCol = StyleUtils.getMStyle(sty, "infoLeftCol", pr.screen.width, "md");

        return (
            <Div
                className="settingsInfoContainer"
                style={{...settingsInfoContainer, ...{paddingLeft: 32, paddingRight: 32}}}
            >
                <Div className="personalInfoTitle" style={sty.personalInfoTitle}>
                    <Div className="personalInfoText" style={sty.personalInfoText}>
                        Password
                    </Div>
                    <Div className="personalInfoSubtitle" style={sty.personalInfoSubtitle}>
                        Please enter your current password to change your password.
                    </Div>
                </Div>

                {/* <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <Div className="infoRow" style={infoRow}>
                    <Div className="infoLeftCol" style={infoLeftCol}>
                        Current Password
                    </Div>
                    <Div className="infoRightCol" style={sty.infoRightCol}>
                        <Input
                            value={st.passwordCurrentText}
                            style={sty.inputStyle}
                            type="password"
                            placeholder="Current password"
                            onChange={this.infoChange.bind(this, "passwordCurrentText")}
                        />
                    </Div>
                </Div> */}

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <Div className="infoRow" style={infoRow}>
                    <Div className="infoLeftCol" style={infoLeftCol}>
                        New Password
                    </Div>
                    <Div className="infoRightCol" style={sty.infoRightCol}>
                        <Input
                            value={st.passwordText}
                            style={sty.inputStyle}
                            type="password"
                            placeholder="New password"
                            onChange={this.infoChange.bind(this, "passwordText")}
                        />
                    </Div>
                </Div>

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <Div className="infoRow" style={infoRow}>
                    <Div className="infoLeftCol" style={infoLeftCol}>
                        Confirm New Password
                    </Div>
                    <Div className="infoRightCol" style={sty.infoRightCol}>
                        <Input
                            value={st.passwordConfirmText}
                            style={sty.inputStyle}
                            type="password"
                            placeholder="Confirm new password"
                            onChange={this.infoChange.bind(this, "passwordConfirmText")}
                        />
                    </Div>
                </Div>

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <Div className="infoRow" style={infoRow}>
                    <Div className="infoLeftCol" style={infoLeftCol} />
                    <Div className="infoRightCol" style={{...sty.infoRightCol, ...{maxWidth: 512,}}}>
                        <Button
                            color="pinkGradient"
                            style={{marginLeft: "auto", marginBottom: 28}}
                            onClick={this.saveUpdatePassword.bind(this)}
                        >
                            Save
                        </Button>
                    </Div>
                </Div>
                {this.renderErrorMessages()}
            </Div>
        )
    }

    renderWalletsSection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const connectedWallet = Utils.get(pr.user, "wallets.0");

        const settingsInfoContainer = StyleUtils.getMStyle(sty, "settingsInfoContainer", pr.screen.width, "md");
        const infoRow = StyleUtils.getMStyle(sty, "infoRow", pr.screen.width, "md");
        const infoLeftCol = StyleUtils.getMStyle(sty, "infoLeftCol", pr.screen.width, "md");

        return (
            <Div
                className="settingsInfoContainer"
                style={{...settingsInfoContainer, ...{paddingLeft: 32, paddingRight: 32}}}
            >
                { false ?
                    <Div className="personalInfoTitle" style={sty.personalInfoTitle}>
                        <Div className="personalInfoText" style={sty.personalInfoText}>
                            Wallets
                        </Div>
                        <Div className="personalInfoSubtitle" style={sty.personalInfoSubtitle}>
                            Manage your connected wallets
                        </Div>
                    </Div>
                    :
                    null
                }

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <Div className="infoRow" style={infoRow}>
                    <Div className="infoLeftCol" style={infoLeftCol}>
                        Connected Wallets
                    </Div>
                    <Div className="infoRightCol" style={sty.infoRightCol}>
                        {connectedWallet ?
                            <Div
                                className="connectedWallet"
                                style={{...sty.connectedWallet, ...StylesPlatform.cursorPointer}}
                                onClick={this.viewWallet.bind(this)}
                            >
                                <Image
                                    className="walletImage"
                                    style={sty.walletImage}
                                    src={apLogo}
                                />
                                <Div>
                                    <Div className="connectedWalletId" style={sty.connectedWalletId}>
                                        {Utils.abbreviateHash(connectedWallet.wallet_id, 8)}
                                    </Div>
                                    <Div className="connectedWalletName" style={sty.connectedWalletName}>
                                        {connectedWallet.name}
                                    </Div>
                                </Div>

                            </Div>
                            :
                            <Div>No wallet connected</Div>
                        }
                    </Div>
                </Div>

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                {this.renderErrorMessages()}
            </Div>
        )
    }

    renderBankingSection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="settingsInfoContainer"
                style={sty.settingsInfoContainer}
            >
                Banking
            </Div>
        )
    }

    renderConnectedAccountsSection(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="settingsInfoContainer"
                style={sty.settingsInfoContainer}
            >
                Connected Accounts
            </Div>
        )
    }

    renderPayments(profile) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const settingsInfoContainer = StyleUtils.getMStyle(sty, "settingsInfoContainer", pr.screen.width, "md");


        return (
            <Div
                className="settingsInfoContainer"
                style={{...settingsInfoContainer, ...{paddingLeft: 32, paddingRight: 32}}}
            >
                <Div className="personalInfoTitle" style={sty.personalInfoTitle}>
                    <Div className="personalInfoText" style={sty.personalInfoText}>
                       Payment
                    </Div>
                    <Div className="personalInfoSubtitle" style={sty.personalInfoSubtitle}>
                        Manage your payment methods
                    </Div>
                </Div>

                <BorderSeparator color="indigoLighter" style={{height: 1}}/>
                <ManagePayments
                    modalProps={{
                        screen: pr.screen,
                        isDragon: false,
                    }}
                />
            </Div>
        );
    }

    renderErrorMessages() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const infoRow = StyleUtils.getMStyle(sty, "infoRow", pr.screen.width, "md");
        const infoLeftCol = StyleUtils.getMStyle(sty, "infoLeftCol", pr.screen.width, "md");

        return (
            <Div className="infoRow" style={infoRow}>
                <Div className="infoLeftCol" style={infoLeftCol} />
                <Div className="infoRightCol" style={{...sty.infoRightCol, ...{maxWidth: 512,}}}>
                    <ErrorBox
                        successMessages={st.successMessages}
                        errorMessages={st.errorMessages}
                    />
                </Div>
            </Div>

        )
    }

    renderShowVerifyModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        console.log("st.hideCloseButton >>>>", st.hideCloseButton);

        return (
            <Modal
                screen={pr.screen}
                className="showVerifyModal"
                // title="Find New Creators"
                onRequestClose={ () => { this.setState({showVerifyModal: false}); } }
                style={{maxWidth: 450, paddingBottom: 20}}
                color="indigo"
                hideCloseButton={st.hideCloseButton}
            >
                <EmailPhoneVerification
                    showTokenBadges
                    startState={st.verifyStartState}
                    screen={pr.screen}
                    history={pr.history}
                    user={pr.user}
                    userLoaded={pr.userLoaded}
                    setUser={pr.setUser.bind(this)}
                    continueFunction={ () => { this.setState({showVerifyModal: false}); } }
                />
            </Modal>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let profile = false;
        // If not a public profile, display user info
        if(!pr.public) {
            profile = Utils.get(pr.user, "email") ? pr.user : null;
        } else {
            // Public profile -- check if profile is loaded
            profile = pr.publicProfile ? pr.publicProfile : false;
        }

        // const settingsComponent = StyleUtils.getMediaStyle('settingsComponent', sty.settingsComponent, sty, StyleUtils.getWidthType(pr.screen.width));
        const settingsComponent = StyleUtils.getMStyle(sty, 'settingsComponent', pr.screen.width, "md");
        const settingsContainer = StyleUtils.getMStyle(sty, 'settingsContainer', pr.screen.width, "md");

        if(!profile && pr.userLoaded && !pr.public) {
            return null;
        }

        return (
            <>
                <Div className="settingsComponent" style={{...settingsComponent, ...pr.style}}>
                    <Div className="settingsContainer" style={settingsContainer}>
                        {this.renderSettingsSideBar()}
                        {this.renderBackToSettingsSelection()}
                        {this.renderSettingsPage(profile)}
                    </Div>

                </Div>

                {st.showVerifyModal ?
                    this.renderShowVerifyModal()
                    :
                    null
                }

                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                    footerColor={Colors.indigo}
                />
            </>
        );
    }

    styles = {
        settingsComponent: {
            width: "100%",
            padding: 40,
            cursor: "default",
            backgroundColor: "#0F0420",
            color: "white",
            minHeight: "100vh",
            fontFamily: "Nyata",
            fontWeight: 300,
            fontSize: 17,
            marginTop: 72,
        },
        settingsComponent_md: {
            padding: 0,
            borderTopLeftRadius: 0,
            minHeight: "calc(100vh - 340px)",
        },

        settingsSideBar: {
            width: 280,
            flexShrink: 0,
            position: "fixed",
        },
        settingsSideBar_md: {
            width: "100%",
            padding: 20,
            position: "static"
        },
        settingsTitle: {
            fontSize: 32,
            fontWeight: 400,
            marginBottom: 32,
        },
        sectionTitle: {
            marginLeft: 12,
        },
        sideBarItem: {
            display: "flex",
            alignItems: "center",
            padding: "8px 12px",
        },
        sideBarItemSelected: {
            backgroundColor: Colors.plumLight,
            color: "white",
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            marginLeft: 0,
        },

        settingsContainer: {
            display: "flex",
            flexDirection: "row",
        },
        settingsContainer_md: {
            flexDirection: "column",
        },
        settingsInfoContainer: {
            width: "100%",
            backgroundColor: Colors.indigoLight,
            borderRadius: 16,
            overflow: "hidden",
            minHeight: 615,
            marginLeft: 280,
        },
        settingsInfoContainer_md: {
            borderRadius: 0,
            marginLeft: 0,
        },
        settingsInfoInnerContainer: {
            padding: "24px 32px",
        },
        settingsInfoCoverPhoto: {
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
            height: 300,
            paddingTop: 24,
            paddingRight: 32,
        },
        profileInfoRow: {
            width: "100%",
            display: "flex",
        },
        profileInfoRow_md: {
            flexDirection: "column",
        },
        settingsImage: {
            width: 160,
            height: 160,
            backgroundColor: "black",
            borderRadius: 200,
            border: `4px solid ${Colors.indigoLighter}`,
            marginTop: -62,
            marginRight: 24,
        },

        settingsUploadAvatar: {
            width: 126,
            height: 126,
            borderRadius: 200,
            marginRight: 40,
            backgroundColor: "black",
        },
        uploadContainerTarget: {
            backgroundColor: Colors.indigoLighter,
            height: 126,
            width: "100%",
            borderRadius: 8,
            border: "1px dashed #7C738C",
            padding: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
        },

        settingsUserNames: {
            marginRight: "auto",
        },
        settingsFullName: {
            fontSize: 33,
            fontWeight: 500,
        },
        settingsFullName_md: {
            marginTop: 10,
            marginBottom: 5,
        },
        settingsUsername: {
            marginBottom: 10,
        },
        viewButtonsContainer: {
            display: "flex",
            alignItems: "flex-start",
        },
        viewButtonsContainer_md: {
            flexDirection: "column",
        },
        personalInfoTitle: {
            marginTop: 24,
            marginBottom: 32,
        },
        personalInfoText: {
            fontSize: 23,
            fontWeight: 500,
            display: "flex",
            whiteSpace: "nowrap",
        },
        personalInfoSubtitle: {},
        infoRow: {
            display: "flex",
            paddingTop: 20,
            paddingBottom: 20,
        },
        infoRow_md: {
            flexDirection: "column",
        },
        infoLeftCol: {
            flexShrink: 0,
            width: 280,
        },
        infoLeftCol_md: {
            marginBottom: 10,
        },
        infoRightCol: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 512,
        },
        inputStyle: {
            marginBottom: 0,
            maxWidth: 512,
        },

        connectedWallet: {
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 12,
            paddingRight: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: Colors.indigoLighter,
            borderRadius: 16,
            maxWidth: 496,
        },

        connectedWalletId: {
            fontSize: 16,
            fontWeight: 400,
        },
        connectedWalletName: {
            fontSize: 14,
            marginTop: 4,
        },
        walletImage: {
            width: 34,
            borderRadius: 100,
            border: `2px solid ${Colors.magenta}`,
            marginRight: 10,
        },

    };
}
