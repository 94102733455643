import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Span from '../elements/Span';
import Icon from '../elements/Icon';
import Input from '../elements/Input';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import BorderSeparator from '../elements/BorderSeparator';

import Div from './Div';
import Post from './Post';
import SplashSocialMediaIcons from './SplashSocialMediaIcons';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"

export default class SplashFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showGetHelp: false,
            column1: [
                {
                    title: "Popular Links",
                },
                {
                    title: "Home",
                    link: "home",
                },
                {
                    title: "Membership",
                    link: "p/utopian_community",
                },
                {
                    title: "AI Chat",
                    link: "p/clone_yourself?artist_id=67",
                    isBadge: true,
                },
                {
                    title: "For Creators",
                    link: "p/afterparty_platform",
                },
                {
                    title: "About Us",
                    link: "p/about",
                },
                {
                    title: "Artist Directory",
                    link: "p/creator_directory",
                },
                {
                    title: "In the News",
                    link: "p/in_the_news",
                },
            ],

            column2: [
                {
                    title: "Help Center",
                },
                {
                    title: "Give Feedback",
                    link: "p/afterparty_contact_form",
                },
                {
                    title: "Help & Support",
                    link: "p/faq",
                },
            ],

            column3: [
                {
                    title: "Legal",
                },
                {
                    title: "Terms",
                    link: "tos",
                },
                {
                    title: "Privacy",
                    link: "privacypolicy",
                },
                {
                    title: "DMCA",
                    link: "dmca",
                },
                {
                    title: "Trademark Guidelines",
                    link: "p/trademark_usage_guidelines",
                },
                {
                    title: "Content Guidelines",
                    link: "p/content_guidelines",
                },
            ],
        };
    }
    newTab(link) {
        window.open(`/${link}`, "_blank");
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    showGetHelp() {
        this.setState({
            showGetHelp: !this.state.showGetHelp,
        });
    }

    gotoRoute(link) {
        const pr = this.props;

        Utils.gotoRoute(pr, link);
    }

    renderFooterColumn(column) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="footerColumn" style={sty.footerColumn}>
                {column.map((colInfo, colInfoIndex) => {
                    if(colInfoIndex == 0) {
                        if(colInfo.title == "Popular Links" && pr.screen.mobile) {
                            return null;
                        }

                        return (
                            <Div key={`footerColumnRowHeader-${colInfoIndex}`} className="footerColumnRowHeader" style={sty.footerColumnRowHeader}>
                                {colInfo.title}
                            </Div>
                        );
                    } else {
                        return (
                            <Div
                                key={`footerColumnRow-${colInfoIndex}`}
                                className="footerColumnRow"
                                style={{...sty.footerColumnRow, ...StylesPlatform.cursorPointer, ...(colInfo.isBadge ? {whiteSpace: "nowrap"} : null)}}
                                onClick={this.gotoRoute.bind(this, colInfo.link)}
                            >
                                {colInfo.title} {colInfo.isBadge ? <Span style={sty.badgeStyle}>New</Span> : null}
                            </Div>
                        );
                    }
                })}
            </Div>
        )
    }

    renderBorder() {
        const pr = this.props;

        if(!pr.screen.mobile) {
            return null;
        }

        return (
            <BorderSeparator
                color="indigoLighter"
                style={{height: 1}}
            />
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const splashFooterTitle = StyleUtils.getMediaStyle('splashFooterTitle', sty.splashFooterTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const splashFooterSection = StyleUtils.getMediaStyle('splashFooterSection', sty.splashFooterSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyLogoOnlyStyle = StyleUtils.getMediaStyle('afterpartyLogoOnly', sty.afterpartyLogoOnly, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const splashFooterSectionInner = StyleUtils.getMStyle(sty, "splashFooterSectionInner", pr.screen.width, "md");
        const splashFooterSection1 = StyleUtils.getMStyle(sty, "splashFooterSection1", pr.screen.width, "md");
        const splashFooterSection2 = StyleUtils.getMStyle(sty, "splashFooterSection2", pr.screen.width, "md");
        const footerBottomInner = StyleUtils.getMStyle(sty, "footerBottomInner", pr.screen.width, "md");
        const footerBottom = StyleUtils.getMStyle(sty, "footerBottom", pr.screen.width, "md");

        const localGlobalProps = {
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        };

        return (
            <>
                {pr.isFooterBorder ?
                    <BorderSeparator
                        color="pinkGradient"
                    />
                    :
                    null
                }

                <Div className="splashFooterSection" style={splashFooterSection}>
                    <Div className="splashFooterSectionInner" style={splashFooterSectionInner}>
                        <Div className="splashFooterSection1" style={splashFooterSection1}>
                            <Div className="" style={{maxWidth: 320}}>
                                <Div className="" style={{marginBottom: 16}}>
                                    More From
                                </Div>
                                <AfterpartyLogo
                                    logo="white"
                                    width={188}
                                    height={32}
                                    style={{marginBottom: 16}}
                                />
                                <Div className="" style={null}>
                                    Your favorite creators are here! Get 1 on 1 unfiltered experiences with creators through Afterparty livestreams or chat 24/7 through AI powered conversations.
                                </Div>
                            </Div>

                            {this.renderBorder()}

                            <Div>
                                <Div className="" style={{fontWeight: 500, marginBottom: 8}}>
                                    Learn More About Afterparty AI
                                </Div>
                                <Div className="" style={{marginBottom: 18}}>
                                    Chat with your favorite creators via <Button type="text" style={sty.buttonTextStyle} onClick={Utils.gotoRoute.bind(this, pr, "p/clone_yourself?artist_id=67")}>Afterparty AI Chat</Button>
                                </Div>
                                <Div className="" style={{display: "flex"}}>
                                    Creators: <Button type="text" style={sty.buttonTextStyle} onClick={Utils.gotoRoute.bind(this, pr, "p/ai_creator_intake_form")}>&nbsp;Sign up for Access</Button>
                                </Div>
                            </Div>
                        </Div>

                        {this.renderBorder()}

                        <Div className="splashFooterSection2" style={splashFooterSection2}>
                            {this.renderFooterColumn(st.column1)}
                            {pr.screen.mobile ?
                                <>
                                    {this.renderBorder()}
                                    <Div style={{display: "flex", justifyContent: "space-between"}} onClick={this.showGetHelp.bind(this)}>
                                        <Div>Get Help</Div>
                                        <Div>+</Div>
                                    </Div>
                                    {st.showGetHelp ?
                                        <Div>
                                            {this.renderFooterColumn(st.column2)}
                                            {this.renderFooterColumn(st.column3)}
                                        </Div>
                                        :
                                        null
                                    }
                                </>
                                :
                                <>
                                    {this.renderFooterColumn(st.column2)}
                                    {this.renderFooterColumn(st.column3)}
                                </>
                            }
                        </Div>

                        {this.renderBorder()}
                    </Div>

                    <Div className="footerBottom" style={footerBottom}>
                        <Div className="footerBottomInner" style={footerBottomInner}>
                            <Div>
                                © 2024 Afterparty. All rights reserved.
                            </Div>
                            <Div className="socialIconsList" style={sty.socialIconsList}>
                                <Icon
                                    icon="twitter"
                                    size={24}
                                    onClick={Utils.newTab.bind(this, "https://twitter.com/afterparty")}
                                    style={StylesPlatform.cursorPointer}
                                />
                                <Icon
                                    icon="youtube"
                                    size={24}
                                    onClick={Utils.newTab.bind(this, "https://www.youtube.com/@afterparty6151")}
                                    style={StylesPlatform.cursorPointer}
                                />
                                <Icon
                                    icon="instagram"
                                    size={24}
                                    onClick={Utils.newTab.bind(this, "https://www.instagram.com/afterparty")}
                                    style={StylesPlatform.cursorPointer}
                                />
                                <Icon
                                    icon="tiktok"
                                    size={24}
                                    onClick={Utils.newTab.bind(this, "https://www.tiktok.com/@afterparty?lang=en")}
                                    style={StylesPlatform.cursorPointer}
                                />
                                <Icon
                                    icon="linkedin"
                                    size={24}
                                    onClick={Utils.newTab.bind(this, "https://www.linkedin.com/company/afterpartyai/")}
                                    style={StylesPlatform.cursorPointer}
                                />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </>
        );
    }

    styles = {
        splashFooterSection: {
            backgroundColor: this.props.footerColor ? this.props.footerColor : Colors.indigo,
            fontSize: 18,
            color: "white",
            fontWeight: 300,
            paddingTop: 40,
        },
        splashFooterSection_md: {
        },
        splashFooterSectionInner: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            maxWidth: 1220,
            marginLeft: "auto",
            marginRight: "auto",
            padding: 20,
            gap: 60,
        },
        splashFooterSectionInner_md: {
            flexDirection: "column",
            paddingTop: 48,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 48,
            gap: 24,
        },
        splashFooterSection1: {
            display: "flex",
            flexDirection: "row",
            width: "60%",
            gap: 20,
            justifyContent: "space-between",
        },
        splashFooterSection1_md: {
            flexDirection: "column",
            width: "100%",
        },
        splashFooterSection2: {
            display: "flex",
            flexDirection: "row",
            width: "40%",
            gap: 20,
            justifyContent: "space-between",
        },
        splashFooterSection2_md: {
            flexDirection: "column",
            width: "100%",
        },
        splashFooterRow: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
        },
        splashFooterTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 40,
            color: "white",
            marginBottom: 30,
            textAlign: "center",
        },
        splashFooterTitle_md: {
            fontSize: 26,
            fontWeight: 500,
        },
        afterpartyLogoOnly: {
            marginBottom: 30,
            width: 89,
        },
        afterpartyLogoOnly_md: {
            width: 60,
        },
        footerLinks: {
            display: "flex",
            maxWidth: 380,
            marginTop: 35,
            width: "100%",
            justifyContent: "space-between",
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 12,
        },
        footerColumnRowHeader: {
            color: "#A38FC4",
            marginBottom: 12,
        },
        footerColumnRow: {
            marginBottom: 12,
        },

        socialIconsList: {
            display: "flex",
            gap: 24,
            alignItems: "center",
        },

        footerBottom: {
            backgroundColor: Colors.indigoLight,
            paddingTop: 48,
            paddingBottom: 48,
            paddingLeft: 20,
            paddingRight: 20,

        },
        footerBottom_md: {
            paddingTop: 24,
            paddingBottom: 24,
        },
        footerBottomInner: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            maxWidth: 1220,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: 0.5,
        },
        footerBottomInner_md: {
            flexDirection: "column-reverse",
            gap: 20,
            alignItems: "flex-start"
        },

        buttonTextStyle: {
            fontSize: 18,
            fontWeight: 400,
        },
        badgeStyle: {
            backgroundColor: Colors.indigoLight,
            border: `1px solid ${Colors.magenta}`,
            borderRadius: 100,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 2,
            paddingBottom: 2,
            marginLeft: 8,
            fontSize: 12,
        },

        artistCarousel: {
            maxWidth: 1220,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 48,
            paddingLeft: 20,
            paddingRight: 20,
        },
    };
}
