import React from 'react';
import * as Cookies from "js-cookie";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';

import Div from './Div';

import followUser from '../assets/icons/follow-user.svg';
import coinsIcon from '../assets/icons/coins.svg';

export default class AfterpartyBidBadge extends React.Component {
    handleCloseBidNotification(e) {
        const pr = this.props;

        // e.stopPropagation();
        // e.nativeEvent.stopImmediatePropagation();
        pr.handleCloseBidNotification();
    }

    renderBidNotification(badgeNotification) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.showBidNotification) {
            return null;
        }

        return (
            <Div
                className="bidNotification"
                style={sty.bidNotification}
                onClick={pr.handleClickBidNotification.bind(this)}
            >
                <Div className="absoluteArrowUp" style={sty.absoluteArrowUp}></Div>
                <Div
                    className="notificationCloseButton"
                    style={sty.notificationCloseButton}
                    onClick={pr.handleCloseBidNotification.bind(this)}
                >
                    ×
                </Div>
                <Div>{pr.notificationMessage}</Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="bidNotificationContainer"
                style={{...sty.bidNotificationContainer, ...StylesPlatform.cursorPointer}}
                // onClick={pr.handleBidClick.bind(this, 1)}
            >
                <Icon
                    className="bidNotificationBadge"
                    icon="certificate"
                    size={27}
                    color={Colors.purple}
                    style={{marginLeft: 10}}
                />
                {/* <Div className="notificationBadgeText" style={sty.notificationBadgeText}>
                    +{pr.badgeNotification.id}
                </Div> */}
                {this.renderBidNotification(pr.badgeNotification)}
            </Div>
        );
    }

    styles = {
        bidNotificationContainer: {
            position: "relative",
        },
        bidNotification: {
            position: "absolute",
            alignItems: "center",
            display: "flex",
            top: 43,
            right: -44,
            minWidth: 200,
            padding: 10,
            backgroundColor: "white",
            borderRadius: 8,
            zIndex: 1000,
            color: Colors.purple,
            fontSize: 12,
            border: "1px solid #aaa",
            overflow: "hidden",
        },
        absoluteArrowUp: {
            position: "absolute",
            top: -14,
            right: 41,
            width: 0,
            height: 0,
            borderLeft: "15px solid white",
            borderRight: "15px solid white",
            borderBottom: "15px solid white",
            border: "1px solid #aaa",
            backgroundColor: "red",
        },
        notificationCloseButton: {
            position: "absolute",
            top: -4,
            right: 4,
            fontSize: 20,
            color: "#aaa",
        },
        notificationBadgeText: {
            position: "absolute",
            top: 7,
            left: 13,
            fontSize: 9,
            color: Colors.magenta,
        },
    };
}
