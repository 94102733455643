import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import '../App.css';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';

import Div from './Div';
import SplashSocialMediaIcons from './SplashSocialMediaIcons';
import SplashFooter from './SplashFooter';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import menuImage from "../assets/images/splash/Menu.png"

import fourHeaderBackgroundImage from "../assets/images/splash/FOUR2x.jpg"
import platformHeaderImage from "../assets/images/splash/Phones 1.png"
import oneAfterpartyLogo from "../assets/images/splash/Group 171.png"
import creatorToFanConnectionImage from "../assets/images/splash/Phones 2.png"
import creatorToFanConnectionGradient from "../assets/images/splash/Gradient.png"
import creatorToFanConnectionGallery from "../assets/images/splash/Fest pics.png"
import afterpartyCreatorPassGradient from "../assets/images/splash/Gradient. orange.png"
import afterpartyCreatorPassImage from "../assets/images/splash/Pass.png"
import afterpartyTokenImage from "../assets/images/splash/Party icon.png"
import oneAfterpartyFestival from "../assets/images/splash/Universe_ Fest 2.png"
import oneAfterpartyVideoCall from "../assets/images/splash/Universe_ Platform 2.png"

export default class SplashPlatform extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();

        this.state = {
            selected: "afterparty",

            includes: {},
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.setState({selected: pr.route ? pr.route : 'afterparty'}, () => {
            this.handleRoute(st.selected);
        });
        if(pr.includes) {
            this.setState({includes: pr.includes});
        }
        Utils.handleScroll.bind(this)();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';
        //console.log("INCLUDES2", nextProps.includes);
        let tempState = {};
        if(nextProps.includes) {
            tempState['includes'] = nextProps.includes;
        }
        if(this.state.selected != selected) {
            tempState['selected'] = selected;
        }
        // if(Utils.get(nextProps, "includes.presale") && !Utils.getRequest('presale')) {
        //     tempState['showPresale'] = Utils.get(nextProps, "includes.presale");
        // }
        this.setState(tempState);
    }

    handleRoute(selected) {
    }
    newTab(link) {
        window.open(link, "_blank");
        //this.props.history.push(`/${link}`);
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    renderHeaderFourSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const headerFourSection = StyleUtils.getMediaStyle('headerFourSection', sty.headerFourSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const headerFourInfoContainer = StyleUtils.getMediaStyle('headerFourInfoContainer', sty.headerFourInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyLogoStyle = StyleUtils.getMediaStyle('oneAfterpartyLogoStyle', sty.oneAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const fourMainTitle = StyleUtils.getMediaStyle('fourMainTitle', sty.fourMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const fourMainSubtitle = StyleUtils.getMediaStyle('fourMainSubtitle', sty.fourMainSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="headerFourSection" style={headerFourSection}>
                <Div className="headerFourInfoContainer" style={headerFourInfoContainer}>
                    <Image
                        src={oneAfterpartyLogo}
                        style={oneAfterpartyLogoStyle}
                    />
                    <Div className="fourMainTitle" style={fourMainTitle}>
                        Creator to consumer platform
                    </Div>
                    <Div className="fourMainSubtitle" style={fourMainSubtitle}>
                        End to end toolset for creators to launch NFTs and connect with their fans
                    </Div>
                    <Div className="fourMainSubtitle" style={fourMainSubtitle}>
                        Join the community
                    </Div>

                    <SplashSocialMediaIcons
                        size={40}
                    />
                </Div>
                <Image
                    src={platformHeaderImage}
                    style={sty.platformHeaderImageStyle}
                />
            </Div>
        )
    }

    renderCreatorToFanConnection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const creatorToFanConnection = StyleUtils.getMediaStyle('creatorToFanConnection', sty.creatorToFanConnection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const creatorToFanConnectionDescription = StyleUtils.getMediaStyle('creatorToFanConnectionDescription', sty.creatorToFanConnectionDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="creatorToFanConnection" style={creatorToFanConnection}>
                <Div className="creatorToFanConnectionInner" style={sty.creatorToFanConnectionInner}>
                    <Div className="creatorToFanConnectionTitle" style={sty.creatorToFanConnectionTitle}>
                        Creator To Fan Connection
                    </Div>
                    <Div className="creatorToFanConnectionDescription" style={creatorToFanConnectionDescription}>
                        Afterparty’s NFT marketplace and streaming platform allows for direct fan to creator connection, while providing an end to end toolset for creators to launch NFTs. Afterparty will be collaborating with some of the biggest names in the crypto and entertainment space.
                    </Div>
                    <Image
                        src={creatorToFanConnectionImage}
                        style={sty.creatorToFanConnectionImageStyle}
                    />
                </Div>
            </Div>
        )
    }

    renderAfterpartyCreatorPass() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const afterpartyCreatorPassInner = StyleUtils.getMediaStyle('afterpartyCreatorPassInner', sty.afterpartyCreatorPassInner, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyCreatorPassInfoLeft = StyleUtils.getMediaStyle('afterpartyCreatorPassInfoLeft', sty.afterpartyCreatorPassInfoLeft, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="afterpartyCreatorPass" style={sty.afterpartyCreatorPass}>
                <Div className="afterpartyCreatorPassInner" style={afterpartyCreatorPassInner}>
                    <Div className="afterpartyCreatorPassInfoLeft" style={afterpartyCreatorPassInfoLeft}>
                        <Div className="afterpartyCreatorPassTitle" style={sty.afterpartyCreatorPassTitle}>
                            Afterparty Creator Pass
                        </Div>
                        <Div className="afterpartyCreatorPassDescription" style={sty.afterpartyCreatorPassDescription}>
                            Afterparty is working with some of the most influential creators in the world developing exclusive NFT collections that serve as a passport to that creators world. The first collections will be dropping shortly.
                        </Div>
                    </Div>

                    <Image
                        className="afterpartyCreatorPassImage"
                        src={afterpartyCreatorPassImage}
                        style={sty.afterpartyCreatorPassImageStyle}
                    />
                </Div>
            </Div>
        )
    }

    renderAfterpartyToken() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const afterpartyToken = StyleUtils.getMediaStyle('afterpartyToken', sty.afterpartyToken, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyTokenInner = StyleUtils.getMediaStyle('afterpartyTokenInner', sty.afterpartyTokenInner, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyTokenInfoRight = StyleUtils.getMediaStyle('afterpartyTokenInfoRight', sty.afterpartyTokenInfoRight, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="afterpartyToken" style={afterpartyToken}>
                <Div className="afterpartyTokenInner" style={afterpartyTokenInner}>
                    <Image
                        className="afterpartyTokenImage"
                        src={afterpartyTokenImage}
                        style={sty.afterpartyTokenImageStyle}
                    />
                    <Div className="afterpartyTokenInfoRight" style={afterpartyTokenInfoRight}>
                        <Div className="afterpartyTokenTitle" style={sty.afterpartyTokenTitle}>
                            Afterparty Token
                        </Div>
                        <Div className="afterpartyTokenDescription" style={sty.afterpartyTokenDescription}>
                            Afterparty belongs to its community of creators and we are building towards that creator-controlled future with the $<Span style={{fontWeight: "bold"}}>PARTY</Span> token.
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <div ref={this.childDiv} className="afterpartyStaticHomeComponent" style={sty.afterpartyStaticHomeComponent}>
                {this.renderHeaderFourSection()}
                {this.renderCreatorToFanConnection()}
                {this.renderAfterpartyCreatorPass()}
                {false ?
                    this.renderAfterpartyToken()
                    :
                    null
                }

                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                />
            </div>
        );
    }

    styles = {
        splashMagentaButton: {
            backgroundColor: Colors.magenta,
            color: Colors.splashDarkPurple,
            marginTop: 12,
            marginBottom: 12,
        },
        festivalButton: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            backgroundColor: "none",
            border: "1px solid white",
            borderRadius: 200,
            paddingLeft: 90,
            paddingRight: 90,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 68,
            boxSizing: "border-box",
        },
        festivalButton_md: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        festivalButtonMagenta: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            backgroundColor: Colors.magenta,
            borderRadius: 200,
            paddingLeft: 90,
            paddingRight: 90,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 68,
        },
        afterpartyStaticHomeComponent: {
            backgroundColor: Colors.splashDarkPurple,
        },
        headerFourSection: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: 852,
            backgroundImage: `url("${fourHeaderBackgroundImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            padding: 20,
        },
        headerFourSection_md: {
            backgroundPosition: "right bottom",
            backgroundImage: `url("${fourHeaderBackgroundImage}")`,
            minHeight: 852,
            flexDirection: "column",
            paddingTop: 150,
        },
        headerFourInfoContainer: {
            paddingTop: 56,
            paddingRight: 56,
            paddingBottom: 56,
            width: "100%",
            maxWidth: 725,
            width: "fit-content",
            borderRadius: 40,
        },
        headerFourInfoContainer_md: {
            marginLeft: "auto",
            marginRight: "auto",
            padding: 0,
        },
        oneAfterpartyLogoStyle: {
            width: 254,
            marginBottom: 5,
        },
        oneAfterpartyLogoStyle_md: {
            width: 182,
        },
        fourMainTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 72,
            color: "white",
            maxWidth: 942,
            width: "100%",
            marginBottom: 20,
        },
        fourMainTitle_md: {
            fontSize: 50,
            lineHeight: "54px",
        },
        fourMainSubtitle: {
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 24,
            marginBottom: 20,
        },
        fourMainSubtitle_md: {
            fontSize: 18,
            maxWidth: 280,
        },
        platformHeaderImageStyle: {
            maxWidth: 547,
            width: "100%",
            flexShrink: 0,
        },
        creatorToFanConnection: {
            width: "100%",
            minHeight: 1350,
            backgroundColor: Colors.splashDarkPurple,
            backgroundImage: `url("${creatorToFanConnectionGradient}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            paddingRight: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingTop: 150,
        },
        creatorToFanConnection_md: {
            paddingRight: 20,
            paddingBottom: 50,
            paddingLeft: 20,
            paddingTop: 50,
            minHeight: 800,
        },
        creatorToFanConnectionInner: {
            maxWidth: 1023,
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 100,
            paddingBottom: 100,
        },
        creatorToFanConnectionTitle: {
            fontFamily: "Nyata",
            color: "white",
            fontSize: 53,
            paddingBottom: 35,
            textAlign: "center",
        },
        creatorToFanConnectionDescription: {
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 24,
            marginBottom: 40,
            textAlign: "center",
        },
        creatorToFanConnectionDescription_md: {
            fontSize: 18,
        },
        creatorToFanConnectionImageStyle: {
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            maxWidth: 835,
            width: "100%",
        },

        afterpartyCreatorPass: {
            width: "100%",
            // minHeight: 830,
            backgroundColor: "white",
            backgroundImage: `url("${afterpartyCreatorPassGradient}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 150,
            paddingBottom: 150,
            display: "flex",
            justifyContent: "center",
        },
        afterpartyCreatorPassInner: {
            display: "flex",
            alignItems: "center",
            maxWidth: 1030,
        },
        afterpartyCreatorPassInner_md: {
            flexDirection: "column",
            justifyContent: "center",
        },
        afterpartyCreatorPassInfoLeft: {
            paddingRight: 70,
        },
        afterpartyCreatorPassInfoLeft_md: {
            paddingRight: 0,
            textAlign: "center",
        },
        afterpartyCreatorPassImageStyle: {
            maxWidth: 357,
            width: "100%",
        },
        afterpartyCreatorPassTitle: {
            fontFamily: "Nyata",
            color: Colors.splashDarkPurple,
            fontSize: 53,
            paddingBottom: 25,
        },
        afterpartyCreatorPassDescription: {
            fontFamily: "Mont Demo",
            color: Colors.splashDarkPurple,
            fontSize: 18,
            marginBottom: 40,
            maxWidth: 445,
        },

        afterpartyToken: {
            width: "100%",
            // minHeight: 830,
            backgroundColor: Colors.splashDarkPurple,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 150,
            paddingBottom: 150,
            display: "flex",
            justifyContent: "center",
        },
        afterpartyToken_md: {
            paddingBottom: 0,
        },
        afterpartyTokenInner: {
            display: "flex",
            alignItems: "center",
            maxWidth: 1030,
        },
        afterpartyTokenInner_md: {
            flexDirection: "column",
            justifyContent: "center",
        },
        afterpartyTokenInfoRight: {
            paddingLeft: 70,
        },
        afterpartyTokenInfoRight_md: {
            paddingLeft: 0,
            textAlign: "center",
        },
        afterpartyTokenImageStyle: {
            maxWidth: 357,
            width: "100%",
        },
        afterpartyTokenTitle: {
            fontFamily: "Nyata",
            color: "white",
            fontSize: 53,
            paddingBottom: 25,
        },
        afterpartyTokenDescription: {
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 18,
            marginBottom: 40,
            maxWidth: 445,
        },

        subTitleCard: {
            fontFamily: "Mont Demo",
            fontSize: 20,
            color: "white",
            textAlign: "center",
            marginBottom: 10,
        },
        oneAfterpartyUniverseImage: {
            width: "100%",
            marginBottom: 40,
        },
    };
}
