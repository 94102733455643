import React, { Fragment } from 'react'

import { Carousel } from 'react-responsive-carousel'
import Scroller from './Scroller'
import PlaylistScroller from './PlaylistScroller'
import axios from 'axios'
import ReactPlayer from "react-player"
import { AreaChart, LineChart, PieChart } from 'react-chartkick'
import 'chart.js'
import checkboxAnimation from "../assets/52645-checkmark.json"

import Input from '../elements/Input'
import Textarea from '../elements/Textarea'
import Span from '../elements/Span'
import Panel from '../elements/Panel'
import Dropdown from '../elements/Dropdown'
import Image from '../elements/Image'
import Icon from '../elements/Icon'
import Button from '../elements/Button'
import Modal from '../elements/Modal'
import DebugBox from '../elements/DebugBox'
import Checkbox from '../elements/Checkbox'
import Spinner from '../elements/Spinner'

import Div from '../components/Div'
import PageNotFound from '../components/PageNotFound'

import Utils from '../modules/Utils'
import Colors from '../modules/Colors'
import Api from '../modules/Api'
import Styles from '../modules/Styles'
import StylesPlatform from '../modules/StylesPlatform'

export default class DashboardNewSoin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: "",
        dirty: false,
        nftStyle: 0,
        title: "",
        description: "",
        notesForReviewer: "",
        artist: props.mediaItem ? props.mediaItem : {},
        uploadProgress: "",

        mediaItem: {
            style: "",
        },

        submitForReviewSuccess: false,

        isDesignChecked: false,
        isRevenueChecked: false,
        isPublishChecked: false,

        style: "pop",
        rarity: "original",

        startingBid: "",

        selectedLevel: {value: "original", text: "Original"},

        selectedDropWeek: null,
        selectedDropDay: null,
        selectedDuration: null,

        videos: {
            generic: {
                //pop: "/images/renders/apnft_pop_2assets.mp4",
                pop: "/images/renders/apnft_pop_5assets.mp4",
                hiphop: "/images/renders/ap-diva_2assets_square.mp4",
                edm: "/images/renders/ap-dj_2assets_square.mp4",
                popextra: "/images/renders/apnft_pop_5assets.mp4",
            },
            tropix: {
                pop: "/images/renders/tropix_pop_5assets.mp4",
                hiphop: "/images/renders/tropix_diva_2assets.mp4",
                edm: "/images/renders/tropix_dj_2assets_square.mp4",
                popextra: "/images/renders/tropix_pop_5assets.mp4",
            },
        },
        nftState: "generic",
        uploads: {},
        rarities: {
            prices: {
                small: [5000, 500, 200, 50],
                medium: [5000, 500, 200, 50],
                large: [5000, 500, 200, 50],
            },
            prints: {
                small: [1, 3, 50, 500],
                medium: [1, 5, 75, 1500],
                large: [1, 10, 100, 5000],
            },
        },

        experiences: {
            liveStreams: "0",
            liveAudioSessions: "0",
        },
        releaseSize: "",

        designPanelFinished: {
            titleFinished: false,
            descriptionFinished: false,
            frontImageFinished: false,
            backImageFinished: false,
        },
        revenuePanelFinished: {
            releaseSizeFinished: false,
        },
        publishPanelFinished: {
            requestedDropWeekFinished: false,
            requestedDropDayFinished: false,
            durationFinished: false,
        },

        autoPublish: false,
        showStyleModal: false,
        showDeezerModal: false,

        otherStyleNft: null,

        showRemoveFromReviewButton: false,

        artistSearchText: "",
        artistsFromSearch: [],
        artistItems: [],
        artistDeezer: {name: ""},
        artistTrack: null,
        artistItem: null,

        isTicketOnSale: false,
        isExclusiveMerchandise: false,

        deezerArtistLoading: false,

        // TODO
        artists: [],
        artist: {},
        artistProjects: [],
        artist_id: 22,
        listingCrc: null,
        artist_name: "Tropix",
        artist_image_url: "/images/tropix_artist_image.jpg",
    };

    this.levelOptions = [
        {
            value: "silver",
            text: "Still is the Night",
        },
        {
            value: "gold",
            text: "People Don't Need",
        },
        {
            value: "platinum",
            text: "Glimmer",
        },
    ];

    this.dropWeekOptions = [
        {text: "Select requested drop week"},
        {value: "-1", text: "As soon as possible"},
        {value: "1", text: "Week of March 22th"},
        {value: "2", text: "Week of March 29th"},
        {value: "3", text: "Week of April 5th"},
        {value: "4", text: "Week of April 12th"},
        {value: "5", text: "Week of April 19th"},
    ];

    this.dropDayOptions = [
        {text: "Select a drop day"},
        {value: "1", text: "Monday"},
        {value: "2", text: "Tuesday"},
        {value: "3", text: "Wednesday"},
        {value: "4", text: "Thursday"},
        {value: "5", text: "Friday"},
    ];

    this.durationOptions = [
        {text: "Select auction duration"},
        {value: "1", text: "1 week"},
        {value: "2", text: "2 week"},
        {value: "3", text: "3 week"},
    ];

    this.otherStyleOptions = [
        {id:1, title:"Pop Extra", imageUrl:"/images/nft-thumbnails/0220.png",},
        {id:2, title:"Bling", imageUrl:"/images/nft-thumbnails/Divas-2.png",},
        {id:3, title:"Tricolor", imageUrl:"/images/nft-thumbnails/3Colors.png",},
        {id:4, title:"Navy", imageUrl:"/images/nft-thumbnails/TXT-1.png",},
        {id:5, title:"Onyx", imageUrl:"/images/nft-thumbnails/BLK.png",},
        {id:6, title:"Gliterrati", imageUrl:"/images/nft-thumbnails/Divas-1-2.png",},
        //{id:7, title:"Design Marketplace...", imageUrl:"/images/nft-thumbnails/Gold1.png",},
    ];

    this.sigRef = React.createRef();
    console.log("PROPS", this.props);
    console.log("sort_order", Utils.get(this.props.mediaItem, "sort_order"));

  }

    componentDidMount() {
        this.setWindowDirty(false);

        let pathname = this.props.history.location.pathname;
        const crc = pathname.split('/')[2];

        // Api.getNewNft(crc, (response) => {
        //     console.log("response >>>", response);
        //     const data = response.data;
        //     this.setState({
        //         title: data.title,
        //         description: data.description,
        //         notesForReviewer: data.notesForReviewer,
        //         style: data.style,
        //         rarity: data.rarity,
        //         startingBid: data.startingBid,
        //         selectedLevel: data.selectedLevel,
        //         selectedDropWeek: data.selectedDropWeek,
        //         selectedDropDay: data.selectedDropDay,
        //         selectedDuration: data.selectedDuration,
        //         experiences: data.experiences,
        //     });
        // });

        document.addEventListener('keydown', this.keydownHandler.bind(this));

        window.onbeforeunload = () => {
            if(window.isDirty) {
                return "Are you sure you want to leave?";
            }
        };
    }

  componentWillUnmount() {
      console.log("Unmount");
      this.setWindowDirty(false);
      document.removeEventListener('keydown',this.keydownHandler);
      window.onbeforeunload = () => {};

      if(this.props.apiLoadStreaming && this.state.dirty) {
          this.props.apiLoadStreaming();
      }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
      if(!newProps.user.email && newProps.userLoaded) {
          newProps.history.push("/404");
      }

      let tempState = {};
      if(newProps["mediaItem"]) {
          tempState["mediaItem"] = newProps["mediaItem"];
          tempState["title"] = tempState["mediaItem"]['title'];
          if(tempState["mediaItem"]['artist_name']) {
              tempState["artist_name"] = tempState["mediaItem"]['artist_name'];
          }
          if(tempState["mediaItem"]['artist_image_url']) {
              tempState["artist_image_url"] = tempState["mediaItem"]['artist_image_url'];
          }
          tempState["description"] = tempState["mediaItem"]['description'];
          tempState["highlightColor"] = Utils.get(tempState["mediaItem"], "sort_order") != 3000 ? "#6CDCCD" : "#9342E8";
      }
      this.setState(tempState);
  }

  setWindowDirty(value) {
      window.isDirty = value;
  }

  keydownHandler(e) {
    if(e.keyCode===13 && e.ctrlKey) {
        console.log("blackListMode");
        this.setState({blackListMode:true});
    }
  }

  handleNewSoinClick() {
      this.setWindowDirty(value);

      const featured = {};
      this.setState({
              selectedMediaItem: featured,
      }, () => {
        this.props.change('profile', featured);
      });
  }

  handleSaveClick(publish) {
      const st = this.state;

      this.setWindowDirty(value);
      var newListing = true; // TODO: Check if existing
      if(st.title.length == 0) {
          alert("Title is empty");
          return;
      }
      let listing = {
        title: st.title,
        description: st.description,
        listing_status: 1,
        //publish: publish ? publish : 0
      };
      if(st.nftRenderUrl) {
          listing["render_url"] = st.nftRenderUrl;
      }
      console.log("SAVING", listing);
      if(newListing) {
          Api.postListing(listing, () => {
                  this.setState({txtStatus:"Saved Draft of Listing"});
          });
      } else {
          //Api.putListing(listingId, listing, () => {});
      }
  }

  artistSearchChange(event) {
      this.setState({artistSearchText: event.target.value});
  }

  searchArtists(artist) {
      const st = this.state;
      this.setState({
          artistsFromSearch: [],
          artistItems: [],
      });

      Api.apiLoadArtists(st.artistSearchText, (response) => {
          console.log("response >>>", response);
          this.setState({
              artistsFromSearch: response.data,
          });
      });
  }

  selectArtist(artist) {
      this.setState({
          deezerArtistLoading: true,
          artistDeezer: artist,
      });

      Api.apiLoadArtist(artist.id, (response) => {
          console.log("response.data >>>", response.data);
          this.setState({
              deezerArtistLoading: false,
              artistItems: response.data,
          });
      });
  }

  selectArtistTrack(track, artistItem) {
      let uploads = this.state.uploads;
      console.log("track >>>", track);
      uploads["deezer"] = {image_url: artistItem.cover_medium};

      this.setState({
          artistTrack: track,
          artistItem: artistItem,
          uploads: uploads,
          showDeezerModal: false,
      });
  }

  validateNftForm() {
      const st = this.state;

      if(this.checkDesignPanelFinished() &&
         this.checkRevenuePanelFinished() &&
         this.checkPublishPanelFinished()
      ) {
          return true;
      }

      return false;
  }

  isValid() {
      const st = this.state;
      const isValid = st.isDesignChecked && st.isRevenueChecked && st.isPublishChecked;

      return isValid;
  }

  numSectionsComplete() {
      const st = this.state;
      return st.isDesignChecked + st.isRevenueChecked + st.isPublishChecked;
  }

  handleSaveSubmitClick(publish) {
      const st = this.state;

      this.setState({submitForReviewSuccess: true});

      var newListing = !st.listingCrc;

      if(!this.validateNftForm()) {
          return;
      }

      let listing = {
        title: st.title,
        description: st.description,
        listing_status: 2,
        publish_immediately: st.autoPublish,
        listing_style: st.style,
        num_live_streams: st.experiences.liveStreams,
        num_live_audio_sessions: st.experiences.liveAudioSessions,
        release_size: st.releaseSize,
        artist_id: st.artist_id,
        rarities: st.rarities,
        request_drop_week: st.selectedDropWeek.value,
        request_drop_day: st.selectedDropDay.value,
        request_auction_duration: st.selectedDuration.value,
        reviewer_notes: st.notesForReviewer,
        artist_name: st.artist_name,
        artist_image_url: st.artist_image_url,
      };
      if(st.nftRenderUrl) {
          listing["render_url"] = st.nftRenderUrl;
      }
      console.log("SAVING", listing);
      if(newListing) {
          Api.postListing(listing, () => {
            this.setState({
                txtStatus: "Submitted Listing for Review",
                showRemoveFromReviewButton: true,
            });
          });
      } else {
          Api.putListing(listingId, listing, () => {
              this.setState({
                  showRemoveFromReviewButton: true,
              });
          });
      }
  }

  handleRemoveReviewClick() {
      const featured = {};
      this.setState({
              selectedMediaItem: featured,
      }, () => {
        this.props.change('profile', featured);
      });
  }

  handlePostClick() {
      const featured = {};
      this.setState({
              selectedMediaItem: featured,
      }, () => {
        this.props.change('profile', featured);
      });
  }

    checkDesignPanelFinished() {
        const st = this.state;

        let finished = true;
        for(const key in st.designPanelFinished) {
            if(!st.designPanelFinished[key]) {
                finished = false;
            }
        }

        this.setState({isDesignChecked: finished});
        return finished;
    }

    checkRevenuePanelFinished() {
        const st = this.state;

        let finished = true;
        for(const key in st.revenuePanelFinished) {
            if(!st.revenuePanelFinished[key]) {
                finished = false;
            }
        }

        this.setState({isRevenueChecked: finished});
        return finished;
    }

    checkPublishPanelFinished() {
        const st = this.state;

        let finished = true;
        for(const key in st.publishPanelFinished) {
            if(!st.publishPanelFinished[key]) {
                finished = false;
            }
        }

        this.setState({isPublishChecked: finished});
        return finished;
    }

  changeTitle(event) {
      this.setWindowDirty(true);
      const st = this.state;
      const title = event.target.value;

      let designPanelFinished = st.designPanelFinished;

      designPanelFinished.titleFinished = title.length > 2;

      this.setState({title: title}, () => {
          this.checkDesignPanelFinished();
      });
  }
  changeDescription(event) {
      this.setWindowDirty(true);
      const st = this.state;
      const description = event.target.value;

      let designPanelFinished = st.designPanelFinished;

      designPanelFinished.descriptionFinished = description.length > 2;

      this.setState({description: description}, () => {
          this.checkDesignPanelFinished();
      });
  }

  changeNotesForReviewer(event) {
      this.setWindowDirty(true);
      this.setState({notesForReviewer: event.target.value});
  }


  handleChangeStyle(event) {
      this.setWindowDirty(true);
      const val = event.target.selectedIndex;
      console.log("VALUE", val);
      this.setState({nftStyle: val});
  }

  handleChangeLevel(event) {
      this.setWindowDirty(true);
      const val = event.target.selectedIndex;
      console.log("VALUE", event.target[val].value);
      this.setState({level: event.target[val].value});
  }

  handleChangeSubmission(event) {
      this.setWindowDirty(true);
		console.log(event.target.files[0]);
		this.setState({selectedFile: event.target.files[0]});
  }

  handleUploadClick(event) {
      const st = this.state;
      const formData = new FormData();
      formData.append('UploadType', st.uploadType ? st.uploadType : 1);

      if(st.selectedFile) {
          formData.append('File', st.selectedFile);
          formData.append('Filetype', st.selectedFile.type);
          formData.append('Size', st.selectedFile.size);
          formData.append('lastModifiedDate', st.selectedFile.lastModifiedDate);

          console.log('File >>>', st.selectedFile);
          console.log('Filetype >>>', st.selectedFile.type);
          console.log('Size >>>', st.selectedFile.size);
          console.log('lastModifiedDate >>>', st.selectedFile.lastModifiedDate);
      } else if(st.uploadId) {
          formData.append('UploadId', st.uploadId);
      } else {
          alert("No upload Id or file selected");
          return;
      }

      Api.postUploadSellerAsset(formData, (o) => {
        if(!o || (o && o.errors.length > 0)) {
            alert("Upload failed");
        } else {
            this.setState({uploadComplete: o.data}, this.startCheckRenderComplete.bind(this));
        }
      });
  }

  startCheckRenderComplete() {
    if(window.intervalId) {
        clearInterval(window.intervalId);
    }
    var intervalId = setInterval(this.checkRenderComplete.bind(this), 5000);
    //var intervalId = setTimeout(this.checkRenderComplete.bind(this), 120 * 1000);
    // Note: Can't use state, because state gets cleared by React refresh and leaves interval in place
    window.intervalId = intervalId;
    window.progress = 1;

  }

  checkRenderComplete() {
      console.log("Check render complete");
      //this.setState({nftRenderUrl:"/images/lord-huron-world-ender-curves.mp4"});
      const _self = this;
      const uploadId = this.state.uploadComplete["id"];
      Api.getUpload(uploadId, function(o) {
          console.log("IN", o);
          if(o && o.data['render_status'] == 3 && o.data['render_name']) {
              console.log("Render complete", o.data);
              var renderName = o.data['render_name'];
              var path = "/renders/" + renderName;
              console.log("Display soin at path: ", path);
              var tempState = {
                nftRenderUrl:path,
              };
              clearInterval(window.intervalId);
              _self.setState(tempState, function() {
                clearInterval(window.intervalId);
                //window.intervalId = null;
              });

          } else {
              window.progress += 1;
              _self.setState({uploadProgress:" " + parseInt(window.progress/24*100)+"% complete"});
          }
      });

      //this.setState({nftStyle: 2});
  }

    onSelectLevel(selectedLevel) {
        this.setWindowDirty(true);
        this.setState({
            selectedLevel: selectedLevel,
        });
    }

    onSelectDropWeek(selectedDropWeek) {
        this.setWindowDirty(true);
        const st = this.state;

        let publishPanelFinished = st.publishPanelFinished;
        publishPanelFinished.requestedDropWeekFinished = selectedDropWeek.value != 0;

        this.setState({
            selectedDropWeek: selectedDropWeek,
            publishPanelFinished: publishPanelFinished,
        }, () => {
            this.checkPublishPanelFinished()
        });
    }

    onSelectDropDay(selectedDropDay) {
        this.setWindowDirty(true);
        const st = this.state;

        let publishPanelFinished = st.publishPanelFinished;
        publishPanelFinished.requestedDropDayFinished = selectedDropDay.value != 0;

        this.setState({
            selectedDropDay: selectedDropDay,
            publishPanelFinished: publishPanelFinished,
        }, () => {
            this.checkPublishPanelFinished()
        });
    }

    onSelectDuration(selectedDuration) {
        this.setWindowDirty(true);
        const st = this.state;

        let publishPanelFinished = st.publishPanelFinished;
        publishPanelFinished.durationFinished = selectedDuration.value != 0;

        this.setState({
            selectedDuration: selectedDuration,
            publishPanelFinished: publishPanelFinished,
        }, () => {
            this.checkPublishPanelFinished()
        });
    }

    changeStartingBid(event) {
        this.setWindowDirty(true);
        const startingBid = event.target.value;
        this.setState({startingBid: startingBid});
    }

    changeVal(valPath, event) {
        this.setWindowDirty(true);
        console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        this.setState(tempState);
    }

    setupSketch(imgUrl) {
        //var imgUrl = "/images/uploads/upload_51a74e9f67e640946261f9124bc4fcdd.jpg";
        //var parts = imgUrl.split("/");
        //var localUrl = "/images/uploads/" + parts[parts.length-1];
        var config = {
            controls: true,
            //background: localUrl,
            color: '#000',
            size: 3,
            enlargeYourContainer: true,
            stretchImg: true,
        }
        var myBoard = new window.DrawingBoard.Board('sigh', config);
        window.myBoard = myBoard;
    }

    styleChange(style) {
        this.setWindowDirty(true);
        console.log("Change style", style);
        let tempState = {};
        if(style == "popextra") {
            tempState['showStyleModal'] = true;
        } else {
            tempState['otherStyleNft'] = null;
            tempState['style'] = style;
        }
        this.setState(tempState);
    }

    handleCloseStyleModal() {
        this.setState({showStyleModal: false});
    }

    handleCloseDeezerModal() {
        this.setState({showDeezerModal: false});
    }

    rarityChange(rarity) {
        this.setWindowDirty(true);
        this.setState({rarity: rarity});
    }

    releaseSizeChange(releaseSize) {
        const st = this.state;

        this.setWindowDirty(true);

        let revenuePanelFinished = st.revenuePanelFinished;
        revenuePanelFinished.releaseSizeFinished = true;

        this.setState({
            releaseSize: releaseSize,
            isRevenueChecked: true,
            revenuePanelFinished: revenuePanelFinished,
        });
    }

    handleClickResetSig() {
        this.setWindowDirty(true);
        window.myBoard.clearWebStorage();
        window.myBoard.reset({ webStorage: false, history: false, background: true });
    }

    handleDesignUploadChange(event) {
        this.setWindowDirty(true);
        const st = this.state;

		console.log(event.target.files[0]);
        let uploads = st.uploads;
        let image_url = "";
        if(st.uploadType === "front") {
            image_url = "/images/tropix_artist_image.jpg";
        }

        uploads[st.uploadType] = {
            image_url: image_url,
        };
		this.setState({uploads: uploads, nftState:"tropix", selectedFile: event.target.files[0]});
    }

    handleDesignUploadClick(item) {
        this.setWindowDirty(true);
        const st = this.state;
        let designPanelFinished = st.designPanelFinished;

        console.log("CLICK", item);
        if(item['name'] == 'back') {
            designPanelFinished.backImageFinished = true;
            let uploads = st.uploads;
            uploads[item['name']] = {};
            this.setState({uploads: uploads, uploadType: item['name'], designPanelFinished: designPanelFinished}, () => {
                this.setupSketch();
                this.checkDesignPanelFinished();
            });
        } else if(item['name'] == 'deezer') {
            this.setState({showDeezerModal: true});
        } else {
            if(item['name'] == 'front') {
                designPanelFinished.frontImageFinished = true;
            }
            this.setState({uploadType: item['name'], designPanelFinished: designPanelFinished}, () => {
                this.upload.click();
                this.checkDesignPanelFinished();
            });
        }
    }

    onOtherStyleSelect(otherStyleNft) {
        this.setState({
            style: "popextra",
            showStyleModal: false,
            otherStyleNft: otherStyleNft,
        });
    }

    toggleAutoPublish() {
        this.setState({autoPublish: !this.state.autoPublish});
    }

    isImageError(itemIndex) {
        const st = this.state;

        if(!st.designPanelFinished.frontImageFinished && st.submitForReviewSuccess && itemIndex == 0) {
            return Colors.redError;
        }

        if(!st.designPanelFinished.backImageFinished && st.submitForReviewSuccess && itemIndex == 1) {
            return Colors.redError;
        }

        return Colors.purpleLight;
    }

    toggleExclusiveTicketOnSale() {
        const st = this.state;
        this.setState({isTicketOnSale: !st.isTicketOnSale});
    }

    toggleExclusiveMerchandise() {
        const st = this.state;
        this.setState({isExclusiveMerchandise: !st.isExclusiveMerchandise});
    }


    renderFileUpload() {
        return (
                <Div style={ { ...Styles.uploadContainer, ...{} } }>
                    <Div>
                        Upload for Render
                    </Div>
                    <Div style={{margin:20, display:"flex",flexDirection:"row",gap:20,}}>
                        <Div>
                            Render Type:
                            <select>
                                <option value="1">Still Image</option>
                                <option value="4">Animation</option>
                            </select>
                        </Div>
                        <Div>
                            Upload ID
                        </Div>
                        <Div>
                            <Input
                                style={{marginTop:-18}}
                                inputType="text"
                                color="indigo"
                                value={Utils.get(this.state, `uploadId`)}
                                onChange={this.changeVal.bind(this, `uploadId`)}
                                placeholder="Enter Upload ID..."
                            />
                        </Div>
                        <Div>
                            OR
                        </Div>
                        <Div>
                            <input type="file" name="file" onChange={this.handleChangeSubmission.bind(this)} />
                        </Div>
                    </Div>
                    <Div>
                        <Button onClick={this.handleUploadClick.bind(this)}>Start Render</Button>
                    </Div>
                {this.state.selectedFile && !this.state.uploadComplete ?
                    <Div style={Styles.uploadContainer}>
                        <p>Filename: {this.state.selectedFile.name}</p>
                        <p>Filetype: {this.state.selectedFile.type}</p>
                        <p>Size in bytes: {this.state.selectedFile.size}</p>
                        <p>
                            lastModifiedDate:{' '}
                            {this.state.selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                    </Div>
                    :
                    null
                }
                {this.state.selectedFile && this.state.uploadComplete ?
                    <Div>
                        Uploaded {this.state.uploadComplete["name"]}. Rendering sample {this.state.uploadComplete["id"]}{this.state.uploadProgress}
                    </Div>
                    :
                    null
                }

                </Div>

              );
    }

    renderDesignUploads() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const uploadButtons = [
            {name:"front", titles:["Upload","Image"], subtitle:"Front"},
            {name:"back", titles:["Create","Signature"], subtitle:"Back"},
            {name:"extra1", titles:["Upload","Image"], subtitle:"Extra 1"},
            {name:"extra2", titles:["Upload","Image"], subtitle:"Extra 2"},
            {name:"extra3", titles:["Upload","Image"], subtitle:"Extra 3"},
            {name:"music", titles:["Upload",".mp3"], subtitle:"Music"},
            {name:"deezer", titles:["Link to","Track"], subtitle:"Deezer"},
        ];

        let displaySig = st.uploadType == "back" ? null : {display: "none"};
        const sampleMp3 = Utils.get(st.artistTrack, "preview", null);

        return (
            <Div className="nftUploadsContainer" style={{...sty.nftUploadsContainer, ...{paddingTop: 0}}}>
                <Div style={sty.createNftSubtitle}>Uploads</Div>
                <input
                    id="myInput"
                    type="file"
                    ref={(ref) => this.upload = ref}
                    style={{ display: 'none' }}
                    onChange={this.handleDesignUploadChange.bind(this)}
                />

                <Div className="nftUploadImagesContainer" style={sty.nftUploadImagesContainer}>
                    {uploadButtons.map((item, itemIndex) => {
                        const imageUrl = Utils.get(st.uploads[item['name']], "image_url", '');
                        return (
                            <Div key={`uploadBtn-${item['name']}`} className="uploadImagesContainer" style={sty.uploadImagesContainer} onClick={this.handleDesignUploadClick.bind(this, item)}>
                                {st.uploads[item['name']] ? this.renderPinkCheck() : null}
                                <Div
                                    className="uploadImage"
                                    style={{...sty.uploadImage, ...StylesPlatform.cursorPointer, ...{ backgroundImage: `url(${imageUrl})`}, ...{borderColor: this.isImageError(itemIndex)}}}
                                >
                                    {imageUrl.length ?
                                        <ReactPlayer
                                            url={sampleMp3}
                                            width="250px"
                                            height="50px"
                                            playing={false}
                                            controls={true}
                                        />
                                        :
                                        <>
                                            {item['titles'][0]}<br/>
                                            {item['titles'][1]}
                                        </>
                                    }

                                </Div>
                                <Div className="itemName" style={sty.itemName}>{item['subtitle']}</Div>
                            </Div>
                        );
                    })}
                </Div>

                <Div style={displaySig}>
                    <Div className="signatureContainer" style={sty.signatureContainer}>
                        <Div style={{flex: 1}}>
                            <Div style={sty.signatureMessage}>Sign on your phone or touch screen</Div>
                        </Div>
                        <Div id="sigh" ref={this.sigRef} style={{width:400,height:200,border:"1px solid gray", flex: 3, borderRadius:10, overflow:"hidden"}}></Div>
                        <Div style={{flex: 1, display: "flex", justifyContent: "center", flexDirection: "column"}}>
                            <Button
                                color="white"
                                style={{width: "fit-content", marginLeft: "auto"}}
                                onClick={this.handleClickResetSig.bind(this)}
                                noBorder
                            >
                                Reset
                            </Button>
                        </Div>
                    </Div>
                </Div>
            </Div>
        );
    }

    renderDesignPanel() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let nftRenderSample = false;
        let nftRender = st['videos'][st.nftState][st.style];
        const artistAvatarUrl = st.artist_image_url;
        const artistName = st.artist_name;

        const otherStyleBackgroundImage = st.otherStyleNft ? {backgroundImage: `url("${st.otherStyleNft.imageUrl}")`} : null;

        return (
            <Panel
                title="Design"
                checked={st.isDesignChecked}
                style={{marginBottom: 40}}
                checkboxPanel
            >
                <Div
                    className="designPanelContainer"
                    style={sty.designPanelContainer}
                >
                    <Div
                        className="createNftTitleDescStyleContainer"
                        style={sty.createNftTitleDescStyleContainer}
                    >
                        <Div style={{marginTop:25, marginRight:30}} className="nftImageContainer">
                            <Div style={{borderRadius:20, overflow:"hidden"}}>
                                { nftRenderSample ?
                                    <img style={{width:500,height:500}} src={nftRenderSample} />
                                    :
                                    <ReactPlayer
                                        type="video/mp4"
                                        url={nftRender}
                                        width="500px"
                                        height="500px"
                                        playing={true}
                                        controls={false}
                                        loop={true}
                                        muted={true}
                                  />
                                }
                             </Div>
                        </Div>
                        <Div className="titleDescStyleContainer" style={sty.titleDescStyleContainer}>
                            <Div style={sty.createNftSubtitle}>Artist</Div>
                            <Div style={sty.artistContainer}>
                                <Div>
                                    <Image style={sty.artistThumbnail} src={artistAvatarUrl} />
                                </Div>
                                <Div style={sty.artistName}>
                                    {artistName}
                                </Div>
                            </Div>
                            <Div style={sty.createNftSubtitle}>Title</Div>
                            <Input
                                placeholder="Title"
                                value={st.title}
                                onChange={this.changeTitle.bind(this)}
                                color="indigo"
                                isError={st.submitForReviewSuccess && st.title.length < 3}
                            />
                            <Div style={sty.createNftSubtitle}>Description</Div>
                            <Textarea
                                className="nftDescription"
                                placeholder="Description"
                                value={st.description}
                                onChange={this.changeDescription.bind(this)}
                                color="indigo"
                                isError={st.submitForReviewSuccess && st.description.length < 3}
                            />

                            <Div className="nftStyleOuterContainer">
                                <Div style={sty.createNftSubtitle}>Style</Div>
                                <Div className="nftStyleContainer" style={sty.nftStyleContainer}>
                                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.styleChange.bind(this, "pop")}>
                                        <Div className="tabContainerImage" style={{...sty.tabContainerImage, ...{backgroundImage: "url('/images/Purp.png')"}, ...{border: "none"}}} />
                                        <Div className="itemName" style={sty.itemName}>Pop</Div>
                                        {st.style === "pop" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                                    </Div>
                                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.styleChange.bind(this, "hiphop")}>
                                        <Div className="tabContainerImage" style={{...sty.tabContainerImage, ...{backgroundImage: "url('/images//DvasBig.png')"}, ...{border: "none"}}} />
                                        <Div className="itemName" style={sty.itemName}>Hip Hop</Div>
                                        {st.style === "hiphop" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                                    </Div>
                                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.styleChange.bind(this, "edm")}>
                                        <Div className="tabContainerImage" style={{...sty.tabContainerImage, ...{backgroundImage: "url('/images//DJ_dark-bg.png')"}, ...{border: "none"}}} />
                                        <Div className="itemName" style={sty.itemName}>EDM</Div>
                                        {st.style === "edm" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                                    </Div>
                                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.styleChange.bind(this, "popextra")}>
                                        {otherStyleBackgroundImage ?
                                            <Div className="tabContainerImage" style={{...sty.tabContainerImage, ...otherStyleBackgroundImage, ...{border: "none"}}} />
                                            :
                                            <Div className="tabContainerImage" style={{...sty.tabContainerImage, ...{border: "2px solid white"}}}>
                                                {this.renderPlusSign()}
                                            </Div>
                                        }
                                        <Div className="itemName" style={sty.itemName}>{st.otherStyleNft ? st.otherStyleNft.title : "Other"}</Div>
                                        {st.style === "popextra" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    {this.renderDesignUploads()}
                    {this.renderFileUpload()}
                </Div>

            </Panel>
        )
    }

    renderExperiencesPanel() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Panel
                title="Experiences"
                style={{marginBottom: 40}}
            >
                <Div
                    className="experiencesPanelContainer"
                    style={sty.experiencesPanelContainer}
                >
                    <Div style={{flex:3, paddingRight: 40,}}>
                        <Div style={{display:"flex"}}>
                            <Div style={{marginTop:14,marginRight:6,flex:2}}>
                                Number of Live Streams with Artists
                            </Div>
                            <Div style={{flex:1}}>
                                <Input
                                    color="indigo"
                                    value={Utils.get(st, 'experiences.liveStreams', "")}
                                    onChange={this.changeVal.bind(this, 'experiences.liveStreams')} />
                            </Div>
                        </Div>
                        <Div style={{display:"flex"}}>
                            <Div style={{marginTop:14,marginRight:6,flex:2}}>
                                Number of Live Audio Sessions with Artists
                            </Div>
                            <Div style={{flex:1}}>
                                <Input
                                    color="indigo"
                                    value={Utils.get(st, 'experiences.liveAudioSessions', "")}
                                    onChange={this.changeVal.bind(this, 'experiences.liveAudioSessions')} />
                            </Div>
                        </Div>
                        <Div style={{display: "flex", marginBottom: 15}}>
                            <Checkbox checked={st.isTicketOnSale} style={{marginRight: 10}} color="indigo" onClick={this.toggleExclusiveTicketOnSale.bind(this)} /> Exclusive ticket onsales
                        </Div>
                        <Div style={{display: "flex"}}>
                            <Checkbox checked={st.isExclusiveMerchandise} style={{marginRight: 10}} color="indigo" onClick={this.toggleExclusiveMerchandise.bind(this)} /> Exclusive merchandise
                        </Div>
                    </Div>
                    <Div style={{flex:2, padding: "0px 40px",}}>
                        <Div style={Styles.subhead}>Suggested Experiences</Div>

                        <Div>• Studio Session</Div>
                        <Div>• Debuting New Demos</Div>
                        <Div>• Album Release Party</Div>
                        <Div>• Clubhouse style Q&As with other artist friends</Div>
                        <Div>• Early Ticket access for your NFT holders</Div>
                        <Div>• Merch drops exclusively for NFT holders</Div>
                    </Div>
                </Div>
            </Panel>
        );
    }

    renderReleaseSize() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{marginBottom: 40}}>
                <Div style={{...sty.createNftSubtitle, ...{marginBottom: 20}}}>Select Release Size</Div>
                <Div className="nftReleaseSizeContainer" style={sty.nftReleaseSizeContainer}>
                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.releaseSizeChange.bind(this, "small")}>
                        <Div className="tabReleaseSizeImage" style={{...sty.tabReleaseSizeImage, ...{backgroundImage: "url('/images/releaseSmall.png')"}, ...{border: "none"}}} />
                        <Div className="itemName" style={sty.itemName}>Small</Div>
                        {st.releaseSize === "small" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                    </Div>
                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.releaseSizeChange.bind(this, "medium")}>
                        <Div className="tabReleaseSizeImage" style={{...sty.tabReleaseSizeImage, ...{backgroundImage: "url('/images/releaseMedium.png')"}, ...{border: "none"}}} />
                        <Div className="itemName" style={sty.itemName}>Medium</Div>
                        {st.releaseSize === "medium" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                    </Div>
                    <Div className="pinkTabContainer" style={{...sty.pinkTabContainer, ...StylesPlatform.cursorPointer}} onClick={this.releaseSizeChange.bind(this, "large")}>
                        <Div className="tabReleaseSizeImage" style={{...sty.tabReleaseSizeImage, ...{backgroundImage: "url('/images/releaseLarge.png')"}, ...{border: "none"}}} />
                        <Div className="itemName" style={sty.itemName}>Large</Div>
                        {st.releaseSize === "large" ? <Div className="pinkLine" style={sty.pinkLine}></Div> : null}
                    </Div>
                </Div>
            </Div>
        )
    }

    renderRevenuePanel() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        let grossRevenue = 70000;
        let startingPrice = 5000;
        let original = [1,5000];
        grossRevenue = 0;
        const prices = st['rarities']['prices'][st.releaseSize];
        const prints = st['rarities']['prints'][st.releaseSize];
        for(var idx in prices) {
            const price = prices[idx];
            const print = prints[idx];
            grossRevenue += price * print;
        }
        const originalImage = st.nftState == "generic" ? "url('/images/ap-original.png')" : "url('/images/tropix-original.png')";

        return (
            <Panel
                title="Revenue"
                checked={st.isRevenueChecked}
                style={{marginBottom: 40}}
                checkboxPanel
            >
                <Div className="revenuePanelContainer" style={sty.revenuePanelContainer}>
                    <Div className="raritiesContainer" style={sty.raritiesContainer}>

                        {this.renderReleaseSize()}

                        {st.releaseSize.length ?
                            <>
                                <Div className="createNftSubtitleContainer">
                                    <Div style={{...sty.createNftSubtitle, ...{marginTop: 20, marginBottom: 20}}}>Rarities</Div>
                                </Div>
                                <Div className="nftRaritiesContainer" style={sty.nftRaritiesContainer}>
                                    <Div className="rarityTabContainerCol1" style={{...sty.rarityTabContainer, ...{marginLeft:0, paddingTop:160}}}>
                                        <Div style={{textAlign:"right", width: "100%", marginTop: 22, marginBottom:48}}># of Prints</Div>
                                        <Div style={{textAlign:"right", width: "100%",}}>Price per Print</Div>
                                    </Div>
                                    <Div className="rarityTabContainer" style={{...sty.rarityTabContainer, ...{}}} onClick={this.rarityChange.bind(this, "original")}>
                                        <Div style={{...sty.tabContainerImage, ...{backgroundColor:"#ECECED", backgroundImage: originalImage}}}></Div>
                                        <Div className="itemName" style={sty.itemName}>1 of 1</Div>
                                        <Div className="itemSubName" style={sty.itemSubName}></Div>
                                        <Div style={sty.printsContainer}>
                                            <Input
                                                className="originalQuantity"
                                                color="indigo"
                                                value={Utils.get(st, `rarities.prints.${st.releaseSize}.0`)}
                                                onChange={this.changeVal.bind(this, `rarities.prints.${st.releaseSize}.0`)}
                                            />
                                        </Div>
                                        <Div style={sty.priceContainer}>
                                            <Input
                                                inputType="text"
                                                inputText="$"
                                                color="indigo"
                                                value={Utils.get(st, `rarities.prices.${st.releaseSize}.0`)}
                                                onChange={this.changeVal.bind(this, `rarities.prices.${st.releaseSize}.0`)}
                                                placeholder="Price"
                                            />
                                        </Div>
                                    </Div>
                                    <Div className="nonOriginalRartitiesContainer" style={sty.nonOriginalRartitiesContainer}>
                                        {false ?
                                            <Div className="raritiesDropdownContainer" style={sty.raritiesDropdownContainer}>
                                                <Dropdown
                                                    style={{marginBottom: 10, marginLeft: 30, marginRight: 30}}
                                                    selectStyle={{width: "100%"}}
                                                    selected={st.selectedLevel}
                                                    onSelectOption={this.onSelectLevel.bind(this)}
                                                    options={this.levelOptions}
                                                    color="indigo"
                                                />
                                            </Div>
                                            :
                                            null
                                        }

                                        <Div style={{display: "flex",}}>
                                            <Div className="rarityTabContainer" style={sty.rarityTabContainer} onClick={this.rarityChange.bind(this, "platinum")}>
                                                <Div style={{...sty.tabContainerImage, ...{backgroundColor:"#ECECED", backgroundImage: "url('/images/nft-thumbnails/Plat-emissive.jpg')"}}}></Div>
                                                <Div className="itemName" style={sty.itemName}>Platinum</Div>
                                                <Div style={sty.printsContainer}>
                                                    <Input
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prints.${st.releaseSize}.1`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prints.${st.releaseSize}.1`)} />
                                                </Div>
                                                <Div style={sty.priceContainer}>
                                                    <Input
                                                        inputType="text"
                                                        inputText="$"
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prices.${st.releaseSize}.1`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prices.${st.releaseSize}.1`)}
                                                        placeholder="Price"
                                                    />
                                                </Div>
                                            </Div>
                                            <Div className="rarityTabContainer" style={sty.rarityTabContainer} onClick={this.rarityChange.bind(this, "gold")}>
                                                <Div style={{...sty.tabContainerImage, ...{backgroundImage: "url('/images/nft-thumbnails/Gold.png')"}}}></Div>
                                                <Div className="itemName" style={sty.itemName}>Gold</Div>
                                                <Div style={sty.printsContainer}>
                                                    <Input
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prints.${st.releaseSize}.2`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prints.${st.releaseSize}.2`)} />
                                                </Div>
                                                <Div style={sty.priceContainer}>
                                                    <Input
                                                        inputType="text"
                                                        inputText="$"
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prices.${st.releaseSize}.2`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prices.${st.releaseSize}.2`)}
                                                        placeholder="Price"
                                                    />
                                                </Div>
                                            </Div>
                                            <Div className="rarityTabContainer" style={sty.rarityTabContainer} onClick={this.rarityChange.bind(this, "silver")}>
                                                <Div style={{...sty.tabContainerImage, ...{backgroundColor:"#ECECED", backgroundImage: "url('/images/silver.png')"}}}></Div>
                                                <Div className="itemName" style={sty.itemName}>Silver</Div>
                                                <Div style={sty.printsContainer}>
                                                    <Input
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prints.${st.releaseSize}.3`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prints.${st.releaseSize}.3`)} />
                                                </Div>
                                                <Div style={sty.priceContainer}>
                                                    <Input
                                                        inputType="text"
                                                        inputText="$"
                                                        color="indigo"
                                                        value={Utils.get(st, `rarities.prices.${st.releaseSize}.3`)}
                                                        onChange={this.changeVal.bind(this, `rarities.prices.${st.releaseSize}.3`)}
                                                        placeholder="Price"
                                                    />
                                                </Div>
                                            </Div>
                                        </Div>

                                    </Div>

                                    <Div style={{paddingTop: 147, paddingLeft: 50}}>
                                        <Div style={{display:"flex"}}>
                                            <Div style={sty.revenueTitleCol}>Gross Revenue</Div>
                                            <Div style={sty.revenueCol}>${Utils.commas(parseInt(grossRevenue))}</Div>
                                            <Div style={sty.percentCol}></Div>
                                         </Div>
                                        <Div style={{display:"flex"}}>
                                            <Div style={sty.revenueTitleCol}>Less NFT Design Fee</Div>
                                            <Div style={sty.revenueCol}>(${Utils.commas(parseInt(grossRevenue*.1))})</Div>
                                            <Div style={sty.percentCol}>10%</Div>
                                         </Div>
                                        <Div style={{display:"flex", borderBottom: "1px solid white", marginBottom:10,}}>
                                            <Div style={sty.revenueTitleCol}>Less Afterparty Platform</Div>
                                            <Div style={sty.revenueCol}>(${Utils.commas(parseInt(grossRevenue*.2))})</Div>
                                            <Div style={sty.percentCol}>20%</Div>
                                         </Div>
                                        <Div style={{display:"flex", fontWeight:"bold",}}>
                                            <Div style={{flex:3}}>Total to Creator</Div>
                                            <Div style={sty.revenueCol}>${Utils.commas(parseInt(grossRevenue*.7))}</Div>
                                            <Div style={sty.percentCol}></Div>
                                         </Div>
                                    </Div>

                                </Div>
                            </>
                            :
                            null
                        }
                    </Div>

                </Div>
            </Panel>
        )
    }

    renderPublishPanel() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Panel
                title="Publish"
                checked={st.isPublishChecked}
                style={{marginBottom: 60}}
                checkboxPanel
            >
                <Div style={{paddingTop: 60, paddingLeft: 70, paddingBottom: 40, paddingRight: 180}}>
                    {/* <DebugBox
                        show={st}
                        /> */}
                    <Div style={sty.timingContainer}>
                        <Div className="timingTextDropdownContainer" style={sty.timingTextDropdownContainer}>
                            <Div style={{fontSize: 18, marginRight: "auto"}}>Requested Drop Week</Div>
                            <Dropdown
                                selectStyle={{width: 400}}
                                selected={st.selectedDropWeek}
                                onSelectOption={this.onSelectDropWeek.bind(this)}
                                options={this.dropWeekOptions}
                                color="indigo"
                                isError={st.submitForReviewSuccess && st.selectedDropWeek.value == 0}
                            />
                        </Div>
                        <Div className="timingTextDropdownContainer" style={sty.timingTextDropdownContainer}>
                            <Div style={{fontSize: 18, marginRight: "auto"}}>Requested Drop Day</Div>
                            <Dropdown
                                selectStyle={{width: 400}}
                                selected={st.selectedDropDay}
                                onSelectOption={this.onSelectDropDay.bind(this)}
                                options={this.dropDayOptions}
                                color="indigo"
                                isError={st.submitForReviewSuccess && st.selectedDropDay.value == 0}
                            />
                        </Div>
                        <Div className="timingTextDropdownContainer" style={sty.timingTextDropdownContainer}>
                            <Div style={{fontSize: 18, marginRight: "auto"}}>Auction Duration</Div>
                            <Dropdown
                                selectStyle={{width: 400}}
                                selected={st.selectedDuration}
                                onSelectOption={this.onSelectDuration.bind(this)}
                                options={this.durationOptions}
                                color="indigo"
                                isError={st.submitForReviewSuccess && st.selectedDuration.value == 0}
                            />
                        </Div>
                    </Div>

                    <Div className="notesForReviewerContainer" style={sty.notesForReviewerContainer}>
                        <Div style={{fontSize: 18, marginRight: 30, whiteSpace: "nowrap", width: 270, flexShrink: 0,}}>
                            Notes for Reviewer (optional)
                        </Div>
                        <Textarea className="notesForReviewer" onChange={this.changeNotesForReviewer.bind(this)} placeholder="Notes for reviewer..." color="indigo" />
                    </Div>

                    <Div style={sty.sectionsComplete}>{this.numSectionsComplete()} / 3 Sections complete</Div>
                    <Button disabled={false} color="pink" style={{marginLeft: "auto", maxWidth: 250, width: "100%"}} onClick={this.handleSaveSubmitClick.bind(this, true)}>
                        Submit for Review
                    </Button>
                    <Div style={{display: "flex", alignItems: "center", marginTop: 15, marginBottom: 15}}>
                        <Div style={{marginLeft: "auto", marginTop: 3}}>Automatically publish NFT after reviewed</Div>
                        <Checkbox
                            checked={st.autoPublish}
                            style={{marginLeft: 10}}
                            onClick={this.toggleAutoPublish.bind(this)}
                        />
                    </Div>

                    {!this.isValid() && st.submitForReviewSuccess ?
                        <Div className="missingItemsText" style={sty.missingItemsText}>
                            You are missing some required items, all missing items are highlighted in red
                        </Div>
                        :
                        null
                    }

                    {st.showRemoveFromReviewButton ?
                        <Button
                            disabled={false}
                            color="white"
                            style={{marginTop: 60, marginBottom: 40, marginLeft: "auto", maxWidth: 250}}
                            onClick={this.handleRemoveReviewClick.bind(this)}
                            noBorder
                        >
                            Remove from Review
                        </Button>
                        :
                        null
                    }

                    <Div style={{display: "flex", paddingBottom: 20}}>
                        <Div style={{fontSize: 18, marginRight: 30, whiteSpace: "nowrap", width: 270}}>
                            Notes from Reviewer
                        </Div>
                        {/* {st.txtStatus} */}
                       <Div style={{fontWeight: "bold", fontSize: 18}}>None yet</Div>
                    </Div>


                    <Button disabled color="white" style={{marginTop: 20, marginLeft: 300, maxWidth: 250}} onClick={this.handlePostClick.bind(this)} noBorder>
                        Publish
                    </Button>

                </Div>
            </Panel>
        )
    }

    renderPinkCheck() {
        const sty = this.styles;
        return (
            <Div className="pinkCheck" style={sty.pinkCheck}>
                <Icon icon="check" size={12} color="white" style={null} />
            </Div>
        )
    }

    renderPlusSign() {
        const sty = this.styles;
        return (
            <Div className="plusSign" style={sty.plusSign}>
                <Icon icon="plus" size={16} color="white" style={null} />
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let nftRenderSample;
        if(st.nftRenderUrl) {
            nftRenderSample = st.nftRenderUrl;
        }

        return (
            <Div
                style={sty.dashboardNewSoinComponent}
                className="dashboardNewSoinComponent"
            >
                <Div className="dashboardNewSoinInnerContainer" style={sty.dashboardNewSoinInnerContainer}>
                    <Div>
                        <Div className="createNftTitle" style={sty.createNftTitle}>Create an Afterparty NFT</Div>
                        <Div style={sty.createNftDescription}>
                            <Div style={sty.paragraph}>NFTs completely change the relationship creators can have with their biggest fans. Join the revolution and create your own custom NFT in just a few minutes without needing any crypto knowledge. </Div>
                            <Div style={sty.paragraph}>At Afterparty, we do much more than make creating an NFT easy and fast -- we provide a way to make your NFT more than just a collectible. Your NFT becomes a way for fans to invest in your career and support your artistic process. The NFT can also be a ticket into an event or a show on Afterparty’s event platform, or offer access to unique content and merchandise exclusively for your collectors.</Div>

                            <Div style={sty.paragraph}>Create an <b>Afterparty NFT</b> now to connect with your most engaged fans and savvy collectors. Let them be part of your artistic world.</Div>
                        </Div>
                        {this.renderDesignPanel()}
                        {this.renderExperiencesPanel()}
                        {this.renderRevenuePanel()}
                        {this.renderPublishPanel()}
                    </Div>

                    <Div style={{display: "flex", marginBottom: 40}}>
                        <Button color="purpleTextWhiteButton" style={{marginLeft: "auto", maxWidth: 250}} onClick={this.handleSaveClick.bind(this)} noBorder>
                            Cancel
                        </Button>
                        <Button color="pink" style={{marginLeft: 20, maxWidth: 250}} onClick={this.handleSaveClick.bind(this)}>
                            Preview
                        </Button>
                        <Button color="pink" style={{marginLeft: 20, maxWidth: 250}} onClick={this.handleSaveClick.bind(this)}>
                            Save
                        </Button>
                    </Div>

                    {st.showStyleModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.handleCloseStyleModal.bind(this)}
                            className="otherNftStyleModal"
                            title="Popular NFT Styles"
                            color="indigo"
                        >
                            <Div className="modalImageContainer" style={sty.modalImageContainer}>
                                {this.otherStyleOptions.map((otherStyleOption, index) => {
                                    return (
                                        <Div key={index} style={{marginBottom: 12,}}>
                                            <Div
                                                className="modalImage"
                                                style={{...sty.modalImage, ...StylesPlatform.cursorPointer}}
                                                onClick={this.onOtherStyleSelect.bind(this, otherStyleOption)}
                                            >
                                              <Image style={{width:200, height:150,}} src={otherStyleOption['imageUrl']} />
                                            </Div>
                                             <Div style={{textAlign:"center"}}>{otherStyleOption['title']}</Div>
                                        </Div>
                                    )
                                })}
                                <Div>
                                    <Div
                                        className="modalImage"
                                        style={{...sty.modalText, ...StylesPlatform.cursorPointer}}
                                        onClick={this.onOtherStyleSelect.bind(this)}
                                    >
                                          <Div style={{fontSize: 10}}>Go to</Div>
                                          <Div>Design Marketplace</Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Modal>
                        :
                        null
                    }

                    {st.showDeezerModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.handleCloseDeezerModal.bind(this)}
                            className="deezerModal"
                            title="Link To Track With cozyboy"
                            color="indigo"
                        >
                            <Div className="modalDeezerContainer" style={sty.modalDeezerContainer}>
                                <Div className="artistSearchContainer" style={sty.artistSearchContainer}>
                                    <Input
                                        className="artistSearchInput"
                                        value={st.artistSearchText}
                                        onChange={this.artistSearchChange.bind(this)}
                                        placeholder="Search Deezer artists..."
                                        style={{maxWidth: 280, width: "100%"}}
                                        color="indigo"
                                    />
                                    <Button
                                        style={{height: "fit-content", marginLeft: 20, minWidth: 120}}
                                        onClick={this.searchArtists.bind(this)}
                                    >
                                        Search
                                    </Button>
                                </Div>

                                {st.artistDeezer.name ? <Div style={{marginTop: 5, marginBottom: 10}}>Showing {st.artistDeezer.name}'s albums:</Div> : null}

                                {st.artistsFromSearch.length || st.artistItems.length ?
                                    <Div className="artistsFromSearchContainer" style={sty.artistsFromSearchContainer}>
                                        {st.deezerArtistLoading ?
                                            <Spinner style={{margin: "auto"}} message="Loading artist albums..." />
                                            :
                                            null
                                        }

                                        {st.artistsFromSearch.map((artist, artistIndex) => {
                                            if(st.artistItems.length || st.deezerArtistLoading || artistIndex >= 6) {
                                                return null;
                                            }

                                            const artistId = artist.id;
                                            return (
                                                <Div
                                                    key={artistId}
                                                    className="artistContainer"
                                                    style={{...sty.artistContainer, ...StylesPlatform.cursorPointer}}
                                                >
                                                    <Image style={sty.artistPicture} src={artist.picture_medium} />
                                                    <Div style={sty.artistNameDeezer}>{artist.name}</Div>
                                                    <Div style={sty.artistAlbumDeezer}>Albums: {Utils.commas(parseInt(artist.nb_album))}</Div>
                                                    <Div style={sty.artistFanCountDeezer}>Fans: {Utils.commas(parseInt(artist.nb_fan))}</Div>
                                                    <Button
                                                        style={sty.artistShowTracksDeezer}
                                                        size="small"
                                                        onClick={this.selectArtist.bind(this, artist)}
                                                    >
                                                        Show Tracks
                                                    </Button>
                                                </Div>
                                            )
                                        })}

                                        {st.artistItems.map((artistItem, artistItemsIndex) => {
                                            const artistItemId = artistItem.id;

                                            return (
                                                <Div
                                                    key={artistItemId}
                                                    className="artistItemContainer"
                                                    style={{...sty.artistItemContainer, ...StylesPlatform.cursorPointer}}
                                                >
                                                    <Div className="artistItemRow" style={sty.artistItemRow}>
                                                        <Image src={artistItem.cover_medium} style={sty.artistPicture} />
                                                        <Div>{artistItem.title}</Div>
                                                    </Div>
                                                    <Div>
                                                        {artistItem.tracks.map((track, trackIndex) => {
                                                            return (
                                                                <Div key={track.id} style={sty.trackRow}>
                                                                    <Div>{artistItem.tracks.length === 1 ? null : `${trackIndex + 1}.`} {track.title}</Div>
                                                                    <Button
                                                                        style={sty.artistShowTracksDeezer}
                                                                        size="small"
                                                                        onClick={this.selectArtistTrack.bind(this, track, artistItem)}
                                                                    >
                                                                        Select Track
                                                                    </Button>
                                                                </Div>
                                                            )
                                                        })}
                                                    </Div>
                                                </Div>
                                            );
                                        })}
                                    </Div>
                                    :
                                    null
                                }

                            </Div>
                        </Modal>
                        :
                        null
                    }
                </Div>
          </Div>
        );
    }

    styles = {
        dashboardNewSoinComponent: {
            backgroundColor: Colors.indigo,
            borderTopLeftRadius: 36,
            paddingTop: 30,
            paddingRight: 50,
            paddingBottom: 20,
            paddingLeft: 50,
        },
        dashboardNewSoinInnerContainer: {
            maxWidth: 1350,
            marginLeft: "auto",
            marginRight: "auto",
        },
        designPanelContainer: {
            paddingTop: 30,
            paddingRight: 60,
            paddingBottom: 60,
            paddingLeft: 60,
        },
        experiencesPanelContainer: {
            display: "flex",
            paddingTop: 30,
            paddingRight: 60,
            paddingBottom: 60,
            paddingLeft: 60,
        },
        createNftTitle: {
            marginTop: 5,
            marginBottom: 10,
            fontSize: 36,
            fontWeight: "bold",
            color: "white",
        },
        createNftSubtitle: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8,
        },
        createNftTitleDescStyleContainer: {
            display: "flex",
            alignItems: "top",
        },
        titleDescStyleContainer: {
            width: "100%",
            maxWidth: 800,
            paddingLeft: 30,
        },
        nftStyleContainer: {
            display: "flex",
            justifyContent: "space-around",
            maxWidth: 700,
            marginLeft: "auto",
            marginRight: "auto",
            flexWrap: "wrap",
        },
        nftReleaseSizeContainer: {
            display: "flex",
            justifyContent: "space-around",
            maxWidth: 1200,
            marginTop: 10,
            marginLeft: "auto",
            marginRight: "auto",
        },
        nftRaritiesContainer: {
            display: "flex",
        },
        pinkTabContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: 5,
            marginRight: 5,
            marginBottom: 15,
        },
        rarityTabContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: 20,
            marginRight: 20,
            whiteSpace: "nowrap",
        },
        tabContainerImage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 120,
            height: 120,
            borderRadius: 20,
            marginBottom: 8,
            //border: "2px solid white",
            boxSizing: "border-box",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        tabReleaseSizeImage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 200,
            height: 100,
            borderRadius: 20,
            marginBottom: 8,
            //border: "2px solid white",
            boxSizing: "border-box",
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        modalImage: {
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 120,
            height: 120,
            borderRadius: 20,
            boxSizing: "border-box",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "black",
            flex: "19%",
            marginTop: 10,
            marginRight: 10,
            marginBottom: 10,
            marginLeft: 10,
            flexGrow: 0,
            flexShrink: 0,
        },
        modalText: {
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 120,
            height: 120,
            borderRadius: 20,
            boxSizing: "border-box",
            border: "1px solid white",
            textAlign:"center",
            flex: "19%",
            marginTop: 10,
            marginRight: 5,
            marginBottom: 18,
            marginLeft: 5,
            flexGrow: 0,
            flexShrink: 0,
            flexDirection: "column",
        },
        modalImageContainer: {
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        itemName: {
            fontWeight: "bold",
            fontSize: 14,
        },
        itemSubName: {
            fontSize: 10,
        },
        pinkLine: {
            height: 6,
            width: 90,
            backgroundColor: Colors.pink,
            borderRadius: 100,
            marginTop: 8,
        },
        uploadImage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: 120,
            height: 120,
            borderRadius: 20,
            marginBottom: 8,
            borderWidth: 2,
            borderColor: Colors.purpleLight,
            borderStyle: "solid",
            backgroundPosition: "center",
            backgroundSize: "cover",
        },
        nftUploadsContainer: {
            paddingTop: 20,
        },
        nftUploadImagesContainer: {
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
            maxWidth: 1200,
            marginLeft: "auto",
            marginRight: "auto",
            flexWrap: "wrap",
        },
        uploadImagesContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            marginLeft: 5,
            marginRight: 5,
            marginBottom: 15,
        },
        pinkCheck: {
            height: 30,
            width: 30,
            borderRadius: 100,
            backgroundColor: Colors.pink,
            position: "absolute",
            top: -10,
            right: -10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        plusSign: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
            width: 40,
            borderRadius: 100,
            // backgroundColor: Colors.purple,
        },
        signatureContainer: {
            display: "flex",
            marginTop: 40,
            paddingLeft: 80,
            backgroundColor: Colors.purple,
            borderRadius: 24,
            padding: 30,
        },
        signatureMessage: {
            fontSize: 18,
            width: 135,
            textAlign: "left",
            marginTop: 20,
            marginLeft: 40,
            paddingRight: 10,
        },
        raritiesDropdownContainer: {

        },
        revenuePanelContainer: {
            paddingTop: 40,
            paddingRight: 40,
            paddingBottom: 40,
            paddingLeft: 40,
            display: "flex",
        },
        raritiesContainer: {
            flex: 5,
            padding: "0px 40px",
        },
        nonOriginalRartitiesContainer: {
            justifyContent: "space-around",
        },
        originalAuctionContainer: {
            flex: 2,
        },
        timingContainer: {
            maxWidth: 702,
            marginBottom: 50,
        },
        timingTextDropdownContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
        },
        notesForReviewerContainer: {
            display: "flex",
            marginBottom: 30,
        },




    cardBackground: {
        filter: "blur(25px) brightness(3)",
        marginLeft: -100,
        marginTop: -200,
        minWidth: "130%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: -1,
        opacity: 0.6,
    },

    title: {
      color: '#032E4B',
      fontWeight: 'bold',
      fontSize: 32,
      padding: 12,
      letterSpacing: '0.75px'
    },
    body: {
      padding: 12
    },
    bodyExtra: {
      padding: 12
    },
    smallclose: {
      textAlign: 'right',
      marginRight: '-10px',
      marginTop: '-20px',
      cursor: 'pointer',
      color: '#F6F0E8'
    },
    buttonRow: {
      clear: "both",
    },
    mediaTitle: {
      fontSize: 51,
      fontWeight: "bold",
      marginBottom: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    transparentPanel: {
        marginTop: 20,
        padding:20,
        marginBottom:20,
    },
    transparentButton: {
        cursor: "pointer",
        margin: "10px 60px",
        paddingTop: 8,
        flex:2,
        textAlign: 'center',
        borderRadius:20,
        height:40,
        backgroundColor:"rgba(0.1,0.1,0.1,0.5)",
    },
    mediaArtist: {
      fontSize: 30,
      marginLeft:14,
      marginBottom: 12,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    mediaDescription: {
      fontSize: 12,
      textAlign: "left",
      overflow: "hidden",
      height: 180,
      textOverflow: "ellipsis",
      marginBottom: 12,
    },
    mediaProperties: {
      fontSize: 17,
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    posterContainer: {
        marginLeft: 20,
        marginTop: 34,
        padding: 20,
        borderWidth: 1,
        borderStyle: "solid",
        width: "84%",
        position:"relative",
    },
    posterTile: {
      borderTop: "2px solid rgba(255,255,255,0.5)",
      borderLeft: "2px solid rgba(255,255,255,0.5)",
      boxSizing: "border-box",
      boxShadow: "6px 6px 10px 0 rgba(0,0,0,0.51)",
      overflow: "hidden",
      width:"98%",
      /* border: "1px solid rgba(255,255,255,0.2)", */
    },
    posterBorderTL: {
        position:"absolute",
        top:0,
        left:0,
        borderColor: "#6CDCCD",
        borderLeftWidth:4,
        borderLeftStyle:"solid",
        borderTopWidth:4,
        borderTopStyle:"solid",
        width: 57,
        height: 57,
    },
    posterBorderTR: {
        position:"absolute",
        top:0,
        right:0,
        borderColor: "#6CDCCD",
        borderRightWidth:4,
        borderRightStyle:"solid",
        borderTopWidth:4,
        borderTopStyle:"solid",
        width: 57,
        height: 57,
    },
    posterBorderBR: {
        position:"absolute",
        bottom:0,
        right:0,
        borderColor: "#6CDCCD",
        borderRightWidth:4,
        borderRightStyle:"solid",
        borderBottomWidth:4,
        borderBottomStyle:"solid",
        width: 57,
        height: 57,
    },
    posterBorderBL: {
        position:"absolute",
        bottom:0,
        left:0,
        borderColor: "#6CDCCD",
        borderLeftWidth:4,
        borderLeftStyle:"solid",
        borderBottomWidth:4,
        borderBottomStyle:"solid",
        width: 57,
        height: 57,
    },
    circle: {
        paddingTop:3,marginRight:6,fontSize:20,
    },
    propsItem: {
        marginBottom: 20,
        color: "gray",
    },
    propsItemPerc: {
        fontSize: 10,
        backgroundColor:"#6CDCCD",
        color:"white",
        padding: 4,
    },
    orangeGradientButton: {
        background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
        color: "white",
        padding:23,
        textAlign:"center",
        fontWeight: "bold",
        cursor: "pointer",
    },
    artistContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
    artistName: {
        color: Colors.purpleLight4,
        fontSize: 28,
        marginRight: 20,
        marginTop: 2,
    },
    artistThumbnail: {
        width: 48,
        height: 48,
        borderRadius: 10,
        marginBottom: 20,
        marginRight: 20,
        marginLeft: 4,
    },
    createNftDescription: {
        color: "white",
        padding: "10px 90px",
        marginBottom: 20,
    },
    paragraph: {
        marginBottom: 20,
    },
    printsContainer: {
        marginTop: 15,
        maxWidth: 100,
    },
    priceContainer: {
        maxWidth: 100,
    },
    revenueTitleCol: {
        flex:3,
        fontSize: 12,
    },
    revenueCol: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
    },
    percentCol: {
        flex: 1,
    },
    missingItemsText: {
        paddingTop: 15,
        textAlign: "right",
        paddingBottom: 20,
        fontWeight: "bold",
        color: "yellow",
    },
    sectionsComplete: {
        textAlign: "right",
        fontWeight: "bold",
        paddingBottom: 30,
    },
    modalDeezerContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        width: "100%",
    },
    artistSearchContainer: {
        display: "flex",
        justifyContent: "center",
    },
    artistsFromSearchContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "fit-content",
        borderRadius: 8,
        borderColor: Colors.purpleLight,
        borderStyle: "solid",
        borderWidth: 1,
        width: "100%",
        height: 300,
        overflow: "scroll",
        padding: 20,
        backgroundColor: Colors.purpleBackground,
    },
    artistContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
    },
    artistPicture: {
        height: 80,
        width: 80,
        borderRadius: 8,
        marginRight: 20,
    },
    artistNameDeezer: {
        flex: 1,
    },
    artistAlbumDeezer: {
        flex: 1,
    },
    artistFanCountDeezer: {
        flex: 1,
    },
    artistShowTracksDeezer: {
        marginLeft: "auto",
        width: "fit-content",
    },
    artistItemContainer: {
        // display: "flex",
        // alignItems: "center",
        marginBottom: 25,
    },
    artistItemRow: {
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
    },
    trackRow: {
        display: "flex",
        alignItems: "center",
        paddingTop: 20,
        paddingLeft: 40,
    },
  };
}
