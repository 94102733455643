import React from 'react';
import axios from 'axios';

import { Carousel } from 'react-responsive-carousel';
import moment, { max } from 'moment'

import '../App.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';

import Div from '../elements/Div';

import Scroller from './Scroller';
import SearchBox from './SearchBox';

import banner from '../assets/hero-banner.png';
import dropArtistAnimation from '../assets/drops_transparent.gif';
import dropArtistImage from '../assets/weeknd1.png';

export default class DashboardSoinMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        wallet: null,
        results: this.props.results,
    };

        var then = moment().add(160, 'seconds'); // new Date(now + 60 * 1000);

        //$(".now").text(moment(now).format('h:mm:ss a'));
        //$(".then").text(moment(then).format('h:mm:ss a'));
        //$(".duration").text(moment(now).to(then));
        if(false) {
            setInterval(function() {
                var now = moment(); // new Date().getTime();
                console.log("WHEN", moment().to(then));
            }, 1000);
        }

        console.log("WHEN", moment().to(then));

  }

  UNSAFE_componentWillReceiveProps(newProps) {
      let tempState = {};
      if(newProps["results"]) {
          tempState["results"] = newProps["results"];
      }
      this.setState(tempState);
  }

  changeSelection(selection) {
      console.log("Changing selection: ", selection);
      if(selection !== "logout") {
          this.setState({selected: selection});
      } else {
          this.setState({showLogout: true});
      }
  }

  handleConfirmCancel() {
      this.setState({showLogout: false});
  }

  handleConfirmMediaModal() {
    this.setState({ showMediaModal: false });

  }

  handleCancelMediaModal(dirty) {
    this.setState({ showMediaModal: false });
    console.log(">>>>>>>>> 11");
    Api.apiLoadMusic(this.processMusic.bind(this));

  }

  runSearch(event, search) {
      console.log("Run search on ", search);
      const _self = this;
      Api.getSearch(search, (o) => {
          console.log(o);
          _self.setState({results:o.data});
      });
  }



  handlePosterClick(item) {
      item["thumbnail"] = item["cover_big"];
      this.setState({
              selectedMediaItem: item,
      }, () => {
        this.props.change('item', item);
      });
  }


  renderCard(listing, size) {
      const borderRadius = 0; //parseInt(size*.1);
      const captionHeight = parseInt(size*.4);
      const fontSize = parseInt(size*.06);
      const trackName = listing["title"]; //album["tracks"][0]["title"];
      const artistName = listing["artist_name"]; //album["artist"]["name"];
      const remainingPrints = listing["total_prints"] - listing["prints_sold"];
      //size = 180;
      let ts = moment();
      let formatDate = ts.format('h') + "h " + ts.format('m') + "m " + ts.format('s') + "s";
      let timeRemaing = "2h 35m";

      var a = moment([2021, 2, 6]);
      var b = moment([2021, 2, 4]);
      var hours = a.diff(b, 'hours');
      var minutes = a.diff(b, 'minutes');
      var seconds = a.diff(b, 'seconds');
      var sec = seconds % 60;
      window.sec = seconds;


        /*
        (function timerLoop() {
          $(".difference > span").text(moment().to(then));
          $(".countdown").text(countdown(then).toString());
          requestAnimationFrame(timerLoop);
        })();
        */

      formatDate = a.diff(b, 'hours') + "h " + a.diff(b, 'minutes') + "m " + sec + "s "; // 1
      formatDate = listing["remaining"];

      return <Div
        key={`card-${listing['id']}`}
        onClick={this.handlePosterClick.bind(this, listing)}
        style={{position:"relative",marginTop:6,borderRadius:borderRadius,overflow:"hidden",textAlign:"left"}}>
            { listing["listing_status"] == 2 ?
            <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>{formatDate}</Div>
            :
            null
            }
                <img style={{width:size, height:size, filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"}} src={listing["image_url"]} />
                <Div style={{whiteSpace: "nowrap", textAlign:"left",marginTop:19}}>
                    <Div style={{fontSize:14,fontWeight:"bold"}}>
                        {trackName}
                    </Div>
                    <Div style={{fontSize:12,color:"gray",marginTop:4}}>
                        {artistName}
                    </Div>
                    { listing["listing_status"] == 2 ?
                        <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>
                            {remainingPrints} of {listing["total_prints"]} left
                        </Div>
                        :
                        null
                    }
                </Div>
        </Div>;
  }

  renderResults() {
      let renderedTiles = [];
      if(this.state.results) {
          renderedTiles = this.state.results.map( (album) => {
               return this.renderCard(album, 180);
            });
          console.log("RESULTS", this.state.results, renderedTiles);
      }
      let slidePercentage = 20;
      let transitionTime = 350;
      let centerMode = true;
      const found = this.state.results ? " -- " + this.state.results.length + " result(s) found" : "";

      return <Div style={this.styles.sectionHead}>
        <Div className='row' style={{marginTop:18,marginLeft:-3,width:"26%",fontSize:12,}}>
            <SearchBox
                placeholder="Search..."
                validate={this.runSearch.bind(this)}
                />
        </Div>

        <Div style={this.styles.headline}>Search Results{found}</Div>
            <Div style={{padding:0,marginTop:10}}>
                {this.state.results
                    ?
                      <Carousel
                        key={"resultsScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    <Div>No results found</Div>
                }
            </Div>
        </Div>;
  }



  render() {
    return (
      <Div style={this.styles.app} className="App">
        <Div className="container-fluid" style={{paddingLeft:0}}>
          <Div className="row">
            <Div className="col" style={this.styles.rightCol}>
                <Div style={{padding:20}}>
                    {this.renderResults()}
                </Div>
            </Div>
          </Div>
        </Div>


      </Div>
    );
  }
  styles = {
    app: {
      backgroundColor: "#000",
      color: "black",
      textAlign: "left",
        fontFamily: "'Montserrat', sans-serif",
    },
    leftCol: {
      minHeight: 850,
      maxWidth: 220,
      backgroundColor:"white",
      color: "black",
      paddingLeft: 0,
      paddingRight: 0,
    },
    leftColBody: {
      paddingTop: 20,
      justifyContent: "center",
      display: "flex",
      flexDirection:"col",

    },
    rightCol: {
      paddingLeft:0,
      background:"#FBF8F4",
      minHeight:800,
      color:"black"
    },
    slateButton: {
        cursor: "pointer",
        padding:10,
        backgroundColor:"black",borderRadius:20,
        right:20,
        width: 300,
        margin: 20,
        textAlign: "center",
        color:"white",
    },
    sectionHead: {
        marginTop: 20,
        width:1200, paddingBottom:0,marginLeft:33

    },
    orangeGradientButton: {
        background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
        color: "white",
        padding:23,
        textAlign:"center",
        fontWeight: "bold",
        cursor: "pointer",
    },
  };
}
