import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import StyleUtils from '../modules/StyleUtils';

import '../App.css';

import Spinner from '../elements/Spinner';
import Image from '../elements/Image';
import DebugBox from '../elements/DebugBox';
import WebsiteHeader from '../elements/WebsiteHeader';

import Div from './Div';
import AfterpartyUpcomingEvents from './AfterpartyUpcomingEvents';
import AfterpartyEvent from './AfterpartyEvent';
import AfterpartyRoom from './AfterpartyRoom';
import AfterpartyRoomV2 from './AfterpartyRoomV2';
import RoomV3 from './RoomV3';
import AfterpartyNft from './AfterpartyNft';
import Profile from './Profile';
import PrivacyPolicy from './PrivacyPolicy';
import Blog from './Blog';
import Doc from './Doc';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import EmailVerify from './EmailVerify';
import Museum from './Museum';
import TicketFlow from './TicketFlow';
import DashboardNewSoin from './DashboardNewSoin';
import LoginRegister from './LoginRegister';
import SplashFooter from './SplashFooter';
import EmailPhoneVerificationPage from './EmailPhoneVerificationPage';

import menuImage from "../assets/images/splash/Menu.png"
import menuImageProfile from "../assets/images/ticketflow/profile-img@2x.png"

export default class AfterpartyHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',

            selected: "afterparty",
            mediaItem: false,

            eventItem: false,
            roomItem: false,
            afterpartyItem: false,

            events: false,
            upcomingLoaded: false,
            roomLoaded: false,
            eventLoaded: false,

            roomNotFound: false,
            eventNotFound: false,

            seller: null,

            notificationData: {
                message: "You should buy this NFT it's tight!",
            },
            showBitCloutBuyModal: false,
            showFollowModal: false,

            showRoomNotification: null,
            currentRoomSignal: null,
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        ll._("Route from routes: "+pr.route, "green");
        let showRoute = pr.route ? pr.route : 'afterparty';

        this.setState({selected: showRoute}, () => {
            console.log("DID", showRoute);
            this.handleRoute(showRoute);
        });
        window.addVip = () => {
            let roomItem = Utils.get(this.state, "roomItem");
            if(roomItem) {
                const roomCrc = Utils.getUrlCrc();
                Api.getAfterPartyRoomVips(roomCrc, (o) => {
                    console.log(o);
                    let newRoomItem = Utils.deepCopy(roomItem);
                    newRoomItem["customers_vip"] = o.data;
                    this.setState({roomItem:newRoomItem});
                    if(false) {
                        let vips = Utils.get(roomItem, "customers_vip", []);
                        console.log("Adding VIP", vips);
                        let newVip = Utils.deepCopy(vips[vips.length-1]);
                        newVip['id'] = newVip['id']+1;
                        newVip['username'] = newVip['username']+"-"+newVip['id'];
                        vips.push(newVip);
                        this.setState({roomItem:roomItem});
                    }
                });
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';

        if(this.state.selected != selected) {
            this.handleRoute(selected);
            this.setState({selected: selected});
        }
    }

    processMusic(data, error) {
        const st = this.state;

        console.log("process Music", data);
        if(!data) {
            console.log("No data to process");
            return;
        }
        let tempState;
        if(st.selected == 'soinlisting' || st.selected.includes('newnft')) {
            tempState = {
                mediaItem: data.data,
                //relatedSoins: data.includes.relatedSoins,
            };
            var related = Utils.get(data, "includes.relatedSoins");
            if(related) {
                tempState["relatedSoins"] = related;
            }
            console.log("soinlisting tempState", tempState);
        } else if(st.selected == 'search') {
            tempState = {
                results: data["data"],
            };
        } else if(st.selected == 'profile') {
            tempState = {
                seller: data["data"],
            };
        } else {
            let drops = data["data"]["drops"];
            var now = moment();
            for(var idx in drops) {
                var drop = drops[idx];
                //const secs = Math.floor(Math.random() * (drop["id"] * 16000));
                var then = moment(drop['drop_date_begin']); //moment().add(secs, 'seconds');
                if(now.isBefore(then)) {
                    //drop["until"] = moment().to(then);
                    drop["until"] = this.calcTimeStr(now, then);
                } else {
                    drop["remaining"] = this.calcTimeStr(now, moment(drop['drop_date_end'])); //moment().to(moment(drop['drop_date_end']));
                }
                //console.log("WHEN", moment().to(then));

            }
            tempState = {
                drops: data["data"]["drops"],
                marketplace: data["data"]["marketplace"],
                mycollection: data["data"]["mycollection"],
                featured: data["data"]["featured"][0],
                // For testing item screen
                mediaItem: data["data"]["drops"][0],
            };
        }
        this.setState(tempState);
    }

    handleRoute(selected) {
        const st = this.state;

        if(selected == 'event') {
            const eventId = Utils.getUrlCrc();
            if(eventId) {
                Api.getAfterPartyEvent(eventId, this.processEvent.bind(this));
            } else {
                this.setState({eventNotFound: true});
                ll._("Event not found");
            }
        } else if(selected == 'room') {
            const roomId = Utils.getUrlCrc();
            if(roomId) {
                Api.getAfterPartyRoom(roomId, this.processRoom.bind(this));
            } else {
                this.setState({roomNotFound: true});
            }
        } else if(selected.includes('newnft')) {
            const listingId = Utils.getUrlCrc();
            if(listingId) {
                Api.getAfterpartyListing(listingId, this.processMusic.bind(this));
            }
        } else if(selected == 'profile') {
            Api.getSeller(this.processMusic.bind(this));
        } else if(selected == 'blog') {
            Api.getAfterPartyBlogs(this.processBlogs.bind(this));
        } else if(selected == 'tos') {
            Api.getAfterPartyDoc(2, this.processDoc.bind(this));
        } else if(selected == 'privacypolicy') {
            Api.getAfterPartyDoc(1, this.processDoc.bind(this));
        } else if(selected == 'dmca') {
            Api.getAfterPartyDoc(3, this.processDoc.bind(this));
        } else if(selected == 'festival_accommodations') {
            Api.getAfterPartyDoc(12, this.processDoc.bind(this));
        } else if(selected == 'afterparty') {
            Api.getAfterPartyEvents(this.processEvents.bind(this));
        } else if(selected == 'publicprofile') {
            const profileName = Utils.getUrlPathPart(3); //Utils.getProfileName();
            if(profileName) {
                Api.getPublicProfile(profileName, this.processPublicProfile.bind(this));
            }
        } else {
            //Api.apiLoadMusic(this.processMusic.bind(this));
            console.log("NOTE: No route to handle");
        }
    }

    processEvents(data, error) {
        ll._("process Events","green", data, error);
        if(data && data.errors && data.errors.length == 0) {
            let tempState = {
                events: data.data,
                upcomingLoaded: true,
                current_user_afterpartynft: data.current_user_afterpartynft,
                //relatedSoins: data.includes.relatedSoins,
            };
            this.setState(tempState);
        }
    }

    processBlogs(data, error) {
        ll._("process Blog","green", data, error);
        if(data && data.errors && data.errors.length == 0) {
            let tempState = {
                blogs: data.data,
                blogLoaded: true,
            };
            this.setState(tempState);
        }
    }

    processDoc(data, error) {
        ll._("process Doc","green", data, error);
        if(data && data.errors && data.errors.length == 0) {
            let tempState = {
                body: data.data.body,
                docLoaded: true,
            };
            this.setState(tempState);
        }
    }

    processEvent(eventResponse, error) {
        if(eventResponse && eventResponse.errors) {
            if(eventResponse.errors.length == 0) {
                let tempState = {
                    eventItem: eventResponse.data,
                    eventLoaded: true,
                    //relatedSoins: data.includes.relatedSoins,
                };
                this.setState(tempState);
            } else {
                this.setState({
                    eventNotFound: true,
                    eventLoaded: true,
                });
            }
        }
    }

    processRoom(roomResponse, error) {
        let roomItem = Utils.get(roomResponse, "data");
        if(roomResponse && roomResponse.errors) {
            let tempState = {
                roomItem: roomItem,
                roomLoaded: true,
            }
            if(roomResponse.errors.length == 0) {
                roomItem["customersVipDict"] = Utils.arrayToObject(roomItem.customers_vip, "id");
            } else {
                tempState['roomNotFound'] = Utils.get(roomResponse, "errors.0.id", true);
            }
            this.setState(tempState);
        }
    }

    processPublicProfile(data, error) {
        const st = this.state;
        let tempState = {};
        if(data) {
            data["public"] = true;
            tempState["publicProfile"] = data.data;
        };
        this.setState(tempState);
    }

    resetRoomLoaded() {
        this.setState({
            roomLoaded: false,
            roomNotFound: false,
        });
    }

    change(selection, obj, dirtyProfile) {
        const st = this.state;
        ll._('Changing selection to: '+selection, "blue"); //, obj, st.musicLoaded);
        if(selection !== 'logout') {
            let tempState = {
                selected: selection,
                showMobileFlyout: false,
                eventLoaded: false,
                roomLoaded: false,
            };

            let correctKey = selection === "room" ? "roomItem" : "eventItem";

            // tempState[correctKey] = obj; //TODO need to fix
            this.setState(tempState, () => {
                //console.log("Checking for reload", selection, _self.state.musicLoaded);
                if(dirtyProfile) {
                    //console.log("apiLoadProfile");
                    //Api.apiLoadProfile(pr.setUser.bind(_self));
                }
                if((selection == 'afterparty' || selection == 'home') && !st.upcomingLoaded) {
                    Api.getAfterPartyEvents(this.processEvents.bind(this));
                } else if(selection === 'room' && !st.roomLoaded) {
                    const roomId = Utils.getUrlCrc();
                    if(roomId) {
                        Api.getAfterPartyRoom(roomId, this.processRoom.bind(this));
                    } else {
                        this.setState({roomNotFound: true});
                    }
                } else if(selection === 'event') {
                    this.setState({eventItem: obj})

                    if(!st.eventLoaded) {
                        const eventId = Utils.getUrlCrc();

                        if(eventId) {
                            Api.getAfterPartyEvent(eventId, this.processEvent.bind(this));
                        } else {
                            console.log("Event not found");
                            this.setState({eventNotFound: true});
                        }
                    }
                } else if((selection === 'item' || selection === 'soinlisting') && !obj.relatedSoins) {
                    console.log("LOAD RELATED SOINS");
                    //Api.apiGetListing(obj["id"], this.processMusic.bind(_self));
                }
                //console.log("Changing end", tempState);

            });
        } else {
            console.log("Changing logout");

            this.setState({ showLogout: true, showMobileFlyout: false, mediaItem: obj });
        }
    }

    eventPageBack() {
        const pr = this.props;
        const st = this.state;
        const event = st.roomItem.event;

        let path = `event/${event.name}-${event.crc}`;
        pr.history.push(`/${path}`);
        this.change('event', event);
    }

    handleNotificationClick() {
        const st = this.state;
    }

    handleBuyClick() {
        this.setState({showBitCloutBuyModal: true});
    }

    handleBuyModalClose() {
        this.setState({showBitCloutBuyModal: false});
    }

    handleShowFollowModal() {
        this.setState({showFollowModal: true});
    }

    handleFollowModalClose() {
        this.setState({showFollowModal: false});
    }

    handleShowRoomNotification(listing, signal) {
        this.setState({
            showRoomNotification: listing,
            currentRoomSignal: signal,
        });
    }

    handleClickRoomNotification(listing, signalId) {
        const st = this.state;

        Api.postAnalytics({
            signal_id: st.currentRoomSignal.id,
            type: "click_on_notify"
        });
        this.setState({
            showRoomNotification: null,
            currentRoomSignal: null,
        });
    }

    handleCloseRoomNotification(e) {
        e.stopPropagation();
        this.setState({
            showRoomNotification: null,
            currentRoomSignal: null,
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let showFlyout = !st.isPortal;
        let showFlyoutNavbar = false;
        // Hide flyout if event or room
        if(st.selected === 'event' || st.selected === 'room') {
            showFlyout = false;
        } else if(pr.screen.width <= 768) {
            showFlyout = false;
            showFlyoutNavbar = true;
        }

        // Allow force override
        if(Utils.getRequest('flyout')) {
            showFlyout = true;
        }

        let afterpartyHomeComponent = {
            ...sty.afterpartyHomeComponent,
            marginLeft: 0,
            marginTop: 0,
        };

        return (
            <Div className="afterpartyHomeComponent" style={afterpartyHomeComponent}>
                { false ?
                    <DebugBox
                        title="AfterpartyHome"
                        show={this.state}
                        />
                    :
                    null
                }
                    <WebsiteHeader
                        screen={pr.screen}
                        user={pr.user}
                        userLoaded={pr.userLoaded}
                        setUser={pr.setUser.bind(this)}
                        selected={st.selected}
                        history={pr.history}
                        change={this.change.bind(this)}
                        metamaskWalletId={st.metamaskWalletId}
                        headerType={pr.headerType}
                    />

                    {st.selected === 'afterparty' || st.selected === 'home' ?
                        <AfterpartyUpcomingEvents
                            navbar={showFlyoutNavbar}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            change={this.change.bind(this)}
                            events={st.events}
                            current_user_afterpartynft={st.current_user_afterpartynft}
                        />
                        :
                        null
                    }
                    {st.selected === 'event' && st.eventItem ?
                        <Div className="afterpartyEventWrapper">
                            <AfterpartyEvent
                                screen={pr.screen}
                                showFlyout={showFlyout}
                                history={pr.history}
                                eventItem={st.eventItem}
                                change={this.change.bind(this)}
                                eventLoaded={st.eventLoaded}
                                setUser={pr.setUser.bind(this)}
                                user={pr.user}
                            />
                        </Div>
                        :
                        null
                    }
                    {st.selected === 'room' ?
                        <Div
                            className="afterpartyRoomWrapper"
                            style={{width: "100%"}}
                        >
                            {pr.screen.mobile || pr.screen.mobileLandscape || true?
                                <RoomV3
                                    screen={pr.screen}
                                    showFlyout={showFlyout}
                                    history={pr.history}
                                    roomItem={st.roomItem}
                                    change={this.change.bind(this)}
                                    roomLoaded={st.roomLoaded}
                                    roomNotFound={st.roomNotFound}
                                    resetRoomLoaded={this.resetRoomLoaded.bind(this)}
                                    user={pr.user}
                                    setUser={pr.setUser.bind(this)}
                                    showBitCloutBuyModal={st.showBitCloutBuyModal}
                                    handleBuyModalClose={this.handleBuyModalClose.bind(this)}
                                    showFollowModal={st.showFollowModal}
                                    handleFollowModalClose={this.handleFollowModalClose.bind(this)}
                                    handleShowRoomNotification={this.handleShowRoomNotification.bind(this)}
                                    handleBuyClick={this.handleBuyClick.bind(this)}
                                    handleClickRoomNotification={this.handleClickRoomNotification.bind(this)}
                                    handleShowFollowModal={this.handleShowFollowModal.bind(this)}
                                    handleCloseRoomNotification={this.handleCloseRoomNotification.bind(this)}
                                    badgeNotifications={Utils.get(st.roomItem, "listings", [])}
                                />
                                :
                                <AfterpartyRoom
                                    screen={pr.screen}
                                    showFlyout={showFlyout}
                                    history={pr.history}
                                    roomItem={st.roomItem}
                                    change={this.change.bind(this)}
                                    roomLoaded={st.roomLoaded}
                                    roomNotFound={st.roomNotFound}
                                    user={pr.user}
                                    setUser={pr.setUser.bind(this)}
                                    showBitCloutBuyModal={st.showBitCloutBuyModal}
                                    handleBuyModalClose={this.handleBuyModalClose.bind(this)}
                                    showFollowModal={st.showFollowModal}
                                    handleFollowModalClose={this.handleFollowModalClose.bind(this)}
                                    handleShowRoomNotification={this.handleShowRoomNotification.bind(this)}
                                    showRoomNotification={st.showRoomNotification}
                                    handleBuyClick={this.handleBuyClick.bind(this)}
                                    handleClickRoomNotification={this.handleClickRoomNotification.bind(this)}
                                    handleShowFollowModal={this.handleShowFollowModal.bind(this)}
                                    handleCloseRoomNotification={this.handleCloseRoomNotification.bind(this)}
                                    badgeNotifications={Utils.get(st.roomItem, "listings", [])}
                                    currentRoomSignal={st.currentRoomSignal}
                                />
                            }
                        </Div>
                        :
                        null
                    }
                    {st.selected === 'profile' ?
                        <Div
                            className="afterpartyProfileWrapper"
                            style={{width: "100%"}}
                        >
                            <Profile
                                screen={pr.screen}
                                showFlyout={showFlyout}
                                history={pr.history}
                                change={this.change.bind(this)}
                                user={pr.user}
                                seller={st.seller}
                                userLoaded={pr.userLoaded}
                            />
                        </Div>
                        :
                        null
                    }
                    {st.selected === 'publicprofile' ?
                        <Div
                            className="afterpartyPublicProfileWrapper"
                            style={{width: "100%"}}
                        >
                            <Profile
                                screen={pr.screen}
                                showFlyout={showFlyout}
                                history={pr.history}
                                change={this.change.bind(this)}
                                user={pr.user}
                                publicProfile={st.publicProfile}
                                userLoaded={pr.userLoaded}
                                public
                            />
                        </Div>
                        :
                        null
                    }

                    {st.selected.includes('newnft') ?
                        <DashboardNewSoin
                          screen={pr.screen}
                          user={pr.user}
                          mediaItem={st.mediaItem}
                          change={this.change.bind(this)}
                          history={pr.history}
                          userLoaded={pr.userLoaded}
                        />
                      :
                      null
                    }

                    {st.selected === 'tos' || st.selected === 'privacypolicy' || st.selected === 'dmca' || st.selected === 'festival_accommodations' ?
                        <Div
                            className="afterpartyDocWrapper"
                            style={{width: "100%"}}
                        >
                            <Doc
                                screen={pr.screen}
                                user={pr.user}
                                body={st.body}
                            />
                        </Div>
                        :
                        null
                    }

                    {st.selected === 'blog' ?
                        <Div
                            className="afterpartyBlogWrapper"
                            style={{width: "100%"}}
                        >
                            <Blog
                                showFlyout={showFlyout}
                                history={pr.history}
                                change={this.change.bind(this)}
                                user={pr.user}
                                blogs={st.blogs}
                            />
                        </Div>
                        :
                        null
                    }
                    {(st.selected === 'nft') ?
                        <Div
                            className="afterpartyBlogWrapper"
                            style={{width: "100%"}}
                        >
                            <AfterpartyNft
                                screen={pr.screen}
                                showFlyout={showFlyout}
                                history={pr.history}
                                change={this.change.bind(this)}
                                user={pr.user}
                                blogs={st.blogs}
                            />
                        </Div>
                        :
                        null
                    }

                    {st.selected === "login" ?
                        <LoginRegister
                            selected={st.selected}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            mediaItem={st.mediaItem}
                            change={this.change.bind(this)}
                            setUser={pr.setUser.bind(this)}
                        />
                        :
                        null
                    }
                    {st.selected === "register" ?
                        <LoginRegister
                            selected={st.selected}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            mediaItem={st.mediaItem}
                            change={this.change.bind(this)}
                            setUser={pr.setUser.bind(this)}
                        />
                        :
                        null
                    }
                    {st.selected === "verificationemail" ?
                        <EmailPhoneVerificationPage
                            selected={st.selected}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            change={this.change.bind(this)}
                            setUser={pr.setUser.bind(this)}
                        />
                        :
                        null
                    }
                    {st.selected === "verificationphone" ?
                        <EmailPhoneVerificationPage
                            selected={st.selected}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            change={this.change.bind(this)}
                            setUser={pr.setUser.bind(this)}
                        />
                        :
                        null
                    }
                  {st.selected === 'forgot' ?
                      <ForgotPassword
                          selected={st.selected}
                          screen={pr.screen}
                          history={pr.history}
                          user={pr.user}
                          mediaItem={st.mediaItem}
                          change={this.change.bind(this)}
                      />
                      :
                      null
                  }

                  {st.selected === 'changepassword' ?
                      <ChangePassword
                          selected={st.selected}
                          screen={pr.screen}
                          history={pr.history}
                          user={pr.user}
                          mediaItem={st.mediaItem}
                          change={this.change.bind(this)}
                          setUser={pr.setUser.bind(this)}
                      />
                      :
                      null
                  }

                  {st.selected === 'emailverify' ?
                      <Div
                          style={sty.registerContainer}
                        >
                          <EmailVerify
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            mediaItem={st.mediaItem}
                            change={this.change.bind(this)}
                            getNewUserAfterRegisterSuccess={pr.setUser.bind(this)}
                          />
                      </Div>
                      :
                      null
                  }

                  {st.selected === 'museum' ?
                    <Museum
                        screen={pr.screen}
                    />
                    :
                    null
                  }

                {st.selected === "guardianticketflow" && pr.userLoaded ?
                    <TicketFlow
                        screen={pr.screen}
                        selected={st.selected}
                        history={pr.history}
                        change={this.change.bind(this)}
                        setUser={pr.setUser.bind(this)}
                        user={pr.user}
                    />
                    :
                    null
                }

                {pr.footer &&
                    <SplashFooter
                        screen={pr.screen}
                        history={pr.history}
                    />
                }
            </Div>
        );
    }

    styles = {
        afterpartyHomeComponent: {},
        registerContainer: {
            padding: "60px 15px",
            margin: "0 auto",
            maxWidth: 400,
            width: "100%",
        },
        menuImageStyle: {
            zIndex: 1000,
            position: "absolute",
            top: 44,
            right: 48,
            width: 35,
            cursor: "pointer",
        },
    };
}
