import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Api from '../modules/Api';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Span from '../elements/Span';
import Spinner from '../elements/Spinner';
import WebappLoginRegister from '../elements/WebappLoginRegister';

import Div from './Div';

import apLogo from "../assets/images/ticketflow/ap logo.png";

export default class LoginRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: props.selected === "login" ? true : false,

            // NOTE: next= (for global search purposes)
            nextRoute: Utils.getRequest("next") ? Utils.getRequest("next").replace("^", "?") : false,
        };
        //console.log("NEXT 1", this.state['nextRoute']);
        this.autoRedirect(this.state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.autoRedirect(nextProps);
    }
    autoRedirect(nextProps) {
        //console.log("Next!2", nextProps, nextProps.nextRoute); // st.nextRoute
        const userIsLoggedIn = Utils.get(nextProps, "user.id") ? true : false;
        const nextRoute = Utils.getRequest("next") ? Utils.getRequest("next").replace("^", "?") : false
        if(userIsLoggedIn && nextRoute) {
            return this.goTo(nextRoute); // http://localhost:3004/webapp/login?next=p/conner_pre_survey_short_link
        } else {
            console.log("User not logged in");
        }
    }

    goTo(path) {
        const pr = this.props;
        console.log("NEXT GOTO",path);
        pr.history.push(`/${path}`);
    }

    handleNextRoute() {
        const st = this.state;

        if(st.nextRoute) {
            const refreshNextPage = Utils.getRequest("refresh") == 1;

            this.goTo(st.nextRoute);

            if(refreshNextPage) {
                window.location.reload();
            }

        } else {
            this.goTo("home");
        }
    }

    onLoginToggle() {
        this.setState({
            isLogin: !this.state.isLogin,
        });
    }

    generalLoginSignUpHandle(res) {
        if(Utils.get(res, "errors", []).length) {
            return;
        }

        this.handleNextRoute();
    }

    handleLogout() {
        Api.getLogout(() => { window.location.reload(); });
    }

    renderLoggedInOptions() {
        const pr = this.props;
        const sty = this.styles;

        if(!pr.userLoaded) {
            return (
                <Spinner
                    style={{marginTop: 100,}}
                />
            );
        }

        return (
            <Div style={{paddingTop: 150,}}>
                <Div className="alreadyLoggedInContainer" style={sty.alreadyLoggedInContainer}>
                    <Div>You are already logged in as:</Div>
                    <Div className="usernameLoginText" style={sty.usernameLoginText}>
                        @{Utils.get(pr.user, "username", "")}
                    </Div>
                </Div>
                <Div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", gap: 16}}>
                    <Button
                        className="homeLoginButton"
                        onClick={this.goTo.bind(this, "home")}
                        color="magenta"
                    >
                        Home
                    </Button>
                    <Button
                        className="settingsLoginButton"
                        onClick={this.goTo.bind(this, "settings")}
                        color="magenta"
                    >
                        Settings
                    </Button>
                </Div>
                <Div>
                    <Button
                        onClick={this.handleLogout.bind(this)}
                        size="small"
                        type="outlined"
                        style={{marginLeft: "auto", marginRight: "auto", marginTop: 16}}
                    >
                        Sign Out
                    </Button>
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const userIsLoggedIn = Utils.get(pr, "user.id") ? true : false;

        return (
            <Div className="loginRegisterComponent" style={sty.loginRegisterComponent}>
                <Image
                    src={apLogo}
                    style={sty.apLogoStyle}
                />
                <Div className="loginRegisterContainer" style={sty.loginRegisterContainer}>
                    {userIsLoggedIn ?
                        this.renderLoggedInOptions()
                        :
                        <WebappLoginRegister
                            isLogin={st.isLogin}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            setUser={pr.setUser.bind(this)}
                            onLoginToggle={this.onLoginToggle.bind(this)}
                            onLoginPhone={() => {}}
                            onLoginEmail={this.generalLoginSignUpHandle.bind(this)}
                            onSignUpPhone={() => {}}
                            onConfirmVerificationCodeLogin={this.generalLoginSignUpHandle.bind(this)}
                            onConfirmVerificationCodeSignUp={this.generalLoginSignUpHandle.bind(this)}
                        />
                    }
                </Div>
            </Div>
        )
    }

    styles = {
        loginRegisterComponent: {
            backgroundColor: Colors.indigo,
            minHeight: "100vh",
        },
        loginRegisterContainer: {
            maxWidth: 440,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 20,
            paddingTop: 120,
        },
        apLogoStyle: {
            position: "absolute",
            width: this.props.screen.mobile ? 190 : 240,
            top: 44,
            left: 44,
        },
        usernameLoginText: {
            fontWeight: 400,
            color: Colors.neonGreen,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
        },
        alreadyLoggedInContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            textAlign: "center",
            marginBottom: 20,
            fontSize: 32,
            fontWeight: 300,
        },
    };
}
