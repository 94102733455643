import React, { Fragment } from 'react';

import { Carousel } from 'react-responsive-carousel';
import Scroller from './Scroller';
import PlaylistScroller from './PlaylistScroller';
import axios from 'axios';
import ReactPlayer from "react-player";
import { AreaChart, LineChart, PieChart } from 'react-chartkick'
import 'chart.js'

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Input from '../elements/Input';

import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';

import SoinCard from '../components/SoinCard';
import Div from './Div';

export default class DashboardSoinItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
    };

  }

      changeEmail(e) {
        this.setState({email: e.target.value});
    }


  handlePosterClick(item) {
      /*
      if(item['json'].indexOf('permalink') != -1) {
          console.log("PERMALINK");
          let data = JSON.parse(item['json']);
          window.open( data['permalink'], "_blank");
          return;
      }
      */
      item["thumbnail"] = item["cover_big"];
      this.setState({
              selectedMediaItem: item,
      }, () => {
        this.props.history.push('/soin/'+item["name"])
        this.props.change('item', item);
      });
  }

    handleNewSoinClick(artist) {
      console.log("ARTIST", artist);
      const featured = {};
      this.setState({
              selectedMediaItem: artist,
      }, () => {
        this.props.change('newnft', artist);
      });
    }

    handleStartEvent(artist) {
        var data = {};
        Api.postAfterPartyEvent(data, (o) => {
                console.log("DATA", data);
            const url = "/room/clay_perry_music_room-517990781";
            this.setState({showProfileModal: false});
            Utils.gotoRoute(this.props, url);
        });
    }

    closeProfileModal() {
        this.setState({showProfileModal: false});
    }

  renderArtists(reppedArtists) {
    return reppedArtists.map( (artist) => {
        return (
            <Div style={{display:"flex",padding:5, color: "white",}}>
                <Div style={{marginRight:20, flex: 1}}>
                    <img style={{width:64,height:64, borderRadius:32,overflow:"hidden",}} src={artist["image_url"]} />
                </Div>
                <Div style={{marginRight:20,marginTop:20, flex: 1}}>
                    {artist["title"]}
                </Div>
                { false ?
                    <Div style={{marginRight:20, marginTop:8}}>
                        <Button
                            color="outline">
                            Sales Dashboard
                        </Button>
                    </Div>
                    :
                    null
                }
                <Div style={{marginRight:20, marginTop:8, flex: 1}}>
                    <Button
                        color="pinkGradient"
                        onClick={ () => { this.setState({email: Utils.get(artist, "title", "")+" "+new Date(), showProfileModal: true}); } }
                        >
                        Go Live Now
                    </Button>
                    { false ?
                        <Div title="Create new soin"
                            style={Styles.orangePlus}
                            onClick={this.handleNewSoinClick.bind(this, artist)}
                        >
                            +
                        </Div>
                        :
                        null
                    }
                </Div>
           </Div>
       )
    });
  }

  renderMyCollection() {
      let renderedTiles = [];
      let myCollection = [];
      if(this.props.user.items) {
          myCollection = this.props.user.items;
          //console.log("Render user items", myCollection);
          renderedTiles = myCollection.map( (album,idx) => {
               return <SoinCard
                        mediaItem={album}
                        size={180}
                        hideRemaining={true}
                        onClick={this.handlePosterClick.bind(this)}
                        />
            });
      } else {
          console.log("No user items", this.props.user);
      }
      let slidePercentage = 20;
      let transitionTime = 350;
      let centerMode = true;

      return <Div style={this.styles.sectionHead}>
        <Div style={this.styles.headline}>My Collection</Div>
            <Div style={{padding:30}}>
                {myCollection.length > 0
                    ?
                      <Carousel
                        key={"MarketplaceScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    <Div>No items yet</Div>
                }
            </Div>
        </Div>;
  }

  renderCollections() {
      return (
          <Div>
            <Div style={Styles.subhead}>
                Soin Collection
            </Div>
            { this.props.user && this.props.user.collections.length > 0 ?
                <Div style={{textAlign:"left"}}>
                    {this.renderMyCollection()}
                </Div>
                :
                <Div>You don't own any soins yet</Div>
            }
          </Div>
      );
  }

  renderMyNfts(myNfts) {
      let renderedTiles = [];
      if(myNfts) {
          //console.log("Render user items", myCollection);
          renderedTiles = myNfts.map( (album,idx) => {
               return <SoinCard
                        mediaItem={album}
                        size={180}
                        hideRemaining={true}
                        onClick={this.handlePosterClick.bind(this)}
                        />
            });
      } else {
          console.log("No user items", this.props.user);
      }
      let slidePercentage = 20;
      let transitionTime = 350;
      let centerMode = true;

      return <Div style={this.styles.sectionHead}>
        <Div style={this.styles.headline}>My NFTs</Div>
            <Div style={{padding:30}}>
                {myNfts.length > 0
                    ?
                      <Carousel
                        key={"MarketplaceScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    <Div>No items yet</Div>
                }
            </Div>
        </Div>;
  }

  renderNfts() {
      const nfts = Utils.get(this.props, "user.opensea", []);
      return (
          <Div>
            <Div style={Styles.subhead}>
                Other NFTs
            </Div>
            { nfts.length > 0 ?
                <Div style={{textAlign:"left"}}>
                    {this.renderMyNfts(nfts)}
                </Div>
                :
                <Div>You don't own any NFTs yet</Div>
            }
          </Div>
      );
  }


  render() {
    const reppedArtists = Utils.get(this.props, "seller", [])
    //console.log("PROPS", this.props.seller);
    const containerStyle = this.styles.container;
    const numActive = 0;
    return (
      <Div className="itemContainer"
        style={containerStyle}
      >
        { this.props.user && this.props.user["id"] && reppedArtists && reppedArtists.length > 0 ?
            <Div>
                { false ?
                    <Div className='row' style={this.styles.row}>
                        Current active items: {numActive}
                    </Div>
                    :
                    null
                }
                <Div className='row' style={this.styles.row}>
                    Creators under management: {reppedArtists.length}
                </Div>
                <Div className='row' style={{paddingTop:20, paddingLeft:10,}}>
                    {this.renderArtists(reppedArtists)}
                </Div>
                <Div className='row' style={this.styles.button}>
                </Div>
            </Div>
            :
            <Div style={{textAlign:"left", marginTop:30}}>
                You must be a registered artist or artist representative to mint collectibles.
            </Div>
        }

        {this.state.showProfileModal ?
            <Modal
                screen={this.props.screen}
                onRequestClose={this.closeProfileModal.bind(this)}
                color="purple"
                title="Start LIVE Event"
            >
                <Div style={{width:"100%"}}>
                    <Div style={{width:"100%",marginTop:10, display:"flex",flexDirection:"column", gap:20, }}>
                        <Div style={{marginTop:10, textAlign:"center",}}>Enter Event Title</Div>
                    </Div>
                    <Div style={{width:"100%",marginTop:20, display:"flex",flexDirection:"column", gap:20, }}>
                        <Div style={{justifyContent: "center"}}>
                            <Input
                                style={{width:"100%"}}
                                placeholder="Event Title"
                                value={this.state.email}
                                onChange={this.changeEmail.bind(this)}
                            />

                        </Div>
                    </Div>
                    <Div style={{marginBottom:20, display:"flex", justifyContent:"center"}}>
                        <Button onClick={this.handleStartEvent.bind(this)}>Start Now</Button>
                    </Div>
                </Div>
            </Modal>
            :
            null
        }

      </Div>
    );
  }
  styles = {
      container: {
          color: "white",
          marginTop: 70,
          background: Colors.indigo,
          padding: 5,
          textAlign: "left",
          paddingBottom: 150,
          minHeight: 1000,
      },
      row: {
          padding: 20,
          fontSize: 24,
          fontWeight: "bold",
      },


  };
}
