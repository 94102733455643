import React from 'react';

import Api from '../modules/Api';
import Utils from '../modules/Utils';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';

import Div from './Div';
import ProfileImage from './ProfileImage';
import Image from '../elements/Image';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
            this.state = {
        };
    }

    render() {
        const pr = this.props;
        const sty = this.styles;

        if(!Utils.get(pr.user, "email", false)) {
            return null;
        }

        const profileImage = Utils.get(pr.user, "photo", "/images/avatars/male01.png");

        return (
            <Div
                className="profileMenuComponent"
                title={pr.title}
                onClick={pr.onClick.bind(this)}
                style={{...sty.profileMenu, ...pr.style, ...StylesPlatform.cursorPointer}}
            >
                <BloatedCircleDefs scales={[48]} />
                <Image
                    style={sty.profileImage}
                    // src={Api.getFullUrl(profileImage)}
                    src={profileImage}
                />
                {pr.screen.width >= 992 || !pr.isProfileName ?
                    null
                    :
                    <Div style={{color: "white", marginLeft: 10}}>
                        {pr.user.username}
                    </Div>
                }
            </Div>
        );
    }

    styles = {
        profileMenu: {
            display: "flex",
            alignItems: "center",
            backgroundColor: Colors.purpleLight,
            borderRadius: 100,
            width: "fit-content",
        },
        profileImage: {
            // clipPath: "url(#bloated-circle-48)",
            // WebkitClipPath: "url(#bloated-circle-48)",
            borderRadius: 14,
            width: 48,
            height: 48,
        },
    };
}
