import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import '../App.css';

import Button from '../elements/Button';
import MetaMask from '../elements/MetaMask';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';
import FlashMessage from '../elements/FlashMessage';

import Div from './Div';
import RegisterFlow from './RegisterFlow';

import oneAfterpartyLogo from "../assets/images/splash/Group 171.png"
import oneHeaderBackgroundImage from "../assets/images/splash/ONE - 2.jpg"

import iconTwitterThick from "../assets/images/splash/twitter 23.png"
import iconDiscordThick from "../assets/images/splash/Discord 3.png"
import iconInstagramThick from "../assets/images/splash/Discord-1.png"

import twoHeaderBackgroundImage from "../assets/images/splash/TWO2x.jpg"
import twoHeaderBackgroundMobileImage from "../assets/images/splash/Two2x mobile.jpg"
import twoAfterpartyLogo from "../assets/images/splash/Afterparty.png"
import iconPurpleTwitterThick from "../assets/images/splash/twitter 2.png"
import iconPurpleDiscordThick from "../assets/images/splash/Discord 2.png"
import iconPurpleInstagramThick from "../assets/images/splash/ig 2.png"
import twoEnterContestImage from "../assets/images/splash/Graphic.png"
import twoGradientBackground1 from "../assets/images/splash/Gradient 2.png"
import twoVideoContest from "../assets/images/splash/video contest.png"
import twoCommunityImage from "../assets/images/splash/community.png"
import twoMusicFestivalBackground from "../assets/images/splash/Fest2x.jpg"
import twoMusicFestivalMobileBackground from "../assets/images/splash/Fest Section 1 mobile.jpg"
import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import menuImage from "../assets/images/splash/Menu.png"

export default class SplashUtopian extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            value: '',

            selected: "afterparty",

            eventItem: false,

            seller: null,

            notificationData: {
                message: "You should buy this NFT it's tight!",
            },
            showSuccessUtopianModal: false,
            showSuccessUtopianTitle: "",
            showSuccessUtopianMessage: "",

            showPresale: Utils.getRequest('presale'),
            collectionId: 1,
            guestlistBalance: {},
            masterCollectionId: 0,
            metamaskWalletId: false,
            includes: {},

            walletSuccess: false,

            showMintNowInfo: false,
        };
        //Utils.activateLogger();
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let tempState = {};

        tempState['selected'] = pr.route ? pr.route : 'afterparty';

        if(pr.includes && !Utils.getRequest('presale')) {
            tempState['includes'] = pr.includes;
            if(!Utils.getRequest('presale')) {
                tempState['showPresale'] = Utils.get(pr, "includes.presale");
            }
        }
        //console.log("tempState", tempState);
        this.setState(tempState, () => {
            this.handleRoute(tempState['selected']);
        });

        setTimeout(this.checkGuestlistBalance.bind(this, false), 1000);
        setInterval(this.checkGuestlistBalance.bind(this), 5 * 1000);

        // NOTE TODO: gleam
        // if(Utils.getCookie('remember_token')) {
        //     setTimeout(function () { $(".peices").toggleClass("active"); }, 500);
        // }
        // if(Utils.getCookie('remember_token')) {
        //     $("#btnEnterContest").hide();
        //     $("#txtEntered").show();
        // }
        Utils.handleScroll.bind(this)();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';

        let tempState = {};
        if(nextProps.includes) {
            tempState['includes'] = nextProps.includes;
        }
        if(this.state.selected != selected) {
            tempState['selected'] = selected;
        }
        if(Utils.get(nextProps, "includes.presale") && !Utils.getRequest('presale')) {
            tempState['showPresale'] = Utils.get(nextProps, "includes.presale");
        }

        this.setState(tempState);
    }

    handleRoute(selected) {
    }
    handleMetaMaskChange(changeType, data) {
        console.log("MM", changeType, data);
        if(changeType == 'accounts') {
            this.setState({accounts: data, metamaskWalletId:data[0]});
        }
    }
    newTab(link) {
        window.open(link, "_blank");
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    videoContestLink() {
        console.log("videoContestLink");
    }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "username"
        });
    }

    onWalletFlashDisplayNone() {
        this.setState({
            walletSuccess: false,
        });
    }

    onRequestRegisterModalClose() {
        this.setState({
            showRegisterFlowModal: false,
            registerModalBody: "username",
            errorMessages: [],
            rsvpModalMessage: "",
        });
    }

    onRequestSuccessUtopianModalClose() {
        this.setState({
            showSuccessUtopianModal: false,
            showSuccessUtopianMessage: "",
        });
    }

    setRegisteredSuccess(isSuccessful) {
        this.setState({
            registeredSuccess: isSuccessful,
        });
    }

    checkGuestlistBalance(updateState) {
        updateState = updateState == undefined ? true : updateState;
        const walletAddress = this.state.metamaskWalletId; //updateConnectedMetamaskWallet(false);
        if(walletAddress) {
            Api.getWalletPermission(this.state.masterCollectionId, walletAddress, (o, e) => {
                let temp = {};
                //console.log("o", o);
                let errors = Utils.get(o, "errors", []);
                temp['errors'] = errors;
                if(Utils.get(o, "success") == 1) {
                    temp['guestlistBalance'] = o.data;
                } else {
                    temp['guestlistBalance'] = {};
                }
                //console.log("temp", temp);
                this.setState(temp);
            });
        } else {
            console.log("No wallet selected.");
        }

    }

    updateConnectedMetamaskWallet(updateState) {
        /*
        updateState = updateState == undefined ? true : updateState;
        const curWalletId = Utils.get(this.state, "metamaskWalletId", "");
        let walletId = '';
        if(window.ethereum && window.ethereum.isMetaMask && window.ethereum.selectedAddress) {
            walletId = window.ethereum.selectedAddress;
        }
        if(!walletId || walletId.length == 0) {
            walletId = 'None. Please activate Metamask.';
        }
        if(updateState && curWalletId != walletId) {
            this.setState({metamaskWalletId: walletId});
        }
        return walletId;
        */
        return this.state.metamaskWalletId;
    }

    handleConnectMetamask() {
        const pr = this.props;

        if(window.ethereum && window.ethereum.isMetaMask) {
            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            window.ethereum.request({ method: 'eth_requestAccounts' })
            .then( (accounts) => {
                ll._("Attaching metamask wallets", accounts);
                this.setState({
                    wallet: accounts[0],
                    walletSuccess: true,
                }, () => {
                   if(pr.handleSetWallet) {
                    return pr.handleSetWallet(accounts);
                   }
                });
                // alert("Selected wallet: "+ accounts[0]);

            })
            .catch( (e) => {
                if(Utils.get(e, "code") == -32002) {
                    alert("Metamask error: Waiting for approval window. Please click on the browser Metamask toolbar icon to show Metamask window.");
                } else {
                    alert("Metamask error: "+Utils.get(e, "message"));
                    console.log("ERROR", e);
                }
            });
        } else {
            alert("Metamask not found. Please activate Metamask and refresh the page.");
        }
    }

    mintNotButtonClick() {
        this.setState({
            showMintNowInfo: true,
        });
    }

    handleBuyMetamask(quantity) {
        const pr = this.props;

        if(window.ethereum && window.ethereum.isMetaMask) {
            let walletAddress = this.state.metamaskWalletId; //ethereum.selectedAddress;
            //walletAddress = "0xebfd733fbdd1d91b75e941908bf3c748492ced29";
            console.log("handleBuyMetamask walletAddress", walletAddress);
            if(!walletAddress) {
                alert("No wallet attached");
            }
            let params = [];
            //const mintPassContractAddress = '0x1a963216e887bbcb6414be75c5e4d72dF27D6a64';
            const testMode = true;
            let weiVal = '0x300';
            const weiPerEth = 1000000000000000000;
            const utopianEth = 3.65;
            let costPerUtopian = utopianEth * weiPerEth;
            costPerUtopian = 3000000000000000; // 0.003
            const masterCollectionId = 0;
            let collectionId = 7;

            let mintPassContractAddress = '0xdfeda9c39b3571fe1d403e381ebc06e9d5d85fb5';
            if(this.state.showPresale == 11) {
                mintPassContractAddress = '0x55e1A47D3b308d128387F70f48Cef286d63B039D';
                collectionId = 1;
                costPerUtopian = 300;
            } else if(this.state.showPresale == 14) { // Actual presale
                collectionId = 24;
                costPerUtopian = utopianEth * weiPerEth;
            }
            weiVal = "0x" + Number(costPerUtopian).toString(16);

            const mintPassCmd = '0x57d67cd7000000000000000000000000';
            const mintToPassAddress = walletAddress.replace('0x', '');
            const collectionIdStr = Number(collectionId).toString(16);
            const collectionIdHex = Utils.pad(collectionIdStr, 64);
            const dataHex = Utils.pad("0", 64); //'0000000000000000000000000000000000000000000000000000000000000000';
            const abiCmd = mintPassCmd + mintToPassAddress + collectionIdHex + '00000000000000000000000000000000000000000000000000000000000000600000000000000000000000000000000000000000000000000000000000000001'+dataHex;

            //console.log("costPerUtopian", weiVal);
            //const mintToPassAddress = '0x2E00281abdEc577DE34c0764e9cB19b2B65B7ebc'.replace('0x', '');
            //console.log("collectionIdHex", collectionIdHex);
            //const abiCmd = mintPassCmd + mintToPassAddress+'0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000';
            //'0x57d67cd7000000000000000000000000' + '2e00281abdec577de34c0764e9cb19b2b65b7ebc' + '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000';
            const tx = {
              from: walletAddress,
              to: mintPassContractAddress,
              value: weiVal, //'0x300', // Amount in Wei
              data: abiCmd,
            };
            params.push(tx);
            // TODO: Doesn't work -- figure out how to do batch
            if(quantity > 1) {
                params.push(tx);
                console.log("Double Transactions", params);
            }

            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            console.log("Metamask request");
            const txHash = window.ethereum.request({
                method: 'eth_sendTransaction',
                params: params,
            }).then((o) => {
                console.log("BUY", o);
                Api.putWalletPermission(this.state.masterCollectionId, walletAddress, {}, () => {
                    //alert("Success");
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianTitle: "Transaction Processing",
                        showSuccessUtopianMessage: quantity === 1 ? "Check Metamask for transaction confirmation" : "You just bought two Utopians!",
                    }, this.checkGuestlistBalance.bind(this));
                });
            }).catch((o) => {
                console.log("Fail", o);
                this.setState({
                    showSuccessUtopianModal: true,
                    showSuccessUtopianTitle: "Transaction Error",
                    showSuccessUtopianMessage: "Transaction failed!",
                });
                if(o.code == 4001) {
                    // alert("Customer cancelled transaction");
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianMessage: "Customer cancelled transaction!",
                    });
                } else if(o.code == -32602) {
                    // alert("Invalid wallet selected. Maybe you're not logged into Metamask? Please select wallet in Metamask.");
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianMessage: "Invalid wallet selected. Maybe you're not logged into Metamask? Please select wallet in Metamask.",
                    });
                } else {
                    console.log("ERROR", o);
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianTitle: "Transaction Error",
                        showSuccessUtopianMessage: "Transaction failed : "+o.message + " ("+o.code+")",
                    });

                }
            });
        } else {
            alert("Metamask not found");
        }
    }

    onClickSocialMediaIcon(socialIcon) {
        switch(socialIcon) {
            case "twitter":
                window.open("https://www.twitter.com/afterparty", "_blank");
                break;
            case "discord":
                window.open("https://discord.gg/afterpartyNFT", "_blank");
                break;
            case "instagram":
                window.open("https://www.instagram.com/afterparty", "_blank");
                break;
            default:
        }
    }

    handleSetWallet(accounts, bitCloutPublicKey) {
        const pr = this.props;

        let data = null;
        if(!accounts && bitCloutPublicKey) {
            data = {params:{user:{bitclout_public_key:bitCloutPublicKey}}};
        } else {
            data = {params:{user:{wallet_id:accounts[0]}}};
        }

        Api.putMusicWallet(data, (res) => {
          if(res.errors.length) {
              ll._("ERROR wallet did not save", "red");
              return;
          }

          let updatedUser = res.data.user;
          pr.setUser(updatedUser);

          this.onRequestRegisterModalClose();

          this.setState({walletConnectedFlashMessage: true});
      });
    }

    renderPresaleSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!st.showPresale) {
            //return null;
        }
        const walletId = this.updateConnectedMetamaskWallet(false);
        const walletOnWhitelist = Utils.get(st.guestlistBalance, 'remaining') != undefined;
        const loggedIn = Utils.get(pr.user, "id");

        const walletConnected = Utils.get(pr.user, "wallet_id");
        // Need to add Connect Wallet or Wallet Table
        let presaleBuyMessage = "You do not currently have access to the pre-sale.";
        presaleBuyMessage += " We will notify you on future sales.";
        const selectedWallet = walletId && walletId.indexOf('None.') == -1 ? walletId : 'None. Please activate Metamask.';

        const mintpassesRemaining = Utils.get(st.guestlistBalance, 'remaining', 0) > 0 ? Utils.get(st.guestlistBalance, 'remaining', 0) : 0;
        return true ?
                    // <Div className="preSaleButtonContainer" style={{display: "flex"}}>
                    <Div>
                        { !loggedIn ?
                            <Button
                                className="presaleBuyButton1"
                                type="splash"
                                style={{...sty.splashMagentaButton, ...{marginLeft: "auto", marginRight: "auto"}}}
                                onClick={this.showLoginModalBody.bind(this)}
                            >
                                Register or Sign In {pr.screen.mobile ? <br/> : null} for Presale
                            </Button>
                            :
                            null
                        }
                        { walletOnWhitelist ?
                            <Div className="preSaleButtonContainer" style={sty.preSaleButtonContainer}>
                                <Button
                                    className="presaleBuyButton1"
                                    type="splash"
                                    style={sty.splashMagentaButton}
                                    onClick={this.handleBuyMetamask.bind(this, 1)}
                                >
                                    Buy MintPass
                                </Button>
                                { Utils.get(st.guestlistBalance, 'remaining') != undefined ?
                                    <Div style={{marginTop:12}}>
                                        MintPasses remaining: {mintpassesRemaining}
                                    </Div>
                                    :
                                    null
                                }
                            </Div>
                            :
                            null
                        }
                        { !walletOnWhitelist ?
                            <Div className="presaleBuyMessage" style={{textAlign: "center"}}>
                                {presaleBuyMessage}
                            </Div>
                            :
                            null
                        }
                        { Utils.get(pr.user, "id") ?
                            <Div>
                                <Button
                                    style={{textAlign: "center", marginTop: 20}}
                                    type="text"
                                    onClick={this.handleConnectMetamask.bind(this)}
                                >
                                    Connect Other Wallet
                                </Button>
                                <Div style={{textAlign: "center", marginTop: 20}}>
                                    Selected Wallet: <br/> {selectedWallet}
                                </Div>
                                <Button
                                    style={{textAlign: "center", marginTop: 20}}
                                    onClick={this.onClickSocialMediaIcon.bind(this, "discord")}
                                    type="text"
                                >
                                    For support, click here to visit<br />our Discord server
                                </Button>

                            </Div>
                            :
                            null
                        }
                    </Div>
                    :
                    null
     }

    renderHeaderTwoSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const headerTwoSection = StyleUtils.getMediaStyle('headerTwoSection', sty.headerTwoSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const utopianHeaderInfoContainer = StyleUtils.getMediaStyle('utopianHeaderInfoContainer', sty.utopianHeaderInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoMainTitle = StyleUtils.getMediaStyle('twoMainTitle', sty.twoMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoSubTitle = StyleUtils.getMediaStyle('twoSubTitle', sty.twoSubTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoAfterpartyLogoStyle = StyleUtils.getMediaStyle('twoAfterpartyLogoStyle', sty.twoAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoSubTitleLower = StyleUtils.getMediaStyle('twoSubTitleLower', sty.twoSubTitleLower, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const announceContainer = StyleUtils.getMediaStyle('announceContainer', sty.announceContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const preSaleMessage = StyleUtils.getMediaStyle('preSaleMessage', sty.preSaleMessage, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const applyForAccess = StyleUtils.getMediaStyle('applyForAccess', sty.applyForAccess, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="headerTwoSection" style={headerTwoSection}>

                <Div className="utopianHeaderInfoContainer" style={utopianHeaderInfoContainer}>
                    <Image
                        className="twoAfterpartyLogoStyle"
                        src={twoAfterpartyLogo}
                        style={twoAfterpartyLogoStyle}
                    />
                    <Div className="twoMainTitle" style={twoMainTitle}>
                        UTOPIAN COLLECTION
                    </Div>
                    <Div className="twoSubTitle" style={twoSubTitle}>
                        {/* <Span style={{fontWeight: 700}}>1,500</Span> unique generative Utopians */}
                        This is Afterparty’s All-Access Collection
                    </Div>
                    <Div className="twoSubTitle" style={{...twoSubTitle, ...{fontSize: 20}}}>
                        1,500 unique generative works of art that serve as keys to Afterparty’s world-class experiences
                    </Div>

                    <Div className="applyForAccess" style={applyForAccess}>
                        <Button
                            className="twoSubTitle"
                            type="text"
                            onClick={this.newTab.bind(this, "https://cd437dx3xys.typeform.com/to/srZFjI7R?typeform-source=linktr.ee")}
                            isSpan
                        >
                            Apply for Access
                        </Button>
                        &nbsp;to Private Sale Guestlist
                    </Div>

                    <MetaMask
                        show={Utils.getRequest('debug') == 14}
                        onChange={this.handleMetaMaskChange.bind(this)}
                    />

                    <Div className="announceContainer" style={announceContainer}>
                        <Div style={{flex: 1, marginBottom: 20}}>
                            <Div className="twoSubTitleLower" style={twoSubTitleLower}>
                                Join the community
                            </Div>
                            {this.renderSocialMediaIcons(pr.screen.mobile ? 30 : 40, "purple")}
                        </Div>

                        <Div style={{flex: 1}}>
                            {false && st.showPresale != 16 ?
                                <Div className="preSaleMessage" style={sty.preSaleMessage}>
                                    {/* Pre-Sale for MintPass starts Thursday, 2/10 at 2pm, PT. */}
                                    If you have received confirmation of Guestlist Access
                                    <br/>
                                    <Button
                                        className="mintNowButton"
                                        style={{...sty.utopianLearnMoreButton, ...{marginLeft: "auto", marginRight: "auto", marginTop: 20, marginBottom: 20}}}
                                        type="splash"
                                        onClick={this.mintNotButtonClick.bind(this)}
                                    >
                                        Mint Now
                                    </Button>
                                    {st.showMintNowInfo ?
                                        <Div className="guestListText" style={{color: Colors.splashDarkPurple, fontWeight: "normal"}}>
                                            You dont currently have guest list access, apply for access <Button type="text" onClick={this.newTab.bind(this, "https://docs.google.com/forms/d/e/1FAIpQLSepp88YMiAlDq9tUcfxiM5E9DNXy3GDZ6udC8zjq6i43UOefg/viewform")}>here</Button>
                                        </Div>
                                        :
                                        null
                                    }
                                </Div>
                                :
                                null
                            }

                            { st.showPresale == 11 || st.showPresale == 12 || st.showPresale == 14 ?
                                this.renderPresaleSection()
                                :
                                null
                            }
                            {st.showPresale == 15 ?
                                <Div style={{paddingTop:10, paddingBottom:10, fontWeight:"bold",fontSize:24,border:"2px solid rgb(31, 7, 65)", textAlign:"center",}}>
                                Pre-Sale SOLD OUT
                                </Div>
                                :
                                null
                            }
                            {st.showPresale == 16 ?
                                <Div style={{maxWidth:600,padding:20, border:"2px solid rgb(31, 7, 65)", textAlign:"center", borderRadius: 5}}>
                                    <Div style={{fontWeight:"bold",fontSize:24,paddingBottom:10,}}>The Private Community Sale has Concluded</Div>
                                    <Div>If you are interested in purchasing a Utopian, please fill out our Interest form
                                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSepp88YMiAlDq9tUcfxiM5E9DNXy3GDZ6udC8zjq6i43UOefg/viewform"> HERE</a>
                                    </Div>
                                </Div>
                                :
                                null
                            }
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    renderTwoEnterContestSection() {
        const pr = this.props;
        const sty = this.styles;

        const utopianContestRow = StyleUtils.getMediaStyle('utopianContestRow', sty.utopianContestRow, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const utopianContestTitle = StyleUtils.getMediaStyle('utopianContestTitle', sty.utopianContestTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="twoEnterContestSection" style={sty.twoEnterContestSection}>
                <Div className="utopianContestRow" style={utopianContestRow}>
                    <Div className="" style={{padding: 30}}>
                        <Div className="utopianContestTitle" style={utopianContestTitle}>
                            UTOPIAN BENEFITS
                        </Div>
                        <Div className="utopianContestDescription" style={sty.utopianContestDescription}>
                            {/* The Afterparty Utopian collection consists of <Span style={{fontWeight: 700}}>1,500</Span> NFTs – each collectible is completely unique and lives forever on the <Span style={{fontWeight: 700}}>Ethereum blockchain</Span>. Every Afterparty Utopian collectible will be exclusively minted IRL at the Afterparty House in Los Angeles and be a gateway into the entire Afterparty universe. The holders of these NFTs have the keys to the Afterparty kingdom. */}
                            <ul>
                                <li className="bulletedList" style={sty.bulletedList}>Artist Level All-Access to the Afterparty NFT Art & Music Festival Vegas for AT LEAST 5 years, plus access to "Utopian Only" Artist areas at the festival <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>One (1) free Afterparty Guardian NFT airdropped <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Two (2) free Afterparty Passes airdopped <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Priority Guestlist for all upcoming Afterparty and Creator NFT drops <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Artist Level All Access +1 to Afterparty's Fall event in Los Angeles, plus access to "Utopian Only" Artist areas at the festival <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Access to select events at the Afterparty house in Los Angeles <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Priority Access for Afterparty Pop-up events NFT.NYC, Art Basel Miami and more <br/></li>
                                <li className="bulletedList" style={sty.bulletedList}>Surprise and Delight: Afterparty is going to continue to deliver world-class experiences and push the boundaries of innovation with our creator partners on their drops. <br/></li>
                            </ul>

                        </Div>
                        <Button
                            className="enterContestButton"
                            type="splash"
                            style={sty.enterContestButton}
                            // onClick={this.showLoginModalBody.bind(this)}
                            onClick={this.newTab.bind(this, "https://cd437dx3xys.typeform.com/to/srZFjI7R?typeform-source=linktr.ee")}
                        >
                            Apply For Private Sale Access
                        </Button>

                        {/* <Div class="container">
                          <Div class="peices" id="gleam">
                            <a
                                class="e-widget no-button generic-loader"
                                href="https://gleam.io/mwGvb/afterparty-collection-contest"
                                rel="nofollow"
                            >
                                Afterparty Collection Contest
                            </a>
                        </Div>
                    </Div> */}
                    </Div>
                    <Image
                        className="twoEnterContestImage"
                        src={twoEnterContestImage}
                        style={sty.twoEnterContestImage}
                    />
                </Div>

            </Div>
        )
    }

    renderTwoAfterpartyHouseSection() {
        const pr = this.props;
        const sty = this.styles;

        const afterpartyHouseRow = StyleUtils.getMediaStyle('afterpartyHouseRow', sty.afterpartyHouseRow, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyHouseTitle = StyleUtils.getMediaStyle('afterpartyHouseTitle', sty.afterpartyHouseTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoVideoContestStyle = StyleUtils.getMediaStyle('twoVideoContest', sty.twoVideoContest, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="twoAfterpartyHouseSection" style={sty.twoAfterpartyHouseSection}>
                <Div className="afterpartyHouseRow" style={afterpartyHouseRow}>
                    <Image
                        className="twoVideoContest"
                        src={twoVideoContest}
                        style={{...twoVideoContestStyle, ...StylesPlatform.cursorPointer}}
                        onClick={this.newTab.bind(this, "https://www.youtube.com/watch?v=wvDrJY9DtcQ&ab_channel=AfterpartyNFT")}
                    />
                    <Div style={{paddingLeft: 30, paddingRight: 30}}>
                        <Div className="afterpartyHouseTitle" style={afterpartyHouseTitle}>
                            THE AFTERPARTY HOUSE
                        </Div>
                        <Div className="afterpartyHouseDescription" style={sty.afterpartyHouseDescription}>
                            On November 10, 2021, the Afterparty House was born. The energy from that night left a dent in the crypto universe. The Afterparty Utopian Collection is not just minted here but also serves as your key to curated events at the house, bringing together the most ambitious, risk-taking artists, creatives, and entrepreneurs.
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    renderTwoHolderSpotlightSection() {
        const pr = this.props;
        const sty = this.styles;

        const holderSpotlightTitle = StyleUtils.getMediaStyle('holderSpotlightTitle', sty.holderSpotlightTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoHolderSpotlightSection = StyleUtils.getMediaStyle('twoHolderSpotlightSection', sty.twoHolderSpotlightSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const influencerColumnContainer = StyleUtils.getMediaStyle('influencerColumnContainer', sty.influencerColumnContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="twoHolderSpotlightSection" style={twoHolderSpotlightSection}>
                <Div className="holderSpotlightTitle" style={holderSpotlightTitle}>
                    UTOPIAN HOLDER SPOTLIGHT
                </Div>
                <Div className="holderSpotlightDescription" style={sty.holderSpotlightDescription}>
                    {/* The Afterparty Utopian collection consists of <Span style={{fontWeight: 700}}>1,500</Span> NFTs – each collectible is completely unique and lives forever on the <Span style={{fontWeight: 700}}>Ethereum blockchain</Span>. Every Afterparty Utopian collectible will be exclusively minted IRL at the Afterparty House in Los Angeles and be a gateway into the entire Afterparty universe. The holders of these NFTs have the keys to the Afterparty kingdom. */}
                    Afterparty is a collection of the most innovative, diverse and dynamic creators building out the Web3 future we want. Here are just a few of the people we have minted Utopians for in-real-life at the Afterparty home.
                </Div>

                <Div className="influencerColumnContainer" style={influencerColumnContainer}>
                    <Div className="influencerColumn" style={sty.influencerColumn}>
                        <Div className="influencerRow" style={sty.influencerRow}>TV Personality&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CXRWupdvQn3/")}>David Dobrik</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Supermodel&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZND40zPqVz/")}>Heidi Klum</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Actor&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZAacJ4vQQm/")}>Josh Duhamel</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Songwriter / Producer&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZ0AIpnPPEM/")}>Nile Rodgers</Button></Div>
                    </Div>
                    <Div className="influencerColumn" style={sty.influencerColumn}>
                        <Div className="influencerRow" style={sty.influencerRow}>Singer / songwriter&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CXRQqh_JGgK/")}>Sia</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Actress&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZdEolxPmwm/")}>Madelyn Cline</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Creator&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZf489zNLuj/")}>Hannah Stocking</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Creators&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CYo7jZthlyX/")}>Bryce Hall and Josh Richards</Button></Div>
                    </Div>
                    <Div className="influencerColumn" style={sty.influencerColumn}>
                        <Div className="influencerRow" style={sty.influencerRow}>Creator&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CYcVW2ChDEL/")}>Vinnie Hacker</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Singer / songwriter&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CYaml0hLTdY/")}>Ashe</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Rocker&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CXRUF2FPbAS/")}>Jaden Hossler</Button></Div>
                        <Div className="influencerRow" style={sty.influencerRow}>Creator&nbsp;<Button type="text" onClick={this.newTab.bind(this, "https://www.instagram.com/p/CZ2o-ykvj5M/")}>Loren Gray</Button></Div>
                    </Div>
                </Div>

                <Image
                    className="twoCommunityImage"
                    src={twoCommunityImage}
                    style={sty.twoCommunityImage}
                />
            </Div>
        )
    }

    renderTwoMusicFestivalSection() {
        const pr = this.props;
        const sty = this.styles;

        const twoMusicFestivalContainer = StyleUtils.getMediaStyle('twoMusicFestivalContainer', sty.twoMusicFestivalContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const twoMusicFestivalTitle = StyleUtils.getMediaStyle('twoMusicFestivalTitle', sty.twoMusicFestivalTitle, sty, StyleUtils.getWidthType(pr.screen.width));
        const twoMusicFestivalSection = StyleUtils.getMediaStyle('twoMusicFestivalSection', sty.twoMusicFestivalSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="twoMusicFestivalSection" style={twoMusicFestivalSection}>
                <Div className="twoMusicFestivalContainer" style={twoMusicFestivalContainer}>
                    <Image
                        className="twoMusicFestivalAfterpartyLogo"
                        src={oneAfterpartyLogo}
                        style={sty.twoMusicFestivalAfterpartyLogo}
                    />
                    <Div className="twoMusicFestivalTitle" style={twoMusicFestivalTitle}>
                        NFT ART + MUSIC FESTIVAL
                    </Div>
                    <Div className="twoMusicFestivalSubtitle" style={sty.twoMusicFestivalSubtitle}>
                        {/* Afterparty will be launching an NFT Art & Music festival with some of the most exciting creators in the world at a venue that is so perfect it appears to have been purpose built for hosting an event that highlights the artists pushing the boundaries of art and sound. */}
                        The Afterparty NFT Art & Music festival in Vegas March 18/19, 2022 is the World’s First NFT-gated festival.  With The Chainsmokers, The Kid Laroi, Gryffin, Lany, Sofi Tukker, Bob Moses and dozens more performing over 3 stages, this is the celebration NFT art deserves. We have many of the most exciting creators in the world attending in a venue that is so perfect it appears to have been purpose built for hosting an event that highlights artists pushing the boundaries of art and sound.
                    </Div>
                    <Button
                        style={sty.utopianLearnMoreButton}
                        type="splash"
                        onClick={this.newTab.bind(this, "https://afterparty.com/sl/festival")}
                    >
                        Learn more
                    </Button>
                </Div>
            </Div>
        )
    }

    renderSplashFooter() {
        const pr = this.props;
        const sty = this.styles;

        const splashFooterTitle = StyleUtils.getMediaStyle('splashFooterTitle', sty.splashFooterTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="splashFooterSection" style={sty.splashFooterSection}>
                <Div className="splashFooterRow" style={sty.splashFooterRow}>
                    <Div className="splashFooterTitle" style={splashFooterTitle}>
                        Join the community
                    </Div>

                    {this.renderSocialMediaIcons(pr.screen.mobile ? 60 : 80)}

                    <Image
                        className="afterpartyLogoOnly"
                        src={afterpartyLogoOnly}
                        style={sty.afterpartyLogoOnly}
                    />

                    <Div className="footerLinks" style={sty.footerLinks}>
                        <Div
                            onClick={this.newTab.bind(this, "tos")}
                            style={StylesPlatform.cursorPointer}
                        >
                            Terms
                        </Div>
                        <Div
                            onClick={this.newTab.bind(this, "dmca")}
                            style={StylesPlatform.cursorPointer}
                        >
                            DMCA
                        </Div>
                        <Div
                            onClick={this.newTab.bind(this, "privacypolicy")}
                            style={StylesPlatform.cursorPointer}
                        >
                            Privacy
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    renderBigJpg() {
        return (
            <Div style={{position: "relative"}}>
                <Image
                    src={pageOneSplash}
                    style={{width: "100%"}}
                />
                <Div style={{position: "absolute", display: "flex", bottom: 200, left: 0}}>
                    <Div style={{backgroundColor: "red", width: 100, height: 100}}>ICON</Div>
                    <Div style={{backgroundColor: "green", width: 100, height: 100}}>ICON</Div>
                    <Div style={{backgroundColor: "blue", width: 100, height: 100}}>ICON</Div>
                </Div>
            </Div>
        )
    }

    renderSocialMediaIcons(size, color) {
        let sty = this.styles;

        return (
            <Div className="socialMediaIcons" style={sty.socialMediaIcons}>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                    <Image
                        src={color === "purple" ? iconPurpleTwitterThick : iconTwitterThick}
                        style={{width: size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "twitter")}
                    />
                </Div>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                    <Image
                        src={color === "purple" ? iconPurpleDiscordThick : iconDiscordThick}
                        style={{width: size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "discord")}
                    />
                </Div>
                <Div className="socialMediaIcon" style={{...sty.socialMediaIcon, ...StylesPlatform.cursorPointer}}>
                    <Image
                        src={color === "purple" ? iconPurpleInstagramThick : iconInstagramThick}
                        style={{width: size}}
                        onClick={this.onClickSocialMediaIcon.bind(this, "instagram")}
                    />
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // const afterpartyStaticHomeComponent = StyleUtils.getMediaStyle('afterpartyStaticHomeComponent', sty.afterpartyStaticHomeComponent, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        // const dialogTitle = <Div>Enter the contest to win an Afterparty Utopian</Div>;
        const dialogTitle = <Div>Register or Login to buy an Afterparty Utopian</Div>;

        return (
            <div ref={this.childDiv} className="afterpartyStaticHomeComponent" style={sty.afterpartyStaticHomeComponent}>
                {st.walletSuccess ?
                    <FlashMessage
                        type="success"
                        message="Wallet Connected!"
                        subMessage={<Div>Wallet: {Utils.abbreviateHash(st.metamaskWalletId)}<br/>To change selected wallet, click on your browser's MetaMask toolbar icon</Div>}
                        flashMessageContainerStyle={{position: "absolute", width: "100%", boxSizing: "border-box"}}
                        onFlashDisplayNone={this.onWalletFlashDisplayNone.bind(this)}
                    />
                    :
                    null
                }

                {this.renderHeaderTwoSection()}
                {this.renderTwoEnterContestSection()}
                {/* this.renderTwoAfterpartyHouseSection() */}
                {this.renderTwoHolderSpotlightSection()}
                {/* this.renderTwoMusicFestivalSection() */}

                {this.renderSplashFooter()}
                <RegisterFlow
                    user={pr.user}
                    screen={pr.screen}
                    usernameModalTitle={dialogTitle}
                    successModalMessage={<Div>Already have {Utils.get(pr, "eventItem.title")} social token?{Utils.get(pr, "screen.mobile") ? null : <br/>}</Div>}
                    registerModalBody={st.registerModalBody}
                    showRegisterFlowModal={st.showRegisterFlowModal}
                    eventItem={pr.eventItem}
                    isPhoneInput
                    eventType={4}
                    successMessageData={{line1: "Thank you for joining the community."}}
                    successModalMessage=""
                    setUser={pr.setUser.bind(this)}
                    onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
                    cellphonePlaceholder="Enter phone number for text updates (optional)"
                    listings={[{}]}
                    isVerificationCode={false}
                    isWalletTableOnSuccess
                />

                {st.showSuccessUtopianModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.onRequestSuccessUtopianModalClose.bind(this)}
                        className="successUtopianModal"
                        color="purple"
                        title={st.showSuccessUtopianTitle}
                    >
                        <Div style={{paddingTop: 20, paddingBottom: 30}}>
                            {st.showSuccessUtopianMessage}
                        </Div>
                    </Modal>
                    :
                    null
                }
            </div>
        );
    }
                //  handleSetWallet={this.handleSetWallet.bind(this)}

    styles = {
        splashMagentaButton: {
            backgroundColor: Colors.splashMagenta,
            color: "white",
            marginTop: 12,
            marginBottom: 12,
        },
        afterpartyStaticHomeComponent: {
            backgroundColor: Colors.splashDarkPurple,
        },

        headerTwoSection: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 852,
            backgroundImage: `url("${twoHeaderBackgroundImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            padding: 20,
        },
        headerTwoSection_md: {
            backgroundPosition: "right bottom",
            backgroundImage: `url("${twoHeaderBackgroundMobileImage}")`,
            alignItems: this.props.screen.mobileLandscape ? "intitial" : "center",
        },
        utopianHeaderInfoContainer: {
            padding: 56,
            backgroundColor: "#FFF8E7",
            width: "fit-content",
            borderRadius: 40,
            filter: "drop-shadow(0px 4px 10px rgba(0,0,0,0.5))",
            marginLeft: "auto",
            marginRight: 153,
            color: Colors.splashDarkPurple,
        },
        utopianHeaderInfoContainer_md: {
            marginLeft: "auto",
            marginRight: "auto",
            padding: 30,
            marginTop: this.props.screen.mobileLandscape ? 0 : 300,
            height: this.props.screen.mobileLandscape ? "fit-content" : "auto",
        },
        twoAfterpartyLogoStyle: {
            width: 254,
        },
        twoAfterpartyLogoStyle_md: {
            width: 180,
        },
        twoMainTitle: {
            fontFamily: "Coluna",
            fontWeight: 400,
            fontSize: 90,
            marginTop: 10,
            marginRight: 50,
        },
        twoMainTitle_md: {
            fontSize: 44,
            lineHeight: "48px",
            marginRight: 0,
        },
        twoSubTitle: {
            fontFamily: "Mont Demo",
            fontSize: 24,
            marginBottom: 24,
            maxWidth: 480,
            width: "100%",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
        },
        twoSubTitle_md: {
            fontSize: 15,
            marginBottom: 16,
        },
        twoSubTitleLower: {
            fontFamily: "Mont Demo",
            fontSize: 16,
            marginBottom: 15,
        },
        twoSubTitleLower_md: {
            fontSize: 12,
        },
        preSaleMessage: {
          textAlign: "center",
          fontSize: 18,
          fontWeight: "bold",
          color: "rgb(201, 45, 122)",
          marginBottom: 10,
        },
        preSaleMessage_md: {
            paddingTop: 20,
        },

        twoEnterContestSection: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Colors.splashDarkPurple,
            minHeight: 860,
        },
        twoEnterContestImage: {
            width: "100%",
            maxWidth: 568,
        },
        utopianContestTitle: {
            fontFamily: "Coluna",
            color: "white",
            fontSize: 64,
            textAlign: "center",
        },
        utopianContestTitle_md: {
            fontSize: 60,
        },
        utopianContestDescription: {
            fontFamily: "Mont Demo",
            fontWeight: 100,
            color: "white",
            fontSize: 18,
            marginTop: 20,
            marginBottom: 40,
        },
        utopianContestRow: {
            display: "flex",
            alignItems: "center",
            maxWidth: 1138,
            width: "100%",
        },
        utopianContestRow_md: {
            flexDirection: "column-reverse",
        },
        enterContestButton: {
            backgroundColor: Colors.splashOrange,
            color: Colors.splashDarkPurple,
            marginTop: 10,
            marginLeft: "auto",
            marginRight: "auto",
        },
        twoAfterpartyHouseSection: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: 826,
            backgroundImage: `url("${twoGradientBackground1}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#CAE5FF", // splashLightBlue
        },
        afterpartyHouseTitle: {
            fontFamily: "Coluna",
            color: Colors.splashDarkPurple,
            fontSize: 64,
        },
        afterpartyHouseTitle_md: {
            fontSize: 60,
            marginTop: 20,
            lineHeight: "60px",
        },
        afterpartyHouseDescription: {
            fontFamily: "Mont Demo",
            color: Colors.splashDarkPurple,
            fontSize: 18,
            marginTop: 20,
        },
        twoVideoContest: {
            maxWidth: 580,
            width: "100%",
            marginRight: 60,
        },
        twoVideoContest_md: {
            marginRight: 0,
        },
        afterpartyHouseRow: {
            display: "flex",
            alignItems: "center",
            maxWidth: 1165,
            width: "100%",
        },
        afterpartyHouseRow_md: {
            flexDirection: "column",
        },
        preSaleButtonContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        twoHolderSpotlightSection: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: Colors.splashDarkPurple,
            color: "white",
            minHeight: 1100,
            padding: 30,
        },
        twoHolderSpotlightSection_md: {
            minHeight: 900,
        },
        holderSpotlightTitle: {
            fontFamily: "Coluna",
            color: "white",
            fontSize: 64,
            textAlign: "center",
        },
        holderSpotlightTitle_md: {
            fontSize: 60,
            lineHeight: "60px",
        },
        holderSpotlightDescription: {
            fontFamily: "Mont Demo",
            fontWeight: 100,
            color: "white",
            fontSize: 18,
            textAlign: "center",
            maxWidth: 692,
            marginTop: 20,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
        },
        twoCommunityImage: {
            maxWidth: 939,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
        },

        twoMusicFestivalSection: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 838,
            backgroundImage: `url("${twoMusicFestivalBackground}")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        },
        twoMusicFestivalSection_md: {
            backgroundPosition: "right bottom",
            backgroundImage: `url("${twoMusicFestivalMobileBackground}")`,
        },
        twoMusicFestivalContainer: {
            color: "white",
            width: "fit-content",
            marginLeft: 200,
            marginRight: "auto",
            maxWidth: 665,
            boxSizing: "border-box",
        },
        twoMusicFestivalContainer_md: {
            marginLeft: 0,
            padding: 30,
        },
        twoMusicFestivalContainer_xs: {
            marginLeft: 0,
            padding: 20,
        },
        twoMusicFestivalAfterpartyLogo: {
            width: 254,
        },
        twoMusicFestivalTitle: {
            fontFamily: "Adina",
            fontSize: 40,
            fontWeight: 700,
            marginTop: 20,
        },
        twoMusicFestivalTitle_md: {
            fontSize: 30,
        },
        twoMusicFestivalTitle_xs: {
            fontSize: 24,

        },
        twoMusicFestivalSubtitle: {
            fontFamily: "Mont Demo",
            fontSize: 18,
            marginTop: 20,
            marginBottom: 20,
        },
        utopianLearnMoreButton: {
            backgroundColor: Colors.splashMagenta,
            color: "white",
        },

        socialMediaIcons: {
            display: "flex",
        },
        socialMediaIcon: {
            // marginLeft: 10,
            marginRight: 20,
        },

        splashFooterSection: {
            display: "flex",
            alignItems: "center",
            height: 850,
            backgroundColor: Colors.splashDarkPurple,
        },
        splashFooterRow: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
        },
        splashFooterTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 48,
            color: "white",
            marginBottom: 30,
            textAlign: "center",
        },
        splashFooterTitle_md: {
            fontSize: 30,
        },
        afterpartyLogoOnly: {
            marginTop: 132,
            width: 89,
        },
        footerLinks: {
            display: "flex",
            maxWidth: 292,
            marginTop: 47,
            width: "100%",
            justifyContent: "space-between",
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 14,
        },
        announceContainer: {
            display: "flex",
            maxWidth: 600,
        },
        announceContainer_md: {
            flexDirection: "column",
        },
        bulletedList: {
            fontSize: 16,
            marginBottom: 10,
        },
        influencerColumnContainer: {
            display: "flex",
            maxWidth: 1000,
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 40,
        },
        influencerColumnContainer_md: {
            flexDirection: "column",
        },
        influencerColumn: {
            textAlign: "center",
            flex: 1,
            marginBottom: 20,
        },
        influencerRow: {
            display: "flex",
            justifyContent: "center",
             marginBottom: 10,
        },
        applyForAccess: {
            display: "flex",
            fontSize: 20,
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 20,
            justifyContent: "center",
        },
        applyForAccess_md: {
            display: "block",
            textAlign: "center",
        },
    };
}
