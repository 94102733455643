import React from 'react';
import moment from "moment";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Api from '../modules/Api';

import Button from '../elements/Button';
import Span from '../elements/Span';

import Div from './Div';
import AfterpartyRoomBadge from '../components/AfterpartyRoomBadge';

export default class ConnectWallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            wallet: null,
            bitcloutActive: true,
            desoBalance: {
                nanoBalance:0.0,
                nanoBalanceCreatorCoin:0.0,
                checkTime:"not yet"
            },
            desoChecking: false,
        };
        this.init = false;
        this.iframe = null;
        this.pendingRequests = [];
        this.identityWindow = null;
    }

    componentDidMount() {
        if(window) {
            window.addEventListener('message', this.handleEventMessage.bind(this));
        }
        this.startDesoCheck();
    }

    componentWillUnmount() {
        if(window) {
            window.removeEventListener('message',this.keydownHandler);
        }
    }
    checkDesoBalance(userKey) {
        const desoKey = this.getDesoListingKey();
        if(!userKey) {
            userKey = this.getUserDesoKey(); // 'BC1YLgY4CSocS4ssteGmcyo4chMKnaKDsCW4YNgnjCx9o3ABh7QW1yR'
        }
        const roomId = Utils.get(this.props.eventItem, 'room.id')
        Api.postDesoBalance({userKey:userKey, creatorCoinKey:desoKey, roomId:roomId}, (o) => {
            let temp = this.state.desoBalance;
            const nanoBalance = Utils.get(o, 'data.nanoBalance');
            const nanoBalanceCreatorCoin = Utils.get(o, 'data.nanoBalanceCreatorCoin');

            // If all in, then show Enter Room as Guest button
            window.letAllIn = Utils.get(o, 'data.letallin');
            if(nanoBalance) {
                temp.nanoBalance = nanoBalance;
            }
            if(nanoBalanceCreatorCoin) {
                temp.nanoBalanceCreatorCoin = nanoBalanceCreatorCoin;
                if(true) {
                    window.qualifies = 1;
                }
            }
            if(true) {
                //window.letAllIn = true;
            }
            temp.checkTime = moment().format('h:mm');
            this.setState({desoBalance: temp});
        });
    }

    startDesoCheck(userKey) {
        let check = true;
        if(Utils.getRequest('nocheck') || this.props.noDesoCheck) {
            check = false;
        }
        if(check) {
            if(window.desoCheck) {
                window.clearInterval(window.desoCheck);
                window.desoCheck = undefined;
            }
            if(!userKey) {
                userKey = this.getUserDesoKey(); // 'BC1YLgY4CSocS4ssteGmcyo4chMKnaKDsCW4YNgnjCx9o3ABh7QW1yR'
            }
            // TODO: Redo check balance because this will be general call
            this.checkDesoBalance();
            window.desoCheck = setInterval( this.checkDesoBalance.bind(this), 5000);
            if(!userKey) {
                ll._("No Deso/Bitclout Key set for this user", "red");
                return;
            }
        }
    }

    getDesoListingKey() {
        const event = this.props.eventItem;
        const listings = Utils.get(event, "listings", []);
        // TODO: Search listings for DESO listing
        const listing = Utils.get(listings, "0", {});
        // Bryce Hall -- BC1YLfzSPMMyV4FAwuBieJHieHZc2tERMmqYkRKurHUy8zzLQrADfiX
        const desoKey = Utils.get(listing, 'bitclout_public_key');
        return desoKey;
    }

    getUserDesoKey() {
        let wallets = Utils.get(this.props.user, "wallets", []);
        let walletId = false;
        for(var idx in wallets) {
            const wallet = wallets[idx];
            if(wallet['wallet_type_id'] == 2) {
                walletId = wallet['wallet_id'];
                break;
            }
        }

        return walletId;
    }

    handleBuyClick() {
        const desoKey = this.getDesoListingKey();
        const walletId = this.getUserDesoKey();

        if(!walletId) {
            alert("No DeSo wallet found");
            return;
        }
        //const walletId = 'BC1YLiiDZEtyUc65nWFuSH62XnfXfbYfJLafV5mKYRA1okUXGrBYopv'; // Dave
        //const walletId = 'BC1YLgY4CSocS4ssteGmcyo4chMKnaKDsCW4YNgnjCx9o3ABh7QW1yR'

        const data = {
            userKey: walletId,
            creatorCoinKey: desoKey,
            desoAmount: 600,
        }
        Api.postDesoTx(data, (o) => {
            //console.log(o);
            const txHex = Utils.get(o, 'data.TransactionHex');
            if(txHex) { // NOTE Transaction Hex
                this.approveWindow = window.open('https://identity.deso.org/approve?tx='+txHex, null, 'toolbar=no, width=800, height=1000, top=0, left=0');
            } else {
                alert("You don't have enough DESO to buy the creator coin. Click on the Buy Deso link below.");
            }
        });
    }

    handleApproveTx(signedTransactionHex) {
        const data = {
            signedTransactionHex: signedTransactionHex,
        }
        Api.postDesoCommit(data, (o) => {
            console.log("postDesoCommit", o);
            this.checkDesoBalance();
            if(o.success) {
                this.setState({
                    showDesoSuccessModal: true,
                });
            }
        });

    }

    handleBuyDeso() {
        const url =  'https://buy.deso.org';
        window.open(url, '_blank');
    }

    handleConnectMetamask() {
        const pr = this.props;

        if(window.ethereum && window.ethereum.isMetaMask) {
            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            window.ethereum.request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                ll._("Attaching metamask wallets", accounts);
                this.setState({wallet: accounts[0]}, () => {
                   if(pr.handleSetWallet) {
                    return pr.handleSetWallet(accounts);
                   }
                });

            });
        } else {
            alert("Metamask not found");
        }
    }

    handleEventMessage(message, obj) {
        //console.log("MESSAGE", message['data'], typeof message);
        // NOTE: Stripe sends conflicting event messages, so this traps the Stripe messages.
        if(message['data'] == undefined || message['data']['service'] == undefined) {
            return;
        }
        const messageService = Utils.get(message, "data.service");
        // Check for Bitclout ID
        if(messageService) {
            const messageId = Utils.get(message, "data.id");
            const messageMethod = Utils.get(message, "data.method");
            const messagePayload = Utils.get(message, "data.payload");
            //console.log("RECEIVED BC MESSAGE!", message, message.data, messageId, messageMethod, messagePayload);
            const {data: {id: id, method: method, payload: payload}} = message;

            if(method == 'initialize') {
                this.handleInit(message);
            } else if (method == 'login') {
                this.handleLogin(payload);
            }
        }
    }

    loginBitclout() {
        //this.identityWindow = window.open('https://identity.bitclout.com/log-in', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
        //this.identityWindow = window.open('https://identity.deso.org/log-in', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
        this.identityWindow = window.open(`${Api.getBitcloutUrl()}/log-in?r=WtC3WgA2`, null, 'toolbar=no, width=800, height=1000, top=0, left=0');
    }

    handleInit(e) {
        if(!this.init) {
            this.init = true;
            this.iframe = document.getElementById("identity");

            for(const e of this.pendingRequests) {
                postMessage(e);
            }

            this.pendingRequests = []
        }
        this.respond(e.source, e.data.id, {})
    }

    handleLogin(payload) {
        console.log("Payload", payload);
        if(this.identityWindow) {
            this.identityWindow.close();
            this.identityWindow = null;

            var element = document.getElementById('loggedin');
            if(element) {
                element.innerText = 'Connected BitClout account: ' + Utils.abbreviateHash(payload.publicKeyAdded);
            }
            this.props.handleSetWallet(false, payload.publicKeyAdded);
            this.checkDesoBalance(payload.publicKeyAdded)
            this.startDesoCheck(payload.publicKeyAdded)
        } else if(this.approveWindow) {
            this.approveWindow.close();
            this.approveWindow = null;

            this.handleApproveTx(payload.signedTransactionHex);
        }
    }

    respond(e, t, n) {
        if(!e) {
            return '';
        }
        e.postMessage({
            id: t,
            service: "identity",
            payload: n
        }, "*")
    }

    postMessage(e) {
        this.init ? this.iframe.contentWindow.postMessage(e, "*") : this.pendingRequests.push(e);
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const user = pr.user ? pr.user : {};

        if(pr.badge) {
            return (
                <AfterpartyRoomBadge
                    handleBuyClick={this.handleBuyClick.bind(this)}
                    badgeNotification={pr.badgeNotification}
                    showRoomNotification={pr.showRoomNotification}
                    handleClickRoomNotification={pr.handleClickRoomNotification.bind(this)}
                    currentRoomSignal={pr.currentRoomSignal}
                />
            )
        }

        let showMetamask = pr.walletTypes.indexOf("metamask") != -1 ? true : false;
        let showBitClout = pr.walletTypes.indexOf("bitclout") != -1 ? true : false;
        //ll._("--WALLET TYPES", "red", pr.walletTypes, showMetamask, showBitClout);

        // Set this flag to false for Dev so bad BitClout errors don't spam JS console
        if(!st.bitcloutActive) {
             if(!this.bcWarning) {
                 this.bcWarning = true;
                 ll._("Bitclout deactivated in ConnectedWallet state", "yellow");
             }
             showBitClout = false;
        }

        const connectWallet = StyleUtils.getMediaStyle('connectWallet', sty.connectWallet, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const bitCloutButtonContainer = StyleUtils.getMediaStyle('bitCloutButtonContainer', sty.bitCloutButtonContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const centsPerDeso = 9069; //USDCentsPerDeSoExchangeRate
        const yourDeso = 0.003; //USDCentsPerDeSoExchangeRate
        const yourDollars = (centsPerDeso * yourDeso) / 100;
        const wantToBuyDollars = 5.00;
        const buyDeso =  (wantToBuyDollars*100) / centsPerDeso;
        const bryceHallPerCent = 0.000110266;
        const bryceHallCoinBuy = (wantToBuyDollars * 100) * bryceHallPerCent;

        // NOTE: event_type == 2 is NOT a deso event
        let hasDesoWallet = Utils.get(pr.eventItem, "event_type") === 2 ? false : this.getUserDesoKey();
        // localhost wants to spend 0.0000006 $DESO to buy the creator coin of BC1YLfzSPMMyV4FAwuBieJHieHZc2tERMmqYkRKurHUy8zzLQrADfiX

        return (
            <Div>
                <Div className="connectWalletComponent" style={{...connectWallet, ...pr.style, ...{flexWrap: "wrap"}}}>
                    {showMetamask ?
                        <Button
                            className="metaMaskButtonContainer"
                            style={{height: "fit-content", alignSelf: "flex-end"}}
                            onClick={this.handleConnectMetamask.bind(this)}
                            size="small"
                            color="white"
                            noBorder
                        >
                            <Span style={{fontSize: 18, lineHeight: "12px"}}>+</Span>&nbsp;MetaMask Wallet
                        </Button>
                        :
                        null
                    }

                    {showBitClout ?
                        <Div
                            className="bitCloutButtonContainer"
                            style={bitCloutButtonContainer}
                        >
                            { /* <iframe id="identity" src="https://identity.bitclout.com/embed?v=2" style={{display: "none"}} /> */ }
                            { /* <iframe id="identity" src="https://identity.deso.org/embed?v=2" style={{display: "none"}} />*/ }
                            <iframe id="identity" src={`${Api.getBitcloutUrl()}/embed?v=2`} style={{display: "none"}} />
                            <Button
                                style={{height: "fit-content"}}
                                onClick={this.loginBitclout.bind(this)}
                                color={pr.loginButtonColor}
                                id="login"
                                size="small"
                            >
                                {/* <Span style={{fontSize: 18, lineHeight: "12px"}}>+</Span>&nbsp;DeSo Wallet */}
                                {pr.loginButtonNameOverride ? pr.loginButtonNameOverride : "LOGIN"}
                            </Button>

                        </Div>
                        :
                        null
                    }
                </Div>
                    {false && hasDesoWallet ?
                        <Div>
                            <Div>
                                { false ?
                                    <Div>
                                        <Div>Dollars per DESO coin: ${centsPerDeso/100}</Div>
                                        <Div>Your DESO coin: {yourDeso}</Div>
                                        <Div>Your Dollars: ${yourDollars.toFixed(2)}</Div>
                                        <Div>Want to Buy Dollars: ${wantToBuyDollars}</Div>
                                        <Div>Would buy DESO: {buyDeso}</Div>
                                        <Div>600 DesoNano = 0.0000006 DESO</Div>
                                        <Div>600000 DesoNano = 0.0006 DESO</Div>
                                        <Div>{Utils.get(this.props.eventItem, 'title')} Coin per Cent: {bryceHallPerCent}</Div>
                                        <Div>{Utils.get(this.props.eventItem, 'title')} Coin buy: {bryceHallCoinBuy}</Div>
                                    </Div>
                                    :
                                    null
                                }
                                <Div style={{padding:20, display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                    <Div style={{flex:1,textAlign:"center",paddingBottom:20}}>
                                        You need $1 of {Utils.get(this.props.eventItem, 'title')} coin to RSVP
                                    </Div>
                                    <Div style={{flex:1, display:"flex", justifyContent:"center"}}>
                                        <Button
                                            style={{marginBottom: 30}}
                                            onClick={this.handleBuyClick.bind(this)}
                                        >
                                            BUY NOW
                                        </Button>
                                    </Div>
                                    <Div style={{flex:1, display:"flex", justifyContent:"center"}}>
                                        <Div>Current DESO account balance: {this.state.desoBalance.nanoBalance}</Div>
                                        <Div
                                            onClick={this.handleBuyDeso.bind(this)}
                                            style={{flex:1, textAlign:"center", textDecoration: "underline", cursor:"pointer"}}>
                                            Buy Deso
                                        </Div>
                                    </Div>
                                    <Div style={{flex:1, display:"flex", justifyContent:"center"}}>
                                        <Div>{Utils.get(this.props.eventItem, 'title')} coin balance: {this.state.desoBalance.nanoBalanceCreatorCoin}</Div>
                                        <Div style={{flex:1}}></Div>
                                    </Div>
                                    <Div style={{paddingLeft:20,paddingBottom:10,}}>Last checked: {this.state.desoBalance.checkTime}</Div>
                                    <Div style={{fontSize:9}}>(It may take a few minutes for your $3 complimentary to be transfered to your account)</Div>
                                </Div>

                            </Div>
                        </Div>
                        :
                        null
                }
            </Div>
        )
    }

    styles = {
        connectWallet: {
            display: "flex",
        },
        connectWallet_sm: {
            justifyContent: "center",
        },
        bitCloutButtonContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            marginTop: 0,
            marginLeft: 10,
        },
        bitCloutButtonContainer_sm: {
            justifyContent: "left",
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
        },
    }
}
