import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Crypto from '../modules/Crypto';
import Validate from '../modules/Validate';

import RegisterFlow from './RegisterFlow';
import DebugBox from '../elements/DebugBox';
import Dropdown from '../elements/Dropdown';
import MetaMask from '../elements/MetaMask';
import Modal from '../elements/Modal';

import '../App.css';

import Input from '../elements/Input';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Span from '../elements/Span';

import Div from './Div';
import ConnectWallet from './ConnectWallet';
import SplashFooter from './SplashFooter';

import afterpartyLogo from "../assets/images/splash/logo@3x.png"
import guardianBackground from "../assets/images/splash/desktop_bg.jpg"
import guardianBackgroundMobile from "../assets/images/splash/mobile_bg.jpg"

import nftGuardianButton from "../assets/images/ap_guardian_button.png"
import nftGradientButton from "../assets/images/ap_gradient_button.png"
import nftGaButton from "../assets/images/ap_ga_button.jpg"

import instagramIcon from "../assets/images/instagram white 1.png"
import artistCharly from "../assets/images/artist_charly.jpg"
import artistEben from "../assets/images/artist_eben.jpg"
import artistElohim from "../assets/images/artist_elohim.jpg"
import artistGrady from "../assets/images/artist_grady.jpg"
import artistSnoop from "../assets/images/artist_snoop.jpg"
import artistChainsmokers from "../assets/images/artist_chainsmokers.jpg"
import artistCalvinHarris from "../assets/images/artist_calvinharris.jpg"

export default class Guardian extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            name: "",
            phone: "",
            code: "",
            newCustomerId: null,
            guardianStage: "home", // home, name, phone, code
            guardianInfo: 0, // 0, 1, 2
            guardianSuccess: false,
            invalidCode: false,
            errorMessage: "",
            stage: 'loading',
            selectedTab: 'vip',
            soldGuardians: 2501,
            custodialWalletId: false,
            showMoonPayModal: false,
            showMoonPayModalStep: 1,
            showCreateWalletModal: false,
            showPurchaseModal: false,
            selectedArtistId: 0,
            prices: {
                vip: { dollar: 500, eth: .1759},
                ga: { dollar: 300, eth: .1055},
            },
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const stage = Utils.getRequest('stage', 'marketing');
        this.setState({stage: stage});

        setInterval(() => {
            this.cycleGuardianInfo();
        }, 5000);
        Utils.handleScroll.bind(this)();
    }

    cycleGuardianInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.guardianStage !== "home") {
            return;
        }

        this.setState({
            guardianInfo: (st.guardianInfo + 1) % 3,
        });
    }

    onClose() {
        this.setState({
            guardianStage: "home",
            name: "",
            phone: "",
            code: "",
            guardianInfo: 0, // 0, 1, 2
            guardianSuccess: false,
            errorMessage: "",
        });
    }

    closeBiddingModal() {
      this.setState({
          showMoonPayModal: false,
      });
    }

    closeCreateWalletModal() {
      this.setState({
          showCreateWalletModal: false,
      });
    }

    closePurchaseModal() {
      this.setState({
          showPurchaseModal: false,
      });
    }

    nameChange(e) {
        this.setState({
            name: e.target.value,
            errorMessage: "",
        });
    }

    phoneChange(e) {
        this.setState({
            phone: e.target.value,
            errorMessage: "",
        });
    }

    codeChange(e) {
        this.setState({
            code: e.target.value,
            errorMessage: "",
        });
    }

    iWantOne() {
        this.setState({
            guardianStage: "name",
        });
    }

    submitFullName() {
        const st = this.state;

        const validateTextResponse = Validate.minLength(st.name, 3, "Your name");

        if(validateTextResponse.errors.length) {
            this.setState({
                errorMessage: validateTextResponse.errors[0].message,
            })
            return;
        }

        this.setState({
            guardianStage: "phone",
            errorMessage: "",
        });
    }

    submitPhone() {
        const st = this.state;

        const validatePhoneResponse = Validate.phone(st.phone);

        if(validatePhoneResponse.errors.length) {
            this.setState({
                errorMessage: validatePhoneResponse.errors[0].message,
            })
            return;
        }

        let registerFormData = {
            // username: `${st.name}_USERNAME`,
            full_name: st.name,
            // last_name: "LAST",
            // email: `${st.name}_EMAIL`,
            primary_phone1: st.phone,
            registerFlow: "guardian",
            // password: "TESTpassword",
            // retypepassword: "TESTpassword",
            noLogin: 1,
            format: "json",
        };

        Api.postRegisterGuardian(registerFormData, (response) => {
            if(response == null) {
                return;
            }

            if(Utils.get(response, "success")) {
                this.setState({
                    guardianStage: "code",
                    newCustomerId: Utils.get(response, "data.id"),
                    errorMessage: "",
                });
            } else {
               this.setState({errorMessages: Utils.get(response, "errors", "")});
            }
        });
    }

    goToInstagram() {
        window.open("https://instagram.com/afterparty", "_blank");
    }

    handleMetaMaskChange(changeType, data) {
        console.log("MM", changeType, data);
        if(changeType == 'accounts') {
            this.setState({accounts: data, metamaskWalletId:data[0]});
            this.loadItems(data[0]);

        }
    }

    handleCreateWalletClick() {
        console.log("Create wallet");
        Api.postCreateCustodialWallet({}, (o) => {
            console.log(Utils.get(o, 'data.walletId.wallet_id'));
            this.setState({custodialWalletId:Utils.get(o, 'data.walletId.wallet_id'), showCreateWalletModal: true});
        });
    }

    handleArtistClick(artistId) {
        this.setState({selectedArtistId: artistId});
    }

    handleGotoWalletClick() {
        //this.props.history.push('/soin/horizon-22');
        this.props.history.push('/profile');
        if(this.props.change) {
            this.props.change('profile', {}, true);
        } else {
            console.log("ERROR: No change", this.props);
        }
    }

    handlePathWithEthClick() {
        const collectionId = this.state.selectedTab == 'vip' ? 4 : 5;
        const tokenId = 99;
        Api.postCreateGuardian(collectionId, tokenId, {}, (o) => {
            const nftUrl = Utils.get(o, 'data.name')+'-'+Utils.get(o, 'data.crc');
            console.log("nftUrl", nftUrl);
            this.setState({showPurchaseModal:true, nftUrl:nftUrl})
        });
    }
    handleMoonpayMockNext() {
        this.setState({showMoonPayModalStep:this.state.showMoonPayModalStep+1});
    }
    handleMoonpayMockEnd() {
        this.setState({showMoonPayModalStep:1, showMoonPayModal:false});
        this.handlePathWithEthClick();
    }

    change(type, event) {

    }


    confirmVerificationCode() {
        const pr = this.props;
        const st = this.state;

        if(st.code.length == 0) {
            alert("You need to enter a verification code!");
            return;
        }

        const verifyCodeData = {
            code: st.code,
            customerId: st.newCustomerId,
        };

        Api.postVerifyCode(verifyCodeData, (o) => {
            console.log("getVerifyCode:", o);
            if(o.success == 1) {
                this.setState({
                    guardianStage: "home",
                    guardianSuccess: true,
                });
            } else {
                console.log("ERRORS: ", o.errors);
                alert(Utils.get(o.errors, "0.message"));
            }
        });
    }

    resendVerificationCode() {
        const pr = this.props;

        const resendCodeData = {
            customerId: st.newCustomerId,
        };

        Api.resendVerifyCode(resendCodeData, (res) => {
            // alert(`Verify code has been resent to ${Utils.formatPhoneNumber(Utils.get(pr.user, "primary_phone1", ""))}!  If you didn't receive the message, email support@afterparty.com`);
            alert(`Verify code has been resent!  If you didn't receive the message, email support@afterparty.com`);
            console.log(res);
        });
    }

    renderInfoDots() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const infoDotContainer = StyleUtils.getMediaStyle('infoDotContainer', sty.infoDotContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="infoDotContainer" style={infoDotContainer}>
                <Div
                    className="infoDot"
                    style={st.guardianInfo == 0 ? sty.infoDot : sty.infoDotUnselected}
                ></Div>
                <Div
                    className="infoDot"
                    style={st.guardianInfo == 1 ? sty.infoDot : sty.infoDotUnselected}
                ></Div>
                <Div
                    className="infoDot"
                    style={st.guardianInfo == 2 ? sty.infoDot : sty.infoDotUnselected}
                ></Div>
            </Div>

        )
    }

    renderGuardianInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescription = StyleUtils.getMediaStyle('guardianDescription', sty.guardianDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        switch(st.guardianInfo) {
            case 0:
                return (
                    <Div className="guardianInfo" style={sty.guardianInfo}>
                        <Div className="guardianSubtitle" style={guardianSubtitle}>
                            LOS ANGELES FESTIVAL
                        </Div>
                        <Div className="guardianDescription" style={guardianDescription}>
                            Each Guardian is a VIP ticket to the upcoming LA Afterparty festival (Halloween Weekend 2022)
                        </Div>
                        {this.renderInfoDots()}
                    </Div>
                )
                break;
            case 1:
                return (
                    <Div className="guardianInfo" style={sty.guardianInfo}>
                        <Div className="guardianSubtitle" style={guardianSubtitle}>
                            LIFETIME FESTIVAL MEMBERSHIP
                        </Div>
                        <Div className="guardianDescription" style={guardianDescription}>
                            Priority access to all future LA festivals
                        </Div>

                        {this.renderInfoDots()}
                    </Div>
                )
                break;
            case 2:
                return (
                    <Div className="guardianInfo" style={sty.guardianInfo}>
                        <Div className="guardianSubtitle" style={guardianSubtitle}>
                            ALLOWLIST ACCESS
                        </Div>
                        <Div className="guardianDescription" style={guardianDescription}>
                            Priority access to our 3rd generation Afterparty NFT collection and more
                        </Div>

                        {this.renderInfoDots()}
                    </Div>
                )
                break;
            default:
                return null;
        }
    }

    renderGuardianSuccessInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescription = StyleUtils.getMediaStyle('guardianDescription', sty.guardianDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="guardianInfo" style={sty.guardianInfo}>
                <Div className="guardianSubtitle" style={guardianSubtitle}>
                    YOU'RE ON THE LIST
                </Div>
                <Div className="guardianDescription" style={guardianDescription}>
                    WE'LL TEXT YOU BEFORE THE PRESALE GOES LIVE!
                </Div>
                <Div className="guardianDescription" style={guardianDescription}>
                    IN THE MEANTIME, FOLLOW US FOR UPDATES.
                </Div>
            </Div>
        )
    }

    renderGuardianScroller() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSection = StyleUtils.getMediaStyle('guardianSection', sty.guardianSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const mainTitle = StyleUtils.getMediaStyle('mainTitle', sty.mainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyLogoStyle = StyleUtils.getMediaStyle('afterpartyLogoStyle', sty.afterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianSubtitleLower = StyleUtils.getMediaStyle('guardianSubtitleLower', sty.guardianSubtitleLower, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        let adLine1 = null;
        let adLine2 = null;
        let adLine3 = null;
        let adLine4 = null;
        let adGroupId = null;
        if(pr.ad) {
            adGroupId = Utils.get(pr.ad, "ad_group_id", 1);
            //console.log("adGroupId", pr.ad, adGroupId);
            const line1 = Utils.get(pr.ad, "line1", "").trim();
            const line2 = Utils.get(pr.ad, "line2", "").trim();
            const line3 = Utils.get(pr.ad, "line3", "").trim();
            const line4 = Utils.get(pr.ad, "line4", "").trim();
            if(line1.length > 0) {
                adLine1 = line1
            }
            if(line2.length > 0) {
                adLine2 = line2;
            }
            if(line3.length > 0) {
                adLine3 = line3;
            }
            if(line4.length > 0) {
                adLine4 = line4;
            }
        }

        return (
            <Div className="guardianSection" style={guardianSection}>
                <Div style={{display:"flex", flexWrap:"wrap", }}>
                    <Div>
                        <Image
                            className="afterpartyLogoStyle"
                            src={afterpartyLogo}
                            style={afterpartyLogoStyle}
                        />
                    </Div>
                    { adGroupId == 1 && adLine1 ?
                        <Div style={sty.line1}>
                            {adLine1}
                        </Div>
                        :
                        null
                    }
                    { adGroupId == 2 && adLine1 ?
                        <Div style={sty.line1a}>
                            {adLine1}
                        </Div>
                        :
                        null
                    }
                </Div>
                <Div className="mainTitle" style={mainTitle}>
                    {st.guardianSuccess ? "SUCCESS" : "THE GUARDIANS COLLECTION"}
                </Div>

                {st.guardianSuccess ? this.renderGuardianSuccessInfo() : null}
                {adGroupId == 2  || st.guardianSuccess ? null : this.renderGuardianInfo()}

                { adGroupId == 1 && adLine2 ?
                    <Div  style={sty.line2}>
                        {adLine2}
                    </Div>
                    :
                    null
                }
                { adGroupId == 2 && adLine2 ?
                    <Div  style={sty.line2a}>
                        {adLine2}
                    </Div>
                    :
                    null
                }
                { adGroupId == 1 && adLine3 ?
                    <Div  style={sty.line3}>
                        {adLine3}
                    </Div>
                    :
                    null
                }
                { adGroupId == 2 && adLine3 ?
                    <Div  style={sty.line3a}>
                        {adLine3}
                    </Div>
                    :
                    null
                }

                {st.guardianSuccess ?
                    <Button
                        className="guardianButton"
                        style={sty.guardianButton}
                        onClick={this.goToInstagram.bind(this)}
                    >
                        <Image style={{marginRight: 12, width: 22}} src={instagramIcon} /> FOLLOW US
                    </Button>
                    :
                    <Button
                        className="guardianButton"
                        style={sty.guardianButton}
                        onClick={this.iWantOne.bind(this)}
                    >
                        { adGroupId == 2 && adLine4 ?
                            <Span>{adLine4}</Span>
                            :
                            <Span>I WANT ONE!</Span>
                        }
                    </Button>
                }
                { true ?
                    <Div style={sty.learnMoreSection}>
                        <Div style={sty.learnMoreContainer}>
                            {pr.guardian_faq}
                        </Div>
                    </Div>
                    :
                    null
                }

            </Div>
        )
    }

    renderCloseButton() {
        const pr = this.props;
        const sty = this.styles;

        const closeButton = StyleUtils.getMediaStyle('closeButton', sty.closeButton, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div
                className="closeButton"
                style={sty.closeButton}
                onClick={this.onClose.bind(this)}
            >
                ×
            </Div>
        )
    }

    renderGuardianName() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescription = StyleUtils.getMediaStyle('guardianDescription', sty.guardianDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescriptionError = StyleUtils.getMediaStyle('guardianDescriptionError', sty.guardianDescriptionError, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="guardianOverlay" style={sty.guardianOverlay}>
                {this.renderCloseButton()}

                <Div className="guardianOverlayInner" style={sty.guardianOverlayInner}>
                    <Div className="guardianSubtitle" style={guardianSubtitle}>
                        ENTER FULL NAME
                    </Div>
                    <Div className="guardianDescription" style={guardianDescription}>
                        SO WE CAN ADD YOU TO OUR PRIORITY ACCESS LIST
                    </Div>

                    <Div className="guardianDescriptionError" style={guardianDescriptionError}>
                        {st.errorMessage}
                    </Div>

                    <Input
                        value={st.name}
                        iconButtonInputContainerStyle={sty.guardianInput}
                        onChange={this.nameChange.bind(this)}
                        onClickIcon={this.submitFullName.bind(this)}
                        onEnter={this.submitFullName.bind(this)}
                        inputType="iconButton"
                        placeholder="Full name"
                        iconButtonContainerStyle={{opacity: st.name.length ? 1 : 0.5}}
                    />
                </Div>
            </Div>
        )
    }

    renderGuardianPhone() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescription = StyleUtils.getMediaStyle('guardianDescription', sty.guardianDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescriptionError = StyleUtils.getMediaStyle('guardianDescriptionError', sty.guardianDescriptionError, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="guardianOverlay" style={sty.guardianOverlay}>
                {this.renderCloseButton()}

                <Div className="guardianOverlayInner" style={sty.guardianOverlayInner}>
                    <Div className="guardianSubtitle" style={guardianSubtitle}>
                        ENTER NUMBER
                    </Div>
                    <Div className="guardianDescription" style={guardianDescription}>
                        SO WE CAN TEXT YOU THE UNIQUE PRESALE LINK
                    </Div>

                    <Div className="guardianDescriptionError" style={guardianDescriptionError}>
                        {st.errorMessage}
                    </Div>

                    <Input
                        value={st.phone}
                        type="tel"
                        iconButtonInputContainerStyle={sty.guardianInput}
                        onChange={this.phoneChange.bind(this)}
                        onClickIcon={this.submitPhone.bind(this)}
                        onEnter={this.submitPhone.bind(this)}
                        inputType="iconButton"
                        placeholder="Phone number"
                        iconButtonContainerStyle={{opacity: st.phone.length ? 1 : 0.5}}
                    />
                </Div>
            </Div>
        )
    }

    renderGuardianCode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescription = StyleUtils.getMediaStyle('guardianDescription', sty.guardianDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianDescriptionError = StyleUtils.getMediaStyle('guardianDescriptionError', sty.guardianDescriptionError, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="guardianOverlay" style={sty.guardianOverlay}>
                {this.renderCloseButton()}

                <Div className="guardianOverlayInner" style={sty.guardianOverlayInner}>
                    <Div className="guardianSubtitle" style={guardianSubtitle}>
                        ENTER THE CODE
                    </Div>
                    <Div className="guardianDescription" style={guardianDescription}>
                        {st.invalidCode ? "INVALID CODE!" : "DIDN'T GET A CODE?"}
                        <Div
                            onClick={this.resendVerificationCode.bind(this)}
                            style={sty.buttonTextStyle}
                        >
                            RESEND TO {Utils.formatPhoneNumber(st.phone)}
                        </Div>
                    </Div>

                    <Div className="guardianDescriptionError" style={guardianDescriptionError}>
                        {st.errorMessage}
                    </Div>

                    <Input
                        value={st.code}
                        iconButtonInputContainerStyle={sty.guardianInput}
                        onChange={this.codeChange.bind(this)}
                        onClickIcon={this.confirmVerificationCode.bind(this)}
                        onEnter={this.confirmVerificationCode.bind(this)}
                        inputType="iconButton"
                        placeholder="Verification code"
                        iconButtonContainerStyle={{opacity: st.code.length ? 1 : 0.5}}
                    />
                </Div>
            </Div>
        )
    }

    renderGuardianMarketing() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const learnMoreSection = StyleUtils.getMediaStyle('guardianSection', sty.guardianSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));


        return <Div>
            {this.renderGuardianScroller()}
            {st.guardianStage === "name" ? this.renderGuardianName() : null}
            {st.guardianStage === "phone" ? this.renderGuardianPhone() : null}
            {st.guardianStage === "code" ? this.renderGuardianCode() : null}
        </Div>;
    }
    onSelectQuantity(selectedQuantity) {
        const pr = this.props;
        const st = this.state;

        this.setState({
            selectedQuantity: selectedQuantity,
        });
    }

    renderWalletBar() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const selectedWallet = Utils.get(pr.user, 'wallets.0');
        return (
            <Div style={{display:"flex"}}>
                <Div style={this.styles.connectWalletButton}>
                    <Button
                        color="orange"
                        style={{width:60, height:60, minWidth:"auto"}}
                        onClick={this.handleCreateWalletClick.bind(this)}
                        >+</Button>
                </Div>
                <Div style={this.styles.connectWalletInfo}>
                    <Div style={{marginTop:6, marginRight:8, borderRadius:24,width:24,height:24,backgroundColor:"red"}}></Div>
                    { !this.state.custodialWalletId ?
                        <Div>
                            <Div style={{fontWeight:"bold"}}>{Utils.abbreviateHash(Utils.get(selectedWallet, 'wallet_id'))}</Div>
                            <Div>MetaMask</Div>
                        </Div>
                        :
                        <Div>
                            <Div style={{fontWeight:"bold"}}>{Utils.abbreviateHash(this.state.custodialWalletId)}</Div>
                            <Div>Afterparty Wallet</Div>
                        </Div>
                    }

                </Div>
            </Div>
        )
    }

    renderMockMoonpay() {
        if(this.state.showMoonPayModalStep == 1) {
            return this.renderMockMoonpayStep1();
        } else {
            return this.renderMockMoonpayStep2();
        }
    }

    renderMockMoonpaySummary() {
        const dollarAmount = this.state.prices[this.state.selectedTab]['dollar'];
        const totalAmount = dollarAmount*1.03;

        return (
                <Div style={{display:"flex", marginTop:40,}}>
                    <Div style={{flex:1}}>
                        <Div style={{...this.styles.moonpayLine, ...{fontWeight:"bold"} }}>Summary</Div>
                        <Div style={this.styles.moonpayLine}>Guardian</Div>
                        <Div style={{...this.styles.moonpayLine, ...{fontWeight:"bold"} }}>Total</Div>
                    </Div>
                    <Div style={{flex:1}}>
                        <Div style={{...this.styles.moonpayLine, ...{color:"gray"} }}><i style={{fontSize:14,marginRight:6}} className="fa fa-hourglass-end" />Valid for 5m</Div>
                        <Div style={this.styles.moonpayLine}>${parseInt(dollarAmount)}.00</Div>
                        <Div style={this.styles.moonpayLine}>${parseInt(totalAmount)}.00</Div>
                    </Div>
                </Div>
        )
    }

    renderMockMoonpayStep1() {
        const imageSrc = this.state.selectedTab == 'vip' ? "https://afterparty.ai/webapp/static/media/btnGuardians.e2383ee8.png" : nftGaButton;
        const title = this.state.selectedTab == 'vip' ? "Guardian VIP Pass" : "Afterparty GA Pass";
        return (
            <Div style={this.styles.moonpayContainer}>
                <Div>
                    <Div style={{fontSize:20,fontWeight:500}}>Purchase NFT</Div>
                </Div>
                <Div style={{display:"flex", marginTop:20,}}>
                    <Div style={{flex:1}}>
                        <Div style={this.styles.moonpayLine}>{title}</Div>
                        <Div style={this.styles.moonpayLine}>Afterparty Guardians</Div>
                        <Div style={{...this.styles.moonpayLine, ...{fontSize:9} }}>View item in chain</Div>
                    </Div>
                    <Div style={{flex:1}}>
                        <Image style={{width:150}} src={imageSrc} />
                    </Div>
                </Div>
                {this.renderMockMoonpaySummary()}
                <Div style={{display:"flex", marginTop:100,}}>
                    <Button
                        onClick={this.handleMoonpayMockNext.bind(this)}
                        style={{width:"100%",borderRadius:50,backgroundColor:"blue", color:"white"}}>
                        Continue
                    </Button>
                </Div>
            </Div>
        );
    }

    renderMockMoonpayStep2() {
        return (
            <Div style={this.styles.moonpayContainer}>
                <Div>
                    <Div style={{fontSize:20,fontWeight:500}}>Confirm your order</Div>
                </Div>
                <Div style={{display:"flex", marginTop:20,}}>
                    <Div style={this.styles.moonpayLine}>Paying with Visa ending 5032</Div>
                </Div>
                <Div style={{marginTop:20,}}>
                    <Div style={this.styles.moonpayLine}>Card security code</Div>
                    <Div style={this.styles.moonpayLine}>
                        <Input style={{backgroundColor:"#eee",border:"none", borderRadius:12,}} />
                    </Div>
                </Div>

                <Div style={{display:"flex", marginTop:20,}}>
                    <Div style={this.styles.moonpayLine}> Delivering crypto to</Div>
                    <Div style={this.styles.moonpayLine}>0x2e00...5b7ebc</Div>
                </Div>
                {this.renderMockMoonpaySummary()}
                <Div style={{display:"flex", marginTop:20,}}>
                    <Div style={{marginRight:10}}><input type="checkbox" /></Div>
                    <Div>I authorise MoonPay to debit my chosen payment method for the amount above on today’s date and understand that this can not be cancelled, recalled or refunded.</Div>
                </Div>
                <Div style={{display:"flex", marginTop:100,}}>
                    <Button
                        onClick={this.handleMoonpayMockEnd.bind(this)}
                        style={{width:"100%",borderRadius:50,backgroundColor:"blue", color:"white"}}>
                        Buy now
                    </Button>
                </Div>
            </Div>
        )
    }

    renderGuardianSale(stage) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const guardianSection = StyleUtils.getMStyle(sty, 'guardianSection', pr.screen.width);
        //const guardianSection = StyleUtils.getMediaStyle('guardianSection', sty.guardianSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const mainTitle = StyleUtils.getMediaStyle('mainTitle', sty.mainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianSubtitle = StyleUtils.getMediaStyle('guardianSubtitle', sty.guardianSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyLogoStyle = StyleUtils.getMediaStyle('afterpartyLogoStyle', sty.afterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const guardianSubtitleLower = StyleUtils.getMediaStyle('guardianSubtitleLower', sty.guardianSubtitleLower, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        const guardianVipSelect = this.state.selectedTab == 'vip' ? this.styles.selectedTab : this.styles.unselectedTab;
        const guardianVipBullet = this.state.selectedTab == 'vip' ? "fa fa-circle-o" : "fa fa-circle";
        const guardianGaSelect = this.state.selectedTab != 'vip' ? this.styles.selectedTab : this.styles.unselectedTab;
        const guardianGaBullet = this.state.selectedTab != 'vip' ? "fa fa-circle-o" : "fa fa-circle";
        const utopianQuantities = [
            { id: "0", text: "Afterparty", image: nftGradientButton, },
            { id: "2", text: "Snoop Dogg", image: artistSnoop, },
            { id: "7", text: "Chainsmokers", image: artistChainsmokers, },
            { id: "8", text: "Calvin Harris", image: artistCalvinHarris, },
            { id: "1", text: "Elohim", image: artistElohim, },
            { id: "6", text: "Charly Jordan", image: artistCharly, },
            { id: "3", text: "Grady", image: artistGrady, },
            { id: "4", text: "Eben", image: artistEben, },
            //{ id: "5", text: "Ship Rekt", },
        ];
        const MoonPayUrl = "https://buy-sandbox.moonpay.com/nft?contractAddress=0xA1A9dFab98C5F692119686Aff5f5Bd993A8B5330&tokenId=112457658586931934152042554105038651850087300550240488280277587652331018649601&apiKey=pk_test_DsEcfo3q6iLBMIwpgpfldoOUcQE5nxGu&signature=IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        const moonPayHeight = 700;
        const selectedWallet = Utils.get(pr.user, 'wallets.0');
        //console.log("PRUSER", pr.user, selectedWallet);


        return (
            <Div className="guardianSection" style={guardianSection}>
                    <Image
                        className="afterpartyLogoStyle"
                        src={afterpartyLogo}
                        style={afterpartyLogoStyle}
                    />
                    <Div className="mainTitle" style={mainTitle}>
                        {st.guardianSuccess ? "SUCCESS" : "THE GUARDIANS COLLECTION"}
                    </Div>
                    <Div style={{display:"flex", flexWrap: "wrap"}}>
                        <Div style={{width:"100%", display:"flex", flexWrap: "wrap"}}>
                            <Div style={this.styles.leftContainer}>
                                <Div style={{marginTop:12, marginBottom:12, color:"white",}}>Select Pass</Div>

                                <Div style={{width:"100%", display:"flex", flexWrap: "wrap"}}>

                                    <Div onClick={ () => { this.setState({"selectedTab":'vip'}) } } style={guardianVipSelect}>
                                        <Div>
                                            <Image style={this.styles.nftButton} src={nftGuardianButton} />
                                        </Div>
                                        <Div style={{marginLeft:24,marginTop:12,}}>
                                            <Div style={this.styles.nftButtonTitle}>Guardian VIP Pass</Div>
                                            <Div style={this.styles.nftButtonSubtitle}>$500 <Span style={this.styles.nftButtonCost}>.1759 ETH</Span></Div>
                                        </Div>
                                    </Div>
                                    <Div onClick={ () => { this.setState({"selectedTab":'ga'}) } } style={guardianGaSelect}>
                                        <Div>
                                            <Image style={this.styles.nftButton} src={nftGaButton} />
                                        </Div>
                                        <Div style={{marginLeft:24,marginTop:12,}}>
                                            <Div style={this.styles.nftButtonTitle}>Afterparty GA Pass</Div>
                                            <Div style={this.styles.nftButtonSubtitle}>$300 <Span style={this.styles.nftButtonCost}>.1055 ETH</Span></Div>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div style={{marginTop:12, marginBottom:12, color:"white",}}>Select Artist NFT</Div>
                                <Div style={{display:"flex", width:"100%",position:"relative", height:130}}>
                                    <Div style={this.styles.artistTilesContainer}>
                                    {utopianQuantities.map( (o) => {
                                        return <Div key={`artistImage-${o.id}`} style={this.styles.artistTile} onClick={this.handleArtistClick.bind(this, o.id)}>
                                                <Div style={o.id == this.state.selectedArtistId ? this.styles.artistTileImageContainerCheck : this.styles.artistTileImageContainer}>
                                                    <Image style={this.styles.artistTileImage} src={o.image} />
                                                </Div>
                                                <Div style={this.styles.artistTileTitle}>{o.text}</Div>
                                                { o.id == this.state.selectedArtistId ?
                                                    <Div style={this.styles.artistTileCheck}>
                                                        <i className="fa fa-check" />
                                                    </Div>
                                                    :
                                                    null
                                                }
                                        </Div>
                                    })}
                                    </Div>
                                    {/*
                                    <Dropdown
                                        style={{marginLeft: 10}}
                                        selectStyle={{minWidth: 60}}
                                        selected={st.selectedQuantity}
                                        onSelectOption={this.onSelectQuantity.bind(this)}
                                        options={utopianQuantities}
                                        color="purple"
                                    />
                                    */}
                                </Div>
                                <Div style={{marginTop:12, marginBottom:12, color:"white",}}>Connect Wallet</Div>
                                { !selectedWallet ?
                                    <Div>
                                        <Div style={{marginTop:12, marginBottom:0, color:"white",}}>Connect Wallet</Div>
                                        <MetaMask
                                            style={{maxWidth:"100%", marginTop:40,}}
                                            show={true}
                                            onChange={this.handleMetaMaskChange.bind(this)}
                                            abbr={true}
                                        />
                                        <Button
                                            onClick={this.handleCreateWalletClick.bind(this)}
                                            color="orange" size="small"
                                            style={{marginTop:20, width:"100%"}}>
                                                Create Custodial Wallet
                                        </Button>
                                    </Div>
                                    :
                                    this.renderWalletBar()
                                }

                                <Div style={{marginTop:12, marginBottom:12, color:"white",}}>Purchase</Div>

                                { stage == 'presale' ?
                                    <Div style={{margin:20}}>
                                        You have 2 available Guardians to mint.
                                    </Div>
                                    :
                                    <Div style={this.styles.purchaseBox}>
                                        <Div style={{fontWeight:"bold",fontSize:18}}>{Utils.commas(10000 - this.state.soldGuardians)} Guardians left</Div>
                                        <Div>{Utils.commas(this.state.soldGuardians)} Guardians sold</Div>
                                    </Div>
                                }

                                <Div style={{gap:8, width:"100%", marginTop: 30, display:"flex", flexDirection:"row", justifyContent:"center", alignItems: "center"}}>
                                    <Div style={this.styles.purchaseButton} onClick={() => { this.setState({showMoonPayModal: true}) } }>
                                        <Div style={this.styles.purchaseButtonTitle}>Pay with Credit Card</Div>
                                        <Div style={this.styles.purchaseButtonSubtitle}>${this.state.prices[this.state.selectedTab]['dollar']}</Div>
                                    </Div>
                                    <Div style={this.styles.purchaseButton}  onClick={this.handlePathWithEthClick.bind(this)}>
                                        <Div style={this.styles.purchaseButtonTitle}>Pay with ETH</Div>
                                        <Div style={this.styles.purchaseButtonSubtitle}>{this.state.prices[this.state.selectedTab]['eth']} ETH</Div>
                                    </Div>
                                </Div>

                            </Div>
                            { /* <Div style={{top:-50,marginTop:50,borderRadius:6,backgroundColor:"rgba(0,0,0,0.5)",boxShadow: "0 -25px 0 0 rgba(0,0,0,0.5)"}}>
                            Hello
                            </Div>
                            */ }
                        </Div>

                        <Div style={this.styles.rightContainer}>
                            <Div style={{padding:20,borderRadius:10,}}>
                                <Div style={{textAlign:"center", fontWeight:"bold", fontSize:20,marginBottom:20,}}>Don't have a wallet to hold your Guardian?</Div>
                                <Div style={{}}>
                                    <Div style={{width:"100%",padding:10,display:"flex", flexDirection:"column", alignItems:"center"}}>
                                        <Div style={{marginBottom:10}}>Instructions to setup the MetaMask browser extension to manage your own wallet</Div>
                                        <a target="_blank" href="https://metamask.zendesk.com/hc/en-us/articles/360015489471-How-to-Install-MetaMask-Manually" size="text" color="white" style={{color:"white", marginRight:20}}>MetaMask Instructions</a>
                                        <Div style={{textAlign:"center",marginTop:6,fontSize:12,}}>(slightly technical)</Div>
                                    </Div>
                                    <Div style={{width:"100%",padding:20,display:"flex", flexDirection:"column", alignItems:"center"}}>
                                        <Div style={{marginBottom:10}}>Or create a custodial wallet with Torus using your email</Div>
                                        <Button size="text" color="white" style={{textAlign:"center", marginRight:20}}>Create Custodial wallet</Button>
                                        <Div style={{textAlign:"center",marginTop:6,}}>(non-technical)</Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>


                    { st.showMoonPayModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.closeBiddingModal.bind(this)}
                            className="biddingModal"
                            color="purple"
                            title={this.state.selectedTab == 'vip' ? "Buy Guardian VIP Pass" : "Buy Afterparty GA Pass" }
                        >
                            <Div style={{height:moonPayHeight,width:"100%",borderRadius:12,overflow:"hidden",}}>
                                { Utils.getRequest('moonpay') ?
                                    <iframe
                                          allow="accelerometer; autoplay; camera; gyroscope; payment"
                                          frameBorder="0"
                                          height="100%"
                                          src={MoonPayUrl}
                                          width="100%"
                                        />
                                    :
                                    this.renderMockMoonpay()
                                }
                            </Div>
                        </Modal>
                        :
                        null

                    }
                    { st.showCreateWalletModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.closeCreateWalletModal.bind(this)}
                            className="biddingModal"
                            color="black"
                            title={`Afterparty Wallet Created`}
                        >
                            <Div style={{width:"100%",borderRadius:12,overflow:"hidden",}}>
                                <Div style={{padding:20,}}>
                                    An Afterparty custodial wallet has been created for you to store your NFTs. The wallet has the following ID: {this.state.custodialWalletId}
                                </Div>
                            </Div>
                        </Modal>
                        :
                        null

                    }
                    { st.showPurchaseModal ?
                        <Modal
                            screen={pr.screen}
                            onRequestClose={this.closePurchaseModal.bind(this)}
                            className="biddingModal"
                            color="black"
                            title={`Congrats!`}
                        >
                            <Div style={{width:"100%",borderRadius:12,overflow:"hidden",}}>
                                <Div style={{padding:20,}}>
                                     You're going to the Afterparty NFT Art + Music Festival in Los Angeles, CA!
                                </Div>
                                <Button onClick={this.handleGotoWalletClick.bind(this)} color="orange" style={{width:"100%",}}>
                                     View NFT in Wallet
                                </Button>
                            </Div>
                        </Modal>
                        :
                        null

                    }


            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        //const stage = 'marketing';

        let stage = 'marketing';
        let containerStyle = 'container';
        if( Utils.isDemoMode()) {
            stage = "sale";
            containerStyle = 'container_demo';
        }


        const container = StyleUtils.getMediaStyle(containerStyle, sty[containerStyle], sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return ([
            <div ref={this.childDiv} className="afterpartyStaticHomeComponent" style={container} key="1-afterpartyStaticHomeComponent">
                { stage == 'marketing' ?
                    this.renderGuardianMarketing(st.stage)
                    :
                    null
                }
                { stage == 'sale' || stage == 'presale' ?
                    this.renderGuardianSale(st.stage)
                    :
                    null
                }
                { stage == 'postsale' ?
                    this.renderGuardianMarketing(st.stage)
                    :
                    null
                }
            </div>,
            <SplashFooter
                key="2-splashFooter"
                screen={pr.screen}
                footerColor="black"
                history={pr.history}
            />
        ]);
    }

    styles = {
        container: {
            // display: "inline-block",
            // position: "relative",
            backgroundImage: `url("${guardianBackground}")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            width: "100%",
            color: "white",
            padding: 28,
            flexWrap: "wrap",
            minHeight: "100vh",
        },
        container_md: {
            backgroundImage: `url("${guardianBackgroundMobile}")`,
            backgroundPosition: "bottom",
        },
        container_demo: {
            // display: "inline-block",
            // position: "relative",
            backgroundImage: `url(/images/Orange_Background.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            width: "100%",
            color: "white",
            padding: 28,
            flexWrap: "wrap",
            minHeight: "100vh",
        },
        container_demo_md: {
            backgroundImage: `url(/images/Orange_Background.png)`,
            backgroundPosition: "bottom",
        },
        guardianSection: {
            paddingTop: 100,
            maxWidth: 1000,
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 100,
        },
        guardianSection_md: {
            paddingTop: 20,
            display: "block",
        },
        guardianOverlay: {
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,0.85)",
            padding: 28,
            paddingTop: 300,
            bottom: 0,
        },
        guardianOverlayInner: {
            maxWidth: 612,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        afterpartyLogoStyle: {
            width: 254,
        },
        afterpartyLogoStyle_md: {
            width: 180,
        },
        mainTitle: {
            fontFamily: "CA Mystery Girl",
            fontWeight: 400,
            fontSize: 120,
            marginTop: 10,
            marginBottom: 10,
        },
        mainTitle_md: {
            fontSize: 47,
            lineHeight: "65px",
        },
        guardianSubtitle: {
            fontFamily: "You Work For Them",
            fontWeight: 900,
            fontSize: 35,
            color: "white",
            maxWidth: 942,
            width: "100%",
            marginBottom: 8,
        },
        guardianSubtitle_md: {
            fontSize: 20,
            marginBottom: 15,
        },
        guardianDescription: {
            fontFamily: "Proto Mono",
            fontSize: 24,
            marginBottom: 10,
            maxWidth: 844,
        },
        guardianDescription_md: {
            fontSize: 15,
        },
        guardianDescriptionError: {
            fontFamily: "Proto Mono",
            fontSize: 24,
            marginBottom: 15,
            paddingLeft: 40,
            paddingRight: 40,
            textAlign: "center",
            color: Colors.yellowError,
        },
        guardianDescriptionError_md: {
            fontSize: 15,
        },
        guardianButton: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            backgroundColor: Colors.splashRed,
            borderRadius: 100,
            width: "100%",
            maxWidth: 410,
            padding: 16,
        },
        guardianInfo: {
        },
        guardianInput: {
            maxWidth: 400,
        },
        infoDotContainer: {
            display: "flex",
            justifyContent: "left",
            paddingTop: 20,
            paddingBottom: 40,
        },
        infoDotContainer_md: {
            justifyContent: "center",
            paddingBottom: 20,
        },
        infoDot: {
            width: 8,
            height: 8,
            backgroundColor: "white",
            borderRadius: 100,
            marginLeft: 5,
            marginRight: 5,
        },
        infoDotUnselected: {
            width: 8,
            height: 8,
            opacity: 0.5,
            backgroundColor: "white",
            borderRadius: 100,
            marginLeft: 5,
            marginRight: 5,
        },
        closeButton: {
            position: "absolute",
            top: 28,
            right: 28,
            fontSize: 32,
            cursor: "pointer",
            color: "white",
        },
        closeButton_sm: {
            marginRight: 3,
        },
        closeButton_xs: {
            marginRight: 3,
        },
        buttonTextStyle: {
            color: Colors.splashRed,
            textDecoration: "underline",
        },
        selectedTab: {
            border: "3px solid #E04629",
            background: "black",
            borderRadius: 20,
            width: "100%",
            padding: 20,
            cursor: "pointer",
            marginBottom:20,
            display: "flex",
        },
        unselectedTab: {
            border: "3px solid black",
            background: "black",
            borderRadius: 20,
            width: "100%",
            padding: 20,
            cursor: "pointer",
            marginBottom:20,
            display: "flex",
        },
        leftContainer: {
            //height: 500,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            //backgroundColor:"rgba(0,0,0,0.5)",
            //padding:30,
            //height:500,
        },
        rightContainer: {
            display: "none",
            marginLeft: 40,
            //transform: "rotate(-5deg)",
            height: 400,
            marginTop:85,
            borderRadius: 20,
            //borderBottomLeftRadius: 20,
            backgroundColor:"rgba(0,0,0,0.5)",
            padding:30,

        },
        bulletPoint: {
            marginRight:6,
            fontSize: 18,
        },
        line1: {
            marginLeft: 7,
            color: "rgb(224, 70, 41)",
            fontFamily: "You Work For Them",
            fontSize: 28,
        },
        line1a: {
            marginLeft: 7,
            color: "rgb(224, 70, 41)",
            fontSize: 18,
            marginTop: 7,
        },
        line2: {
            fontWeight: "bold",
            marginBottom: 40,
            color: "white",
            fontFamily: "You Work For Them",
            fontSize: 18,
        },
        line2a: {
            marginBottom: 40,
            color: "white",
            fontSize: 18,
        },
        line3: {
            marginBottom: 40,
            color: "white",
            fontFamily: "You Work For Them",
            fontSize: 14,
        },
        line3a: {
            marginBottom: 40,
            color: "white",
            fontSize: 14,
        },
        learnMoreSection: {
            marginTop: 40,
            marginBottom: 40,
            fontFamily: "Proto Mono",
            fontSize: 16,
        },
        learnMoreContainer: {
            background: "white",
            color: "black",
            borderRadius: 20,
            padding: 30,
            minHeight: 200,
            whiteSpace: "pre-line",
        },
        nftButton: {
            width: 57,
            height: 57,
            borderRadius: 29,
            border: "1px solid #A1A1A1",
        },
        nftButtonTitle: {
            fontWeight: "bold",
            fontSize: 18,
        },
        nftButtonSubtitle: {
            fontSize: 14,
        },
        nftButtonCost: {
            fontWeight:"bold",
            marginLeft:10,
        },
        artistTilesContainer: {
            width:"100%",position:"absolute",overflowX:"scroll",overflowY:"hidden",display:"flex",
            background:"black",borderRadius:20,
        },
        artistTile: {
            textAlign:"center",
            paddingTop: 10,
            paddingLeft: 10,
            position: "relative",
        },
        artistTileCheck: {
            width:16,
            height:16,
            borderRadius:8,
            backgroundColor:"red",
            position:"absolute",
            right: 10,
            top: 16,
            color:"white",
            fontSize: 11,
        },
        artistTileImageContainerCheck: {
            overflow: "hidden",
            boxSizing: "border-box",
            border: "1px solid red",
            margin:6,width:64,height:64,
            borderRadius:64,
            backgroundColor:"orange"
        },
        artistTileImageContainer: {
            overflow: "hidden",
            margin:6,width:64,height:64,
            borderRadius:64,
            backgroundColor:"orange"
        },
        artistTileImage: {
            width:64,height:64,
        },
        artistTileTitle: {
            fontSize:12,
        },
        connectWalletButton: {
            marginRight: 8,
        },
        connectWalletInfo: {
            backgroundColor: "black",
            borderRadius: 12,
            color: "white",
            display:"flex",
            padding: 13,
            width: "100%",
        },
        purchaseBox: {
            marginTop:20,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: "black",
            borderRadius: 12,
            textAlign: "center",
            width: "100%",
        },
        purchaseButton: {
            backgroundColor:"rgb(224, 70, 41)",
            color:"white",
            flex:1,
            padding: 20,
            textAlign:"center",
            borderRadius: 12,
        },
        purchaseButtonTitle: {
            fontSize: 12,
            marginBottom: 4,
        },
        purchaseButtonSubtitle: {
            fontSize: 16,
            fontWeight: "bold",
        },
        moonpayContainer: {
            backgroundColor: "white",
            color: "black",
            padding: 20,
            borderRadius: 20,
            fontFamily: "system-ui,-apple-system,BlinkMacSystemFont,Helvetica,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
            fontWeight: 400,
        },
        moonpayFont: {
            fontFamily: "system-ui,-apple-system,BlinkMacSystemFont,Helvetica,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
        },
        moonpayLine: {
            marginBottom: 10,
        }
    };
}
