import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Validate from '../modules/Validate';

import Input from '../elements/Input';
import ErrorBox from '../elements/ErrorBox';
import Button from '../elements/Button';

import Div from './Div';

export default class EmailVerify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailText: "",
            isForgotPasswordError: false,
            isForgotPasswordSuccess: false,
            errorMessages: [],

            // NOTE status
            // NOTE: 1 == they got verification email, clicked link, and now email has been verified after registering;
            // NOTE: 2 == invalid verification: either the hash is broken, of the link is bad, or missing email
            // NOTE: 3 == they forgot their account, got email, clicked link, and now are creating new password;
            status: 1,

            email: Utils.getRequest('email'),
            hash: Utils.getRequest('hash'),
            recover: Utils.getRequest('recover'),
            password: '',
            password2: '',
        };
    }

    componentDidMount() {
      this.getStatus();
    }

    goToForgot() {
        this.props.history.push('/forgot');
    }

    goToLogin() {
        this.props.history.push('/login');
    }

    getStatus() {
        const st = this.state;
        const isValidHash = st.hash.length === 32;

        if(st.recover) {
            if(st.hash && isValidHash) {
                console.log('want recover ' + st.recover);
                this.setState({ status: 3 });
                return;
            } else {
                console.log('No hash');
                this.setState({ status: 2 });
            }
        } else {
            if(st.hash && isValidHash) {
                console.log('want recover ' + st.recover);
                this.setState({ status: 3 });
                return;
            } else {
                console.log('No hash');
                this.setState({ status: 2 });
            }
        }
    }

    changeInput(field, e) {
        let tempState = {}
        tempState[field] = e.target.value;
        this.setState(tempState);
    }

    validatePassword() {
        const st = this.state;

        let isValid = true;
        let temp = {
            passwordError: '',
            password2Error: '',
            emailError: '',
        };

        if (st.password.length < 3) {
            temp['passwordError'] = 'Your password must be at least 3 characters';
            isValid = false;
        }
        if (st.password2 !== st.password) {
            temp['password2Error'] = 'Your passwords do not match.';
            isValid = false;
        }

        const validateEmailResponse = Validate.email(st.email);
        if(validateEmailResponse.errors.length) {
            // temp['emailError'] = Utils.get("validateEmailResponse.errors", "0.message");
            temp['emailError'] = validateEmailResponse.errors[0].message;
            isValid = false;
        }

        this.setState(temp);
        return isValid;
    }

    handleSubmit() {
        const st = this.state;

        const validatePassword = this.validatePassword();
        if (!validatePassword) {
            console.log("Password is not valid");
        } else {
            let user = {
                email: st.email,
                hash: st.hash,
                password: st.password
            };
            this.apiResetPassword(user);
        }
    }

    apiResetPassword(user) {
        Api.postPasswordReset(user, (response) => {
            if(Utils.get(response, "errors", "").length && false) {
                // TODO: Show error
                this.setState({
                    passwordError: response.errors[0].message,
                });
            } else {
                // _self.showEmailVerify(res.data);
                // this.props.history.push('/registersuccess');
                this.setState({
                    isForgotPasswordSuccess: true,
                });
            }
        });
    }

    renderSuccessMessage() {
        const st = this.state;

        return (
            <Div style={{color: "green", textAlign: "center", marginBottom: 15, marginTop: -20}}>
                Your reset password link has been sent to your email!
            </Div>
        )
    }

    renderValidationErrors() {
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="validationErrorsContainer" style={{paddingBottom: 20}}>
                <Div className="emailError" style={sty.emailError}>
                    {st.passwordError}
                </Div>
                <Div className="emailError" style={sty.emailError}>
                    {st.password2Error}
                </Div>
                <Div className="emailError" style={sty.emailError}>
                    {st.emailError}
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="forgotPasswordComponent" style={{...sty.container, ...pr.style}}>
                <Div
                    style={Styles.purpleHeadline}
                >
                    {st.status !== 3 ?
                        <Div>Email Verify</Div>
                        :
                        <Div>Reset Password</Div>
                    }
                </Div>

                {st.status === 1 ?
                    <Div>
                        <Div style={sty.headlineDark}>Success</Div>
                        <Div style={sty.agreement}>
                            Your email has been verified.
                        </Div>
                        <Div style={sty.buttonContainer}>
                            <Button
                                clickHandler={this.goToLogin.bind(this)}
                            >
                                Get to Afterparty
                            </Button>
                        </Div>
                    </Div>
                    :
                    null
                }

                {st.status === 2 ?
                    <Div>
                        <Div style={{...sty.headlineDark, ...{textAlign: "center", marginBottom: 20}}}>
                            Invalid Verification!
                        </Div>
                        <Button style={{...sty.agreement, ...{marginLeft: "auto", marginRight: "auto"}}} onClick={this.goToForgot.bind(this)}>
                            Please Verify Again
                        </Button>
                    </Div>
                    :
                    null
                }

                {st.status === 3 ?
                    <Div>
                        <Div style={sty.agreement}>
                            <Div style={{margin: '0 15%'}}>
                                <Input
                                    placeholder='New Password'
                                    error={st.passwordError}
                                    onChange={this.changeInput.bind(this, 'password')}
                                    type='password'
                                />
                                <Input
                                    placeholder='Confirm New Password'
                                    error={st.password2Error}
                                    onChange={this.changeInput.bind(this, 'password2')}
                                    type='password'
                                />

                                {this.renderValidationErrors()}
                                {st.isForgotPasswordSuccess ? this.renderSuccessMessage() : null}

                                {st.isForgotPasswordSuccess ?
                                    <Button
                                        style={{width: "fit-content", margin: "0 auto"}}
                                        onClick={this.goToLogin.bind(this)}
                                    >
                                        Go to Login
                                    </Button>
                                    :
                                    <Button
                                        style={{width: "fit-content", margin: "0 auto"}}
                                        onClick={this.handleSubmit.bind(this)}
                                    >
                                        Submit
                                    </Button>
                                }

                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        container: {
            maxWidth: 400,
        },
        emailError: {
            color: Colors.redError,
            textAlign: "center",
        },
        bottomForm: {
            display: "flex",
            maxWidth: 384,
            width: "100%",
            justifyContent: "space-around",
            paddingTop: 20,
            paddingBottom: 54
        },
        connectWallet: {
            display: "flex",
            alignItems: "center",
        },
        button: {
            width: 200,
        },
        fileUploadInput: {
            display: "none",
        },
        chooseFileButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: Colors.white,
            backgroundColor: Colors.pink,
            border: "none",
            boxSizing: "border-box",
            cursor: "pointer",
            padding: "15px 20px 14px",
            borderRadius: 14,
            fontSize: 16,
            width: 220,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 15,
        },
        croppedImage: {
            display: "flex",
            width: 250,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 15,
        },
        uploadImageButton: {
            marginBottom: 15,
            marginLeft: "auto",
            marginRight: "auto",
        },
        twoColumn: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: 400,
            width: "100%"
        },
    };
}
