import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';
import Errors from '../modules/Errors';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import Icon from '../elements/Icon';
import Spinner from '../elements/Spinner';

import Div from './Div';

import errorIcon from "../assets/images/ticketflow/error icon.png"

export default class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            leaderboard: [],
            errors: [],
            leaderboardLoaded: false,
        }
    }

    componentDidMount() {
        const pr = this.props;

        const artistId = parseInt(pr.artistId);
        const collectibleId = Utils.getRequest('collectible', undefined, false, true);
        const collectionId = Utils.getRequest('collection', undefined, false, true);

        let reqParams = {};
        if(artistId) {
            reqParams['artist_id'] = artistId;
        }
        if(collectibleId) {
            reqParams['collectible'] = collectibleId;
        }
        if(collectionId) {
            reqParams['collection'] = collectionId;
        }

        Api.getShortLinkPost("instreamleaderboard", (response) => {
        // Api.getPost(117, (response) => {
            if(Utils.get(response, "errors", []).length) {
                this.setState({
                    errors: response.errors,
                    leaderboardLoaded: true,
                });
            } else { // NOTE: success
                const leaderboardData = Utils.get(response, "data.leaderboard");
                this.setState({
                    data: Utils.get(response, "data"),
                    leaderboard: leaderboardData,
                    leaderboardLoaded: true,
                });

                if(pr.handleLeaderboardUpdate) {
                    pr.handleLeaderboardUpdate(leaderboardData)
                }
            }
        }, reqParams);
    }

    renderHandUpOverlay(customerId) {
        const pr = this.props;
        const sty = this.styles;

        if(!pr.userHandUpDict || !pr.userHandUpDict[customerId]) {
            return null;
        }

        const handUpUser = pr.userHandUpDict[customerId];

        if(handUpUser.extra_id == 1) { // NOTE: hand is raised
            if(handUpUser.extra_str >= 1) { // NOTE: hand has been accepted at least 1 time
                return (
                    <Div className="leaderboardHandOverlay" style={{...sty.leaderboardHandOverlay, ...{background: "rgba(255, 0, 0, 0.79)"}}}>
                        <Image
                            className="handImageSecondTime"
                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_14c85fb405a98f085523bf884dfb0246.png"
                            style={{width: 38}}
                        />
                    </Div>
                )
            } else {
                return (
                    <Div className="leaderboardHandOverlay" style={sty.leaderboardHandOverlay}>
                        <Image
                            className="handImage"
                            src="https://chorusuploads.s3.us-west-1.amazonaws.com/upload_abed636bfef256a88f9805c75d60879f.png"
                            style={{width: 25}}
                        />
                    </Div>
                )
            }
        }

        return null;
    }

    renderAvatarPhoto(leader, size, num, borderColor, borderSize, isMe, prScreenMobile) {
        const pr = this.props;
        const sty = this.styles;

        const imageUrl = leader.photo && leader.photo.length > 0 ? leader.photo : "/images/avatars/male01.png";
        let imageStyle = {width:size,height:size,background:Color.purpleDark,borderRadius:100,boxSizing: "border-box", alignSelf: "center"};
        let avatarNum = $.extend(true, {}, this.styles.avatarNum);

        const customerId = leader.customer_id ? leader.customer_id : leader.id;

        if(borderColor) {
            borderColor = "transparent";
            borderSize = borderSize ? borderSize : 3;
            borderSize = pr.screen.mobile ? 2 : borderSize;
            imageStyle['border'] = `${borderSize}px solid ${borderColor}`;
            // imageStyle['background'] = "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)";
            if(num == 1) {
                imageStyle["filter"] = "filter: drop-shadow(0px 3.49166px 73.3248px #FFD466)";
                imageStyle['background'] = "linear-gradient(#C92D7A, #FFB84F) padding-box, linear-gradient(0deg, #C92D7A, #FFB84F) border-box";
                imageStyle["boxShadow"] = prScreenMobile ? "none" : "0px 3.4916584491729736px 73.3248291015625px 0px #FFD466";
            } else if(num == 2) {
                imageStyle['background'] = "linear-gradient(#7A6A80, #DEDEDE) padding-box, linear-gradient(0deg, #7A6A80, #DEDEDE) border-box";
            } else if(num == 3) {
                imageStyle['background'] = "linear-gradient(#6B2222, #AF6660) padding-box, linear-gradient(0deg, #6B2222, #AF6660) border-box";
            } else {
                imageStyle["filter"] = "drop-shadow(0px 2.63157px 15.7894px #0F0420)";
                imageStyle['background'] = "linear-gradient(#C92D7A, #EE6E62) padding-box, linear-gradient(135deg, #C92D7A, #EE6E62) border-box";
            }

            avatarNum['background'] = "linear-gradient(135deg, #C92D7A 0%, #EE6E62 100%)";
            avatarNum['color'] = "white";
            avatarNum['paddingTop'] = 4;
            avatarNum['fontWeight'] = 500;
            avatarNum["fontSize"] = pr.screen.mobile || pr.type == 2 ? 12 : avatarNum["fontSize"];
        }

        return (
            <Div className="avatarPhotoContainer" style={{width: "fit-content", display: "flex", alignSelf: "center", position: "relative"}} data-customer_id={customerId}>
                {this.renderHandUpOverlay(customerId)}

                <Image className="avatarPicture imageStyle" style={imageStyle} src={imageUrl} />
                { isMe ?
                    <Div className="avatarNumber" style={avatarNum}>YOU</Div>
                    :
                    null
                }
            </Div>
        );
    }

    renderInviteVipToStreamButton(customerId) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE if you're not the host and your live, then return null
        if(!(pr.isHost && pr.isLive)) {
            return null;
        }

        let disabled = true;

        if(pr.vipIsInLiveStream || pr.disableAllInviteButtons) {
            disabled = true;
        } else {
            const userId = Utils.get(pr, "user.id");
            for(var i = 0; i < pr.onlineUsers.length; i++) {
                if(customerId == userId) {
                    disabled = true;
                } else if(customerId == pr.onlineUsers[i]) {
                    disabled = false;
                }
            }
        }

        return (
            <Button
                onClick={pr.inviteToStream ? pr.inviteToStream.bind(this, customerId) : () => {}}
                color="pinkGradient"
                size="small"
                style={sty.inviteToStreamButton}
                disabled={disabled}
            >
                Invite to Stream
            </Button>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const leaders = Utils.get(st, 'leaderboard.leaders', []);
        const currentUser = Utils.get(st, 'leaderboard.current_user', []);
        // const leaderboardType = Utils.get(st.data, 'props.type', 1);
        let leaderboardType = pr.type ? pr.type : 1;

        const leader1 = Utils.get(leaders, '0');
        const leader2 = Utils.get(leaders, '1');
        const leader3 = Utils.get(leaders, '2');
        const otherLeaders = leaders.slice(3, 11);

        const mediaQuery = leaderboardType == 2 ? 'md' : StyleUtils.getWidthType(pr.screen.width, 'md');
        const prScreenMobile = leaderboardType == 2 || pr.screen.mobile ? true : false;

        const otherLeadersContainer = StyleUtils.getMediaStyle('otherLeadersContainer', sty.otherLeadersContainer, sty, mediaQuery);
        const usernameText = StyleUtils.getMediaStyle('usernameText', sty.usernameText, sty, mediaQuery);
        const leaderPointsInRow = StyleUtils.getMediaStyle('leaderPointsInRow', sty.leaderPointsInRow, sty, mediaQuery);
        const usernameTextTop = StyleUtils.getMediaStyle('usernameTextTop', sty.usernameTextTop, sty, mediaQuery);
        const leaderboardHeader = StyleUtils.getMediaStyle('leaderboardHeader', sty.leaderboardHeader, sty, mediaQuery);
        const userLeaderboardContainer = StyleUtils.getMediaStyle('userLeaderboardContainer', sty.userLeaderboardContainer, sty, mediaQuery);
        const leaderboardFirstPlace = StyleUtils.getMediaStyle('leaderboardFirstPlace', sty.leaderboardFirstPlace, sty, mediaQuery);
        const leaderboardSecondPlace = StyleUtils.getMediaStyle('leaderboardSecondPlace', sty.leaderboardSecondPlace, sty, mediaQuery);
        const leaderboardThirdPlace = StyleUtils.getMediaStyle('leaderboardThirdPlace', sty.leaderboardThirdPlace, sty, mediaQuery);
        const leaderboardRow = StyleUtils.getMediaStyle('leaderboardRow', sty.leaderboardRow, sty, mediaQuery);
        const rankPoints = StyleUtils.getMediaStyle('rankPoints', sty.rankPoints, sty, mediaQuery);
        const userPoints = StyleUtils.getMediaStyle('userPoints', sty.userPoints, sty, mediaQuery);
        const fanMessage = StyleUtils.getMediaStyle('fanMessage', sty.fanMessage, sty, mediaQuery);
        const trophyIcon = StyleUtils.getMediaStyle('trophyIcon', sty.trophyIcon, sty, mediaQuery);
        const trophyIconSmaller = StyleUtils.getMediaStyle('trophyIconSmaller', sty.trophyIconSmaller, sty, mediaQuery);
        const gradientTrophyBackground = StyleUtils.getMediaStyle('gradientTrophyBackground', sty.gradientTrophyBackground, sty, mediaQuery);
        const usernamePointsContainer = StyleUtils.getMediaStyle('usernamePointsContainer', sty.usernamePointsContainer, sty, mediaQuery);
        const largeQuoteContainer = StyleUtils.getMediaStyle('largeQuoteContainer', sty.largeQuoteContainer, sty, mediaQuery);
        const leaderPoints = StyleUtils.getMediaStyle('leaderPoints', sty.leaderPoints, sty, mediaQuery);
        const rankText = StyleUtils.getMediaStyle('rankText', sty.rankText, sty, mediaQuery);

        const pinkDotQuoteLeft = <Div className="pinkDotQuoteLeft" style={sty.pinkDotQuoteLeft}>“</Div>;
        const pinkDotQuoteRight = <Div className="pinkDotQuoteRight" style={sty.pinkDotQuoteRight}>”</Div>;

        if(!st.leaderboardLoaded) {
            return (
                <Div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Spinner
                        message="Loading the leaderboard..."
                    />
                </Div>
            )
        }

        if(leaders.length == 0 && st.leaderboardLoaded) {
            return (
                <Image
                    className="emptyLeaderboard"
                    style={{
                        maxWidth: 392,
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: Colors.indigoLight,
                        padding: 10,
                        borderRadius: 16,
                        width: "100%",
                        boxSizing: "border-box",
                    }}
                    src="https://dhwk6gixvyfl3.cloudfront.net/upload_3917f338a2eb044182d6c1e525b3e5c7.png"
                />
            )
        }

        let renderYou = true;

        const userId = Utils.get(pr, "user.id");

        // NOTE: if the current user is NOT in the top 3 and they can't he the host
        if(Utils.get(leader1, "customer_id") == userId ||
           Utils.get(leader2, "customer_id") == userId ||
           Utils.get(leader3, "customer_id") == userId ||
           pr.isHost
       ) {
            renderYou = false;
        }

        return (
            <Div
                style={{...sty.leaderboardComponent, ...{paddingTop: prScreenMobile ? 20 : 100}, ...pr.style}}
                className="leaderboardComponent"
                id="leaderboard"
            >
                <Div
                    style={leaderboardHeader}
                    className="leaderboardHeader"
                >
                    {/* NOTE: 1ST PLACE */}
                    <Div
                        style={leaderboardFirstPlace}
                        className="leaderboardFirstPlace"
                    >
                        {this.renderAvatarPhoto(leader1, prScreenMobile ? 56 : 131, 1, Colors.magenta, 3, false, prScreenMobile)}
                        <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                            <Div className="usernameTextTop" style={usernameTextTop}>
                                @{Utils.formatUsername(Utils.get(leader1, 'username'), 20, false, "end")}
                            </Div>
                            <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                {prScreenMobile ? null : pinkDotQuoteLeft}
                                <Div className="fanMessage" style={fanMessage}>
                                    {Utils.get(leader1, 'artist_question', "").trim().length > 0 ? Utils.shortenString(Utils.get(leader1, 'artist_question'), 100, true) : "..."}
                                </Div>
                                {prScreenMobile ? null : pinkDotQuoteRight}
                            </Div>
                            {this.renderInviteVipToStreamButton(leader1.customer_id)}
                        </Div>

                        <Div className="gradientTrophyContainer" style={{marginLeft: "auto"}}>
                            <Div className="gradientTrophyBackground" style={gradientTrophyBackground}>
                                <Image
                                    style={trophyIcon}
                                    className="trophyIcon"
                                    src="https://dhwk6gixvyfl3.cloudfront.net/upload_9267b88583f7d63623387a14802c2bbc.png"
                                />
                                <Div className="leaderPoints" style={leaderPoints}>
                                    {Utils.get(leader1, 'points')}
                                </Div>
                            </Div>
                        </Div>
                    </Div>

                    {/* NOTE: 2ND PLACE */}
                    {leader2 ?
                        <Div
                            style={sty.leaderboardSecondPlaceContainer}
                            className="leaderboardSecondPlaceContainer"
                        >
                            <Div
                                style={leaderboardSecondPlace}
                                className="leaderboardSecondPlace"
                            >
                                {this.renderAvatarPhoto(leader2, prScreenMobile ? 56 : 98, 2, Colors.magenta)}
                                <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                                    <Div className="usernameTextTop" style={usernameTextTop}>
                                        @{Utils.formatUsername(Utils.get(leader2, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                        {prScreenMobile ? null : pinkDotQuoteLeft}
                                        <Div className="fanMessage" style={fanMessage}>
                                            {Utils.get(leader2, 'artist_question', "").trim().length > 0 ? Utils.shortenString(Utils.get(leader2, 'artist_question'), 100, true) : "..."}
                                        </Div>
                                        {prScreenMobile ? null : pinkDotQuoteRight}
                                    </Div>
                                    {this.renderInviteVipToStreamButton(leader2.customer_id)}

                                </Div>

                                <Div className="gradientTrophyContainer" style={{marginLeft: "auto"}}>
                                    <Div className="gradientTrophyBackground" style={{...gradientTrophyBackground, ...{width: prScreenMobile ? 65 : 112, height: prScreenMobile ? 75 : 131}}}>
                                        <Image
                                            style={trophyIconSmaller}
                                            className="trophyIconSmaller"
                                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_7d6304ab37e2cc2f2a87ae481365246d.png"
                                        />
                                        <Div className="leaderPoints" style={{...leaderPoints, ...{fontSize: prScreenMobile ? 16 : 28}}}>
                                            {Utils.get(leader2, 'points')}
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }

                    {/* NOTE: 3RD PLACE */}
                    {leader3 ?
                        <Div
                            style={sty.leaderboardThirdPlaceContainer}
                            className="leaderboardThirdPlaceContainer"
                        >
                            <Div
                                style={leaderboardThirdPlace}
                                className="leaderboardThirdPlace"
                            >
                                {this.renderAvatarPhoto(leader3, prScreenMobile ? 56 : 98, 3, Colors.magenta)}
                                <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                                    <Div className="usernameTextTop" style={usernameTextTop}>
                                        @{Utils.formatUsername(Utils.get(leader3, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                        {prScreenMobile ? null : pinkDotQuoteLeft}
                                        <Div className="fanMessage" style={fanMessage}>
                                            {Utils.get(leader3, 'artist_question', "").trim().length > 0 ? Utils.shortenString(Utils.get(leader3, 'artist_question'), 100, true) : "..."}
                                        </Div>
                                        {prScreenMobile ? null : pinkDotQuoteRight}
                                    </Div>
                                    {this.renderInviteVipToStreamButton(leader3.customer_id)}

                                </Div>

                                <Div className="gradientTrophyContainer" style={{marginLeft: "auto"}}>
                                    <Div className="gradientTrophyBackground" style={{...gradientTrophyBackground, ...{width: prScreenMobile ? 65 : 112, height: prScreenMobile ? 75 : 131}}}>
                                        <Image
                                            style={trophyIconSmaller}
                                            className="trophyIconSmaller"
                                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_7bd4b0556e594c8752a410f9ed6afb53.png"
                                        />
                                        <Div className="leaderPoints" style={{...leaderPoints, ...{fontSize: prScreenMobile ? 16 : 28}}}>
                                            {Utils.get(leader3, 'points')}
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }
                </Div>

                {/* NOTE: YOU i.e. Current User */}
                {(Utils.get(currentUser, 'id') || Utils.get(currentUser, 'customer_id')) && renderYou ?
                    <Div
                        className="userLeaderboardContainer"
                        style={userLeaderboardContainer}
                    >
                        {this.renderAvatarPhoto(currentUser, prScreenMobile ? 56 : 92, 0, Colors.magenta, 3, true)}
                        <Div className="usernamePointsContainer" style={usernamePointsContainer}>
                            <Div className="usernameTextTop" style={{...usernameTextTop, ...{paddingLeft: 0}}}>
                                @{Utils.formatUsername(Utils.get(currentUser, 'username'), 20, false, "end")}
                            </Div>
                            <Div className="userPointsContainer" style={sty.userPointsContainer}>
                                <Div className="userPoints" style={userPoints}>
                                    {Utils.get(currentUser, 'points')}
                                </Div>
                                <Div className="userPoints" style={{fontSize: 18, marginLeft: 8}}>Points</Div>
                            </Div>
                            {this.renderInviteVipToStreamButton(currentUser.id)}
                        </Div>

                        <Div className="rankPointsContainer" style={sty.rankPointsContainer}>
                            <Div className="rankText" style={rankText}>
                                {Utils.get(currentUser, 'points') == 0 ?
                                    ""
                                    :
                                    "Rank"
                                }
                            </Div>
                            <Div className="rankPoints" style={rankPoints}>
                                {Utils.get(currentUser, 'points') == 0 ?
                                    <Div style={{fontSize: 26}}>
                                        Not<br/>Ranked
                                    </Div>
                                    :
                                    Utils.get(currentUser, 'rank')
                                }
                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }

                {/* NOTE: TOP 4 - 10 Users */}
                <Div className="otherLeadersContainer" style={otherLeadersContainer}>
                     {otherLeaders.map((leaderItem, bulletIndex) => {
                         return (
                             <Div key={`leaders-${bulletIndex}`} className="leaderboardRow" style={leaderboardRow}>
                                {this.renderAvatarPhoto(leaderItem, prScreenMobile ? 56 : 92, 0, Colors.magenta)}
                                <Div className="usernamePointsContainer" style={usernamePointsContainer}>
                                    <Div className="usernameTextTop" style={{...usernameTextTop, ...{paddingLeft: 0}}}>
                                        @{Utils.formatUsername(Utils.get(leaderItem, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="userPointsContainer" style={sty.userPointsContainer}>
                                        <Div className="userPoints" style={userPoints}>
                                            {Utils.get(leaderItem, 'points')}
                                        </Div>
                                        <Div className="userPoints" style={{fontSize: 18, marginLeft: 8}}>Points</Div>
                                    </Div>
                                    {this.renderInviteVipToStreamButton(leaderItem.customer_id)}
                                </Div>

                                <Div className="rankPointsContainer" style={sty.rankPointsContainer}>
                                    <Div className="rankText" style={rankText}>
                                        Rank
                                    </Div>
                                    <Div className="rankPoints" style={rankPoints}>
                                        {Utils.get(leaderItem, 'rank')}
                                    </Div>
                                </Div>
                            </Div>
                         )
                     })}
                </Div>
            </Div>
        );
    }

    styles = {
        leaderboardComponent: {
            width: "100%",
            fontFamily: "Nyata",
            color: "white",
            maxWidth: 692,
            marginLeft: "auto",
            marginRight: "auto",
        },
        avatarNum: {
            position:"absolute",
            bottom:-10,
            background: Colors.purpleDark,
            borderRadius: 100,
            padding: "3px 10px",
            textAlign: "center",
            boxShadow: "0px 1.57022px 13.3469px #DC4D6F",
            textShadow: "0px 1.57022px 6.28087px #FFAD9C",
            fontSize: 19,
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 2,
        },
        leaderboardHeader: {
            width: "100%",
            marginBottom: 10,
        },
        leaderboardHeader_md: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        leaderboardFirstPlace: {
            height: 181,
            backgroundColor: Colors.indigoLight,
            borderRadius: 36,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #E8B365",
            marginBottom: 12,
        },
        leaderboardFirstPlace_md: {
            // height: 94,
            height: "auto",
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardSecondPlace: {
            height: 162,
            backgroundColor: Colors.indigoLight,
            borderRadius: 32,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #EE6E62",
            marginBottom: 12,
        },
        leaderboardSecondPlace_md: {
            // height: 94,
            height: "auto",
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardThirdPlace: {
            height: 162,
            backgroundColor: Colors.indigoLight,
            borderRadius: 32,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #C92D7A",
            marginBottom: 12,
        },
        leaderboardThirdPlace_md: {
            // height: 94,
            height: "auto",
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardSecondPlaceContainer: {
            maxWidth: 619,
            marginLeft: "auto",
            marginRight: "auto",
        },
        leaderboardThirdPlaceContainer: {
            maxWidth: 619,
            marginLeft: "auto",
            marginRight: "auto",
        },
        gradientTrophyBackground: {
            position: "relative",
            background: "linear-gradient(180deg, rgba(93, 57, 172, 0.2) 0%, rgba(253, 115, 73, 0.2) 100%)",
            borderRadius: 22,
            width: 125,
            height: 146,
        },
        gradientTrophyBackground_md: {
            width: 65,
            height: 75,
            borderRadius: 12,
        },
        trophyIcon: {
            position: "absolute",
            width: 240,
            top: -94,
            left: -54,
        },
        trophyIcon_md: {
            width: 120,
            top: -50,
            left: -25,
        },
        trophyIconSmaller: {
            position: "absolute",
            width: 200,
            top: -72,
            left: -40,
        },
        trophyIconSmaller_md: {
            width: 125,
            top: -52,
            left: -26,
        },
        rankPointsContainer: {
            marginLeft: "auto",
            textAlign: "center",
        },
        rankText: {
            background: "linear-gradient(180deg, #EE9462 0%, #C92D7A 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textFillColor: "transparent",
            backgroundClip: "text",
            textShadow: "0px 0.341359px 0.682717px rgba(0, 0, 0, 0.1)",
            fontSize: 20,
        },
        rankText_md: {
            fontSize: 16,
        },
        rankPoints: {
            fontSize: 50,
            background: "linear-gradient(180deg, #EE9462 0%, #C92D7A 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textFillColor: "transparent",
            backgroundClip: "text",
            textShadow: "0px 0.341359px 0.682717px rgba(0, 0, 0, 0.1)",
        },
        rankPoints_md: {
            fontSize: 32,
        },
        userPoints: {
            // border: "2px solid #69347A",
            background: "linear-gradient(90deg, rgb(201, 45, 122) 0%, rgb(238, 110, 98) 100%)",
            borderRadius: 100,
            padding: "6px 13px",
            textAlign: "center",
            fontSize: 25,
            textShadow: "0px 1.99931px 7.99723px #DD60FE",
            width: "fit-content",
        },
        userPoints_md: {
            fontSize: 16,
            padding: "4px 8px",
            borderRadius: 100,
        },
        userLeaderboardContainer: {
            maxWidth: 500,
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "20px 28px",
            // background: "linear-gradient(135deg, #C92D7A 0%, #EE6E62 100%)",
            // background: "linear-gradient(#C92D7A, #EE6E62) padding-box, linear-gradient(135deg, #C92D7A, #EE6E62) border-box",
            background: "radial-gradient(164.03% 1680.21% at 106.53% 79.63%, #271D37 0%, #69347A 100%), #271D37",
            borderRadius: 33,
            border: `2px solid ${Colors.magenta}`,
            backgroundColor: Colors.indigoLight,
            //boxSizing: "border-box",
            marginBottom: 12,
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: "inset 0px 0px 15.7894px rgba(237, 107, 100, 0.4)",
        },
        userLeaderboardContainer_md: {
            borderRadius: 20,
            padding: "8px 17px",
            paddingBottom: 15,
        },
        otherLeadersContainer: {
            maxWidth: 500,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        otherLeadersContainer_md: {
        },
        leaderboardRow: {
            display: "flex",
            justifyContent: "",
            alignItems: "center",
            width: "100%",
            marginBottom: 12,
            padding: "20px 24px",
            backgroundColor: Colors.indigoLight,
            borderRadius: 33,
            border: "2px solid #888193",
        },
        leaderboardRow_md: {
            padding: "8px 17px",
            borderRadius: 20,
        },
        usernameText: {
            fontSize: 20,
            fontWeight: 400,
        },
        usernameText_md: {
        },
        quoteTextContainer: {
            display: "flex",
        },
        fanMessage: {
            fontWeight: 300,
            fontSize: 20,
        },
        fanMessage_md: {
            fontSize: 12,
        },
        largeQuoteContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: 15,
        },
        largeQuoteContainer_md: {
            marginLeft: 15,
        },
        usernameTextTop: {
            fontSize: 20,
            fontWeight: 400,
            color: "#BABABA",
            paddingLeft: 55,
            marginBottom: 4,
        },
        usernameTextTop_md: {
            paddingLeft: 0,
            fontSize: 15,
        },
        pinkDotQuoteLeft: {
            fontSize: 68,
            height: 36,
            width: 56,
            borderRadius: 50,
            color: Colors.magenta,
            paddingLeft: 12,
            paddingRight: 12,
            flexShrink: 0,
            fontWeight: 400,
            marginTop: -10,
        },
        pinkDotQuoteRight: {
            fontSize: 68,
            height: 36,
            width: 56,
            borderRadius: 50,
            color: Colors.magenta,
            paddingLeft: 12,
            paddingRight: 12,
            flexShrink: 0,
            marginRight: 30,
            marginTop: "auto",
            fontWeight: 400,
        },
        usernamePointsContainer: {
            marginLeft: 37,
        },
        usernamePointsContainer_md: {
            marginLeft: 15,
        },
        leaderPointsInRow: {
            fontSize: 28,
            fontWeight: 400,
            marginLeft: "auto",
        },
        leaderPointsInRow_md: {
            fontSize: 21,
        },
        leaderPoints: {
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: 32,
            fontWeight: 500,
            padding: "7px 15px",
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
            borderRadius: 100,
            textShadow: "0px 2.08515px 8.3406px #DD60FE",
        },
        leaderPoints_md: {
            padding: "5px 8px",
            fontSize: 16,
        },
        inviteToStreamButton: {
            marginTop: 9,
        },

        leaderboardHandOverlay: {
            position: "absolute",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 120,
            backgroundColor: "rgba(0, 14, 136, 0.79)",
            width: 50,
            height: 50,
            marginTop: 3,
            marginLeft: 3,
        },
        userPointsContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: 10,
        },
    }
}
