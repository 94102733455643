import React from 'react';

import Styles from '../modules/Styles';
import badgeIcons from '../assets/badges_sprite.png';
import Div from '../components/Div';

export default class Badge extends React.Component {
    render() {
        const badgeNum = parseInt(this.props.num);
        const col = (badgeNum-1) % 16;
        const row = parseInt((badgeNum-1) / 16);
        const left = 48 * col + 1;
        const top = 48 * row + 1;
        const pos = "-"+left+"px -"+top+"px"; //"-46px 0"
        const width = 46;
        const height = 46;
        const style = {
            //backgroundImage: badgeIcons,
            //backgroundImage: `url("https://via.placeholder.com/500")`
            left: left,
            top: top,
            width: width,
            height: height,
            backgroundImage: `url(${badgeIcons})`,
            backgroundPosition: pos,
            //backgroundImage: `url(${badgeIcons}) -51px 0`,
        }

        return (
            <Div className="apBadge" style={style}>
            </Div>
        );
    }

    styles = {

    }
}
