import React, { useEffect, useCallback, useState } from 'react';
import Modal from '../elements/Modal';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';

// Elements
import Div from './Div';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';

// Remember to add card to getPaymentMethodIconSrc so its picked up dynamically
import cashapp from "../assets/images/payment_methods/cashapp.svg"
import paypalIcon from "../assets/images/payment_methods/paypal.png"
import paylater from "../assets/images/payment_methods/paypal-paylater.png"
import venmo from "../assets/images/payment_methods/venmo.png"
import newCard from "../assets/images/payment_methods/newCard.svg"
import bankUnknown from "../assets/images/payment_methods/bankUnknown.png"
import mastercard from "../assets/images/payment_methods/mastercard.png"
import visa from "../assets/images/payment_methods/visa.svg"
import discover from "../assets/images/payment_methods/discover.svg"
import amex from "../assets/images/payment_methods/amex.svg"

import StripePayment from '../elements/StripePayment';

import '../App.css';
import './StripePayment.css'

// supports keeping other query params so only those below will be removed
export const cleanUrlQueryStrings = () => {
    // only removes stripe v3 redirect query strings
    let url = new URL(window.location.href);
    // Delete the foo parameter.
    url.searchParams.delete('payment_intent');
    url.searchParams.delete('payment_intent_client_secret');
    url.searchParams.delete('redirect_status');
    url.searchParams.delete('paymentType');
    url.searchParams.delete('computedAmount');

    // force reload
    // window.location.href = newUrl

    // does not force reload
    history.replaceState(null, '', url.href)
}

/**
 * Create new payment intent
 * @param {Object} data
 * e.g.
 *
 * ```
 * {
 *     quantity,
 *     collectableId,
 *     amount,
 *     paymentType,
 *     roomId
 * }
 * ```
 */
const handleCreateNewPaymentIntent = (data, setPaymentIntent, postSuccessCallback, errorCallBack) => {
    Api.postStripeIntent(data, (o) => {
        if (o && o.errors.length > 0) {
            // tell user there was an error
            const error = o.errors[0].message
            if (errorCallBack) {
                errorCallBack(error)
            }
            return
        }
        // success
        const stringInfo = o.data
        // set total computed amount to show with title
        const computedAmount = stringInfo.amount / 100
        stringInfo["computedAmount"] = computedAmount
        if (setPaymentIntent) {
            setPaymentIntent(stringInfo)
        }

        if (postSuccessCallback) {
            postSuccessCallback(stringInfo)
        }
    });
}

const getStripePaymentIntent = ({
    stripeProps,
    setPaymentIntent,
    setShowPaymentMethods,
    setSecondPaymentIntent,
    setMessageObject,
    setSelectedPaymentMethodId,
    postStripeCustomerIdCallback,
}) => {
    // BE supports sending no amount when collectableId is sent
    if(!stripeProps.amount && !stripeProps.collectibleId) {
        setMessageObject({stripe: "error: missing stripe amount and collectible"})
        return;
    }

    const quantity = Utils.get(stripeProps, 'quantity')
    const collectibleId = Utils.get(stripeProps, 'collectibleId', null)
    const formData = JSON.stringify(Utils.get(stripeProps, 'formData', null))
    const amount = Utils.get(stripeProps, 'amount')
    const paymentType = Utils.get(stripeProps, 'paymentType', null)
    const roomId = Utils.get(stripeProps, 'roomId', null)

    const handleError = (error) => {
        setMessageObject({stripe: error || "System error. Please contact support"})
    }

    // first
    handleCreateNewPaymentIntent({
        quantity,
        collectibleId,
        amount,
        paymentType,
        roomId,
        formData,
    }, setPaymentIntent, (stripeInfo) => {
        const paymentMethodId = Utils.get(stripeInfo, 'paymentMethodId')
        if(paymentMethodId) {
            setShowPaymentMethods(true)
            setSelectedPaymentMethodId(paymentMethodId)
        }
        const stripeCustomerId = Utils.get(stripeInfo, 'stripeCustomerId')
        if (stripeCustomerId) {
            /**
             * create second payment intent for payment that does not store payment method
             * - this allows support for paying w/o saving new payment method to the user via the checkbox
             */
            handleCreateNewPaymentIntent({
                quantity,
                collectibleId,
                amount,
                paymentType,
                roomId,
                formData,
                savePaymentMethod: false,
            }, setSecondPaymentIntent, null, handleError)
            if (postStripeCustomerIdCallback) {
                postStripeCustomerIdCallback(stripeCustomerId)
            }
        }
    }, handleError)
}

export const fetchStripePaymentMethods = (setPaymentMethods, setShowPaymentMethods, errorCallBack) => {
    Api.getStripePaymentMethods((o) => {
        if (o && o.errors.length > 0) {
            // tell user there was an error
            const error = o.errors[0].message
            if (errorCallBack) {
                errorCallBack(error)
            }
            return
        }

        const paymentMethods = Utils.get(o, "data", []);
        setPaymentMethods(paymentMethods)
        if (paymentMethods.length && setShowPaymentMethods) {
            setShowPaymentMethods(true)
        }
    });
}

const handleSubmitWithPaymentMethod = ({
    paymentMethod,
    paymentIntent,
    setIsLoading,
    setIsSuccess,
    setMessageObject,
    setPaypalRendered,
    returnUrl,
    stripeProps,
    setDisableCompletePaymentButton,
    metadata,
    errorCallBack,
}) => {
    const { paymentMethodSuccessModal, paymentMethodSuccess } = stripeProps
    setIsLoading(true)

    const data = {
        paymentMethodId: paymentMethod.id,
        paymentIntentId: paymentIntent.stripeOrderId,
        returnUrl,
        type: paymentMethod.type,
        stripeProps,
    }

    const handleLoading = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000);
    }

    Api.postStripeConfirmPayment(data, (o) => {
        if (o && o.errors.length > 0) {
            // tell user there was an error
            const error = o.errors[0].message
            if (errorCallBack) {
                errorCallBack(error)
            } else {
                setIsLoading(false)
                if (paymentMethodSuccessModal) {
                    setIsSuccess(false)
                }
                // ensures paypal buttons get re-rendered when back to payment methods again and only one
                setPaypalRendered(false)
                const paymentMethodIdKey = paymentMethod.id
                setMessageObject({
                    stripePaymentMethod: {
                        [paymentMethodIdKey]: o.errors[0].message // or consider a custom message here
                    }
                })
                setDisableCompletePaymentButton(true)
            }
            return
        }
        handleLoading()
        if (paymentMethodSuccessModal) {
            setIsSuccess(true)
        }

        // o.data is actual stripe payment intent. It differs from the stripeInfo which is custom during payment intent creation and it includes the stripe payload as a property
        const data = Utils.get(o, "data", {});
        paymentMethodSuccess(data)

        stripeGtmDataHandler({
            stripeResponse: paymentIntent,
            initalPaymentIntent: paymentIntent,
            metadata
        })
    });
}

export const handleDeletePaymentMethod = ({
    paymentMethod,
    setIsLoading,
    setMessageObject,
    setPaypalRendered,
    setDisableCompletePaymentButton,
    setPaymentMethods,
    errorCallBack,
}) => {
    setIsLoading(true)

    const data = {
        paymentMethodId: paymentMethod.id,
    }

    Api.postStripeDeletePaymentMethod(data, (o) => {
        if (o && o.errors.length > 0) {
            // tell user there was an error
            const error = o.errors[0].message

            setIsLoading(false)

            // ensures paypal buttons get re-rendered when back to payment methods again and only one
            if (setPaypalRendered) {
                setPaypalRendered(false)
            }

            const paymentMethodIdKey = paymentMethod.id
            setMessageObject({
                stripePaymentMethod: {
                    [paymentMethodIdKey]: o.errors[0].message // or consider a custom message here
                }
            })
            if (setDisableCompletePaymentButton) {
                setDisableCompletePaymentButton(true)
            }

            if (errorCallBack) {
                errorCallBack(error)
            }
            return
        }

        setPaymentMethods(o.data)
        if (setDisableCompletePaymentButton) {
            setDisableCompletePaymentButton(false)
        }

        // important for loader to give enought time to ensure paypal re-renders
        setTimeout(() => {
            // ensures paypal buttons get re-rendered when back to payment methods again and only one
            if (setPaypalRendered) {
                setPaypalRendered(false)
            }
            setIsLoading(false)
        }, 3000);
    });
}

const showPayPalButtons = (props) => {
    const { metadata } = props;
    const CREATE_PAYMENT_URL  = '/chorus/paypal_payment';
    const EXECUTE_PAYMENT_URL = '/chorus/paypal_execute/';
    const amount = Utils.get(props, 'amount', 100.00);
    const quantity = Utils.get(props, 'quantity', 1);

    let userSelections = {};
    userSelections['pricePerTicket'] = amount;
    userSelections['quantity'] = quantity;
    userSelections['uid'] = Utils.getRequest('uid', '');
    userSelections['igid'] = Utils.getRequest('igid', '');
    userSelections['collectible'] = Utils.get(props, 'collectibleId');
    userSelections['paymentType'] = Utils.get(props, 'paymentType');
    userSelections['roomId'] = Utils.get(props, 'roomId');
    userSelections['formData'] = Utils.get(props, 'formData', {});
    //userSelections['formData']['msg'] = msg:"Hello World";

    setTimeout(() => {
        paypal.Buttons({
            // Sets up the transaction when a payment button is clicked
            createOrder: (data, actions) => {
                return fetch(CREATE_PAYMENT_URL, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userSelections)
                }).then(function(res) {
                    return res.json();
                }).then(function(data) {
                    return data.orderID;
                })
                }
            ,
            // Finalize the transaction after payer approval
            onApprove: (data, actions) => {
                return actions.order.capture().then(function(orderData) {
                return fetch(EXECUTE_PAYMENT_URL + data.orderID, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderData)
                }).then(function(res) {
                    return res.json();
                }).then(function(details) {
                    details['amount'] = details.tx.debit_amount
                    // TODO: consider logging to third party api for analytics
                    // console.log("details", details);

                    const artistName = Utils.get(metadata, "artist.name");
                    const userEmail = Utils.get(metadata, 'user.email')
                    const userPhone = Utils.get(metadata, 'user.primary_phone1')
                    const value = quantity * amount;
                    const eventProperties = {
                        userID: 'afterparty-'+Utils.get(metadata, 'user.id',''), //string minimum of 5 characters
                        value: value,
                        currency: 'USD',
                        quantity: quantity,
                        paymentsource: 'paypal'
                    };
                    amplitude.track('payment_client_payment_completed', eventProperties);
                    const gtmData = {
                        'event':'ticket_purchase',
                        'purchase.user_id': Utils.get(metadata, 'user.id'),
                        'purchase.user_email': userEmail,
                        'purchase.user_phone': userPhone,
                        'onsale_event_id':'gravy_sep1',
                        'quantity':quantity,
                        'value':value,
                        'currency':'USD',
                        'artist':artistName,
                        type: 'paypal'
                    };
                    window.dataLayer.push(gtmData);

                    if(props.paymentSuccess) {
                        props.paymentSuccess(details);
                    }
                });
            })} }).render('#paypal-button-container-'+ Utils.get(props, 'cardId', 1));
    }, 500)
}

const stripeGtmDataHandler = ({stripeResponse, initalPaymentIntent, metadata}) => {
    /**
     * Notes:
     * - initalPaymentIntent.id and stripeResponse.id may not match for a few reasons:
     * -- for cashapp due to a required new pi to confirm at the same time
     * -- user select not to save payment method and thats what the stripeResponse becomes
     * - BUT the values (e.g. amount, quantity) will be the same.
     */
    // console.log('stripeGtmDataHandler>>>stripeResponse',stripeResponse, '\n initalPaymentIntent', initalPaymentIntent, '\n metadata', metadata )

    const artistName = Utils.get(metadata, "artist.name");
    const userEmail = Utils.get(metadata, 'user.email')
    const userPhone = Utils.get(metadata, 'user.primary_phone1')
    const quantity = Utils.get(initalPaymentIntent, "quantity", 1)
    const value = quantity * Utils.get(stripeResponse, 'amount')

    const eventProperties = {
        userID: 'afterparty-'+Utils.get(metadata, 'user.id', ''), //string minimum of 5 characters
        value: value,
        currency: 'USD',
        quantity: quantity,
        paymentsource: 'stripe'
    };
    amplitude.track('payment_client_payment_completed', eventProperties);
    const gtmData = {
        'event':'ticket_purchase',
        'purchase.user_id': Utils.get(metadata, 'user.id'),
        'purchase.user_email': userEmail,
        'purchase.user_phone': userPhone,
        'onsale_event_id':'collectible_stripe',
        'quantity':quantity,
        'value':value,
        'currency':'USD',
        'artist':artistName,
        type: 'stripe'
    };
    window.dataLayer.push(gtmData);
}

/**
 * Handles custom border for paymentMethods selection and error handling
 * @param {string} error
 * @param {boolean} activePaymentMethod
 * @returns string
 */
export const handleBorder = (error, activePaymentMethod) => {
    if (error) {
        return `3px solid ${Colors.redError}`
    } else if (activePaymentMethod) {
        return "3px solid white"
    } else {
        return `1px solid ${Colors.indigoLighter}`
    }
}

export const getPaymentMethodIconSrc = (type, paymentMethodObject) => {
    const v = paymentMethodObject;
    switch(type) {
        case "cashapp": {
            const nameOnCard = Utils.get(v, 'cashapp.cashtag')
            return {
                src: cashapp,
                title: Utils.toTitleCase(type),
                nameOnCard,
            }
        }
        case "card":
        default: {
            const brand = Utils.get(v, 'card.brand')
            const last4 = Utils.get(v, 'card.last4')
            const nameOnCard = Utils.get(v, 'billing_details.name')
            const cardsSrc = {
                venmo,
                mastercard,
                bankUnknown,
                visa,
                discover,
                amex,
            }
            const src = cardsSrc[brand] ?? cardsSrc.bankUnknown
            return {
                src,
                title: Utils.toTitleCase(brand),
                last4,
                nameOnCard,
            }
        }
    }
}

const PaymentsV2 = (props) => {
    // States
    const [paypalRendered, setPaypalRendered] = useState(false);
    const [stripeRendered, setStripeRendered] = useState(false);
    const [messageObject, setMessageObject] = useState({
        paypal: "",
        stripe: "",
        stripePaymentMethod: {
            'idxxxx': ''
        }
    });
    const [paymentIntent, setPaymentIntent] = useState(undefined);
    const [secondPaymentIntent, setSecondPaymentIntent] = useState(undefined);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [returnUrl, setReturnUrl] = useState(null);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    const [disableCompletePaymentButton, setDisableCompletePaymentButton] = useState(null);

    // props
    const { metadata, modalProps, paypalProps, stripeProps } = props;
    const modalHeight = modalProps.modalHeight || "80vh"// 350 was from Post.js but increase here

    useEffect(() => {
        if (!stripeRendered) {
            getStripePaymentIntent({
                stripeProps,
                setPaymentIntent,
                setShowPaymentMethods,
                setSecondPaymentIntent,
                setMessageObject,
                setSelectedPaymentMethodId,
                postStripeCustomerIdCallback: (stripeCustomerId) => {
                    fetchStripePaymentMethods(setPaymentMethods, setShowPaymentMethods, (error) => {
                        setMessageObject({stripe: error || "System error. Please contact support"})
                    })
                }
            })

            /**
             * Reflects where the user used this component to purchase
             * e.g. collectable, artist profile, liveroom, etc
             * includes query params, such as payment_intent if used directly on the return_url
             * note: paymentType param will be removed on load from RoomV3 and Post when implemented
             *
             * TODO: move to func
             */
            const url = new URL(window.location.href);
            // add new param
            url.searchParams.append('paymentType', stripeProps.paymentType)
            setReturnUrl(url.href)

            // prevent side effects
            setStripeRendered(true)
        }
    }, [])

    useEffect(() => {
        // paypal may render more than once (e.g. payment methods UX change)
        if (!paypalRendered && showPaymentMethods) {
            if (!paypalProps.amount) {
                setMessageObject({paypal: "error: missing paypal amount"})
            }
            showPayPalButtons({ ...paypalProps, metadata });
            setPaypalRendered(true)
        }
    }, [showPaymentMethods, paypalRendered])

    // Used for custom loading page
    // may not be needed now that its implemented inside handleSubmitWithPaymentMethod
    const handleLoading = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            // ensures paypal buttons get re-rendered when back to payment methods again and only one
            setPaypalRendered(false)
        }, 3000);
    }

    // TODO: confirm UX needs it or delete
    if(isLoading) {
        return (
            <Modal
                screen={modalProps.screen}
                className="isLoading"
                hideCloseButton={true}
                onRequestClose={ () => console.log('close modal') }
                color="indigo"
                style={{
                    maxWidth: 500,
                    backgroundColor: modalProps.isDragon ? Colors.dragon : Colors.indigo,
                    maxHeight: "50vh"
                }}
            >
                <Div style={{
                    height: "100%",
                    width:"100%",
                    borderRadius: 16,
                    display: "flex",
                    flexDirection: "column",
                    overflow: 'auto',
                    justifyContent: 'flex-start',
                    gap: 10,
                }}>
                    <Div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        alignItems: "center",
                    }}>
                        {modalProps.loadingTitle && (
                            <Div style={{fontSize: 24}}>
                                {modalProps.loadingTitle}
                            </Div>
                        )}
                        {modalProps.loadingSubtitle && (
                            <Div style={{fontSize: 18}}>
                                {modalProps.loadingSubtitle}
                            </Div>
                        )}
                        <div className="spinner" id="spinner"></div>
                    </Div>
                </Div>
            </Modal>
        )
    }

    if(isSuccess) {
        return (
            <Modal
                screen={modalProps.screen}
                className="isSuccess"
                onRequestClose={modalProps.onRequestClose}
                color="indigo"
                style={{
                    maxWidth: 500,
                    backgroundColor: modalProps.isDragon ? Colors.dragon : Colors.indigo,
                    maxHeight: "50vh"
                }}
            >
                <Div style={{
                    height: "100%",
                    width:"100%",
                    borderRadius:12,
                    display: "flex",
                    flexDirection: "column",
                    overflow: 'auto',
                    justifyContent: 'flex-start',
                    gap: 10
                }}>
                    <Div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        alignItems: "center",
                    }}>
                        <Div style={{fontSize: 24}}>
                            Thank you for your purchase
                        </Div>
                        <Div style={{fontSize: 18 }}>
                            We are processing your purchase. Check back soon.
                        </Div>
                    </Div>
                </Div>
            </Modal>
        )
    }

    const handleTitle = (title) => {
        if (title.charAt(0) === "@") {
            title = title.substring(1);
        }

        return title
    }

    // NOTE: render()
    return (
        <Modal
            screen={modalProps.screen}
            fullScreen={modalProps.screen.mobile}
            className="postModalBody"
            onRequestClose={modalProps.onRequestClose}
            color="indigo"
            style={{
                maxWidth: 500,
                backgroundColor: modalProps.isDragon ? Colors.dragon : Colors.indigo,
                maxHeight: modalHeight,
            }}
            title={
                <Div className="modalTitleInner" style={{fontSize: 22}}>
                    <Div>{handleTitle(Utils.get(modalProps, "title", ""))}</Div>
                </Div>
            }
        >
            <Div
                style={{
                    height: "100%",
                    width:"100%",
                    borderRadius:12,
                    display: "flex",
                    flexDirection: "column",
                    overflow: 'auto',
                    justifyContent: 'flex-start',
                    gap: 10
                }}
            >
                {/* <Div className="PaymentsV2Subtitle" style={{fontSize: 18}}>
                    {Utils.get(modalProps, "subtitle")}
                </Div> */}
                {/* initial screen: show payment element to enter card */}
                { stripeProps.showButtons && paymentIntent && !showPaymentMethods ?
                    <>
                        <StripePayment
                            metadata={metadata}
                            style={{width:300}}
                            stripeMode="prod"
                            stripeInfo={paymentIntent}
                            secondPaymentIntent={secondPaymentIntent}
                            stripeGtmDataHandler={stripeProps.stripeGtmDataHandler || stripeGtmDataHandler}
                            version="V4"
                            hideCountry={true}
                            paymentType={Utils.get(stripeProps, 'paymentType')}
                            paymentSuccess={stripeProps.paymentSuccess}
                            roomId={stripeProps.roomId || null}
                            onRequestClose={stripeProps.onRequestClose}
                        />
                        {messageObject.stripe && (
                            <Div style={{
                                color: Colors.redError,
                                margin: '10px 0'
                            }}>{messageObject.stripe}</Div>
                        )}
                    </>
                    :
                    null
                }
                {/* TODO: move to component */}
                {/* payment methods icons at the bottom for UX */}
                {!showPaymentMethods && (
                    <Div style={{
                            background: '#332746',
                            padding: '20px 10px',
                            borderRadius: 16,
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowPaymentMethods(true)}
                        >
                        <Div style={{
                            display: 'flex',
                            gap: 5
                        }}>
                            <Image
                                className="afterpartyLogoElement"
                                src={venmo}
                                style={{width: 50}}
                            />
                            <Image
                                className="afterpartyLogoElement"
                                src={paypalIcon}
                                style={{width: 50}}
                            />
                            <Image
                                className="afterpartyLogoElement"
                                src={paylater}
                                style={{width: 50}}
                            />
                            {/* uncomment to see cashapp: removed by Product request */}
                            {/* <Image
                                className="afterpartyLogoElement"
                                src={cashapp}
                                style={{width: 50}}
                            /> */}
                        </Div>
                    </Div>
                )}
                {!paymentIntent && messageObject.stripe && (
                    <Div style={{
                        color: Colors.redError,
                        margin: '10px 0'
                    }}>{messageObject.stripe}</Div>
                )}
                {/* payment methods and saved payment methods (TODO: likely move out) */}
                {showPaymentMethods && (
                    <Div className="paymentMethods">
                        {paymentMethods && paymentMethods.length ? (
                            <Div
                                className="paymentMethods_headers"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: 10
                                }}
                            >
                                <Div style={{
                                    fontWeight: 'normal'
                                }}>Saved Payment Methods</Div>
                                <Div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 5
                                }}>
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            const url = new URL(location);
                                            url.pathname = "settings"
                                            window.location.assign(url)
                                        }}
                                    >
                                        Manage Payments
                                    </Button>
                                    <Icon
                                        icon="arrow-right"
                                        size={18}
                                    />
                                </Div>
                                {/* TODO: confirm is still needed for ux or delete */}
                                {/* <Div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    border: '1px solid white',
                                    padding: '20px',
                                    borderRadius: '16px',
                                    margin: '5px 0',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                }}
                                    onClick={() => {
                                        handleLoading(true)
                                    }}
                                >
                                    <Image
                                        className="afterpartyLogoElement"
                                        src={cashapp}
                                        style={{width: "45px", height: "45px"}}
                                    />
                                    <Div style={{
                                        width: '100%',
                                        marginLeft: '20px',
                                        fontSize: '18px',
                                    }}>Cashapp</Div>
                                    <Icon
                                        icon="arrow-right"
                                        size={18}
                                    />
                                </Div> */}
                            </Div>
                            ) :
                            null
                        }
                        {/* show stripe payment methods */}
                        { stripeProps.showButtons && paymentIntent && paymentMethods.length ?
                            <Div
                                className="paymentMethods_list"
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                {paymentMethods && paymentMethods.map((v, i) => {
                                    // e.g. type = 'card'
                                    const { type, id } = v
                                    const { title, src, last4, nameOnCard } = getPaymentMethodIconSrc(type, v)
                                    const paymentMethodError = Utils.get(messageObject.stripePaymentMethod, id)

                                    const activePaymentMethod = selectedPaymentMethodId === id

                                    return (
                                        <Div key={`${i}-${type}-${id}`}>
                                            <Div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: 20,
                                                    borderRadius: 16,
                                                    margin: '10px 0',
                                                    cursor: 'pointer',
                                                    border: handleBorder(paymentMethodError, activePaymentMethod),
                                                    alignItems: 'center',
                                                }}
                                                onClick={() => {
                                                    // updates UI->UX and uses button to trigger purchase
                                                    setSelectedPaymentMethodId(id)
                                                    // clear error for better UX
                                                    const cloneMessageObject = {...messageObject}
                                                    cloneMessageObject.stripePaymentMethod = {}
                                                    setMessageObject(cloneMessageObject)

                                                    // reset purchase button
                                                    setDisableCompletePaymentButton(false)
                                                }}
                                            >
                                                <Image
                                                    className="paymentMethodImage"
                                                    src={src}
                                                    style={{width: 45, height: 45}}
                                                />
                                                <Div style={{
                                                        width: '100%',
                                                        marginLeft: 20,
                                                }}>
                                                    <Div style={{
                                                        fontSize: 18,
                                                    }}>{title}</Div>
                                                    {last4 && (
                                                        <Div>****{last4}</Div>
                                                    )}
                                                    {nameOnCard && (
                                                        <Div>{nameOnCard}</Div>
                                                    )}
                                                </Div>
                                                {activePaymentMethod ?
                                                    <Icon
                                                        icon="check-circle-o"
                                                        size={24}
                                                        // #C92D7A - light border
                                                        // #8C2056 - dark
                                                        style={{
                                                            color: "#8C2056"
                                                        }}
                                                    />
                                                    :
                                                    null
                                                }
                                            </Div>
                                            <Div style={{color: paymentMethodError ? Colors.redError : 'inherit'}}>{paymentMethodError}</Div>
                                            {paymentMethodError ?
                                                <Div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    flexWrap: 'nowrap',
                                                    width: '100%',
                                                    gap: 10
                                                }}>
                                                    <Button
                                                        type="outlined"
                                                        style={{ marginTop: 20, width: "100%", flex: 1 }}
                                                        onClick={() => {
                                                            const selectedPaymentMethod = paymentMethods.find(v => v.id === selectedPaymentMethodId)

                                                            handleDeletePaymentMethod({
                                                                paymentMethod: selectedPaymentMethod,
                                                                setIsLoading,
                                                                setMessageObject,
                                                                setPaypalRendered,
                                                                setDisableCompletePaymentButton,
                                                                setPaymentMethods,
                                                            })
                                                        }}
                                                    >
                                                        Delete Payment Method
                                                    </Button>
                                                    <Button
                                                        color="pinkGradient"
                                                        style={{ marginTop: 20, width: "100%", flex: 1 }}
                                                        onClick={() => {
                                                            const selectedPaymentMethod = paymentMethods.find(v => v.id === selectedPaymentMethodId)

                                                            handleSubmitWithPaymentMethod({
                                                                paymentMethod: selectedPaymentMethod,
                                                                paymentIntent,
                                                                setIsLoading,
                                                                setIsSuccess,
                                                                setMessageObject,
                                                                setPaypalRendered,
                                                                returnUrl,
                                                                stripeProps,
                                                                setDisableCompletePaymentButton,
                                                                metadata,
                                                            })
                                                        }}
                                                    >
                                                        Try Again
                                                    </Button>
                                                </Div>
                                            :
                                                null
                                            }
                                        </Div>
                                    )
                                })}
                                {!messageObject.stripe && paymentMethods.length && (
                                    <Button
                                        color="pinkGradient"
                                        disabled={disableCompletePaymentButton}
                                        style={{ marginTop: 20, width: "100%" }}
                                        onClick={() => {
                                            const selectedPaymentMethod = paymentMethods.find(v => v.id === selectedPaymentMethodId)

                                            handleSubmitWithPaymentMethod({
                                                paymentMethod: selectedPaymentMethod,
                                                paymentIntent,
                                                setIsLoading,
                                                setIsSuccess,
                                                setMessageObject,
                                                setPaypalRendered,
                                                returnUrl,
                                                stripeProps,
                                                setDisableCompletePaymentButton,
                                                metadata,
                                            })
                                        }}
                                    >
                                        Complete Payment – ${Utils.formatMoney(paymentIntent.computedAmount)}
                                    </Button>
                                )}
                                {messageObject.stripe && (
                                    <Div style={{
                                        color: Colors.redError,
                                        margin: '10px 0'
                                    }}>{messageObject.stripe}</Div>
                                )}
                            </Div>
                            :
                            null
                        }
                        {/* TODO: move to a component */}
                        <Div style={{marginBottom: 10, fontWeight: 'normal' }}>Add New Payment Method</Div>
                        <Div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            border: `1px solid ${Colors.indigoLighter}`,
                            padding: 20,
                            borderRadius: 16,
                            margin: '5px 0',
                            cursor: 'pointer',
                            alignItems: 'center',
                        }}
                            onClick={() => {
                                setShowPaymentMethods(false)
                                // ensures paypal buttons get re-rendered when back to payment methods again and only one
                                setPaypalRendered(false)
                            }}
                        >
                            <Image
                                className="afterpartyLogoElement"
                                src={newCard}
                                style={{width: 50}}
                            />
                            <Div style={{
                                width: '100%',
                                marginLeft: 20,
                                fontSize: 18,
                            }}>Add New Payment Method</Div>
                            <Icon
                                icon="arrow-right"
                                size={18}
                            />
                        </Div>
                        { paypalProps.showButtons ?
                            <>
                                <Div style={{marginTop: 20, marginBottom: 10, fontWeight: 'normal'}}>Other Payment Methods</Div>
                                <Div
                                    className="paypalContainer"
                                    id={`paypal-button-container-${paypalProps.cardId || 1}`}
                                >
                                </Div>
                                {messageObject.paypal && (
                                    <Div style={{
                                        color: Colors.redError,
                                        margin: '10px 0'
                                    }}>{messageObject.paypal}</Div>
                                )}
                            </>
                            :
                            null
                        }
                    </Div>
                )}
            </Div>
        </Modal>
    )
}

export default PaymentsV2;
