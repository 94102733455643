import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';

import Icon from './Icon';

import Div from '../components/Div';

export default class FlashMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayNone: false,
        };
    }

    componentDidMount() {
        const pr = this.props;

        if(pr.duration) {
            setTimeout(this.setDisplayNone.bind(this), pr.duration);
        }
    }

    setDisplayNone() {
        this.setState({
            isDisplayNone: true,
        }, () => {
            if(this.props.onFlashDisplayNone) {
                this.props.onFlashDisplayNone();
            }
        });
    }

    getColor() {
        const {
            green,
            greenDark,
            blue,
            blueDark,
            yellow,
            yellowDark,
            red,
            redDark,
        } = Colors;

        const defaultColors = {
            color: greenDark,
            backgroundColor: green,
            borderColor: greenDark,
        };

        switch(this.props.color) {
            case "green":
                return defaultColors;
            case "blue":
                return {
                    color: blueDark,
                    backgroundColor: blue,
                    borderColor: blueDark,
                }
            case "yellow":
                return {
                    color: yellowDark,
                    backgroundColor: yellow,
                    borderColor: yellowDark,
                }
            case "red":
                return {
                    color: redDark,
                    backgroundColor: red,
                    borderColor: redDark,
                }
            default:
                return defaultColors;
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles.flashMessageElement;

        let displayNone = st.isDisplayNone ? {display: "none"} : null;
        const messageTypes = {
            info: {color: "blue", icon:"info", },
            success: {color: "green", icon:"check", },
            fail: {color: "red", icon:"times", },
            warning: {color: "orange", icon:"exclamation", },
            danger: {color: "red", icon:"times", },
        }
        const messageType = this.props.type ? messageTypes[this.props.type] : messageTypes["info"];
        const color = messageType['color'];
        const iconName = messageType['icon'];

        return (
            <Div
                className="flashMessageContainer"
                style={{...this.styles.flashMessageContainer, ...pr.flashMessageContainerStyle}}
            >
                <Div
                    className="flashMessageElement"
                    style={{...sty, ...pr.style, ...displayNone}}
                >
                    <Div style={{...this.styles.leftBar,...{backgroundColor:color}}}>
                    </Div>
                    <Div style={this.styles.iconContainer}>
                        <Div className="iconContainerFlashMessage" style={{...this.styles.iconContainerFlashMessage,...{backgroundColor:color}}}>
                            <Icon
                                icon={iconName}
                                size={24}
                                style={this.styles.messageTypeIcon}
                            />
                        </Div>

                    </Div>
                    <Div>
                        <Div style={{fontWeight: 600, fontSize: 18}}>{pr.message}</Div>
                        <Div style={this.styles.subMessage}>{pr.subMessage}</Div>
                    </Div>
                    <Div style={{...this.styles.closeContainer, ...StylesPlatform.cursorPointer}}>
                        <Icon
                            icon="times"
                            size={32}
                            onClick={this.setDisplayNone.bind(this)}
                        />
                    </Div>
                </Div>
            </Div>
        );
    }

    styles = {
        flashMessageContainer: {
            position: "absolute",
            top: 0,
            width: "100%",
            padding: 20,
            filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
            zIndex: 5000,
        },
        flashMessageElement: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            borderRadius: 4,
            boxSizing: "border-box",
            background: "white",
        },
        leftBar: {
            width: 8,
            backgroundColor: "green",
            height: 100,
        },
        iconContainer: {
            padding: 20,
        },
        messageTypeIcon: {
            padding:3,
            color:"white",
            textAlign:"center",
        },
        iconContainerFlashMessage: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height:40,
            width:40,
            borderRadius: "50%",
        },
        closeContainer: {
            padding: 20,
            marginLeft: "auto",
        },
        subMessage: {
            color: "gray",
        },
    };
}
