import React from "react";

import { merge } from "lodash";
//import AgoraRTC from "agora-rtc-sdk";
import AgoraRTC from "agora-rtc-sdk-ng";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';

import "./agora.css";
import "../assets/fonts/css/icons.css";

window.muteMyStream = function(type, muteStream) {
    if(!window.agoraLocalStream) {
        return;
    }

    //console.log("window.agoraLocalStream ???", window.agoraLocalStream);
    //console.log("    window.agoraLocalStream ???", type, muteStream);

    if(type == "mutevideo") {
        try {
            window.agoraLocalStream["video"].setMuted(muteStream)
                .catch(err=>{
                    //console.log("Video mute", err);
                });
        } catch(e) {
            console.log("Video mute failed", e);
        }
    } else if(type == "muteaudio") {
        try {
            window.agoraLocalStream["audio"].setMuted(muteStream)
                .catch(err=>{
                    //console.log("Audio mute", err);
                });
        } catch(e) {
            console.log("Audio mute failed", e);
        }
    }
}

class AgoraVideoCall extends React.Component {
    constructor(props) {
        super(props);
        this.client = false;
        this.localStream = false;
        this.shareClient = false;
        this.shareStream = false;
        this.state = {
            displayMode: "pip",
            streamList: [],
            streamDict: {},
            readyState: false,
            stateSharing: false,
            defaultMute: false,
            mobile: true,
            hideCohosts: false,

            canStreamVideo: props.host.id == props.user.id ? true : false,
        };
        //ll._("CONSTRUCTOR", "red");
        this.insiderElements = {};
    }

    changeLocalStream(attendeeMode) {
        const pr = this.props;

        window.agoraLocalStream = this.localStream;

        if(pr.changeLocalStream) {
            pr.changeLocalStream(this.localStream, attendeeMode);
        }
    }

    changeClient(clientId) {
        window.agoraClient = this.client;
        if(this.props.changeClient) {
            this.props.changeClient(this.client, clientId);
        }
    }


    // NOTE: AgoraVideoCall component is NOT mounted until the RoomV3 gets a message to start conference
    UNSAFE_componentWillMount() {
        if(this.props.agoraActive === false) {
            ll._("Agora disabled with state setting agoraActive == false", "red");
        } else {
            ll._("Agora starting dev test call agoraActive == true", "green");
            this.startCall()
                .then(() => {
                    console.log("Started call");
                })
                .catch(console.error);
        }
    }

    componentDidMount() {
        const pr = this.props;
        ll._("componentDidMount::pr.agoraActive >>>", "red", pr.agoraActive);

        /*
        AgoraRTC.onAutoplayFailed = () => {
            if(true) {
                let btn = $("<button class='agoraButtonAutoAudio'>Click me to resume the audio playback</button>");
                $(".agoraButtonAutoAudio").click(() => {
                    console.log(">>>> 2");
                    $(".agoraButtonAutoAudio").remove();
                });
                $("body").append(btn);
            } else {
                let btn = document.createElement("button");
                btn.setAttribute("class", "agoraButtonAutoAudio");
                btn.innerText = "Click me to resume the audio playback";
                console.log(">>>> 1");
                btn.onClick = () => {
                    console.log(">>>> 2");
                    btn.remove();
                };
                $("body").append(btn);
            }
        }
        */

        if(pr.agoraActive === false) {
            this.setupAfterpartyDisplay();
        }

        window.startStopStreamWithoutMountingAgora = this.startStopStreamWithoutMountingAgora.bind(this);
        window.removeUserFromStreamDict = this.removeUserFromStreamDict.bind(this);

        window.selectVipForStream = function(vipId) {
            if(pr.selectVipForStream) {
                pr.selectVipForStream(vipId);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
    }

    componentDidUpdate() {
        if(!this.cdu) { // cdu == componentDidUpdate abbreviated
            this.cdu = 0;
        }
        this.cdu++;
        //ll._("componentDidUpdate: cdu / displayMode", "blue", this.cdu, this.props.displayMode);

        this.setupAfterpartyDisplay();
    }

    componentWillUnmount() {
        if(this.client) {
            try {
                this.client.unpublish(this.localStream['video'],
                () => {
                    console.log("ERROR: Client leave failed");
                }).catch(error => { });
                this.client.unpublish(this.localStream['audio'],
                    () => {
                    console.log("ERROR: Client leave failed");
                }

                    ).catch(error => { });
            } catch(e) {
                console.log("Unpublish error", e);
            }
        }
        if(this.localStream && this.localStream.close) {
            try {
                this.localStream.close();
            } catch(e) {
                console.log("localStream error", e);
            }
        }
        if (this.state.stateSharing) {
            try {
                this.shareClient && this.shareClient.unpublish(this.shareStream);
                this.shareStream && this.shareStream.close();
            } catch(e) {
                console.log("stateSharing error", e);
            }
        }
        if(this.client && this.client.leave) {
            this.client.leave( () => {
                    console.log("Client leave succeeded.");
                },
                () => {
                    console.log("ERROR: Client leave failed");
                }
            );
        }
    }

    removeUserFromStreamDict(clientId) {
        this.removeStream(clientId, null, true);

        // NOTE had to add because if i added a user, then removed, then added back
        // video would not stream, most likely async error, perhaps having to do with
        // .close() method in startStopStreamWithoutMountingAgora() or how the streamDict
        // is built back up after deletion

        // setTimeout(() => {
        //     this.removeStream(clientId, null, true);
        // }, 6000);
    }

    async startStopStreamWithoutMountingAgora(isStart, vipId) {
        ll._("startStopStreamWithoutMountingAgora isStart vipId >>>>", "gray", isStart, vipId);
        //return;
        if(isStart) {
            let availableCameras = await AgoraRTC.getCameras();
            this.setState({availableCameras: availableCameras});
            console.log("sswma AgoraRTC.getCameras()", availableCameras);
            let audioTrack, videoTrack;
            //console.log("Trying sel", await AgoraRTC.getSelectedVideo());
            for(var idx in availableCameras) {
                var cam = availableCameras[idx];
                var config = {cameraId:cam['deviceId']};
                try {
                    videoTrack = await AgoraRTC.createCameraVideoTrack(config);
                    console.log("Success! Videotrack created with cam "+idx+" id: "+cam['deviceId']+" label: "+cam['label'], config);
                    break;
                } catch (e) {
                    console.log("FAILED to create video track with cam "+idx+" id: "+cam['deviceId']+" label: "+cam['label'], config, e);
                }
            }
            try {
                audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            } catch (e) {
                console.error("Couldn't create audio track", e);
            }

            // this.setTileAspectRatio(this.clientUid, videoTrack, videoTrack._encoderConfig.width, videoTrack._encoderConfig.height);
            if(audioTrack && videoTrack) {
                this.client.publish([audioTrack, videoTrack])
                    .then((o) => {
                        ll._("this.client.publish >>>>", "green");
                        this.addStream4(this.clientUid, 'video', videoTrack, audioTrack);
                        this.addStream4(this.clientUid, 'audio', videoTrack, audioTrack);
                        this.localStream = {video: videoTrack, audio: audioTrack};
                        this.changeLocalStream(this.localStream);
                    })
                    .catch( (err) => {
                        ll._("ERROR Publishing local stream: >>> " + err, "red");
                    });
            } else {
                const camTitleItems = Utils.pluck(availableCameras, "label");
                const camTitles = camTitleItems.join('\n    ');
                if(!audioTrack && !videoTrack) {
                    alert("Can't obtain a video and audio source to publish to stream.\nMake camera available and then refresh this page.\nInaccessible cameras:\n    "+camTitles);
                } else if(!videoTrack) {
                    alert("Can't obtain a video source to publish to stream.\nMake camera available and then refresh this page.\n\nInaccessible cameras:\n    "+camTitles);
                } else if(!audioTrack) {
                    alert("Can't obtain an audio source to publish to stream. Make available and then refresh this page.");
                }
                this.client.unpublish().then((o) => {
                        console.log("this.client.unpublish after start FAIL >>>");
                })
                 .catch( (err) => {
                        ll._("ERROR2 Un-Publishing local stream: >>>" + err, "red");
                    });
            }
        } else {
            // this.removeStream(this.clientUid);
            // this.handleExit();
            // this.changeLocalStream({});

            console.log("Trying to remove stream this.localStream >>>", this.localStream);
            if(this.localStream) {
                this.localStream.audio.close();
                this.localStream.video.close();

                // this.client.leave();
                // this.client.unpublish();

                if(!vipId) {
                    return;
                }

                this.client.unpublish()
                    .then((o) => {
                        console.log("UNPUB:: this.client.unpublish >>>");

                        // NOTE removing audio and video
                        this.removeStream(this.clientUid, null, true);

                        var vipSideElement = this.getElementByDataId(vipId);
                        console.log("UNPUB:: vipSideElement >>>>", vipSideElement);
                        var videoToRemove = vipSideElement.childNodes[1];
                        console.log("UNPUB:: videoToRemove >>>>", videoToRemove);
                        if(videoToRemove) {
                            // videoToRemove.parentNode.removeChild(videoToRemove);
                            videoToRemove.remove();
                        }
                    })
                    .catch( (err) => {
                        ll._("UNPUB:: ERROR Un-Publishing local stream: >>>" + err, "red");
                    });
            }
        }
    }

    async startCall() {
        const pr = this.props;
        const st = this.state;

        const token = null;


        ll._("startCall::Creating client", "yellow");
        this.client = AgoraRTC.createClient();

        console.log("Starting call...", this.client);

        let customerId, clientUid;
        if(pr.customerId) {
            customerId = pr.customerId;
            clientUid = Utils.generateClientUid(customerId);
            ll._("USING Customer ID: "+customerId+" ClientUid:"+clientUid, "green");
        }
        this.changeClient(clientUid);
        ll._("AgoraRTC client initialized. Joining channel... >>>", "green", clientUid, pr.channel);
        this.client.join(pr.appId, pr.channel, token, clientUid)
            .then( async (uid) => {
                console.log("clientUid: " + clientUid + " with returned uid: "+uid+" joined channel successfully At >>>" + new Date().toLocaleTimeString());
                ll._("Subscribing to events...", "coral");
                this.bindStreamEvents();
                // TODO: Why not setState?
                this.state.uid = uid;
                this.clientUid = uid;

                // Send localStream to parent component
                //this.changeLocalStream(pr.attendeeMode);

                // Activate video/audio feed if user is host/cohost/vip
                if((pr.attendeeMode != "audience" && pr.clickedStartLiveStream && st.canStreamVideo) || uid == 83610015) {
                    // NOTE this should only be called on accepted signal
                    console.log("startCall() Publishing stream...");
                    this.startStopStreamWithoutMountingAgora(true);
                }
                this.setState({ readyState: true });
            });
    }

    getAvatarPhoto(customer, tileType) {
        const pr = this.props;

        let photo = "/images/afterparty_logo_big_landscape.jpg";

        if(tileType == "host" && Utils.get(pr, "roomBackgroundImageUrl")) {
            photo = pr.roomBackgroundImageUrl;
        } else {
            if(customer.photo) {
                photo = customer.photo;
            } else if(pr.users && pr.users[customer.id]) {
                photo = Utils.get(pr, `users.${customer.id}.photo`)
            }
        }

        return photo;
    }

    getUsername(customer) {
        const pr = this.props;

        let username = customer['id'];
        if(customer['username']) {
            username = customer['username'];
        } else if(pr.users && pr.users[customer['id']]) {
            username = pr.users[customer['id']]['username'];
        }
        return username;
    }

    setElementOrientation(element, orientation) {
        if(orientation == undefined) {
            return;
        }

        if(orientation == "landscape") {
            $(".agoraVideoRatio").removeClass("portraitHostView").addClass("landscapeHostView");
        } else {
            $(".agoraVideoRatio").removeClass("landscapeHostView").addClass("portraitHostView");
        }
    }

    setupOrientation(currentVideoTrack) {
        currentVideoTrack.on('first-frame-decoded', () => {
            const frameInfo = currentVideoTrack.getCurrentFrameData()
            ll._("        Remote Video track info ", "blue", frameInfo, frameInfo.width, frameInfo.height);
            const insiderTypeForOrientation = insiderItem["type"];
            const width = frameInfo.width;
            const height = frameInfo.height;

            const orientationInsider = width >= height ? "landscape" : "portrait";

            if(insiderTypeForOrientation == "host") {
                // this.setTileAspectRatio(streamIdx, currentVideoTrack, width, height);
            } else if(insiderTypeForOrientation == "cohost") {
                // element.style.background = "none";

                // if(orientationInsider === "landscape") {
                //     element.style.width = "150px";
                // } else {
                //     element.style.width = "115px";
                // }
            } else if(insiderTypeForOrientation == "vip") {
                // element.style.background = "none";

                if(orientationInsider === "landscape") {
                    // element.style.height = "150px";
                    // element.style.marginTop = "auto";
                    // element.style.marginBottom = "auto";
                    if(pr.screen.mobile) {
                        // element.style.width = "136px";
                    } else {
                        // element.style.width = "200px";
                    }
                } else {
                    // element.style.marginRight = "auto";
                    // element.style.marginLeft = "auto";
                    // element.style.height = "200px";

                    if(pr.screen.mobile) {
                        // element.style.width = "80px";
                    } else {
                        // element.style.width = "125px";
                    }
                }
            }
        });
    }

    setTileAspectRatio(uuid, videoTrack, width, height) {
        if(!videoTrack) {
            return false;
        }

        let [customerId, browserPrefix] = Utils.processClientUid(uuid);
        const itemSel = "ag-item-" + customerId;
        let element = document.querySelector("#"+itemSel);

        const orientation = width >= height ? "landscape" : "portrait";
        this.setElementOrientation(element, orientation);
    }

    addRemoveTile(insiderItem, tileType, num) {
        // tileType == host, cohost, vip
        // num == the element number as assigned from setupAfterpartyDisplay()
        // ll._("addRemoveTile::insiderItem CONNER, tileType, num", "purple", insiderItem.id, tileType, num)
        const pr = this.props;
        const st = this.state;

        const customerId = insiderItem['id']; // this is Chorus customer['id']
        let username = this.getUsername(insiderItem);
        const avatarPhoto = this.getAvatarPhoto(insiderItem, tileType);

        // const insiderOrientation = insiderItem["orientation"];

        let itemSel = "ag-item-" + customerId;
        let styleImage = 'background: url("'+avatarPhoto+'");background-size: cover;';
        let style = `position:relative;overflow:hidden;` + styleImage;
        // ll._("    itemSel CONNER", "yellow", itemSel)

        // let element = document.querySelector("#"+itemSel);
        let element = document.getElementById(itemSel);
        if(!element) {
            element = this.getElementByDataId(customerId);
        }
        if(st.hideCohosts) {
            ll._("    Hiding cohosts");
        }

        // If element isn't on the page, we need to create it
        if(!element) {
            // ll._("Creating new '"+tileType+"' element for item >>>", "blue", itemSel, this.insiderElements);
            ll._("    !element::Creating new '"+tileType+"' element for item >>>", "blue");
            // NOTE: this is basically a div, but agora docs used <section />; although we do not know why
            element = document.createElement("section");

            element.num = num;
            this.insiderElements[itemSel] = element;

            element.setAttribute("id", itemSel);
            element.setAttribute("data-id", itemSel);
            element.setAttribute("data-order", 20000); // NOTE: default goes to the bottom
            element.tileType = tileType;
            element.notPlayingVideo = true;

            let nameOverlay = document.createElement("div");
            // nameOverlay.innerText = username;

            if (tileType == "host") {
                element.setAttribute("class", "insiderAvatar hostAvatar");
                element.setAttribute("style", "overflow:hidden;flex:1;width:100%;"+style);
                // nameOverlay.setAttribute("class", "ag-name hostNameTag");
                if(!(pr.screen.mobile || pr.screen.mobileLandscape)) {
                    element.appendChild(nameOverlay);
                }
                this.containerHost.appendChild(element);
            } else if(tileType == "cohost" && !st.hideCohosts) {
                // nameOverlay.setAttribute("class", "ag-name coHostNameTag");
                if(!(pr.screen.mobile || pr.screen.mobileLandscape)) {
                    element.appendChild(nameOverlay);
                }

                element.setAttribute("class", "insiderAvatar cohostAvatar");
                element.setAttribute("style", style);

                this.containerCohostsInside.appendChild(element);
            } else if(tileType == "vip") {
                element.setAttribute("class", "insiderAvatar vipAvatar");
                element.setAttribute("onclick", `window.selectVipForStream(${customerId})`);


                // if i'm the VIP, then append a mute video button
                if(customerId == this.props.customerId) {
                    username += "<span onclick='window.muteMyStream(\"video\")'><span id='muteMyVideo' class='videoOn'></span></span>";
                    username += "<span onclick='window.muteMyStream(\"audio\")'><span id='muteMyAudio' class='audioOn'></span></span>";
                }

                // nameOverlay.innerHTML = username;
                // nameOverlay.setAttribute("class", "ag-name vipNameTag");
                let order = 20000; // NOTE: default goes to the bottom
                let vipStyle = "font-size:9px;position:relative; overflow:hidden; cursor: pointer;order:"+order+";";
                // let vipStyle = "font-size:9px;position:relative; overflow:hidden; cursor: pointer;";

                if(insiderItem["photo"]) {
                    vipStyle += 'background: url("'+insiderItem['photo']+'");background-size: cover;';
                } else {
                    vipStyle += 'background: url("/images/icons/afterparty_logo_256.jpg");background-size: cover;';
                }

                element.setAttribute("style", vipStyle);
                element.setAttribute("title", "@" + insiderItem['username']);
                // if(!(pr.screen.mobile || pr.screen.mobileLandscape)) {
                //     element.appendChild(nameOverlay);
                // }

                let handUpIcon = document.createElement("img");
                handUpIcon.setAttribute("style", "width: 22px;");
                // handUpIcon.setAttribute("src", "/images/icons/hand.png");
                handUpIcon.setAttribute("src", "https://chorusuploads.s3.us-west-1.amazonaws.com/upload_abed636bfef256a88f9805c75d60879f.png");
                handUpIcon.setAttribute("class", "handUpInVip");

                let handUpOverlay = document.createElement("div");
                handUpOverlay.setAttribute("style", "position: absolute; width: 44px; height: 44px; background-color: rgba(0, 0, 255, 0.55);display: none; justify-content: center; align-items: center;");
                handUpOverlay.setAttribute("id", `handup-container-${insiderItem.id}`);
                handUpOverlay.setAttribute("onclick", `window.selectVipForStream(${insiderItem.id})`);

                handUpOverlay.appendChild(handUpIcon);
                element.appendChild(handUpOverlay);

                // NOTE add the VIPs to the side container on the right
                if(this.containerVips) {
                    if(this.containerVips.childNodes.length == 0) {
                        this.containerVips.appendChild(element);
                    } else {
                        // if i'm the vip...
                        if(customerId == this.props.customerId) {
                            // ll._("Show user first if current user is VIP id: ", "coral", this.props.customerId);
                            // insert myself at the first position
                            this.containerVips.insertBefore(element, this.containerVips.childNodes[0]);
                        } else {
                            this.containerVips.appendChild(element);
                        }
                    }
                    ll._("    CHILDREN", "gray", this.containerVips.childNodes);
                }
            }

            // this.setElementOrientation(element, insiderOrientation); // TODO: do this so that it applies to all insiders
        } else {
            // NOTE no longer needed becaues tileTypes are currently not changing
            // this.insiderElements[itemSel] = element;
            // Move element if tileType changed, e.g. the host makes a cohost the host and moves themself to the cohost
            if(element.tileType != tileType) {
                // TODO: Move element using appendChild() -- see https://stackoverflow.com/questions/1279957/how-to-move-an-element-into-another-element
            }
        }

        // This indicates that the play() method hasn't been executed on item
        if(element.notPlayingVideo == true) {
            for(var streamClientUid in this.state.streamDict) {
                // NOTE: this allows the host to have 2 streams (multiple camera angles)
                let [streamCustomerId, browserPrefix] = Utils.processClientUid(streamClientUid);
                if(streamCustomerId == customerId) {
                    ll._("Starting PLAY for customerId: "+customerId+" clientUid: "+streamClientUid, "green", streamDict[streamClientUid][0]);
                    let success = false;
                    try {
                        if(streamDict[streamClientUid][0]) {
                            const currentVideoTrack = streamDict[streamClientUid][0];
                            // NOTE: ag-item- agora video stream is injected here, bind to DOM element
                            currentVideoTrack.play(itemSel);
                            // NOTE: Not need atm because all tiles will be square or portrait
                            //this.setupOrientation(currentVideoTrack);

                            success = true;
                            ll._("        success FIRST::element.notPlayingVideo", "sienna", element.notPlayingVideo);
                        }
                    } catch (error) {
                        alert("Your browser generated an error. Please try refreshing the page.");
                        ll._("PLAY ERROR", "red", error);
                    }
                  element.stream = streamDict[streamClientUid];
                  style = '';
                  if(success) {
                      element.notPlayingVideo = false;
                      ll._("        success SECOND::element.notPlayingVideo", "red", element.notPlayingVideo);
                  }
                  break;
                }
            }
        }
        //insiderItem.player.resize && item.player.resize();
    }

    setupAfterpartyDisplay() {
        const pr = this.props;
        const st = this.state;
        // Get element references from compiled components
        //ll._("Setting up afterparty display", "green");
        let canvas = document.querySelector("#ag-canvas");
        this.containerCohostsInside = document.querySelector("#cohosts");
        this.containerCohostsOutside = document.querySelector("#cohostsOutside");
        this.containerHost = document.querySelector("#hostContainer");
        const vipsSel = pr.version === "v2" ? "#tab-container-vips" : "#vipsContainer";
        this.containerVips = document.querySelector(vipsSel);

        // Force load stream data -- id will be empty without getId() call
        //let streamDict = Utils.deepCopy(st.streamDict);
        let streamDict = st.streamDict;
        //ll._("setupAfterpartyDisplay streamDict", "blue", streamDict);

        // Put on window for access by higher component
        window.streamDict = streamDict;

        // Setup insider list by adding host+cohosts+vips which has usernames, photos, etc.
        let insiderList = [];
        if(pr.host) {
            pr.host["type"] = "host";
            insiderList.push(pr.host);
        }
        let cohostCount = 0;
        if(pr.cohosts) {
            for(var idx in pr.cohosts) {
                pr.cohosts[idx]["type"] = "cohost";
                insiderList.push(pr.cohosts[idx]);
            }
            cohostCount = pr.cohosts.length;
        }
        let vipCount = 0;
        if(pr.customersVip) {
            for(var idx in pr.customersVip) {
                pr.customersVip[idx]["type"] = "vip";
                insiderList.push(pr.customersVip[idx]);
            }
            vipCount = pr.customersVip.length;
        }

        //ll._("streamDict / host / cohosts", "purple", streamDict, Utils.get(pr.host, "username"), cohostCount + " cohost", pr.cohosts);
        let no = insiderList.length - 1;
        //console.log("STREAMS", this.state.streamList);
        let style = ';background:none;';
        window.insiders = this.insiderElements; // this is an empty dictionary the first time around

        // Loop through each insider and create the necessary elements on the page to inject the Agora Video view
        insiderList.map((insiderItem, index) => {
            // Find the number of insiders on object -- the first is the host
            let num = 0;
            // NOTE write Utils.countObjectKeys() function
            for(var idx in this.insiderElements) {
                num++;
            }
            let tileType = "host"; // TODO i think this is unnecessary, look above at ["type"] code
            if(num > 0) {
                if(insiderItem['type'] == 'vip') {
                    tileType = "vip";
                } else {
                    tileType = "cohost";
                }
            }

            // const uuid = 26290016;
            // if(index === 0 && streamDict[uuid]) {
            //     insiderItem["orientation"] = streamDict[uuid][0]._videoWidth > streamDict[uuid][0]._videoHeight ? "landscape" : "portrait"; // TODO: figure out how to get videoHiehgt and width here
            // }

            this.addRemoveTile(insiderItem, tileType, num);
        });
    }

    getElementByDataId(id) {
        return document.querySelector(`[data-id="ag-item-${id}"]`);
    }

    // NOTE OLD CODE, MOST LIKELY DELETE
    // streamInit = (uid, attendeeMode, videoProfile, config) => {
    //   let defaultConfig = {
    //     streamID: uid,
    //     audio: true,
    //     video: true,
    //     screen: false,
    //   };
    //
    //     switch (attendeeMode) {
    //         case "audio-only":
    //             defaultConfig.video = false;
    //             break;
    //         case "audience":
    //             defaultConfig.video = false;
    //             defaultConfig.audio = false;
    //             break;
    //         default:
    //         case "video":
    //             break;
    //     }
    //
    //     let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    //     stream.setVideoProfile(videoProfile);
    //     return stream;
    // };

    bindStreamEvents = () => {
        let _self = this;

        // Agora4
        _self.client.on("user-published", async (remoteUser, mediaType) => {
          await _self.client.subscribe(remoteUser, mediaType).catch(error => { console.log("ERROR", error)});
          ll._("user-published! >>>>", 'green', remoteUser, mediaType, remoteUser.uid);
          if (mediaType === "video") {
            console.log("subscribe video success");
            //remoteUser.videoTrack.play("#ag-item-53");
            _self.addStream4(remoteUser.uid, mediaType, remoteUser.videoTrack, remoteUser.audioTrack);
          }
          if (mediaType === "audio") {
            console.log("subscribe audio success");
            _self.addStream4(remoteUser.uid, mediaType, remoteUser.videoTrack, remoteUser.audioTrack);
            // NOTE: remoteUser.uid is the global id
            remoteUser.audioTrack.play(); // NOTE ask dan
          }
        });

        _self.client.on("user-unpublished", (evt, mediaType) => {
            //let stream = evt.stream;
            ll._("user-unpublished: >>>>", "yellow", new Date().toLocaleTimeString(), mediaType, evt);
            const myobj = document.getElementById("player_"+evt.uid);
            //ll._("    myobj >>>>", "yellow", myobj)
            // TODO this seems to always be null
            if(myobj) {
                myobj.remove();
            }
            _self.removeStream(evt.uid, mediaType);
        });

        _self.client.on("user-left", function (evt) {
            let stream = evt.stream;
            // let clientId = parseInt((evt.uid+"").substr(-5));
            let clientId = parseInt((evt.uid+""));
            ll._("User Left event: >>> " + clientId, "chartreuse", new Date().toLocaleTimeString(), evt);
            //_self.removeStream(stream.getId());
            const itemSel = "#ag-item-" + clientId;
            let element = document.querySelector(itemSel);
            if(element) {
                let tempState = Utils.deepCopy(_self.state.streamDict);
                delete tempState[evt.uid];
                _self.setState({streamDict:tempState});
                ll._("Add notPlayingVideo back for id ???", clientId);
                element.notPlayingVideo = true;
                const myobj = document.getElementById("player_"+evt.uid);
                if(myobj) {
                    myobj.remove();
                }
            }
            _self.removeStream(evt.uid, null, true);
        });

        const eventList = [ "error", "exception",
            "channel-media-relay-event", "channel-media-relay-state", "user-joined", "volume-indicator", "live-streaming-warning",
            "live-streaming-error", "connection-state-change",
        ];
        eventList.map( (eventName) => {
            _self.client.on(eventName, function(evt, evt2) {
                    ll._(eventName, "blue", evt);
                    ll._(eventName, "blue", evt2);
            });
        });
    };

    // removeStream = (uid, mediaType) => {
    //     const st = this.state;
    //     console.log("removeStream uid mediaType )))))", uid, mediaType);
    //     console.log("removeStream streamDict )))))", Utils.deepCopy(st.streamDict));
    //     for(var idx in st.streamDict) {
    //         const stream = st.streamDict[idx];
    //         if(idx == uid) {
    //             //stream.close()
    //             // let clientId = parseInt((idx+"").substr(-5));
    //             let clientId = parseInt((idx+""));
    //             console.log("removeStream clientId )))))", clientId);
    //             // console.log("removeStream streamDict )))))", Utils.deepCopy(st.streamDict));
    //             // let element = document.getElementById("ag-item-" + clientId);
    //             if(mediaType == "video") {
    //                 let element = this.getElementByDataId(clientId);
    //                 if(element) {
    //                     element.notPlayingVideo = true;
    //                 }
    //                 let dataElement = this.getElementByDataId(0);
    //                 if(dataElement) {
    //                     dataElement.notPlayingVideo = true;
    //                 }
    //             }
    //
    //             // if (element && mediaType == "video") {
    //             //     console.log("element )))))", element);
    //             //     element.notPlayingVideo = true;
    //             //     let dataElement = this.getElementByDataId(0);
    //             //     console.log("dataElement )))))", dataElement);
    //             //     if(dataElement) {
    //             //         dataElement.notPlayingVideo = true;
    //             //     }
    //             // }
    //
    //             let tempState = st.streamDict;
    //             delete tempState[uid];
    //             this.setState({
    //                 streamDict: streamDict,
    //             });
    //             break;
    //         }
    //     }
    // };

    // clientUid = customerId + browserId so if the customer is logged in on 2 devices, they won't conflict

    addStream4(uuid, streamType, videoTrack, audioTrack, push = false) {
        ll._("addStream4 >>>>", "purple", uuid, streamType);
        let tempStreamDict = Utils.deepCopy(this.state.streamDict);
        if(!tempStreamDict[uuid]) {
            tempStreamDict[uuid] = [null, null];
        }
        if (streamType === "video") {
            tempStreamDict[uuid][0] = videoTrack;
        }
        if (streamType === "audio") {
            tempStreamDict[uuid][1] = audioTrack;
        }

        //console.log("streamDict >>>>", tempStreamDict);
        this.setState({streamDict: tempStreamDict});
    }

    removeStream = (uid, mediaType, removeFromStreamDict) => {
        ll._("UNPUB:: removeStream uid mediaType )))))", uid, mediaType);
        ll._("UNPUB:: removeStream streamDict )))))", Utils.deepCopy(this.state.streamDict));
        let tempStreamDict = Utils.deepCopy(this.state.streamDict);
        // let clientId = parseInt((idx+"").substr(-5));
        let [customerId, browserPrefix] = Utils.processClientUid(uid);
        ll._("UNPUB:: removeStream clientUid"+uid+" customerId:"+ customerId);
        // console.log("removeStream streamDict )))))", Utils.deepCopy(st.streamDict));
        // let element = document.getElementById("ag-item-" + clientId);

        if(!tempStreamDict[uid]) {
            ll._("UNPUB:: stream not found in streamDict");
            return;
            // tempStreamDict[clientId] = [null, null];
        }

        if(mediaType == "video" || removeFromStreamDict) {
            let element = this.getElementByDataId(customerId);
            if(element) {
                element.notPlayingVideo = true;
            } else {
                ll._("UNPUB:: element not found in streamDict");
            }
            let dataElement = this.getElementByDataId(0);
            if(dataElement) {
                dataElement.notPlayingVideo = true;
            }

            tempStreamDict[uid][0] = null;
        }

        if(mediaType == "audio" || removeFromStreamDict) {
            tempStreamDict[uid][1] = null;
        }

        if(removeFromStreamDict) {
            delete tempStreamDict[uid];
        }

        this.setState({
            streamDict: tempStreamDict,
        });
    };

    handleCamera = (e) => {
        e.currentTarget.classList.toggle("off");
        this.localStream.isVideoOn()
            ? this.localStream.disableVideo()
            : this.localStream.enableVideo();
    };

    handleMic = (e) => {
        e.currentTarget.classList.toggle("off");
        this.localStream['audio'].muted
            ? this.localStream.['audio'].setMuted(true)
            : this.localStream.['audio'].setMuted(false);
    };

    switchDisplay = (e) => {
        if (
            e.currentTarget.classList.contains("disabled") ||
            this.state.streamList.length <= 1
        ) {
            return;
        }
        if (this.state.displayMode === "pip") {
            this.setState({ displayMode: "tile" });
        } else if (this.state.displayMode === "tile") {
            this.setState({ displayMode: "pip" });
        } else if (this.state.displayMode === "share") {
            // do nothing or alert, tbd
        } else {
            console.error("Display Mode can only be tile/pip/share");
        }
    };

    hideRemote = (e) => {
        if (
            e.currentTarget.classList.contains("disabled") ||
            this.state.streamList.length <= 1
        ) {
            return;
        }
        let list;
        let id = this.state.streamList[this.state.streamList.length - 1].getId();
        list = Array.from(
            document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
        );
        list.map((item) => {
            if (item.style.display !== "none") {
                item.style.display = "none";
            } else {
                item.style.display = "block";
            }
        });
    };

    handleExit = (e) => {
        if (e && Utils.get(e, "currentTarget.classList").contains("disabled")) {
            return;
        }
        try {
            this.client && this.client.unpublish(this.localStream);
            this.localStream && this.localStream.close();
            if (this.state.stateSharing) {
                this.shareClient && this.shareClient.unpublish(this.shareStream);
                this.shareStream && this.shareStream.close();
            }
            this.client &&
                this.client.leave(
                    () => {
                        console.log("Client succeed to leave.");
                    },
                    () => {
                        console.log("Client failed to leave.");
                    }
                );
        } finally {
            this.setState({ readyState: false });
            this.client = null;
            this.localStream = null;
            // redirect to index
            window.location.hash = "";
        }
    };

    sharingScreen = (e) => {
        if (this.state.stateSharing) {
            this.shareClient && this.shareClient.unpublish(this.shareStream);
            this.shareStream && this.shareStream.close();
            this.state.stateSharing = false;
        } else {
            this.state.stateSharing = true;
            let $ = this.props;
            // init AgoraRTC local client
            this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
            this.shareClient.init($.appId, () => {
                const clientId = this.props.customerId ? this.props.customerId + 111100000 : $.uid;

                ll._("AgoraRTC SHARE client initialized","yellow", clientId);
                this.bindStreamEvents();
                this.shareClient.join($.appId, $.channel, clientId, (uid) => {
                    this.state.uid = uid;
                    console.log("User " + uid + " join channel successfully");
                    console.log("At " + new Date().toLocaleTimeString());
                    // create local stream
                    // It is not recommended to setState in function addStream
                    this.shareStream = this.streamInitSharing(
                        uid,
                        $.attendeeMode,
                        $.videoProfile
                    );
                    this.shareStream.init(
                        () => {
                            if ($.attendeeMode !== "audience") {
                                this.addStream(this.shareStream, true);
                                this.shareClient.publish(this.shareStream, (err) => {
                                    console.log("Publish local stream error: " + err);
                                });
                            }
                            this.setState({ readyState: true });
                        },
                        (err) => {
                            console.log("getUserMedia failed", err);
                            this.setState({ readyState: true });
                        }
                    );
                });
            });
        }
    };

    streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
        let defaultConfig = {
            streamID: uid,
            audio: true,
            video: false,
            screen: true,
        };

        switch (attendeeMode) {
            case "audio-only":
                defaultConfig.video = false;
                break;
            case "audience":
                defaultConfig.video = false;
                defaultConfig.audio = false;
                break;
            default:
            case "video":
                break;
        }

        let stream = AgoraRTC.createStream(merge(defaultConfig, config));
        stream.setVideoProfile(videoProfile);
        return stream;
    };

    selectTabRoomMobile(tabName) {
        const pr = this.props;
        if(pr.selectTabRoomMobile) {
            pr.selectTabRoomMobile(tabName);
        }
    }

    getAgoraRatioPaddingBottom() {
        let pr = this.props;

        // if(pr.screen.width <= 568) {
        //     return "185%";
        // } else if(pr.screen.width <= 812) {
        //     return "150%";
        //     return `calc(${pr.screen.height}px - ${pr.agoraActive ? 16 : 69}px)`;
        // } else if(false) {
        //     return "150%";
        //     return "100%";
        // } else {
        //     return "150%";
        //     return "60%";
        // }
    }
    reorderVips() {
        var els =$("section");

        alert("Reordered: "+els.length);
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        // console.log("this.localStream['audio'].muted >>>", this.localStream['audio'].muted);

        const style = {
            display: "grid",
            gridGap: "18px",
            alignItems: "center",
            justifyItems: "center",
            gridTemplateRows: "repeat(12, auto)",
            gridTemplateColumns: "repeat(24, auto)",
            height: 480,
        };
        const videoControlBtn =
            pr.attendeeMode === "video" ? (
                <span
                    onClick={this.handleCamera}
                    className="ag-btn videoControlBtn"
                    title="Enable/Disable Video"
                >
                    <i className="ag-icon ag-icon-camera"></i>
                    <i className="ag-icon ag-icon-camera-off"></i>
                </span>
            ) : (
                ""
            );

        const audioControlBtn =
            pr.attendeeMode !== "audience" ? (
                <span
                    onClick={this.handleMic}
                    className="ag-btn audioControlBtn"
                    title="Enable/Disable Audio"
                >
                    <i className="ag-icon ag-icon-mic"></i>
                    <i className="ag-icon ag-icon-mic-off"></i>
                </span>
            ) : (
                ""
            );

        const switchDisplayBtn = (
            <span
                onClick={this.switchDisplay}
                className={
                    st.streamList.length > 4
                        ? "ag-btn displayModeBtn disabled"
                        : "ag-btn displayModeBtn"
                }
                title="Switch Display Mode"
            >
                <i className="ag-icon ag-icon-switch-display"></i>
            </span>
        );
        const hideRemoteBtn = (
            <span
                className={
                    st.streamList.length > 4 || st.displayMode !== "pip"
                        ? "ag-btn disableRemoteBtn disabled"
                        : "ag-btn disableRemoteBtn"
                }
                onClick={this.hideRemote}
                title="Hide Remote Stream"
            >
                <i className="ag-icon ag-icon-remove-pip"></i>
            </span>
        );
        const exitBtn = (
            <span
                onClick={this.handleExit}
                className={
                    st.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"
                }
                title="Exit"
            >
                <i className="ag-icon ag-icon-leave"></i>
            </span>
        );

        const agoraVideoCall = StyleUtils.getMediaStyle('agoraVideoCall', sty.agoraVideoCall, sty, StyleUtils.getWidthType(pr.screen.width));
        let agoraVideoRatioOld = StyleUtils.getMediaStyle('agoraVideoRatio', sty.agoraVideoRatio, sty, StyleUtils.getWidthType(pr.screen.width));
        const vipsPanel = StyleUtils.getMediaStyle('vipsPanel', sty.vipsPanel, sty, StyleUtils.getWidthType(pr.screen.width));
        const vipsContainer = StyleUtils.getMediaStyle('vipsContainer', sty.vipsContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const subHeaderText = StyleUtils.getMediaStyle('subHeaderText', sty.subHeaderText, sty, StyleUtils.getWidthType(pr.screen.width));
        const cohostsOutside = StyleUtils.getMediaStyle('cohostsOutside', sty.cohostsOutside, sty, StyleUtils.getWidthType(pr.screen.width));
        const shareScreenButton = StyleUtils.getMediaStyle('shareScreenButton', sty.shareScreenButton, sty, StyleUtils.getWidthType(pr.screen.width));

        const mobile = pr.screen.mobile;

        let agoraVideoRatio = JSON.parse(JSON.stringify(agoraVideoRatioOld)); // NOTE needed this because a const was throwing an error
        // agoraVideoRatio.paddingBottom = this.getAgoraRatioPaddingBottom();

        return (
            <div className="agoraVideoCallComponent" style={agoraVideoCall}>
                { /* <div onClick={this.reorderVips.bind(this)} style={{color: "black",background:"red",textAlign:"center",cursor:"pointer",}}>Reorder VIPs</div> */ }
                <div id="videoDisplayContainer" className="agoraVideoRatio" style={agoraVideoRatio}>
                    <div id="hostContainer" style={{position:"absolute",left:0,top:0,right:0,bottom:0, width:"100%",height:"100%", display:"flex"}}></div>
                    {true || pr.screen.width >= StyleUtils.iphoneWidth ?
                        <div id="cohostsContainerId" className="cohostsContainer" style={{display: "flex", position: "absolute", left:0, bottom:0,flexDirection:"column", width: "100%", height: "50%", display: "none"}}>
                            <div id="cohosts" data-id="ag-cohosts" style={{flex:1, display:"flex",zIndex:200,alignItems:"flex-end", height: "100%"}}></div>
                        </div>
                        :
                        null
                    }
                </div>
                {/* { pr.screen.width < StyleUtils.iphoneWidth && Utils.get(pr, "cohosts", []).length ?
                        <div id="cohostsOutside" className="cohostsOutside" style={cohostsOutside}></div>
                        :
                        null XXX
                } */}
                { /* false && pr.attendeeMode == 'video' && pr.agoraActive ?
                        <div className="shareScreenButton" style={shareScreenButton} onClick={this.sharingScreen} >
                                Share Screen
                        </div>
                        :
                        null
                */ }
                {/* {pr.customersVip && pr.customersVip.length > 0 ?
                        <div className="vipsPanel" style={vipsPanel}>
                            <div className="subHeaderText" style={subHeaderText}>VIPs</div>
                            <div id="vipsContainer" className="vipsContainer" style={vipsContainer}></div>
                        </div>
                        :
                        null
                } */}
                { true ?
                            <div id="ag-canvas" style={{display:"none"}}>
                                    <div className="ag-btn-group">
                                    {false ?
                                                <div>
                                                        {exitBtn}
                                                        {videoControlBtn}
                                                        {audioControlBtn}
                                                        {switchDisplayBtn}
                                                        {hideRemoteBtn}
                                                </div>
                                            :
                                            null
                                    }

                                    </div>
                        </div>
                        :
                        null
                }
            </div>
        );
    }


    styles = {
        xxx: {
            color: "black", height: 200, width: 200, backgroundColor: "red"
        },
        xxx_sm: {
            color: "black", height: 200, width: 200, backgroundColor: "yellow"
        },
        xxx_xs: {
            color: "black", height: 200, width: 200, backgroundColor: "pink"
        },
        agoraVideoCall: {
            padding: 0,
            // maxHeight: "calc(100vh - 120px)",
        },
        agoraVideoCall_md: {
            padding: 0,
        },
        agoraVideoCall_sm: {
            padding: 0,
        },
        agoraVideoCall_xs: {
            padding: 0,
        },
        agoraVideoRatio: {
            position: "relative",
            width: "100%",
            height: 690,
            marginLeft: "auto",
            marginRight: "auto",
        },
        agoraVideoRatio_md: {
        },
        agoraVideoRatio_sm: {
        },
        agoraVideoRatio_xs: {
            height: "calc(100vh - 68px)",
            // height: this.props.screen.height - 120,
            // height: window.innerHeight,
            // height: "calc(100vh - env(safe-area-inset-bottom))",
        },
        vipsPanel: {
            backgroundColor: Colors.indigo,
            borderRadius: 10,
            padding: 20,
            marginTop: 10,
        },
        vipsPanel_lg: {
            padding: 20,
        },
        vipsPanel_md: {
            padding: 20,
        },
        vipsPanel_sm: {
            padding: 20,
        },
        vipsPanel_xs: {
            paddingTop: 10,
            paddingRight: 20,
            paddingBottom: 10,
            paddingLeft: 20,
        },
        vipsContainer: {
            display: "flex",
            flexWrap: "wrap",
            borderRadius: 10,
            minHeight: 100,
        },
        subHeaderText: {
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 15,
        },
        subHeaderText_md: {
            marginBottom: 10,
        },
        subHeaderText_sm: {
            marginBottom: 10,
        },
        subHeaderText_xs: {
            fontSize: 18,
            marginBottom: 5,
        },
        cohostsOutside: {
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: Colors.purple,
            borderRadius: 10,
            minHeight: 100,
            padding: 5,
            marginTop: 10,
        },
        shareScreenButton: {
            width: 200,
            textAlign: "center",
            backgroundColor: Colors.purpleLight3,
            color: "black",
            borderRadius: 20,
            cursor: "pointer",
            padding: 10,
            marginTop: 10,
            marginLeft: "auto",
            marginRight: "auto",
        },
        shareScreenButton_xs: {}
    }
}

export default AgoraVideoCall;
