import React from 'react';
import * as Cookies from "js-cookie";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';

import Div from './Div';

import followUser from '../assets/icons/follow-user.svg';
import coinsIcon from '../assets/icons/coins.svg';

export default class AfterpartyRoomBadge extends React.Component {
    renderRoomNotification(badgeNotification) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE: These are both LISTINGS -- not the notification signal
        if(Utils.get(pr.showRoomNotification, "id", false) != pr.badgeNotification.id) {
            return null;
        }
        //ll._("pr.showRoomNotification is a LISTING", "blue", pr.showRoomNotification);
        //ll._("pr.badgeNotification ", "yellow", pr.badgeNotification);

        return (
            <Div
                className="roomNotification"
                style={sty.roomNotification}
                onClick={pr.handleClickRoomNotification.bind(this, pr.badgeNotification)}
            >
                <Div className="absoluteArrowUp" style={sty.absoluteArrowUp}></Div>
                <Div
                    className="notificationCloseButton"
                    style={sty.notificationCloseButton}
                    onClick={pr.handleClickRoomNotification.bind(this)}
                >
                    ×
                </Div>
                <Image src={badgeNotification.image_url} style={{width: 50, height: 50, marginRight: 10, borderRadius: 8}} />
                { Utils.get(pr.currentRoomSignal, 'extra_str', '').length > 1 ?
                    <Div>{Utils.get(pr.currentRoomSignal, 'extra_str')}</Div>
                    :
                    <Div>Tap here to buy the new {badgeNotification.title}</Div>
                }
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        return null;

        return (
            <Div
                className="roomNotificationContainer"
                style={{...sty.roomNotificationContainer, ...StylesPlatform.cursorPointer}}
                onClick={pr.handleBuyClick.bind(this, 1)}
            >
                {/* <Icon
                    className="roomNotificationBadge"
                    icon="certificate"
                    size={27}
                    color="white"
                    style={{marginLeft: 10}}
                /> */}
                <Image
                    className={`roomNotificationBadge-${pr.badgeNotification.id}`}
                    color="white"
                    style={{marginLeft: 10, width: 23}}
                    src={coinsIcon}
                />
                {/* <Div className="notificationBadgeText" style={sty.notificationBadgeText}>
                    +{pr.badgeNotification.id}
                </Div> */}
                {this.renderRoomNotification(pr.badgeNotification)}
            </Div>
        );
    }

    styles = {
        roomItems: {
            display: "flex",
            marginRight: 10,
            alignItems: "center",
        },
        roomNotificationContainer: {
            position: "relative",
        },
        roomNotification: {
            position: "absolute",
            alignItems: "center",
            display: "flex",
            top: 43,
            right: -44,
            minWidth: 200,
            padding: 10,
            backgroundColor: "white",
            borderRadius: 8,
            zIndex: 1000,
            color: Colors.purple,
            fontSize: 12,
        },
        absoluteArrowUp: {
            position: "absolute",
            top: -14,
            right: 41,
            width: 0,
            height: 0,
            borderLeft: "15px solid transparent",
            borderRight: "15px solid transparent",
            borderBottom: "15px solid white",
        },
        notificationCloseButton: {
            position: "absolute",
            top: -4,
            right: 4,
            fontSize: 20,
            color: "#aaa",
        },
        notificationBadgeText: {
            position: "absolute",
            top: 7,
            left: 13,
            fontSize: 9,
            color: Colors.magenta,
        },
    };
}
