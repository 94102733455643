import React, { Fragment } from 'react';
import moment from "moment";
import DeviceDetector from "device-detector-js";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Crypto from '../modules/Crypto';
import Validate from '../modules/Validate';
import Analytics from '../modules/Analytics';
import StylesPlatform from '../modules/StylesPlatform';
import Constant from '../modules/Constant';

// Elements
import Div from './Div';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Span from '../elements/Span';
import H1 from '../elements/H1';
import WebappLoginRegister from '../elements/WebappLoginRegister';
import Doc from '../components/Doc';
//import RegisterFlow from './RegisterFlow';
import DebugBox from '../elements/DebugBox';
import Dropdown from '../elements/Dropdown';
import MetaMask from '../elements/MetaMask';
import Modal from '../elements/Modal';
import Carousel from '../elements/Carousel';
import PanelQa from '../elements/PanelQa';
import Calendar from '../elements/Calendar';
import AfterpartyLogo from '../elements/AfterpartyLogo';

import StripePayment, { StripeV1, StripeV1SingleButton } from '../elements/StripePayment';

// Components
//import ConnectWallet from './ConnectWallet';


import '../App.css';

export default class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            isCreditCard: false,
            isWire: false,
            stripeInfo: false,
            paypalCardId: 1,
        };
        this.getStripePaymentIntent();
        this.showPayPalButtons();
    }

    stripePaymentCallback(stripeResponse) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        console.log('stripePaymentCallback>>>state', st, '\n stripeResponse:', stripeResponse)

        this.setState({showPayPalModal:false, showPayPalSuccessModal2:true, form1_show_modal: ""});
        const quantity = Utils.get(this.state, "param_ticket_quantity", 1)
        const pricePerTicket = 1;
        const artist_name = Utils.get(st, "field^artist_name") || "collectible_stripe";
        // TODO: onsale_event_id : ARTISTNAME, artist_name -- double check qty and value
        // event_type: 'afterparty_livestream' <-- paypal card default
        const gtmData = {'event':'ticket_purchase', 'purchase.user_email':Utils.get(pr, "user.email"), 'purchase.user_phone':Utils.get(pr, "user.primary_phone1"), 'onsale_event_id':'collectible_stripe', 'quantity':quantity, 'value':quantity*pricePerTicket, 'currency':'USD', 'artist':artist_name};
        //console.log("gtmData", gtmData);
        window.dataLayer.push(gtmData);

        /*
        this.setState({
            showPaymentModalNextButton: true,
        });
        */
    }

    getStripePaymentIntent(selectedItem) {
        const pr = this.props;
        const st = this.state;

        // Note: not sure why this reverts the credit card but causes a stripe payment
        // intent to not fire which does not update the amount
        // const itWillBeCreditCard = !st.isCreditCard;
        const itWillBeCreditCard = true
        const quantity = Utils.get(st, "param_ticket_quantity", 1);
        const qty = st.selectedQuantity ? st.selectedQuantity : quantity;
        const collectibleId = selectedItem ? selectedItem.id : null;
        if(!pr.amount) {
            ll._("No amount specified to Payments component. Aborting.", "red");
            return;
        }

        console.log('>>>>>>>>toggleCreditCardOption>st', st, '\n props', pr,
        '\n selectedItem', selectedItem,
        '\n st.selectedQuantity.value >>>', qty,
        '\n itWillBeCreditCard', itWillBeCreditCard
        )
        if(itWillBeCreditCard) {
            Api.getStripeIntent(qty, (o) => {
                console.log('getStripeIntent>o', o)
                if(o && o.success) {
                    o.data = Utils.get(o, "data", {});
                    o.data['paymentType'] = this.props.paymentType;
                    this.setState({
                        isCreditCard: itWillBeCreditCard,
                        isWire: false,
                        stripeInfo: o.data,
                    });
                } else {
                    console.log("ERRORS: ", o.errors);
                    if (o && o.errors.length > 0) {
                        this.setState({
                            isCreditCard: itWillBeCreditCard,
                            stripeError: "Apple Pay & Google Pay not available at this time. Try again soon.",
                        });
                    }
                }
            }, collectibleId, pr.amount, this.props.paymentType, pr.roomId);
        }
    }

    showPayPalButtons() {
        const pr = this.props;
        const st = this.state;

        const CREATE_PAYMENT_URL  = '/chorus/paypal_payment';
        const EXECUTE_PAYMENT_URL = '/chorus/paypal_execute/';
        const quantity = Utils.get(this.state, "param_ticket_quantity", 1);
        let pricePerTicket = this.state["param^price_per_ticket"];
        if(Utils.get(this.state, "instaitem.unitPrice")) {
            pricePerTicket = Utils.get(this.state, "instaitem.unitPrice");
        }
        const _self = this;
        let item = {initial_price:this.props.amount};
        let userSelections = {}; //this.getFormVariables(1);
        userSelections['pricePerTicket'] = Utils.get(item, 'initial_price', 100.00);
        userSelections['quantity'] = quantity;
        userSelections['uid'] = Utils.getRequest('uid', '');
        userSelections['igid'] = Utils.getRequest('igid', '');
        userSelections['collectible'] = Utils.get(item, 'id'); //Utils.getRequest('collectible', '');
        userSelections['paymentType'] = Utils.get(this.props, 'paymentType');
        userSelections['roomId'] = Utils.get(this.props, 'roomId');
        console.log("userSelections", userSelections);

        setTimeout(() => {
                  paypal.Buttons({
                        // Sets up the transaction when a payment button is clicked
                        createOrder: (data, actions) => {
                          return fetch(CREATE_PAYMENT_URL, {
                                method: 'post',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(userSelections)
                            }).then(function(res) {
                                return res.json();
                            }).then(function(data) {
                                return data.orderID;
                            })
                          }
                        ,
                        // Finalize the transaction after payer approval
                        onApprove: (data, actions) => {
                          return actions.order.capture().then(function(orderData) {
                            return fetch(EXECUTE_PAYMENT_URL + data.orderID, {
                                method: 'post',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(orderData)
                            }).then(function(res) {
                                return res.json();
                            }).then(function(details) {
                                console.log("details", details);
                                _self.setState({showPayPalModal:false, showPayPalSuccessModal2:true, form1_show_modal: ""});
                                // TODO: onsale_event_id : ARTISTNAME, artist_name -- double check qty and value
                                // event_type: 'afterparty_livestream' <-- paypal card default
                                const gtmData = {'event':'ticket_purchase', 'purchase.user_email':Utils.get(pr, "user.email"), 'purchase.user_phone':Utils.get(pr, "user.primary_phone1"), 'onsale_event_id':'gravy_sep1', 'quantity':quantity, 'value':quantity*pricePerTicket, 'currency':'USD', 'artist':'yung gravy'};
                                //console.log("gtmData", gtmData);
                                window.dataLayer.push(gtmData);
                                if(_self.props.completePayment) {
                                    console.log("PAYPAL complete", _self.props);
                                    return _self.props.completePayment();
                                } else {
                                    console.log("PAYPAL no complete", _self.props);
                                }
                            });
                      })} }).render('#paypal-button-container-'+this.state['paypalCardId']);
            }, 500)
    }


    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        if(!pr.amount) {
            return null;
        }
        const showPayPal = true;
        const cardId = 1;

        return (
            <Div className="paymentsElement">
                {st.isCreditCard && st.stripeInfo ?
                    <StripePayment
                        style={{width:300}}
                        stripeMode="test"
                        stripeInfo={st.stripeInfo}
                        stripePaymentCallback={this.stripePaymentCallback.bind(this)}
                        version="V3"
                        hideCountry={true}
                        paymentType={Utils.get(this.props, 'paymentType')}
                        paymentSuccess={this.props.completePayment}
                        roomId={this.props.roomId}
                        onRequestClose={this.props.onRequestClose}
                        /**
                         * Currently this implementation processes payment but fails to store
                         * due to missing collectable id.
                         * Refer to usage within paypal modal for full working example.
                         * This implementation will still require the following props
                         * so uncomment and pass a valid value.
                         */
                        // collectableId={undefined}
                        // selectedItem={undefined}
                    />
                    :
                    null
                }
                { showPayPal ?
                    <Div className="paypalContainer" id={`paypal-button-container-${cardId}`}>
                    </Div>
                    :
                    null
                }

            </Div>
        )
    }
}
