export default {
    pink: "#EF4CA4",
    // magenta: "#EF4CA4",
    purple: "#584FB9",
    purpleLight1: "#655DBF",
    purpleLight1Semi: "rgba(101, 93, 191, 0.6)",
    purpleLight2: "#847DCC",
    purpleLight2Semi: "rgba(132, 125, 204, 0.6)",
    purpleLight3: "#D6D6E3",
    purpleLight4: "#EBE9FF",
    purpleLight5: "#F9F9FF",
    purpleDark: "#313373",
    purpleLight: "#D6D6E3",
    purpleLighter: "#EBE9FF",
    purpleLightest: "#F9F9FF",

    purpleInputBackground: "#6961C0",

    purpleDarkBackground: "#584FB9",
    purpleBackground: "#655DBF",
    purpleLightBackground: "#847DCC",

    green: "#D4EDDA",
    greenDark: "#155724",
    blue: "#CCE5FF",
    blueDark: "#004085",
    yellow: "#FFF3CD",
    yellowError: "#ffe135",
    yellowDark: "#856404",
    redDark: "#721C24",
    redDark: "#721C24",

    white: "#FFF",
    black: "#000",
    colorIndex: [
        "darkgreen",
        "red",
        "green",
        "blue",
        "orange",
        "yellow",
        "purple",
        "brown",
        "black",
        "magenta",
    ],

    splashDarkPurple: "#1F0744",
    splashOrange: "#EE6E62",
    splashMagenta: "#C92D7A",
    splashPurple: "#4E1062",
    splashWhite: "white",
    splashRed: "#E04629",

    indigo: "#0F0420",
    eggplant: "#170F2B",
    indigoLight: "#271D37",
    indigoLighter: "#413850", // OLD "#322941"
    indigoLightest: "#F4EBFF",
    plum: "#4E1062",
    plumLight: "#69347A",
    lemon: "#FFB84D",

    magenta: "#C92D7A",
    dragon: "#64173D",
    yellow: "#FFC649",
    orange: "#FFB84F",
    peach: "#EE6E62",
    gray: "#BABABA",
    gray2: "#929292",
    gray3: "#706B78",
    red: "#E04629",
    redError: "#EE6E62",
    offBlack: "#0E0E0E",
    neonGreen: "#4BE06C",
    accentGreen: "#4BE06C",
    pinkGradient: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
    blueGradient: "linear-gradient(180deg, #4785FF 0%, #0649CD 100%)",
    // translucentWhite: "rgba(255,255,255,0.9)",
    translucentBorder: `1px solid rgba(255, 255, 255, 0.2)`,

    translucentIndigo: "rgba(15, 4, 32, 0.5)",
    translucentBlack: "rgba(0, 0, 0, 0)",

    aiBorder: `1px solid #413850`,
}
