
export default {
    silent: false,

    _() {
        if(this.silent) {
            return;
        }
        let myArray = [];
        // If Utils.activateLogger is enabled, send through clean message to logger
        if(!window.consolelog) {
            const colors = ["blue", "green", "orange", "red", "purple", "yellow", "gray", "indigo", "coral", 'chartreuse'];
            if(arguments.length >= 2 && colors.indexOf(arguments[1]) != -1) {
                let foreColor = "white";
                let backColor = arguments[1];
                if(backColor == 'yellow') {
                    foreColor = "black";
                } else if(backColor == 'coral') {
                    foreColor = backColor;
                    backColor = "white";
                }
                arguments[0] = "%c" + arguments[0];
                arguments[1] = "border-radius: 2px;padding: 2px 4px;color:"+foreColor+";background-color:" + backColor;
            }
        }

        for(var idx in arguments) {
            myArray.push(arguments[idx]);
        }
        console.log.apply(console, myArray);
    },

}
