import React, { Fragment } from 'react';

import moment, { max } from 'moment'

import Div from './Div';

export default class EventCard extends React.Component {

  handlePosterClick() {
      if(this.props.onClick) {
          return this.props.onClick(this.props.mediaItem);
      }
  }


  render() {
      const sty = this.styles;

      const listing = this.props.mediaItem;
      // console.log("SOINCARD", listing);
      const width = this.props.width ? this.props.width : 180;
      const height = this.props.height ? this.props.height : 180;
      const borderRadius = parseInt(width*.1);
      const captionHeight = parseInt(width*.4);
      const fontSize = parseInt(width*.06);
      const trackName = listing["title"]; //album["tracks"][0]["title"];
      const artistName = listing["artist_name"]; //album["artist"]["name"];
      const remainingPrints = listing["total_prints"] - listing["prints_sold"];
      //size = 180;
      let ts = moment();
      let formatDate = ts.format('h') + "h " + ts.format('m') + "m " + ts.format('s') + "s";
      let timeRemaing = "2h 35m";

      var a = moment([2021, 2, 6]);
      var b = moment([2021, 2, 4]);
      var hours = a.diff(b, 'hours');
      var minutes = a.diff(b, 'minutes');
      var seconds = a.diff(b, 'seconds');
      var sec = seconds % 60;
      window.sec = seconds;
      const hideRemaining = this.props.hideRemaining;


        /*
        (function timerLoop() {
          $(".difference > span").text(moment().to(then));
          $(".countdown").text(countdown(then).toString());
          requestAnimationFrame(timerLoop);
        })();
        */

      formatDate = a.diff(b, 'hours') + "h " + a.diff(b, 'minutes') + "m " + sec + "s "; // 1
      formatDate = listing["remaining"];

      return <Div
        key={`event-card-${listing['id']}`}
        onClick={this.handlePosterClick.bind(this, listing)}
        style={{position:"relative",marginTop:6,textAlign:"left", marginRight:20}}>
            {listing["listing_status"] == 2 ?
                <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>{formatDate}</Div>
            :
                null
            }
                <Div
                    className="eventImage"
                    style={{
                        width: width,
                        height: height,
                        borderRadius: borderRadius,
                        overflow: "hidden",
                        filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
                        backgroundImage: `url(${listing["image_url"]})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                ></Div>
                <Div style={{whiteSpace: "nowrap", textAlign:"left",marginTop:19}}>
                    <Div style={{fontSize:14,fontWeight:"bold"}}>
                        {trackName}
                    </Div>
                    <Div style={{fontSize:12,color:"gray",marginTop:4}}>
                        {artistName}
                    </Div>
                    {listing["listing_status"] == 2 && !hideRemaining ?
                        <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>
                            {remainingPrints} of {listing["total_prints"]} left
                        </Div>
                        :
                        null
                    }
                </Div>
        </Div>;
  }

  styles = {
      container: {

      }
  }
}
