import React, { Fragment } from 'react';

import axios from 'axios';

import ConnectWallet from './ConnectWallet'
import Header from './Header';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';

import Input from '../elements/Input';
import Modal from '../elements/Modal';
import ErrorBox from '../elements/ErrorBox';
import Button from '../elements/Button';

import Div from './Div';
import Register from './Register';
import ModalRegister from './ModalRegister';


export default class DashboardSoinItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            password: '',
            errorMessages: [],
        };
    }

    handleLoginClick() {
        const pr = this.props;
        const st = this.state;

        this.setState({errorMessages: []});
        const data = {
            email: st.email,
            password: st.password,
            format: "json",
        };
        if(!data['email'] || data['email'].length == 0) {
            this.setState({errorMessages:[{id:9023842034, message:"Email required"}]});
            return;
        }
        Api.postLogin(data, (response) => {
            if(response && response.success) {
                this.props.history.push('/afterparty');
                window.location.reload();
            } else {
                if(response) {
                    this.setState({errorMessages: response.errors});
                }
            }
        });
    }

    changeEmail(e) {
        this.setState({email: e.target.value});
    }

    changePassword(e) {
        this.setState({password: e.target.value});
    }

    keyDownLogin(e) {
        if(e.key === 'Enter') {
            this.handleLoginClick();
            return;
        }
    }

    goToRegister() {
        const pr = this.props;

        pr.history.push("/register");
        pr.change("register");
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="loginComponent"
                style={{...sty.container, ...pr.style}}
            >
                <Header />
                <Div style={Styles.purpleHeadline}>
                    Sign In
                </Div>

                <Div
                    className="signInSubtitle"
                    style={sty.signInSubtitle}
                >
                    Don't have an account? <Button type="text" style={{marginLeft: 5}} onClick={this.goToRegister.bind(this)}>Register an account</Button>
                </Div>

                {st.errorMessages && st.errorMessages.length > 0 ?
                    <ErrorBox
                        errorMessages={st.errorMessages}
                    />
                    :
                    null
                }
                <Div style={{justifyContent: "center"}}>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.changeEmail.bind(this)}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onKeyDown={this.keyDownLogin.bind(this)}
                        onChange={this.changePassword.bind(this)}
                    />

                    <Button
                        style={{width: "fit-content", margin: "0 auto"}}
                        onClick={this.handleLoginClick.bind(this)}
                    >
                        Sign In
                    </Button>
                </Div>
            </Div>
        );
    }

    styles = {
        container: {
            maxWidth: 400,
            width: "100%",
            padding: 5,
            textAlign: "left",
            paddingBottom: 150,
            cursor: "default",
        },
        signInSubtitle: {
            display: "flex",
            justifyContent: "center",
            color: Colors.pink,
            marginTop: -10,
            marginBottom: 20,
        },
    };
}
