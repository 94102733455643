import React from 'react';
import * as Cookies from "js-cookie";
import moment from "moment";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Badge from '../elements/Badge';
import Input from '../elements/Input';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';

import Div from './Div';
import CustomerProfile from './CustomerProfile';
import Chatbox from './Chatbox';
import ModalNFT from './ModalNFT';
import Scroller from './Scroller';
import ProfileMenu from './ProfileMenu';
import Profile from './Profile';

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';
import followUser from '../assets/icons/follow-user.svg';

import commentIconWhite from '../assets/icons/comment-white.svg';
import nftIconWhite from '../assets/icons/nft-white.svg';
import starIconWhite from '../assets/icons/star-white.svg';
import usersIconWhite from '../assets/icons/users-white.svg';

import commentIconPurple from '../assets/icons/comment-purple.svg';
import nftIconPurple from '../assets/icons/nft-purple.svg';
import starIconPurple from '../assets/icons/star-purple.svg';
import usersIconPurple from '../assets/icons/users-purple.svg';

import AgoraVideoCall from "./AgoraVideoCall";
const AGORA_APP_ID = 'b0dc8c2769cd4f8389486454d1dfb958';

export default class AfterpartyRoomV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            wallet: null,
            mode: Utils.getRequest('host') == 1 ? 'host' : 'guest',
            hostPicture: true,
            guestsOnline: 0,
            sessionDuration: 0,
            onlineUsers: [],
            chatboxContent: "messages", //"messages", // "participants",
            showSionModal: false,
            currentSoin: {
                name: "",
                description: "",
                image_url: "",
                created_at: "",
            },
            currentSoinIndex: -1,

            videoIcons: {
                video: {
                    isShown: true,
                    isOn: false,
                },
                audio: {
                    isShown: true,
                    isOn: false,
                },
                tv: {
                    isShown: false,
                    isOn: false,
                },
                desktop: {
                    isShown: true,
                    isOn: true,
                },
                exit: {
                    isShown: true,
                },
                eventEnd: {
                    isShown: true , // NOTE not being used
                },
            },

            agoraActive: false, //Utils.getRequest('agora') == 1 ? true : true,
            agoraKey: 0,
            testMode: Utils.getRequest('test') == 1 ? true : false,
            showHelpQuestions: true,
            muteChecked: {},
            showProfileModal: false,

            mostRecentMessage: {
                body: "",
                created_at: "",
            },
            posting: false,
            post: "",
            expandMostRecentMessageInput: false,

            subscribeSuccess: false,
            muteVideo: {},
            muteAudio: {},

            selectedTabMobileRoom: "vips",
            isChatboxInputAbove: props.screen.mobile ? true : false,

            isHost: false,
            isCohost: false,
            isMod: false,

            vipsInitialized: false,
        };

        this.emojis = ["🎉", "😍", "❤️", "🔥", "👏", "😂"];

        //window.startConference = this.startConference;
        window.addVideoStream = this.addVideoStream;
        window.removeVideoStream = this.removeVideoStream;
        this.hostRef = React.createRef();
        const urlPath = Utils.get(document, "location.pathname", "");
        let channel = "mychannel";
        const parts =  urlPath.split("-");
          console.log("LISTING", parts);
          if(parts.length >= 2) {
              const id = parts[parts.length-1];
              channel = id;
          }

        //ll._("Joining channel: "+channel, "green")
        this.videoProfile = "480p_4";
        this.channel = channel;
        this.transcode = "interop";
        this.attendeeMode = "video";
        this.baseMode = "avc";
        this.appId = AGORA_APP_ID;
        if(window.location.host.indexOf('localhost') == -1) {
            if(window.location.protocol.indexOf('https') == -1) {
                if(Utils.getRequest('test') != 1) {
                    alert("Video chat won't work with an HTTP -- must be HTTPs");
                }
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        const customersVip = Utils.get(newProps.roomItem, "customers_vip", []);

        this.setState({
            isHost: Utils.get(newProps, "roomItem.insiders.isHost"),
            isCohost: Utils.get(newProps, "roomItem.insiders.isCohost"),
            isMod: Utils.get(newProps, "roomItem.insiders.isMod"),
        });

        let muteAudio = JSON.parse(JSON.stringify(st.muteAudio));

        if(!st.vipsInitialized && customersVip.length) {
            this.handleMuteClick(customersVip);
            this.setState({vipsInitialized: true});
        }
    }

    // Add video streams to the container.
    addVideoStream(elementId){
        // Creates a new div for every stream
        let streamDiv = document.createElement("div");
        // Assigns the elementId to the div.
        streamDiv.id = elementId;
        // Takes care of the lateral inversion
        streamDiv.style.transform = "rotateY(180deg)";
        // Adds the div to the container.
        remoteContainer.appendChild(streamDiv);
    }

    // Remove the video stream from the container.
    removeVideoStream(elementId) {
        let remoteDiv = document.getElementById(elementId);
        if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
    }

    updateStats() {
         window.client.getSessionStats((o) => {
            this.setState({guestsOnline: o["UserCount"], sessionDuration: o["Duration"]});
        })
    }
    openProfileModal() {
        this.setState({showProfileModal: true});
    }

    closeProfileModal() {
        this.setState({showProfileModal: false});
    }

    handleStartAgora() {
        const pr = this.props;
        const st = this.state;

        const roomId = Utils.get(pr.roomItem, "id");
        const eventId = Utils.get(pr.roomItem, "event.id");
        const data = {
            event_type: 1, // Join
            room_id: roomId,
            event_id: eventId,
        }
        Api.postCustomerEvent(data, (o) => { console.log("Joined"); });
        this.setState({agoraActive: true, agoraKey: st.agoraKey+1});
    }

    handleChangeAgoraClient(newClient, clientId) {
        console.log("New client", newClient);
        this.agoraClient = newClient;
        this.setState({clientId:clientId});
    }
    handleChangeAgoraStream(newStream) {
        console.log("New stream", newStream);
        this.agoraStream = newStream;
        let tempState = {};
        let videoIcons = this.state.videoIcons;
        videoIcons.video.isOn = !newStream['video'].muted;
        videoIcons.audio.isOn = !newStream['audio'].muted;
        tempState['videoIcons'] = videoIcons;
        this.setState(tempState);
    }
    handleChangeAgoraEvent(eventType, eventInfo) {
        ll._("AGORA EVENT", "yellow", eventType, eventInfo);
    }

    handleCloseHelpQuestions() {
        this.setState({showHelpQuestions: false});
    }

    handleVideoClick(e) {
        let videoOn = this.state.videoIcons.video.isOn;
        if(this.agoraStream && this.agoraStream['video']) {
            if(!this.agoraStream['video'].muted) {
                this.agoraStream['video'].setMuted(true);
                videoOn = false;
            } else {
                this.agoraStream['video'].setMuted(false);
                videoOn = true;
            }
        } else {
            ll._("There is no Agora video stream", "red");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.video.isOn = videoOn;

        this.setState(videoIcons);
    }

    handleAudioClick(e) {
        let microphoneOn = this.state.videoIcons.audio.isOn;
        if(this.agoraStream && this.agoraStream['audio']) {
            if(!this.agoraStream['audio'].muted) {
                this.agoraStream['audio'].setMuted(true);
                microphoneOn = false;
            } else {
                this.agoraStream['audio'].setMuted(false);
                microphoneOn = true;
            }
        } else {
            ll._("There is no Agora audio stream", "red");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.audio.isOn = microphoneOn;

        this.setState(videoIcons);
    }
    handleShareScreenClick(e) {
        let shareScreen = this.state.videoIcons.tv.isOn;
        if(this.agoraStream) {
            if(shareScreen) {
                //this.agoraStream.disableAudio();
                shareScreen = false;
            } else {
                //this.agoraStream.enableAudio();
                shareScreen = true;
            }
        } else {
            console.log("There is no Agora stream >>>");
        }

        let videoIcons = this.state.videoIcons;
        videoIcons.tv.isOn = shareScreen;

        this.setState(videoIcons);
    }

    handleExitClick(e) {
        if (e.currentTarget.classList.contains("disabled")) {
          return;
        }
        try {
            if(this.agoraClient && this.agoraStream['video']) {
                ll._("Unpublishing video stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['video']);
            }
            if(this.agoraClient && this.agoraStream['audio']) {
                ll._("Unpublishing audio stream", "orange");
                this.agoraClient.unpublish(this.agoraStream['audio']);
            }
            if(this.agoraClient) {
                window.agoraClient = agoraClient;
                this.agoraClient.leave();
            }
            // TODO: Enable when screen sharing is activated
            /*
            if (this.state.stateSharing) {
                this.shareClient && this.shareClient.unpublish(this.shareStream);
                this.shareStream && this.shareStream.close();
            }
            */
            if(this.agoraClient) {
                this.agoraClient.leave(
                    () => { alert("You have left the room."); },
                    () => { alert("Leave failed."); }
                );
            };
        } finally {
          this.setState({ readyState: false });
          this.client = null;
          this.localStream = null;
          // redirect to index
          window.location.reload();
        }
    }

    handleSignal(signal) {
        const pr = this.props;

        if(signal["cmd"] == "mute") {
            console.log("MUTE SIGNAL", signal);
            const itemSel = "#ag-item-" + signal['customer_id'];
            var ele = document.getElementById(itemSel);
            if(ele) {
                if(!ele.muted) {
                    window.clickStream(ele, true);
                }
            }
            let mutes = this.state.muteAudio;
            mutes[signal['customer_id']] = true;
            this.setState({muteAudio: mutes});

        } else if(signal["cmd"] == "unmute") {
            console.log("UNMUTE SIGNAL", signal);
            const itemSel = "#ag-item-" + signal['customer_id'];
            var ele = document.getElementById(itemSel);
            if(ele) {
                if(ele.muted) {
                    window.clickStream(ele, false);
                }
            }
            let mutes = this.state.muteAudio;
            mutes[signal['customer_id']] = false;
            this.setState({muteAudio: mutes});
        }

        if(signal["cmd"] == "badgenotify") {
            const signalId = signal['id'];
            var key = "s-" + signalId;

            if(!Utils.getCookie(key)) {
                Api.postAnalytics({signal_id: signalId, type: "display_notify"});

                let listing = null;
                let index = -1;

                for(let i = 0; i < pr.roomItem.listings.length; ++i) {
                    if(pr.roomItem.listings[i].id === signal.extra_id) {
                        listing = pr.roomItem.listings[i];
                        index = i;
                        break;
                    }
                }

                pr.handleShowRoomNotification(listing, signal);
                Utils.setCookie(key, 1);
            }
        }
    }

    selectTab(chatboxContent) {
        this.setState({chatboxContent: chatboxContent})
    }

    closeSoinModal() {
        this.setState({showSionModal: false});
    }

    backToEvents() {
        this.props.history.push("/home");
    }

    handleSubscribeClick(customer) {
        const st = this.state;
        const pr = this.props;

        const isCurrentlyFollowing = pr.user.following[customer.id];

        const data = {
            from_customer_id: pr.user.id,
            to_customer_id: customer.id,
            follow: !isCurrentlyFollowing ? true : false,
        };

        let user = JSON.parse(JSON.stringify(pr.user));
        user.following[`${data.to_customer_id}`] = data;
        pr.setUser(user);

        Api.postFollow(data, (response) => {
            if(response.errors.length) {
                console.log("ERROR", response.data.errors);
                return;
            }

            let user = JSON.parse(JSON.stringify(pr.user));
            user.following = response.data;

            pr.setUser(user);
        });
    }

    chooseTabIcon(isSelectedTab, iconName) {
        switch(iconName) {
            case "star":
                return isSelectedTab ? starIconWhite : starIconPurple
            case "comment":
                return isSelectedTab ? commentIconWhite : commentIconPurple
            case "nft":
                return isSelectedTab ? nftIconWhite : nftIconPurple
            case "users":
                return isSelectedTab ? usersIconWhite : usersIconPurple
        }
    }

  renderHostTile(type, imageUrl, title, size) {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      let tileStyle = {width:640,borderRadius:8,overflow:"hidden"};
      let nameBoxStyle = sty.nameBox;
      if(size) {
          tileStyle['width'] = size;
          tileStyle['height'] = size;
          if(size <= 200) {
              nameBoxStyle = sty.nameBoxSmall;
          }

      } else if(type=="sub") {
          tileStyle['width'] = 200;
          tileStyle['height'] = 200;
          nameBoxStyle = sty.nameBoxSmall;
      }

      const remoteContainer = StyleUtils.getMediaStyle('remoteContainer', sty.remoteContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

      return true || type=="host" ?
              <Div key={`host-tile-${title}`} id="remote-container" ref={this.hostRef} style={remoteContainer}>
                {st.hostPicture ?
                    <Div style={{width: "100%"}}>
                        {false ?
                            <Image style={tileStyle} src={imageUrl} />
                            :
                            this.renderVideobox()
                        }
                    </Div>
                    :
                    null
                }
            </Div>
            :
            <Div key={`host-tile-other-${title}`} style={{position:"relative", margin: 10}}>
                <Image style={tileStyle} src={imageUrl} />
                <Div className="nameBox" style={nameBoxStyle}>
                    {title}
                </Div>
            </Div>
        ;
    }

    renderCustomer(guest) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const isMicrophone = guest["speak"];

        return (
            <CustomerProfile
                key={`ap_guest_${guest['id']}`}
                name={guest.full_name}
                username={guest.username}
                // image_url={guest.image_url}
                image_url={guest.photo}
                style={{width: "50%"}}
                microphoneIcon={isMicrophone}
                questionIcon
                size={56}
                width={pr.screen.width}
            />
        )
    }

    renderCustomers() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const eventRowDateCol = StyleUtils.getMediaStyle('eventRowDateCol', sty.eventRowDateCol, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));

        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomer(customerVip)
                })}

                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomer(customer)
                })}
            </Div>
        )
    }

    chatAction(action, customerId) {
      console.log("Check >>>", action, customerId);
      const roomCrc = Utils.getUrlCrc();

      let modalData = {
          action: action, // NOTE either: "kick", "block", "mute", "refresh"
          roomCrc: roomCrc,
      };

      if(customerId) {
          modalData["customerId"] = customerId;
      }

      if(action == "mute") {
          let mutes = this.state.muteAudio;
          mutes[customerId] = mutes[customerId] ? false : true;
          this.setState({muteAudio: mutes});
          if(!mutes[customerId]) {
              ll._("Unmute", "green");
              modalData['action'] = "unmute";
          }
      }

      Api.putRoomChatSignal(roomCrc, modalData, (response) => {
          console.log("response", response);
      });
    }

    openSoinModal(listing, i) {
        console.log("openSoinModal", listing, i);
        this.setState({
            showSionModal: true,
            currentSoin: listing,
            currentSoinIndex: i,
        });
    }

    goToSignIn() {
        this.props.history.push('/login');
    }

    renderManageGuests(guests) {
        return guests.map((guest) => {
            return (
                <Div key={`ap_guest_${guest['id']}`} style={{position:"relative", display:"flex"}}>
                    <Div style={{margin:8,borderRadius:8,overflow:"hidden",backgroundImage: 'url('+guest["photo"]+')',backgroundSize: "cover",height: 48, width:48,color: "#f5f5f5"}}>
                    </Div>
                    <Div style={{flex: 3,fontSize:10,textAlign:"center",marginTop:20,color:"black"}}>
                        {guest["username"]}
                    </Div>
                </Div>
            );
        });
    }

    renderStats() {
        return (
            <Div>
                <Div>Guests: {this.props.roomItem && this.props.roomItem["guests"] ? this.props.roomItem["guests"].length : 0}</Div>
                <Div>Guests online: {this.state.guestsOnline}</Div>
            </Div>
        );
    }

    handleOnlineUsersChange(onlineUsers) {
        const customerDict = Utils.arrayToObject(this.props.roomItem["customers"], "id");
        let ou = [];
        for(var idx in onlineUsers) {
            //console.log("rec", onlineUsers, idx, idx in customerDict);
            if(idx in customerDict) {
                ou.push(customerDict[idx]);
            }
        }
        this.setState({onlineUsers: ou});
    }

    renderVideoIcons() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="videoIconsContainer" style={sty.videoIconsContainer}>
                {st.videoIcons.video.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        size={24}
                        icon={st.videoIcons.video.isOn ? "video" : "video-slash"}
                        onClick={this.handleVideoClick.bind(this)}
                    />
                    :
                    null
                }

                {st.videoIcons.audio.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        icon={st.videoIcons.audio.isOn ? "microphone" : "microphone-slash"}
                        onClick={this.handleAudioClick.bind(this)}
                    />
                    :
                    null
                }

                {st.videoIcons.tv.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Share Screen"
                        icon={st.videoIcons.tv.isOn ? "tv" : "times"}
                        onClick={this.handleShareScreenClick.bind(this)}
                    />
                    :
                    null
                }

                {st.videoIcons.desktop.isShown && false ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Switch Display"
                        icon={st.videoIcons.desktop.isOn ? "desktop" : "power-off"}
                    />
                    :
                    null
                }

                {st.videoIcons.exit.isShown ?
                    <Icon
                        style={sty.videoIconsIcon}
                        title="Leave"
                        icon="sign-out"
                        onClick={this.handleExitClick.bind(this)}
                    />
                    :
                    null
                }

                {pr.roomItem.insiders.host.id === pr.user.id ?
                    <Div style={sty.videoEndEventButton}>
                        <Icon title="End Event" icon="times" size={16} />
                    </Div>
                    :
                    null
                }
            </Div>
        )
    }

    renderHostControls() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const listings = pr.roomItem['listings'] ? pr.roomItem['listings'] : [];

        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
          <Div className="hostControls" style={null}>
              {this.renderHostTile("host", pr.roomItem["image_url"], pr.roomItem['insiders']['host']["username"], 200) }
              { false ?
                    this.renderVideoIcons()
                    :
                    null
              }
              {false ?
                  <Div>
                      <Div style={Styles.subhead}>Moderators</Div>
                      <Div style={{display:"flex"}}>
                        {pr.roomItem['insiders']['moderators'].map((mod) => {
                            return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
                        })}
                      </Div>
                  </Div>
                  :
                  null
              }
              <Div style={{...afterpartyRoomInfoContainer, ...{marginTop: 30}}}>
                  <Div
                    style={{...Styles.subhead, ...{marginBottom: 20}}}
                >
                    Admission Nfts
                </Div>
                  <Div style={sty.admissionSoins}>
                      <Scroller
                        items={this.renderSoinsInScroller()}
                        numSlots={6}
                        centerSlidePercentage={100}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        autoPlay={false}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        whiteArrows={true}
                        minHeight={120}
                        marginLeft={40}
                        scrollerStyle={{display: "flex", flexDirection: "row", width: "100%"}}
                    />
                  </Div>
              </Div>

              { false ?
                  <Div style={afterpartyRoomInfoContainer}>
                      <Div style={Styles.subhead}>Stats</Div>
                      {this.renderStats()}
                  </Div>
                  :
                  null
              }
          </Div>
      )
  }

    renderSoinsInScroller() {
      const listings = this.props.roomItem['listings'] ? this.props.roomItem['listings'] : [];
      return (
          listings.map((listing, i) => {
              return (
                  <Div
                      key={`${i}-${Math.random()}`}
                      style={{
                          // clipPath:"url(#bloated-circle-125)",
                          width: 125,
                          height: 125,
                          marginRight: 20,
                          flexShrink: 0,
                          backgroundImage: `url(${listing['image_url']})`,
                          cursor: "pointer",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 32,
                      }}
                      onClick={this.openSoinModal.bind(this, listing, i)}
                  >
                  </Div>
              )
          })
      )
  }

    renderChatboxTabs(showHelpTab) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const tabStyle = StyleUtils.getMediaStyle('tabStyle', sty.tabStyle, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="tabsContainer" style={sty.tabsContainer}>
                <Div
                    style={st.chatboxContent == "messages" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "messages")}
                >
                    Messages
                </Div>
                <Div
                    style={st.chatboxContent == "participants" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                    onClick={this.selectTab.bind(this, "participants")}
                >
                    Participants
                </Div>
                {showHelpTab ?
                    <Div
                        style={st.chatboxContent == "help" ? {...tabStyle, ...sty.tabSelected} : tabStyle}
                        onClick={this.selectTab.bind(this, "help")}
                    >
                        Help
                    </Div>
                    :
                    null
                }
            </Div>
       );
  }

    renderCustomerInHostMode(guest) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const isMicrophone = guest["speak"];

        const hostButtonStyles = {
            ...Styles.btn,
            ...Styles.btnTiny,
        };

        const guestId = guest['id'];

        return (
            <Div key={`customer-in-host-${guestId}`} style={{display: "flex", width: "100%", alignItems: "center"}}>
                <CustomerProfile
                    key={`ap_guest_${guestId}`}
                    name={guest.full_name}
                    username={guest.username}
                    image_url={guest.photo}
                    style={{marginRight: "auto"}}
                    microphoneIcon={isMicrophone}
                    size={56}
                    width={pr.screen.width}
                    questionIcon
                />
                <Div style={{display:"flex"}}>
                    <Checkbox
                        style={{marginRight: 8}}
                        checked={this.state.muteChecked[guestId]}
                        onClick={this.chatAction.bind(this, "mute", guestId)}
                        />
                        Mute
                </Div>
                { false ?
                    <Button
                        style={{marginRight: 8}}
                        onClick={this.chatAction.bind(this, "kick", guestId)}
                        color="white"
                        size="small"
                    >
                        Kick
                    </Button>
                    :
                    null
                }
                { false ?
                    <Button
                        style={{marginRight: 0}}
                        onClick={this.chatAction.bind(this, "block", guestId)}
                        color="black"
                        size="small"
                    >
                        Block
                    </Button>
                    :
                    null
                }
            </Div>
        )
    }

    renderCustomersInHostMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customers = pr.roomItem["customers"];
        const customersVip = pr.roomItem["customers_vip"];

        const innerParticipantsContainer = StyleUtils.getMediaStyle('innerParticipantsContainer', sty.innerParticipantsContainer, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="innerParticipantsContainer" style={innerParticipantsContainer}>
                <Div style={{width: "100%", fontSize: 22, paddingTop: 20}}>
                    VIP ({customersVip.length})
                </Div>
                {customersVip.map((customerVip) => {
                    return this.renderCustomerInHostMode(customerVip)
                })}
                <Div style={{width: "100%", fontSize: 22, paddingTop: 25}}>
                    Guests ({customers.length})
                </Div>
                {customers.map((customer) => {
                    return this.renderCustomerInHostMode(customer)
                })}
            </Div>
        )
    }

  renderVideobox() {
      const pr = this.props;
      const st = this.state;
      const sty = this.styles;

      let attendeeMode = Utils.get(pr.roomItem, "insiders.isInsider") ? "video" : "audience";
      if(st.mode == 'host') {
          attendeeMode = 'video';
      }
      //attendeeMode = 'video';
      const host = Utils.get(pr.roomItem, "insiders.host", []);
      const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);
      const customers = Utils.get(pr.roomItem, "customers", []);
      const customersVip = Utils.get(pr.roomItem, "customers_vip", []);
      const vipDict = Utils.arrayToObject(customersVip, 'id');
      if(pr.user.id in vipDict) {
          attendeeMode = 'video';
      }
      let users = Utils.arrayToObject([host], 'id');
      Utils.arrayToObject(cohosts, 'id', users);
      Utils.arrayToObject(customers, 'id', users);
      Utils.arrayToObject(customersVip, 'id', users);
      //ll._("renderVideobox agora with users (host/customer/vip): ", "blue", users);
      const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");

      const videoContainer = StyleUtils.getMediaStyle('videoContainer', sty.videoContainer, sty, StyleUtils.getWidthType(pr.screen.width));

      return (
          <Div style={videoContainer} className="AgoraCall videoContainer">
              <AgoraVideoCall
                key={`agora-${st.agoraKey}`}
                screen={pr.screen}
                videoProfile={this.videoProfile}
                channel={this.channel}
                transcode={this.transcode}
                attendeeMode={attendeeMode}
                baseMode={this.baseMode}
                appId={this.appId}
                uid={this.uid}
                displayMode={'afterparty'}
                customerId={Utils.get(this.props.user, 'id')}
                changeLocalStream={this.handleChangeAgoraStream.bind(this)}
                changeClient={this.handleChangeAgoraClient.bind(this)}
                users={users}
                host={host}
                cohosts={cohosts}
                customersVip={customersVip}
                agoraActive={st.agoraActive}
                agoraEvents={this.handleChangeAgoraEvent.bind(this)}
                version="v2"
                selectTabRoomMobile={this.selectTabRoomMobile.bind(this)}
                isInsider={isInsider}
              />
          </Div>
      )
     }

    renderCustomersInChatboxHostMode() {
        const pr = this.props;
        const sty = this.styles;

        // {pr.roomItem['insiders']['cohosts'].map((host) => {
        //     return this.renderHostTile("sub", host["photo"], host["username"], 100);
        // })}
        // {pr.roomItem['insiders']['mods'].map((mod) => {
        //     return this.renderHostTile("sub", mod["photo"], mod["username"], 100);
        // })}

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem.customers || pr.roomItem.customers_vip ?
                    this.renderCustomersInHostMode()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    renderChatboxContent() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const helpHtml = Utils.get(pr.roomItem, "help.body", "");

        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const blogBody = StyleUtils.getMediaStyle('blogBody', sty.blogBody, sty, StyleUtils.getWidthType(pr.screen.width));

        switch(st.chatboxContent) {
            case "messages":
                return (
                    <Chatbox
                        screen={pr.screen}
                        roomId={pr.roomItem["id"]}
                        roomChats={pr.roomItem["roomChats"]}
                        customers={pr.roomItem["customers"]}
                        badgeTypes={pr.roomItem["badgeTypes"]}
                        handleOnlineUsersChange={this.handleOnlineUsersChange.bind(this)}
                        scrollContainerStyle={scrollContainerStyle}
                        handleSignal={this.handleSignal.bind(this)}
                        customerId={Utils.get(this.props.user, 'id')}
                        mostRecentMessage={this.handleMostRecentMessage.bind(this)}
                        width={pr.screen.width}
                    />
                )
                break;
            case "participants":
                return this.renderCustomersInChatboxHostMode()
                break;
            case "help":
                return (
                    <Div
                        style={blogBody}
                        dangerouslySetInnerHTML={{ __html: helpHtml }}
                    />
                )
                break;
        }
    }

    renderHostMode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const hostModeContainer = StyleUtils.getMediaStyle('hostModeContainer', sty.hostModeContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxContainer = StyleUtils.getMediaStyle('chatboxContainer', sty.chatboxContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const videoNftContainer = StyleUtils.getMediaStyle('videoNftContainer', sty.videoNftContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxHeader = StyleUtils.getMediaStyle('chatboxHeader', sty.chatboxHeader, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="hostModeContainer" style={hostModeContainer}>
                <Div className="videoNftContainer" style={videoNftContainer}>
                    {this.renderTestInfo()}
                    {this.renderJoinMeeting()}
                    {this.renderMostRecentMessage()}

                    {this.renderHostControls()}
                </Div>

                <Div className="chatboxContainer" style={chatboxContainer}>
                    <Div className="chatboxHeader" style={chatboxHeader}>
                        {this.renderChatboxTabs(true)}
                    </Div>
                    {/* {this.renderChatboxContent()} */}
                </Div>
            </Div>
        );
    }

    renderCustomersInChatboxGuestMode() {
        const pr = this.props;
        const sty = this.styles;

        return (
            <Div className="participantsContainer" style={sty.participantsContainer}>
                {pr.roomItem["customers"] || pr.roomItem["customers_vip"] ?
                    this.renderCustomers()
                    :
                    <Div>No Guests are in the room"</Div>
                }
            </Div>
        )
    }

    renderTestInfo() {
        if(!this.state.testMode) {
            return null;
        }
        let attendeeMode = Utils.get(this.props.roomItem, "insiders.isInsider") ? "Insider" : "Guest";

        return (
            <Div style={{marginTop:8,display:"flex", backgroundColor: Colors.purpleDark, padding: 10, borderRadius: 8, marginBottom:8, flexWrap: "wrap",}}>
                <Div style={{flex:1, display:"flex",}}>
                    Agora Active: {this.state.agoraActive ? <Div style={{marginLeft: 8,color:"green"}}>Yes</Div> : <Div style={{marginLeft: 8,color:"red"}}>No</Div> }
                </Div>
                <Div style={{flex:1, display:"flex",}}>Customer ID: {Utils.get(this.props.user, 'username')} / {Utils.get(this.props.user, 'id')}</Div>
                <Div style={{flex:1}}>Agora Client ID: {this.state.clientId ? this.state.clientId : "Null"}</Div>
                <Div style={{flex:1}}>LocalStream: {this.state.streamActive ? this.state.streamActive : "Null"}</Div>
                <Div style={{flex:1}}>Mode: {attendeeMode}</Div>
                <Div style={{flex:1}}>Width: {this.props.screen.width} h: {this.props.screen.height} wt: {StyleUtils.getWidthType(this.props.screen.width)}</Div>

            </Div>
        );
    }
    renderJoinMeeting() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const joinMeetingButton = StyleUtils.getMediaStyle('joinMeetingButton', sty.joinMeetingButton, sty, StyleUtils.getWidthType(pr.screen.width));
        const joinMessage = Utils.get(this.props.roomItem, "event.join_message");

        if(!st.agoraActive) {
            return (
                <Div>
                    { false ?
                        <Div style={{color:"black", fontSize:18, textAlign:"center", marginBottom:18}}>
                            Event will start at 3:00pm PST
                        </Div>
                        :
                        null
                    }
                    { joinMessage && joinMessage.length > 1 ?
                        <Div style={{color:"black", fontSize:18, textAlign:"center", marginBottom:18}}>
                            {joinMessage}
                        </Div>
                        :
                        null
                    }
                    <Button
                        className="pulse joinMeetingButton"
                        size="small"
                        style={joinMeetingButton}
                        onClick={this.handleStartAgora.bind(this)}
                        color="pinkGradient"
                    >
                        Join Afterparty Event
                    </Button>
                </Div>
            )
        } else if(st.agoraActive && !pr.screen.mobile) {
            return <Div style={{height: 78}}></Div>
        } else {
            return null;
        }
    }

    handleMostRecentMessage(message) {
        this.setState({mostRecentMessage: message});
    }

    changePost(e) {
        this.setState({post: e.target.value});
    }

    keyDownPost(e) {
        if(e.key === 'Enter') {
            this.handleChatPost();
            return;
        }
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }
        let data = {
            body: st.post,
            room_id: pr.roomId,
        };
        this.setState({posting: true});

        Api.postRoomChat(data, (o) => {
             if(o && o.data) {
                 this.setState({messages: o.data, post: "", lastPost: st.post, posting: false}, () => {
                     const objDiv = document.getElementById('scrollBottom');
                     objDiv.scrollTop = objDiv.scrollHeight;
                 });
             }
        });
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }

        let data = {
            body: st.post,
            room_id: pr.roomItem["id"],
        };
        this.setState({posting: true});

        Api.postRoomChat(data, (o) => {
             if(o && o.data) {
                 this.setState({mostRecentMessage: o.data[o.data.length - 1], post: "", lastPost: st.post, posting: false});
             }
        });
    }

    getTabsRoomMobileComponentHeight() {
        const pr = this.props;

        if(pr.screen.width <= 568) {
            return "fit-content";
        } else if(pr.screen.width <= 812) {
            return `calc(${pr.screen.height}px - 69px)`;
        } else if (!pr.screen.mobile) {
            return "100%";
        } else {
            return "fit-content";
        }
    }

    renderUsername(message, customerDict) {
        let username = message['customer_id'];
        if(message['username']) {
            username = message['username'];
        } else if(message['customer_id'] in customerDict) {
            username = customerDict[message['customer_id']]['username'];
        }
        return username;
    }

    renderAvatar(message, customerDict) {
        const sty = this.styles;

        let avatarStyle = null;

        if(message['customer_id'] in customerDict && customerDict[message['customer_id']]['photo']) {
            avatarStyle = {...sty.customerIcon, ...{backgroundImage: `url(${customerDict[message['customer_id']]['photo']})`},};
        } else {
            const avatarColor = Colors.colorIndex[message['customer_id'] % Colors.colorIndex.length];
            avatarStyle = {...sty.customerIcon, ...{backgroundColor: avatarColor},};
        }

        return <Div style={avatarStyle} className="avatarStyle"></Div>
    }

    renderBadges(message, customerDict) {
        const pr = this.props;
        const badgeTypes = pr.roomItem["badgeTypes"];

        let badges = null;

        if(message['customer_id'] in customerDict && badgeTypes && customerDict[message['customer_id']]['badges'].length > 0 ) {
            const badgeList = customerDict[message['customer_id']]['badges'];
            let activeBadges = [];
            for(var idx in badgeList) {
                if(badgeList[idx] in badgeTypes) {
                    activeBadges.push(<img key={`${message.id}-${Math.random()}`} style={{width:24,height:24,padding:2}} src={badgeTypes[badgeList[idx]]['image_url']} />)
                }
            }
            if(activeBadges.length > 0) {
                badges = activeBadges;
            }
        }
        return badges;
    }

    handleToggleMostRecent() {
        this.setState({expandMostRecentMessageInput: !this.state.expandMostRecentMessageInput});
    }

    addEmojiToChat(emoji) {
        this.setState({post: this.state.post += emoji});
    }

    selectTabRoomMobile(tabName) {
        this.setState({
            selectedTabMobileRoom: tabName,
            autoScroll: false,
        });
    }

    changeChatDirection() {
        const st = this.state;

        this.setState({
            isChatboxInputAbove: !st.isChatboxInputAbove,
            autoScroll: false,
        });
    }

    renderMostRecentMessage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customerDict = Utils.arrayToObject(pr.roomItem["customers"], "id");

        // const roundedBorders = !st.expandMostRecentMessageInput ? {borderBottomLeftRadius: 22, borderBottomRightRadius: 22, overflow: "hidden"} : null;
        const roundedBorders = {borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: "hidden"};

        const inputContainer = StyleUtils.getMediaStyle('inputContainer', sty.inputContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const recentMessageContainer = StyleUtils.getMediaStyle('recentMessageContainer', sty.recentMessageContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const messageStyle = StyleUtils.getMediaStyle('messageStyle', sty.messageStyle, sty, StyleUtils.getWidthType(pr.screen.width));

        if(pr.screen.width > 992) {
            return null;
        }

        const messageTime = Utils.getTimeFromDate(st.mostRecentMessage['created_at']);

        return (
            <Div className="recentMessageContainer" style={recentMessageContainer} onClick={this.selectTabRoomMobile.bind(this, "chatbox")}>
                <Div style={sty.customerInfoContainer}>
                    {/* {this.renderBadges(st.mostRecentMessage, customerDict)} */}

                    <Div className="messageStyle" style={messageStyle} dangerouslySetInnerHTML={{__html: st.mostRecentMessage['body']}}>
                    </Div>
                    {/* <Div style={{color: "white"}}>
                        {pr.screen.mobile.toString()} {pr.screen.mobileLandscape.toString()}
                    </Div> */}
                    {/* <Div style={{color: "white"}}>
                        {window.innerHeight} • {window.innerWidth}
                    </Div> */}

                    <Div className="usernameAndDateContainer" style={{display: "flex", justifyContent: "right", flexDirection: "column", paddingLeft: 8}}>
                        <Div className="messageDate" style={sty.messageDate}>
                            {messageTime}
                        </Div>
                        <Div className="customerName" style={sty.customerName}>
                            {this.renderAvatar(st.mostRecentMessage, customerDict)}
                            <Div style={{marginLeft: -4}}>{this.renderUsername(st.mostRecentMessage, customerDict)}</Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    handleMuteClick(customerOrCustomers) {
        const st = this.state;
        const roomCrc = Utils.getUrlCrc();

        let muteAudio = JSON.parse(JSON.stringify(st.muteAudio));

        let customers = [];
        if(!customerOrCustomers.length) {
            customers = [customerOrCustomers]
        } else {
            customers = customerOrCustomers;
        }

        for(let i = 0; i < customers.length; ++i) {
            let customer = customers[i];
            let muteAction = "mute";

            if(customer.id && muteAudio[customer.id]) {
                muteAction = "unmute";
                delete muteAudio[customer.id];
            } else {
                muteAction = "mute";
                muteAudio[customer.id] = true;
            }

            let modalData = {
                action: muteAction,
                roomCrc: roomCrc,
                customerId: customer.id,
            };

            Api.putRoomChatSignal(roomCrc, modalData, (response) => {
                console.log("response", response);
            });
        }

        this.setState({muteAudio: muteAudio});
    }

    handleHideVideoClick(customer) {
        const st = this.state;

        let muteVideo = JSON.parse(JSON.stringify(st.muteVideo))

        if(muteVideo[customer.id]) {
            delete muteVideo[customer.id];
        } else {
            muteVideo[customer.id] = true;
        }

        this.setState({muteVideo: muteVideo});
    }

    renderParticipantsIcons(customer, isVip) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="participantIcons" style={sty.participantIcons}>
                {st.isHost || st.isCohost || st.isMod ?
                    <Icon
                        title="toggle audio"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        size={20}
                        icon={st.muteAudio[customer.id] ? "microphone-slash" : "microphone"}
                        onClick={this.handleMuteClick.bind(this, customer)}
                    />
                    :
                    null
                }

                {st.isMod /*|| isVip*/ ?
                    <Icon
                        title="toggle video"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        size={20}
                        icon={st.muteVideo[customer.id] ? "video-slash" : "video"}
                        onClick={this.handleHideVideoClick.bind(this, customer)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="refresh user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="recycle"
                        color="white"
                        size={20}
                        onClick={this.chatAction.bind(this, "refresh", customer.id)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="kick user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="eject"
                        color="white"
                        size={20}
                        onClick={this.chatAction.bind(this, "kick", customer.id)}
                    />
                    :
                    null
                }

                {st.isMod ?
                    <Icon
                        title="block user"
                        style={{...sty.participantIcon, ...StylesPlatform.cursorPointer}}
                        icon="close"
                        color="white"
                        size={20}
                        onClick={this.chatAction.bind(this, "block", customer.id)}
                    />
                    :
                    null
                }
            </Div>
        )
    }

    renderTabbedSelector() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        let tabbedContent = null;

        const listings = pr.roomItem['listings'] ? pr.roomItem['listings'] : [];
        const customersVip = Utils.get(pr.roomItem, "customers_vip", []);
        const host = pr.roomItem.insiders["host"];
        const cohosts = pr.roomItem.insiders["cohosts"];
        const vips = pr.roomItem["customers_vip"];
        const customers = pr.roomItem["customers"];

        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const scrollContainerStyle = StyleUtils.getMediaStyle('scrollContainerStyle', sty.scrollContainerStyle, sty, StyleUtils.getWidthType(pr.screen.width));
        const tabbedSelectorContainer = StyleUtils.getMediaStyle('tabbedSelectorContainer', sty.tabbedSelectorContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        let tabsRoomMobileContent = StyleUtils.getMediaStyle('tabsRoomMobileContent', sty.tabsRoomMobileContent, sty, StyleUtils.getWidthType(pr.screen.width));
        tabsRoomMobileContent = {...tabsRoomMobileContent, ...{height: this.getTabsRoomMobileComponentHeight()}};
        const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");

        const mobileTabs = [
            {
                tabName: "vips",
                iconName: "star",
            },
            {
                tabName: "chatbox",
                iconName: "comment",
            },
            {
                tabName: "nfts",
                iconName: "nft",
            },
            {
                tabName: "participants",
                iconName: "users",
            },
        ];

        return (
            <Div className="tabbedSelectorContainer" style={tabbedSelectorContainer}>
                <Div className="tabsRoomMobileContent" style={tabsRoomMobileContent}>
                    {st.selectedTabMobileRoom === "chatbox" ?
                        <Div
                            className="arrowContainer"
                            style={sty.arrowContainer}
                        >
                            <Div style={{marginRight: "auto"}}>Chat</Div>
                            {pr.screen.mobile ?
                                st.isChatboxInputAbove ?
                                    <Icon icon="arrow-up" size={16} onClick={this.changeChatDirection.bind(this)} />
                                    :
                                    <Icon icon="arrow-down" size={16} onClick={this.changeChatDirection.bind(this)} />
                                :
                                null
                            }

                        </Div>
                        :
                        null
                    }
                    <Div className="tabbedContentOuterContainer">
                        <Div className="tabbedHideShowContainer" style={{display: st.selectedTabMobileRoom === "vips" ? "block" : "none"}}>
                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>VIPs</Div>
                            <Div className="customerOuterContainer" id={`tab-container-${st.selectedTabMobileRoom}`} style={sty.customerOuterContainer}>
                                {/* NOTE appending VIPS here */}
                                {customersVip.length ? null : <Div style={{marginTop: 60}}>No VIPs in the room</Div>}
                            </Div>
                        </Div>

                        <Div className="tabbedHideShowContainer" style={{display: st.selectedTabMobileRoom === "chatbox" ? "block" : "none"}}>
                            <Chatbox
                                screen={pr.screen}
                                roomId={pr.roomItem["id"]}
                                roomChats={pr.roomItem["roomChats"]}
                                badgeTypes={pr.roomItem["badgeTypes"]}
                                handleOnlineUsersChange={this.handleOnlineUsersChange.bind(this)}
                                scrollContainerStyle={scrollContainerStyle}
                                handleSignal={this.handleSignal.bind(this)}
                                customerId={Utils.get(pr.user, 'id')}
                                mostRecentMessage={this.handleMostRecentMessage.bind(this)}
                                width={pr.screen.width}
                                isChatboxInputAbove={st.isChatboxInputAbove}
                                isDisplayed={st.selectedTabMobileRoom === "chatbox"}
                            />
                        </Div>

                        <Div className="tabbedHideShowContainer" style={{display: st.selectedTabMobileRoom === "nfts" ? "block" : "none"}}>
                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>NFTs</Div>
                            <Div style={sty.admissionSoins}>
                              {listings.map((listing, i) => {
                                  return (
                                      <Div key={`listing-${listing['id']}`} className="roomNfts" style={{margin: 5, display: "flex", flexWrap: "wrap", justifyContent: "center", width: 135, position: "relative",}}>
                                          <Div
                                              className="roomNft"
                                              style={{
                                                  // clipPath: "url(#bloated-circle-125)",
                                                  width: 125,
                                                  height: 125,
                                                  marginBottom: 10,
                                                  flexShrink: 0,
                                                  backgroundImage: `url(${listing['image_url']})`,
                                                  cursor: "pointer",
                                                  backgroundSize: "cover",
                                                  backgroundPosition: "center",
                                                  backgroundRepeat: "no-repeat",
                                                  borderRadius: 32,
                                              }}
                                              onClick={this.openSoinModal.bind(this, listing, i)}
                                          >
                                          </Div>
                                          {/* <Div className="roomNftBadge" style={sty.roomNftBadge}>10%<br/>Off</Div> */}
                                          <Div style={{textAlign: "center", whiteSpace: "wrap"}}>{listing.title}</Div>
                                      </Div>
                                  )
                              })}
                            </Div>
                        </Div>

                        <Div className="tabbedHideShowContainer" style={{display: st.selectedTabMobileRoom === "participants" ? "block" : "none"}}>
                            {st.isMod ?
                                <Button
                                    size="small"
                                    onClick={this.chatAction.bind(this, "refresh")}
                                    style={{marginTop: 30, marginRight: "auto", marginBottom: 20, marginLeft: "auto"}}
                                >
                                    REFRESH ALL
                                </Button>
                                :
                                null
                            }

                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Host</Div>
                            <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                                <Div className="customerContainer" style={sty.customerContainer}>
                                    <Div
                                        className="customerPhoto"
                                        style={{...sty.customerPhoto, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                    ></Div>
                                    <Div style={sty.customerListName}>{host.username}</Div>
                                    {st.isMod || st.isHost ? this.renderParticipantsIcons(host) : null}
                                </Div>
                            </Div>

                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Co-Hosts</Div>
                            <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                                {cohosts.map((customer, customerIndex) => {
                                    return (
                                        <Div key={`customer-${customerIndex}`} className="customerContainer" style={sty.customerContainer}>
                                            <Div
                                                className="customerPhoto"
                                                style={{...sty.customerPhoto, ...{backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                            ></Div>
                                            <Div style={sty.customerListName}>{customer.username}</Div>
                                            {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer) : null}
                                        </Div>
                                    )
                                })}
                            </Div>

                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>VIPs</Div>
                            <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                                {vips.map((customer, customerIndex) => {
                                    return (
                                        <Div key={`customer-${customerIndex}`} className="customerContainer" style={sty.customerContainer}>
                                            <Div
                                                className="customerPhoto"
                                                style={{...sty.customerPhoto, ...{backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                            ></Div>
                                            <Div style={sty.customerListName}>{customer.username}</Div>
                                            {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer, true) : null}
                                        </Div>
                                    )
                                })}
                            </Div>

                            <Div className="tabbedContainerHeader" style={sty.tabbedContainerHeader}>Participants</Div>
                            <Div className="customerOuterContainer" style={sty.customerOuterContainer}>
                                {customers.map((customer, customerIndex) => {
                                    return (
                                        <Div key={`customer-${customerIndex}`} className="customerContainer" style={sty.customerContainer}>
                                            <Div
                                                className="customerPhoto"
                                                style={{...sty.customerPhoto, ...{backgroundImage: `url('${customer.photo ? customer.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                            ></Div>
                                            <Div style={sty.customerListName}>{customer.username}</Div>
                                            {/* { isInsider ?
                                                <Div style={Styles.videoIconsContainer}>
                                                    <Icon
                                                        style={Styles.videoIconsIcon}
                                                        size={16}
                                                        icon={!st.muteAudio[customer['id']] ? "microphone" : "microphone-slash"}
                                                        onClick={this.chatAction.bind(this, "mute", customer['id'])}
                                                    />

                                                </Div>
                                                :
                                                null
                                            } */}
                                            {/* {st.isMod || st.isHost || st.isCohost ? this.renderParticipantsIcons(customer) : null} */}
                                        </Div>
                                    )
                                })}
                            </Div>
                        </Div>
                    </Div>
                </Div>
                <Div className="tabsRoomMobile" style={sty.tabsRoomMobile}>
                    {mobileTabs.map((mobileTab, mobileTabIndex) => {
                        const isSelectedTab = st.selectedTabMobileRoom === mobileTab.tabName;
                        if(isSelectedTab) {
                            sty.tabsRoomMobileIconContainer.boxShadow = "inset 0 0 2px #333";
                            sty.tabsRoomMobileIconContainer.filter = "none";
                        } else {
                            sty.tabsRoomMobileIconContainer.boxShadow = "none";
                            sty.tabsRoomMobileIconContainer.filter = "drop-shadow(1px 1px 1px #aaa)";
                        }

                        return (
                            <Div
                                key={`tabbed-selector-${mobileTabIndex}`}
                                className="tabsRoomMobileIconContainer"
                                style={{
                                    ...sty.tabsRoomMobileIconContainer,
                                    ...StylesPlatform.cursorPointer,
                                    ...{backgroundColor: isSelectedTab ? Colors.indigoLight : "white"},
                                }}
                            >
                                {/* <Icon
                                    style={sty.tabRoomMobileIcon}
                                    icon={mobileTab.iconName}
                                    onClick={this.selectTabRoomMobile.bind(this, mobileTab.tabName)}
                                    color={isSelectedTab ? "white" : Colors.purple}
                                    size={20}
                                /> */}
                                <Image
                                    style={sty.tabRoomMobileIcon}
                                    src={this.chooseTabIcon(isSelectedTab, mobileTab.iconName)}
                                    onClick={this.selectTabRoomMobile.bind(this, mobileTab.tabName)}
                                />
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        )
    }

    renderGuestMode(isHostMode) { // GUESTMODE
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomItem) {
            return null;
        }

        let artistName = Utils.get(pr.roomItem, 'artist.title')
        const guestCount = pr.roomItem && pr.roomItem["guests"] ? pr.roomItem["guests"].length : 0;
        let hosts = Utils.get(pr.roomItem, 'insiders.mods');
        hosts = hosts ? hosts : [];


        const guestModeContainer = StyleUtils.getMediaStyle('guestModeContainer', sty.guestModeContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const afterpartyRoomInfoContainer = StyleUtils.getMediaStyle('afterpartyRoomInfoContainer', sty.afterpartyRoomInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const videoNftContainer = StyleUtils.getMediaStyle('videoNftContainer', sty.videoNftContainer, sty, StyleUtils.getWidthType(pr.screen.width));
        const chatboxContainer = StyleUtils.getMediaStyle('chatboxContainer', sty.chatboxContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const chatboxHeader = StyleUtils.getMediaStyle('chatboxHeader', sty.chatboxHeader, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="guestModeContainer" style={guestModeContainer}>
                <Div className="videoNftContainer" style={videoNftContainer}>
                    <Div style={{maxWidth: 1600, width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        {this.renderTestInfo()}
                        {this.renderJoinMeeting()}
                        {this.renderHostTile("host", pr.roomItem["image_url"], artistName)}
                        <Div className="hostsRoomContainer" style={{display: "flex"}}>
                            {hosts.map((host) => {
                                return this.renderHostTile("sub", host["photo"], host["username"], 100);
                            })}
                        </Div>
                    </Div>
                    { isHostMode && this.state.agoraActive ?
                        this.renderVideoIcons()
                        :
                        null
                    }
                </Div>

                <Div className="chatboxContainer" style={chatboxContainer}>
                    {/* <Div className="chatboxHeader" style={chatboxHeader}>
                        {this.renderChatboxTabs(true)}
                    </Div> */}
                    {/* {this.renderChatboxContent()} */}
                    {pr.screen.width > 812 ? null : this.renderMostRecentMessage()}
                    {this.renderTabbedSelector()}
                </Div>
            </Div>
        );
    }

    renderRoomNotFound() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.roomLoaded) {
            return null;
        }

        return (
            <Div style={sty.roomNotFound}>
                {Utils.get(pr.user, "email", false) ?
                    <>
                        <Div style={{textAlign: "center"}}>Room Not Found</Div>
                        <Button style={{marginTop: 10}} onClick={this.backToEvents.bind(this)}>
                            Back To Events
                        </Button>
                    </>
                    :
                    <>
                        <Div style={{textAlign: "center"}}>Room Not Accessible</Div>
                        <Button style={{marginTop: 10}} onClick={this.goToSignIn.bind(this)}>
                            Please Sign In
                        </Button>
                    </>
                }
            </Div>
        )
    }

    renderModes(isInsider, mode) {
        const isHostMode = isInsider || mode == 'host';
        return this.renderGuestMode(isHostMode);
    }

    getBuyListing() {
        const pr = this.props;

        let buyListing = false;
        const listings = Utils.get(pr.roomItem, 'listings', []);

        for(var idx in listings) {
            const listing = listings[idx];
            if(Utils.get(listing, "bitclout_username") && listing['id'] != 75) {
                buyListing = listing;
                break;
            }
        }
        return buyListing;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const isInsider = Utils.get(pr.roomItem, "insiders.isInsider");
        let buyListing = this.getBuyListing();

        const host = Utils.get(pr.roomItem, "insiders.host", {username: ""});
        const cohosts = Utils.get(pr.roomItem, "insiders.cohosts", []);

        const afterpartyRoomComponent = StyleUtils.getMediaStyle('afterpartyRoomComponent', sty.afterpartyRoomComponent, sty, StyleUtils.getWidthType(pr.screen.width));

        return (
            <Div className="afterpartyRoomComponent" style={afterpartyRoomComponent}>
                <BloatedCircleDefs
                    scales={[50, 100, 125, 200]}
                />
                <DebugBox show={window.streamDict} />


                <DebugBox show={st} />

                {pr.roomItem && !pr.roomNotFound ?
                    this.renderModes(isInsider, st.mode)
                    :
                    this.renderRoomNotFound()
                }

                {st.showSionModal ?
                    <ModalNFT
                        screen={pr.screen}
                        nft={st.currentSoin}
                        onRequestClose={this.closeSoinModal.bind(this)}
                    />
                    :
                    null
                }

                {st.showProfileModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeProfileModal.bind(this)}
                        color="purple"
                        wide
                    >
                        <Profile
                            screen={pr.screen}
                            style={{padding: "20px 0 0"}}
                            hidePageBack
                            user={pr.user}
                            history={pr.history}
                            userLoaded={pr.userLoaded}
                        />
                    </Modal>
                    :
                    null
                }
                {pr.showBitCloutBuyModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={pr.handleBuyModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <iframe style={sty.buyIframe} src={`https://bitclout.com/u/${Utils.get(buyListing, "bitclout_username", "afterparty")}/buy`} />
                        </Div>
                    </Modal>
                    :
                    null
                }

                {pr.showFollowModal ?
                    <Modal
                        screen={pr.screen}
                        title={<><Image src={followUser} style={{width: 20, marginBottom: 6}} /> &nbsp;Following</>}
                        onRequestClose={pr.handleFollowModalClose.bind(this)}
                        color="purple"
                        style={{maxWidth: 500}}
                    >
                        <Div style={sty.followSubtitles}>Host</Div>
                        <Div className="followRow" style={sty.followRow}>
                            <Div
                                className="followImage"
                                style={{...sty.followImage, ...{backgroundImage: `url('${host.photo ? host.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                            ></Div>
                            {host.username}
                            <Button
                                style={{marginLeft: "auto"}}
                                color={pr.user.following[host.id] ? "magenta" : "white"}
                                size="small"
                                onClick={this.handleSubscribeClick.bind(this, host)}
                            >
                                {pr.user.following[host.id] ? "Following" : "Follow"}
                            </Button>
                        </Div>

                        {cohosts.length > 0 ?
                            <Div style={sty.followSubtitles}>Co-Hosts</Div>
                            :
                            null
                        }
                        {cohosts.map((cohost, cohostIndex) => {
                            const isFollowing = pr.user.following[cohost.id];

                            return (
                                <Div key={`cohost-${cohostIndex}`} className="followRow" style={sty.followRow}>
                                    <Div
                                        className="followImage"
                                        style={{...sty.followImage, ...{backgroundImage: `url('${cohost.photo ? cohost.photo : "/images/avatars/male01_640x480.jpg"}')`}}}
                                    ></Div>
                                    {cohost.username}
                                    <Button
                                        style={{marginLeft: "auto"}}
                                        color="white"
                                        color={isFollowing ? "magenta" : "white"}
                                        size="small"
                                        onClick={this.handleSubscribeClick.bind(this, cohost)}
                                        noBorder
                                    >
                                        {isFollowing ? "Following" : "Follow"}
                                    </Button>
                                </Div>
                            )
                        })}
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        afterpartyRoomComponent: {
            minHeight: "100vh",
            backgroundColor: Colors.eggplant,
            color: "white",
            overflow: "hidden",
            marginTop: 72,
        },
        afterpartyRoomComponent_md: {
            // backgroundColor: "white",
        },
        afterpartyRoomComponent_sm: {
            // backgroundColor: "white",
        },
        afterpartyRoomComponent_xs: {
            // backgroundColor: "white",
        },
        videoIconsContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: this.props.screen.mobile ? Colors.purple : "none",
            borderRadius: 8,
            marginBottom: 5,
            padding: 3,
        },
        videoIconsIcon: {
            padding: "10px 10px",
            margin: "0 10px",
            cursor: "pointer",
        },
        remoteContainer: {
            position: "relative",
            margin: 0,
            borderRadius: 14,
            overflow: "hidden",
            justifyContent: "center",
        },
        remoteContainer_md: {
            borderRadius: 8,
        },
        app: {
            backgroundColor: "#000",
            textAlign: "left",
            fontFamily: "'Montserrat', sans-serif",
        },
        leftCol: {
            minHeight: 850,
            maxWidth: 220,
            backgroundColor:"white",
            paddingLeft: 0,
            paddingRight: 0,
        },
        leftColBody: {
            paddingTop: 20,
            justifyContent: "center",
            display: "flex",
            flexDirection:"col",
        },
        rightCol: {
            paddingLeft:0,
            background:"#FBF8F4",
            minHeight:800,
            color:"black",
            padding: 20,
            width: "100%",
        },
        joinMeetingButton: {
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 500,
            width: "100%",
            padding: "16px 14px 17px",
        },
        joinMeetingButton_md: {
            marginBottom: 5,
        },
        joinMeetingButton_sm: {
            marginBottom: 5,
        },
        joinMeetingButton_xs: {
            marginBottom: 5,
        },
        eventBanner: {
            position: "relative",
            width: "100%",
        },
        eventBannerInfobox: {
            color: "white",
            position: "absolute",
            bottom: 55,
            left: 47,
            textAlign: "center",
        },
        nameBox: {
            position:"absolute",
            bottom: 50,
            left: 0,
            width: 200,
            height: 26,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        nameBoxSmall: {
            position:"absolute",
            bottom: 20,
            left: 0,
            width: 100,
            height: 23,
            fontSize: 10,
            color: "white",
            paddingLeft: 8,
            backgroundColor: "rgba(0,0,0,0.5)",
            textAlign: "left",
        },
        videoContainer: {
            // minWidth: 565,
            //minHeight: 400,
            // background: "rgba(255,255,255,0.1)",
            // backgroundColor: Colors.purpleBackground,
            borderRadius: 22,
        },
        videoContainer_md: {
            backgroundColor: "white",
            borderRadius: 8,
            marginBottom: 0,
        },
        videoContainer_sm: {
            backgroundColor: "white",
            borderRadius: 0,
            marginBottom: 5,
        },
        videoContainer_xs: {
            backgroundColor: "white",
            borderRadius: 0,
            marginBottom: 5,
        },
        hostModeContainer: {
            display: "flex",
            flexWrap: "wrap",
            padding: "20px 40px",
            // maxWidth: 1600,
            marginLeft: "auto",
            marginRight: "auto",
        },
        hostModeContainer_lg: {
            padding: "20px 20px",
        },
        hostModeContainer_md: {
            padding: "20px 20px",
            flexDirection: "column",
        },
        hostModeContainer_xs: {
            padding: "5px 10px",
        },
        videoNftContainer: {
            display: "flex",
            flexDirection: "column",
            width: "calc(100vw - 590px)",
        },
        videoNftContainer_lg: {
            width: "calc(100vw - 560px)",
        },
        videoNftContainer_md: {
            width: "100%",
            paddingRight: 5,
        },
        videoNftContainer_sm: {
            width: "100%",
            paddingRight: 5,
        },
        videoNftContainer_xs: {
            width: "100%",
            paddingRight: 0,
        },
        roomTitleContainer: {
            display: "flex",
            padding: "10px 0 0 0",
        },
        roomTitleContainer_xs: {
            padding: "0",
        },
        eventTitle: {
            fontWeight: "bold",
            fontSize: 18,
        },
        eventTitle_xs: {
            fontSize: 12,
        },
        artistTitle: {
            fontSize: 40,
            fontWeight: "bold",
            marginBottom: 15,
        },
        artistTitle_xs: {
            fontSize: 26,
        },
        eventTimeDuration: {
            padding: "8px 14px",
            borderRadius: 8,
            backgroundColor: Colors.purpleBackground,
            fontSize: 12,
        },
        guestModeContainer: {
            display: "flex",
            padding: "20px 40px",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",

        },
        guestModeContainer_lg: {
            padding: "20px 20px",
        },
        guestModeContainer_md: {
            padding: "10px 5px 5px",
        },
        guestModeContainer_sm: {
            padding: "10px 5px 5px",
        },
        guestModeContainer_xs: {
            padding: "10px 5px 5px 5px",
            flexDirection: "column",
        },

        nftSoinContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
        },
        eventIconCircle: {
            borderRadius: "100%",
            height: 27,
            width: 27,
            backgroundColor: Colors.purpleLightBackground,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
        },
        chevronIcon: {
            fontSize: 18,
            marginLeft: -2,
        },
        profile: {
            width: 50,
            height: 50,
            backgroundColor: Colors.purpleLightBackground,
            borderRadius: 16,
            marginLeft: "auto",
        },

        customersContainer: {
            display: "flex",
            backgroundColor: Colors.purpleBackground,
            borderRadius: 24,
            padding: "30px 45px",
            marginBottom: 15,
            flexDirection: "column",
        },

        hostsAndCohosts: {
            display: "flex",
            flexWrap: "wrap",
        },

        afterpartyRoomInfoContainer: {
            backgroundColor: Colors.purpleBackground,
            padding: 30,
            borderRadius: 24,
            marginBottom: 30,
         },
         afterpartyRoomInfoContainer_md: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_sm: {
             padding: 20,
         },
         afterpartyRoomInfoContainer_xs: {
             padding: 10,
         },

         admissionSoins: {
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            //overflowX: "scroll",
            flexWrap: "wrap",
            overflow: "hidden",
         },

        chatboxContainer: {
            // flex: 4,
            // backgroundColor: Colors.purpleLight2,
            borderRadius: 22,
            color: "black",
            //height: 804,
            marginLeft: 20,
            maxWidth: 500,
            width: "100%",
            // height: 866,
            // height: "100vh",
            position: "fixed",
            right: 20,
            // height: "calc(100vh - 37px)",
            // zIndex: 1000

        },
        chatboxContainer_md: {
            // backgroundColor: "white",
            marginLeft: 0,
            marginBottom: 0,
            maxWidth: "100%",
            height: "auto",
            position: "unset",
        },
        chatboxContainer_sm: {
            // backgroundColor: "white",
            position: "unset",
        },
        chatboxContainer_xs: {
            // backgroundColor: "white",
            position: "unset",
        },
        scrollContainerStyle_lg: {
            padding: "15px 15px 0 15px",
        },
        chatboxHeader: {
            padding: "20px 20px 15px 20px",
            borderBottom: `1px solid ${Colors.purpleLight}`,
        },
        chatboxHeader_xs: {
            padding: "20px 15px 0 15px",
        },
        tabsContainer: {
            display: "flex",
            width: "100%",
            // backgroundColor: Colors.purpleLight,
            backgroundColor: Colors.purpleLightest,
            borderRadius: 14,
            fontSize: "bold",
            color: Colors.purple,
            marginBottom: 15,
        },
        tabSelected: {
            backgroundColor: Colors.purpleLighter,
            borderRadius: 14,
        },
        tabStyle: {
            cursor: "pointer",
            padding: 15,
            width: "50%",
            textAlign: "center",
        },
        tabStyle_xs: {
            padding: 10,
        },

        innerParticipantsContainer: {
            display: "flex",
            flexWrap: "wrap",
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 254px)",
            padding: "0 24px 24px 24px",
        },
        innerParticipantsContainer_sm_landscape: {
        },
        innerParticipantsContainer_xs_landscape: {
        },
        innerParticipantsContainer_xs: {
            flexDirection: "column",
            flexWrap: "nowrap",
            height: 452,
            padding: "0 15px 15px 15px",
        },

        roomNotFound: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 48,
            color: "white",
            backgroundColor: Colors.purple,
            width: "100%",
            height: "100vh",
        },
        blogBody: {
            overflow: "scroll",
            height: "calc(100vh - 254px)",
            paddingTop: 20,
            paddingRight: 20,
        },
        blogBody_xs: {
            height: 452,
        },

        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 10,
        },
        recentMessageContainer: {
            borderRadius: 8,
            padding: "6px 10px",
            backgroundColor: Colors.indigoLight,
            color: "white",
            wordWrap: "break-word",
            width: "calc(100% - 40px)",
            minHeight: 48,
        },
        recentMessageContainer_sm: {
            width: "calc(100% - 40px)",
        },
        recentMessageContainer_xs: {
            width: "100%",
        },
        customerInfoContainer: {
            display: "flex",
            alignItems: "center",
        },
        customerIcon: {
            overflow: "hidden",
            backgroundSize: "cover",
            width: 18,
            height: 18,
            backgroundColor: Colors.purpleLight,
            marginRight: 10,
            borderRadius: 4,
            flexShrink: 0,
        },
        customerName: {
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "white",
            fontSize: 12,
            whiteSpace: "nowrap",
        },
        messageDate: {
            color: "white",
            fontSize: 10,
            marginLeft: "auto",
            whiteSpace: "nowrap",
        },
        messageStyle: {
            fontSize: 12,
            color: "white",
            width: "100%",
            overflowWrap: "anywhere",
        },
        messageStyle_md: {
            fontSize: 12,
        },
        messageStyle_sm: {
            fontSize: 12,
        },
        messageStyle_xs: {
            fontSize: 12,
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 22,
            borderBottomLeftRadius: 22,
        },
        inputContainer_xs: {
            padding: 15,
        },
        plusMinusButton: {
            color: "black",
            padding: 10,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "15px 20px 20px",
            backgroundColor: Colors.purpleLighter,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
        },
        inputContainer_xs: {
            padding: "8px 15px 15px",
        },
        inputAndSendIconContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
        },
        emoji: {
            padding: "0 8px"
        },
        buyIframeContainer: {
            width:"100%",
            height: 500,
            overflowX: "hidden",
            overflowY: "scroll",
        },
        buyIframe: {
            width:"100%",
            height:600,
            borderRadius:32,
        },

        tabbedSelectorContainer: {
            display: "flex",
            width: "100%",
            marginTop: 10,
            height: "calc(100vh - 180px)",
            marginTop: 78,
        },
        tabbedSelectorContainer_md: {
            marginTop: 5,
            height: "auto",
        },
        tabbedSelectorContainer_sm: {
            marginTop: 5,
            height: "auto",
        },
        tabbedSelectorContainer_xs: {
            marginTop: 5,
            height: 550,
        },
        tabsRoomMobileContent: {
            width: "100%",
            marginRight: 15,
            backgroundColor: Colors.indigoLight,
            padding: 10,
            borderRadius: 8,
            color: "white",
            minHeight: 245,
            overflow: "scroll",
        },
        tabsRoomMobileContent_md: {
            // height: `calc(${this.props.screen.height}px - 69px)`,
            marginRight: 5,
            maxHeight: 547,
        },
        tabsRoomMobileContent_sm: {
            // height: `calc(${this.props.screen.height}px - 69px)`,
            marginRight: 5,
            maxHeight: 547,
        },
        tabsRoomMobileContent_xs: {
            // height: "fit-content",
            marginRight: 5,
            maxHeight: 547,
        },
        tabsRoomMobile: {
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
        },
        tabRoomMobileIcon: {
            // marginBottom: 1,
            width: 20,

        },
        tabsRoomMobileIconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
            backgroundColor: "white",
            borderRadius: 8,
            flexShrink: 0,
            width: 35,
            height: 35,
        },
        arrowContainer: {
            paddingBottom: 8,
            display: "flex",
            color: "white",
            fontWeight: "bold",
        },
        tabbedContainerHeader: {
            marginBottom: 5,
            fontWeight: "bold",
        },
        customerOuterContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        customerContainer: {
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            // margin: 5,
            width: "100%",
            marginBottom: 15,
        },
        customerPhoto: {
            // width: 135,
            width: 60,
            height: 60,
            // paddingBottom: "75%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 8,
            marginRight: 10,
        },
        customerListName: {
            marginRight: "auto",
        },
        roomNftBadge: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: -5,
            right: -5,
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            borderRadius: "100%",
            fontSize: 10,
            width: 35,
            height: 35,
            lineHeight: "11px",
        },
        followSubtitles: {
            marginRight: "auto",
            marginBottom: 10,
            fontSize: 18,
            fontWeight: "bold",
        },
        followRow: {
            display: "flex",
            width: "100%",
            marginBottom: 10,
            alignItems: "center",
        },
        followImage: {
            height: 48,
            width: 48,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 8,
            marginRight: 15,
        },
        participantIcons: {
            width: 120,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "right",
        },
        participantIcon: {
            padding: 10,
        },
        videoEndEventButton:  {
            padding: "6px 14px 6px 14px",
            backgroundColor: "white",
            color: "black",
            borderRadius: 4,
            backgroundColor: "white",
            color: "black",
            height: "fit-content",
            width: "fit-content",
            marginLeft: 15,
            marginRight: 20,
        },
    };
}
