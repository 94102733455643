import React from "react";
import Modal from "react-modal";
import Colors from "../../modules/Colors";
import Div from "../../elements/Div";
// import Modal from "../../elements/Modal";

import ImageCropper from "./ImageCropper";

const customStyles = {
    overlay: {
        position: "fixed",
        display: "flex",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        padding: "32px 10px 32px 10px",
        zIndex: 1100,
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 1100,
        width: "100%",
        outline: "none",
        background: Colors.indigoLight,
        borderRadius: 18,
        border: "none",
        padding: "18px 20px 40px",
    },
};

const ImageModal = ({
    modalIsOpen,
    closeModal,
    image,
    onCropImage,
    ratio,
    screen,
    aspectRatio,
}) => {
    return (
        <Div
            className="imageModalComponent"
        >
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                screen={screen}
            >
                <Div
                    style={{
                        fontWeight: "bold",
                        fontSize: 24,
                        textAlign: "center",
                        marginTop: 10,
                        marginBottom: 25,
                        color: "white",
                    }}
                >
                    Upload Image
                </Div>
                {image && (
                    <ImageCropper
                        imgName={image.name}
                        onCropImage={onCropImage}
                        inputImg={URL.createObjectURL(image)}
                        closeModal={closeModal}
                        ratio={ratio}
                        aspectRatio={aspectRatio}
                    />
                )}
            </Modal>
        </Div>
    );
};

export default ImageModal;
