import React, { useState } from "react";
// import "./index.css";

import ImageModal from "./ImageModal";
import Button from "../../elements/Button";
import Div from "../../elements/Div";

const ImageInput = ({
    onChange,
    showPreview,
    imageData,
    aspectRatio,
    children,
}) => {
    const [image, setImage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const onChangeHandler = file => {
        onChange({
            photo: {
                data: file[0],
                src: URL.createObjectURL(file[0]),
            },
        });
    };

    const handleFile = e => {
        if (e.target.files.length > 0) {
            const file = e.target.files;
            var url = URL.createObjectURL(file[0]);
            var img = new Image();
            img.src = url;
            img.onload = function () {
                setWidth(this.width);
                setHeight(this.height);
            };
            const maxAllowedSize = 5 * 1024 * 1024;
            if (file[0].size > maxAllowedSize) {
                console.log("max image size");
            } else {
                setImage(file[0]);
                setModalIsOpen(true);
            }
            e.target.value = null;
        }
    };

    let inputElement;

    return (
        <>
            <ImageModal
                modalIsOpen={modalIsOpen}
                closeModal={() => {
                    setModalIsOpen(prevState => !prevState);
                }}
                image={image}
                onCropImage={croppedImg => onChangeHandler([croppedImg])}
                ratio={height / width <= 0.5 ? true : false}
                aspectRatio={aspectRatio}
            />
            <Div
                className="imageInputDivWrapper"
                onClick={() => inputElement.click()}
            >
                {children}
            </Div>
            <input
                className="imageInputUploader"
                ref={input => (inputElement = input)}
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFile}
            />
        </>
    );
};

export default ImageInput;
