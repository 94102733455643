import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Lottie from "lottie-react-web"

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StyleUtils from '../modules/StyleUtils';
import Colors from '../modules/Colors';

import Div from './Div';
import Image from '../elements/Image';
import Button from '../elements/Button';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Icon from '../elements/Icon';

import DashboardSoinFlyout from '../components/DashboardSoinFlyout';
import AfterpartyRoomBadges from '../components/AfterpartyRoomBadges';

import hamburgerMenuLottie from "../assets/lf30_editor_w1syhzm5.json";
import soinLogo from '../assets/soin-beige-logo.png';
import apLogo from '../assets/logo_set.png';
import followUser from '../assets/icons/follow-user.svg';

export default class FlyoutMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            subscribeSuccess: false,
        };
    }

    handleLogoClick() {
        const pr = this.props;
        window.location.href = "/";
        //pr.history.push('/home');
        //pr.change('home');
    }

    toggleMenu() {
        const st = this.state;

        if(!st.expanded) { // opening
            document.body.style.overflow = 'hidden';
            this.setState({
                expanded: !st.expanded,
                isForward: true, // !st.expanded
            });
        } else { // closing
            document.body.style.overflow = 'unset';
            this.setState({
                expanded: !st.expanded,
                isForward: false, // !st.expanded
            });
        }
    }

    changeMenu() {
        const pr = this.props;

        this.toggleMenu();
        // pr.change().bind(this);
        pr.change();
    }

    onClickBlackPart(event) {
        if(event.target.id === "blackPart") {
            this.toggleMenu();
        }
    }

    eventPageBack() {
        const pr = this.props;
        this.toggleMenu();
        pr.eventPageBack();
    }

    handleRoomNotificationClick(notificationNumber) {
        const pr = this.props;
        const st = this.state;

        if(notificationNumber === 1) {
            this.setState({
                showNotification: !st.showNotification,
            });
        } else if(notificationNumber === 2) {
            this.setState({
                showNotification: false,
            });
        }

        pr.handleNotificationClick();
    }

    renderAfterpartyLogo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Image className="logoStyle" style={sty.logoStyle} src={apLogo} onClick={this.handleLogoClick.bind(this)}/>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!Utils.get(pr.user, "email", false) && pr.selected === "room") {
            return null;
        }

        return (
            <CSSTransition
                in={st.expanded}
                timeout={500}
                classNames="blackPart"
            >
                <Div
                    id="blackPart"
                    className="blackPart flyoutMobileComponent"
                    style={sty.flyoutMobileComponent}
                    onClick={this.onClickBlackPart.bind(this)}
                >
                    <Div className="mobileHeader" style={sty.mobileHeader}>
                        {this.renderAfterpartyLogo()}
                        {pr.selected === "room" ?
                            <AfterpartyRoomBadges
                                showRoomNotification={pr.showRoomNotification}
                                badgeNotifications={pr.badgeNotifications}
                                currentRoomSignal={pr.currentRoomSignal}
                                handleBuyClick={pr.handleBuyClick.bind(this)}
                                handleShowFollowModal={pr.handleShowFollowModal.bind(this)}
                                handleClickRoomNotification={pr.handleClickRoomNotification.bind(this)}
                                handleCloseRoomNotification={pr.handleCloseRoomNotification.bind(this)}
                                roomItem={pr.roomItem}
                                user={pr.user}
                            />
                            :
                            null
                        }
                        <Div onClick={this.toggleMenu.bind(this)}>
                            <Lottie
                                style={sty.hamburgerMenu}
                                height={24}
                                width={24}
                                direction={st.isForward ? 1 : -1}
                                options={{
                                    animationData: hamburgerMenuLottie,
                                    loop: false,
                                    autoplay: true,
                                }}
                            />
                        </Div>
                    </Div>

                    <CSSTransition
                        in={st.expanded}
                        timeout={500}
                        classNames="navBarRight"
                        unmountOnExit
                    >
                        <Div className="navBarRight flyoutMobileInnerContainer" style={sty.flyoutMobileInnerContainer}>
                            <DashboardSoinFlyout
                                screen={pr.screen}
                                history={pr.history}
                                selected={pr.selected}
                                change={this.changeMenu.bind(this)}
                                user={pr.user}
                                version={pr.version}
                                eventPageBack={this.eventPageBack.bind(this)}
                                showMobileFlyout
                            />
                        </Div>
                    </CSSTransition>
                </Div>
            </CSSTransition>
        )
    }

    styles = {
        flyoutMobileComponent: {
            position: "fixed",
            zIndex: 1000,
            // backgroundColor: "rgba(0,0,0,0.5)",
            top: 50,
            width: "100%",

        },
        flyoutMobileInnerContainer: {
            backgroundColor: "white",
            height: this.props.screen.height - 50,
            width: 250,
            overflowY: "scroll",
            fontSize: 12,
            borderBottom: "1px solid #999",
            position: "absolute",
            filter: "drop-shadow(-2px 0 4px rgba(0,0,0,0.5))",
            // transform: "translateX(250px)",
        },
        logoStyle: {
            cursor: "pointer",
            width: 150,
            height: 24,
            marginRight: "auto",
        },
        mobileHeader: {
            display: "flex",
            alignItems: "center",
            backgroundColor: Colors.purple,
            backgroundImage: "linear-gradient(to right, #313373, #CD197B, #E9775F)",
            height: 50,
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.5))",
            paddingLeft: 10,
            paddingRight: 10,
        },
        roomItems: {
            display: "flex",
            marginRight: 10,
            alignItems: "center",
        },
        roomNotification: {
            position: "absolute",
            alignItems: "center",
            display: "flex",
            top: 43,
            right: -65,
            minWidth: 200,
            padding: 10,
            backgroundColor: "white",
            borderRadius: 8,
        },
        absoluteArrowUp: {
            position: "absolute",
            top: -14,
            right: 62,
            width: 0,
            height: 0,
            borderLeft: "15px solid transparent",
            borderRight: "15px solid transparent",
            borderBottom: "15px solid white",
        },
        notificationCloseButton: {
            position: "absolute",
            top: -4,
            right: 4,
            fontSize: 20,
            color: "#aaa",
        },
        notificationBadgeText: {
            position: "absolute",
            top: 7,
            left: 13,
            fontSize: 9,
            color: Colors.magenta,
        },
    };
}
