import React from 'react';

import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Div from '../components/Div';
import Image from './Image';
import Icon from './Icon';

export default class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemPosition: 0,
            leftoverVisibleItems: -1,
        };

        this.leftScrollPos = undefined;
    }

    componentDidMount() {
        const pr = this.props;

        if(pr.isAnimated) {
            setTimeout(() => {
                $('.carouselOuterContainer').toggleClass('animate');
                setTimeout(() => {
                    let time = 500;
                    $('.carouselItem').each((i, element) => {
                        setTimeout(() => {
                            $(element).toggleClass('floating');
                        }, time += 500);
                    })
                    // $('.carouselItem').toggleClass('floating')
                }, 3500);
            }, 7200);
        }

        $(".carouselContainer").on('scroll', () => {
            let leftScrollDistance = $(".carouselContainer").scrollLeft();
            let itemWidth = $(".carouselItem").outerWidth(true);
            let newItemPosition = Math.floor(leftScrollDistance / itemWidth);

            if(leftScrollDistance % itemWidth > 0) { // NOTE: if there is a remainder from the scroll window width, you hit the wall
                newItemPosition++;
            }

            if(leftScrollDistance == 0) { // NOTE: hit the wall
                newItemPosition = 0;
            }

            this.setState({
                itemPosition: newItemPosition,
            });
        });
    }

    itemPositionChange(scrollDirection) {
        const pr = this.props;
        const st = this.state;

        const isLeft = scrollDirection == -1;
        const isRight = scrollDirection == 1;

        const totalItems = pr.items ? pr.items.length : pr.children.length;

        let carouselContainerWidth = $(".carouselContainer").width();
        let carouselItemWidth = $(".carouselItem").outerWidth(true);

        let visibleItems = Math.floor(carouselContainerWidth / carouselItemWidth);
        let leftoverVisibleItems = totalItems - visibleItems;

        this.setState({
            leftoverVisibleItems: leftoverVisibleItems,
        });

        let leftScrollPos = $('.carouselContainer').scrollLeft();

        let hitWall = false;

        if(st.itemPosition >= leftoverVisibleItems && isRight) {
            hitWall = true;
        }

        if((totalItems - st.itemPosition) < visibleItems && isLeft) {
            hitWall = true;
        }

        if(st.itemPosition == 0 && isLeft) {
            hitWall = true;
        }

        // console.log("direction >>>", scrollDirection);
        // console.log("leftoverVisibleItems >>>", leftoverVisibleItems);
        // console.log("st.itemPosition INTIIAL >>>", st.itemPosition);
        // console.log("hitWall >>>", hitWall);

        let newItemPosition = -1;
        if(hitWall) {
            if(isRight) {
                leftScrollPos = -1 * (carouselItemWidth);
                newItemPosition = 0;
            } else {
                leftScrollPos = 10000; // NOTE: scroll to end
                newItemPosition = leftoverVisibleItems;
            }
        } else {
            newItemPosition = (st.itemPosition + scrollDirection) % totalItems;
        }

        this.setState({itemPosition: newItemPosition}, () => {
            // console.log("st.itemPosition AFTER >>>", this.state.itemPosition);
            // console.log("<<<<<<< END >>>>>>>>");
            // console.log(">>>");
        });

        this.leftScrollPos = leftScrollPos;
        $(".carouselContainer").animate({scrollLeft: this.leftScrollPos + (scrollDirection * carouselItemWidth)}, 300);
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let size = Utils.get(pr, "size", 150);
        // if(pr.screen.mobileLandscape) {
        //     size = 120;
        // } else if(pr.screen.mobile) {
        //     size = 80;
        // }
        const tempUrl = "";
        const imagePath = Utils.get(pr, "imagePath", "image_url");

        const displayNone = pr.hideArrows && !pr.screen.mobile ? {visibility: "hidden"} : null;

        return (
            <Div className="carouselOuterContainer" style={sty.carouselOuterContainer}>
                <Div className="leftArrow" style={{...sty.leftArrow, ...StylesPlatform.cursorPointer, ...displayNone}} onClick={this.itemPositionChange.bind(this, -1)}>
                    <Icon
                        icon="chevron-left"
                        color="white"
                    />
                </Div>

                <Div className="carouselElement" style={{...sty.carouselElement, ...pr.style}}>
                    {st.itemPosition === 0 ?
                        <Div style={{...sty.gradientOverlayLeft, ...pr.gradientStyle}}></Div>
                        :
                        null
                    }

                    <Div className="carouselContainer" style={{...sty.carouselContainer, ...pr.style}}>
                        {pr.children}

                        {pr.items ?
                            pr.items.map((item, index) => {
                                return (
                                    <Div
                                        key={`carouselItem-${index}`}
                                        className="carouselItem"
                                        style={{...sty.carouselItem, ...pr.carouselItemStyle, ...{height: size, width: size}, ...{backgroundImage: `url("${Utils.get(item, imagePath, tempUrl)}")`}, ...StylesPlatform.cursorPointer}}
                                        onClick={pr.itemClick.bind(this, item, index)}
                                    >
                                    </Div>
                                )
                            })
                            :
                            null
                        }
                    </Div>

                    {st.itemPosition === st.leftoverVisibleItems ?
                        <Div style={{...sty.gradientOverlayRight, ...pr.gradientStyle}}></Div>
                        :
                        null
                    }
                </Div>
                <Div className="rightArrow" style={{...sty.rightArrow, ...StylesPlatform.cursorPointer, ...displayNone}} onClick={this.itemPositionChange.bind(this, 1)}>
                    <Icon
                        icon="chevron-right"
                        color="white"
                    />
                </Div>
            </Div>
        );
    }

    styles = {
        carouselOuterContainer: {
            display: "flex",
            // width: "100%",
            // boxSizing: "border-box",
            // justifyContent: "center",
            alignItems: "center",
        },
        carouselElement: {
            position: "relative",
            backgroundColor: Colors.plum,
            width: "calc(100% - 100px)",
            borderRadius: 20,
            marginLeft: 20,
            marginRight: 20,
        },
        carouselContainer: {
            display: "flex",
            overflow: "scroll",
            paddingTop: 20,
            paddingBottom: 20,
            // "overflow-x": "hidden",
        },
        carouselItem: {
            height: 150,
            width: 150,
            margin: 10,
            flexShrink: 0,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 15,
        },
        leftArrow: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
            paddingLeft: 8,
            paddingRight: 10,
            backgroundColor: Colors.magenta,
            flexShrink: 0,
            borderRadius: 10,
        },
        rightArrow: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
            paddingLeft: 10,
            paddingRight: 8,
            backgroundColor: Colors.magenta,
            flexShrink: 0,
            borderRadius: 10,
        },
        gradientOverlayLeft: {
            position: "absolute",
            // backgroundColor: "rgba(0,0,0,0.7)",
            background: "linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%)",
            height: "100%",
            width: 50,
            left: 0,
            top: 0,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
        },
        gradientOverlayRight: {
            position: "absolute",
            // backgroundColor: "rgba(0,0,0,0.7)",
            background: "linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%)",
            height: "100%",
            width: 50,
            right: 0,
            top: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
        },
    };
}
