import React, { useEffect, useCallback, useState } from 'react';
import Modal from '../elements/Modal';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

// Elements
import Div from './Div';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import BorderSeparator from '../elements/BorderSeparator';
import { 
    fetchStripePaymentMethods, 
    getPaymentMethodIconSrc,
    handleDeletePaymentMethod,
    handleBorder,
} from '../elements/PaymentsV2';
import StripePayment from '../elements/StripePayment';

const getSetupIntent = (setSetupIntent, errorCallBack) => {
    Api.postStripeSetupIntent({}, (o) => {
        if (o && o.errors.length > 0) {
            // tell user there was an error
            const error = o.errors[0].message
            if (errorCallBack) {
                errorCallBack(error)
            }
            return
        }
        // success
        const stringInfo = o.data
        if (setSetupIntent) {
            setSetupIntent(stringInfo)
        }
    });
}

const NewPaymentMethodModal = (props) => {
    const { modalProps, setShowNewPaymentMethodModal, handleFetchStripePaymentMethods } = props;
    const modalHeight = modalProps.newPaymentMethodModalHeight || "80vH"// 350 was from Post.js but increase here

    const [stripeRendered, setStripeRendered] = useState(false);
    const [setupIntent, setSetupIntent] = useState(false);
    const [messageObject, setMessageObject] = useState({
        stripe: "",
    })

    useEffect(() => {
        if (!stripeRendered) {
            // fetch payment methods and set first as selected per payment intent by default
            getSetupIntent(setSetupIntent, (error) => {
                setMessageObject({stripe: error || "System error. Please contact support"})
            })

            // prevent side effects
            setStripeRendered(true)
        }
    }, [])

    return (
        <Modal
            screen={modalProps.screen}
            className="postModalBody"
            onRequestClose={() => setShowNewPaymentMethodModal(false)}
            color="indigo"
            style={{
                maxWidth: 500, 
                backgroundColor: modalProps.isDragon ? Colors.dragon : Colors.indigo,
                height: modalHeight,
            }}
        >
            <Div style={{
                height: "100%",
                width:"100%",
                borderRadius:12,
                display: "flex", 
                flexDirection: "column", 
                overflow: 'auto',
                justifyContent: 'flex-start',
                gap: '10px'
            }}>
                <Div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                }}>
                    {modalProps.title && (
                        <Div style={{fontSize: 24}}>
                            {modalProps.title}
                        </Div>
                    )}
                    {modalProps.subtitle && (
                        <Div style={{fontSize: 18}}>
                            {modalProps.subtitle}
                        </Div>
                    )}
                </Div>
                {setupIntent ? (
                    <StripePayment
                        style={{width:300}}
                        stripeMode="prod"
                        stripeInfo={setupIntent}
                        version="V4"
                        type="setup"
                        paymentSuccess={(intent) => {
                            handleFetchStripePaymentMethods()
                            setShowNewPaymentMethodModal(false)
                        }}
                    />
                ) :
                    null
                }
                <Div style={{
                    color: Colors.redError
                }}>{messageObject.stripe}</Div>
            </Div>
        </Modal>
    )
}

const DeletePaymentMethodModal = (props) => {
    const { modalProps, setShowDeletePaymentMethodModal, handleDeletePaymentMethod } = props;
    const modalHeight = modalProps.height || "50vH"

    return (
        <Modal
            screen={modalProps.screen}
            className="postModalBody"
            // per design
            hideCloseButton={true}
            color="indigo"
            style={{
                maxWidth: 500, 
                backgroundColor: modalProps.isDragon ? Colors.dragon : Colors.indigo,
                height: modalHeight,
            }}
        >
            <Div style={{
                height: "100%",
                width:"100%",
                borderRadius:12,
                display: "flex", 
                flexDirection: "column", 
                overflow: 'auto',
                justifyContent: 'flex-start',
                gap: '10px'
            }}>
                <Div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                }}>
                    {modalProps.title && (
                        <Div style={{fontSize: 24, textAlign: 'center'}}>
                            {modalProps.title}
                        </Div>
                    )}
                    {modalProps.subtitle && (
                        <Div style={{fontSize: 18}}>
                            {modalProps.subtitle}
                        </Div>
                    )}
                </Div>
                <Div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    width: '100%',
                    gap: '10px'
                }}>
                    <Button
                        color="pinkGradient"
                        style={{ marginTop: 20, width: "100%", maxWidth: '100%' }}
                        onClick={() => {
                            setShowDeletePaymentMethodModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="outlined"
                        style={{ width: "100%", maxWidth: '100%' }}
                        onClick={() => {
                            handleDeletePaymentMethod()
                            setShowDeletePaymentMethodModal(false)
                        }}
                    >
                        Delete
                    </Button>
                </Div>
            </Div>
        </Modal>
    )
}

const ManagePayments = (props) => {
    // States
    const [showNewPaymentMethodModal, setShowNewPaymentMethodModal] = useState(false);
    const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(false);
    const [selectedToDeletePaymentMethod, setSelectedToDeletePaymentMethod] = useState(null);
    const [selectedToDeletePaymentMethodData, setSelectedToDeletePaymentMethodData] = useState(null);
    const [stripeRendered, setStripeRendered] = useState(false);
    const [messageObject, setMessageObject] = useState({
        stripe: "",
        stripePaymentMethod: {
            'idxxxx': ''
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const handleFetchStripePaymentMethods = () => {
        fetchStripePaymentMethods(setPaymentMethods, null, (error) => {
            setMessageObject({stripe: error || "System error. Please contact support"})
        })
    }

    useEffect(() => {
        if (!stripeRendered) {
            // fetch payment methods and set first as selected per payment intent by default
            fetchStripePaymentMethods(setPaymentMethods, null, (error) => {
                setMessageObject({stripe: error || "System error. Please contact support"})
            })

            // prevent side effects
            setStripeRendered(true)
        }
    }, [])
    
    if (showNewPaymentMethodModal) {
        return (
            <NewPaymentMethodModal 
                setShowNewPaymentMethodModal={setShowNewPaymentMethodModal} 
                handleFetchStripePaymentMethods={handleFetchStripePaymentMethods} 
                {...props} 
            />
        )
    }

    if (showDeletePaymentMethodModal) {
        const { title, last4, nameOnCard } = selectedToDeletePaymentMethodData
        return (
            <DeletePaymentMethodModal 
                setShowDeletePaymentMethodModal={setShowDeletePaymentMethodModal} 
                selectedToDeletePaymentMethod={selectedToDeletePaymentMethod}
                handleDeletePaymentMethod={() => {
                    handleDeletePaymentMethod({
                        paymentMethod: selectedToDeletePaymentMethod,
                        setIsLoading, 
                        setMessageObject, 
                        setPaypalRendered: null,
                        setDisableCompletePaymentButton: null,
                        setPaymentMethods,
                    })
                }} 
                {...props} 
                modalProps={{
                    ...props.modalProps, 
                    title: 'Are you sure you want to delete this payment method',
                    subtitle: `${title}: ${nameOnCard || last4}`
                }}
            />
        )
    }

    const styles = {
        centerPosition: {
            width: '50%',
            margin: "auto"
        },
        centerPosition_sm: {
            width: "100%",
        },
    }

    const centerPosition = StyleUtils.getMStyle(styles, "centerPosition", props.modalProps.screen.width, "sm");

    return (
        <Div>
            <Div style={{marginTop: 20}}>Payment Methods</Div>
            <Div style={{...centerPosition, paddingBottom: 20}}>
                {/* TODO: consider consolidating with PaymentsV2 implementation if possible */}
                {paymentMethods && paymentMethods.map((v, i) => {
                    // e.g. type = 'card'
                    const { type, id } = v
                    const paymentMethodData = getPaymentMethodIconSrc(type, v)
                    const { title, src, last4, nameOnCard } = paymentMethodData
                    const paymentMethodError = Utils.get(messageObject.stripePaymentMethod, id)
                    
                    return (
                        <Div key={`${i}-${type}-${id}`}>
                            <Div 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '20px',
                                    borderRadius: '16px',
                                    margin: '10px 0',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                    border: handleBorder(paymentMethodError),
                                    borderRadius: '20px',
                                }}
                            >
                                <Image
                                    className="paymentMethodImage"
                                    src={src}
                                    style={{width: "45px", height: "45px"}}
                                />
                                <Div style={{
                                        width: '100%',
                                        marginLeft: '20px',
                                }}>
                                    <Div style={{
                                        fontSize: '18px',
                                    }}>{title}</Div>
                                    {last4 && (
                                        <Div>****{last4}</Div>
                                    )}
                                    {nameOnCard && (
                                        <Div>{nameOnCard}</Div>
                                    )}
                                </Div>
                                <Button
                                    type="text"
                                    disabled={isLoading}
                                    style={{ color: Colors.red }}
                                    onClick={() => {
                                        setSelectedToDeletePaymentMethod(v)
                                        setSelectedToDeletePaymentMethodData(paymentMethodData)
                                        setShowDeletePaymentMethodModal(true)
                                    }}
                                >
                                    Delete
                                </Button>
                            </Div>
                            <Div style={{color: paymentMethodError ? Colors.redError : 'inherit'}}>{paymentMethodError}</Div>
                        </Div>
                    )
                })}
                <Div style={{
                    color: Colors.redError
                }}>{messageObject.stripe}</Div>
            </Div>
            <BorderSeparator color="indigoLighter" style={{height: 1}}/>
            <Button
                color="pinkGradient"
                disabled={isLoading}
                style={{...centerPosition, marginTop: 20, marginBottom: 20 }}
                onClick={() => {
                    setShowNewPaymentMethodModal(true)
                }}
            >
                Add New Payment Method
            </Button>
        </Div>
    )
}

export default ManagePayments;
