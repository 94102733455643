import React from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';

import Div from '../components/Div';

export default class Collapsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
    }

    getButtonColors() {
        const pr = this.props;
        const sty = this.styles;

        if(pr.size == "text") {
            switch(pr.color) {
                case "white":
                    return sty.whiteTextButton;
                default:
                    return sty.purpleTextButton;
            }
        }

        switch(pr.color) {
            case "pink":
                return sty.pinkButton;
            case "red":
                return sty.redButton;
            case "transparentPinkButton":
                return sty.transparentPinkButton;
            case "white":
                return sty.whiteButton;
            case "black":
                return sty.blackButton;
            case "purple":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purple}};
            case "purpleTextWhiteButton":
                return sty.purpleTextWhiteButton;
            case "purpleLight1":
                return sty.purpleButton;
            case "purpleLight2":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight2}};
            case "purpleLight3":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight3, color: "black"}};
            case "purpleLight4":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight4, color: "black"}};
            case "purpleLight5":
                return {...sty.purpleButton, ...{backgroundColor:Colors.purpleLight5, color: "black"}};
            default:
                return sty.pinkButton;
        }
    }

    toggleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        // const buttonColors = this.getButtonColors();
        // const buttonSize = this.getButtonSize();

        // let buttonStyles = {...sty.button, ...StylesPlatform.cursorPointer, ...buttonColors, ...buttonSize};

        // const disabledStyle = pr.disabled ? sty.disabled : null;

        return (
            <Div
                style={{...sty.collapsible, ...pr.style}}
                className={`collapsibleElement ${Utils.get(pr, "className", "")}`}
            >
                <Div
                    className="collapsibleTitleContainer"
                    style={sty.collapsibleTitleContainer}
                >
                    <Div
                        className="collapsibleTitle"
                        style={sty.collapsibleTitle}
                    >
                        {pr.title}
                    </Div>
                    <Div
                        className="collapsibleIcon"
                        style={{...sty.collapsibleIcon, ...StylesPlatform.cursorPointer}}
                        onClick={this.toggleCollapse.bind(this)}
                    >
                        {st.collapsed ?
                            "^"
                            :
                            "$"
                        }
                    </Div>
                </Div>

                {st.collapsed ?
                    null
                    :
                    <Div
                        style={sty.collapsibleBody}
                    >
                        {pr.children}
                    </Div>
                }
            </Div>
        );
    }

    styles = {
        collapsible: {
            width: "100%",
            // backgroundColor: Colors.black,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            borderRadius: 40,
            paddingTop: 10,
            paddingRight: 20,
            paddingBottom: 10,
            paddingLeft: 20,
        },
        collapsibleTitleContainer: {
            display: "flex",
            justifyContent: "space-between",
        },
        collapsibleTitle: {
            color: "white",
            marginRight: 50,
            fontSize: 14,
        },
        collapsibleIcon: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 16,
            height: 16,
            borderRadius: 100,
            backgroundColor: Colors.red,
        },
        collapsibleBody: {
            marginTop: 10,
            fontSize: 10,
        },

        // Button colors
        pinkButton: {
            backgroundColor: Colors.magenta,
            color: "white",
        },
        transparentPinkButton: {
            backgroundColor: "rgba(201, 45, 122 ,0.5)",
            color: "white",
        },
        whiteButton: {
            backgroundColor: "white",
            color: Colors.pink,
            border: this.props.noBorder ? "none" : "1px solid #ccc",
        },
        blackButton: {
            backgroundColor: "black",
            color: "white",
        },
        purpleButton: {
            backgroundColor: Colors.purpleLight1,
            color: "white",
        },
        purpleTextWhiteButton: {
            backgroundColor: "white",
            color: Colors.purpleLight1,
        },
        whiteTextButton: {
            color: "white",
        },
        purpleTextButton: {
            color: Colors.purple,
        },
        redButton: {
            backgroundColor: Colors.red,
        },
    };
}
