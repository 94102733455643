import React from 'react';
import Lottie from "lottie-react"
// import Lottie from "lottie-react-web"
import checkboxAnimation from "../assets/52645-checkmark.json"

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';

import Icon from './Icon';

import Div from '../components/Div';

export default class PanelQa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPanelOpen: false,
        };
    }

    togglePanel() {
        const pr = this.props;

        this.setState({
            isPanelOpen: !this.state.isPanelOpen
        });

        if(pr.togglePanel) {
            pr.togglePanel();
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        let panelQaHeaderStyle = Utils.deepCopy(sty.panelQaHeader);
        let panelQaElementStyle = Utils.deepCopy(sty.panelQaElement);

        if(st.isPanelOpen) {
            panelQaHeaderStyle.paddingBottom = 20;
            // panelElementStyle.border = `2px solid ${Colors.magenta}`;
            // panelQaElementStyle.boxShadow = `inset 0px 0px 0px 2px ${Colors.magenta}`;
            panelQaElementStyle.borderBottomRightRadius = 0;
            panelQaElementStyle.borderBottomLeftRadius = 0;
        } else {
            panelQaHeaderStyle.paddingBottom = 15;
            panelQaElementStyle.border = "none";
            panelQaElementStyle.borderBottomRightRadius = 14;
            panelQaElementStyle.borderBottomLeftRadius = 14;

        }

        if(pr.screen.mobile) {
            panelQaHeaderStyle.paddingLeft = 25;
            panelQaHeaderStyle.paddingRight = 25;
        } else {
            panelQaHeaderStyle.paddingLeft = 35;
            panelQaHeaderStyle.paddingRight = 35;
        }

        const arrowDropdown = StyleUtils.getMediaStyle('arrowDropdown', sty.arrowDropdown, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const panelQaContainer = StyleUtils.getMediaStyle('panelQaContainer', sty.panelQaContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="panelQaElement" style={{...panelQaElementStyle, ...pr.style, ...StylesPlatform.cursorPointer}} onClick={this.togglePanel.bind(this)}>
                <Div className="panelQaHeader" style={panelQaHeaderStyle}>
                    <Div className="panelQaTitle" style={sty.panelQaTitle}>
                        {pr.title}
                    </Div>

                    <Div
                        className="arrowDropdown"
                        style={{...arrowDropdown, ...StylesPlatform.cursorPointer}}
                        onClick={this.togglePanel.bind(this)}
                    >
                        {st.isPanelOpen ?
                            <Icon icon="chevron-down" color={Colors.magenta} size={pr.screen.mobile ? 18 : 24} />
                            :
                            <Icon icon="chevron-right" color={Colors.magenta} size={pr.screen.mobile ? 18 : 24} />
                        }
                    </Div>
                </Div>
                {/* {st.isPanelOpen ?
                    <Div className="panelQaContainer" style={panelQaContainer}>
                        {st.isPanelOpen}
                        {pr.children ?
                            pr.children
                            :
                            pr.description
                        }
                    </Div>
                    :
                    null
                } */}
            </Div>
        );
    }

    styles = {
        panelQaElement: {
            borderRadius: 14,
            overflow: "hidden",
            color: Colors.splashDarkPurple,
            fontFamily: "Nyata",
            overflow: "hidden",
            backgroundColor: "white",
            boxSizing: "border-box",
        },
        panelQaHeader: {
            display: "flex",
            alignItems: "center",
            padding: 35,
        },
        panelQaTitle: {
            fontSize: 23,
            fontWeight: 400,
            flex: 1,
            paddingRight: 15,
        },
        arrowDropdown: {
            width: 50,
            height: 50,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            filter: "drop-shadow(0 4px 6px #ddd)",
            backgroundColor: "white",
        },
        arrowDropdown_md: {
            width: 30,
            height: 30,
        },
        panelQaContainer: {
            // backgroundColor: Colors.purpleLightBackground,
            paddingTop: 0,
            paddingRight: 120,
            paddingBottom: 35,
            paddingLeft: 35,
            fontSize: 21,
            fontWeight: 400,
            color: "#7B7B7B"
        },
        panelQaContainer_md: {
            paddingLeft: 25,
            paddingRight: 58,
            paddingTop: 14,
        },
    };
}
