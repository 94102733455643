import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import Errors from '../modules/Errors';
import Validate from '../modules/Validate';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import RegisterFlow from './RegisterFlow';
import DebugBox from '../elements/DebugBox';
import Input from '../elements/Input';
import MetaMask from '../elements/MetaMask';

import '../App.css';

import Div from '../elements/Div';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';

import ConnectWallet from './ConnectWallet';

import backgroundImage from "../assets/images/grid_background.jpg"
import backgroundImageMobile from "../assets/images/ap_v4_platform_mobile.jpg"

export default class Tickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            ticketNum: 1,
            emailTo: '',
            testMode: Utils.getRequest('test', 0),
            showUltraHeader: Utils.getRequest('ultra') ? true : false,
            mode: this.props.mode,
            showMinting: {},
            firstLoad: true,
            loading:false,
            tag: "",
            mintingOpen: false,
            accounts: false,
            metamaskWalletId: false,
            nfts: [], // Utopians
            mintpasses: [],
            festivalpasses: [],
            emailList: {},
            emailTest: '',
            shortRender: Utils.getRequest('short') ? parseInt(Utils.getRequest('short')) : false,
            passes: [],
        };
        /*
        let walletId = '';
        if(window.ethereum && window.ethereum.isMetaMask) {
            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            window.ethereum.request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                ll._("Attaching metamask wallets", accounts);
                this.setState({wallet:accounts[0], loading:true,});
                this.loadItems(accounts[0]);
            });
        }
        */
        //Utils.activateLogger();

    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        if(!this.state.loading) {
            this.setState({firstLoad:false});
        }

        this.setState({selected: pr.route ? pr.route : 'afterparty'}, () => {
            this.handleRoute(st.selected);
        });
    }

    handleRoute(sel) {

    }

    handleMetaMaskChange(changeType, data) {
        const pr = this.props;

        console.log("MM", changeType, data);
        if(changeType == 'accounts') {
            const metamaskWalletId = data[0];
            this.setState({accounts: data, metamaskWalletId: metamaskWalletId});
            pr.setMetamaskWalletId(metamaskWalletId);
            this.loadItems(metamaskWalletId);
        }
    }

    loadItems(walletId) {
        if(!walletId) {
            console.log("No wallet");
            return;
        }
        console.log("loadItems", walletId);
        var extraParams = {
            test: this.state.testMode,
            wallet: walletId,
            ticketType: this.state.mode == 'ticket' ? 1 : 2,
        };
        this.setState({loading:true});
        const crc = Utils.getUrlCrc();
        Api.getNfts(crc, (o) => {
            //console.log("Result", o, Utils.get(o, "data.utopians", []));
            const mintingOpen = Utils.get(o, "data.mintingOpen");
            const success = Utils.get(o, "success");
            if(success == 1) {
                var tempState = {
                    nfts: Utils.get(o, "data.passes.Afterparty Utopians", []),
                    mintpasses: Utils.get(o, "data.passes.Cartons", []),
                    festivalpasses: Utils.get(o, "data.passes.MV3", []),
                    tag: Utils.get(o, "system.tag", ""),
                    firstLoad:false,
                    loading: false,
                    mintingOpen: mintingOpen,
                };
                if(this.state.shortRender) {
                    const short = parseInt(this.state.shortRender);
                    tempState['nfts'] = tempState['nfts'].slice(0,short);
                    tempState['mintpasses'] = tempState['mintpasses'].slice(0,short);
                    tempState['festivalpasses'] = tempState['festivalpasses'].slice(0,short);
                }
                for(var idx in tempState.nfts) {
                    let nft = tempState.nfts[idx];
                    let passes = Utils.get(nft, "passes");
                    const extraPassesCount = 2 - passes.length;
                    for(var i=0;i<extraPassesCount;i++) {
                        passes.push({
                            "num":Math.floor(Math.random() * 10000),
                            "metadata":Utils.get(nft, "token_metadata"),
                            "image_url":Utils.get(nft, "image_url")
                        });
                    }
                }
                for(var idx in tempState.mintpasses) {
                    let nft = tempState.mintpasses[idx];
                    let passes = Utils.get(nft, "passes");
                    const extraPassesCount = 2 - passes.length;
                    for(var i=0;i<extraPassesCount;i++) {
                        passes.push({
                            "num": Math.floor(Math.random() * 10000),
                            "metadata": Utils.get(nft, "token_metadata"),
                            "image_url": Utils.get(nft, "image_url")
                        });
                    }
                }
                for(var idx in tempState.festivalpasses) {
                    let nft = tempState.festivalpasses[idx];
                    let passes = Utils.get(nft, "passes");
                    const extraPassesCount = 1 - passes.length;
                    for(var i=0;i<extraPassesCount;i++) {
                        passes.push({
                            "num": Math.floor(Math.random() * 10000),
                            "metadata": Utils.get(nft, "token_metadata"),
                            "image_url": Utils.get(nft, "image_url")
                        });
                    }
                }


                this.setState(tempState);
            } else {
                this.setState({loading: false, mintingOpen: mintingOpen});
            }
        }, extraParams);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';

        if(this.state.selected != selected) {
            this.setState({selected: selected});
        }
    }

    newTab(link) {
        window.open(link, "_blank");
        //this.props.history.push(`/${link}`);
    }

    changeVal(valPath, event) {
        //console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        this.setState(tempState);
    }

    onClickSocialMediaIcon(socialIcon) {
        switch(socialIcon) {
            case "twitter":
                window.open("https://www.twitter.com/afterparty", "_blank");
                break;
            case "discord":
                window.open("https://discord.gg/afterpartyNFT", "_blank");
                break;
            case "instagram":
                window.open("https://www.instagram.com/afterparty", "_blank");
                break;
            default:
        }
    }


    putTicket(obj, nft, walletId, ticketType, signature, emailTo, cmd) {
        //console.log("TICKET", obj);
        var data = {
            cmd: cmd,
            ticketType: ticketType,
            selectedWallet: walletId,
        }

        //let temp = this.state.showMinting;
        //temp[obj['num']] = 1;
        //this.setState({showMinting: temp});
        const id = Utils.get(obj, "id");
        Api.putCustomerTicket(id, data, (o) => {
            if(Api.noErrors(o)) {
                alert("Resent pass.");
            }

        });
    }

    postTicket(ticket, nft, walletId, ticketType, signature, emailTo, plusOne) {
        let obj = ticket;
        console.log("NFT", nft);
        var metadata = Utils.get(ticket, 'metadata', '');
        if(ticketType == 2) {
            console.log("mintNftPass", ticket, metadata, Utils.get(nft, 'contract_id'));
        } else {
            console.log("generateQRTicket", ticket, metadata, Utils.get(nft, 'contract_id'));
        }
        const nftType = Utils.get(nft, 'contract_symbol')
        var idParts = metadata.split('/');
        if(idParts.length < 2) {
            alert("Missing metadata: "+JSON.stringify(idParts));
            return;
        }
        let collection_index = parseInt(idParts[idParts.length-2]);
        if(!collection_index) {
            if(nftType == 'MV3NFT') {
                collection_index = 18;
            } else if(nftType == 'LYRICAL') {
                collection_index = 19;
            }
        }

        const collection_item_index = idParts[idParts.length-1];
        //console.log("TICKET", obj, metadata);

        var data = {
            test: this.state.testMode,
            ticketType: ticketType,
            metadata: metadata,  //'https://nft.afterparty.ai/nft_metadata/1/13'
            //selectedWallet: walletId,
            selectedWallet: Utils.getRequest('wallet', walletId),
            collection_index: parseInt(collection_index),
            collection_item_index: nft['token_id'],
            symbol: nftType,
            contract_id: Utils.get(nft, 'contract_id'),
            image_url: Utils.get(obj, 'image_url', ''),
            signature: signature,
            emailTo: emailTo ? emailTo : '',
            plusOne: plusOne ? 1 : 0,
        }

        let temp = this.state.showMinting;
        temp[obj['num']] = 1;
        this.setState({showMinting: temp});

        Api.postCustomerTicket(data, (o) => {
            if(o.success == 1) {
                let temp = this.state.showMinting;
                console.log("o", o);
                temp[obj['num']] = o.data;
                const showEmailTo = emailTo ? emailTo : Utils.get(this.props, "user.email");

                if(this.state.mode == 'minting') {
                    this.setState({showMinting: temp, showMintedModal:true, emailTo:showEmailTo, ticketNum:Utils.get(o, "data.id")});
                } else {
                    this.setState({showMinting: temp, showSentModal:true, emailTo:showEmailTo, ticketNum:Utils.get(o, "data.id")});
                }
                this.loadItems(this.state.metamaskWalletId);
            } else {
                alert("Error minting. "+Errors.getErrorMessages(o.errors)+". Please try again later");
                //temp[obj['num']] = 0;
                //this.setState({showMinting: temp});
            }
        });

    }

    handleClickSendPass(ticket, nft, emailTo, plusOne) {
        let ticketType = 1;
        const nftType = Utils.get(nft, 'contract_symbol')
        //if(nftType == 'APU' || nftType == 'APMP') {
        //   ticketType = 3;
        //}
        //console.log("emailTo", emailTo, ticketType);
        const validate = Validate.email(emailTo);
        //console.log(validate);
        if(validate.errors.length > 0) {
            alert(validate.errors[0].message);
            return;
        }
        const walletId = this.state.metamaskWalletId;
        const signature = 'None';
        //console.log(nft, Utils.get(nft, 'contract_id'));
        this.postTicket(ticket, nft, walletId, ticketType, signature, emailTo, plusOne);
    }

    clickResend(obj, nft, walletId, ticketType, signature2, emailTo) {
        //console.log("clickResend", ticket);
        const signature = 'None';
        const cmd = 'resend';
        const walletAddress = Utils.getRequest('wallet', this.state.metamaskWalletId);
        this.putTicket(obj, nft, walletAddress, 1, signature, emailTo, cmd);

        //this.setState({ticketNum:Utils.get(ticket, "id"), showSentModal:true, emailTo:Utils.get(this.props, "user.email")});
    }

    clickInvalidate(ticket) {
        console.log("clickInvalidate", ticket);
        //this.setState({ticketNum:Utils.get(ticket, "id"), showSentModal:true, emailTo:Utils.get(this.props, "user.email")});
    }

    mintNftPass(obj, nft) {
        var message = "Please confirm you want to verify minting the Afterparty Pass from this wallet ("+this.state.tag+")";
        if(!window.ethereum) {
            alert("Please activate Metamask");
            return;
        }
        const walletId = this.state.metamaskWalletId;
        window.ethereum.sendAsync({method: 'personal_sign', params: [walletId, message], from: walletId}, (err, result) => {
            //console.log('signature', result.result)
            if(result.result) {
                this.postTicket(obj, nft, window.ethereum.selectedAddress, 2, result.result);
            }
        });

        return;
    }


    handleBuyModalClose(o) {
        //console.log("close");
        this.setState({showSentModal:false});
    }

    handleMintModalClose(o) {
        //console.log("close");
        this.setState({showMintedModal:false});
    }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "username"
        });
    }

    onRequestRegisterModalClose() {
        this.setState({
            showRegisterFlowModal: false,
            registerModalBody: "username",
            errorMessages: [],
            rsvpModalMessage: "",
        });
    }

    renderNftPass(ticket, nft) {
        const key = "t-" + Utils.get(ticket, "id", "") + Math.floor(Math.random() * 10000);
        let showMintingData = this.state.showMinting[ticket['num']];
        const txHash = Utils.get(ticket, 'tx_id');
        if(!showMintingData && txHash) {
            showMintingData = txHash;
        }
        //const mintURL = "https://mumbai.polygonscan.com/tx/"+showMintingData
        //https://rinkeby.etherscan.io/tx/0xbb1fda0d30c04652b4c78f3a24761be9fe2c1f39cb420d42f33075f731df5fa2
        //https://etherscan.io/address/0x862a7BBEE9C9Ec010069571C73ba8e6Cd0769251#readContract
        //console.log("ticket", );
        let mintURL;
        if(showMintingData) {
            mintURL = this.state.testMode ? "https://rinkeby.etherscan.io/tx/"+showMintingData : "https://etherscan.io/tx/"+showMintingData;
        }

        return (
            <Div key={key} style={{background:"rgba(127,127,127,0.3)",borderRadius:8,margin:10,padding:10}}>
                <Div style={{margin:6}}>Afterparty Pass</Div>
                { Utils.get(ticket, "id") ?
                    Utils.get(ticket, "status") == 2 ?
                        <Div>Used</Div>
                        :
                        Utils.get(ticket, "status") == 3 ?
                            <Div>Previously Owned</Div>
                            :
                            mintURL ?
                                <Div>Minted. <a target='_blank' style={{color:"white"}} href={mintURL}>Show Transaction</a></Div>
                                :
                                <Div>Minting... Please wait.</Div>
                    :
                    !showMintingData ?
                        <Button onClick={this.mintNftPass.bind(this,ticket, nft)} style={{width:"100%"}} size="small" color="white">Mint Afterparty Pass</Button>
                        :
                        showMintingData == 1 ?
                            <Div>
                                <Div>
                                    Minting... this process can take up to a minute.
                                </Div>
                                <Div style={{padding:4, margin:8, marginTop:12, border:"2px solid orange", borderRadius:8,}}>
                                    Please Do Not Refresh Your Browser
                                </Div>
                            </Div>
                            :
                            <Div>Minted. <a target='_blank' style={{color:"white"}} href={mintURL}>Show Transaction</a></Div>
                }
            </Div>
        );
    }

    renderQrPass(ticket, nft, plusOne) {
        const num = Utils.get(ticket, "num");
        const key = "t2-" + Utils.get(ticket, "id") + num;
        const placeholder = "Enter email...";
        let panelTitle = "Enter Your Email"
        if(plusOne) {
            panelTitle = "Enter +1 Email"
        }
        const sending = this.state.showMinting[num] == 1;
        return (
            <Div key={key} style={{background:"rgba(127,127,127,0.3)",borderRadius:8,margin:10,padding:10}}>
                <Div style={{margin:6}}>{panelTitle}</Div>
                { Utils.get(ticket, "id") ?
                    Utils.get(ticket, "status") == 2 ?
                        <Div>Used</Div>
                        :
                        Utils.get(ticket, "status") == 3 ?
                            <Div>Previously Owned</Div>
                            :
                            <Div>
                                <Button onClick={this.clickResend.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''))} color="purpleLight1" style={{width:"100%",marginBottom:12}} size="small">
                                    Resend Pass <i className="fa fa-retweet" style={{marginLeft:10, fontSize:13}} />
                                </Button>
                                { false ?
                                    <Button onClick={this.clickInvalidate.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''))} color="black" style={{width:"100%"}} size="small">
                                        Invalidate Pass <i className="fa fa-times" style={{marginLeft:10, fontSize:13}} />
                                    </Button>
                                    :
                                    null
                                }
                            </Div>
                    :
                    <Div>
                        <Input
                            value={Utils.get(this.state, `emailList.${num}`, '')}
                            onChange={this.changeVal.bind(this, `emailList.${num}`)}
                            style={{width:"100%", paddingTop:8,paddingBottom:8,}}
                            placeholder={placeholder}
                            />
                        <Button
                            onClick={this.handleClickSendPass.bind(this, ticket, nft, Utils.get(this.state, `emailList.${num}`, ''), plusOne)}
                            disabled={sending}
                            style={{width:"100%"}}
                            size="small" >
                            { sending ?
                                <span>Send Pass...</span>
                                :
                                <span>Send Pass <i className="fa fa-envelope" style={{marginLeft:10, fontSize:13}} /></span>
                            }

                        </Button>
                    </Div>
                }
            </Div>
        );

    }

    renderNftTile(nft, nftTicketCount) {
        if(!nft) {
            console.log("No NFT tile to render");
            return;
        }
        if(nft['token_id'] == 39) {
            //console.log("NFT"+nft['id'], nft);
        }
        let tickets = Utils.get(nft, 'tickets', []);
        let passes = Utils.get(nft, 'passes', []);


        let partsName = Utils.get(nft, 'name', '').split(' ');
        let nftNum = parseInt(partsName[partsName.length-1]);
        let nftNames = [
            {title:"All Access", tickets:4},
            {title:"VIP 1", tickets:2},
            {title:"VIP 2", tickets:2},
        ];
        const nftRef = nftNum % nftNames.length;
        const nftName = ""; //"Ultra Music Fest 1 - " + Utils.get(nftNames, nftRef+'.title');
        //const nftTicketCount = Utils.get(nftNames, nftRef+'.tickets', []);
        const extraCount = nftTicketCount - tickets.length;
        for(var i=0;i<extraCount;i++) {
            tickets.push({"num":Math.floor(Math.random() * 10000), "metadata":Utils.get(nft, "token_metadata"),
            "image_url":Utils.get(nft, "image_url")});
        }
        const showTickets = this.state.mode == 'ticket';
        const showNftPasses = this.state.mode != 'ticket';
        const openseaUrl = "https://testnets.opensea.io/assets/0x0ad1f6445d35d7623d6450ab497fe2872c05ae3e/"+nftNum;
        //ll._("Show tickets:"+showTickets+" nftpasses: "+showNftPasses+" ");
        //nft['image_url'] = "https://lh3.googleusercontent.com/9cPSy4rdax8X8LWKaKJh-sAcZmjsLFAK6foXkzOtX25evTHpj2FtU-a7ghwm4F4FgkN7r3g9AAT7b88nmXteDyJBwWG8Wmx5_MobOQ";
        return (
            <Div key={Utils.get(nft, "id")} className="nft-tile" style={this.styles.nftTile}>
                <Div title={Utils.get(nft, "name")} className="innerTile" style={{backgroundColor: "rgba(31,7,68,0.6)"}}>
                    <Div>
                        <a target="_blank" href={openseaUrl}>
                            <Div style={{position:"relative"}}  className='nft-tile-image-container'>
                                <img
                                    src={nft['image_url']}
                                    style={{width:197,borderRadius:10, overflow:"hidden"}}
                                    />
                            </Div>
                        </a>
                        <Div id={nft['token_id']} style={{marginTop:8, marginBottom:20,}}>{nftName}</Div>
                    </Div>
                    { showTickets ?
                        <Div >
                            {tickets.map((ticket, idx) => {
                                return this.renderQrPass(ticket, nft, idx);
                            })}
                        </Div>
                        :
                        null
                    }
                    { showNftPasses ?
                        <Div >
                            {passes.map((pass) => {
                                return this.renderNftPass(pass, nft);
                            })}
                        </Div>
                        :
                        null
                    }
                </Div>

            </Div>
        );
    }

    renderNfts() {
        if(this.state.firstLoad || this.state.loading) {
            return <Div style={{fontSize:24, margin:30}}>Loading...</Div>;
        }
        if(!(window.ethereum && window.ethereum.isMetaMask)) {
            return <Div style={{fontSize:24, margin:30}}>MetaMask is not active. Please click the MetaMask wallet button to continue.</Div>;
        }
        if(this.state.mode == "minting" && this.state.mintingOpen != 11) {
            return <Div>Minting will be available shortly. Sorry for the delay!</Div>;
        }
        const nfts = Utils.get(this.state, "nfts", []);
        const mintpasses = Utils.get(this.state, "mintpasses", []);
        let festivalpasses = [];
        if(this.state.mode == 'ticket') {
            festivalpasses = Utils.get(this.state, "festivalpasses", []);
        }
        if(nfts.length == 0 && mintpasses.length == 0 && festivalpasses.length == 0) {
            return <Div style={{fontSize:24, margin:30}}>No NFTs found for this wallet</Div>;
        }
        //ll._("Count nfts:"+nfts.length+" mintpasses: "+mintpasses.length+" festivalpasses:"+festivalpasses.length);
        return (
            <Div style={{display:"flex",margin:20,flexWrap:"wrap"}}>
                {nfts.map((nft) => {
                    return this.renderNftTile(nft, 2)
                })}
                {mintpasses.map((nft) => {
                    return this.renderNftTile(nft, 2)
                })}
                {festivalpasses.map((nft) => {
                    return this.renderNftTile(nft, 1)
                })}
            </Div>
        );
    }

    handleSetWallet(o) {
        console.log("wallet", o);
        this.setState({wallet:o[0]});
        alert("Selected wallet: "+o[0]);
    }

    renderHeader() {
        let title = "Afterparty Passes";
        if(this.state.mode == 'ticket') {
            //title = "Afterparty NFT Art & Music Festival";
            title = "NFT NYC: Afterparty x MV3 x Lyrical Lemonade at Marquee, 6/23/2022";
        }
        return (
            <Div style={{fontSize:24,fontWeight:"bold",}}>
                {title}
            </Div>
        );
    }

    renderUltraHeader() {
        return (
            <Div style={{display:"flex", flex:1}}>
                <Div style={{display:"flex"}}>
                    <Div>
                        <img style={{width:200}} src='/images/logoofultramusicfestival-white.png' />
                    </Div>
                    <Div style={{margin: "15px 15px", fontWeight: "bold"}}>
                        x
                    </Div>
                    <Div>
                        <img style={{width:200}} src='/images/opensea_logo_light.png' />
                    </Div>
                </Div>
            </Div>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        //console.log("MOBILE", pr.screen.mobile);
        const img = Utils.get(pr, "screen.mobile") ? backgroundImageMobile : backgroundImage;
        let buyListing = {};
        const ticketNum = st.ticketNum;
        const qrLink = 'https://dan.soindrop.com/chorus/ticketqrcode/'+ticketNum;
        const walletOnWhitelist = false;
        const connectedWallet = window.ethereum;

        return (
            <Div className="ticketsComponent" style={sty.ticketsComponent}>
                <DebugBox
                    show={st}
                    />

                <Div style={{display:"flex", flex:1}}>
                    { st.showUltraHeader ?
                        this.renderUltraHeader()
                        :
                        this.renderHeader()
                    }

                    <Div style={{display:"flex", flex:1,justifyContent: "flex-end",marginRight:100,marginTop:17,}}>
                        <Div style={{marginTop:10}}>
                            <ConnectWallet
                                noDesoCheck={true}
                                style={null}
                                screen={pr.screen}
                                user={pr.user}
                                eventItem={{}}
                                handleSetWallet={this.handleSetWallet.bind(this)}
                                walletTypes={["metamask"]}
                                loginButtonNameOverride="SIGNUP"
                            />
                        </Div>
                        <Div style={{marginLeft:40,marginTop:8,}}>
                            <a style={{color:"white"}} target='_blank' href='https://opensea.io/collection/afterparty-festival-pass'>
                                <img style={{width:32}} src="/images/opensea_logomark.png" />
                            </a>
                        </Div>
                    </Div>

                </Div>

                <MetaMask
                    show={Utils.getRequest('debug') == 14 || st.mode == 'ticket'}
                    onChange={this.handleMetaMaskChange.bind(this)}
                />

                { st.showUltraHeader ?
                    <Div style={{display:"flex"}}>
                        <Div style={{marginLeft:300,fontSize:12,marginBottom:20,marginTop: 5, color: "rgba(255,255,255,0.5)"}}>
                            Powered by
                        </Div>
                        <Div>
                            <img style={{width:134,marginLeft:9}} src="/webapp/static/media/Group%20171.1f6c7912.png" />
                        </Div>
                    </Div>
                    :
                    null
                }
                { st.mode != "ticket" && connectedWallet ?
                    <Div style={{display:"flex"}}>
                        <Div style={{display:"flex", flex:1}}>
                            <Div style={{marginLeft:20,marginTop:12,}}>{st.metamaskWalletId}</Div>
                            <Div style={{fontSize:9,marginLeft:20,marginTop:8,background:"white",borderRadius:12,color:"black",padding:"6px 10px"}}>
                                connected
                            </Div>

                        </Div>

                    </Div>
                    :
                    null
                }

                { st.showUltraHeader ?
                    <Div style={{fontSize:24,fontWeight:"bold",marginRight:20,marginTop:20,display:"flex"}}>
                        <Div style={{marginTop:4,}}>
                            NFTs
                        </Div>
                        <Div>
                            <i style={{marginLeft:8,marginRight:8,fontSize:16}} className="fa fa-arrow-right"></i>
                        </Div>
                        <Div style={{marginTop:4,}}>
                            tickets
                        </Div>

                    </Div>
                    :
                    connectedWallet ?
                        <Div style={{marginLeft:20,marginTop:30}}>
                            { st.mode == 'ticket' ?
                                <Div style={Styles.subhead}>
                                    Claim your Access Codes
                                </Div>
                                :
                                <Div style={Styles.subhead}>
                                    Owned Utopians
                                </Div>
                            }

                        </Div>
                        :
                        null
                }
                { st.mode == 'minting' && !pr.user.email ?
                    <Div style={{marginTop:20}}>
                        {  !pr.user.email ?
                            <Div style={{marginLeft:20,marginTop:30,}}>
                                <Button
                                    className="presaleBuyButton1"
                                    onClick={this.showLoginModalBody.bind(this)}
                                >
                                    Register or Signin to claim tickets
                                </Button>
                            </Div>
                            :
                            null
                        }
                        { walletOnWhitelist ?
                            <Div className="preSaleButtonContainer" style={sty.preSaleButtonContainer}>
                                <Button
                                    className="presaleBuyButton1"
                                    type="splash"
                                    style={sty.splashMagentaButton}
                                    onClick={this.handleBuyMetamask.bind(this)}
                                >
                                    Buy MintPass
                                </Button>
                                { Utils.get(st.guestlistBalance, 'remaining') != undefined ?
                                    <Div style={{marginTop:12}}>
                                        MintPasses remaining: {mintpassesRemaining}
                                    </Div>
                                    :
                                    null
                                }
                            </Div>
                            :
                            null
                        }



                        <RegisterFlow
                            user={pr.user}
                            screen={pr.screen}
                            usernameModalTitle={<Div>Register for Afterparty</Div>}
                            successModalMessage={<Div>Already have {Utils.get(pr, "eventItem.title")} social token?{Utils.get(pr, "screen.mobile") ? null : <br/>}</Div>}
                            registerModalBody={st.registerModalBody}
                            showRegisterFlowModal={st.showRegisterFlowModal}
                            eventItem={pr.eventItem}
                            isPhoneInput
                            eventType={4}
                            successMessageData={{line1: "Thank you for joining the community."}}
                            successModalMessage=""
                            setUser={pr.setUser.bind(this)}
                            onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
                            cellphonePlaceholder="Enter phone number for text updates (optional)"
                            listings={[{}]}
                            isVerificationCode={false}
                            isWalletTableOnSuccess
                        />
                    </Div>
                    :
                    null
                }
                {this.renderNfts()}
                { st.showSentModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.handleBuyModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <Div>
                                The Ticket has been sent to the email: {Utils.get(st, "emailTo", '')}
                            </Div>
                            <Div style={{ borderRadius:12,padding:20,color:"black",textAlign:"center",margin:10}}>
                                { false ?
                                    <Div>
                                        <a target="_blank" href={qrLink}>Demo Link</a>
                                    </Div>
                                    :
                                    null
                                }
                            </Div>

                        </Div>
                    </Modal>
                    :
                    null
                }
                { this.state.showMintedModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.handleMintModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <Div style={{marginBottom:20}}>
                                You've minted an Afterparty Pass!
                            </Div>

                        </Div>
                    </Modal>
                    :
                    null
                }


            </Div>
        );
    }

    styles = {
        ticketsComponent: {
            display: "inline-block",
            position: "relative",
            backgroundImage: "url(/images/grid_background.jpg)",
            backgroundSize: "cover",
            width: "100%",
            minHeight: 800,
            color: "white",
            padding: 20,
            flexWrap: "wrap",
            marginTop: 72,
            minHeight: "calc(100vh - 72px)",
        },
        nftTile: {
            textAlign:"center",
            padding:20,
            width: 318,
            minHeight: 512,
            backgroundColor: "#1F0744",
            borderRadius: 10,
            padding: 20,
            overflow: "hidden",
            marginRight: 20,
            marginBottom: 20,
            border: "1px solid rgba(127,127,127,0.8)",
        },
        backgroundImage: {
            maxWidth: "100%",
            display: "block",
            height: "auto",
        },
        marker: {
           position: "absolute",
           //border: "2px solid white",
           overflow: "hidden",
           cursor: "pointer",
        },
        markerTwitter: {
           left: "11%",
           top: "13.5%",
           width: 50,
           height: 60,
        },
        markerTwitterMobile: {
           left: "11%",
           top: "11%",
           width: 50,
           height: 60,
        },
        markerDiscord: {
           left: "15%",
           top: "13.5%",
           width: 40,
           height: 60,
        },
        markerDiscordMobile: {
           left: "22%",
           top: "11%",
           width: 40,
           height: 60,
        },
        markerInstagram: {
           left: "18.5%",
           top: "13.5%",
           width: 50,
           height: 60,
        },
        markerInstagramMobile: {
           left: "30.5%",
           top: "11%",
           width: 50,
           height: 60,
        },

        markerUtopian: {
           left: "6%",
           top: "39.5%",
           width: 406,
            height: 646,
        },
        markerFestival: {
           left: "36%",
           top: "39.5%",
           width: 406,
            height: 646,
        },
        markerPlatform: {
           left: "65.5%",
           top: "39.5%",
           width: 406,
            height: 646,
        },

        markerTwitterBottom: {
           left: "37%",
           top: "88%",
           width: 115,
           height: 115,
        },
        markerTwitterBottomMobile: {
           left: "11%",
           top: "88%",
           width: 115,
           height: 115,
        },
        markerDiscordBottom: {
           left: "46%",
           top: "88%",
           width: 115,
           height: 115,
        },
        markerDiscordBottomMobile: {
           left: "37%",
           top: "88%",
           width: 115,
           height: 115,
        },
        markerInstagramBottom: {
           left: "56%",
           top: "88%",
           width: 115,
           height: 115,
        },
        markerInstagramBottomMobile: {
           left: "65%",
           top: "88%",
           width: 115,
           height: 115,
        },

        markerTosBottom: {
           left: "37%",
           top: "96%",
           width: 115,
           height: 115,
        },
        markerTosBottomMobile: {
           left: "11%",
           top: "96%",
           width: 115,
           height: 115,
        },
        markerDmcaBottom: {
           left: "46%",
           top: "96%",
           width: 115,
           height: 115,
        },
        markerDmcaBottomMobile: {
           left: "37%",
           top: "96%",
           width: 115,
           height: 115,
        },
       markerPrivacyBottom: {
           left: "56%",
           top: "96%",
           width: 115,
           height: 115,
        },
       markerPrivacyBottomMobile: {
           left: "65%",
           top: "96%",
           width: 115,
           height: 115,
        },


        markerH: {
           left: "22%",
           top: "16%",
        },
        markerTitle: {
           position: "relative",
           padding: 10,
           color: "white",
        },
    };
}
