import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Validate from '../modules/Validate';

import Input from '../elements/Input';
import ErrorBox from '../elements/ErrorBox';
import Button from '../elements/Button';

import Div from './Div';

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailText: "",
            phoneText: "",
            successMessages: [],
            errorMessages: [],
        };
    }

    infoChange(infoVar, e) {
        let tempState = Utils.deepCopy(this.state);
        tempState[infoVar] = e.target.value;

        this.setState(tempState);
    }

    resetErrorSuccessMessages() {
        this.setState({
            errorMessages: [],
            successMessages: [],
        });
    }

    resetPassword() {
        const st = this.state;
        const pr = this.props;

        let forgotData = {
            format: "json",
        };

        this.resetErrorSuccessMessages();

        if(st.emailText.length) {
            forgotData.email = st.emailText;

            let validateEmailResponse = Validate.email(st.emailText);

            if(validateEmailResponse.errors?.length) {
                this.setState({
                    errorMessages: validateEmailResponse.errors,
                    successMessages: [],
                });

                return;
            }
        } else if(st.phoneText.length) {
            forgotData.primary_phone1 = st.phoneText;

            let validatePhoneResponse = Validate.phone(st.phoneText);

            if(validatePhoneResponse.errors?.length) {
                this.setState({
                    errorMessages: validatePhoneResponse.errors,
                    successMessages: [],
                });

                return;
            }
        } else {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(375348, "Please enter your email"),
                successMessages: [],
            });

            return;
        }

        Api.postPasswordReset(forgotData, (res) => {
            if(Utils.get(res, "errors", "").length) {
                const errors = res.errors;
                const forgotErrors = {
                    errorMessages: errors,
                    successMessage: [],
                };

                this.setState(forgotErrors);
            } else {
                this.setState({
                    errorMessages: [],
                    successMessages: [{id: 487393, message: res?.data?.message}],
                });
            }
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="forgotPasswordComponent"
                style={{...sty.forgotPasswordComponent, ...pr.style}}
            >
                <Div
                    className="forgotPasswordInner"
                    style={sty.forgotPasswordInner}
                >
                    <Div
                        className="forgotTitle"
                        style={{...Styles.ap_text__subheading2, ...{marginBottom: 10, fontWeight: 800}}}
                    >
                        Forgot Password
                    </Div>

                    <Div
                        className="forgotDescription"
                        style={{...Styles.ap_text__subheading3, ...{marginBottom: 20}}}
                    >
                        We'll send you a link to change your password
                    </Div>

                    <Input
                        id="forgotpassword.email"
                        placeholderLabelStyle={{marginBottom: 20}}
                        inputType="placeholderLabel"
                        type="email"
                        placeholder="Email"
                        value={st.emailText}
                        onChange={this.infoChange.bind(this, "emailText")}
                    />

                    {/* <Div
                        className="forgotOr"
                        style={{...Styles.ap_text__subheading3, ...{marginBottom: 20, textAlign: "center"}}}
                    >
                        OR
                    </Div>

                    <Input
                        placeholderLabelStyle={{marginBottom: 20}}
                        inputType="placeholderLabel"
                        type="phone"
                        placeholder="Phone Number"
                        value={st.phoneText}
                        onChange={this.infoChange.bind(this, "phoneText")}
                        inputType="placeholderLabel"
                    /> */}

                    <Button
                        style={{width: "100%"}}
                        color="pinkGradient"
                        onClick={this.resetPassword.bind(this)}
                    >
                        Reset Password
                    </Button>

                    <ErrorBox
                        style={{marginTop: 20}}
                        successMessages={st.successMessages}
                        errorMessages={st.errorMessages}
                    />
                </Div>

            </Div>
        );
    }

    styles = {
        forgotPasswordComponent: {
            minHeight: "calc(100vh - 300px)",
            backgroundColor: Colors.indigo,
            paddingTop: 140,
            paddingLeft: 20,
            paddingRight: 20,
        },
        forgotPasswordInner: {
            maxWidth: 463,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
        },
    };
}
