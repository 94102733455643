import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';

import Div from '../components/Div';

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    renderPP() {
        return (
<Div>
<Div>
<p><strong>AFTERPARTY PRIVACY POLICY</strong></p>
<p></p>
<p><strong>Effective date:</strong> June 1, 2021</p>
<p>Soin Holdings, Inc. (&ldquo;Afterparty,&rdquo; &ldquo;we,&rdquo; &ldquo;our,&rdquo; and/or &ldquo;us&rdquo;) values the privacy of individuals who use our website, app, and related online services (collectively, our &ldquo;Services&rdquo; or &ldquo;Platform&rdquo;). This privacy policy (the &ldquo;Privacy Policy&rdquo;) explains how we collect, use, and share information from users of our Services (&ldquo;Users&rdquo;, &ldquo;you&rdquo;, and/or &ldquo;your&rdquo;) It applies to users who attend Afterparty events (&ldquo;Attendees&rdquo;), as well as the individuals hosting those events (&ldquo;Artists&rdquo;). By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our Terms of Service (URL).</p>
<p><br /> <strong>INFORMATION WE COLLECT</strong></p>
<p></p>
<p>We may collect a variety of information from or about you or your devices from various sources, as described below.</p>
<p>If you do not provide your information when requested, you may not be able to use our Services if that information is necessary to provide you with our Services or if we are legally required to collect it. </p>
<p></p>
<p><u>Information You Provide to Us</u></p>
<p><strong>Registration and Profile Information</strong>. When you sign up for an account, we ask you for a username, email address, and phone number. We will also ask you to link a wallet to your account where you store your NFTs. If you sign up for our waitlist, we will collect your email address. </p>
<p><strong>Communications</strong>. If you contact us directly, we may receive additional information about you. For example, if you contact us for customer support, we may receive your name, email address, phone number, the contents of the message and any attachments you send to us, and any other information you choose to provide. When we send you emails, we may track whether you open them to learn how to deliver a better customer experience and improve our Services</p>
<p><strong>Careers.</strong> If you decide that you wish to apply for a job with us, you may submit your contact information and your resume online. We will collect the information you choose to provide on your resume, such as your education and employment experience. You may also apply through LinkedIn. If you do so, we will collect the information you make available to us on LinkedIn. </p>
<p><strong>Payment Information.</strong> If you make a purchase through our Services, your payment-related information, such as credit card, public blockchain data, or other financial information, is collected by our third party payment processor on our behalf. </p>
<p><strong>Artist Content.</strong> If you are an Artist, we may collect information that you submit to us in connection with your event. For example, we may use the information you provide to create your event page. </p>
<p><strong>Event Interactions.</strong> You may have the ability to engage with other users during Artist events. If you choose to send messages, videos, or other content during Artist events, we may collect that information. </p>
<p></p>
<p><u>Information We Collect When You Use Our Services </u></p>
<p><strong>Video and Audio Recordings. </strong>When you attend or host events, the events may be recorded and publicly posted to our Platform and other services.</p>
<p><strong>Location Information</strong>. When you use our Services, we infer your general location information, for example, by using your internet protocol (IP) address. </p>
<p><strong>Device Information</strong>. We receive information about the device and software you use to access our Services, such as your IP address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens. </p>
<p><strong>Usage Information</strong>. To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, such as the pages or other content you view, the searches you conduct, people you follow, purchases you make, your comments, any content you post, and the dates and times of your visits.</p>
<p><strong>Your Contacts</strong>. If you permit us to access the address book on your device or give us permission to import your contacts from an email account associated with your account, we may access and store names and contact information from your address book to facilitate invitations and social interactions that you initiate through our Services and for other purposes described in this privacy policy or at the time of consent or collection. We also collect your contacts if you invite them to use our Services or share information or content with them from our Services.</p>
<p><strong>Information from Cookies and Similar Technologies. </strong>We and third party partners collect information using cookies, pixel tags, or similar technologies. Our third party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services. Please review your web browser&rsquo;s &ldquo;Help&rdquo; file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.</p>
<p><u>Information We Receive from Third Parties</u></p>
<p><strong>Information from third party services. </strong>If you choose to link our Services to a third party account, such as an account associated with your NFT wallet, we may receive information about you, including your profile information, wallet address, public key, and your use of the third party account. If you wish to limit the information available to us, you should visit the privacy settings of your third party accounts to learn about your options.</p>
<p><strong>Other third parties. </strong>We may receive additional information about you, such as demographic data, from third parties such as data or marketing partners and combine it with other information we have about you. </p>
<p><strong>HOW WE USE THE INFORMATION WE COLLECT</strong></p>
<p></p>
<p>We may use the information we collect:</p>
<p>To provide, maintain, improve, and enhance our Services;</p>
<p>To personalize your experience on our Services such as by providing tailored content and recommendations; </p>
<p>To understand and analyze how you use our Services and develop new products, services, features, and functionality;</p>
<p>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</p>
<p>For marketing and advertising purposes, such as developing and providing promotional and advertising materials that may be relevant, valuable or otherwise of interest to you;</p>
<p>To generate anonymized, aggregate data containing only de-identified, non-personal information that we may use to publish reports;</p>
<p>To send you text messages and push notifications;</p>
<p>To facilitate transactions and payments;</p>
<p>To find and prevent fraud, and respond to trust and safety issues that may arise; </p>
<p>For compliance purposes, including enforcing our Terms of Service or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</p>
<p>For other purposes for which we provide specific notice at the time the information is collected.</p>
<p></p>
<p><strong>HOW WE SHARE THE INFORMATION WE COLLECT</strong></p>
<p></p>
<p>We do not share or otherwise disclose information we collect from or about you except as described below or otherwise disclosed to you at the time of the collection. </p>
<p><strong>Affiliates. </strong>We may share any information we receive with our affiliates for any of the purposes described in this Privacy Policy. </p>
<p><strong>Vendors and Service Providers. </strong>We may share any information we receive with vendors and service providers retained in connection with the provision of our Services. </p>
<p><strong>User Information and Video Events.</strong> Artist events are social events during which you may be able to participate by chatting with Artists and other participants via video or chat functionality. If you participate during an event, event participants will see your profile and profile picture, your video, and any messages you send. Afterparty or Artists may publicly post recorded sessions, including your participation in them, on Afterparty&rsquo;s Services or third party services. You should not share content or otherwise participate in a session if you would not be comfortable having that content publicly viewable.</p>
<p><strong>User Profile and Collections. </strong>User profiles on Afterparty are publicly viewable. If you have chosen to provide a username, profile picture, or wallet address, that will be viewable by other users. If you have linked your wallet to our Services, the NFTs available in your wallet will be publicly displayed to other users. Additionally, other users may be able to view your connections and which artists you have chosen to follow. </p>
<p><strong>Marketing. </strong>With your consent, we share your email address and any other contact information you choose to provide with Artists, who may use that information to market their own products or services to you. </p>
<p><strong>Analytics Partners.</strong> We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn more about Google&rsquo;s practices by visiting <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>. </p>
<p><strong>Advertising Partners. </strong>We work with third party advertising partners to show you ads that we think may interest you. Some of our advertising partners are members of the Network Advertising Initiative (<a href="https://optout.networkadvertising.org">https://optout.networkadvertising.org</a>) or the Digital Advertising Alliance (<a href="https://optout.aboutads.info">https://optout.aboutads.info</a>). If you do not wish to receive personalized ads, please visit their opt-out pages to learn about how you may opt out of receiving web-based personalized ads from member companies. You can access any settings offered by your mobile operating system to limit ad tracking, or you can install the AppChoices mobile app to learn more about how you may opt out of personalized ads in mobile apps.</p>
<p><strong>As Required By Law and Similar Disclosures. </strong>We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others&rsquo; rights, property, or safety. For the avoidance of doubt, the disclosure of your information may occur if you post any objectionable content on or through the Services.</p>
<p><strong>Merger, Sale, or Other Asset Transfers. </strong>We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets. The use of your information following any of these events will be governed by the provisions of this Privacy Policy in effect at the time the applicable information was collected.</p>
<p><strong>Consent. </strong>We may also disclose your information with your permission.</p>
<p></p>
<p><strong>YOUR RIGHTS AND CHOICES</strong></p>
<p></p>
<p><strong>Marketing Communications. </strong>You can unsubscribe from our promotional emails via the link provided in the emails. Even if you opt out of receiving promotional messages from us, you will continue to receive administrative messages from us.</p>
<p><strong>THIRD PARTIES</strong></p>
<p></p>
<p>Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</p>
<p></p>
<p><strong>SECURITY</strong></p>
<p></p>
<p>We make reasonable efforts to protect your information by using safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.</p>
<p><strong>CHILDREN</strong></p>
<p></p>
<h1>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at privacy@afterparty.ai. </h1>
<p><strong>INTERNATIONAL TRANSFERS OF DATA</strong></p>
<p>Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use the Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Services. By providing any information, including personal information, on or to the Services, you consent to such transfer, storage, and processing.</p>
<p><strong>ACCESSING/UPDATING YOUR INFORMATION</strong></p>
<p></p>
<p>You can update your account and profile information through your profile settings. </p>
<p><strong>CHANGES TO THIS PRIVACY POLICY</strong></p>
<p></p>
<p>We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through the Services, we will notify you through the Services, by email, or other communication.</p>
<p><strong>CONTACT US</strong></p>
<p></p>
<p>Afterparty is responsible for processing your information. If you have any questions, comments, or concerns about our processing activities, please email us at privacy@afterparty.ai or write to us at Soin Holdings, Inc., 11228 W Sunset Blvd, Los Angeles, CA 90049.</p>
</Div>
<p>@Afterparty: To be updated upon completion of the policy and each time you update the policy </p>
</Div>
        );
    }


    render() {
        return (
            <Div style={this.styles.container}>
                {this.renderPP()}
            </Div>
        );
    }

    styles = {
        container: {
            padding: 20,
        },
    };
}
