import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Crypto from '../modules/Crypto';

import RegisterFlow from './RegisterFlow';
import DebugBox from '../elements/DebugBox';

import '../App.css';

import Input from '../elements/Input';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';

import Div from './Div';
import ConnectWallet from './ConnectWallet';

import oneHeaderBackgroundImage from "../assets/images/splash/ONE - 2.jpg"
import twoHeaderBackgroundImage from "../assets/images/splash/TWO2x.jpg"
import backgroundImage from "../assets/images/grid_background.jpg"
import backgroundImageMobile from "../assets/images/ap_v4_platform_mobile.jpg"
import twoAfterpartyLogo from "../assets/images/splash/Afterparty.png"


export default class MintUtopian extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            nfts: [],
            contractId: "0xdfeda9c39b3571fe1d403e381ebc06e9d5d85fb5", //  Prod MintPass AP1155
            testContractId: "0x55e1A47D3b308d128387F70f48Cef286d63B039D", //  Rinkeby Test MintPass
            ticketNum: 1,
            emailTo: '',
            testMode: Utils.getRequest('test') == 1,
            words1: {},
            words2: {},
            words3: {},
            showPresale: Utils.getRequest('presale'),
            hideTokenList: {},
            showSuccessUtopianModal: false,
            showSuccessUtopianTitle: "",
            showSuccessUtopianMessage: "",

            showPresale: Utils.getRequest('presale'),
            collectionId: 1,
            guestlistBalance: {},
            masterCollectionId: 1,
            //metamaskWalletId: this.updateConnectedMetamaskWallet(false),
            includes: {},

            wallet: '',
            walletSuccess: false,
        };
        //Utils.activateLogger();
        window.count = 0;
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        /*
        Crypto.connectWallet( (o) => {
            if(o['connectedWallet']) {
                ll._("Attaching metamask wallet", "green", o['connectedWallet']);
                this.setState({wallet:o['connectedWallet']});
                this.loadItems(o['connectedWallet']);
            }
        }, false, this.props.user);
        */

        let tempState = {selected: pr.route ? pr.route : 'afterparty'};
        if(pr.includes) {
            tempState['includes'] = pr.includes;
        }
        this.setState(tempState, () => {
            this.handleRoute(st.selected);
        });

        if(window.checkGuestlistBalance) {
            clearInterval(window.checkGuestlistBalance);
            window.checkGuestlistBalance = undefined;
        }

        this.checkGuestlistBalance();
        if(Utils.getRequest('nocheck') != 1) {
            window.checkGuestlistBalance = setInterval(this.checkGuestlistBalance.bind(this), 5 * 1000);
            //this.checkGuestlistBalance();
        }
    }

    loadItems(walletId) {
        Crypto.connectWallet( (o) => {
            if(o['connectedWallet']) {
            } else {
                ll._("No connected MetaMask wallet", "red");
                return;
            }
        }, false, this.props.user);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';

        if(this.state.selected != selected) {
            this.setState({selected: selected});
        }
    }


    onClickSocialMediaIcon(socialIcon) {
        switch(socialIcon) {
            case "twitter":
                window.open("https://www.twitter.com/afterparty", "_blank");
                break;
            case "discord":
                window.open("https://discord.gg/afterpartyNFT", "_blank");
                break;
            case "instagram":
                window.open("https://www.instagram.com/afterparty", "_blank");
                break;
            default:
        }
    }

    newTab(link) {
        window.open(link, "_blank");
        //this.props.history.push(`/${link}`);
    }
    handleRoute(selected) {
    }

    setWindowDirty(value) {
        window.isDirty = value;
    }

    changeVal(valPath, event) {
        this.setWindowDirty(true);
        //console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        this.setState(tempState);
    }

    clickGenerate(ticket) {
        console.log("clickGenerate", ticket);
        this.generatePass(ticket)
    }

    generatePass(obj) {
        //var imageUrl = $(obj).attr('data-image_url');
        //var imageIdx = $(obj).attr('data-idx');
        //var imageName = $(obj).attr('data-name');
        var metadata = Utils.get(obj, 'metadata', '');
        console.log(obj, metadata);
        //return;
        var idParts = metadata.split('/');
        if(idParts.length < 2) {
            alert("Missing metadata: "+JSON.stringify(idParts));
            return;
        }
        const collection_index = idParts[idParts.length-2];
        const collection_item_index = idParts[idParts.length-1];
        var data = {
            collection_index: parseInt(collection_index),
            collection_item_index: parseInt(collection_item_index),
            image_url: Utils.get(obj, 'image_url', ''), //imageUrl,
        }
        console.log("obj", obj, data);
        //this.setState({showSentModal:true, emailTo:""});
        //return;
        Api.postCustomerTicket(data,(o) => {
            //$(obj).html('<div class="btn-chorus btn-chorus-small btn-chorus-green">Resend Pass #'+(parseInt(1)+1)+'</div>');
            // POST to generate endpoint
            //  Force update confirms own NFT
            //  Creates ticket entry with status of 1 to validate URL later
            //  Creates URL to ticket clain ID
            //  Generate QR Code with claim hash
            //  Record QR code url
            //  Emails ticket with QR code
            /*
            var sel = "#emailExample";
            $(sel).empty().show();
            console.log(imageUrl, imageIdx, imageName);
            var qrCode = Utils.get(o, "data.qc_code_hash");
            var info = {
                utopian_name: imageName,
                utopian_image: '<img width="117" height="175" style="height:175px; width:117px;" src="'+imageUrl+'" alt="'+imageName+'" />',
                qr_image: '<img alt="QR Code" class="img-responsive center qrcode" src="/images/qrcodes/'+qrCode+'" style="width: 150px; padding: 10px; background:#ffffff;" />',
            }
            j.appendComponent("ticket_email.html", sel, info);
            */
            this.setState({showSentModal:true, emailTo:Utils.get(this.props, "user.email"), ticketNum:Utils.get(o.data, "id")});
            this.loadItems(this.state.wallet);
            //$.alert('Email with QR Code sent to: dan@afterparty.ai', 'Pass generated');
        });
    }


    handleBuyModalClose(o) {
        console.log("close");
        this.setState({showSentModal:false});
    }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "username"
        });
    }

    checkGuestlistBalance() { //updateState) {
        //updateState = updateState == undefined ? true : updateState;
        Crypto.connectWallet( (co) => {
            let connectedWallet = co['connectedWallet'];
            if(connectedWallet) {
                console.log("Wallet selected", connectedWallet);
                if(this.state.wallet != connectedWallet) {
                    this.setState({wallet: connectedWallet});
                }
                var extraParams = {
                    collectionId: this.state.masterCollectionId,
                    wallet: connectedWallet,
                };
                // Api.getWalletPermission(this.state.masterCollectionId, connectedWallet, (o, e) => {
                Api.getNfts( (o2) => {
                    //console.log("Result", o);
                    let tempState = {};
                    tempState['errors'] = Utils.get(o2, "errors", []);
                    if(Utils.get(o2, "success") == 1) {
                        tempState['nfts'] = Utils.get(o2, "data.mintpasses");
                        tempState['wallet'] = connectedWallet;
                        tempState['guestlistBalance'] = o2.data.guestlistBalance;
                    } else {
                        //alert("Login required");
                    }
                    this.setState(tempState);
                }, extraParams);

                /*
                ll._("FOUND connected MetaMask wallet", "green", o['connectedWallet']);
                let contractId = this.state.contractId; //  Prod MintPass
                if(this.state.testMode) {
                    contractId = this.state.testContractId; //  Test MintPass
                }
                const color = this.state.testMode ? "blue" : "orange";
                const walletType = this.state.testMode ? "test" : "PROD";
                ll._("Loading items for "+walletType+" wallet: ", color, walletId, " on contract: ", contractId, " testMode: ", this.state.testMode);
                var extraParams = {
                    contract: contractId,
                    wallet: o['connectedWallet'],
                };
                */

            } else {
                console.log("No wallet selected", connectedWallet);
                //this.setState({wallet: ''});
            }
        });
    }

    /*
    updateConnectedMetamaskWallet(updateState) {
        if(Utils.get(this.props.user, "ul") >= 90 && Utils.getRequest('wallet')) {
            return Utils.getRequest('wallet');
        }
        updateState = updateState == undefined ? true : updateState;
        const curWalletId = Utils.get(this.state, "metamaskWalletId", "");
        let walletId = '';
        if(window.ethereum && window.ethereum.isMetaMask && window.ethereum.selectedAddress) {
            walletId = window.ethereum.selectedAddress;
        }
        if(!walletId || walletId.length == 0) {
            walletId = 'None. Please activate Metamask.';
        }
        if(updateState && curWalletId != walletId) {
            this.setState({metamaskWalletId: walletId, wallet: walletId});
        }
        return walletId;
    }
    */

    onRequestRegisterModalClose() {
        this.setState({
            showRegisterFlowModal: false,
            registerModalBody: "username",
            errorMessages: [],
            rsvpModalMessage: "",
        });
    }

    onRequestSuccessUtopianModalClose() {
        this.setState({
            showSuccessUtopianModal: false,
            showSuccessUtopianMessage: "",
        });
    }

    handleConnectMetamask() {
        const pr = this.props;

        if(window.ethereum && window.ethereum.isMetaMask) {
            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            window.ethereum.request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                ll._("Attaching metamask wallets", accounts);
                this.setState({
                    wallet: accounts[0],
                    walletSuccess: true,
                }, () => {
                   if(pr.handleSetWallet) {
                    return pr.handleSetWallet(accounts);
                   }
                });
                // alert("Selected wallet: "+ accounts[0]);

            })
            .catch( (e) => {
                if(Utils.get(e, "code") == -32002) {
                    alert("Metamask error: Waiting for approval window. Please click on the browser Metamask toolbar icon to show Metamask window.");
                } else {
                    alert("Metamask error: "+Utils.get(e, "message"));
                    console.log("ERROR", e);
                }
            });
        } else {
            alert("Metamask not found. Please activate Metamask and refresh the page.");
        }
    }


    handleMintUtopian(tokenId) {
        ll._("Mint Token: "+tokenId);
        const word1 = Utils.get(this.state, `words1.${tokenId}`, '').trim();
        const word2 = Utils.get(this.state, `words2.${tokenId}`, '').trim();
        const word3 = Utils.get(this.state, `words3.${tokenId}`, '').trim();
        ll._("Words", "green", word1, word2, word3);
        if(word1.length == 0 ||  word2.length == 0 || word3.length == 0) {
            alert("All 3 creation phrase words must be filled in to mint.");
            return;
        }
        if(this.state.wallet) {
            let walletAddress = this.state.wallet;
            ll._("Minting from metamask wallet", "green", walletAddress);
            //walletAddress = "0xebfd733fbdd1d91b75e941908bf3c748492ced29"; John's wallet with mainnet mintpasses
            let params = [];

            let mintPassContractAddress = this.state.contractId;
            if(this.state.testMode) {
                mintPassContractAddress = this.state.testContractId;
            }

            const mintNftCmd = '0x490df8aa000000000000000000000000';
            const tokenIdStr = Number(tokenId).toString(16);
            const tokenIdHex = Utils.pad(tokenIdStr, 40);
            const abiCmd = mintNftCmd + tokenIdHex;

            const tx = {
              from: walletAddress,
              to: mintPassContractAddress,
              data: abiCmd,
            };
            params.push(tx);
            const quantity = 1;
            let o = '0xbb1fda0d30c04652b4c78f3a24761be9fe2c1f39cb420d42f33075f731df5fa2';
            const extraData = {
                token_id: tokenId,
                testMode: this.state.testMode,
                generationPhrase: word1 + " " + word2 + " " + word3,
            };
            const transactionData = {
                title: "Mint Utopian from Pass #"+tokenId,
                transaction_type: 2,
                wallet_id: walletAddress,
                contract_id: mintPassContractAddress,
                stripe_transaction_id: o,
                json: JSON.stringify(extraData),
            };
            // TEST CODE
            if(false) {
                Api.postTransaction(transactionData,  (o) => {});
                let hideTokenList = this.state.hideTokenList;
                hideTokenList[tokenId] = true;
                this.setState({
                        hideTokenList: hideTokenList,
                        showSuccessUtopianModal: true,
                        showSuccessUtopianTitle: "Transaction Processing",
                        showSuccessUtopianMessage: quantity === 1 ? "Check Metamask for transaction confirmation" : "You just bought two Utopians!",
                    }, this.checkGuestlistBalance.bind(this));
                return;
            }

            //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
            const txHash = window.ethereum.request({
                method: 'eth_sendTransaction',
                params: params,
            }).then((o) => {
                console.log("BUY transaction ID: ", o);
                Api.postTransaction(transactionData,  (o) => {
                    console.log(o);
                    let hideTokenList = this.state.hideTokenList;
                    hideTokenList[tokenId] = true;
                    this.setState({
                            hideTokenList: hideTokenList,
                            showSuccessUtopianModal: true,
                            showSuccessUtopianTitle: "Transaction Processing",
                            showSuccessUtopianMessage: quantity === 1 ? "Check Metamask for transaction confirmation" : "You just bought two Utopians!",
                        }, this.checkGuestlistBalance.bind(this));
                    return;
                });
            }).catch((o) => {
                console.log("Fail", o);
                this.setState({
                    showSuccessUtopianModal: true,
                    showSuccessUtopianTitle: "Transaction Error",
                    showSuccessUtopianMessage: "Transaction failed!",
                });
                if(o.code == 4001) {
                    // alert("Customer cancelled transaction");
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianMessage: "Customer cancelled transaction!",
                    });
                } else if(o.code == -32602) {
                    // alert("Invalid wallet selected. Maybe you're not logged into Metamask? Please select wallet in Metamask.");
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianMessage: "Invalid wallet selected. Maybe you're not logged into Metamask? Please select wallet in Metamask.",
                    });
                } else {
                    console.log("ERROR", o);
                    this.setState({
                        showSuccessUtopianModal: true,
                        showSuccessUtopianTitle: "Transaction Error",
                        showSuccessUtopianMessage: "Transaction failed : "+o.message + " ("+o.code+")",
                    });

                }
            });

        } else {
            alert("No connected Metamask wallet.");
        }

    }

    handleSetWallet(o) {
        console.log("wallet", o);
        this.setState({wallet:o[0]});
    }

    renderTicket(ticket) {
        const key = "t-" + Utils.get(ticket, "id") + Math.floor(Math.random() * 100);
        return (
            <Div key={key} style={{background:"rgba(127,127,127,0.3)",borderRadius:8,margin:10,padding:10}}>
                <Div style={{margin:6}}>Ticket</Div>
                <Button onClick={this.clickGenerate.bind(this,ticket)} style={{width:"100%"}} size="small" color="white">Mint Utopian</Button>
            </Div>
        );

    }

    renderNftTile(nft) {
        if(!nft) {
            console.log("No NFT tile to render");
            return;
        }
        let tickets = Utils.get(nft, 'tickets', []);
        let partsName = Utils.get(nft, 'name', '').split(' ');
        let nftNum = parseInt(partsName[partsName.length-1]);
        let nftNames = [
            {title:"All Access", tickets:4},
            {title:"VIP 1", tickets:2},
            {title:"VIP 2", tickets:2},
        ];
        const nftRef = nftNum % nftNames.length;
        const tokenId = Utils.get(nft, 'token_id', '');
        const nftName = "Utopian MintPass - " + tokenId;
        const nftTicketCount = Utils.get(nftNames, nftRef+'.tickets', []);
        const extraCount = nftTicketCount - tickets.length;
        for(var i=0;i<extraCount;i++) {
            tickets.push({"num":Math.floor(Math.random() * 100), "metadata":Utils.get(nft, "token_metadata"),
            "image_url":Utils.get(nft, "image_url")});
        }

        let openseaUrl = "https://opensea.io/assets/0xdfeda9c39b3571fe1d403e381ebc06e9d5d85fb5/"+tokenId;
        if(this.state.showPresale == 11) {
            openseaUrl = "https://testnets.opensea.io/assets/0x55e1a47d3b308d128387f70f48cef286d63b039d/"+tokenId;
        }
        //nft['image_url'] = "https://lh3.googleusercontent.com/9cPSy4rdax8X8LWKaKJh-sAcZmjsLFAK6foXkzOtX25evTHpj2FtU-a7ghwm4F4FgkN7r3g9AAT7b88nmXteDyJBwWG8Wmx5_MobOQ";
        return (
            <Div key={Utils.get(nft, "id")} className="nft-tile" style={this.styles.nftTile}>
                <Div className="innerTile" style={{backgroundColor: "white"}}>
                    <Div>
                        <a target="_blank" href={openseaUrl}>
                            <Div style={{position:"relative"}}  className='nft-tile-image-container'>
                                <img
                                    src={nft['image_url']}
                                    style={{width:197,borderRadius:10, overflow:"hidden"}}
                                    />
                            </Div>
                        </a>
                        <Div style={{marginTop:8, fontWeight:"bold", fontSize:16,}}>{nftName}</Div>
                        <Div style={{marginTop:8}}>Three Word Generation Phrase</Div>
                        <Div style={{marginTop:12, display:"flex",flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                            <Div style={{width:200}}>
                                <Input
                                    value={Utils.get(this.state, `words1.${tokenId}`, '')}
                                    onChange={this.changeVal.bind(this,`words1.${tokenId}`)}
                                    placeholder="First Word"
                                    style={this.styles.registerModalInput}
                                />
                                <Input
                                    value={Utils.get(this.state, `words2.${tokenId}`, '')}
                                    onChange={this.changeVal.bind(this,`words2.${tokenId}`)}
                                    placeholder="Second Word"
                                    style={this.styles.registerModalInput}
                                />
                                <Input
                                    value={Utils.get(this.state, `words3.${tokenId}`, '')}
                                    onChange={this.changeVal.bind(this,`words3.${tokenId}`)}
                                    placeholder="Third Word"
                                    style={this.styles.registerModalInput}
                                />
                            </Div>
                            <Button
                                onClick={this.handleMintUtopian.bind(this, tokenId)}
                                >
                                Mint Utopian
                            </Button>
                        </Div>

                        { /*
                            <Div>{nft['name']}</Div>
                            <Div style={{fontSize:12}}>(<span>{Utils.get(nft, 'asset_contract.name')}</span>)</Div>
                        */ }
                    </Div>
                    <Div >
                        {tickets.map((ticket) => {
                            return this.renderTicket(ticket);
                        })}
                    </Div>
                </Div>

            </Div>
        );

    }

    renderNfts() {
        if(!this.state.wallet) {
            return <Div style={{fontSize:24, margin:30}}>No active wallet</Div>;
        }
        let mintpasses = Utils.get(this.state, "nfts", []);
        if(mintpasses.length == 0) {
            return <Div style={{fontSize:24, margin:30}}>No MintPasses found for this wallet</Div>;
        }
        if(!Utils.getRequest('all')) {
            //console.log("tickets", mintpasses);
            //mintpasses = mintpasses.slice(0, Utils.get(this.state.guestlistBalance, 'remaining'));
        }
        return (
            <Div style={{display:"flex",margin:20,flexWrap:"wrap"}}>
                {mintpasses.map((nft, idx) => {
                    //console.log("IDX", idx);
                    if(!this.state.hideTokenList[nft['token_id']]) {
                        return this.renderNftTile(nft);
                    }
                })}
            </Div>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        //console.log("MOBILE", pr.screen.mobile);
        const img = Utils.get(pr, "screen.mobile") ? backgroundImageMobile : backgroundImage;
        let buyListing = {};
        const ticketNum = this.state.ticketNum;
        const selectedWallet = this.state.wallet;

        const walletOnWhitelist = Utils.get(st.guestlistBalance, 'remaining') != undefined;
        const mintMessage = "You do not currently have access to mint with this wallet.";

        const headerTwoSection = StyleUtils.getMediaStyle('headerTwoSection', sty.headerTwoSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const utopianHeaderInfoContainer = StyleUtils.getMediaStyle('utopianHeaderInfoContainer', sty.utopianHeaderInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoMainTitle = StyleUtils.getMediaStyle('twoMainTitle', sty.twoMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoSubTitle = StyleUtils.getMediaStyle('twoSubTitle', sty.twoSubTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoAfterpartyLogoStyle = StyleUtils.getMediaStyle('twoAfterpartyLogoStyle', sty.twoAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const twoSubTitleLower = StyleUtils.getMediaStyle('twoSubTitleLower', sty.twoSubTitleLower, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const whitelistedCustomer = Utils.get(pr.user, 'email') && Utils.get(pr.user, 'tags', '').indexOf('mintpass_whitelist') != -1;

        return (
            <Div className="afterpartyStaticHomeComponent" style={sty.container}>
                <DebugBox
                    show={this.state}
                    />

                <Div className="headerTwoSection" style={headerTwoSection}>
                    <Div className="utopianHeaderInfoContainer" style={utopianHeaderInfoContainer}>
                        <Image
                            className="twoAfterpartyLogoStyle"
                            src={twoAfterpartyLogo}
                            style={twoAfterpartyLogoStyle}
                        />
                        <Div className="twoMainTitle" style={twoMainTitle}>
                            UTOPIAN COLLECTION
                        </Div>
                        <Div style={sty.announceContainer}>

                { !walletOnWhitelist ?
                    <Div style={{fontSize:18, }}>{mintMessage}</Div>
                    :
                    null

                }
                <Div>
                    <Button
                        style={{textAlign: "center", marginTop: 20}}
                        type="text"
                        onClick={this.handleConnectMetamask.bind(this)}
                    >
                        Connect Other Wallet
                    </Button>

                    <Div style={{textAlign: "center", marginTop: 20}}>
                        Selected Wallet: <br/> {!selectedWallet || selectedWallet.length == 0 ? "None. Please activate Metamask.": selectedWallet}
                    </Div>
                </Div>

                { walletOnWhitelist ?
                    <Div style={{fontSize:24,fontWeight:"bold",marginRight:20,marginTop:20,display:"flex"}}>
                        <Div style={{marginTop:4,textAlign:"center", width:"100%", }}>
                            Mint Utopian
                        </Div>
                    </Div>
                    :
                    null
                }
                { false && walletOnWhitelist && !pr.user.email ?
                    <Div style={{marginTop:20}}>
                        { !pr.user.email ?
                            <Button
                                className="presaleBuyButton1"
                                onClick={this.showLoginModalBody.bind(this)}
                            >
                                Register or Signin for Presale
                            </Button>
                            :
                            null
                        }




                        <RegisterFlow
                            user={pr.user}
                            screen={pr.screen}
                            usernameModalTitle={<Div>Enter the contest to win an Afterparty Utopian</Div>}
                            successModalMessage={<Div>Already have {Utils.get(pr, "eventItem.title")} social token?{Utils.get(pr, "screen.mobile") ? null : <br/>}</Div>}
                            registerModalBody={st.registerModalBody}
                            showRegisterFlowModal={st.showRegisterFlowModal}
                            eventItem={pr.eventItem}
                            isPhoneInput
                            eventType={4}
                            successMessageData={{line1: "Thank you for joining the community."}}
                            successModalMessage=""
                            setUser={pr.setUser.bind(this)}
                            onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
                            cellphonePlaceholder="Enter phone number for text updates (optional)"
                            listings={[{}]}
                            isVerificationCode={false}
                            isWalletTableOnSuccess
                        />



                    </Div>
                    :
                    null
                }
                {this.state.showSuccessUtopianModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.onRequestSuccessUtopianModalClose.bind(this)}
                        className="successUtopianModal"
                        color="purple"
                        title={st.showSuccessUtopianTitle}
                    >
                        <Div style={{paddingTop: 20, paddingBottom: 30}}>
                            {st.showSuccessUtopianMessage}
                        </Div>
                    </Modal>
                    :
                    null
                }

                { walletOnWhitelist ?
                    this.renderNfts()
                    :
                    null
                }
                { false && Utils.get(st.guestlistBalance, 'remaining') != undefined ?
                    <Div style={{marginTop:12}}>
                        MintPasses remaining: {Utils.get(st.guestlistBalance, 'remaining')}
                    </Div>
                    :
                    null
                }
                <Div>
                        <Button
                            style={{textAlign: "center", marginTop: 20}}
                            onClick={this.onClickSocialMediaIcon.bind(this, "discord")}
                            type="text"
                        >
                            For support, click here to visit<br />our Discord server
                        </Button>
                </Div>
                { this.state.showSentModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.handleBuyModalClose.bind(this)}
                        color="purple"
                    >
                        <Div style={sty.buyIframeContainer}>
                            <Div>
                                Minted Utopian from MintPass
                            </Div>
                            <Div style={{ borderRadius:12,padding:20,color:"black",textAlign:"center",margin:10}}>
                                <Div>
                                </Div>
                            </Div>

                        </Div>
                    </Modal>
                    :
                    null
                }
                        </Div>
                    </Div>
                </Div>


            </Div>
        );
    }

    styles = {
        container: {
            display: "inline-block",
            position: "relative",
            backgroundImage: `url("${twoHeaderBackgroundImage}")`,
            backgroundSize: "cover",
            width: "100%",
            minHeight: 800,
            color: "white",
            padding: 20,
            flexWrap: "wrap",
        },
        nftTile: {
            textAlign:"center",
            padding:20,
            width: 318,
            minHeight: 512,
            backgroundColor: "white", //"#1F0744",
            borderRadius: 10,
            padding: 20,
            overflow: "hidden",
            marginRight: 20,
            marginBottom: 20,
            border: "1px solid rgba(127,127,127,0.8)",
        },
        backgroundImage: {
            maxWidth: "100%",
            display: "block",
            height: "auto",
        },
        utopianHeaderInfoContainer: {
            padding: 56,
            backgroundColor: "#FFF8E7",
            width: "fit-content",
            borderRadius: 40,
            filter: "drop-shadow(0px 4px 10px rgba(0,0,0,0.5))",
            marginLeft: "auto",
            marginRight: 153,
            color: Colors.splashDarkPurple,
            maxWidth:828,
        },
        utopianHeaderInfoContainer_md: {
            marginLeft: "auto",
            marginRight: "auto",
            padding: 30,
            marginTop: 300,
        },
        twoAfterpartyLogoStyle: {
            width: 254,
        },
        twoAfterpartyLogoStyle_md: {
            width: 180,
        },
        twoMainTitle: {
            fontFamily: "Coluna",
            fontWeight: 400,
            fontSize: 90,
            marginTop: 10,
            marginRight: 50,
        },
        twoMainTitle_md: {
            fontSize: 44,
            lineHeight: "48px",
            marginRight: 0,
        },
        twoSubTitle: {
            fontFamily: "Mont Demo",
            fontSize: 24,
            marginBottom: 24,
        },
        twoSubTitle_md: {
            fontSize: 15,
            marginBottom: 16,
        },
        twoSubTitleLower: {
            fontFamily: "Mont Demo",
            fontSize: 16,
            marginBottom: 15,
        },
        twoSubTitleLower_md: {
            fontSize: 12,
        },
    };
}
