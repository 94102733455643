import React from 'react';

import Api from '../modules/Api';

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
        };
    }

    componentWillMount() {
        Api.getAfterPartyDoc(1, (res) => {
            this.setState({
                body: res.data.body,
            });
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <div
                className="privacyPolicyElement"
                style={sty.privacyPolicyElement}
                {...pr}
                dangerouslySetInnerHTML={{ __html: st.body }}
            >
            </div>
        );
    }

    styles = {
        privacyPolicyElement: {
            width: "100%",
            overflowWrap: "break-word",
        },
    };
}
