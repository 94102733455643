import React from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StylesPlatform from '../modules/StylesPlatform';

import Div from '../components/Div';
import Image from './Image';
import Span from './Span';
import AudioChip from './AudioChip';

export default class AudioShoutout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artist: props.artist ?? {},
            collectible: props.collectible ?? {},
        };
    }

    componentDidMount() {
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        return (
            <Div
                className="audioShoutoutElement"
                style={{...sty.audioShoutoutElement, ...pr.style}}
            >
                <AudioChip
                    // audioPlayer={st.audioPlayer}
                    // playAudio={this.playAudio.bind(this)}
                    // pauseAudio={this.pauseAudio.bind(this)}
                    // setAudioEndedCallback={this.setChildAudioEndedCallback.bind(this)}
                    history={pr.history}
                    isShoutout
                    showIgSharePage
                    localAudioPlayer
                    user={pr.user}
                    src={Utils.get(pr, "shoutout.audio")}
                    body={Utils.get(pr, "shoutout.body")}
                    // roomChat={message}
                    artist={Utils.get(pr, "artist")}
                    backgroundImage={Utils.get(pr, "artist.audio_chip_background_image_url")}
                    avatar={Utils.get(pr, "artist.image_url")}
                    screen={pr.screen}
                    // autoPlay={!st.isMuted}
                    // feedback={st.feedbackTypes}
                    // submitFeedback={this.submitAudioFeedback.bind(this)}
                    // audioPlayCallback={this.audioPlayCallback.bind(this)}
                    // playFlag={playFlag}
                    // isWelcome={st.showWelcomeModal}
                />
            </Div>
        );
    }

    styles = {
        audioShoutoutElement: {
        },
    };
}
