import Colors from './Colors';

export default {
    headline_xl: {
        fontSize: 64,
        fontWeight:'bold',
    },
    subhead_md: {
        fontSize: 36,
        fontWeight:"bold",
        marginBottom: 8,
    },
}
