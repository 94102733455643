import React, { Fragment } from 'react'

import { Carousel } from 'react-responsive-carousel'
import Scroller from './Scroller'
import PlaylistScroller from './PlaylistScroller'
import axios from 'axios'
import ReactPlayer from "react-player"
import { AreaChart, LineChart, PieChart } from 'react-chartkick'
import 'chart.js'
import checkboxAnimation from "../assets/52645-checkmark.json"

import Input from '../elements/Input'
import Textarea from '../elements/Textarea'
import Span from '../elements/Span'
import Panel from '../elements/Panel'
import Dropdown from '../elements/Dropdown'
import Image from '../elements/Image'
import Icon from '../elements/Icon'
import Button from '../elements/Button'
import Modal from '../elements/Modal'
import DebugBox from '../elements/DebugBox'
import Checkbox from '../elements/Checkbox'

import Div from '../components/Div'

import Utils from '../modules/Utils'
import Colors from '../modules/Colors'
import Api from '../modules/Api'
import Styles from '../modules/Styles'
import StylesPlatform from '../modules/StylesPlatform'

export default class PageNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const pr = this.props;
    }

    backToLogin() {
        const pr = this.props;

        let path = "/login";
        if(pr.isRedirect) {
            let nextPathname = Utils.get(pr, "history.location.pathname");
            nextPathname = nextPathname.substring(1);
            path += `?next=${nextPathname}`;
        }
        pr.history.push(path);
    }

    render() {
        const sty = this.styles;

        return (
            <Div className="pageNotFoundComponent" style={sty.pageNotFoundComponent}>
                <Div className="mainText" style={sty.mainText}>
                    Oops!
                </Div>
                <Div className="mainText" style={sty.mainText}>
                    404 Page Not Found
                </Div>
                <Button
                    size="text"
                    color="white"
                    className="pageNavigation"
                    onClick={this.backToLogin.bind(this)}
                    style={{marginTop: 30}}
                >
                    Back to login
                </Button>
            </Div>
        );
    }

    styles = {
        pageNotFoundComponent: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            color: "white",
            backgroundColor: Colors.purple,
            flexDirection: "column",
        },
        mainText: {
            fontWeight: "bold",
            fontSize: 48,
            textAlign: "center"
        },
    }
}
