import { polyfill } from 'es6-promise';

import React from 'react';
import axios from 'axios';

import ReactTestUtils from 'react-dom/test-utils'; // ES6

import '../App.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StyleUtils from '../modules/StyleUtils';

import WebsiteHeader from '../elements/WebsiteHeader';

import Div from './Div';

import DashboardSoinFlyout from './DashboardSoinFlyout';
import DashboardSoinMain from './DashboardSoinMain';
import DashboardSoinItem from './DashboardSoinItem';
import NftItem from './NftItem';
import DashboardSeller from './CreatorDashboard';
import DashboardNewSoin from './DashboardNewSoin';
import FlyoutMain from './FlyoutMain';
import FlyoutMobile from './FlyoutMobile';
import DashboardSearch from './DashboardSoinSearch';
import DashboardDiscordVerify from './DashboardDiscordVerify';
import Profile from './Profile';

import Login from './Login';
import Register from './Register';
import moment, { max } from 'moment'

export default class DashboardSoin extends React.Component {
  constructor(props) {
    polyfill();

    super(props);
    this.state = {
      value: '',
      showLogout: false,
      orders: [],
      hostUrl: '',
      referrerCount: 0,

      holidays: [],
      showNotLoggedIn: false,
      version: Utils.getRequest('version', 6),
      // Used for mobile hide flyout until click
      showMobileFlyout: false,
      isPortal: false,
      mediaItem: null,
      featured: {},
      route: 'home',
      musicLoaded: false,
      userLoaded: false,
    };

    console.log("ROUTE", this.props.route);
    this.state.selected = this.props.route ? this.props.route : 'home';
    if (this.props.route == 'unsubscribe') {
      //this.apiUnsubscribe();
    } else {
      console.log("LOAD MUSIC");
      if(this.state.selected == 'soinlisting' || this.state.selected == 'nftlisting' || this.state.selected.includes('newnft')) {
          const urlPath = Utils.get(this.props, "location.pathname", "");
          const parts =  urlPath.split("/");
          console.log("LISTING", parts);
          if(parts.length >= 3) {
              const nameParts = parts[2].split("-");
              // console.log(">>>>>>>>> 0");
              Api.getAfterpartyListing(nameParts[nameParts.length-1], this.processMusic.bind(this));
          } else {
              console.log("Listing not found");
          }
      } else if(this.state.selected == 'seller') {
          // console.log(">>>>>>>>> 1");
          Api.getSeller(this.processMusic.bind(this));
      } else if(this.state.selected == 'search') {
          // console.log(">>>>>>>>> 2");
          Api.getSearch(Utils.getRequest('q'), this.processMusic.bind(this));
      } else {
          // console.log(">>>>>>>>> 3");
          Api.apiLoadMusic(this.processMusic.bind(this));
      }

    }
    window.ReactTestUtils = ReactTestUtils;
  }

  calcTimeStr(now, then) {
      const st = this.state;

      let timeStr;
      const dropMinutes = then.diff(now, 'minutes');
      const dropHours = then.diff(now, 'hours');
      const dropDays = then.diff(now, 'days');
      if(dropHours > 24) {
          timeStr = dropDays + (dropDays == 1 ? " day" : " days");
      } else if(dropMinutes > 60) {
          timeStr = dropHours + (dropHours == 1 ? " hour" : " hours");
      } else {
          timeStr = dropMinutes + (dropMinutes == 1 ? " minute" : " minutes");
      }
      return timeStr;
  }

  processMusic(response, error) {
      const pr = this.props;
      const st = this.state;

      if(!response) {
          console.log("No response to process >>>");
          return;
      }
      let tempState;
      if(st.selected == 'item' || st.selected == 'nftlisting' || st.selected == 'soinlisting' || st.selected.includes("newnft")) {
          tempState = {
              mediaItem: response.data,
              includes: response.includes,
              //relatedSoins: data.includes.relatedSoins,
          };
          var related = Utils.get(response, "includes.relatedSoins");
          if(related) {
              tempState["relatedSoins"] = related;
          }
          console.log("soinlisting tempState", tempState);
      } else if(st.selected == 'search') {
          tempState = {
              results: response["data"],
          };
      } else if(this.state.selected == 'seller') {
          tempState = {
              seller: response["data"],
          };
      } else {
          let drops = response["data"]["drops"];
          var now = moment();
          for(var idx in drops) {
              var drop = drops[idx];
              //const secs = Math.floor(Math.random() * (drop["id"] * 16000));
              var then = moment(drop['drop_date_begin']); //moment().add(secs, 'seconds');
              if(now.isBefore(then)) {
                  //drop["until"] = moment().to(then);
                  drop["until"] = this.calcTimeStr(now, then);
              } else {
                  drop["remaining"] = this.calcTimeStr(now, moment(drop['drop_date_end'])); //moment().to(moment(drop['drop_date_end']));
              }
              //console.log("WHEN", moment().to(then));

          }
          console.log("mediaItem response >>>", response);
          tempState = {
              drops: response["data"]["drops"],
              marketplace: response["data"]["marketplace"],
              mycollection: response["data"]["mycollection"],
              featured: response["data"]["featured"][0],
              // For testing item screen
              mediaItem: response["data"]["drops"][0],
          };
      }
      this.setState(tempState);
  }

  change(selection, obj, dirtyProfile) {
      const st = this.state;
      console.log('Changing selection: ', selection, obj, st.musicLoaded);
      if (selection !== 'logout') {
          this.setState({ selected: selection, showMobileFlyout: false, mediaItem:obj }, () => {
          //console.log("Checking for reload", selection, this.state.musicLoaded);
          if(dirtyProfile) {
              //console.log("apiLoadProfile");
              // Api.apiLoadProfile(this.processProfile.bind(this));
          }
          if((selection == 'home' || selection == "dashboard") && !st.musicLoaded) {
               Api.apiLoadMusic(this.processMusic.bind(this));
          } else if((selection === 'item' || selection === 'soinlisting' || selection === 'nftlisting') && !obj.relatedSoins) {
               console.log("LOAD RELATED SOINS");
               Api.getAfterpartyListing(obj["id"], this.processMusic.bind(this));
          } else if(selection == 'search') {
               console.log("SEARCH", obj);
               Api.getSearch(obj, this.processMusic.bind(this));
          }
      });
    } else {
      this.setState({ showLogout: true, showMobileFlyout: false, mediaItem:obj });
    }
  }

  handleConfirmCancel() {
    this.setState({ showLogout: false });
  }

  handleFlyoutButton() {
      console.log("Display flyout");
      this.setState({showMobileFlyout:!this.state.showMobileFlyout});
  }

  showLogout() {
    this.setState({ showLogout: true });
  }
  handleConfirmLogout() {
    // TODO: Make logout
    axios.get(`/api/v1/logout`, {}).then(res => {
      console.log('RETURNED', res);
      this.props.history.push('/login');
    });
  }

  handleorder() {
    this.setState({ selected: 'orders' });
  }
  handleOrders(data) {
    this.setState(data);
  }

    isFlyoutMobile() {
        const pr = this.props;
        const st = this.state;

        if(pr.route === "nftlisting") {
            return false;
        }

        return (pr.screen.mobile && !pr.screen.mobileLandscape) && // it's mobile && it's in portrait
               !(!Utils.get(pr.user, "email", false) ) // user doesn't exist && it's the room
    }

    isFlyoutMain() {
        const pr = this.props;
        const st = this.state;

        return !pr.screen.mobile
    }

    renderFlyoutMain() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(this.isFlyoutMain()) {
            return (
                <FlyoutMain
                    screen={pr.screen}
                    history={pr.history}
                    selected={st.selected}
                    change={this.change.bind(this)}
                    user={pr.user}
                    version={st.version}
                />
            )
        }
    }

    renderFlyoutMobile() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(this.isFlyoutMobile()) {
            return (
                <FlyoutMobile
                    screen={pr.screen}
                    history={pr.history}
                    selected={st.selected}
                    change={this.change.bind(this)}
                    user={pr.user}
                    version={st.version}
                />
            )
        }
    }

    render() {
        const st = this.state;
        const pr = this.props;
        const sty = this.styles;

        let showFlyout = !st.isPortal;
        let showFlyoutNavbar = false;
        // Hide flyout if event or room
        if(pr.screen.width <= 768) {
            showFlyout = false;
            showFlyoutNavbar = true;
        }

        // Allow force override
        if(Utils.getRequest('flyout')) {
            showFlyout = true;
        }
        // TODO: Temp until SoinItem moved to StaticSplash
        let dashboardSoinComponent = {
            ...sty.dashboardSoinComponent,
            marginLeft: false && this.isFlyoutMain() ? 250 : 0,
            //marginTop: this.isFlyoutMobile() ? 60 : 0,
            marginTop: this.isFlyoutMobile() ? 0 : 0,
        };

        return (
            <Div className="dashboardSoinComponent" style={dashboardSoinComponent}>
                {!(st.selected === 'item' || st.selected === 'soinlisting') ?
                    <Div>
                        {this.renderFlyoutMain()}
                        {this.renderFlyoutMobile()}
                    </Div>
                    :
                    null
                }

                {(st.selected === 'login') ?
                    <Div style={sty.registerContainer}>
                        <Login
                            screen={pr.screen}
                            user={pr.user}
                            mediaItem={st.mediaItem}
                            change={this.change.bind(this)}
                            setUser={pr.setUser.bind(this)}
                        />
                    </Div>
                    :
                    null
                }

                {(st.selected === 'dashboard' || st.selected === 'soin') ?
                    <DashboardSoinMain
                        screen={pr.screen}
                        history={pr.history}
                        user={pr.user}
                        drops={st.drops}
                        marketplace={st.marketplace}
                        mycollection={st.mycollection}
                        featured={st.featured}
                        change={this.change.bind(this)}

                        history={pr.history}
                        hostUrl={st.hostUrl}
                        handlelogout={this.handleConfirmLogout.bind(this)}
                    />
                    :
                    null
                }

                {st.mediaItem && (st.selected === 'item' || st.selected === 'soinlisting' || st.selected === 'nftlisting') ?
                    <>
                        <WebsiteHeader
                            screen={pr.screen}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            setUser={pr.setUser.bind(this)}
                            selected={st.selected}
                            history={pr.history}
                            change={this.change.bind(this)}
                        />
                        { st.selected === 'soinlisting' ?
                            <DashboardSoinItem
                                screen={pr.screen}
                                user={pr.user}
                                setUser={pr.setUser.bind(this)}
                                mediaItem={st.mediaItem}
                                includes={st.includes}
                                listing={st.mediaItem.listing}
                                relatedSoins={st.mediaItem.relatedSoins}
                                change={this.change.bind(this)}
                                history={pr.history}
                                mobile={pr.screen.mobile}
                            />
                            :
                            <NftItem
                                screen={pr.screen}
                                user={pr.user}
                                setUser={pr.setUser.bind(this)}
                                mediaItem={st.mediaItem}
                                includes={st.includes}
                                listing={st.mediaItem.listing}
                                relatedSoins={st.mediaItem.relatedSoins}
                                change={this.change.bind(this)}
                                history={pr.history}
                                mobile={pr.screen.mobile}
                            />
                        }
                    </>
                    :
                    null
                }

                {(st.selected === 'search') ?
                    <DashboardSearch
                        screen={pr.screen}
                        user={pr.user}
                        mediaItem={st.mediaItem}
                        change={this.change.bind(this)}
                        results={st.results}
                        history={pr.history}
                    />
                    :
                    null
                }

                {(st.selected === 'seller') ?
                    <DashboardSeller
                        screen={pr.screen}
                        user={pr.user}
                        mediaItem={st.mediaItem}
                        change={this.change.bind(this)}
                        seller={st.seller}
                        history={pr.history}
                    />
                    :
                    null
                }

                {(st.selected === 'discordverify') ?
                    <DashboardDiscordVerify
                        screen={pr.screen}
                        user={pr.user}
                        mediaItem={st.mediaItem}
                        change={this.change.bind(this)}
                        history={pr.history}
                    />
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        dashboardSoinComponent: {},
        registerContainer: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: 50,
        },
    };
}
