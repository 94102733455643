import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';

import PanelQa from '../elements/PanelQa.js';

import Div from './Div';

export default class TestDev extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="testDevComponent" style={sty.testDevComponent}>
                <PanelQa
                    screen={pr.screen}
                    title="Lorem ipsum dolor sit amet consecterur?"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo"
                />
            </Div>
        );
    }

    styles = {
        testDevComponent: {
            backgroundColor: Colors.eggplant,
            minHeight: "calc(100vh - 72px)",
            marginTop: 72,
            padding: 20,
            paddingTop: 60,
            color: "white",
        },
    };
}
