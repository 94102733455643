import React from 'react';
import * as Cookies from "js-cookie";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import DebugBox from '../elements/DebugBox';

import Div from './Div';
import AfterpartyRoomBadge from './AfterpartyRoomBadge';
import ConnectWallet from './ConnectWallet';

import followUser from '../assets/icons/follow-user.svg';

export default class AfterpartyRoomBadges extends React.Component {
    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        ll._("SIGNAL", "green", pr.currentRoomSignal);

        return (
            <Div className="roomItems afterpartyRoomBadgesComponent" style={{...sty.roomItems, ...pr.style}}>
                {pr.badgeNotifications.map((badgeNotification, badgeNotificationIndex) => {
                    if(pr.badgeNotifications.length >= 2 && badgeNotificationIndex == 0) {
                        return null;
                    }

                    return (
                        <ConnectWallet
                            badge
                            key={`badge-notification-${badgeNotificationIndex}`}
                            style={null}
                            screen={pr.screen}
                            user={pr.user}
                            eventItem={pr.roomItem.event}
                            // handleSetWallet={this.handleSetWallet.bind(this)}
                            // walletTypes={this.getWalletTypes()}
                            // handleBuyClick={pr.handleBuyClick.bind(this)}
                            badgeNotification={badgeNotification}
                            showRoomNotification={pr.showRoomNotification}
                            handleClickRoomNotification={pr.handleClickRoomNotification.bind(this)}
                            currentRoomSignal={pr.currentRoomSignal}
                        />
                    );
                })}

                <Div
                    className="followArtistIcon"
                    style={{...{marginLeft: 4, marginRight: 2}, ...StylesPlatform.cursorPointer}}
                    onClick={pr.handleShowFollowModal.bind(this)}
                >
                    <Image src={followUser} style={{width: 20, marginLeft: 10}} />
                </Div>
            </Div>
        );
    }

    styles = {
        roomItems: {
            display: "flex",
            marginRight: 10,
            alignItems: "center",
        },
        roomNotificationContainer: {
            position: "relative",
        },
        roomNotification: {
            position: "absolute",
            alignItems: "center",
            display: "flex",
            top: 43,
            right: -44,
            minWidth: 200,
            padding: 10,
            backgroundColor: "white",
            borderRadius: 8,
            zIndex: 1000,
            color: Colors.purple,
            fontSize: 12,
        },
        absoluteArrowUp: {
            position: "absolute",
            top: -14,
            right: 41,
            width: 0,
            height: 0,
            borderLeft: "15px solid transparent",
            borderRight: "15px solid transparent",
            borderBottom: "15px solid white",
        },
        notificationCloseButton: {
            position: "absolute",
            top: -4,
            right: 4,
            fontSize: 20,
            color: "#aaa",
        },
        notificationBadgeText: {
            position: "absolute",
            top: 7,
            left: 13,
            fontSize: 9,
            color: Colors.magenta,
        },
    };
}
