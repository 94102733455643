import React from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import StyleUtils from '../modules/StyleUtils';
import Colors from '../modules/Colors';

import Div from './Div';
import Image from '../elements/Image';
import Button from '../elements/Button';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';

import DashboardSoinFlyout from '../components/DashboardSoinFlyout';

import apLogo from '../assets/ap_side_logo300.jpg';

export default class FlyoutMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleLogoClick() {
        const pr = this.props;
        window.location.href = "/";
        //pr.history.push('/home');
        //pr.change('home');
    }

    renderAfterpartyLogo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Image className="logoContainerStyle" style={sty.logoContainerStyle} src={apLogo} onClick={this.handleLogoClick.bind(this)} />
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="flyoutMainComponent" style={sty.flyoutMainComponent}>
                {this.renderAfterpartyLogo()}
                <DashboardSoinFlyout
                    screen={pr.screen}
                    history={pr.history}
                    selected={pr.selected}
                    change={pr.change.bind(this)}
                    user={pr.user}
                    version={pr.version}
                />
            </Div>
        );
    }

    styles = {
        flyoutMainComponent: {
            position: "fixed",
            backgroundColor: "white",
            color: "#564DB2",
            width: 250,
            flexShrink: 0,
            marginLeft: -250,
            height: "100vh",
            overflowY: "scroll",
        },
        logoContainerStyle: {
            padding: "30px 20px 20px 22px",
            cursor: "pointer",
            width: 208,
            height: 63,
        },
    };
}
