import React from 'react';

export default class H1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const pr = this.props;

        return (
            <h1 {...pr}>
                {pr.children}
            </h1>
        );
    }

    styles = {};
}
