import React from 'react';

import Utils from '../modules/Utils';
import StylesPlatform from '../modules/StylesPlatform';

import Image from './Image';

import Div from '../components/Div';

import spinner from '../assets/images/spinner-2.gif';

import afterpartyLogo from "../assets/images/logos/ap logo main.png";
import afterpartyLogoSmall from "../assets/images/logos/ap logo small.png"
import afterpartyLogoWhite from "../assets/images/logos/ap logo main white.png"
import afterpartyLogoBlack from "../assets/images/logos/ap logo main black.png"
import afterpartyLogoWhiteSmall from "../assets/images/logos/ap logo small white.png"

export default class AfterpartyLogo extends React.Component {
    clickLogo() {
        const pr = this.props;
        if(pr.disableClick == "true" || pr.disableClick == true) {
            return;
        }

        if(pr.clickLogo) {
            pr.clickLogo();
        } else {
            Utils.gotoRoute(pr, "/home");
        }
    }

    chooseLogoSrc() {
        const pr = this.props;

        switch(pr.logo) {
            case "white":
                return afterpartyLogoWhite;
            case "small":
                return afterpartyLogoSmall;
            case "black":
                return afterpartyLogoBlack;
            case "smallWhite":
                return afterpartyLogoWhiteSmall;
            default:
                return afterpartyLogo;
        }
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        let size = {
            width: pr.width ? pr.width : 244,
            height: pr.height ? pr.height : "auto",
        };

        let logoSrc = this.chooseLogoSrc();

        if(pr.defaultProfileImage) {
            return (
                <Div style={{borderRadius: 300, display: "flex", justifyContent: "center", alignItems: "center", aspectRatio: 1, padding: 20, width: pr.width ?? 100, height: pr.width ?? 100, boxSizing: "border-box"}}>
                    <Image
                        style={{widht: "100%"}}
                        src={afterpartyLogoSmall}
                    />
                </Div>
            )
        }

        return (
            <Image
                className="afterpartyLogoElement"
                src={logoSrc}
                style={{...sty.afterpartyLogoElement, ...pr.style, ...size, ...StylesPlatform.cursorPointer}}
                onClick={this.clickLogo.bind(this)}
            />
        )
    }

    styles = {
        afterpartyLogoElement: {},
    }
}
