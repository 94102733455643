import React from 'react';

export default class Div extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const pr = this.props;

        return (
            <div {...pr}>
                {pr.children}
            </div>
        );
    }

    styles = {
        label: {
            width: '100%',
            padding: '12px',
        },
        error: {
            textAlign: 'left',
            fontSize: 12,
            color: 'red',
            paddingTop: 6,
        },
        valid: {
            textAlign: 'left',
            fontSize: 12,
            color: 'green',
            paddingTop: 6,
        }
    };
}
