import React from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Api from '../modules/Api';
import StylesPlatform from '../modules/StylesPlatform';

import Icon from '../elements/Icon';

import Div from '../components/Div';

import './museum.css';

export default class Museum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsOld: [
                "#a4old",
                "#a3old",
                "#a2old",
                "#a1old",
                "#a0old"
            ],
            items: [
                {
                    id: "#a0",
                    src: "/renders/soinasset_99_3605f5482f9508b22604e4b7d5b42a86.png",
                    className: "posHidden2Right",
                },
                {
                    id: "#a1",
                    src: "/renders/soinasset_96_24c25751d8eb03d822c1bb45fb2f82f8.png",
                    className: "posBackRight",
                },
                {
                    id: "#a2",
                    src: "/renders/soinasset_97_61da639d8014172d9be4c1d297cac6ae.png",
                    className: "posMiddleRight",
                },
                {
                    id: "#a3",
                    src: "/renders/soinasset_108_0e48555dda0e2b04586453a78572377a.png",
                    className: "posFrontRight",
                },
                {
                    id: "#a4",
                    src: "/renders/soinasset_104_6ade8e0ee1e76a5097b8e736cc4ceb87.png",
                    className: "posHiddenRight",
                },
            ],
            stage: 0,
            stages: {
                0: {remove: "posBack", add: "posMiddle"},
                1: {remove: "posMiddle", add: "posFront"},
                2: {remove: "posFront", add: "posBack"},
            },
        };

        this.soinWidth = 600;
        this.classNames = [
            "posHidden2Right",
            "posBackRight",
            "posMiddleRight",
            "posFrontRight",
            "posHiddenRight",
        ]
    }

    componentDidMount() {
        $("body").addClass("noScrollBody");

        Api.apiLoadMusic((response) => {
            if(response.errors.length) {
                console.log("ERRORS");
            } else if(Utils.get(response, "data.mycollection")) {
                let items = response.data.mycollection.slice(0, 5);

                for(let i = 0; i < this.classNames.length; ++i) {
                    items[i].className = this.classNames[i];
                    items[i].src = this.state.items[i].src;
                }

                this.setState({
                    items: items,
                });
            }

        })
    }

    componentWillUnmount() {
        $("body").removeClass("noScrollBody");
    }

    // NOTE currently unused
    arrayRotate(arr, reverse) {
        arr.push(arr.shift())

        return arr;
    }

    // NOTE only need to rotate classnames clockwise
    advance(itemIndex) {
        const st = this.state;

        // NOTE making deep copies of the array
        let tempItems = JSON.parse(JSON.stringify(st.items));
        let newItems = JSON.parse(JSON.stringify(st.items));
        // NOTE shifts array clockwise (first element goes to back)
        tempItems.push(tempItems.shift());
        // NOTE shifts array counter clockwise (last element goes to front)
        // tempItems.unshift(tempItems.pop());

        tempItems.map((tempItem, tempItemIndex) => {
            newItems[tempItemIndex].className = tempItem.className;
        })

        // NOTE old implementation
        // let itemsOld = st.itemsOld;
        // $(itemsOld[0])
        //     .removeClass("posHiddenRight")
        //     .addClass("posHidden2Right");
        // $(itemsOld[1])
        //     .removeClass("posFrontRight")
        //     .addClass("posHiddenRight");
        // $(itemsOld[2])
        //     .removeClass("posMiddleRight")
        //     .addClass("posFrontRight");
        // $(itemsOld[3])
        //     .removeClass("posBackRight")
        //     .addClass("posMiddleRight");
        // $(itemsOld[4])
        //     .removeClass("posHidden2Right")
        //     .addClass("posBackRight");
        // itemsOld.push(itemsOld.shift());

        this.setState({
            items: newItems,
            // itemsOld: itemsOld,
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let soinStyle = {width: this.soinWidth};

        return (
            <Div
                className="museumComponent"
                style={sty.museumComponent}
                scroll="no"
            >
                <Div className="museumItems" style={sty.museumItems} scroll="no">
                    {/* <img onClick={this.advance.bind(this)} id="a0" className="soin posHidden2" style={{width: 400}} src="/renders/soinasset_99_3605f5482f9508b22604e4b7d5b42a86.png" />
                    <img onClick={this.advance.bind(this)} id="a1" className="soin posBack" style={{width: 400}} src="/renders/soinasset_96_24c25751d8eb03d822c1bb45fb2f82f8.png" />
                    <img onClick={this.advance.bind(this)} id="a2" className="soin posMiddle" style={{width: 400}} src="/renders/soinasset_97_61da639d8014172d9be4c1d297cac6ae.png" />
                    <img onClick={this.advance.bind(this)} id="a3" className="soin posFront" style={{width: 400}} src="/renders/soinasset_108_0e48555dda0e2b04586453a78572377a.png" />
                    <img onClick={this.advance.bind(this)} id="a4" className="soin posHidden" style={{width: 400}} src="/renders/soinasset_104_6ade8e0ee1e76a5097b8e736cc4ceb87.png" /> */}

                    {/* <img onClick={this.advance.bind(this)} id="a0old" className="soin posHidden2Right" style={soinStyle} src="/renders/soinasset_99_3605f5482f9508b22604e4b7d5b42a86.png" />
                    <img onClick={this.advance.bind(this)} id="a1old" className="soin posBackRight" style={soinStyle} src="/renders/soinasset_96_24c25751d8eb03d822c1bb45fb2f82f8.png" />
                    <img onClick={this.advance.bind(this)} id="a2old" className="soin posMiddleRight" style={soinStyle} src="/renders/soinasset_97_61da639d8014172d9be4c1d297cac6ae.png" />
                    <img onClick={this.advance.bind(this)} id="a3old" className="soin posFrontRight" style={soinStyle} src="/renders/soinasset_108_0e48555dda0e2b04586453a78572377a.png" />
                    <img onClick={this.advance.bind(this)} id="a4old" className="soin posHiddenRight" style={soinStyle} src="/renders/soinasset_104_6ade8e0ee1e76a5097b8e736cc4ceb87.png" /> */}

                    {st.items.map((item, itemIndex) => {
                        const {
                            id,
                            className,
                            artist_image_url,
                            artist_name,
                            album_title,
                            name,
                            src,
                        } = item;

                        return (
                            <Div
                                className={`soin ${className} museumItemContainer`}
                                style={sty.museumItemContainer}
                            >
                                <img
                                    onClick={this.advance.bind(this, itemIndex)}
                                    key={`${itemIndex}-${name}`}
                                    id={id}
                                    style={soinStyle}
                                    src={src}
                                />
                                <Div className="itemInfoContainer" style={sty.itemInfoContainer}>
                                    <Div style={sty.artistName}>{artist_name}</Div>
                                    <Div style={sty.nftName}>{album_title}</Div>
                                    {/* <Div style={sty.nftRatingContainer}>
                                        <Div style={sty.nftRatingIcon}>
                                            <Icon icon="microphone" size={15} />
                                        </Div>
                                        <Div style={sty.nftRating}>
                                            16.08
                                        </Div>
                                    </Div> */}
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        );
    }

    styles = {
        museumComponent: {
            width: "100%",
            backgroundColor: Colors.purpleLight1,
            height: "100vh",
        },
        museumItems: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
        },
        museumItemContainer: {
            display: "flex",
            flexDirection: "column",
            color: "white",
        },
        itemInfoContainer: {
            marginLeft: 100,
            marginTop: -50,
        },
        artistName: {
            fontSize: 16,
        },
        nftName: {
            fontSize: 34,
            fontWeight: "bold",
        },
        nftRatingContainer: {
            display: "flex",
            alignItems: "center",
        },
        nftRatingIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 100,
            backgroundColor: Colors.pink,
            height: 30,
            width: 30,
        },
        nftRating: {
            marginLeft: 10,
            fontSize: 18,
        },
    };
}
