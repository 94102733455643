import React from 'react';

import Div from '../components/Div';
import Input from '../elements/Input';

export default class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    if(this.props.change) {
        this.props.change(event);
    }
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      console.log('do validate');
        if(this.props.validate) {
            this.props.validate(event, this.state.value);
        }
    }
  }

  handleClick(event) {
    event.stopPropagation();
  }

  render() {
    const placeholderClass = this.props.placeholderClass ? this.props.placeholderClass : "whitePlaceholder";

    return (
        <Div style={{...this.styles.searchContainer,...this.props.style}} onClick={this.handleClick.bind(this)}>
          <i style={{paddingTop:3, fontSize:13}} className="fa fa-search"></i>
          <input
            className={placeholderClass}
            type="text"
            style={{width: "80%", border: "none", background:"none",marginLeft:8,fontSize:10}}
            placeholder={this.props.placeholder}
            value={this.state.value}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown.bind(this)}
            />
        </Div>

    );
  }

  styles = {
    searchContainer: {
      marginLeft:10,
      position:"absolute",
      display: "flex",
      top: 6,
      left: 6,
      backgroundColor: "rgba(255,255,255,0.1)",
      borderRadius: 10,
      padding: 10,
      color:"white",
    },
  }
}



/*
<Div className='col'>
                <Div style={{background:"white",borderRadius:6,paddingTop:5,paddingBottom:5,paddingLeft:9,}}>
                    <i style={{color:"orange"}} className="fa fa-search"></i> <span style={{color:"gray"}}>Search</span>
                </Div>
            </Div>
*/