import React from 'react';

import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Div from '../components/Div';
import Image from './Image';

export default class ConnerCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemPosition: 0,
        };

        this.leftScrollPos = undefined;
    }

    componentDidMount() {
        $(".carouselContainer").on('scroll', () => {
            let leftScrollDistance = $(".carouselContainer").scrollLeft();
            let itemWidth = $(".carouselItem").outerWidth(true);
            let newItemPosition = Math.floor(leftScrollDistance / itemWidth);

            if(leftScrollDistance % itemWidth > 0) { // NOTE: if there is a remainder from the scroll window width, you hit the wall
                newItemPosition++;
            }

            if(leftScrollDistance == 0) { // NOTE: hit the wall
                newItemPosition = 0;
            }

            this.setState({
                itemPosition: newItemPosition,
            });
        });
    }

    itemPositionChange(scrollDirection) {
        const pr = this.props;
        const st = this.state;

        const isLeft = scrollDirection == -1;
        const isRight = scrollDirection == 1;

        const totalItems = pr.items.length;

        let carouselContainerWidth = $(".carouselContainer").width();
        let carouselItemWidth = $(".carouselItem").outerWidth(true);

        let visibleItems = Math.floor(carouselContainerWidth / carouselItemWidth);
        let leftoverVisibleItems = totalItems - visibleItems;

        let leftScrollPos = $('.carouselContainer').scrollLeft();

        let hitWall = false;

        if(st.itemPosition >= leftoverVisibleItems && isRight) {
            hitWall = true;
        }

        if((totalItems - st.itemPosition) < visibleItems && isLeft) {
            hitWall = true;
        }

        if(st.itemPosition == 0 && isLeft) {
            hitWall = true;
        }

        // console.log("direction >>>", scrollDirection);
        // console.log("leftoverVisibleItems >>>", leftoverVisibleItems);
        // console.log("st.itemPosition INTIIAL >>>", st.itemPosition);
        // console.log("hitWall >>>", hitWall);

        let newItemPosition = -1;
        if(hitWall) {
            if(isRight) {
                leftScrollPos = -1 * (carouselItemWidth);
                newItemPosition = 0;
            } else {
                leftScrollPos = 10000; // NOTE: scroll to end
                newItemPosition = leftoverVisibleItems;
            }
        } else {
            newItemPosition = (st.itemPosition + scrollDirection) % totalItems;
        }

        this.setState({itemPosition: newItemPosition}, () => {
            // console.log("st.itemPosition AFTER >>>", this.state.itemPosition);
            // console.log("<<<<<<< END >>>>>>>>");
            // console.log(">>>");
        });

        this.leftScrollPos = leftScrollPos;
        $(".carouselContainer").animate({scrollLeft: this.leftScrollPos + (scrollDirection * carouselItemWidth)}, 300);
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // let colors = ["red", "green", "blue", "purple"];

        let size = Utils.get(pr, "size", 150);
        if(pr.screen.mobileLandscape) {
            size = 120;
        } else if(pr.screen.mobile) {
            size = 80;
        }
        const tempUrl = "https://www.billboard.com/wp-content/uploads/media/02-the-weeknd-press-2019-cr-Nabil-Elderkin-billboard-1548.jpg";
        const imagePath = Utils.get(pr, "imagePath", "image");

        return (
            <Div className="carouselElement" style={{...sty.carouselElement, ...pr.style}}>
                <Div className="leftArrow" style={sty.leftArrow} onClick={this.itemPositionChange.bind(this, -1)}>
                    ≤
                </Div>

                <Div className="carouselContainer" style={{...sty.carouselContainer, ...pr.style}}>
                    {pr.items.map((item, index) => {
                        return (
                            <Div
                                key={`carouselItem-${index}`}
                                className="carouselItem"
                                // style={{...sty.carouselItem, ...{backgroundColor: colors[index % 4]}}}
                                style={{...sty.carouselItem, ...{height: size, width: size}, ...{backgroundImage: `url("${Utils.get(item, imagePath, tempUrl)}")`}}}
                                // style={sty.carouselItem}
                            >
                                {/* {item.name} {index} */}
                            </Div>
                        )
                    })}
                </Div>

                <Div className="rightArrow" style={sty.rightArrow} onClick={this.itemPositionChange.bind(this, 1)}>
                    ≥
                </Div>
            </Div>
        );
    }

    styles = {
        carouselElement: {
            display: "flex",
        },
        carouselContainer: {
            display: "flex",
            overflow: "scroll",
            // "overflow-x": "hidden",
        },
        carouselItem: {
            height: 150,
            width: 150,
            margin: 10,
            flexShrink: 0,
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        leftArrow: {
            height: 30,
            width: 30,
            backgroundColor: "black",
            flexShrink: 0,
        },
        rightArrow: {
            height: 30,
            width: 30,
            backgroundColor: "black",
            flexShrink: 0,
        },
    };
}
