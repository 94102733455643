import React from 'react';
import Lottie from "lottie-react"
// import Lottie from "lottie-react-web"
import checkboxAnimation from "../assets/52645-checkmark.json"

import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';

import Icon from './Icon';

import Div from '../components/Div';

export default class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    togglePanel() {
        const pr = this.props;

        if(pr.togglePanel) {
            pr.togglePanel();
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="panelElement" style={{...sty.panelElement, ...pr.style}}>
                <Div className="panelHeader" style={sty.panelHeader}>
                    <Div className="panelTitle" style={sty.panelTitle}>
                        {pr.title}
                    </Div>
                    {pr.checkboxPanel ?
                        <Div
                            className="checkboxContainer"
                            style={sty.checkboxContainer}
                            onClick={this.togglePanel.bind(this)}
                        >
                            {/* {pr.checked ? <Icon icon="check" size={20} color={Colors.purpleDarkBackground} style={sty.checkboxIcon} /> : null} */}
                            {pr.checked ?
                                <Lottie
                                    animationData={checkboxAnimation}
                                    style={sty.checkboxAnimation}
                                    loop={false}
                                />
                                :
                                null
                            }
                        </Div>
                        :
                        null
                    }

                </Div>
                <Div className="panelContainer" style={sty.panelContainer}>
                    {pr.children}
                </Div>
            </Div>
        );
    }

    styles = {
        panelElement: {
            borderRadius: 14,
            overflow: "hidden",
            color: "white",
        },
        panelHeader: {
            display: "flex",
            alignItems: "center",
            padding: 20,
            backgroundColor: Colors.indigoLight,
        },
        panelTitle: {
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
        },
        checkboxContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: 4,
            marginRight: 10,
            width: 30,
            height: 30,
        },
        panelContainer: {
            backgroundColor: Colors.indigoLighter,
        },
        checkboxAnimation: {
            width: 400,
            height: 400,
            marginTop: -7,
            marginRight: -10,
            marginBottom: -10,
            marginLeft: -10,
        },
    };
}
