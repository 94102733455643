import React from 'react';
import ReactModal from 'react-modal';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import Icon from './Icon';

import Div from '../components/Div';

export default class Checkbox extends React.Component {
    handleCheckboxClick() {
        const pr = this.props;

        if(pr.onClick) {
            pr.onClick();
        }
    }

    getCheckColor() {
        switch (this.props.color) {
            case "white":
                return "white"
            default:
                return Colors.purple
        }
    }

    getCheckboxColors() {
        switch (this.props.color) {
            case "white":
                return {
                    border: `1px solid white`
                }
            default:
                return {
                    // border: `1px solid ${Colors.purple}`,
                    backgroundColor: "white",
                }
        }
    }

    render() {
        const pr = this.props;
        let sty = this.styles;

        return (
            <Div
                className="checkboxElement"
                style={{...sty.checkbox, ...pr.style, ...this.getCheckboxColors()}}
                onClick={this.handleCheckboxClick.bind(this)}
            >
                {pr.checked ? <Icon icon="check" size={14} color={this.getCheckColor()} /> : null}
            </Div>
        );
    }

    styles = {
        checkbox: {
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 20,
            height: 20,
            cursor: "pointer",
            flexShrink: 0,
        }
    }
}
