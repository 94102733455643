import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';

import Icon from './Icon';

import Div from '../components/Div';

export default class Textarea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
        };
    }

    getInputColors() {
        switch (this.props.color) {
            case "purple":
                return {
                    backgroundColor: Colors.plumLight,
                    color: "white",
                };
            case "indigo":
                return {
                    backgroundColor: Colors.indigo,
                    color: "white",
                    border: "none",
                };
            default:
                null;
        }
    }

    getInputTypeStyle() {
        switch (this.props.inputType) {
            case "search":
                return null;
            default:
                return null;
        }
    }

    onChange(e) {
        const pr = this.props;

        const value = e.target.value;

        if (pr.maxLength && value.length > pr.maxLength) {
            this.setState({
                isError: true,
            });
        } else {
            this.setState({
                isError: false,
            });
        }

        pr.onChange(e);
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const errorStyle = pr.isError ? sty.errorStyle : null;

        let inputColors = this.getInputColors();

        let resize = pr.resize ? {} : { resize: "none" };
        const maxLength = parseInt(Utils.get(pr, "maxLength")) > 0 ? parseInt(Utils.get(pr, "maxLength")) : null;

        return (
            <>
                <textarea
                    id={Utils.get(pr, "id", "")}
                    style={{ ...sty.textarea, ...inputColors, ...errorStyle, ...resize, ...pr.style }}
                    className={`textareaElement ${pr.className}`}
                    placeholder={pr.placeholder}
                    type={pr.type}
                    value={pr.value}
                    onChange={this.onChange.bind(this)}
                    maxlength={maxLength}
                />
                {pr.maxLength && pr.value.length > 150 ?
                    <Div style={{...sty.characterCount, ...{color: st.isError ? Colors.redError : "rgba(255, 255, 255, 0.7)"}}}>
                        {pr.value.length} / {pr.maxLength}
                    </Div>
                    :
                    null
                }
            </>
        );
    }

    styles = {
        textarea: {
            padding: "15px 20px",
            border: "none",
            borderRadius: 8,
            backgroundColor: Colors.indigoLighter,
            color: "white",
            fontSize: 14,
            fontFamily: "Nyata",
            fontWeight: 300,
            width: "100%",
            boxSizing: "border-box",
            placeholderTextColor: "white",
            minHeight: 120,
            outline: "none",
        },
        errorStyle: {
            borderColor: Colors.redError,
        },
        inputError: {
            marginTop: 5,
            fontSize: 12,
            color: Colors.redError,
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        characterCount: {
            marginTop: 5,
            fontSize: 12,
            textAlign: 'right',
            fontWeight: 400,
        },
    };
}
