import React from 'react';
import Utils from '../modules/Utils';

export default class Scroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      clickBorder: {},
      startItem: 1,
      numSlots: this.props.numSlots ? this.props.numSlots : 5,
      version: Utils.getRequest('version', 5),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
      //console.log("newProps", newProps);
      let tempState = {};
      let newState = false;
      if(newProps.numSlots && newProps.numSlots != this.state.numSlots) {
          tempState["numSlots"] = newProps.numSlots;
          newState = true;
      }
      if(newState) {
          this.setState(tempState);
      }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  handleClick(event) {
    const _self = this;
    this.setState({ clickBorder: this.styles.clickBorder });
    if (this.props.clickHandler) {
      this.props.clickHandler();
    }

    setTimeout(function () { if (!_self.unmounting) { _self.setState({ clickBorder: {} }); } }, 200);
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  handleLeftArrowClick() {
      let newStartItem = this.state.startItem;
      if(!this.props.singleScroll) {
          newStartItem -= this.state.numSlots;
      } else {
          newStartItem--;
      }
      newStartItem = newStartItem > 0 ? newStartItem : 1;
      this.setState({startItem: newStartItem});
  }

  handleRightArrowClick() {
      let newStartItem = this.state.startItem;
      if(!this.props.singleScroll) {
          newStartItem += this.state.numSlots;
      } else {
          newStartItem++;
      }
      const limitStartPosition = (this.props.items.length - this.state.numSlots) + 1;
      newStartItem = newStartItem < limitStartPosition ? newStartItem : limitStartPosition;
      console.log("handleRightArrowClick", this.props.items.length, this.state.numSlots, newStartItem, limitStartPosition);
      this.setState({startItem: newStartItem});
  }


  renderLeftArrow(itemNum) {
      let arrowStyle = this.state.version > 5 ? this.styles.arrowsV6 : this.styles.arrows;
      if(this.props.whiteArrows) {
          arrowStyle = this.styles.arrowsV6
      }
      return (
        <div style={arrowStyle} onClick={this.handleLeftArrowClick.bind(this)}>
            {itemNum > 1 ?
            <i className="left-arrow fa fa-chevron-left" />
            :
            <i className="left-arrow fa fa-empty" />
            }
        </div>
      );
  }
  renderRightArrow(itemLen, endItem) {
      //console.log("IT", itemLen, endItem);
      let arrowStyle = this.state.version > 5 ? this.styles.arrowsV6 : this.styles.arrows;
      if(this.props.whiteArrows) {
          arrowStyle = this.styles.arrowsV6
      }
      return (
        <div style={arrowStyle} onClick={this.handleRightArrowClick.bind(this)}>
            {endItem < itemLen + 1 ?
                <i className="right-arrow fa fa-chevron-right" />
                :
                <i className="right-arrow fa fa-empty" />
            }
        </div>
      );
  }

  render() {
    let buttonStyle = this.styles.whiteButton;
    if(!this.props.items) {
        return null;
    }
    let numSlots = this.state.numSlots;
    let endItem = this.state.startItem + numSlots;
    let curCount = 0;
    let firstTiles = [];
    for(var idx in this.props.items) {
        let item = this.props.items[idx];
        if(item) {
            curCount++;
            if(curCount >= this.state.startItem && curCount < endItem) {
                firstTiles.push(item);
            }
        }
    }
    const minHeight = this.props.minHeight ? this.props.minHeight : 120;
    const marginLeft = this.props.marginLeft ? this.props.marginLeft : 40;
    //console.log("firstTiles", firstTiles);
    return (
        <div className="slider scrollerComponent" style={{...{minHeight:minHeight, marginLeft:marginLeft, userSelect: "none"}, ...this.props.scrollerStyle}}>
            {!this.props.hideArrows ?
                this.renderLeftArrow(this.state.startItem)
                :
                null
            }
                {firstTiles}
            {!this.props.hideArrows ?
                this.renderRightArrow(this.props.items.length, endItem)
                :
                null
            }
            <div style={{clear: "both"}}>
            </div>
        </div>
    );
  }

  styles = {
    blueButton: {
      width: "100%",
      fontSize: 14,
      letterSpacing: "0.75px",
      border: "1px solid #00BCDF",
      backgroundColor: "#00BCDF",
      padding: "14px",
      color: "white",
      borderRadius: "25px",
      minHeight: "50px",
      textTransform: "uppercase",
      cursor: "pointer",
      textAlign: "center",
    },
    arrows: {
      float: "left",
      width: 64,
      fontSize: 24,
      lineHeight: "114px",
      cursor: "pointer",
    },
    arrowsV6: {
      float: "left",
      width: 20,
      fontSize: 24,
      marginTop: 17,
      cursor: "pointer",
      color: "white",
    },

  }
}
