import React from 'react';
import Utils from '../modules/Utils';
import Colors from '../modules/Colors';

import Spinner from './Spinner';
import Image from './Image';

import Div from '../components/Div';

export default class LoadingPage extends React.Component {
    render() {
        const pr = this.props;
        let sty = this.styles;

        return (
            <Div
                className="loadingPageElement"
                style={sty.loadingPageElement}
            >
                <Spinner message={pr.message} />
            </Div>
        )
    }

    styles = {
        loadingPageElement: {
            background: Colors.indigo,
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }
}
