import React from 'react';
import Utils from '../modules/Utils';

import spinner from '../assets/images/spinner-2.gif';

import Image from './Image';

import Div from '../components/Div';

export default class Spinner extends React.Component {
    render() {
        const pr = this.props;
        let sty = this.styles;

        let size = {width: pr.size ? pr.size : 40}

        return (
            <Div
                className="spinnerElement"
                style={{...sty.containerStyle, ...pr.style, ...pr.containerStyle}}
            >
                <Image
                    src={spinner}
                    style={{...sty.spinnerStyle, ...pr.spinnerStyle, ...size}}
                />
                {pr.message ?
                    <Div style={{...sty.messageStyle, ...pr.messageStyle}}>
                        {pr.message}
                    </Div>
                    :
                    null
                }
            </Div>
        )
    }

    styles = {
        containerStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            color: "white",
        },
        spinnerStyle: {
            marginBottom: 10,
        },
        messageStyle: {
            fontWeight: 400,
        },
    }
}
