import React from 'react';

import Utils from '../modules/Utils';

import Div from '../components/Div';
import Button from '../elements/Button';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';

export default class DebugBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: this.props.mode ? this.props.mode : 'brief',
            filterText: Utils.getCookie('debugFilterText', ''),
            filterValueText: Utils.getCookie('debugFilterValueText', ''),
            flexDirection: Utils.getCookie('debugFlexDirection', 'row'),
            isNumber: false,
        };
    }

    toggleRowColumn() {
        const newFlexDirection = this.state.flexDirection == 'row' ? 'column' : 'row';
        this.setState({
            flexDirection: newFlexDirection
        }, () => {
            Utils.setCookie('debugFlexDirection', newFlexDirection);
        });
    }

    toggleIsNumber() {
        this.setState({
            isNumber: !this.state.isNumber
        });
    }

    clearCookies() {
        for (var i = 0; i < 5000; i++) {
            Utils.deleteCookie("s-" + i);
        }
    }

    onFilterChange(e) {
        const filterText = e.target.value;
        this.setState({
            filterText: filterText
        }, () => {
            Utils.setCookie('debugFilterText', filterText);
        });
    }

    onFilterValueChange(e) {
        const filterValueText = e.target.value;
        this.setState({
            filterValueText: filterValueText
        }, () => {
            Utils.setCookie('debugFilterValueText', filterValueText);
        });
    }

    generateLineDiv(utilsGetString, codeResult, i, isKey) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        
        return (
            <Div 
                key={`debugBox-${isKey ? 'key' : 'value'}-${i}`} 
                className={`debugFilterSection-${i}`} 
                style={{...sty.debugFilterSection, ...(i % 2 ? sty.section1 : sty.section2)}}
            >
                <Div className='utilsGetStringContainer' style={sty.utilsGetStringContainer}>
                    {isKey ? 'KEY PATH' : 'VALUE'}: {utilsGetString}
                </Div>
                <Div style={sty.codeSectionContainer}>
                    {codeResult}
                </Div>
            </Div>   
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const debug = Utils.getRequest("debug");
        const debugVar = Utils.getRequest("debugVar");
        let body = "";
        const showBox = debug == 11 || debug == 12 || debug == 13 || pr.alwaysShow;

        const show = debugVar ? pr.show[debugVar] : pr.show;

        if(Utils.getRequest('dConsole') == 13) {
            console.log('DebugBox', show);
        }

        if(showBox) {
            if(show) {
                if(debug == 11) {
                    body = JSON.stringify(show, Utils.getCircularReplacer());
                } else if(debug == 12) {
                    body = JSON.stringify(show, Utils.getCircularReplacer(), 2);
                } else if(debug == 13) {
                    if (st.filterText.length || st.filterValueText.length) {
                        const utilsGetStrings = st.filterText.split(',');
                        body = [];
                    
                        utilsGetStrings.forEach((utilsGetString, i) => {
                            let codeResult = Utils.get(show, utilsGetString);
                            codeResult = JSON.stringify(codeResult, Utils.getCircularReplacer(), 2);
                            body.push(this.generateLineDiv(utilsGetString, codeResult, i, true));
                        });
                    
                        if (st.filterValueText.length) {
                            const valueStrings = st.filterValueText.split(',');
                    
                            valueStrings.forEach((valueString, i) => {
                                const result = Utils.findKeyPath(show, valueString, st.isNumber, false);
                                body.push(this.generateLineDiv(valueString, result, i));
                            });
                        }
                    } else {
                        body = JSON.stringify(show, Utils.getCircularReplacer(), 2);
                    }
                    
                }
            }
        }

        if(!showBox) {
            return null;
        }

        return (
            <Div className="debugboxElement" style={sty.debugboxElement}>
                <Div className="debugboxHeader" style={sty.debugboxHeader}>
                    {pr.title ?
                        <Div className="debugboxTitle" style={sty.title}>
                            {pr.title}
                        </Div>
                        :
                        null
                    }
                    {debug == 13 &&
                        <>
                            <Input
                                className="debugboxFilterKeyInput"
                                style={sty.debugboxFilterInput}
                                type="text"
                                placeholder="Search Key"
                                onChange={this.onFilterChange.bind(this)}
                                value={st.filterText}
                            />
                            <Input
                                className="debugboxFilterValueInput"
                                style={sty.debugboxFilterInput}
                                type="text"
                                placeholder="Search Value"
                                onChange={this.onFilterValueChange.bind(this)}
                                value={st.filterValueText}
                            />
                        </>
                    }
                    <Div style={{...sty.checkboxContainer, ...{width: 100}}}>
                        <Checkbox
                            className="debugboxIsNumberCheckbox"
                            color="black"
                            style={{border: '1px solid black'}}
                            checked={st.isNumber}
                            onClick={this.toggleIsNumber.bind(this)}
                        />
                        <Div style={{fontSize: 12, fontWeight: 'bold'}}>
                            isNum
                        </Div>
                    </Div>
                            
                    <Div style={sty.checkboxContainer}>
                        <Checkbox
                            className="debugboxFlexDirectionCheckbox"
                            color="black"
                            style={{border: '1px solid black'}}
                            checked={st.flexDirection == 'row'}
                            onClick={this.toggleRowColumn.bind(this)}
                        />
                        <Div style={{fontSize: 12, fontWeight: 'bold'}}>
                            {st.flexDirection == 'row' ? 'row' : 'col'}
                        </Div>
                    </Div>
                    
                    <Button
                        size="small"
                        onClick={this.clearCookies.bind(this)}
                    >
                        clear cookies
                    </Button>
                </Div>
                <pre className="preDebugCode" style={{...sty.preDebugCode, ...{flexDirection: st.flexDirection == 'row' ? 'row' : 'column'}}}>
                    {body}
                </pre>
            </Div>
        );
    }

    styles = {
        debugboxElement: {
            width: '90%',
            padding: "0px 10px",
            background: "#FFF9D6",
            maxHeight: parseInt(Utils.getRequest('dHeight', 300)),
            overflow: "scroll",
            color: "black",
        },
        debugboxHeader: {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingTop: 8,
            paddingBottom: 8,
            gap: 8,
            top: 0,
            position: 'sticky',
            backgroundColor: '#FFF9D6',
            zIndex: 1,
        },
        title: {
            color: "black",
            fontSize: 18,
        },
        debugboxFilterInput: {
            background: 'rgba(0,0,0,0.3)',
            padding: 8,
            borderRadius: 4,
            color: 'black',
            marginBottom: 0,
            fontWeight: 400,
        },
        section1: {
            background: 'rgba(0,0,0,0.3)', 
        },
        section2: {
            background: 'rgba(0,0,0,0.1)', 
        },
        checkboxContainer: {
            alignItems: 'center',
            width: 28,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        preDebugCode: {
            display: 'flex',
            fontSize: 12,
            gap: 4,
            margin: 0,
            paddingBottom: 10,
        },
        debugFilterSection: {
            padding: 8, 
            borderRadius: 4,
            overflowX: 'scroll',
            flex: 1,
        },
        utilsGetStringContainer: {
            fontWeight: 'bold',
            position: 'sticky',
            left: 0,
        },
        codeSectionContainer: {
            paddingTop: 8,
        },
    };
}
