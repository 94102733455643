import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Api from '../modules/Api';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Span from '../elements/Span';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import EmailPhoneVerification from '../elements/EmailPhoneVerification';

import Div from './Div';

import apLogo from "../assets/images/ticketflow/ap logo.png";

export default class EmailPhoneVerificationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startState: props.selected === "verificationemail" ? "startEmail" : "startPhone",

            // NOTE: next= (for global search purposes)
            nextRoute: Utils.getRequest("next") ? Utils.getRequest("next").replace("^", "?") : "/home",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.userLoaded && !Utils.get(nextProps, "user.email")) {
            Utils.gotoRoute(this.props, "/login");
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="emailPhoneVerificationPageComponent" style={sty.emailPhoneVerificationPageComponent}>
                <AfterpartyLogo
                    screen={pr.screen}
                    history={pr.history}
                    style={{padding: 20}}
                />
                <Div className="emailPhoneVerificationPageContainer" style={sty.emailPhoneVerificationPageContainer}>
                    <EmailPhoneVerification
                        showTokenBadges
                        showHeaderImage
                        startState={st.startState}
                        screen={pr.screen}
                        history={pr.history}
                        user={pr.user}
                        userLoaded={pr.userLoaded}
                        setUser={pr.setUser.bind(this)}
                        continueFunction={Utils.gotoRoute.bind(this, pr, st.nextRoute)}
                    />
                </Div>
            </Div>
        )
    }

    styles = {
        emailPhoneVerificationPageComponent: {
            backgroundColor: Colors.indigo,
            minHeight: "100vh",
        },
        emailPhoneVerificationPageContainer: {
            display: "flex",
            justifyContent: "center",
            maxWidth: 600,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 20,
            paddingTop: 80,
        },
        apLogoStyle: {
            position: "absolute",
            width: this.props.screen.mobile ? 190 : 240,
            top: 44,
            left: 44,
        },
    };
}
