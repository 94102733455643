import React from 'react'

import Utils from '../modules/Utils'
import Api from '../modules/Api'
import Styles from '../modules/Styles'
import Errors from '../modules/Errors'

import Modal from '../elements/Modal'

import Div from './Div'
import Register from './Register'

export default class ModalRegister extends React.Component {
    getNewUserAfterRegisterSuccess(newUser) {
        const pr = this.props;
        let avoidClose = pr.getNewUserAfterRegisterSuccess(newUser);
        if(!avoidClose) {
            pr.onRequestClose();
        }
    }

    render() {
        const pr = this.props;

        return (
            <Modal
                screen={pr.screen}
                onRequestClose={pr.onRequestClose.bind(this)}
            >
                <Register
                    screen={pr.screen}
                    title={pr.title}
                    fields={pr.fields}
                    user={pr.user}
                    isProfile={pr.isProfile}
                    getNewUserAfterRegisterSuccess={this.getNewUserAfterRegisterSuccess.bind(this)}
                />
            </Modal>
        );
    }
}
