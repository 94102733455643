import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Api from '../modules/Api';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Span from '../elements/Span';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import AudioShoutout from '../elements/AudioShoutout';

import Div from './Div';

import apLogo from "../assets/images/ticketflow/ap logo.png";

export default class AudioShoutoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artist: {
                image_url: "",
                title: "",
            },
            shoutoutListingCrc: props.shoutoutCrc,
            shoutout: {
                audio: null,
                body: "",
            },
        };
    }

    componentDidMount() {
        // NOTE: network call
        this.getShoutoutListing();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if(nextProps.userLoaded && !Utils.get(nextProps, "user.email")) {
        //     Utils.gotoRoute(this.props, "/login");
        // }
    }

    getShoutoutListing() {
        const st = this.state;

        Api.getShoutoutListing(st.shoutoutListingCrc, (res) => {
            const errors = Utils.get(res, "errors", []);
            if(errors.length) {
                console.log("ERRORS: ", errors);
            }

            this.setState({
                shoutout: Utils.get(res, "data.listing"),
                artist: Utils.get(res, "data.artist"),
            });
        })
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="audioShoutoutPageComponent" style={sty.audioShoutoutPageComponent}>
                <Div className="audioShoutoutPageContainer" style={sty.audioShoutoutPageContainer}>
                    <AudioShoutout
                        user={pr.user}
                        history={pr.history}
                        artist={st.artist}
                        shoutout={st.shoutout}
                        screen={pr.screen}
                    />
                </Div>
            </Div>
        )
    }

    styles = {
        audioShoutoutPageComponent: {
            backgroundColor: Colors.indigo,
            minHeight: "100vh",
            // paddingTop: 72,
        },
        audioShoutoutPageContainer: {
            display: "flex",
            justifyContent: "center",
            maxWidth: 600,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        apLogoStyle: {
            position: "absolute",
            width: this.props.screen.mobile ? 190 : 240,
            top: 44,
            left: 44,
        },
    };
}
