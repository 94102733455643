import React from 'react';
import axios from 'axios';

import { Carousel } from 'react-responsive-carousel';
import moment, { max } from 'moment'

import '../App.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';

import Div from '../components/Div';

import Scroller from './Scroller';
import SearchBox from '../components/SearchBox';

import banner from '../assets/hero-banner.png';
import dropArtistAnimation from '../assets/drops_transparent.gif';
import dropArtistImage from '../assets/weeknd1.png';
import heroRender1 from '../assets/heroRender1.png';


export default class DashboardSoinMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        wallet: null,
    };

        var then = moment().add(160, 'seconds'); // new Date(now + 60 * 1000);

        //$(".now").text(moment(now).format('h:mm:ss a'));
        //$(".then").text(moment(then).format('h:mm:ss a'));
        //$(".duration").text(moment(now).to(then));
        if(false) {
            setInterval(function() {
                var now = moment(); // new Date().getTime();
                console.log("WHEN", moment().to(then));
            }, 1000);
        }

        console.log("WHEN", moment().to(then));

  }

  changeSelection(selection) {
      console.log("Changing selection: ", selection);
      if(selection !== "logout") {
          this.setState({selected: selection});
      } else {
          this.setState({showLogout: true});
      }
  }

  handleConfirmCancel() {
      this.setState({showLogout: false});
  }

  handleConfirmMediaModal() {
    this.setState({ showMediaModal: false });

  }

  handleCancelMediaModal(dirty) {
    this.setState({ showMediaModal: false });
    console.log(">>>>>>>>> 10");

    Api.apiLoadMusic(this.processMusic.bind(this));

  }

  handleConnectMetamask() {
      const _self = this;
      if(window.ethereum && window.ethereum.isMetaMask) {
          //window.ethereum.request({ method: 'eth_accounts' }).then(function(acc) { console.log(acc); });
          window.ethereum.request({ method: 'eth_requestAccounts' })
            .then(function(acc) {
                alert("Setting default wallet to: "+ acc);
                Api.putMusicWallet({params:{user:{wallet_id:acc[0]}}});
                _self.setState({wallet:acc});
            });
      } else {
          alert("Metamask not found");
      }
  }

  handleConfirmLogout() {
      // TODO: Make logout
    const _self = this;
    axios.get(`/api/v1/logout`, { })
          .then(res => {
            console.log("RETURNED", res);
            _self.props.history.push('/login');
          }) ;
  }

  runSearch(event, search) {
      console.log("Run search on ", search);
      this.props.change("search");
  }

  renderTopNavBar() {
      let walletId = null;
      if(this.state.wallet) {
          walletId = this.state.wallet;
      } else if(this.state.user && this.state.user.wallet_id) {
          walletId = this.state.user.wallet_id;
      }
      return <Div style={{justifyContent: "flex-end", display: "flex"}}>
        {false && !walletId ?
            <Div onClick={this.handleConnectMetamask.bind(this)} style={this.styles.slateButton}>Connect to Metamask</Div>
            :
            null
        }
        { false ?
            <Div style={{padding:10,margin:10,backgroundColor:"rgba(0.5,0.5,0.5,0.5)",borderRadius:6}}>Using wallet {walletId}</Div>
            :
            null
        }
      </Div>;
  }

  handlePosterClick(item) {
      item["thumbnail"] = item["cover_big"];
      this.setState({
              selectedMediaItem: item,
      }, () => {
        this.props.history.push('/soin/'+item["name"]+"-"+item["crc"]+Api.getReqParams());
        this.props.change('item', item);
      });
  }

  handleFeaturedClick() {
      const featured = this.props.featured;
      featured["thumbnail"] = dropArtistAnimation;
      featured["thumbnail_animated"] = dropArtistAnimation;
      this.setState({
              selectedMediaItem: featured,
      }, () => {
        this.props.change('item', featured);
      });
  }

  renderBanner() {
      const orangeGradientButtonExtras = {width:116, paddingTop:5, paddingBottom:5, paddingLeft:6, fontSize:12, letterSpacing: 5};
      const whiteGradientButtonExtras = {color: "#F374B9", width:100, paddingTop:7, paddingBottom:7, paddingLeft:6, paddingRight:6, fontSize:11, letterSpacing: 3, marginBottom:30,};
      return (
                <Div
                    style={{backgroundColor:"#574EB7", height:400,display:"flex",flexDirection:"row", borderRadius:20,}}
                    onClick={this.handleFeaturedClick.bind(this)}
                    >
                    <Div style={{width:385, width:440, paddingTop:37, position:"relative", }}>
                        <img style={{width:67, height:67, left:0, top:193, position:"absolute", filter: "blur(2px)"}} src={"/renders/soinasset_99_3605f5482f9508b22604e4b7d5b42a86.png"} />
                        <img style={{width:140, height:140, left:65, top:155, position:"absolute", filter: "blur(1px)"}} src={"/renders/soinasset_97_61da639d8014172d9be4c1d297cac6ae.png"} />
                        <img style={{width:240, height:240, left:205, top:101, position:"absolute"}} src={heroRender1} />
                    </Div>
                    {/* <SearchBox
                        style={{width:153, top:38, left:70}}
                        placeholder="Search..."
                        validate={this.runSearch.bind(this)}
                        /> */}
                    <Div style={{flex:2, paddingLeft:108, paddingTop:130}}>
                        <Div style={{...Styles.whiteGradientButton,...whiteGradientButtonExtras}}>
                            FEATURED
                        </Div>
                        <Div style={{color:"white", whiteSpace:"nowrap", fontSize:24, fontWeight:"bold",marginBottom:0, }}>
                            The Weeknd -
                        </Div>
                        <Div style={{fontSize:24, fontWeight:"bold", color:"white",marginBottom:16, lineHeight:"25px"}}>
                            Blinding Lights
                        </Div>
                        <Div style={{fontSize:16, color:"#DCDBF1",marginBottom:20, display:"flex"}}>
                            <Div>
                                <i style={{color:"#E047E2",width:20, fontSize: 36, paddingRight:36}} className="fa fa-circle-o"></i>
                            </Div>
                            <Div style={{paddingTop: 6}}>
                                Limited Release 100
                            </Div>
                        </Div>

                    </Div>
                    <Div style={{flex:1,position:"relative"}}>
                        { true ?
                            <img src={dropArtistImage} />
                            :
                            null
                        }
                        <img style={this.styles.avatarThumbnail} src={this.props.user['photo']} />
                    </Div>
                </Div>
       );
  }

  renderCard(listing, size, hideCounts) {
      const borderRadius = 0; //parseInt(size*.1);
      const captionHeight = parseInt(size*.4);
      const fontSize = parseInt(size*.06);
      const trackName = listing["title"]; //album["tracks"][0]["title"];
      const artistName = listing["artist_name"]; //album["artist"]["name"];
      const remainingPrints = listing["total_prints"] - listing["prints_sold"];
      //size = 180;
      let ts = moment();
      let formatDate = ts.format('h') + "h " + ts.format('m') + "m " + ts.format('s') + "s";
      let timeRemaing = "2h 35m";

      /*
      var a = moment([2021, 2, 6]);
      var b = moment([2021, 2, 4]);
      var hours = a.diff(b, 'hours');
      var minutes = a.diff(b, 'minutes');
      var seconds = a.diff(b, 'seconds');
      var sec = seconds % 60;
      window.sec = seconds;
      */
      var cardSize = {width:size,  height:size,filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))", borderRadius: 14,};
      var _self = this;


        /*
        (function timerLoop() {
          $(".difference > span").text(moment().to(then));
          $(".countdown").text(countdown(then).toString());
          requestAnimationFrame(timerLoop);
        })();
        */


      return <Div
        key={`card-${listing['id']}`}
        onClick={this.handlePosterClick.bind(this, listing)}
        style={{position:"relative",marginTop:6,borderRadius:borderRadius,overflow:"hidden",textAlign:"left"}}>
                <img style={_self.styles.cardImage, cardSize} src={listing["image_url"]} />
                <Div style={{whiteSpace: "nowrap", textAlign:"left",marginTop:19}}>
                    <Div style={{fontSize:14,fontWeight:"bold"}}>
                        {trackName}
                    </Div>
                    <Div style={{fontSize:12,color:"gray",marginTop:4}}>
                        {artistName}
                    </Div>
                    { listing["listing_status"] == 2 && !hideCounts ?
                        <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>
                            {remainingPrints} of {listing["total_prints"]} left
                        </Div>
                        :
                        null
                    }
                    { listing["listing_status"] == 2 && !hideCounts ?
                        listing["until"] ?
                            <Div style={{textAlign:"left",color:"red",fontWeight:"bold",fontSize:12,marginBottom:6}}>Drops in {listing["until"]}</Div>
                            :
                            false ? // Hide until real drops in place
                                <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>{listing["remaining"]} left</Div>
                                :
                                null
                    :
                    null
                    }
                </Div>
        </Div>;
  }

  renderDrops() {
      let renderedTiles = [];
      console.log("this.props.drops >>>", this.props.drops);
      if(this.props.drops) {
          renderedTiles = this.props.drops.map( (album) => {
               return this.renderCard(album, 130);
            });
      }
      let slidePercentage = 19;
      let transitionTime = 350;
      let centerMode = true;

      return <Div style={Styles.sectionHead}>
        <Div style={this.styles.sectionHeadline}>Top Drops This Week</Div>
            <Div style={{padding:0,marginTop:28}}>
                {this.props.drops
                    ?
                      <Carousel
                        key={"dropsScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    null
                }
            </Div>
        </Div>;
  }

  renderMarketplace() {
      let renderedTiles = [];
      if(this.props.marketplace) {
          const albums = this.props.marketplace;
          renderedTiles = albums.map( (album,idx) => {
               return this.renderCard(album, 138, true);
            });
      }
      let slidePercentage = 15 ;
      let transitionTime = 350;
      let centerMode = true;

      return <Div style={Styles.sectionHead}>
            <Div style={this.styles.headline}>Marketplace</Div>
            <Div style={{padding:30}}>
                {this.props.marketplace
                    ?
                      <Carousel
                        key={"MarketplaceScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    null
                }
            </Div>
        </Div>;
  }

  renderMyCollection() {
      let renderedTiles = [];
      let mycollection = this.props.user.items;
      if(mycollection) {
          const albums = mycollection;
          renderedTiles = albums.map( (album,idx) => {
               return this.renderCard(album, 180, true);
            });
      }
      let slidePercentage = 20;
      let transitionTime = 350;
      let centerMode = true;
      console.log("mycollection", mycollection);

      return (
          <Div style={Styles.sectionHead}>
          {mycollection.length > 0 ?
            <Div>
                <Div style={this.styles.headline}>My Collection</Div>
                <Div style={{padding:30}}>
                    {mycollection.length > 0
                        ?
                          <Carousel
                            key={"MarketplaceScroller"}
                            showThumbs={false}
                            useKeyboardArrows={true}
                            emulateTouch={true}
                            showStatus={false}
                            showIndicators={false}
                            centerMode={centerMode}
                            centerSlidePercentage={slidePercentage}
                          >
                            {renderedTiles}
                          </Carousel>
                        :
                        <Div>No items yet</Div>
                    }
                </Div>
            </Div>
            :
            null
        }
        </Div>
        );
  }


  render() {
    return (
      <Div style={this.styles.app} className="dashboardSoinMainComponent">
        <Div className="container-fluid-OLD" style={{paddingLeft:0}}>
          <Div className="row-OLD">
            <Div className="col-OLD" style={this.styles.rightCol}>
                {this.renderBanner()}
                <Div style={{padding:20}}>
                    {this.renderTopNavBar()}
                    {this.renderDrops()}
                    {this.renderMarketplace()}
                    {this.renderMyCollection()}
                </Div>
            </Div>
          </Div>
        </Div>


      </Div>
    );
  }
  styles = {
    app: {
      backgroundColor: "#000",
      color: "black",
      textAlign: "left",
        fontFamily: "'Montserrat', sans-serif",
    },
    leftCol: {
      minHeight: 850,
      maxWidth: 220,
      backgroundColor:"white",
      color: "black",
      paddingLeft: 0,
      paddingRight: 0,
    },
    leftColBody: {
      paddingTop: 20,
      justifyContent: "center",
      display: "flex",
      flexDirection:"col",

    },
    rightCol: {
      paddingLeft:0,
      background:"#FDFCFC",
      minHeight:800,
      color:"black"
    },
    slateButton: {
        cursor: "pointer",
        padding:10,
        backgroundColor:"black",borderRadius:20,
        right:20,
        width: 300,
        margin: 20,
        textAlign: "center",
        color:"white",
    },
    orangeGradientButton: {
        background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
        color: "white",
        padding:23,
        textAlign:"center",
        fontWeight: "bold",
        cursor: "pointer",
    },
    cardImage: {
        filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
        borderRadius: 14,
    },
    avatarThumbnail: {
        position:"absolute",
        top: 34,
        right: 34,
        width: 32,
        height: 32,
        borderRadius: 6,
        overflow: "hidden",
    },
  };
}
