import Colors from './Colors';
import StylesPlatform from './StylesPlatform';

export default {

    // NOTE: Desktop fonts
    ap_text__heading1: {
        fontFamily: "Nyata",
        fontSize: 90,
        fontWeight: 800,
        color: "white",
    },
    ap_text__heading2: {
        fontFamily: "Nyata",
        fontSize: 75,
        fontWeight: 800,
        color: "white",
    },

    ap_text__subheading1: {
        fontFamily: "Nyata",
        fontSize: 45,
        fontWeight: 800,
        color: "white",
    },
    ap_text__subheading2: {
        fontFamily: "Nyata",
        fontSize: 32,
        fontWeight: 400,
        color: "white",
    },

    ap_text__subheading3: {
        fontFamily: "Nyata",
        fontSize: 18,
        fontWeight: 400,
        color: "white",
    },
    ap_text__body1: {
        fontFamily: "Mont Demo",
        fontSize: 20,
        fontWeight: 400,
        color: "white",
    },
    ap_text__body2: {
        fontFamily: "Mont Demo",
        fontSize: 16,
        fontWeight: 400,
        color: "white",
    },
    ap_btn__purple1: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        flexShrink: 0,
        fontWeight: "bold",
        width: "fit-content",
        fontWeight: 400,
        fontFamily: "Nyata",
        background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
        color: "white",
        padding: "15px 20px 14px 20px",
        borderRadius: 100,
        fontSize: 16,
        minWidth: 180,
        textAlign:"center",
        cursor:"pointer",
        userSelect: "none",
    },

    // NOTE: Mobile fonts
    ap_text__heading1_mobile: {
        fontFamily: "Nyata",
        fontSize: 45,
        fontWeight: 800,
        color: "white",
    },
    ap_text__heading2_mobile: {
        fontFamily: "Nyata",
        fontSize: 33,
        fontWeight: 800,
        color: "white",
    },

    ap_text__subheading1_mobile: {
        fontFamily: "Nyata",
        fontSize: 28,
        fontWeight: 800,
        color: "white",
    },
    ap_text__subheading2_mobile: {
        fontFamily: "Nyata",
        fontSize: 24,
        fontWeight: 400,
        color: "white",
    },

    ap_text__body1_mobile: {
        fontFamily: "Mont Demo",
        fontSize: 17,
        fontWeight: 400,
        color: "white",
    },
    ap_text__body2_mobile: {
        fontFamily: "Mont Demo",
        fontSize: 12,
        fontWeight: 400,
        color: "white",
    },

    ap_color__indigo: {
        background: Colors.indigo,
    },
    ap_color__indigoLight: {
        background: Colors.indigoLight,
    },
    ap_color__indigoLighter: {
        background: Colors.indigoLighter,
    },
    ap_color__magenta: {
        background: "#C92D7A",
    },
    ap_color__yellow: {
        background: Colors.yellow,
    },
    ap_color__white: {
        background: Colors.white,
    },

    ap_panelqatopic__Container: {
        background: "white",
        borderRadius: 14,
        paddingBottom: 20
    },
    ap_panelqaanswer__Container: {
        padding: "2px 60px 32px",
    },

    ap_modalOuter__Container: {
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        padding: "32px 10px",
        zIndex: 1055,
    },
    ap_modalInner__Container: {
        display: "flex",
        flexDirection: "column",
        border: "none",
        width: "100%",
        maxWidth: 700,
        borderRadius: 18,
        padding: "18px 20px 20px",
        margin: "auto",
        backgroundColor: "rgb(15, 4, 32)",
        outline: "none",
        color: "white",
        border: "1px solid #69347A",
    },
    orangeGradientButton: {
        ...{
            background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
            color: "white",
            padding:23,
            height: 70,
            textAlign:"center",
            fontWeight: "bold",
            marginRight: 10,
            boxShadow: "3px 3px 12px #999999",
            overflow:"hidden",
        },
        ...StylesPlatform.cursorPointer
    },
    purpleWhiteCircle: {
        width:24,height:24,borderRadius:"50%",backgroundColor:"white",color:"#BA85FE",
        padding: 6,
        marginRight: 6,
    },
    btn: {
        ...{
            background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
            padding:"14px 23px",
            height: 50,
            fontSize: 14,
            color: "white",
            textAlign:"center",
            fontWeight: "bold",
            marginRight: 10,
            overflow:"hidden",
        },
        ...StylesPlatform.cursorPointer
    },
    btnTiny: {
        padding:"6px 8px 3px",
        height: 30,
        fontSize: 12,
        borderRadius: 6,
    },
    headline: {
        fontSize: 36,
        fontWeight:'bold',
    },
    subhead: {
        fontSize: 24,
        fontWeight:"bold",
        marginBottom: 8,
    },
    uploadContainer: {
        border: "1px solid lightgray",
        borderRadius: 4,
        padding: "20px 20px",
        margin: "10px 0px",
    },
    orangePlus: {
        ...{
            backgroundColor: "orange",
            color: "white",
            width: 48,
            height: 48,
            borderRadius: "50%",
            fontSize: 40,
            fontWeight: 600,
            lineHeight: "48px",
            overflow:"hidden",
            textAlign: "center",
        },
        ...StylesPlatform.cursorPointer
    },
    sectionHead: {
        marginTop: 41,
        //width:1200,
        paddingBottom:0,
        marginLeft:60,
        fontSize: 24,
        color: "#584FB9",
    },
    sectionHead2: {
        fontSize: 30,
        fontWeight: "bold",
        color: "#584FB9",
    },
    whiteGradientButton: {
        ...{
            background: "white",
            color: "black",
            padding:23,
            textAlign:"center",
            fontWeight: "bold",
            borderRadius: 5,
            overflow:"hidden",
        },
        ...StylesPlatform.cursorPointer
    },
    pinkGradientButton: {
        ...{
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
            color: "white",
            padding:"15px 20px 14px",
            textAlign:"center",
            fontWeight: "bold",
            borderRadius: 100,
            overflow:"hidden",
        },
        ...StylesPlatform.cursorPointer
    },
    btnMagenta: {
        ...{
            backgroundColor:"#EF4CA4",
            textAlign:"center",
            color: "white",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 6,
            fontSize:12,
            overflow:"hidden",
        },
        ...StylesPlatform.cursorPointer
    },
    purpleHeadline: {
        width: "100%",
        textAlign: "center",
        fontSize: 30,
        paddingBottom: 35,
        color: Colors.purple,
        fontWeight: "bold",
    },
    alink: {
        textDecoration: "underline", color: "white",
    },
    videoIconsContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: Colors.purpleLight2,
        borderRadius: 8,
        marginBottom: 5,
        padding: 3,
        marginRight: 8,
    },
    videoIconsIcon: {
        padding: "10px 10px",
        margin: "0 10px",
        cursor: "pointer",
        color: "white",
    },
    dropShadow: {
        filter: "drop-shadow(0 0 0.75rem black)",
    },

}
