import React from 'react';

import Utils from '../modules/Utils';

import FlashMessage from '../elements/FlashMessage';

import Div from './Div';


export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        flashMessage: null,
    };
    if(Utils.getCookie('flashMessage')) {
        console.log("Found flash message", Utils.getCookie('flashMessage'));
        let messageInfo = false;
        try {
            messageInfo = JSON.parse(Utils.getCookie('flashMessage'));
        } catch {
            console.log("flashMessage parse error. Current value: ", Utils.getCookie('flashMessage'));
        }
        if(messageInfo) {
            this.state.flashMessage = messageInfo;
            Utils.deleteCookie('flashMessage');
        }
    }
    // Test message
    if(false) {
        const exampleMessage = {
            message:"Registration success",
            type:"success",
            subMessage: "Please login to use your account."
        };
        Utils.setCookie("flashMessage", JSON.stringify(this.state.exampleMessage));
    }

  }

  render() {
      const st = this.state;
      return (
          <Div>
                {st.flashMessage ?
                    <FlashMessage
                        type={st.flashMessage.type}
                        message={st.flashMessage.message}
                        subMessage={st.flashMessage.subMessage}
                    />
                    :
                    null
                }
          </Div>
    );
  }

  styles = {
  };
}
