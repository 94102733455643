import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Errors from '../modules/Errors';
import Validate from '../modules/Validate';
import StylesPlatform from '../modules/StylesPlatform';

import Input from '../elements/Input';
import ErrorBox from '../elements/ErrorBox';
import Button from '../elements/Button';
import Span from '../elements/Span';

import Div from './Div';

export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordText: "",
            passwordConfirmText: "",
            successMessages: [],
            errorMessages: [],
            passwordUpdateSuccess: false,

            email: Utils.getRequest('email'),
            hash: Utils.getRequest('hash'),
            recover: Utils.getRequest('recover'),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(Utils.get(nextProps, "user.email")) {
            //Utils.gotoRoute(this.props, "settings");
        }
    }

    infoChange(infoVar, e) {
        let tempState = Utils.deepCopy(this.state);
        tempState[infoVar] = e.target.value;

        this.setState(tempState);
    }

    resetErrorSuccessMessages() {
        this.setState({
            errorMessages: [],
            successMessages: [],
        });
    }

    saveUpdatePassword() {
        const pr = this.props;
        const st = this.state;

        this.resetErrorSuccessMessages();

        // let profileData = {
        //     params: {
        //         user: {},
        //     },
        // };

        let resetPasswordData = {
            email: st.email,
            hash: st.hash,
            password: st.passwordConfirmText
        };

        if(st.passwordText != st.passwordConfirmText) {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(45745, "New passwords don't match"),
            });
            return;
        }

        if(st.passwordText.length < 4) {
            this.setState({
                errorMessages: Utils.addErrorSuccessMessage(45746, "Password must be at least 4 characters"),
            });
            return;
        }

        // profileData.params.user.password = st.passwordConfirmText;

        // Api.putProfile(profileData, (res) => {
        Api.postPasswordReset(resetPasswordData, (res) => {
            if(!Utils.get(res, "errors").length) {
                pr.setUser(res);
                this.setState({
                    successMessages: [{id: 39348, message: "Password updated"}],
                    errorMessages: [],
                    passwordText: "",
                    passwordConfirmText: "",
                    passwordUpdateSuccess: true,
                });
            } else {
                this.setState({
                    successMessages: [],
                    errorMessages: Utils.get(res, "errors"),
                    passwordText: "",
                    passwordConfirmText: "",
                    passwordUpdateSuccess: false,
                });
            }
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="changePasswordComponent"
                style={{...sty.changePasswordComponent, ...pr.style}}
            >
                <Div
                    className="changePasswordInner"
                    style={sty.changePasswordInner}
                >
                    <Div
                        className="forgotTitle"
                        style={{...Styles.ap_text__subheading2, ...{marginBottom: 10, fontWeight: 800}}}
                    >
                        Change your Password
                    </Div>

                    <Div
                        className="forgotDescription"
                        style={{...Styles.ap_text__subheading3, ...{marginBottom: 20}}}
                    >
                        Your new password must be different from your previous one.
                    </Div>

                    <Input
                        placeholderLabelStyle={{marginBottom: 20}}
                        type="password"
                        placeholder="New Password"
                        value={st.passwordText}
                        onChange={this.infoChange.bind(this, "passwordText")}
                        inputType="placeholderLabel"
                    />

                    <Input
                        placeholderLabelStyle={{marginBottom: 20}}
                        type="password"
                        placeholder="Confirm New Password"
                        value={st.passwordConfirmText}
                        onChange={this.infoChange.bind(this, "passwordConfirmText")}
                        inputType="placeholderLabel"
                    />

                    <Button
                        style={{width: "100%"}}
                        color="pinkGradient"
                        onClick={this.saveUpdatePassword.bind(this)}
                    >
                        Change Password
                    </Button>

                    {st.passwordUpdateSuccess &&
                        <Div
                            className="goToLoginButton"
                            style={sty.goToLoginButton}
                        >
                            Success! Go to the <Span className="loginSpan" style={{...sty.loginSpan, ...StylesPlatform.cursorPointer}} onClick={() => {Utils.gotoRoute(this.props, "login?settings", false, false)}}>login</Span> page
                        </Div>
                    }

                    <ErrorBox
                        style={{marginTop: 20}}
                        successMessages={st.successMessages}
                        errorMessages={st.errorMessages}
                    />
                </Div>

            </Div>
        );
    }

    styles = {
        changePasswordComponent: {
            minHeight: "calc(100vh - 300px)",
            backgroundColor: Colors.indigo,
            paddingTop: 140,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 100,
        },
        changePasswordInner: {
            maxWidth: 463,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        goToLoginButton: {
            color: "white",
            textAlign: "center",
            marginTop: 30,
        },
        loginSpan: {
            textDecoration: "underline",
        },
    };
}
