import React from 'react';

import moment, { max } from 'moment'

import Utils from '../modules/Utils';
import Image from '../elements/Image';

import Div from '../components/Div';

export default class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: Utils.get(this.props, "startDate", moment()),
        };
    }

    handleClick() {
        const stepInfo = this.state.steps[this.state.step - 1];
        this.props.history.push(stepInfo['target']);
    }
    handlePreviousMonthClick() {
        const tempState = {
            curDate: this.state.curDate.subtract(1, 'month'),
        };
        this.setState(tempState);
    }
    handleNextMonthClick() {
        const tempState = {
            curDate: this.state.curDate.add(1, 'month'),
        };
        this.setState(tempState);
    }

    renderWeekRow(count, offset, ldom, j) {
        var out = [];
        for(var i=0;i<7;i++) {
            var countStr = '';
            if(count > offset && (count-offset) <= ldom) {
                countStr = count - offset;
            } else if(j == 0) {
                countStr = moment().weekday(i).format('dd');
            }
            out.push(<Div className='dayBox' style={this.styles.dayBox}>{countStr}</Div>);
            count++;
        }
        return out;
    }

    renderMonth(curDate) {
        var firstDom = curDate.startOf('month');
        var lastDom = curDate.endOf('month');
        $("#calendarTitle").html(curDate.format("MMMM YYYY"));
        let out = [];
        let count = 1;
        // Offset for header row + offset for first day of month
        var offset = 7 + firstDom.weekday();
        var ldom = lastDom.date();
        for(var j=0;j<6;j++) {
            var rowClass = "calendarRow";
            if(j == 0) {
                rowClass = "calendarHeaderRow";
            }
            out.push(<Div style={this.styles.calendarRow}>{this.renderWeekRow(count, offset, ldom, j)}</Div>);
            count += 7;
            //outStr += "<div class='"+rowClass+"'>";
            //outStr += "</div>";
        }
        return out;

    }

    render() {
        const style = {};
        const curDate = this.state.curDate; // this.state.curDate;
        const title = curDate.format("MMMM YYYY");

        return (
            <Div style={{width:"fit-content"}}>
                <Div style={{display:"flex",flexDirection:"row",textAlign:"center"}}>
                    <Div className="btnBox" style={this.styles.btnBox} onClick={this.handlePreviousMonthClick.bind(this)}>&lt;</Div>
                    <Div style={{flex:1}} id="calendarTitle">{title}</Div>
                    <Div className="btnBox" style={this.styles.btnBox} onClick={this.handleNextMonthClick.bind(this)}>&gt;</Div>
                </Div>
                <Div id="calendarContainer">
                    {this.renderMonth(curDate)}
                </Div>
            </Div>
        );
    }

    styles = {
        /*
#calendarContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
}
#calendarTitle {
    width: 100%;
    text-align: center;
}
.calendarRow, .calendarHeaderRow {

}
.calendarRow .
.calendarHeaderRow .dayBox {
    width: 64px;
    height: 32px;
    border: 1px solid grey;
}

        */
        calendarRow: {
            display: "flex",
            flexDirection: "row",
        },
        dayBox: {
            width: 64,
            height: 64,
            border: "1px solid grey",
        },
        btnBox: {
            flex:1,
            cursor: "pointer",
        },
    };
}
