import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';

import Icon from '../elements/Icon';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';

import Div from './Div';

export default class CustomerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const pr = this.props;
        const sty = this.styles;

        const imageSize = pr.size ? pr.size : 96;

        const bloatedCircle = {
            clipPath:`url(#bloated-circle-${pr.size})`,
            height: imageSize,
            width: imageSize,
            flexShrink: 0,
            backgroundRepeat: "no-repeat",
        };
        const customerImage = pr.image_url && pr.image_url.length > 0 ? pr.image_url : '/images/avatars/male01.png';
        //console.log("CUSTOMER", customerImage);

        const customerHandle = StyleUtils.getMediaStyle('customerHandle', sty.customerHandle, sty, StyleUtils.getWidthType(pr.width));

        return (
            <Div
                className="afterpartyCustomerComponent"
                style={{...sty.afterpartyCustomerComponent, ...pr.style}}
            >
                <BloatedCircleDefs scales={[56, 96]} />
                <Div
                    className="customerImage"
                    style={{
                        ...sty.customerImage,
                        ...{backgroundImage: `url('${customerImage}')`},
                        ...bloatedCircle,
                    }}
                >
                    {pr.microphoneIcon ? <Div style={{...sty.iconButton, ...sty.microphone}}><Icon icon="microphone" size={12} /></Div> : null}
                    {pr.questionIcon ? <Div style={{...sty.iconButton, ...sty.question}}><Icon icon="thumbs-up" size={12} /></Div> : null}
                </Div>
                <Div
                    className="customerTextContainer"
                    style={sty.customerTextContainer}
                >
                    <Div
                        className="customerName"
                        style={sty.customerName}
                    >
                        {pr.name}
                    </Div>
                    <Div
                        className="customerUsername"
                        style={customerHandle}
                    >
                        @{pr.username}
                    </Div>
                </Div>
            </Div>
        );
    }

    styles = {
        afterpartyCustomerComponent: {
            display: "flex",
            alignItems: "center",
            marginTop: 15,
        },
        customerImage: {
            backgroundSize: "cover",
            marginRight: 8,
            position: "relative",
            color: "white",
            backgroundColor: Colors.purpleLight,
            backgroundPosition: "center",
        },
        customerTextContainer: {
            display: "flex",
            flexDirection: "column",
        },
        customerName: {
            fontSize: 16,
        },
        customerUsername: {
            fontSize: 14,
            fontWeight: 400,
        },
        customerUsername_xs: {
            fontSize: 10,
        },
        iconButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 20,
            height: 20,
            borderRadius: 100,
            backgroundColor: "rgba(239, 76, 164, .8)",
            position: "absolute",
            cursor: "pointer",
        },
        question: {
            top: 7,
            left: 7,
        },
        microphone: {
            bottom: 7,
            left: 7,
        },
    };
}
