import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import DebugBox from './DebugBox';
import Button from './Button';
import AfterpartyLogo from './AfterpartyLogo';
import Image from './Image';
import Icon from './Icon';
import Input from './Input';
import BloatedCircleDefs from './BloatedCircleDefs';
import Span from './Span';
import Spinner from './Spinner';
import Div from './Div';
import Modal from './Modal';

import RegisterFlow from '../components/RegisterFlow';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"

import homePurpleIcon from "../assets/icons/home-purple.png"
import starsPurpleIcon from "../assets/icons/stars-purple.png"
import aiIcon from "../assets/icons/ai icon menu.png"
import creditCardPurpleIcon from "../assets/icons/credit-card-purple.png"
import peoplePurpleIcon from "../assets/icons/people-purple.png"
import apLogoPurpleIcon from "../assets/icons/ap-logo-purple.png"
import slidersPurpleIcon from "../assets/icons/sliders-purple.png"
import arrowRightPurpleIcon from "../assets/icons/arrow-right-purple.png"
import exitPurpleIcon from "../assets/icons/exit-purple.png"
import messageIcon from "../assets/icons/messageLightPurpleIcon.png"
import megaphoneIcon from "../assets/icons/megaphoneIcon.png"

import newBadge from "../assets/icons/newBadge.png"

// import festIcon from "../assets/images/splash/Fest.png"
// import houseApIcon from "../assets/images/splash/House AP.png"
// import nftPlatformIcon from "../assets/images/splash/NFT Platform.png"
// import roadmapIcon from "../assets/images/splash/Roadmap.png"
// import teamIcon from "../assets/images/splash/Team.png"
// import utopianIcon from "../assets/images/splash/Utopian.png"

// import gearIcon from "../assets/icons/gear.png"
// import starIcon from "../assets/icons/star white.png"


export default class SplashMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogoutModal: false,
            menuItems: [
                {
                    name: "home",
                    title: "Home",
                    link: "home",
                    icon: homePurpleIcon,
                },
                // {
                //     name: "/p/clone_yourself",
                //     title: "Clone Yourself",
                //     link: "/p/clone_yourself",
                //     icon: utopianIcon,
                // },
                {
                    name: "p/ai_creators",
                    title: "AI Chat",
                    link: "p/ai_creators",
                    icon: messageIcon,
                },
                {
                    name: "p/shoutouts_creator_directory",
                    title: "Shoutouts",
                    link: "p/shoutouts_creator_directory",
                    icon: megaphoneIcon,
                },
                {
                    name: "p/clone_yourself/artist_id-67",
                    title: "Afterparty AI",
                    link: "p/clone_yourself/artist_id-67",
                    icon: aiIcon,
                },
                // {
                //     name: "private/aichat",
                //     title: "Inbox",
                //     link: "private/aichat",
                //     icon: aiIcon,
                //     needLogin: true,
                // },
                {
                    name: "mywallet",
                    title: "My Collection",
                    link: "mywallet",
                    icon: starsPurpleIcon,
                    needLogin: true,
                },
                // NOTE: we keep this just in case Utopians come back
                // {
                //     name: "p/utopian_community",
                //     title: "Membership",
                //     link: "p/utopian_community",
                //     icon: creditCardPurpleIcon,
                // },
                {
                    name: "p/about",
                    title: "About Us",
                    link: "p/about",
                    icon: apLogoPurpleIcon,
                    hideOnLogin: true,
                },
                {
                    name: "settings",
                    title: "Settings",
                    link: "settings",
                    icon: slidersPurpleIcon,
                    needLogin: true,
                },
            ],

            otherMenuItems: [
                {
                    name: "p/afterparty_contact_form",
                    title: "Give Feedback",
                    link: "p/afterparty_contact_form",
                },
                {
                    name: "p/faq",
                    title: "Help & Support",
                    link: "p/faq",
                },
            ],
        };
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            selected: nextProps.selected,
        });
    }

    handleLogout() {
        Utils.setCookie("selectedConversationId", "");
        Api.getLogout(() => {
            Utils.setCookie("aiPostSurveyNumber", 1);
            window.location.reload();
        });
    }

    showLogoutModal() {
        this.setState({
            showLogoutModal: true,
        });
    }

    hideLogoutModal() {
        this.setState({
            showLogoutModal: false,
        });
    }

    closeSplashMenu() {
        const pr = this.props;
        if(pr.closeSplashMenu) {
            pr.closeSplashMenu();
        }
    }

    clickSplashMenuItem(link, target) {
        const pr = this.props;

        this.closeSplashMenu();
        Utils.gotoRoute(pr, link, target)
    }

    renderMenuItems() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="menuItems" style={{marginTop: 8}}>
                {st.menuItems.map((menuItem, menuItemIndex) => {
                    if(menuItem['mode']=="demo" && !Utils.isDemoMode()) {
                        return null;
                    }

                    if(menuItem.needLogin && !Utils.userIsLoadedAndExists(pr)) {
                        return null;
                    }

                    if(menuItem.hideOnLogin && Utils.userIsLoadedAndExists(pr)) {
                        return null;
                    }

                    let isSelectedStyle = null;

                    const isSelected = pr.history.location.pathname.indexOf(menuItem.link) > 0;

                    if(isSelected) {
                        isSelectedStyle = {backgroundColor: Colors.magenta}
                    }
                    // if(pr.selected === menuItem.name) {
                    //     isSelectedStyle = {backgroundColor: Colors.magenta}
                    // }

                    return (
                        <Div
                            key={`menuItem-${menuItemIndex}`}
                            className="splashMenuItemContainer"
                            style={{...sty.splashMenuItemContainer, ...StylesPlatform.cursorPointer, ...isSelectedStyle}}
                            onClick={this.clickSplashMenuItem.bind(this, menuItem.link, menuItem.target)}
                        >
                            <Image
                                className="splashMenuIcon"
                                style={sty.splashMenuIcon}
                                src={menuItem.icon}
                            />
                            <Div
                                className="splashMenuTitle"
                                style={sty.splashMenuTitle}
                            >
                                {menuItem.title}
                            </Div>
                            {menuItem.name == "p/shoutouts_creator_directory" ?
                                <Image
                                    src={newBadge}
                                    style={{width: 38, marginLeft: 10}}
                                />
                                :
                                null
                            }
                            {isSelected ?
                                null
                                :
                                <Image
                                    className="arrowRightSplashMenu"
                                    style={{width: 7, marginLeft: "auto", marginRight: 20}}
                                    src={arrowRightPurpleIcon}
                                />
                            }

                        </Div>
                    );
                })}
            </Div>
        )
    }

    renderOtherMenuItems() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let otherMenuItems = StyleUtils.getMStyle(sty, 'otherMenuItems', pr.screen.width, "md");
        if(pr.screen.mobile && !Utils.userIsLoadedAndExists(pr)) {
            otherMenuItems = Utils.deepCopy(otherMenuItems);
        }

        return (
            <Div className="otherMenuItems" style={otherMenuItems}>
                {st.otherMenuItems.map((otherMenuItem, otherMenuItemIndex) => {
                    if(otherMenuItem['mode']=="demo" && !Utils.isDemoMode()) {
                        return null;
                    }
                    return (
                        <Div
                            key={`otherMenuItem-${otherMenuItemIndex}`}
                            className="splashMenuItemContainer"
                            style={{...sty.splashMenuItemContainer, ...StylesPlatform.cursorPointer}}
                            onClick={this.clickSplashMenuItem.bind(this, otherMenuItem.link, otherMenuItem.target)}
                        >
                            <Div
                                className="splashMenuTitle"
                                style={{...sty.splashMenuTitle, ...{marginLeft: 0}}}
                            >
                                {otherMenuItem.title}
                            </Div>
                            <Image
                                className="splashMenuIcon"
                                style={{width: 7, marginLeft: "auto", marginRight: 20}}
                                src={arrowRightPurpleIcon}
                            />
                        </Div>
                    )
                })}
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        let usernameForMenu = Utils.get(pr.user, "username", "");
        let firstName = Utils.get(pr.user, "first_name", "");
        let lastName = Utils.get(pr.user, "last_name", "");

        const menuUsername = StyleUtils.getMStyle(sty, 'menuUsername', pr.screen.width, "md");
        let splashMenuComponent = StyleUtils.getMStyle(sty, 'splashMenuComponent', pr.screen.width, "md");
        splashMenuComponent = Utils.deepCopy(splashMenuComponent);
        splashMenuComponent.height = Utils.cssCalc();

        return (
            <Div className="splashMenuComponent" style={{...splashMenuComponent, ...pr.style}}>
                <DebugBox
                    show={st}
                />

                <Div className="splashMenuTopRow" style={sty.splashMenuTopRow}>
                    <AfterpartyLogo
                        logo="smallWhite"
                        style={{opacity: 0.4}}
                        width={36}
                        history={pr.history}
                        screen={pr.screen}
                    />
                    <Icon
                        style={{...sty.closeButton, ...StylesPlatform.cursorPointer}}
                        onClick={this.closeSplashMenu.bind(this)}
                        size={20}
                        icon="close"
                    />
                </Div>

                {this.renderMenuItems()}

                {Utils.userIsLoadedAndExists(pr) ?
                    null
                    :
                    <Div className="signInOutButtonContainer" style={{display: "flex", width: 275, padding: 16, marginTop: 10, gap: 8}}>
                        <Button
                            type="outlined"
                            size="small"
                            style={sty.signInButton}
                            onClick={this.clickSplashMenuItem.bind(this, 'login')}
                            noBorder
                        >
                            Sign In
                        </Button>
                        <Button
                            color="pinkGradient"
                            size="small"
                            style={sty.signOutButton}
                            onClick={this.clickSplashMenuItem.bind(this, 'register')}
                        >
                            Register
                        </Button>
                    </Div>
                }

                {this.renderOtherMenuItems()}

                {Utils.userIsLoadedAndExists(pr) ?
                    null
                    :
                    <Div className="gapSeparator" style={{padding: 10}} />
                }

                {Utils.userIsLoadedAndExists(pr) ?
                    <Div className="menuUsername" style={menuUsername}>
                        <Image
                            className="menuAvatarImage"
                            style={{...{width: 40, height: 40, borderRadius: 100, border: `1px solid #E4D5Fb`, marginLeft: 12, marginRight: 12}, ...StylesPlatform.cursorPointer}}
                            src={Utils.get(pr, "user.photo")}
                            onClick={Utils.gotoRoute.bind(this, pr, "settings")}
                        />
                        <Div className="fullNameUsername" style={{display: "flex", flexDirection: "column", marginRight: "auto"}}>
                            <a style={{color: "white", fontSize: 16, fontWeight: 400, textDecoration: "none"}} href="/webapp/settings">
                                {firstName} {lastName}
                            </a>
                            <a style={{color: "white", fontSize: 14, fontWeight: 400, textDecoration: "none", color: "#F2E7F5"}} href="/webapp/settings">
                                @{Utils.formatUsername(usernameForMenu)}
                            </a>

                        </Div>
                        <Image
                            className="logOutButton"
                            src={exitPurpleIcon}
                            style={{...{width: 15}, ...StylesPlatform.cursorPointer}}
                            onClick={this.showLogoutModal.bind(this)}
                        />
                    </Div>
                    :
                    null
                }

                {st.showLogoutModal ?
                    <Modal
                        screen={this.props.screen}
                        style={{maxWidth: 374}}
                        onRequestClose={this.hideLogoutModal.bind(this)}
                        className="logoutConfirmModal"
                        color="indigo"
                        title="Are you sure you want to log out?"
                        hideCloseButton
                    >
                        <Button
                            className="logoutButton"
                            onClick={this.handleLogout.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%", marginTop: 50, marginBottom: 10}}
                        >
                            Log out
                        </Button>
                        <Button
                            className="cancelButton"
                            onClick={this.hideLogoutModal.bind(this)}
                            type="outlined"
                            style={{width: "100%"}}
                        >
                            Cancel
                        </Button>
                    </Modal>
                    :
                    null
                }
            </Div>
        )
    }

    styles = {
        splashMenuComponent: {
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: Colors.indigo,
            width: 275,
            color: "white",
            // height: "100vh",
            zIndex: 1000,
            overflowY: "scroll",
            filter: "drop-shadow(rgba(0, 0, 0, 0.5) -2px 0px 4px)",
            display: "flex",
            flexDirection: "column",
        },
        splashMenuTopRow: {
            display: "flex",
            alignItems: "center",
            padding: 16,
        },
        afterpartyLogoOnly: {
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            width: 73,
            paddingTop: 46,
            paddingBottom: 50,
        },
        splashMenuItemContainer: {
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 12,
            marginLeft: 10,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
        },
        splashMenuIcon: {
            width: 20,
        },
        splashMenuTitle: {
            fontFamily: "Nyata",
            marginLeft: 16,
            fontSize: 17,
            fontWeight: 300,
            color: Colors.indigoLightest,
        },
        signInButton: {
            flexShrink: 0,
            width: "50%",
        },
        signOutButton: {
            flexShrink: 0,
            width: "50%",
        },
        menuItemButtonContainer: {
            // paddingLeft: 30,
            // paddingRight: 30,
        },
        closeButton: {
            marginLeft: "auto",
        },
        menuUsername: {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            alignItems: "center",
            "borderTop": "1px solid #23094A",
        },
        menuUsername_md: {
        },
        otherMenuItems: {
            marginTop: "auto",
        },
    };
}
