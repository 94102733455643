export default {
    subscriptions: {},
    topLevelMount: false,
    topLevelQu: [],

    subscribe(dataName, setStateFunction) {
       if(!this. subscriptions[dataName]) {
           this. subscriptions[dataName] = [];
       }
       // TODO: Make sure subscriptions don't happen more than once -- indexOf
       this. subscriptions[dataName].push(setStateFunction);
       console.log("this.subscriptionsSUB", this.subscriptions);
    },

    run() {
        if(!this.topLevelMount) {
            this.topLevelMount = true;
            for(var idx in this.topLevelQu) {
                var item = this.topLevelQu[idx];
                this.push(item[0], item[1]);
            }
        }

    },

    push(dataName, dataInfo) {
        console.log("this.subscriptionsPUT", this.subscriptions);
        if(!this.topLevelMount) {
            this.topLevelQu.push([dataName, dataInfo]);
        }
        //return;
        for(var idx1 in this.subscriptions) {
            var sub = this.subscriptions[idx1];
            for(var idx2 in sub) {
                let temp = {};
                temp[idx1] = dataInfo;
                console.log("PUT", idx2, temp);
                sub[idx2](temp);
            }
        }
    }
}
