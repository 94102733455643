import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';

import '../App.css';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';
import H1 from '../elements/H1';
import H2 from '../elements/H2';

import Div from './Div';
import SplashSocialMediaIcons from './SplashSocialMediaIcons';
import SplashFooter from './SplashFooter';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import menuImage from "../assets/images/splash/Menu.png"

import oneHeaderBackgroundImage from "../assets/images/splash/ONE - 2.jpg"
import oneAfterpartyLogo from "../assets/images/splash/Group 171.png"
import oneAfterpartyUniverseGradient from "../assets/images/splash/Gradient.png"
//import oneAfterpartyUtopian from "../assets/images/splash/Universe_ Utopian 2.png"
import oneAfterpartyUtopian from "../assets/images/splash/Universe - Utopian and Guardian.png"
//import oneAfterpartyFestival from "../assets/images/splash/Universe_ Fest 2.png"
import oneAfterpartyFestival from "../assets/images/splash/Universe - NFT Fest - desktop and mobile.png"

import oneAfterpartyVideoCall from "../assets/images/splash/Universe_ Platform 2.png"

import oneAfterpartyApe1 from "../assets/images/splash/david-fields@2x.png"
import teamUtopian1 from "../assets/images/splash/David_Square.jpeg"
import oneAfterpartyApe2 from "../assets/images/splash/eytan-elbaz@2x.png"
import teamUtopian2 from "../assets/images/splash/Eytan_sq.jpeg"
import oneAfterpartyApe3 from "../assets/images/splash/dan-rahmel@2x.png"
import teamUtopian3 from "../assets/images/splash/Dan_sq.jpeg"
import oneAfterpartyApe4 from "../assets/images/splash/robert-graham@2x.png"
import teamUtopian4 from "../assets/images/splash/Robert_sq.jpeg"

import iconPurpleTwitterThick from "../assets/images/splash/twitter 2.png"
import iconPurpleDiscordThick from "../assets/images/splash/Discord 2.png"
import iconPurpleInstagramThick from "../assets/images/splash/ig 2.png"

import btnUtopians from "../assets/images/splash/btnUtopians.png"
import btnGuardians from "../assets/images/splash/btnGuardians.png"

export default class StaticSplash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "afterparty",
            showCollectionModal: false,

            includes: {},
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.setState({selected: pr.route ? pr.route : 'afterparty'}, () => {
            this.handleRoute(st.selected);
        });
        if(pr.includes) {
            this.setState({includes: pr.includes});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';
        //console.log("INCLUDES2", nextProps.includes);
        let tempState = {};
        if(nextProps.includes) {
            tempState['includes'] = nextProps.includes;
        }
        if(this.state.selected != selected) {
            tempState['selected'] = selected;
        }
        // if(Utils.get(nextProps, "includes.presale") && !Utils.getRequest('presale')) {
        //     tempState['showPresale'] = Utils.get(nextProps, "includes.presale");
        // }
        this.setState(tempState);
    }

    handleRoute(selected) {
    }

    closeBiddingModal() {
        this.setState({
            showCollectionModal: false,
        });
    }


    newTab(link, target) {
        window.open(link, "_blank");
        // if(target && typeof target === 'string') {
        //     window.open(link, target);
        // } else {
        //     window.location.href = link;
        // }
    }

    newReactPage(link) {
        this.props.history.push(`/${link}`);
    }

    handleCollectionClick(link) {
        this.setState({showCollectionModal: true});
    }


    videoContestLink() {
        console.log("videoContestLink");
    }

    renderHeaderOneSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const headerOneSection = StyleUtils.getMediaStyle('headerOneSection', sty.headerOneSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const headerOneInfoContainer = StyleUtils.getMediaStyle('headerOneInfoContainer', sty.headerOneInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneMainTitle = StyleUtils.getMediaStyle('oneMainTitle', sty.oneMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyLogoStyle = StyleUtils.getMediaStyle('oneAfterpartyLogoStyle', sty.oneAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="headerOneSection" style={sty.headerOneSection}>
                <Div className="headerOneInfoContainer" style={headerOneInfoContainer}>
                    <H1 style={{textAlign: "left"}}>
                        <Image
                            className="oneAfterpartyLogo"
                            src={oneAfterpartyLogo}
                            style={oneAfterpartyLogoStyle}
                            alt="Afterparty"
                        />
                    </H1>

                    <H2 className="oneMainTitle" style={oneMainTitle}>
                        Building community and technology for the Web3 creator economy
                    </H2>
                    <Div className="oneMainSubtitle" style={sty.oneMainSubtitle}>
                        Join the community
                    </Div>

                    <SplashSocialMediaIcons
                        screen={pr.screen}
                        size={40}
                    />
                </Div>
            </Div>
        )
    }

    renderOneAfterpartyUniverse() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const oneAfterpartyUniverse = StyleUtils.getMediaStyle('oneAfterpartyUniverse', sty.oneAfterpartyUniverse, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyUniverseDescription = StyleUtils.getMediaStyle('oneAfterpartyUniverseDescription', sty.oneAfterpartyUniverseDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyUniverseCards = StyleUtils.getMediaStyle('oneAfterpartyUniverseCards', sty.oneAfterpartyUniverseCards, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyUniverseCard = StyleUtils.getMediaStyle('oneAfterpartyUniverseCard', sty.oneAfterpartyUniverseCard, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyCardTitle = StyleUtils.getMediaStyle('oneAfterpartyCardTitle', sty.oneAfterpartyCardTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="oneAfterpartyUniverse" style={oneAfterpartyUniverse}>
                <Div
                    className="oneAfterpartyUniverseTitle"
                    style={sty.oneAfterpartyUniverseTitle}
                >
                    The Afterparty Universe
                </Div>

                <Div
                    className="oneAfterpartyUniverseDescription"
                    style={oneAfterpartyUniverseDescription}
                >
                    Afterparty is a community of innovative creators, artists, and builders with a Web3 platform for accessing physical and virtual experiences through NFTs. We are building a community & platform where anything is possible and creativity flourishes.
                </Div>

                <Div className="oneAfterpartyUniverseCards" style={oneAfterpartyUniverseCards}>
                    <Div
                        className="oneAfterpartyUniverseCard"
                        style={{...oneAfterpartyUniverseCard, ...StylesPlatform.cursorPointer}}
                        onClick={this.handleCollectionClick.bind(this, "utopian")}
                    >
                        <Image
                            className="oneAfterpartyUniverseImage"
                            style={sty.oneAfterpartyUniverseImage}
                            src={oneAfterpartyUtopian}
                        />
                        <Div className="oneAfterpartyCardTitle" style={{...oneAfterpartyCardTitle, ...sty.oneAfterpartyUtopianTitle}}>
                            NFT Collections
                        </Div>
                        <Div className="subTitleCard" style={sty.subTitleCard}>
                            With IRL Utility
                        </Div>
                        <Button
                            style={sty.splashMagentaButton}
                            type="splash"
                            onClick={this.handleCollectionClick.bind(this, "utopian")}
                        >
                            Learn More
                        </Button>
                    </Div>

                    <Div
                        className="oneAfterpartyUniverseCard"
                        style={{...oneAfterpartyUniverseCard, ...StylesPlatform.cursorPointer}}
                        onClick={this.newTab.bind(this, "https://afterparty.com/sl/festival")}
                    >
                        <Image
                            className="oneAfterpartyUniverseImage"
                            style={sty.oneAfterpartyUniverseImage}
                            src={oneAfterpartyFestival}
                        />
                        <Div className="oneAfterpartyCardTitle" style={{...oneAfterpartyCardTitle, ...sty.oneAfterpartyFestivalTitle}}>
                          nft art and music festivals
                        </Div>
                        <Div className="subTitleCard" style={sty.subTitleCard}>
                            Las Vegas + Los Angeles
                        </Div>
                        <Button
                            style={sty.splashMagentaButton}
                            type="splash"
                            onClick={this.newTab.bind(this, "https://afterparty.com/sl/festival")}
                        >
                            Learn More
                        </Button>
                    </Div>

                    <Div
                        className="oneAfterpartyUniverseCard"
                        style={{...oneAfterpartyUniverseCard, ...StylesPlatform.cursorPointer}}
                        onClick={this.newReactPage.bind(this, "platform")}
                    >
                        <Image
                            className="oneAfterpartyUniverseImage"
                            style={sty.oneAfterpartyUniverseImage}
                            src={oneAfterpartyVideoCall}
                        />
                        <Div className="oneAfterpartyCardTitle" style={{...oneAfterpartyCardTitle, ...sty.oneAfterpartyVideoTitle}}>
                          Creator to Consumer NFT Platform
                        </Div>
                        <Div className="subTitleCard" style={sty.subTitleCard}>
                            Creator to fan connection
                        </Div>
                        <Button
                            style={sty.splashMagentaButton}
                            type="splash"
                            onClick={this.newReactPage.bind(this, "platform")}
                        >
                            Learn More
                        </Button>
                    </Div>

                </Div>
            </Div>
        )
    }

    renderOneTeam() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const teamContainer = StyleUtils.getMediaStyle('teamContainer', sty.teamContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="oneTeamSection" style={sty.oneTeamSection}>
                <Div className="oneTeamTitle" style={sty.oneTeamTitle}>
                    The Team
                </Div>
                <Div className="teamContainer" style={teamContainer}>
                    <Div className="apeIconContainer" style={{...sty.apeIconContainer, ...StylesPlatform.cursorPointer}} onClick={this.newTab.bind(this, "https://twitter.com/DavFields")}>
                        <Div
                            className="apeIcon"
                            style={{...sty.apeIcon, ...{backgroundImage: `url(${teamUtopian1})`}}}
                        ></Div>
                        <Div className="teamTitle" style={sty.teamTitle}>
                            David Fields
                        </Div>
                        <Div className="teamSubTitle" style={sty.teamSubTitle}>
                            Founder
                        </Div>
                        <Div className="teamSubTitle" style={{...sty.teamSubTitle, ...{fontSize: 12, textAlign: "center", marginTop: 10}}}>
                            • Corporate Development, Disney<br/>
                            • Head of Investments, Tornante<br/>
                            • Harvard University
                        </Div>
                    </Div>

                    <Div className="apeIconContainer" style={{...sty.apeIconContainer, ...StylesPlatform.cursorPointer}} onClick={this.newTab.bind(this, "https://twitter.com/eytanelbaz")}>
                        <Div
                            className="apeIcon"
                            style={{...sty.apeIcon, ...{backgroundImage: `url(${teamUtopian2})`}}}
                        ></Div>
                        <Div className="teamTitle" style={sty.teamTitle}>
                            Eytan Elbaz
                        </Div>
                        <Div className="teamSubTitle" style={sty.teamSubTitle}>
                            Founder
                        </Div>

                        <Div className="teamSubTitle" style={{...sty.teamSubTitle, ...{fontSize: 12, textAlign: "center", marginTop: 10}}}>
                            • Co-creator, AdSense<br/>
                            • Head of Domain Channel, Google<br/>
                            • Co-founder, Scopely
                            • UCLA
                        </Div>
                    </Div>

                    <Div className="apeIconContainer" style={{...sty.apeIconContainer, ...StylesPlatform.cursorPointer}} onClick={this.newTab.bind(this, "https://www.linkedin.com/in/dan-rahmel-37271113/")}>
                        <Div
                            className="apeIcon"
                            style={{...sty.apeIcon, ...{backgroundImage: `url(${teamUtopian3})`}}}
                        ></Div>
                        <Div className="teamTitle" style={sty.teamTitle}>
                            Dan Rahmel
                        </Div>
                        <Div className="teamSubTitle" style={sty.teamSubTitle}>
                            Founder
                        </Div>

                        <Div className="teamSubTitle" style={{...sty.teamSubTitle, ...{fontSize: 12, textAlign: "center", marginTop: 10}}}>
                            • Intuit<br/>
                            {/* • Demand Media<br/> */}
                            • VP Engineering,<br/>Music Audience Exchange<br/>
                            • UCSD
                        </Div>
                    </Div>

                    <Div className="apeIconContainer" style={{...sty.apeIconContainer, ...StylesPlatform.cursorPointer}} onClick={this.newTab.bind(this, "https://twitter.com/RobertGraham24")}>
                        <Div
                            className="apeIcon"
                            style={{...sty.apeIcon, ...{backgroundImage: `url(${teamUtopian4})`}}}
                        ></Div>
                        <Div className="teamTitle" style={sty.teamTitle}>
                            Robert Graham
                        </Div>
                        <Div className="teamSubTitle" style={sty.teamSubTitle}>
                            Chief Community Officer
                        </Div>

                        <Div className="teamSubTitle" style={{...sty.teamSubTitle, ...{fontSize: 12, textAlign: "center", marginTop: 10}}}>
                            • Paradigm Talent Agency<br/>
                            • VAMPPED<br/>
                            • Arizona State University
                        </Div>
                    </Div>
                </Div>

            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="afterpartyStaticHomeComponent" style={sty.afterpartyStaticHomeComponent}>
                {this.renderHeaderOneSection()}
                {this.renderOneAfterpartyUniverse()}
                {this.renderOneTeam()}

                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                />
                { this.state.showCollectionModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeBiddingModal.bind(this)}
                        className="biddingModal"
                        color="purple"
                        title={`Select Collection`}
                        style={{width:375,height:358}}
                    >
                        <Div style={{display:"flex",marginTop:10}}>
                            <Div style={this.styles.collectionButtonContainer} onClick={this.newReactPage.bind(this, "utopian")}>
                                <Div style={{marginBottom:10}}>
                                    <Image style={{width:145}} src={btnUtopians} />
                                </Div>
                                <Div style={{fontSize:14}}>The Utopians</Div>
                            </Div>
                            <Div style={this.styles.collectionButtonContainer} onClick={this.newReactPage.bind(this, "guardian")}>
                                <Div style={{marginBottom:10}}>
                                    <Image style={{width:145}} src={btnGuardians} />
                                </Div>
                                <Div style={{fontSize:14}}>The Guardians</Div>
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                }

            </Div>
        );
    }

    styles = {
        splashMagentaButton: {
            backgroundColor: Colors.magenta,
            color: Colors.splashDarkPurple,
            marginTop: 12,
            marginBottom: 12,
        },
        afterpartyStaticHomeComponent: {
            backgroundColor: Colors.splashDarkPurple,
        },
        headerOneSection: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            height: 852,
            backgroundImage: `url("${oneHeaderBackgroundImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            padding: 20,
        },
        headerOneSection_md: {
            backgroundPosition: "right bottom",
            backgroundImage: `url("${oneHeaderBackgroundImage}")`,
            height: 852,
        },
        headerOneInfoContainer: {
            padding: 56,
            width: "fit-content",
            borderRadius: 40,
            marginRight: "auto",
            marginLeft: 153,
        },
        headerOneInfoContainer_md: {
            marginLeft: "auto",
            marginRight: "auto",
            padding: 0,
        },
        oneAfterpartyLogoStyle: {
            width: 254,
            marginBottom: 5,
        },
        oneAfterpartyLogoStyle_md: {
            width: 184,
        },
        oneMainTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 72,
            color: "white",
            maxWidth: 942,
            width: "100%",
            lineHeight: "80px",
            marginBottom: 35,
        },
        oneMainTitle_md: {
            fontSize: 50,
            lineHeight: "54px",
            fontWeight: "auto",
        },
        oneMainSubtitle: {
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 18,
            marginBottom: 20,
        },

        oneAfterpartyUniverse: {
            width: "100%",
            minHeight: 1350,
            backgroundColor: Colors.splashDarkPurple,
            backgroundImage: `url("${oneAfterpartyUniverseGradient}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 10,
        },
        oneAfterpartyUniverse_md: {
            padding: 20,
        },
        oneAfterpartyUniverseTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 48,
            color: "white",
            marginBottom: 30,
            textAlign: "center",
            marginTop: 80,
        },
        oneAfterpartyUniverseDescription: {
            fontFamily: "Mont Demo",
            fontSize: 18,
            color: "white",
            marginBottom: 30,
            textAlign: "center",
            maxWidth: 942,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 70,
        },
        oneAfterpartyUniverseDescription_md: {
            marginBottom: 20,
        },
        oneAfterpartyUniverseCards: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 10,
        },
        oneAfterpartyUniverseCards_md: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 10,
        },
        oneAfterpartyUniverseCard: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: Colors.splashDarkPurple,
            width: "100%",
            maxWidth: 382,
            paddingTop: 55,
            paddingRight: 55,
            paddingBottom: 25,
            paddingLeft: 55,
            borderRadius: 16,
            boxShadow: "-2px 8px 33px rgba(0, 0, 0, 0.2)",
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
        },
        oneAfterpartyUniverseCard_md: {
            paddingTop: 35,
            paddingRight: 35,
            paddingBottom: 15,
            paddingLeft: 35,
            marginLeft: 0,
            marginRight: 0,
            maxWidth: 300,
        },
        oneAfterpartyCardTitle: {
            color: "white",
            textAlign: "center",
            height: 70,
        },
        oneAfterpartyCardTitle_md: {
            height: "auto",
        },
        oneAfterpartyUtopianTitle: {
            fontFamily: "Coluna",
            fontWeight: 400,
            fontSize: 52,
        },
        oneAfterpartyFestivalTitle: {
            fontFamily: "Adina",
            fontSize: 18,
            fontWeight: 700,
            paddingTop: 6,
            paddingBottom: 10,
            // marginBottom: 20,
        },
        oneAfterpartyVideoTitle: {
            fontFamily: "Nyata",
            fontWeight: 800,
            fontSize: 27,
            textAlign: "center",
            lineHeight: "31px",
            paddingBottom: 10,
            // marginBottom: 15,
        },
        subTitleCard: {
            fontFamily: "Mont Demo",
            fontSize: 20,
            color: "white",
            textAlign: "center",
            marginBottom: 10,
        },
        oneTeamSection: {
            backgroundColor: Colors.splashDarkPurple,
            minHeight: 600,
            paddingTop: 150,
        },
        oneTeamTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 48,
            color: "white",
            marginBottom: 50,
            textAlign: "center",
        },
        teamContainer: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
        },

        apeIconContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: 25,
            marginRight: 25,
            marginBottom: 40,
        },
        apeIcon: {
            width: 215,
            marginBottom: 30,
            borderColor: Colors.magenta,
            borderWidth: 3,
            borderStyle: "solid",
            borderRadius: 300,
            height: 215,
            backgroundPosition: "center",
            backgroundSize: "101%",
        },
        teamTitle: {
            fontFamily: "Nyata",
            fontWeight: 900,
            fontSize: 26,
            color: "white",
            marginBottom: 2,
        },
        teamSubTitle: {
            fontFamily: "Mont Demo",
            color: "white",
            fontSize: 16,
        },
        oneAfterpartyUniverseImage: {
            width: "100%",
            marginBottom: 40,
        },
        collectionButtonContainer: {
            margin:12,
            textAlign:"center",
            cursor:"pointer",
        },
    };
}
