import React, { Fragment } from 'react';

import { Carousel } from 'react-responsive-carousel';
import Scroller from './Scroller';
import PlaylistScroller from './PlaylistScroller';
import axios from 'axios';
import ReactPlayer from "react-player";
import { AreaChart, LineChart, PieChart } from 'react-chartkick'
import 'chart.js'
import DebugBox from '../elements/DebugBox';

import spiral from '../assets/spiral.png';

import moment, { max } from 'moment'

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';

import Div from './Div';
import AfterpartyBidBadge from './AfterpartyBidBadge';
import ConnectWallet from './ConnectWallet';

import Button from '../elements/Button';
import Modal from '../elements/Modal';
import Span from '../elements/Span';
import Input from '../elements/Input';
import Icon from '../elements/Icon';

import SearchBox from '../components/SearchBox';
import RegisterFlow from '../components/RegisterFlow';

export default class DashboardSoinListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        selectedPlaylists: props.selectedPlaylists ? props.selectedPlaylists : [],
        playlistChange: false,
        blackListMode: false,
        blacklist: {},
        dirty: false,
        highlightColor: Utils.get(props.mediaItem.listing, "sort_order") != 3000 ? "#EEE1FC" : "#9342E8",
        highlightColor2: "#666",
        demoMode: Utils.getCookie('demomode') == 1,

        showBiddingModal: false,
        showRegisterFlowModal: false,

        isBiddingTime: false,
        mediaItem: props.mediaItem,
        biddingMinutesLeft: Utils.get(props.mediaItem, "goLive.minutesLeft", 0),
        biddingSecondsLeft: Utils.get(props.mediaItem, "goLive.secondsLeft", 0),
        minutesToCome: Utils.get(props.mediaItem, "goLive.minutesToCome", 0),
        dropDateBegin: Utils.get(props.mediaItem, "goLive.drop_date_begin", null),
        dropDateEnd: Utils.get(props.mediaItem, "goLive.drop_date_end", null),

        bidAmount: "",
        highestBid: Utils.get(props.mediaItem, "auctionStatus.highest_bid", 0),
        bidCount: Utils.get(props.mediaItem, "auctionStatus.bid_count", 0),
        intervalBidCount: Utils.get(props.mediaItem, "auctionStatus.interval_bid_count", 0),

        isHighestBidder: false,
        highestBidderId: -1,
        outBid: false,

        showBidNotification: false,
        notificationMessage: "No message",
        bidFailedErrors: [],
        bidErrorMessages: [],
        bidWarningMessages: [],

        showRegisterFlowModal: false,
        registeredSuccess: false,
        registerModalBody: "username",

        ethPriceInUsd: Utils.get(props.includes, "conversion.ethusd", -1),
        moonpayInfo: Utils.get(props.includes, "moonpay"),

        mediaItemLoaded: false,
        showMoonPayModal: false,
        showListItem: false,
        showListItemSelected: 'sale', // sale or auction
        listingPrice: 600,
        listingPriceEth: 0.262,
        reservePrice: 700,
        reservePriceEth: 0.306,
        listingStartDate: "Now",
        listingEndDate: "3:30pm, 5/17/2022",

    };

    this.BID_STEP = 250000;
    this.gwei = 1000000000;
    // this.wei = 1000000000000000000;
  }

  keydownHandler(e) {
    if(e.keyCode === 13 && e.ctrlKey) {
        console.log("blackListMode");
        this.setState({blackListMode:true});
    }
  }

  componentDidMount() {
    document.addEventListener('keydown',this.keydownHandler.bind(this));

    //window.refreshBiddingPrice = setInterval(this.getCurrentBidPrice.bind(this), 5000);

    if(!this.countdownInterval) {
        this.countdownInterval = window.setInterval(this.updateBiddingSeconds.bind(this), 1000);
    }
  }

   UNSAFE_componentWillReceiveProps(newProps) {
       //return;
       const st = this.state;
       console.log("UNSAFE_componentWillReceiveProps mediaItem >>>", newProps.mediaItem);

       let tempState = {};
       if(newProps.mediaItem) {
           tempState.mediaItem = newProps.mediaItem;
           tempState.relatedSoins = newProps.relatedSoins;

           tempState.highlightColor = Utils.get(tempState.mediaItem.listing, "sort_order") != 3000 ? "#6CDCCD" : "#9342E8";
       }
       this.setState(tempState, () => {
           if(Utils.get(newProps, "mediaItem.listing.id") && !st.mediaItemLoaded) {
               this.setState({mediaItemLoaded: true});
               Api.getAfterpartyListing(newProps.mediaItem.listing.id, (response) => {
                   const biddingSecondsLeft = parseInt(Utils.get(response, "data.goLive.secondsLeft"));
                   const biddingMinutesLeft = Utils.get(response, "data.goLive.minutesLeft");
                   const dropDateBegin = Utils.get(response, "data.goLive.drop_date_begin")
                   const dropDateEnd = Utils.get(response, "data.goLive.drop_date_end");

                   this.setState({
                       biddingSecondsLeft: biddingSecondsLeft,
                       biddingMinutesLeft: biddingMinutesLeft,
                       dropDateBegin: dropDateBegin,
                       dropDateEnd: dropDateEnd,
                   }, () => {
                       if(!this.countdownInterval) {
                           this.countdownInterval = window.setInterval(this.updateBiddingSeconds.bind(this), 1000);
                       }
                   });
               })
               this.getCurrentBidPrice();
           }
       });
   }

  componentWillUnmount() {
      const pr = this.props;
      const st = this.state;

      document.removeEventListener('keydown',this.keydownHandler);
      if(pr.apiLoadStreaming && st.dirty) {
          pr.apiLoadStreaming();
      }

      if(window.refreshBiddingPrice) {
          clearInterval(window.refreshBiddingPrice);
      }
  }

    changeVal(valPath, event) {
        //this.setWindowDirty(true);
        console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        if(valPath == 'listingPrice') {
            tempState['listingPriceEth'] = (event.target.value * 0.00043649).toFixed(3);
        } else if(valPath == 'reservePrice') {
            tempState['reservePriceEth'] = (event.target.value * 0.00043649).toFixed(3);
        }
        this.setState(tempState);
    }

  updateBiddingSeconds() {
      const st = this.state;

      if(st.biddingSecondsLeft == -1 || st.biddingSecondsLeft == -2) {
          return;
      }

      this.setState({
          biddingSecondsLeft: st.biddingSecondsLeft - 1,
          biddingMinutesLeft: st.biddingMinutesLeft,
          minutesToCome: st.minutesToCome,
      }, () => {
          if(st.biddingSecondsLeft <= 0) {
              window.clearInterval(this.countdownInterval);
              this.countdownInterval = undefined;
          }

          if(st.biddingSecondsLeft % 60 === 0) {
              this.setState({eventMinutesLeft: st.biddingMinutesLeft - 1});
          }
      });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleMarketplaceLink() {
    let sel = 'marketplace2';
    this.props.history.push('/'+sel)
    this.props.change(sel);
  }

  handleSubmit(event) {
    alert('A modal name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  handleAddToWatchlist(event) {
      const pr = this.props;
      const st = this.state;

    if(pr.user && pr.user["id"]) {
        alert("The '" + pr.listing["title"] + "' soin has been added to your watchlist");
    } else {
        alert("You need to sign in or register to add to a watchlist. Please register now.");
    }
  }

  handlePosterClick(item) {
      const pr = this.props;

      item["thumbnail"] = item["cover_big"];
      this.setState({
              selectedMediaItem: item, // TODO "selectedMediaItem" is not used, but leave it
      }, () => {
        pr.change('item', item);
      });
  }

    handleBuyWithMoonPay() {
        this.setState({showMoonPayModal:true});
    }

    handleBuyWithWallet() {
        if(typeof ethereum != "undefined") {
            let walletAddress = ethereum.selectedAddress;
            //walletAddress = "0xebfd733fbdd1d91b75e941908bf3c748492ced29";
            console.log("walletAddress", walletAddress);
            let params = [];
            const rinkebyMintPass = '0x1a963216e887bbcb6414be75c5e4d72dF27D6a64';
            if(false) {
                const tx = {
                    //nonce: '0x00', // ignored by MetaMask
                    //gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
                    //gasPrice: '0x00000100', // customizable by user during MetaMask confirmation.
                    //gas: '0x2710', // customizable by user during MetaMask confirmation.
                    from: walletAddress, // must match user's active address.
                    to: '0x35358364a719cb90Df75C2B7FFe1570273804F06', // Required except during contract publications.
                    value: '0x100', // Only required to send ether to the recipient from the initiating external account.
                    data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
                    //chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
                };
                params.push(tx);
            } else if(true) {
                // mintPass
                const mintPassCmd = '0x57d67cd7000000000000000000000000';
                const mintToPassAddress = '0x2E00281abdEc577DE34c0764e9cB19b2B65B7ebc'.replace('0x', '');
                const abiCmd = mintPassCmd + mintToPassAddress+'0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000';
                //'0x57d67cd7000000000000000000000000' + '2e00281abdec577de34c0764e9cb19b2b65b7ebc' + '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000';
                const tx = {
                    from: walletAddress,
                    to: rinkebyMintPass,
                    value: '0x100', // Amount in Wei
                    data: abiCmd,
                };
                params.push(tx);
            } else if(false) {
                // addToPassWhitelist
                const whitelistCmd = '0x52a93fe6000000000000000000000000';
                const whitelistAddress = '0x2E00281abdEc577DE34c0764e9cB19b2B65B7ebc'.replace('0x', '');
                // 0x52a93fe60000000000000000000000002e00281abdec577de34c0764e9cb19b2b65b7ebc00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001
                const abiCmd = whitelistCmd+whitelistAddress+'00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001';
                //'0x57d67cd7000000000000000000000000' + '2e00281abdec577de34c0764e9cb19b2b65b7ebc' + '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000';
                const tx = {
                    from: walletAddress,
                    to: rinkebyMintPass,
                    data: abiCmd,
                };
                params.push(tx);
            }

            const txHash = ethereum.request({
                method: 'eth_sendTransaction',
                params: params,
            }).then((o) => {
                console.log("BUY", o);
            }).catch((o) => {
                console.log("Fail", o);
                if(o.code == 4001) {
                    alert("Customer cancelled transaction");
                } else if(o.code == -32602) {
                    alert("Invalid wallet selected. Maybe you're not logged into Metamask? Please select wallet in Metamask.");
                } else if(o.code == -32603) {
                    const _self = this;
                    Api.putMusicListingBuy(this.state.mediaItem["id"], {},  function(data) {
                        alert('Congratulations! You bought a print!');
                        //_self.setState({mediaItem: data["data"]});
                    });
                }
            });
        } else {
            alert("Metamask not available for this page. Please activate and reload");
        }
    }


  runSearch(event, search) {
      console.log("Run search on ", search);
      this.props.change("search", search);
  }


  handleBlacklist(url) {
      console.log("Blacklist", url);
      const params = {
          key: "923489408230ajnssdmvmsdf",
          url: url,
      };
      const _self = this;
        axios.post(`/api/v1/guideboxblacklist`, params).then(res => {
          //console.log('RETURNED', res);
          console.log("BLACKLISTED", res.data);
          let bl = this.state.blacklist;
          bl[url] = true;
          _self.setState({blackList:bl});
        });
  }

    onRequestRegisterModalClose() {
        this.setState({
            showRegisterFlowModal: false,
            registerModalBody: "username",
            rsvpModalMessage: "",
        });
    }

    onRequestRsvpModalOpen() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "username",
        });
    }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "login"
        });
    }

  getCurrentBidPrice() {
      const pr = this.props;
      const st = this.state;

      if(!pr.listing) {
          return;
      }

      this.validateBid();

      Api.getAfterpartyListing(pr.listing.id, (res) => {
          if(!res || res.errors.length) {
              return;
          }

          const data = res.data;
          const auctionStatus = res.data.auctionStatus;

          if(Utils.get(res, "data.signal", false)) {
              for(var sIndex in data.signal) {
                 var item = data.signal[sIndex];
                 var key = "s-"+item['id'];

                 if(Utils.getCookie(key)) {
                     continue;
                 }

                 if(pr.user.id == item.customer_id && pr.listing.id == item.extra_id) {
                     if(item['cmd'] == 'highestbidder') {
                         Utils.setCookie(key, 1);
                         this.setState({
                             isHighestBidder: true,
                             showBidNotification: true,
                             notificationMessage: "You are the highest bidder!",
                             outBid: false,
                         });
                     } else if(item['cmd'] == 'outbid') {
                         Utils.setCookie(key, 1);
                         this.setState({
                             isHighestBidder: false,
                             showBidNotification: true,
                             notificationMessage: "You have been outbid!",
                             outBid: true,
                         });
                     }
                 }
              }
          }

          const biddingSecondsLeft = parseInt(Utils.get(res, "data.goLive.secondsLeft"));
          const biddingMinutesLeft = Utils.get(res, "data.goLive.minutesLeft");
          const dropDateBegin = Utils.get(res, "data.goLive.drop_date_begin");
          const dropDateEnd = Utils.get(res, "data.goLive.drop_date_end");
          const ethPriceInUsd = Utils.get(res, "includes.conversion.ethusd");

          this.setState({
              highestBid: auctionStatus.highest_bid,
              bidCount: auctionStatus.bid_count,
              intervalBidCount: auctionStatus.interval_bid_count,
              highestBidderId: auctionStatus.highest_bidder,

              biddingSecondsLeft: biddingSecondsLeft,
              biddingMinutesLeft: biddingMinutesLeft,
              dropDateBegin: dropDateBegin,
              dropDateEnd: dropDateEnd,

              ethPriceInUsd: ethPriceInUsd,
          });
      });
  }

  openBiddingModal() {
      const pr = this.props;
      const st = this.state;
      // let convertedBidAmount = (st.highestBid * this.gwei) + 1
      let convertedBidAmount = (st.highestBid * this.gwei) + this.BID_STEP

      if(!pr.user.email) {
          this.setState({
              showRegisterFlowModal: true,
          });
          return;
      }

      this.setState({
          showBiddingModal: true,
          bidAmount: convertedBidAmount / this.gwei,
      }, () => {
          this.validateBid();
      });
  }

  closeBiddingModal() {
      this.setState({
          showBiddingModal: false,
      });
  }
  closeMoonPayModal() {
      this.setState({
          showMoonPayModal: false,
      });
  }


  closeListingModal() {
      this.setState({
          showListItem: false,
      });
  }

  placeBidOnListing() {
      const pr = this.props;
      const st = this.state;

      const bidData = {
          // bid_amount: Number(st.bidAmount),
          bid_amount: st.bidAmount,
          listing_id: pr.listing.id,
      };

      Api.postListingBid(bidData, (res) => {
          if(res.success) {
              this.setState({
                  highestBid: res.data.highest_bid,
                  bidCount: st.bidCount + 1,
                  bidFailedErrors: [],
                  bidErrorMessages: [],
                  bidWarningMessages: [],
                  showBiddingModal: false,
                  showBidNotification: true,
              });
          } else {
              this.setState({
                  bidFailedErrors: res.errors,
              });
          }
      })
  }

  bidAmountOnChange(e) {
      const re = /^\d+(\.?)(\d+)?$/;

      if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({bidAmount: e.target.value}, () => {
              this.validateBid();
          });
      }
  }

  nextBidAuto(nextBid) {
      this.setState({
          bidAmount: nextBid,
      });
  }
  handleClickBidNotification() {
      console.log("handleClickBidNotification >>>");
      this.setState({
          // showBiddingModal: true,
          showBidNotification: false,
      });
  }
  handleToggleSaleType() {
      this.setState({
          showListItemSelected: this.state.showListItemSelected == 'sale' ? 'auction' : 'sale',
      });

  }

  handleClickListItem(listStatus) {
      listStatus = listStatus ? listStatus : 4;
      console.log("this.props.mediaItem", this.props.mediaItem);
      let data = {
        listing_status:listStatus,
        initial_price:this.state.listingPrice,
      }

      Api.putListing(this.props.mediaItem['listing']['id'], data, (o) => {
          console.log(o);
          this.setState({
              // showBiddingModal: true,
              showListItem: false,
          });
          window.location.reload(true);
      });
  }

  handleCloseBidNotification() {
      console.log("handleCloseBidNotification >>>");
      this.setState({
          // showBiddingModal: false,
          showBidNotification: false,
      });
  }

  goToRegisterPage() {
      const pr = this.props;

      pr.history.push('/login');
      pr.change('login');
  }

  setRegisteredSuccess(isSuccessful) {
      this.setState({
          registeredSuccess: isSuccessful,
      });
  }

  ethToDollars(dollarAmount = 1, decimalPlaces = 4) {
      const st = this.state;

      if(st.ethPriceInUsd === -1) {
          return "loading...";
      }

      return ((1 / st.ethPriceInUsd) * dollarAmount).toFixed(decimalPlaces)
  }

  isValidBid() {
      const st = this.state;

      const newBidIsHigher = Number(st.bidAmount) > st.highestBid;
      return newBidIsHigher;
  }

  validateBid() {
      const pr = this.props;
      const st = this.state;

      let bidErrorMessages = [];
      let bidWarningMessages = [];

      if(!this.isValidBid()) {
          bidErrorMessages.push("Your bid is lower than the current bid");
      }

      if(!(pr.user.wallets && pr.user.wallets.length)) { // NOTE no wallet connected
          bidErrorMessages.push("You must connect a wallet to bid");
      } else if(Utils.get(pr.user.wallets, "0.balance") < Number(st.bidAmount)) {
          bidWarningMessages.push(`The wallet balance of ${Utils.abbreviateHash(pr.user.wallets[0].wallet_id)} is less than the current bid`);
      }

      this.setState({
          bidErrorMessages: bidErrorMessages,
          bidWarningMessages: bidWarningMessages,
      });
  }

  bidDifference(currency) {
      const st = this.state;
      const bidDifference = Number(st.bidAmount) - st.highestBid;

      switch(currency) {
          case "eth":
              return bidDifference;
              break;
          case "usd":
              return this.approxEthToUsd(bidDifference);
              break;
      }

      return bidDifference;
  }

  approxEthToUsd(ethAmount) {
      const st = this.state;
      return (ethAmount * st.ethPriceInUsd).toFixed(2)
  }

  connectWalletCallback() {
      console.log("connectWalletCallback() >>>");
  }

  renderStat(title, num, extraPerc, perc1) {
      return (
          <Div key={title} className="statForNft" style={this.styles.propsItem}>
            <Div style={{flex:1}}>
                {title}
            </Div>
            <Div style={{flex:1}}>
                <b>{num}</b>
            </Div>
            <Div style={{flex:1}}>
                <span style={{...this.styles.propsItemPerc,...extraPerc}}>
                    <span className="statPercentage" style={{...Styles.purpleWhiteCircle, ...{padding: "6px 8px"}}}>%</span>+{123}
                </span>
            </Div>
          </Div>
      );
  }

  renderStatsView() {
      return null;
      const pr = this.props;

    const collectors = Math.floor(Math.random() * 999);
    const collectorsDelta = Math.floor(Math.random() * collectors);
    const follows = Math.floor(Math.random() * 999);
    const followsDelta = Math.floor(Math.random() * follows);
    const perc1 = Math.floor(Math.random() * 50);
    const perc2 = Math.floor(Math.random() * 40);

    const tStreams = parseInt(Utils.get(pr.listing, "total_streams", 0));
    const tViews = parseInt(Utils.get(pr.listing, "total_views", 0));
    const totalStreams = tStreams != 0 ? pr.listing.total_streams : Math.floor(Math.random() * 999999);
    const totalViews = tViews != 0 ? tViews : Math.floor(Math.random() * 999999);
    const extraPerc = {backgroundColor:this.state.highlightColor, minWidth: 200};
    const statsView = [
        {body: this.renderStat("Streams", totalStreams, extraPerc, perc1+"K")},
        // {body: this.renderStat("Collectors", collectors, extraPerc, collectorsDelta) },
        {body: this.renderStat("Soin Views", totalViews, extraPerc, perc2) },
        // {body: this.renderStat("Soin Follows", follows, extraPerc, followsDelta) },
    ];
    const half = statsView.length / 2;
    let col1 = statsView.slice(0, half);
    let col2 = statsView.slice(half);
    const col1items = col1.map( (item) => {
        return item.body;
    });
    const col2items = col2.map( (item) => {
        return item.body;
    });
    return <Div style={{display:"flex",flexDirection:"row"}}>
            <Div style={{flex:1}}>
                {col1items}
            </Div>
            <Div style={{flex:1}}>
                {col2items}
            </Div>
         </Div>;
  }

    renderBidChart(mediaItem) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let lineChartDataArr = {"hourly":{}}; //"8:00": 2, "9:00": 5}};
        /*
        for(var i=0;i<24;i++) {
            lineChartDataArr["hourly"][i] = i + Math.floor(Math.random() * 4);
        }
        */
        let chartData = Utils.get(mediaItem, "price_history");
        const maxPrice = Utils.get(pr.listing, "max_price", 0)
        //console.log("Line chart data", chartData);
        let avgBandwidthArr = {};
        let maxBandwidthArr = {};
        let totalBandwidthArr = {};
        let peakbw = 0.0;
        let secondpeakbw = 0.0;
        let duration = ["hourly", "weekly", "monthly"];
        return (
            <Div className="col" style={sty.tileWhite} >
                <Div style={sty.tile}>
                        <AreaChart
                            height="225px"
                            data={chartData}
                            colors={[st.highlightColor, st.highlightColor2]}
                            max={maxPrice*1.1}
                            points={false}
                        />
                </Div>
            </Div>
        )
    }

  renderCard(listing, size) {
      const borderRadius = 0; //parseInt(size*.1);
      const captionHeight = parseInt(size*.4);
      const fontSize = parseInt(size*.06);
      const trackName = listing["title"]; //album["tracks"][0]["title"];
      const artistName = listing["artist_name"]; //album["artist"]["name"];
      const remainingPrints = listing["total_prints"] - listing["prints_sold"];
      //size = 180;
      let ts = moment();
      let formatDate = ts.format('h') + "h " + ts.format('m') + "m " + ts.format('s') + "s";
      let timeRemaing = "2h 35m";

      var a = moment([2021, 2, 6]);
      var b = moment([2021, 2, 4]);
      var hours = a.diff(b, 'hours');
      var minutes = a.diff(b, 'minutes');
      var seconds = a.diff(b, 'seconds');
      var sec = seconds % 60;
      window.sec = seconds;


        /*
        (function timerLoop() {
          $(".difference > span").text(moment().to(then));
          $(".countdown").text(countdown(then).toString());
          requestAnimationFrame(timerLoop);
        })();
        */

      formatDate = a.diff(b, 'hours') + "h " + a.diff(b, 'minutes') + "m " + sec + "s "; // 1
      formatDate = listing["remaining"];

      return <Div
        key={`card-${listing['id']}`}
        onClick={this.handlePosterClick.bind(this, listing)}
        style={{position:"relative",marginTop:6,borderRadius:borderRadius,overflow:"hidden",textAlign:"left"}}>
            { listing["listing_status"] == 2 ?
            <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>{formatDate}</Div>
            :
            null
            }
                <img style={{width:size, height:size, filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"}} src={listing["image_url"]} />
                <Div style={{whiteSpace: "nowrap", textAlign:"left",marginTop:19}}>
                    <Div style={{fontSize:14,fontWeight:"bold"}}>
                        {trackName}
                    </Div>
                    <Div style={{fontSize:12,color:"gray",marginTop:4}}>
                        {artistName}
                    </Div>
                    { listing["listing_status"] == 2 ?
                        <Div style={{textAlign:"left",color:"orange",fontWeight:"bold",fontSize:12,marginBottom:6}}>
                            {remainingPrints} of {listing["total_prints"]} left
                        </Div>
                        :
                        null
                    }
                </Div>
        </Div>;
  }

  renderRelatedSoins() {
      let renderedTiles = [];
      const relatedSoins = this.state.relatedSoins ? this.state.relatedSoins : this.props.relatedSoins;
      if(relatedSoins) {
          renderedTiles = relatedSoins.map( (album) => {
               if(album['id'] == this.state.mediaItem['id']) {
                   return <Div key="currentMI"></Div>; //this.renderCard(album, 180);
               } else {
                   return this.renderCard(album, 180);
               }
            });
      }
      let slidePercentage = 20;
      let transitionTime = 350;
      let centerMode = true;

      return <Div>
        <Div style={Styles.headline}>Related Soins</Div>
            <Div style={{padding:0,marginTop:10}}>
                {relatedSoins
                    ?
                      <Carousel
                        key={"relatedSoinsScroller"}
                        showThumbs={false}
                        useKeyboardArrows={true}
                        emulateTouch={true}
                        showStatus={false}
                        showIndicators={false}
                        centerMode={centerMode}
                        centerSlidePercentage={slidePercentage}
                      >
                        {renderedTiles}
                      </Carousel>
                    :
                    <Div>No related soins</Div>
                }
            </Div>
        </Div>;
  }

    renderGraph() {
        return null;
        const pr = this.props;

        let maxPrice = Utils.get(this.state.mediaItem, "max_price");
        maxPrice = maxPrice ? maxPrice.toFixed(3) : "";
        const currentPrice = this.state.mediaItem && this.state.mediaItem["current_price"] ? this.state.mediaItem["current_price"].toFixed(3) : '-';

        return (
            <Div id="historyChart">
                <Div className='chartHeader' style={{display:"flex",flexDirection:"row", marginTop:21, marginBottom:16,fontWeight:"bold"}}>
                    <Div style={{flex:1}}>Value History</Div>
                    <Div style={{flex:1}}>Current: {maxPrice} Ξ</Div>
                </Div>
                <Div>
                    {this.renderBidChart(pr.listing)}
                </Div>
            </Div>
        );
    }

    renderTimeLeftToBid() {
        const st = this.state;

        if(st.biddingSecondsLeft == -1) {
            const secondsToCome = st.minutesToCome * 60;

           return (
               <Div style={{display: "flex", alignItems: "center"}}>
                   {secondsToCome < 300 ?
                       <Div>The bidding for this NFT starts in {Utils.secondsToTimeString(secondsToCome, "dhms_short")}</Div>
                       :
                       <Div>The bidding for this NFT starts in {Utils.secondsToTimeString(secondsToCome, "dhm_short")}</Div>
                   }
                   <Button size="small" style={{marginLeft: 10}} color="white">
                       Notify Me The Day of the Auction
                   </Button>
               </Div>
           )
        }

        if(st.biddingSecondsLeft == -2) {
           return "The bidding for this NFT has ended."
        }

        if(st.biddingSecondsLeft < 300) { // NOTE: 5 min is 300 seconds
            return `Time left to bid: ${Utils.secondsToTimeString(st.biddingSecondsLeft, "dhms")}`;
        } else if(st.biddingSecondsLeft >= 300) {
            return `Time left to bid: ${Utils.secondsToTimeString(st.biddingSecondsLeft, "dhm")}`;
        }
    }

    renderHighestBidderMessage() {
        const pr = this.props;
        const st = this.state;

        if(st.isHighestBidder || Utils.get(pr.user, "id", false) === st.highestBidderId) {
            return "You are the higest bidder!"
        }

        if(st.outBid) {
            return "You've been outbid!"
        }

        return "You can bid now!";
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        console.log("LISTING", pr.listing);

        var titleStyle = sty.title
        if (pr.titleStyle) {
          titleStyle = { ...sty.title, ...pr.titleStyle }
        }
        var bodyStyle = sty.body
        if (pr.bodyStyle) {
          bodyStyle = { ...sty.body, ...pr.bodyStyle }
        }
        var body1Style = null
        if (pr.body1Style) {
          body1Style = this.props.body1Style
        }
        var buttonClass = "col"
        if (pr.buttonClass) {
          buttonClass = pr.buttonClass
        }
        var buttonRowStyle = sty.buttonRow
        if (pr.buttonRowStyle) {
          buttonRowStyle = { ...sty.buttonRow, ...pr.buttonRowStyle }
        }

        //console.log("SOURCES", pr.sourcesDict);
        const mobile = pr.mobile ? {} : {overflow: "hidden"};

        let thumbnail = Utils.get(pr, "listing.image_url", "");
        thumbnail = thumbnail.replace("http://", "https://");
        if(Utils.get(pr, "listing.thumbnail_animated")) {
            thumbnail = Utils.get(pr, "listing.thumbnail_animated");
        }

        //console.log("MEDIADEFAULT", thumbnail);
        let defaultImage = (thumbnail.indexOf("default_") != -1) ? true : false;
        if(defaultImage && thumbnail.indexOf("default_movie_400x570.jpg") != -1) {
            console.log("DEFAULTMEDIA");
            thumbnail = "default.jpg";
        }
        const sampleMp3 = Utils.get(pr.listing, "track_preview_url");
        const trackName = Utils.get(pr.listing, "title");
        const artistName = Utils.get(pr.listing, "artist_name");
        let printsMinted = "";
        const extraPerc = {backgroundColor:st.highlightColor};

        const listingStatus = Utils.get(pr.listing, "listing_status");

        if((listingStatus == 2 || listingStatus == 4) &&
            pr.listing["prints_sold"] < pr.listing["total_prints"]) {
            printsMinted = (pr.listing["total_prints"] - pr.listing["prints_sold"]) + " of " + (pr.listing["total_prints"] ) + " left";
        } else if (Utils.get(pr.listing, "prints_sold") >= Utils.get(pr.listing, "total_prints")) {
            printsMinted = <Div>{pr.listing["total_prints"] + " prints "} -- <span style={{...this.styles.propsItemPerc,...extraPerc}}>SOLD OUT</span></Div>;
        } else {
            printsMinted = (Utils.get(pr.listing, "total_prints", 0) ) + " prints";
        }
        const dropTime = "Drops "+(Utils.get(pr.listing, "remaining") ? pr.listing["remaining"] : " in 5 days");
        const totalStreams = "Streams: "+ Utils.get(pr.listing, "total_streams");
        const totalViews = "Video Views: "+ Utils.get(pr.listing, "total_views");

        const highlightColor = st.highlightColor;
        const borderColor = {borderColor:highlightColor};
        const highlightStyle = {color:highlightColor};
        //console.log("MEDIAITEM", pr.listing);
        let description = Utils.get(pr.listing, "description", "");
        if(!description || description.length == 0) {
            description = "Afterparty Guardians NFT Collection. Each Guardian is a VIP ticket to the upcoming LA Afterparty festival (Halloween Weekend 2022). Priority access to all future LA festivals. Priority access to our 3rd generation Afterparty NFT collection and more.";
        }
        const extraTL = {borderColor:st.highlightColor};
        const extraTR = {};
        const extraBR = {};
        const extraBL = {};
        let maxPrice = Utils.get(pr.listing, "max_price");
        maxPrice = maxPrice ? maxPrice.toFixed(3) : "";
        const currentPrice = pr.listing && pr.listing["current_price"] ? pr.listing["current_price"].toFixed(3) : '-';
        //const MoonPayUrl = "https://buy-staging.moonpay.io?apiKey=pk_test_123";
        const randNum = Math.random();
        const contractAddress = Utils.get(this.state, "moonpayInfo.contractAddress"); //"0xA1A9dFab98C5F692119686Aff5f5Bd993A8B5330";
        const tokenId = Utils.get(this.state, "moonpayInfo.tokenId"); //"112457658586931934152042554105038651850087300550240488280277587652331018649601";
        const apiKey = Utils.get(this.state, "moonpayInfo.apiKey"); //"pk_test_DsEcfo3q6iLBMIwpgpfldoOUcQE5nxGu";
        const sig = Utils.get(this.state, "moonpayInfo.sig"); //"IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        const walletId = Utils.get(this.state, "moonpayInfo.walletId"); //"IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        let MoonPayUrl = "https://buy-sandbox.moonpay.com/nft?contractAddress="+contractAddress+"&tokenId="+tokenId+"&apiKey="+apiKey+"&signature="+sig;
        if(walletId) {
            MoonPayUrl += "&walletAddress="+walletId
        }
        //console.log("MoonPayUrl", MoonPayUrl)
        //"https://buy-sandbox.moonpay.com/nft?contractAddress="+contractAddress+"&tokenId="+tokenId+"&apiKey="+apiKey+"&signature="+sig;
        const moonPayHeight = 640;
        let mainerStyle = {paddingTop:0,paddingLeft:35,paddingRight:35, display:"flex"}
        if(pr.mobile) {
            mainerStyle["flexWrap"] = "wrap";
        }
        const ownerId = Utils.get(pr.listing, "created_by");
        const isOwner = pr.user && pr.user["id"] && pr.user["id"] == ownerId ? true : false; // created_by
        //console.log("isOwner", isOwner);

    return (
      <Div className="dashboardSoinItemComponent col itemContainer"
        style={
          pr.style
            ? { ...sty.container, ...pr.style, ...mobile }
            : { ...sty.container }
        }

      >
        <DebugBox
            show={this.state}
            />

        <Div style={{display:"flex",marginTop:12,}}>
            <Div
                className="marketplaceBackButton"
                style={{marginLeft:6,cursor:"pointer", flex: 9}}
                onClick={this.handleMarketplaceLink.bind(this)}
            >
                <i style={{marginRight:12}} className="fa fa-chevron-left"></i> <span style={{fontWeight:"bold"}}></span>
            </Div>
            { false ?
                <Div style={{fontSize:12, height:36, flex: 3,}}>
                    <SearchBox
                        style={{position:"inherit", width:188, height:43, top:49, left:56,backgroundColor:"white",color:"black", border:"1px solid gray",}}
                        placeholder="Search..."
                        validate={this.runSearch.bind(this)}
                        placeholderClass="blackPlaceholder"
                        />
                    </Div>
                :
                null
            }
        </Div>
        <Div className='row' style={mainerStyle}>
            <Div className='col-lg-6 col-12 leftImage'>
                <Div className='' style={{...this.styles.posterContainer, ...borderColor}}>
                    <img
                        className="posterMediaImage"
                        style={this.styles.posterTile}
                        src={thumbnail}
                        />
                </Div>
                <Div style={{marginTop:20,marginLeft:140}}>
                    <Div style={{width:250}}>
                        <ReactPlayer
                            url={sampleMp3}
                            width="250px"
                            height="50px"
                            playing={false}
                            controls={true}
                          />
                     </Div>
                </Div>
            </Div>
            <Div className='col-lg-6 col-12 mediaInfo' style={{padding:0,marginTop:0}}>
                        <Div style={{display: "flex", flexDirection: "row"}}>
                            {false && pr.listing.artist_image_url ?
                                <img style={{width: 44, height: 44}} src={pr.listing.artist_image_url} />
                                :
                                null
                            }
                            <Div className="mediaArtist" style={sty.mediaArtist}>{artistName}</Div>
                            { false ?
                                <AfterpartyBidBadge
                                    showBidNotification={st.showBidNotification}
                                    handleClickBidNotification={this.handleClickBidNotification.bind(this)}
                                    handleCloseBidNotification={this.handleCloseBidNotification.bind(this)}
                                    notificationMessage={this.renderHighestBidderMessage()}
                                />
                                :
                                null
                            }
                        </Div>
                        <Div style={{display:"flex", gap: 25, flexWrap:"wrap", }}>
                            <Div className="mediaTitle" style={sty.mediaTitle}>{trackName}</Div>
                            <Div
                                onClick={this.handleAddToWatchlist.bind(this)}
                                    style={{color:"white", fontSize:24,marginTop:10,}}>
                                    <i className="fa fa-heart" />
                            </Div>
                        </Div>


                        {false && st.demoMode ?
                            <Div style={{display:"flex",flexDirection:"row",marginTop:14, }}>
                                <i style={{...sty.circle,...highlightStyle}} className="fa fa-circle"></i>
                                <Div className="mediaTitle" style={sty.mediaProperties}>{printsMinted}</Div>
                            </Div>
                            :
                            null
                        }

                        <Div style={{marginBottom:32, lineHeight:"28px",display:"flex", color:"#9A4EFC",}}>
                            { false ?
                                <Div style={{flex:1,display:"flex",paddingTop:8,}}>
                                    <Div>
                                        <img style={{width:50}} src={spiral} />
                                    </Div>
                                    <Div style={{fontSize:26, fontWeight:"bold", paddingTop:7,}}>
                                        Limited Release
                                    </Div>
                                </Div>
                                :
                                null
                            }
                            <Div style={{flex: 1}}>

                            </Div>
                        </Div>


                        <Div style={{marginBottom:32, lineHeight:"28px",color:"white",}}>
                            {description}
                        </Div>

                        <Div>
                            {this.renderStatsView()}
                        </Div>

                        { st.demoMode && pr.user ?
                            <Div style={{display: "flex",paddingTop:24, marginRight: 60}}>
                                { isOwner &&  (listingStatus == 2 || listingStatus == 4) ?
                                    <Div>
                                        <Div style={{padding:10,marginBottom:10,borderRadius:12,color:"white",}}>Current Price: ${this.props.listing["initial_price"]}</Div>
                                        <Button onClick={this.handleClickListItem.bind(this, 1)}>
                                            Remove from Marketplace
                                        </Button>
                                    </Div>
                                    :
                                    null
                                }
                                { isOwner &&  !(listingStatus == 2 || listingStatus == 4) ?
                                    <Div>
                                        <Div style={{textAlign:"center", marginBottom:10}}>You own this print</Div>
                                        <Button
                                            color="orange"
                                            onClick={() => this.setState({ showListItem: true }) }
                                            style={{}}>

                                                List on secondary market
                                        </Button>
                                    </Div>
                                    :
                                    null
                                }

                                { !isOwner && (listingStatus == 2 || listingStatus == 4) && typeof ethereum != "undefined" ?
                                    <Button className="buyEthereumButton" onClick={this.handleBuyWithWallet.bind(this)}>
                                        <Div style={{marginRight:8}}>Buy {currentPrice}</Div>
                                        <Div style={sty.ethereumIcon}>Ξ</Div>
                                    </Button>
                                    :
                                    null
                                }
                                { false && st.demoMode ?
                                    <Button className="buyEthereumButton" onClick={this.handleBuyWithMoonPay.bind(this)}>
                                        <Div style={{marginLeft:8,marginRight:8}}>Buy for ${this.props.listing.initial_price}</Div>
                                    </Button>
                                    :
                                    null
                                }
                            </Div>
                            :
                            st.demoMode ?
                                <Div style={{textAlign:"center",padding:10,borderRadius:6,backgroundColor:"rgba(255,0,0,0.5)"}}>
                                    You need to be signed in to purchase
                                </Div>
                                :
                                null
                        }
                        { st.demoMode && isOwner ?
                            <Div style={{marginTop: 20}}>
                                <Button className="gotoMarketplace" onClick={this.handleMarketplaceLink.bind(this)}>
                                        <Div style={{marginLeft:8,marginRight:8}}>Go to Marketplace</Div>
                                </Button>
                            </Div>
                            :
                            null
                        }

                        {false && st.demoMode ?
                            <Div>
                                <Div className="timeUntilBidEnds" style={sty.timeUntilBidEnds}>
                                    {this.renderTimeLeftToBid()}
                                </Div>
                                {st.biddingSecondsLeft > -1 ? // NOTE: bidding has not ended
                                    <Div className="biddingButtonContainer">
                                        {this.renderHighestBidderMessage()}
                                        <Div>Current bid: {st.highestBid} Ξ</Div>
                                        {st.intervalBidCount == 0 ?
                                            null
                                            :
                                            <Div>There were {st.intervalBidCount} bids in the last 10 minutes</Div>
                                        }
                                        <Button style={{marginTop: 10}} onClick={this.openBiddingModal.bind(this)}>
                                            BIDDING
                                        </Button>
                                    </Div>
                                    :
                                    null
                                }
                            </Div>
                            :
                            null
                        }

                        { false && pr.listing["listing_status"] == 2  ?
                            <Div className="mediaTitle" style={this.styles.mediaProperties}>{dropTime}</Div>
                            :
                            null
                        }

                        { st.demoMode ?
                            this.renderGraph()
                            :
                            null
                        }


                { !this.props.isPortal ?
                    <Div className='row'>
                        <Div className='col-12'>
                            <Div style={{fontSize:18, color: "#111", fontWeight:"bold", marginTop:20}}>
                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }
            </Div>
            {this.state.relatedSoins || this.props.relatedSoins ?
                <Div  style={{width:"100%"}}>
                    {this.renderRelatedSoins()}
                </Div>
                :
                null
            }
        </Div>
        { st.showMoonPayModal ?
            <Modal
                screen={pr.screen}
                onRequestClose={this.closeMoonPayModal.bind(this)}
                className="biddingModal"
                color="purple"
                title={`Bid on ${trackName} NFT`}
            >
                <Div style={{height:moonPayHeight,width:"100%",borderRadius:12,overflow:"hidden",}}>
                    <iframe
                          allow="accelerometer; autoplay; camera; gyroscope; payment"
                          frameborder="0"
                          height="100%"
                          src={MoonPayUrl}
                          width="100%"
                        />
                </Div>
            </Modal>
            :
            null

        }

        { st.showListItem ?
            <Modal
                screen={pr.screen}
                onRequestClose={this.closeListingModal.bind(this)}
                className="biddingModal"
                color="purple"
                title={`List '${trackName}' on marketplace`}
            >
                <Div style={{height:moonPayHeight,width:"100%",borderRadius:12,overflow:"hidden",padding:30}}>
                    <Div style={{display:"flex"}}>
                        <Div
                            onClick={this.handleToggleSaleType.bind(this)}
                            style={this.state.showListItemSelected == 'sale' ? this.styles.selectedSaleTypeTab : this.styles.saleTypeTab}>Sale</Div>
                        <Div
                            onClick={this.handleToggleSaleType.bind(this)}
                            style={this.state.showListItemSelected != 'sale' ? this.styles.selectedSaleTypeTab : this.styles.saleTypeTab}>Auction Sale</Div>
                    </Div>
                    { this.state.showListItemSelected == 'sale' ?
                        <Div style={this.styles.listMarketplaceTitles}>List Price</Div>
                        :
                        <Div style={this.styles.listMarketplaceTitles}>Starting Price</Div>
                    }
                    <Div style={{display:"flex"}}>
                        <Div style={this.styles.amountLabel}>$</Div>
                        <Div>
                            <Input
                                value={st.listingPrice}
                                onChange={this.changeVal.bind(this, 'listingPrice')}
                                style={this.styles.listMarketplaceInputs}
                                />
                        </Div>
                        <Div style={this.styles.amountLabel}>
                            {this.state.listingPriceEth} ETH
                        </Div>
                    </Div>
                    { this.state.showListItemSelected != 'sale' ?
                        <Div>
                            <Div style={this.styles.listMarketplaceTitles}>Reserve Price</Div>
                            <Div style={{display:"flex"}}>
                                <Div style={this.styles.amountLabel}>$</Div>
                                <Input
                                    value={st.reservePrice}
                                    onChange={this.changeVal.bind(this, 'reservePrice')}
                                    style={this.styles.listMarketplaceInputs}
                                    />
                                <Div style={this.styles.amountLabel}>
                                    {this.state.reservePriceEth} ETH
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }

                    <Div style={this.styles.listMarketplaceTitles}>Start Date</Div>
                    <Div>
                        <Input
                            value={st.listingStartDate}
                            onChange={this.changeVal.bind(this, 'listingStartDate')}
                            style={this.styles.listMarketplaceInputs}
                            />
                    </Div>
                    <Div style={this.styles.listMarketplaceTitles}>End Date</Div>
                    <Div>
                        <Input
                            value={st.listingEndDate}
                            onChange={this.changeVal.bind(this, 'listingEndDate')}
                            style={this.styles.listMarketplaceInputs}
                            />
                    </Div>
                    <Button color="orange" onClick={this.handleClickListItem.bind(this)}>List Item</Button>
                </Div>
            </Modal>
            :
            null

        }

        {st.showBiddingModal ?
            <Modal
                screen={pr.screen}
                onRequestClose={this.closeBiddingModal.bind(this)}
                className="biddingModal"
                color="purple"
                title={`Bid on ${trackName} NFT`}
            >
                <Div>
                    <Div className="timeUntilBidEnds" style={sty.timeUntilBidEnds}>
                        {this.renderTimeLeftToBid()}
                    </Div>
                    <Div style={{marginBottom: 10}}>Current bid: {st.highestBid} Ξ (≈ ${this.approxEthToUsd(st.highestBid)})</Div>
                    {st.intervalBidCount == 0 ?
                        null
                        :
                        <Div>There were {st.intervalBidCount} bids in the last 10 minutes</Div>
                    }
                    <Div style={{marginBottom: 5}}>Your bid:</Div>
                    <Div className="bidAmountRow" style={sty.bidAmountRow}>
                        <Div className="biddingInputEthContainer" style={sty.biddingInputEthContainer}>
                            <Input
                                style={sty.biddingInput}
                                className="biddingInput"
                                value={st.bidAmount}
                                onChange={this.bidAmountOnChange.bind(this)}
                                placeholder="0"
                            />
                            <Div style={sty.ethereumIconForBid}>Ξ</Div>
                        </Div>
                    </Div>
                    <Div style={{marginBottom: 10, textAlign: "right"}}>≈ ${this.approxEthToUsd(Number(st.bidAmount))} (≈ ${this.bidDifference("usd")} more than the current bid)</Div>

                    {st.bidFailedErrors.length ?
                        <Div className="bidError" style={sty.bidError}>
                            <Div>Your bid has failed: <Span style={{fontWeight: 700}}>{st.bidFailedErrors[0].message}</Span></Div>
                            <Div style={{display: "flex"}}>Please&nbsp;<Button type="text" onClick={()=>{window.location.reload()}}>refresh</Button>&nbsp;the page and try again.</Div>
                        </Div>
                        :
                        null
                    }

                    {st.bidErrorMessages.length ?
                        <Div className="bidError" style={sty.bidError}>
                            <Div>{st.bidErrorMessages[0]}</Div>
                        </Div>
                        :
                        null
                    }

                    {st.bidWarningMessages.length ?
                        <Div className="bidWarning" style={sty.bidWarning}>
                            <Div>{st.bidWarningMessages[0]}</Div>
                        </Div>
                        :
                        null
                    }
                    <Button
                        className="bidOnButton"
                        style={sty.bidOnButton}
                        onClick={this.placeBidOnListing.bind(this)}
                        disabled={st.bidFailedErrors.length || st.bidErrorMessages.length}
                    >
                        Place Bid
                    </Button>
                </Div>
            </Modal>
            :
            null
        }

        <RegisterFlow
            screen={pr.screen}
            usernameModalTitle={<Div>Create an account to Bid on NFT's</Div>}
            successModalMessage={<Div>Now you can bid on NFT's!</Div>}
            registerModalBody={st.registerModalBody}
            showRegisterFlowModal={st.showRegisterFlowModal}
            listings={[pr.listing]}
            setUser={pr.setUser.bind(this)}
            onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
            setRegisteredSuccess={this.setRegisteredSuccess.bind(this)}
            connectWalletCallback={this.connectWalletCallback.bind(this)}
        />

      </Div>
    );
  }
  styles = {
    container: {
        backgroundColor: "#0F0420",
        padding: 5,
        textAlign: "left",
        paddingBottom: 150,
        minHeight: 800,
        paddingTop: 100,
    },
    cardBackground: {
        filter: "blur(25px) brightness(3)",
        marginLeft: -100,
        marginTop: -200,
        minWidth: "130%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: -1,
        opacity: 0.6,
    },

    title: {
      color: '#032E4B',
      fontWeight: 'bold',
      fontSize: 32,
      padding: 12,
      letterSpacing: '0.75px'
    },
    body: {
      padding: 12
    },
    bodyExtra: {
      padding: 12
    },
    smallclose: {
      textAlign: 'right',
      marginRight: '-10px',
      marginTop: '-20px',
      cursor: 'pointer',
      color: '#F6F0E8',
    },
    buttonRow: {
      clear: "both",
    },
    transparentPanel: {
        marginTop: 20,
        padding:20,
        marginBottom:20,
    },
    transparentButton: {
        cursor: "pointer",
        margin: "10px 60px",
        paddingTop: 8,
        flex:2,
        textAlign: 'center',
        borderRadius:20,
        height:40,
        backgroundColor:"rgba(0.1,0.1,0.1,0.5)",
    },
    mediaArtist: {
      color: "#564DB2",
      fontSize: 22,
      marginBottom: 2,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    mediaTitle: {
      color: "white",
      fontSize: 41,
      fontWeight: "bold",
      marginBottom: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    mediaDescription: {
      color: "white",
      fontSize: 12,
      textAlign: "left",
      overflow: "hidden",
      height: 180,
      textOverflow: "ellipsis",
      marginBottom: 12,
    },
    mediaProperties: {
      fontSize: 17,
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    posterContainer: {
        position:"relative",
    },
    posterTile: {
      overflow: "hidden",
      width:"90%",
      borderRadius:20,
      /* border: "1px solid rgba(255,255,255,0.2)", */
    },
    circle: {
        paddingTop:3,marginRight:6,fontSize:20,
    },
    propsItem: {
        fontSize: 14,
        marginBottom: 35,
        color: "gray",
        display: "flex",
    },
    propsItemPerc: {
        fontSize: 10,
        fontWeight:"bold",
        backgroundColor:"#EEE1FC",
        color:"black",
        padding: 14,
        borderRadius: 8,
    },
    orangeGradientButton: {
        background: "linear-gradient(135deg, #ff4751 0%,#ff9848 100%)",
        color: "white",
        padding:23,
        textAlign:"center",
        fontWeight: "bold",
        cursor: "pointer",
    },

    ethereumIcon: {
        borderRadius: 100,
        height: 25,
        width: 25,
        backgroundColor: "white",
        color: Colors.magenta,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bidOnButton: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    timeUntilBidEnds: {
        paddingTop: 10,
        paddingBottom: 20,
    },
    biddingInput: {
        textAlign: "right",
        marginBottom: 0,
        marginRight: 12,
        paddingRight: 34,
    },
    bidAmountRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    ethereumIconForBid: {
        fontSize: 17,
        color: Colors.purple,
        position: "absolute",
        top: 14,
        right: 12,
    },
    notificationContainer: {
        position: "absolute",
        backgroundColor: "white",
        borderRadius: 4,
        border: "1px solid #bbb",
        padding: 10,
        fontSize: 16,
    },
    biddingInputEthContainer: {
        position: "relative",
    },
    bidError: {
        // display: "flex",
        color: Colors.redError,
        padding: 5,
        borderRadius: 4,
        textAlign: "center",
        marginBottom: 10,
    },
    bidWarning: {
        // display: "flex",
        color: "yellow",
        padding: 5,
        borderRadius: 4,
        textAlign: "center",
        marginBottom: 10,
    },
    listMarketplaceTitles: {
        marginTop: 20,
        marginBottom: 16,
        fontWeight: "bold",
        fontSize: 18,
    },
    listMarketplaceInputs: {
        backgroundColor:"#69347A",
        borderRadius:12,
        color:"rgba(255,255,255,0.5)",
        //width:"80%",
    },
    amountLabel: {
        marginTop: 16,
        marginLeft: 10,
        marginRight: 10,
        whiteSpace: "nowrap",
    },
    saleTypeTab: {
        flex:1, textAlign:'center',
        paddingTop: 6,
        paddingBottom: 6,
    },
    selectedSaleTypeTab: {
        border: "2px solid white",
        borderRadius: 12,
        flex:1, textAlign:'center',
        paddingTop: 6,
        paddingBottom: 6,
    },
  };
}
